import { VIP_BACKGROUND } from '@/config/const';
import { stringAvatar } from '@/shared/helpers';
import { getApiUrl } from '@/utils/api/base/axios';
import {
 colorGreyScale300, colorGreyScale600, colorGreyScale900, colorSuccess, colorText, sidebarBackground, 
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';


const InfoBlock = () => {
  const [imgError, setImgError] = useState(false);
  const { profile, rank = 0 } = useSelector(state => ({
    profile: state.user.profile,
    rank: 0,
  }), isEqual);
  const name = profile?.fn && profile?.fn ? `${profile?.fn} ${profile?.ln}` : profile?.nn;
  const handleError = () => {
    setImgError(true);
  };
  if (!profile) return null;
  return (
    <Box 
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {(!profile.photo || imgError) && <AvatarStyle>{stringAvatar(name)}</AvatarStyle>}
      {(profile.photo && !imgError) && (
        <AvatarImageStyle
          src={getApiUrl(profile.photo)}
          onError={handleError}
        />
      )}
      <Box mt="12px">
        <Title>{name} {rank > 0 && <Vip rank={rank}>VIP {rank}</Vip>}</Title>
        <SubTitle>#{profile?.uid}</SubTitle>
      </Box>
    </Box>
  );
};

const AvatarStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: ${colorSuccess};
  border-radius: 100%;
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;
  color: ${sidebarBackground};
`;

const AvatarImageStyle = styled(Image)`
  width: 80px;
  height: 80px;
  background: ${sidebarBackground};
  border-radius: 100%;
  padding: 2px;
`;
const Title = styled.h1`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${colorText};
  display: flex;
  gap: 4px
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: ${colorGreyScale600};
  text-align: center;
`;
const Vip = styled(Box)`
  display: flex;
  height: 22px;
  padding: 1px 8px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  background: ${props => VIP_BACKGROUND[props.rank - 1]}
`;

export default InfoBlock;
