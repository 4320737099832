import { BET_TYPE } from '@/shared/constants';
import { convertMaskToNum } from './validator';

const setSubProfit = (values) => {
  if (
    !values.subProfit ||
    (values.subProfit &&
      !values.sub_take_profit &&
      !values.sub_stop_loss &&
      !values.sub_lose_streak &&
      !values.sub_win_streak)
  ) {
    return false;
  }

  return true;
};

export const createBody = (values) => {
  const isWinStreak = values.waitSignalType === BET_TYPE.win;

  const body = {
    subProfit: setSubProfit(values),
    reverse_signal: values.reverse_signal,
    wait_signal: values.wait_signal,
    win_streak: isWinStreak ? values.win_streak : 0,
    lose_streak: !isWinStreak ? values.lose_streak : 0,
    no_of_entries: values.isUltilFinishBudget === 'a' ? -1 : values.no_of_entries,
    sub_take_profit: 0,
    sub_stop_loss: 0,
    sub_lose_streak: values.sub_lose_streak,
    sub_win_streak: values.sub_win_streak,
    waitSignalType: values.waitSignalType,
  };

  if (values.showSubProfit && values.subProfit) {
    body.sub_take_profit = convertMaskToNum(values.sub_take_profit);
    body.sub_stop_loss = convertMaskToNum(values.sub_stop_loss);
  }

  return body;
};
