import { createSelector } from 'reselect';

export const selectState = (state) => state.appConfig;
export const selectSiteConfig = createSelector(selectState, (item) => item.siteConfig);
export const selectTuts = createSelector(selectSiteConfig, (item) => item.tuts);

export const selectUserConfig = createSelector(selectState, (item) => {
  // console.log('selectUserConfig: ', item);
  return item.userConfig;
});
