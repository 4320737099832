import { createSelector } from 'reselect';
import { PLAN_STATUS } from '@/shared/constants';

export const getPlan = (state) => state.plan;
export const selectSelectedPlans = createSelector(
  getPlan,
  (plan) => plan.selectedPlans
);

export const selectSelectedRunningPlans = createSelector(
  getPlan,
  (plan) => plan.selectedPlans.filter(p => p.status !== PLAN_STATUS.stopped)
);

export const selectSelectedStoppedPlans = createSelector(
  getPlan,
  (plan) => plan.selectedPlans.filter(p => p.status === PLAN_STATUS.stopped)
);

export const selectSelectedEndedPlans = createSelector(
  getPlan,
  (plan) => plan.selectedPlans.filter(p => p?.is_endtarget)
);

