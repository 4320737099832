import authReducer from './refactor/auth';
import blocksShadowsReducer from './refactor/blocksShadows';
import roundBordersReducer from './refactor/roundBorders';
import rtlReducer from './refactor/rtl';
import sidebarReducer from './refactor/sidebar';
import themeReducer from './refactor/theme';
// import planReducer from './plan';
// import strategyReducer from './strategy';
// import botReducer from './bot';
import dashboardReducer from './refactor/dashboard';
import notificationReducer from './refactor/notification';
import twoFactorReducer from './refactor/twoFactor';
import walletReducer from './refactor/wallet';
// import affiliationReducer from './affiliation';
import appConfigReducer from './refactor/appConfig';
import commonReducer from './refactor/common';
import exchangeReducer from './refactor/exchange';
import exchangeTwoFactorReducer from './refactor/exchangeTwoFactor';

import {
  affiliationReducer,
  botReducer,
  planReducer,
  strategyReducer,
  ticketReducer,
  userFollowerReducer,
  notiReducer,
  userActivityTrackerReducer
} from './reducers';

const rootReducers = {
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  appConfig: appConfigReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  plan: planReducer,
  strategy: strategyReducer,
  bot: botReducer,
  twoFactor: twoFactorReducer,
  wallet: walletReducer,
  dashboard: dashboardReducer,
  notification: notificationReducer,
  affiliation: affiliationReducer,
  exchange: exchangeReducer,
  common: commonReducer,
  ticket: ticketReducer,
  exchangeTwoFactor: exchangeTwoFactorReducer,
  userFollower: userFollowerReducer,
  noti: notiReducer,
  userActivityTracker: userActivityTrackerReducer,
};

export default rootReducers;
