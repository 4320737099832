import { Devices, sidebarBackground } from '@/utils/palette';
import { Drawer } from '@material-ui/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PanelDeposit from './PanelDeposit';
import PanelInfo from './PanelInfo';
import PanelTransaction from './PanelTransaction';
import PanelTransfer from './PanelTransfer';
import PanelWithdraw from './PanelWithdraw';
import Sliders from './Slider';

const DrawerWallet = ({ open, onClose }) => {
  const [selected, setSelected] = useState(null);
  const [focusedIdx, setFocusedIdx] = React.useState(0);
  const isMobile = useSelector((state) => state.theme.isMobile, isEqual);
  const handleCloseModal = () => {
    setFocusedIdx(0);
    onClose();
  };
  return (
    <DrawerStyle
      open={open}
      onClose={handleCloseModal}
      anchor={isMobile ? 'bottom' : 'right'}
    >
      <Sliders focusedIdx={focusedIdx}>
        <PanelInfo
          show={focusedIdx === 0}
          setFocusedIdx={setFocusedIdx}
          setSelected={setSelected}
          onClose={handleCloseModal}
        />
        <PanelDeposit
          show={focusedIdx === 1}
          setFocusedIdx={setFocusedIdx}
          onClose={handleCloseModal}
        />
        <PanelWithdraw
          show={focusedIdx === 2}
          setFocusedIdx={setFocusedIdx}
          onClose={handleCloseModal}
        />
        <PanelTransfer
          show={focusedIdx === 3}
          setFocusedIdx={setFocusedIdx}
          onClose={handleCloseModal}
        />
        <PanelTransaction
          value={selected}
          show={focusedIdx === 4}
          setFocusedIdx={setFocusedIdx}
          onClose={handleCloseModal}
        />
      </Sliders>
    </DrawerStyle>
  );
};

DrawerWallet.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const DrawerStyle = styled(Drawer)`
  .MuiPaper-root {
    background-color: transparent;
  }
  .slider-container {
    background: ${sidebarBackground};
    border-radius: 16px 16px 0px 0px;
    ${(props) =>
      props.anchor === 'right' &&
      `
        width: 450px;
    `}
    @media ${Devices.laptop} {
      border-radius: unset;
    }
  }
`;

export default DrawerWallet;
