/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, ModalHeader } from 'react-bootstrap';
import styled from 'styled-components';
import {
  colorBackgroundModal,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import Icons from '@/shared/components/icons';

const Modal = (
  {
    title,
    isOpen,
    closeModal,
    children,
    className,
    centered,
    renderCustomTitle
  },
) => (
  <ModalStyle onHide={closeModal} show={isOpen} className={className} centered={centered}>
    <ModalHeaderStyle className="d-flex justify-content-between">
      {title && <ModalTitleStyle>{title}</ModalTitleStyle>}
      {!title && renderCustomTitle()}
      <Box textAlign="right">
        <ModalBtnClose onClick={closeModal} />
      </Box>
    </ModalHeaderStyle>
    {children}
  </ModalStyle>
);

export const ModalStyle = styled(BootstrapModal)`
  .modal-dialog {
    max-width: calc(598px + 16px);
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 24px !important;
  }
`;
export const ModalHeaderStyle = styled(ModalHeader)`
`;
export const ModalTitleStyle = styled(BootstrapModal.Title)`
  font-size: 20px;
  line-height: 140%;
`;
export const ModalBodyStyle = styled(BootstrapModal.Body)`
  background-color: ${colorBackgroundModal};
`;
export const ModalFooterStyle = styled(BootstrapModal.Footer)`
  background-color: ${colorBackgroundModal};
  justify-content: center;
`;
export const ModalBtnClose = styled(Icons.CloseIcon)`
  :hover {
    cursor: pointer;
  }
`;

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  centered: PropTypes.bool,
  renderCustomTitle: PropTypes.func
};

Modal.defaultProps = {
  title: '',
  isOpen: false,
  className: '',
  centered: false,
  renderCustomTitle: () => {}
};

export default Modal;
