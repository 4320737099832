import { selectBots } from '@/redux/refactor/common/selector';
import Tooltip from '@/shared/components/Tooltip';
import Error from '@/shared/components/form/Error';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { memo, useMemo } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DefaultTarget as BaseDefaultTarget, FieldStyle } from './FormElements';
import SettingItem from './SettingItem';

export const OrderIndex = memo(
  ({ variant = 'v1', enableOrderIndex = false, botId = '', setOrderIndex, error, signalType }) => {
    const { t } = useTranslation();
    const { second_order_min, second_order_max, defaultSelects } = useSelector(
      ({ appConfig = {} }) => {
        const {
          time_range_order_in_second: { second_order_min = 0, second_order_max = 0 },
        } = appConfig.siteConfig;

        return {
          second_order_min,
          second_order_max,
          defaultSelects: [
            second_order_max,
            (second_order_min + second_order_max) / 2,
            second_order_min,
          ],
        };
      },
      isEqual
    );
    const validate = (value) => {
      const num = Number(value);
      if (!value || num < second_order_min || num > second_order_max)
        return t('FrmCreate_OrderIndex_Invalid', {
          min: second_order_min,
          max: second_order_max,
        });
      return undefined;
    };
    const bots = useSelector(selectBots, isEqual);
    const bot = bots.find((item) => isEqual(item.id.toString(), botId.toString())) || {};
    const defaultValue = useMemo(() => {
      const { strategy, sources = [] } = bot;
      if (strategy === 'SINGLE' && sources[0] && sources[0]?.source?.startsWith('SENTIMENT')) {
        return 6;
      }
      
      return second_order_max;
    }, [bot, second_order_max]);
    const renderLabel = () => t('FrmCreate_OrderIndex_label', 'Order time');
    const renderTooltip = () => (
      <Tooltip
        text={t('FrmCreate_OrderIndex_tooltip', 'The order will be set at the specified time', {
          min: second_order_min,
          max: second_order_max,
        })}
      />
    );
    const renderInputField = () => {
      return (
        <FieldStyle
          name="order_index"
          component={InputField}
          placeholder={t('FrmCreate_OrderIndex_placeholder', '0', {
            min: second_order_min,
            max: second_order_max,
          })}
          type="number"
          min={second_order_min}
          max={second_order_max}
          defaultValue={defaultValue}
          validate={validate}
        />
      );
    };
    const renderDefaultTarget = () => (
      <BaseDefaultTarget onSelect={setOrderIndex} options={defaultSelects} affix="" />
    );
    const renderHeader = () => (
      <SettingItem
        variant={variant}
        name="enableOrderIndex"
        title={t('FrmCreate_OrderIndex_title', 'Set up order time')}
        desc={t('FrmCreate_OrderIndex_description', 'The order will be set at the specified time')}
      />
    );

    if (signalType !== SIGNAL_STRATEGY_TYPE.botai || !bot.allow_order_index) {
      return null;
    }
    return (
      <>
        <Grid.Row
          className={classNames({
            'border-none': !enableOrderIndex,
          })}
        >
          <Grid.Cell>{renderHeader()}</Grid.Cell>
        </Grid.Row>
        <Collapse in={enableOrderIndex} unmountOnExit>
          <Grid.Row>
            <Grid.Cell>
              <Form.Label>
                {renderLabel()}
                {renderTooltip()}
              </Form.Label>
              <Box display="flex" alignItems="center">
                <Box maxWidth={90}>{renderInputField()}</Box>
                {renderDefaultTarget()}
              </Box>
              <Error error={error} />
            </Grid.Cell>
          </Grid.Row>
        </Collapse>
      </>
    );
  }
);

const InputField = ({ input, showError, meta = {}, min, max, ...props }) => {
  const { error } = meta;
  const { onChange } = input;
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input value is a number and within the desired range
    if (
      (/^[+]?\d+$/.test(inputValue) && inputValue > 0 && inputValue <= max) ||
      inputValue === ''
    ) {
      onChange(inputValue);
    }
  };
  return (
    <Box>
      <input {...props} {...input} onChange={handleInputChange} />
      {showError && error && <Error error={error} />}
    </Box>
  );
};
