import React from 'react';
import { isEqual } from 'lodash';
import { FORM_TYPE } from '@/shared/constants';
import { useMediaQuery } from '@material-ui/core';
import { Devices } from '@/utils/palette';
import { useDispatch, useSelector } from 'react-redux';

import { updateCurrentBot, updateFormTypeBot, updateFormStatusBot, updateFormTypeCandle, updateFormStatusCandle } from '@/redux/actions/botActions';
import { updateCurrentStrategy, updateFormTypeStrategy, updateFormStatusStrategy } from '@/redux/actions/strategyActions';
import { DrawerStyle, drawerProps } from '../form/drawer/Elements';
import FormBot from '@/containers/Bot/FormBot';
import FormStrategy from '@/containers/Strategy/FormStrategy';
import CandlePattern from '@/containers/Bot/CandlePattern';

const GlobalPopup = () => {
  const dispatch = useDispatch();
  const isPc = useMediaQuery(Devices.pc);
  const drawerProp = isPc ? drawerProps.right : drawerProps.mobile;

  const { isShowFormBot, isShowFormStrategy, isShowFormCandle, isAuth } = useSelector(
    (state) => ({
      isShowFormBot: state.bot.formBot.isShow,
      isShowFormCandle: state.bot.formCandle.isShow,
      isShowFormStrategy: state.strategy.formStrategy.isShow,
      isAuth: state.user.isAuth,
    }),
    isEqual
  );

  const formBotToggle = () => {
    if (isShowFormBot) {
      dispatch(updateCurrentBot({}));
      dispatch(updateFormTypeBot(FORM_TYPE.create));
    }
    dispatch(updateFormStatusBot(!isShowFormBot));
  };

  const formCandleToggle = () => {
    if (isShowFormCandle) {
      dispatch(updateCurrentBot({}));
      dispatch(updateFormTypeCandle(FORM_TYPE.create));
    }
    dispatch(updateFormStatusCandle(!isShowFormCandle));
  };

  const formStrategyToggle = () => {
    if (isShowFormStrategy) {
      dispatch(updateCurrentStrategy({}));
      dispatch(updateFormTypeStrategy(FORM_TYPE.create));
    }
    dispatch(updateFormStatusStrategy(!isShowFormStrategy));
  };

  const checkShowFormBot = () => {
    return isAuth && (
      location.pathname.includes('/portfolios') ||
      location.pathname.includes('/signal-strategies') ||
      location.pathname === "/"
    )
  }

  const checkShowFormStrategy = () => {
    return isAuth && (
      location.pathname.includes('/portfolios') ||
      location.pathname.includes('/budget-strategies') ||
      location.pathname.includes('/signal-strategies/') ||
      location.pathname === "/"
    )
  }

  return (
    <>
      {checkShowFormBot() && (
        <>
          <DrawerStyle
            anchor="right"
            open={isShowFormBot}
            onClose={formBotToggle}
            keepMounted={false}
            ModalProps={{ keepMounted: false }}
            {...drawerProp}
          >
            <FormBot
              onClose={formBotToggle}
            />
          </DrawerStyle>
          <DrawerStyle
            anchor="right"
            open={isShowFormCandle}
            onClose={formCandleToggle}
            ModalProps={{ keepMounted: false }}
            {...drawerProp}
          >
            <CandlePattern
              onClose={formCandleToggle}
            />
          </DrawerStyle>
        </>
      )}

      {checkShowFormStrategy() && (
        <DrawerStyle
          anchor="right"
          open={isShowFormStrategy}
          onClose={formStrategyToggle}
          keepMounted={false}
          ModalProps={{ keepMounted: false }}
          {...drawerProp}
        >
          <FormStrategy
            onClose={formStrategyToggle}
          />
        </DrawerStyle>
      )}
    </>
  );
};

export default GlobalPopup;
