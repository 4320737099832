import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@/shared/helpers';

const ListItem = ({ item, getListComment }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`ticket-item ${item.status}`}
      onClick={() => getListComment(item)}
      onKeyDown={() => getListComment(item)}
      role="presentation"
    >
      <div className="d-flex justify-content-between">
        <h3 className="ticket-item-id">{t('Ticket ID')}: {item.id}</h3>
        <div className="ticket-item-time">
          {dateFormat(item.created_at)}
        </div>
      </div>
      <div className="ticket-item-subject">
        <span className="ticket-item-label">{t('Issue')}: </span>
        <span className="ticket-item-value">{t(item.subject ? item.subject.trim() : '')}</span>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  getListComment: PropTypes.func.isRequired,
};

export default ListItem;
