import { Devices } from '@/utils/palette';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { FormCheckbox, FormHint, FormLabel } from './FormElements';

// Begin SettingItem
const SettingItem = ({ name, title, desc, variant }) => (
  <Box display="flex" gridGap="24px" alignItems="center" width="100%">
    <Box flex={1}>
      <FormLabelStyle variant={variant}>{title}</FormLabelStyle>
      <FormHintStyle>{desc}</FormHintStyle>
    </Box>
    {name && (
      <Box>
        <Field name={name} component={FormCheckbox} type="switch" />
      </Box>
    )}
  </Box>
);

export const FormLabelStyle = styled(FormLabel)`
  margin-bottom: 4px;
  font-size: ${(props) => (props.variant === 'v1' ? 20 : 16)}px;
  font-weight: 700;
  @media ${Devices.pc} {
    font-size: ${(props) => (props.variant === 'v1' ? 24 : 16)}px;
  }
`;
export const FormHintStyle = styled(FormHint)`
  margin: 0;
  font-size: 12px;
`;
// End SettingItem

SettingItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

SettingItem.defaultProps = {
  name: '',
  title: '',
  desc: '',
};

export default SettingItem;
