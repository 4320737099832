import ModalSettings, {
  ModalSettingBodyStyle,
} from '@/containers/Settings/components/ModalSettings';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { transfer } from '@/redux/refactor/wallet/actions';
import FormTransfer from './components/FormTransfer';


const ModalTransfer = withTranslation()((props) => {
  const { closeModal, t } = props;
  const dispatch = useDispatch();
  const transferring = useSelector(state => state.wallet.transferring, isEqual);
  const handleSubmit = async ({ transferType, amount }) => {
    const ok = await dispatch(transfer(transferType, {
      amount,
      confirmed: true,
    }));
    if (ok) closeModal();
  };
  return (
    <ModalSettings maxWidth="calc(520px + 16px)" {...props} title={t('Transfer Your Fund')}>
      <ModalSettingBodyStyle>
        <FormTransfer onSubmit={handleSubmit} loading={transferring} />
      </ModalSettingBodyStyle>
    </ModalSettings>
  );
});

export default ModalTransfer;
