import React, { useEffect, useMemo, useRef } from 'react';
import { Field, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { setIsCommunitySignal } from '@/redux/refactor/common/actions';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';

import useSignalType from '../useSignalType';
import { RadioAsButton } from './FormElements';

// Begin SignalType
export default ({ setExpertMode, isEdit }) => {
  const dispatch = useDispatch();
  const { values = {} } = useFormState();
  const containerRef = useRef(null);
  const signalType = useSignalType({
    isEdit,
    currentValue: values.signal_type,
    isImported: !!values.copy_from,
  });
  const onSelect = ({ target }) => {
    if (target.value === SIGNAL_STRATEGY_TYPE.community) {
      dispatch(setIsCommunitySignal(true));
    } else {
      dispatch(setIsCommunitySignal(false));
    }
    
    if (isEdit) return;
    setExpertMode(false);
  };

  return useMemo(
    () => (
      <SignalTypeWrap
        isSingle={!!values.copy_from}
        id="signalTypeWrap"
        ref={containerRef}
      >
        {signalType.map((item) => (
          <Field
            name="signal_type"
            label={item.name}
            value={item.code}
            id={'cra'.concat(item.code)}
            key={item.code}
            type="radio"
            component={RadioAsButton}
            onClick={onSelect}
          />
        ))}
      </SignalTypeWrap>
    ),
    [values.copy_from, values.signal_type, signalType]
  );
};

const SignalTypeWrap = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 12px;
  flex: 1;
  label {
    white-space: nowrap;
    user-select: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.isSingle &&
    `
    label {
      max-width: 33.33%
    }
  `}
`;

// End SignalType
