
import sliceDecimalFloat from './sliceDecimalFloat';
import formatCurrency from './formatCurrency';

const formatCurrencyBignumber = (number) => {
  if (!number) {
    return formatCurrency(0);
  }

  let unit = '';
  let divisor = 1;

  if (Math.abs(number) >= 1e12) {
    unit = 'T';
    divisor = 1e12;
  } else if (Math.abs(number) >= 1e9) {
    unit = 'B';
    divisor = 1e9;
  } else if (Math.abs(number) >= 1e6) {
    unit = 'M';
    divisor = 1e6;
  } else if (Math.abs(number) >= 1e3) {
    unit = 'k';
    divisor = 1e3;
  } else {
    return formatCurrency(sliceDecimalFloat(Math.abs(number)));
  }

  const quotient = sliceDecimalFloat(number / divisor);
  return `${formatCurrency(quotient)}${unit}`;
}

export default formatCurrencyBignumber;
