import React from 'react';
import { clearAuthority, login } from '@/redux/refactor/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import authApi from '@/utils/api/authApi';
import _ from 'lodash';
import generic from '@/utils/generic';
import { DEVICE_TOKEN } from '@/config/const';
import { toast } from 'react-toastify';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const selectUser = createSelector(
  (state) => state.user,
  (user) => user
);

const useAuth = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectUser, _.isEqual);
    
    const handleLogin = (params) => {
        dispatch(login(params));
    };
    const handleLogout = async () => {
        toast.promise(
            () =>
              authApi.updateUserSettingV2('device_token', '').finally(() => {
                // Marked as logged out
                const pwdless = generic.getJsonLocalStorage('pwdless') || {};
                pwdless.loggedout = true;
                generic.setJsonLocalStorage('pwdless', pwdless);

                generic.removeLocalStorage(DEVICE_TOKEN);
                dispatch(clearAuthority());

                ga4Stats(gtag.logout.action.logout, gtag.logout.category, gtag.logout.label);
              }),
            {
              pending: t('msg_signing_out', 'Signing out in progress...'),
              success: t(
                'msg_sign_out_success',
                'Your account has been successfully signed out!'
              ),
              error: t(
                'msg_sign_out_fail',
                'Sign-out failed. Please refresh the page and try again.'
              ),
            }
        );
    };

  return {
    isAuth: user.isAuth,
    login: handleLogin,
    logout: handleLogout,
  };
};

export default useAuth;
