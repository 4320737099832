import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { setDeviceToken, getDeviceToken } from './utils/helpers';
import authApi from './utils/api/authApi';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermission = async () => {
  // console.log('Requesting User Permission......');
	const permission = await Notification.requestPermission();
	// // console.log(permission);
	if (permission === 'granted') {
		// console.log('Notification User Permission Granted.');
		try {
      const newToken = await getToken(messaging, {
        vapidKey: `${process.env.REACT_APP_VAPI_KEY}`,
      });

      if (newToken) {
        const setting = await authApi.updateUserSettingV2('device_token', newToken);
        if (setting?.data?.ok === true) {
          setDeviceToken(newToken);
        }
        // console.log('Client Token: ', newToken);
      } else {
        console.log('Failed to generate the app registration token.');
      }
		} catch (error) {
			console.log(
				'An error occurred when requesting to receive the token.',
				error
			);
		}
	} else {
		console.log('User Permission Denied.');
	}

  // Notification.requestPermission().then((permission) => {
  //   if (permission === 'granted') {
  //     console.log('Notification User Permission Granted.');
  //     return getToken(messaging, {
  //       vapidKey: `${process.env.REACT_APP_VAPI_KEY}`,
  //     })
  //       .then((currentToken) => {
  //         if (currentToken) {
  //           localStorage.setItem('push_token', currentToken);

  //           console.log('Client Token: ', currentToken);
  //         } else {
  //           console.log('Failed to generate the app registration token.');
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(
  //           'An error occurred when requesting to receive the token.',
  //           err
  //         );
  //       });
  //   } else {
  //     console.log('User Permission Denied.');
  //   }
  // });
};

export const onMessageListener = () => {
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Received message foreground: ', payload);
      resolve(payload);
    });
  });
};
