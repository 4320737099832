import { forEach } from "lodash";

const languages = [
  {
    name: 'Tiếng Việt',
    flag: '/img/flags/vi.svg',
    code: 'vi',
  },
  {
    name: 'English',
    flag: '/img/flags/en.svg',
    code: 'en',
  },
  {
    name: 'ภาษาไทย',
    flag: '/img/flags/th.png',
    code: 'th',
  },
];

export const languageMap = languages.reduce((dict, item) => ({ ...dict, [item.code]: item }), {});
export const supportedLang = languages.reduce((acc, item) => [...acc, item.code], [])
export default languages;