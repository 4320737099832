import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import formatNumber from '@/shared/helpers/formatNumber';
import _ from 'lodash';
import { selectBalance } from '@/redux/selectors/walletSelector';
import { Button as BsButton } from 'react-bootstrap';
import {
  colorAccent,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale900,
  colorText,
} from '@/utils/palette';
import { ArrowSwapHorizontal } from 'iconsax-react';
import { TRANSFER_TYPES } from '@/config/const';
import CurrencyInput from '../../CurrencyInput';
import { Button } from '../../Button';

const FormTransfer = withTranslation()(({ onSubmit, loading, t }) => {
  const [transferType, setTransferType] = useState(TRANSFER_TYPES.IN);
  const [amount, setAmount] = useState(null);
  const [invalidAmount, setInvalidAmount] = useState(true);
  const { availableBalance, usdtAvailableBalance } = useSelector(
    selectBalance,
    _.isEqual,
  );
  const maxBalance = transferType === TRANSFER_TYPES.IN
      ? usdtAvailableBalance
      : availableBalance;
  const fromTitle = transferType === TRANSFER_TYPES.IN
      ? 'From USDT Wallet'
      : 'From Live Wallet';
  const toTitle = transferType === TRANSFER_TYPES.IN ? 'To Live Wallet' : 'To USDT Wallet';

  const handleChangeType = () => {
    setAmount(null);
    setTransferType(
      transferType === TRANSFER_TYPES.IN
        ? TRANSFER_TYPES.OUT
        : TRANSFER_TYPES.IN,
    );
  };
  const handleMax = () => {
    setAmount(
      transferType === TRANSFER_TYPES.IN
        ? usdtAvailableBalance
        : availableBalance,
    );
  };
  const handleInputChange = ({ target }) => {
    const { value } = target;
    if (value !== '') {
      const num = Number(value.replace('$', '').replaceAll(',', ''));
      setAmount(num);
    } else {
      setAmount(null);
    }
  };

  const handleSubmit = () => onSubmit({ transferType, amount });

  useEffect(() => {
    if (amount > 0 && amount <= maxBalance) {
      setInvalidAmount(false);
    } else {
      setInvalidAmount(true);
    }
  }, [amount, maxBalance]);
  return (
    <>
      <TransferType>
        <Box className="title">{t(fromTitle)}</Box>
        <Box flex={1} display="flex" justifyContent="center">
          <ButtonStyle onClick={handleChangeType} variant="outline" size="sm">
          <ArrowSwapHorizontal size={14} />
          </ButtonStyle>
        </Box>
        <Box className="title">{t(toTitle)}</Box>
      </TransferType>
      <CurrencyInputStyle
        value={amount}
        onChange={handleInputChange}
        placeholder="$0"
        type="text"
      />
      <BalanceBox onClick={handleMax}>
        <span>
          {t('Balance')}: {formatNumber.sliceDecimalFloat(maxBalance)}
        </span>
        <Max>{t('MAX')}</Max>
      </BalanceBox>
      <ButtonTransfer
        onClick={handleSubmit}
        disabled={invalidAmount || loading}
        isLoading={loading}
        variant="secondary"
        block="true"
      >
        {t(amount > maxBalance ? 'Insufficient Balance' : 'Transfer Now')}
      </ButtonTransfer>
    </>
  );
});

const TransferType = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  gap: 32px;
  .title {
    font-weight: 800;
    font-size: 20px;
    line-height: 140%;
    color: ${colorText};
  }
`;
const ButtonStyle = styled(BsButton)`
  padding: 0;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(207, 219, 213, 0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorText};
  &:hover {
    border-color: rgba(207, 219, 213, 0.7) !important;
  }
`;
const CurrencyInputStyle = styled(CurrencyInput)`
  border: none;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${colorText};
  background: transparent;
  &::placeholder {
    color: ${colorGreyScale600};
  }
`;

const BalanceBox = styled(Box)`
  user-select: none;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 41px;
  color: ${colorText};
  cursor: pointer;
  .balance {
    margin-bottom: 8px;
  }
`;

const Max = styled(Box)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  margin-left: 5px;
  color: ${colorAccent};
`;

const ButtonTransfer = styled(Button)`
  background: ${colorGreyScale900};
  border-radius: 12px;
  color: #fff;
  border:none;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: #fff !important;
    border:none;
    background: ${colorGreyScale700}!important;
  }
`;

export default FormTransfer;
