import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PLAN_STATUS } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import toastHandler from '@/shared/helpers/toastHandler';
import ButtonLoading from '@/shared/components/ButtonLoading';
import { selectSelectedPlans, selectSelectedEndedPlans, selectSelectedStoppedPlans } from '@/redux/selectors/planSelector';
import sleep from '@/shared/helpers/sleep';

import './index.scss';
const Img = require('@/assets/image/confirm-start.png');

const PopupConfirmStartPlan = ({ show, onHide, currentPlan, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const { selectedPlans, selectedEndedPlans, selectedStopedPlans } = useSelector(
    (state) => ({
      selectedPlans: selectSelectedPlans(state),
      selectedEndedPlans: selectSelectedEndedPlans(state),
      selectedStopedPlans: selectSelectedStoppedPlans(state)
    }),
    _.isEqual
  );

  const handleResumePlan = async () => {
    try {
      setIsLoading(true);

      const endedPlans = _.isEmpty(currentPlan) ? selectedEndedPlans : [currentPlan];
      const stoppedPlans = _.isEmpty(currentPlan) ? selectedStopedPlans : [currentPlan];

      for (let index = 0; index < endedPlans.length; index++) {
        await toastHandler(
          t,
          'reset_today_profit',
          () => planApi.resetTodayProfit(endedPlans[index].id),
          t('Reset the today profit for plan: {{planName}} successfully!', {planName: endedPlans[index].name}),
        );
        await sleep(100);

        const indexEndedPlanInStoppedPlans = _.findIndex(stoppedPlans, p => p.id === endedPlans[index].id);
        if (indexEndedPlanInStoppedPlans > -1) {
          await toastHandler(
            t,
            'update_status_plan',
            () => planApi.updateStatus(endedPlans[index].id, PLAN_STATUS.running),
            t('Restart the plan: {{planName}} successfully!', {planName: endedPlans[index].name}),
          );
          await sleep(100);
          stoppedPlans.splice(indexEndedPlanInStoppedPlans, 1)
        }        
      }

      for (let index = 0; index < stoppedPlans.length; index++) {
        await toastHandler(
          t,
          'update_status_plan',
          () => planApi.updateStatus(stoppedPlans[index].id, PLAN_STATUS.running),
          t('Restart the plan: {{planName}} successfully!', {planName: stoppedPlans[index].name}),
        );
        await sleep(100);
      }
      
      onSuccess();
      setIsLoading(false);
      onHide();
    } catch (error) {
      console.log(`Handle resume plan error: ${error}`);
    }
  };

  if (!show) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="popup-confirm-start-plan"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <img src={Img} alt="" className="mb-2" />

        <p className="mb-3 modal-title">
          {t('This plan has reached the daily target')}
        </p>
        <p className="text">
          {t(
            'Click the button below to reset your daily target and begin investing right now.'
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div className="d-grid" style={{ width: '100%' }}>
          {!isLoading && (
            <Button variant="success" onClick={handleResumePlan}>
              {t('Reset & start now')}
            </Button>
          )}
          {isLoading && <ButtonLoading variant="success" />}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

PopupConfirmStartPlan.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PopupConfirmStartPlan;
