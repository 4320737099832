import { useContext, useMemo } from 'react';
import { StyledChipItem, StyledChipWrap, StyledSelectedOption } from './styles';
import Icons from '../icons';
import { TelegramSelectContext } from './context';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTelegramChannels } from '@/redux/refactor/common/selector';
import { isEqual } from 'lodash';

export function SelectedChannels() {
  const { t } = useTranslation();
  const { canSelect, handleItemSelect } = useContext(TelegramSelectContext);
  const final = mapSelectedChannel();
  if (!canSelect) return null;
  return (
    <StyledSelectedOption>
      <h5>{t('Selected channel')}</h5>
      <StyledChipWrap>
        {final.map((item) => (
          <ChipComponent value={item} key={item.id} onRemove={handleItemSelect} />
        ))}
      </StyledChipWrap>
    </StyledSelectedOption>
  );
}

function ChipComponent({ value, onRemove }) {
  const handleRemove = () => onRemove(value);
  return (
    <StyledChipItem>
      <span>{value.name}</span>
      <span className="cursor-pointer" onClick={handleRemove}>
        <Icons.CloseIcon className="close-icon" />
      </span>
    </StyledChipItem>
  );
}

function mapSelectedChannel() {
  const data = useSelector(selectTelegramChannels, isEqual);
  const { selected = [] } = useContext(TelegramSelectContext);
  return useMemo(() => {
    const fullData = [];
    // Loop over smaller array
    for (let i = 0; i < selected.length; i++) {
      const foundItem = data.find((item) => item.id === selected[i]);
      if (foundItem) {
        fullData.push(foundItem);
      }
    }
    return fullData;
  }, [data, selected]);
}
