import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const TextInvalid = ({ validObj }) =>
  validObj &&
  !validObj.isValid && (
    <Form.Text
      className="text-danger"
      dangerouslySetInnerHTML={{
        __html: validObj.text,
      }}
    />
  );

TextInvalid.propTypes = {
  validObj: PropTypes.instanceOf(Object).isRequired,
};

export default TextInvalid;
