/* eslint-disable react/prop-types */
import { BET_TYPE } from '@/shared/constants';
import { colorGreyScale500, colorSuccess, colorText } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CollapseStyle, FieldWaitSignalStyle, FormCheckbox, SubTitle } from './FormElements';
import SettingItem from './SettingItem';
import { isInteger } from '../validator';

const TextWait = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: ${colorText};
`;

const WrapOptions = styled.div`
  position: relative;
  padding-top: 24px;
  padding-left: 50px;
  margin-top: 20px;
  color: ${colorText};
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    border-left: 2px dotted ${colorGreyScale500};
    position: absolute;
    left: 18px;
    top: -18px;
  }
  &.option-a {
    &:after {
      content: '';
      height: 50%;
      width: 1px;
      border-left: 2px dotted ${colorSuccess};
      position: absolute;
      left: 18px;
      top: -18px;
    }
  }
  &.option-b {
    &:after {
      content: '';
      height: 100%;
      width: 1px;
      border-left: 2px dotted ${colorSuccess};
      position: absolute;
      left: 18px;
      top: -18px;
    }
  }
  > div {
    position: relative;
    &:before {
      content: '';
      width: 25px;
      height: 1px;
      border-top: 2px dotted ${colorGreyScale500};
      position: absolute;
      left: -32px;
      top: 50%;
    }
    &:first-child {
      margin-bottom: 32px;
    }
    &.active {
      &:after {
        content: '';
        width: 25px;
        height: 1px;
        border-top: 2px dotted ${colorSuccess};
        position: absolute;
        left: -32px;
        top: 50%;
      }
    }
    &.inactive {
      * {
        opacity: 0.8;
      }
      .form-check {
        opacity: 1;
      }
    }
  }
`;

// Begin WaitSignal
export default withTranslation()(({ show, mutators, t, value, waitSignalType }) => {
  if (!show) return null;

  return (
    <>
      <SettingItem
        name="wait_signal"
        title={t('FormCreatePlan_advanced_waitSignal_label')}
        desc={t('FormCreatePlan_advanced_waitSignal_desc')}
      />
      <CollapseStyle in={value} unmountOnExit>
        <Box>
          <SelectWaitSignalType value={waitSignalType} />
          <SetStreak waitSignalType={waitSignalType} mutators={mutators} />
        </Box>
      </CollapseStyle>
    </>
  );
});

const SelectWaitSignalType = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Box mt="41px" className="radio-tab mb-3">
      <Field
        name="waitSignalType"
        id="inline-win"
        label={t('Win')}
        component={FormCheckbox}
        type="radio"
        // defaultValue="true"
        value={BET_TYPE.win}
        className={BET_TYPE.win === value ? 'active' : ''}
      />
      <Field
        name="waitSignalType"
        id="inline-lose"
        label={t('Lose')}
        component={FormCheckbox}
        type="radio"
        value={BET_TYPE.lose}
        className={BET_TYPE.lose === value ? 'active' : ''}
      />
    </Box>
  );
};
const SetStreak = withTranslation()(({ waitSignalType, mutators, t }) => {
  const { values } = useFormState();
  return (
    <div>
      <div className="d-flex align-items-center">
        <TextWait className="pe-2">{t('Wait')}</TextWait>
        {waitSignalType === BET_TYPE.win && (
          <StreakInput label={t('Win Streak')} name="win_streak" placeholder="0" />
        )}
        {waitSignalType === BET_TYPE.lose && (
          <StreakInput label={t('Lose Streak')} name="lose_streak" placeholder="0" />
        )}
      </div>
      <WrapOptions className={values.isUltilFinishBudget === 'a' ? 'option-a' : 'option-b'}>
        <div
          className={`d-flex align-items-center ${
            values.isUltilFinishBudget === 'a' ? 'active' : 'inactive'
          }`}
        >
          <Field
            name="isUltilFinishBudget"
            label=""
            component={FormCheckbox}
            type="radio"
            value="a"
          />
          <span className="ps-1">{t('when ultil finish budget')}</span>
        </div>
        <div
          className={`d-flex align-items-center ${
            values.isUltilFinishBudget === 'b' ? 'active' : 'inactive'
          }`}
        >
          <Field
            name="isUltilFinishBudget"
            label=""
            component={FormCheckbox}
            type="radio"
            value="b"
          />
          <div className="d-flex align-items-center">
            <span className="pe-2 ps-1">{t('bet in')}</span>
            <StreakInput
              label={t('consecutive bet')}
              name="no_of_entries"
              placeholder="0"
              labelNormal
              disabled={values.isUltilFinishBudget === 'a'}
            />
          </div>
        </div>
      </WrapOptions>
    </div>
  );
});

const StreakInput = ({ label, name, placeholder, labelNormal, disabled = false }) => (
  <Box className="d-flex align-items-center">
    <FieldWaitSignalStyle
      name={name}
      component="input"
      type="number"
      min="0"
      placeholder={placeholder}
      disabled={disabled}
      validate={isInteger}
    />
    <SubTitle className={`mb-0 ps-2 ${labelNormal ? 'fw-normal' : ''}`}>{label}</SubTitle>
  </Box>
);
// End WaitSignal
