import { CURRENT_TIMEZONE_STRING } from '@/config/const';
import ModalSettings, {
  ModalSettingBodyStyle,
  ModalSettingFooterStyle,
} from '@/containers/Settings/components/ModalSettings';
import { Button } from '@/shared/components/Button';
import {
  borderDefaultStyle,
  colorGreyScale200,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale700,
  colorGreyScale800,
  colorGreyScale900,
  colorInputBackground,
  colorInputText,
  colorText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styled-theming';

const getDecimalString = (number) => {
  return number < 10 ? '0' + number : number.toString();
};
const getDefaultTimeRange = (fromDate, toDate) => {
  if (fromDate && toDate) {
    return [
      `${getDecimalString(fromDate.getHours())}:${getDecimalString(fromDate.getMinutes())}`,
      `${getDecimalString(toDate.getHours())}:${getDecimalString(toDate.getMinutes())}`,
    ];
  }

  const current = new Date();
  const last4H = new Date();
  current.setMinutes(current.getMinutes() - 1);
  last4H.setMinutes(current.getMinutes() - 240);
  if (last4H.getDate() < current.getDate()) {
    return [
      `00:00`,
      `${getDecimalString(current.getHours())}:${getDecimalString(current.getMinutes())}`,
    ];
  }
  return [
    `${getDecimalString(last4H.getHours())}:${getDecimalString(last4H.getMinutes())}`,
    `${getDecimalString(current.getHours())}:${getDecimalString(current.getMinutes())}`,
  ];
};

const isDateWithinLastDays = (date, startDate, validDays) => {
  if (!validDays) return true;
  const validDaysAgo = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() - validDays
  );

  return date > validDaysAgo && date <= startDate;
};

export function ModalCalendar({
  open,
  values,
  onConfirm,
  onClose,
  isShowTimeRange,
  defaultDate = new Date(),
  validDays = 0,
}) {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(defaultDate);
  const [fromTime, setFromTime] = useState('00:00');
  const [toTime, setToTime] = useState('00:00');
  const [errorMsg, setErrorMsg] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const handleDateChange = (value) => {
    setDate(value);
  };
  const handleFromChange = (e) => {
    setFromTime(e.target.value);
  };
  const handleToChange = (e) => {
    setToTime(e.target.value);
  };
  const handleConfirm = () => {
    onConfirm({ date, fromTime, toTime });
  };

  useEffect(() => {
    if (open) {
      const [from, to] = getDefaultTimeRange(values.fromDate, values.toDate);
      setFromTime(from);
      setToTime(to);
    }
  }, [open]);

  useEffect(() => {
    setErrorMsg(null);
    setDisabled(false);
    if (!fromTime || !toTime) {
      setDisabled(true);
    }

    const format = 'HH:mm';
    const from = moment(fromTime, format);
    const to = moment(toTime, format);
    const duration = moment.duration(to.diff(from));
    const minutesDiff = duration.asMinutes();
    if (minutesDiff <= 0 || minutesDiff > 60 * 24) {
      setDisabled(true);
      setErrorMsg('TimeRange_FromTime_Over_ToTime_Error');
    }
  }, [fromTime, toTime]);

  return (
    <CustomModal title={t('Select time')} maxWidth={'450px'} isOpen={open} closeModal={onClose}>
      <ModalSettingBodyStyle>
        <CustomCalendar
          defaultValue={date}
          onChange={handleDateChange}
          locale={i18n.language}
          tileDisabled={({ date, view }) =>
            view === 'month' && !isDateWithinLastDays(date, defaultDate, validDays)
          }
        />
      </ModalSettingBodyStyle>
      <CustomFooter>
        {isShowTimeRange && (
          <TimeRange
            from={fromTime}
            to={toTime}
            onFromChange={handleFromChange}
            onToChange={handleToChange}
          />
        )}
        <Box flex={1} padding="8px">
          <Button
            disabled={disabled}
            onClick={handleConfirm}
            variant={errorMsg ? 'danger' : 'success'}
            block="true"
          >
            {errorMsg ? t(errorMsg) : t('Confirm')}
          </Button>
        </Box>
      </CustomFooter>
    </CustomModal>
  );
}

function TimeRange({ from, to, onFromChange, onToChange }) {
  const { t } = useTranslation();
  const timezone = CURRENT_TIMEZONE_STRING;
  return (
    <TimeInputGroup>
      <div className="input-wrap">
        <label>
          {t('From')} {timezone}
        </label>
        <div>
          <input onChange={onFromChange} value={from} type="time" placeholder="-:-" />
        </div>
      </div>
      <div className="input-wrap">
        <label>
          {t('To')} {timezone}
        </label>
        <div>
          <input onChange={onToChange} value={to} type="time" placeholder="-:-" />
        </div>
      </div>
    </TimeInputGroup>
  );
}

const CustomModal = styled(ModalSettings)`
  .modal-content {
    .modal-header {
      border: none !important;
    }
  }
`;

const CustomFooter = styled(ModalSettingFooterStyle)`
  padding: 0 8px 8px;
  align-items: flex-end;
  display: block;
`;

const CustomCalendar = styled(Calendar)`
  width: 100% !important;
  border: none !important;
  background: transparent !important;
  color: ${colorText};

  button {
    color: ${colorText};
  }
  .react-calendar__tile {
    border-radius: 8px;
    color: ${colorText};
    &:disabled {
      background-color: transparent;
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active {
    background-color: #ceefdf !important;
    border-radius: 8px !important;
    color: #0ba259 !important;
    font-weight: bold !important;
  }
  .react-calendar__tile--now {
    color: #0ba259 !important;
    background-color: transparent !important;
    font-weight: bold !important;
    opacity: 1 !important;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    border-radius: 8px;
    background: ${theme('mode', {
      light: colorGreyScale300,
      dark: colorGreyScale900,
    })};
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: ${theme('mode', {
      light: colorGreyScale300,
      dark: colorGreyScale900,
    })};
  }
`;

const TimeInputGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .input-wrap {
    padding: 8px;
    flex: 1;
    input {
      width: 100%;
      background: ${colorInputBackground};
      color: ${colorInputText};
      border-radius: 5.68627px;
      border: none;
      padding: 10px 16px;
      font-weight: 600;
      font-size: 17.0588px;
      color-scheme: ${theme('mode', {
        light: 'light',
        dark: 'dark',
      })};
    }
    label {
      font-weight: 600;
      font-size: 14px;
      color: ${colorGreyScale500};
      margin-bottom: 5px;
      white-space: nowrap;
    }
  }
  .separator {
    border-right: ${borderDefaultStyle};
    height: 100px;
  }
`;
