import { VIP_BACKGROUND } from '@/config/const';
import { stringAvatar } from '@/shared/helpers';
import { getApiUrl } from '@/utils/api/base/axios';
import {
  colorGreyScale300,
  colorGreyScale600,
  colorSuccess,
  colorText,
  Devices,
} from '@/utils/palette';
import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const TopbarUserInfoItem = () => {
  const [imgError, setImgError] = useState(false);
  const { profile = {}, rank = 0 } = useSelector(
    (state) => ({
      profile: state.user.profile,
      rank: 0,
    }),
    isEqual
  );
  const name =
    profile?.fn && profile?.fn ? `${profile?.fn} ${profile?.ln}` : profile?.nn;
  const handleError = () => {
    setImgError(true);
  };
  if (!profile) return null;
  return (
    <UserInfoWrap>
      <AvatarWrap rank={rank}>
        {(!profile.photo || imgError) && (
          <AvatarStyle className="avatar">{stringAvatar(name)}</AvatarStyle>
        )}
        {profile.photo && !imgError && (
          <AvatarImageStyle
            className="avatar"
            src={getApiUrl(profile.photo)}
            onError={handleError}
          />
        )}
        {rank > 0 && <div className="rank__text">VIP {rank}</div>}
      </AvatarWrap>
      <TopbarLeftContentWrap>
        <Title>{profile?.nn}</Title>
        <SubTitle>#{profile?.uid}</SubTitle>
      </TopbarLeftContentWrap>
    </UserInfoWrap>
  );
};

const UserInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const TopbarLeftContentWrap = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  @media ${Devices.laptop} {
    display: flex;
  }
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${colorText};
  margin-bottom: 4px;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: ${colorGreyScale600};
`;

const AvatarWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 16px;
  border-radius: 32px;
  .rank__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;
    text-shadow: 0px 0px 2.66667px rgba(0, 0, 0, 0.25);
  }
  ${(props) =>
    props.rank > 0 &&
    `
    background: ${VIP_BACKGROUND[props.rank - 1]};
    padding-right: 8px;
    .avatar {
      border: 1.5px solid #8EA4C9;
      box-shadow: inset 0px 0px 0px 1px #fff;
    }
  `}
`;

const AvatarStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${colorSuccess};
  border-radius: 100%;
  font-weight: 800;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  @media ${Devices.laptop} {
    width: 48px;
    height: 48px;
    font-size: 16px;
  }
`;

const AvatarImageStyle = styled(Image)`
  width: 34px;
  height: 34px;
  background: ${colorGreyScale300};
  border-radius: 100%;
  padding: 1px;
  @media ${Devices.laptop} {
    width: 48px;
    height: 48px;
  }
`;

export default TopbarUserInfoItem;
