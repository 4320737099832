import { Devices, colorText, borderDefaultStyle } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import { memo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { getCommunityBotMessages, setTelegramMessage } from '@/redux/refactor/common/actions';
import { timeFormat } from '@/shared/helpers';

import Nodata from '../Nodata';
import SkeletonLoadingMessage from './SkeletonLoading/SkeletonLoadingMessage';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

export const MessageBox = memo(() => {
  const dispatch = useDispatch();

  const { telegramMessages = [], messageLoading, currentChannel, isCommunitySignal } = useSelector((state) => state.common, isEqual);
  const ref = useRef(null);
  const contentRef = useRef(null);
  const mouseOver = useRef(false);

  useEffect(() => {
    return () => {
      dispatch(setTelegramMessage([]));
    }
  }, []);

  useEffect(() => {
    if (ref.current && contentRef.current && !mouseOver.current) {
      ref.current.scroll({
        top: contentRef.current.clientHeight,
      });
    }
  }, [telegramMessages, ref.current, contentRef.current]);

  const handleMountOver = () => {
    mouseOver.current = true;
  };

  const handleMountLeave = () => {
    mouseOver.current = false;
  };

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop === 0 && isCommunitySignal) {
      dispatch(getCommunityBotMessages(currentChannel))
    }
  };

  return (
    <>
      {messageLoading && !telegramMessages.length && <SkeletonLoadingMessage />}

      {!!telegramMessages.length && (
        <StyledMessageBox ref={ref} onMouseOver={handleMountOver} onMouseLeave={handleMountLeave} onScroll={handleScroll} className='message-box'>
          {messageLoading && telegramMessages.length && <SkeletonLoadingMessage />}
          
          <Box ref={contentRef} display="flex" flexDirection="column">
            {telegramMessages.map((message, key) => (
              <motion.div
                {...motionProps}
                key={key}                
                style={{ fontVariantNumeric: 'tabular-nums' }}
                className="message-item"
              >
                <div className="message-item-content" dangerouslySetInnerHTML={{ __html: isCommunitySignal ? message?.message?.replaceAll('\n', '<br />') : message }}/>
                {isCommunitySignal && <span className='message-item-time'>{timeFormat(message?.createdAt, 'HH:mm:ss')}</span>}
              </motion.div>
            ))}
          </Box>
        </StyledMessageBox>
      )}
    </>
  );
});

const StyledMessageBox = styled.div`
  flex: 1;
  overflow-y: auto;
  line-height: 200%;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 14px;
  .message-item {
    color: ${colorText};
    border: ${borderDefaultStyle};
    border-radius: 8px;
    margin: 16px 0 0 0;
    padding: 8px;
    position: relative;
    &:first-child {
      margin-top: 0;
    }
    .message-item-time {
      color: ${colorText};
      position: absolute;
      right: 12px;
      bottom: 8px;
    }
  }
`;

const StyledNodata = styled(motion.div)`
  @media screen and (max-width: 1023px) {
    .no-data {
      padding: 0;
    }
  }
`;
