import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { colorGreyScale600 } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormHint } from '@/shared/components/portfolio/form/components/FormElements';
import { Form } from 'react-bootstrap';
import { FORM_TYPE } from '@/shared/constants';

export default function SkipCandleBoard({ enableSkip, order, orderType, onChange, formType }) {
  const { t } = useTranslation();
  const handleEnableSkip = (evt) => {
    onChange({ enableSkip: evt.target.checked });
  };
  const handleChangeOrder = (value) => {
    onChange({ order: value });
  };
  const handleChangeOrderType = (evt) => {
    onChange({ orderType: evt.target.value });
  };

  return (
    <StyledFieldGroup>
      <StyledCheck checked={enableSkip} onChange={handleEnableSkip} disabled={formType === FORM_TYPE.read_only} id="enable-skip-board" />
      <StyledLabel display="flex" flexWrap="wrap" gridGap={8} alignItems="center">
        <label className="m-0 user-select-none cursor-pointer" htmlFor="enable-skip-board">
          <StyledFormHint>{t('Skip the rest of board 5 when:')}</StyledFormHint>
        </label>
        <StyledSelect
          value={orderType}
          onChange={handleChangeOrderType}
          className="form-select width-fit-content"
          disabled={!enableSkip}
          // disabled
        >
          <option value="WIN_STREAK">{t('WIN')}</option>
          <option value="LOSE_STREAK">{t('LOSE')}</option>
          <option value="WIN">{t('WIN STREAK')}</option>
          <option value="LOSE">{t('LOSE STREAK')}</option>
          <option value="MAX_ORDERS">{t('MAX ORDERS')}</option>
        </StyledSelect>
        <InputNumberGroup
          input={{
            onChange: handleChangeOrder,
            value: order,
            placeholder: '0',
          }}
          min={1}
          max={9}
          disabled={!enableSkip}
          inputType="text"
        />
        <StyledFormHint color={colorGreyScale600}>{order === 1 ? t('order') : t('orders')}</StyledFormHint>
      </StyledLabel>
    </StyledFieldGroup>
  );
}

const StyledFieldGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StyledSelect = styled.select`
  height: 30px;
  padding: 0 32px 0 13px;
  border-radius: 6px;
  margin-right: 4px;
  &:disabled {
    background-image: none;
    padding: 0 13px;
  }
`;
const StyledLabel = styled(Box)`
  input {
    width: 40px;
    height: 26px;
  }
  button {
    width: 26px;
    height: 26px;
  }
`;
const StyledFormHint = styled(FormHint)`
  font-size: 16px;
`;

const StyledCheck = styled(Form.Check)``;
