import useAuth from '@/hooks/useAuth';
import { useEffect } from 'react';
import { requestPermission } from '../../firebase';

function PushNotification() {
  const { isAuth } = useAuth();
  // const [notification, setNotification] = useState({ title: '', body: '' });
  useEffect(() => {
    if (isAuth) {
      requestPermission();
    }

    // const unsubscribe = onMessageListener().then((payload) => {
    //   console.log("Push Notification: receive message foreground", payload);
    // setNotification({
    //   title: payload?.notification?.title,
    //   body: payload?.notification?.body,
    // });
    // toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
    //   duration: 5000,
    //   position: 'top-right',
    // });
    // });
    // return () => {
    //   unsubscribe.catch((err) => console.log('failed: ', err));
    // };
  }, [isAuth]);
  return;
}
export default PushNotification;
