const { createGlobalStyle } = require('styled-components');

const CustomStyle = createGlobalStyle`
    .full-width {
        width: 100%;
    }
    .modal-backdrop.show {
        opacity: 0.4;
    }
    .modal.show {
        @supports(backdrop-filter: blur(5px)) {
            backdrop-filter: blur(5px);
        }
    }
    .spin {
        animation-name: rotate;
        animation-duration: 500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    @keyframes rotate {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
`;

export default CustomStyle;
