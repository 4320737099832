import Tooltip from '@/shared/components/Tooltip';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { Grid } from '@/shared/components/form/drawer/Elements';
import { Box } from '@material-ui/core';
import { InfoCircle } from 'iconsax-react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormHintStyle, FormLabelStyle } from './SettingItem';

export default function ResetBudgetConfig() {
  const { t } = useTranslation();
  return (
    <StyledGrid className="reset-config">
      <Grid.Row>
        <Grid.Cell>
          <FormLabelStyle>
            {t('FrmCreatePlan_ResetBudgetConfig_title')}
          </FormLabelStyle>
          <FormHintStyle>
            {t('FrmCreatePlan_ResetBudgetConfig_subTitle')}
          </FormHintStyle>
        </Grid.Cell>
      </Grid.Row>
      <Grid.Row cols={3}>
        <Grid.Cell>
          <Box
            display="flex"
            alignItems="center"
            gridGap={6}
            className="checkbox-style2-wrap"
          >
            <Field
              name="reset_budget_at_tp_sl"
              render={renderCheckBoxField}
              label={t('ResetBudgetConfig_TPSL')}
              type="checkbox"
            />
            <Tooltip text={t('ResetBudgetConfig_TPSL_tooltip')}>
              <InfoCircle size={18} />
            </Tooltip>
          </Box>
        </Grid.Cell>
        <Grid.Cell>
          <Box
            display="flex"
            alignItems="center"
            gridGap={6}
            className="checkbox-style2-wrap"
          >
            <Field
              name="reset_budget_at_resume"
              render={renderCheckBoxField}
              label={t('ResetBudgetConfig_Resume')}
              type="checkbox"
            />
            <Tooltip text={t('ResetBudgetConfig_Resume_tooltip')}>
              <InfoCircle size={18} />
            </Tooltip>
          </Box>
        </Grid.Cell>
        <Grid.Cell>
          <Box
            display="flex"
            alignItems="center"
            gridGap={6}
            className="checkbox-style2-wrap"
          >
            <Field
              name="reset_budget_at_reprofit"
              render={renderCheckBoxField}
              label={t('ResetBudgetConfig_ResetProfit')}
              type="checkbox"
            />
            <Tooltip text={t('ResetBudgetConfig_ResetProfit_tooltip')}>
              <InfoCircle size={18} />
            </Tooltip>
          </Box>
        </Grid.Cell>
      </Grid.Row>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)`
  .CheckboxLabel {
    font-size: 14px;
    padding-left: 30px;
  }
`;
