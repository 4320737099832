import React, {  useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Form as BsForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { updateCurrentBot, updateFormTypeBot } from '@/redux/actions/botActions';
import Tooltip from '@/shared/components/Tooltip';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { FORM_TYPE, SIGNAL_STRATEGY_TYPE, STRATEGY_TYPE, OPTIONS_MATCH_ORDER } from '@/shared/constants';
import { validateText } from '@/shared/helpers';
import useBotManagement from './useBotManagement';
import getSignalExpert from './getSignalExpert';

import { MethodExplain } from './components/MethodExplain';
import InputSignalName from './components/InputSignalName';
import SelectSignalType from './components/SelectSignalType';
import SelectTypeOrder from './components/SelectTypeOrder';
import InputTagPattern from './components/InputTagPattern';
import InputTagExpert from './components/InputTagExpert';
import SelectSignalMethod from './components/SelectSignalMethod';
import FormBotFooter from './components/FormBotFooter';

import './index.scss';
import { TOTAL_BOT_MIX } from '@/config/const';

const GridItem = ({ children }) => (
  <Grid.Row>
    <Grid.Cell>{children}</Grid.Cell>
  </Grid.Row>
);

const FormBot = ({
  onClose,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const { currentBot, formType, optionsType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
      optionsType: state.bot.optionsType,
    }),
    isEqual
  );
  
  const { t } = useTranslation();
  const { createBot, editBot, isLoading } = useBotManagement();
  const [botName, setBotName] = useState('');
  const [botValue, setBotValue] = useState([]);
  const [expertValue, setExpertValue] = useState([]);
  const [botSignalStrategyType, setBotSignalStrategyType] = useState('');
  const [botMethodUsing, setBotMethodUsing] = useState({});
  const [optionMatchOder, setOptionMatchOder] = useState(OPTIONS_MATCH_ORDER.byMajority);
  const [lastInputTagValue, setLastInputTagValue] = useState('');
  const [lastInputTagExpertValue, setLastInputTagExpertValue] = useState('');
  const [validBotName, setValidBotName] = useState({
    isValid: !(formType === FORM_TYPE.create),
    text: '',
  });
  const [validPattern, setValidPattern] = useState({
    isValid: !(formType === FORM_TYPE.create),
    text: '',
  });
  const [isValidForm, setIsValidForm] = useState(!(formType === FORM_TYPE.create));

  const checkValidBotName = (value) => {
    const text = value;
    if (text.length > 50 || !validateText(text)) {
      setValidBotName({ isValid: false, text: t('Bot name is invalid') });
    } else {
      setValidBotName({ isValid: true, text: '' });
    }
  };

  const handleChangeBotName = (event) => {
    setBotName(event.target.value);
    checkValidBotName(event.target.value);
  };

  const handleDeleteBotValue = (i) => {
    setBotValue(botValue.filter((tag, index) => index !== i));
  };

  const handleDeleteExpertValue = (i) => {
    setExpertValue(expertValue.filter((tag, index) => index !== i));
  };

  const handleAdditionBotValue = (tag) => {
    setBotValue([...botValue, tag]);
    setLastInputTagValue('');
  };

  const handleAdditionExpertValue = (tag) => {
    if (botMethodUsing.length + expertValue.length < TOTAL_BOT_MIX) {
      setExpertValue([...expertValue, tag]);
      setLastInputTagExpertValue('');
    }
  };

  const handleChangeLastExpertValue = (text) => {
    if (botMethodUsing.length + expertValue.length < TOTAL_BOT_MIX) {
      setLastInputTagExpertValue(text);
    }
  };

  const saveBot = () => {
    const {sources, experts} = currentBot?.copy_from ?
      {sources: [], experts: []} :
      getSignalExpert(botSignalStrategyType, botMethodUsing, expertValue, lastInputTagExpertValue, botValue, lastInputTagValue);

    formType === FORM_TYPE.create || currentBot.is_customize
      ? createBot(botName, botSignalStrategyType, sources, experts, optionMatchOder, onClose)
      : editBot(currentBot.id, botName, botSignalStrategyType, sources, experts, optionMatchOder, onClose);
  };

  const handleCustomize = () => {
    dispatch(updateFormTypeBot(FORM_TYPE.create));
    setBotName('');
    checkValidBotName('');
    dispatch(updateCurrentBot({
      is_customize: true,
      type: currentBot.type,
      strategy: currentBot.strategy,
      sources: currentBot.sources,
    }));
    setTimeout(() => inputRef.current.focus(), 100);
  };

  useEffect(() => {
    if (currentBot.id || currentBot.is_customize) {
      setBotName(currentBot.name);
      if (currentBot.strategy === STRATEGY_TYPE.string) {
        const patterns = currentBot.sources?.map((pattern) => pattern.source);
        if (patterns.length) {
          setBotValue(
            patterns.map((tag) => ({
              id: tag,
              text: tag,
            }))
          );
        }
      }

      if (currentBot.strategy === STRATEGY_TYPE.mix) {
        const expertsName = currentBot.sources
          .filter((s) => s.type === SIGNAL_STRATEGY_TYPE.expert)
          .map((source) => source.source);

        const optionName = currentBot.sources
          .filter((s) => s.type === 'OPTION')
          .map((source) => source.source);

        if (expertsName.length) {
          setExpertValue(
            expertsName.map((tag) => ({
              id: tag,
              text: tag,
            }))
          );
        }

        if (optionName && optionName.length) {
          setOptionMatchOder(optionName[0]);
        }
      }
      
      setBotSignalStrategyType(currentBot.strategy);
    } else {
      setBotName('');
      setBotValue([]);
      setExpertValue([]);
      setOptionMatchOder(OPTIONS_MATCH_ORDER.byMajority);
      setBotSignalStrategyType(optionsType[0]?.value);
    }
  }, [currentBot]);

  useEffect(() => {
    if (botSignalStrategyType === STRATEGY_TYPE.string) {
      if (!currentBot.copy_from && validBotName.isValid && validPattern.isValid) {
        setIsValidForm(true);
      } else if (currentBot.copy_from && validBotName.isValid) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    } else if (validBotName.isValid) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }, [validBotName, validPattern, botSignalStrategyType]);

  useEffect(() => {
    if (
      botValue.length ||
      (!botValue.length && (lastInputTagValue.length || lastInputTagExpertValue.length))
    ) {
      setValidPattern({ isValid: true, text: '' });
    } else {
      setValidPattern({ isValid: false, text: t('Pattern is empty') });
    }
  }, [botValue, lastInputTagValue, lastInputTagExpertValue]);

  return (
    <>
      <Form.Header onClose={onClose} />
      <Form.Body id="form-bot">
        <Form.Headline>{t('Set up your Bot AI')}</Form.Headline>
        <Grid>
          <GridItem>
            <InputSignalName
              inputRef={inputRef}
              isDefaultBot={currentBot.is_default}
              botName={botName}
              formType={formType}
              validBotName={validBotName}
              handleChangeBotName={handleChangeBotName}
            />
          </GridItem>

          <GridItem>
            <SelectSignalType
              botSignalStrategyType={botSignalStrategyType}
              setBotSignalStrategyType={setBotSignalStrategyType}
            />
          </GridItem>

          {!currentBot.copy_from && (
            <>
              <GridItem>
                {botSignalStrategyType !== STRATEGY_TYPE.string && (
                  <BsForm.Group className="full-width">
                    <Form.Label className="d-flex align-items-center">
                      3. {t('Method using')}:
                      <Tooltip
                        text={t('Please select the suitable method below to set up your Bot AI.')}
                      />
                    </Form.Label>

                    <SelectSignalMethod
                      botSignalStrategyType={botSignalStrategyType}
                      botMethodUsing={botMethodUsing}
                      expertValue={expertValue}
                      setBotMethodUsing={setBotMethodUsing}
                    />

                    {botSignalStrategyType === STRATEGY_TYPE.mix && (
                      <div
                        className={classNames({
                          hidden: currentBot.is_default && expertValue.length === 0,
                        })}
                      >
                        <InputTagExpert
                          expertValue={expertValue}
                          handleDeleteExpertValue={handleDeleteExpertValue}
                          handleAdditionExpertValue={handleAdditionExpertValue}
                          handleChangeLastExpertValue={handleChangeLastExpertValue}
                        />

                        <SelectTypeOrder
                          optionMatchOder={optionMatchOder}
                          setOptionMatchOder={setOptionMatchOder}
                          isDefaultBot={currentBot.is_default || false}
                          formType={formType}
                        />
                      </div>
                    )}

                    {botSignalStrategyType === STRATEGY_TYPE.mix && (
                      <BsForm.Text className="mt-2 block">
                        {t('A maximum of {{max}} methods can be combined', { max: TOTAL_BOT_MIX })}
                      </BsForm.Text>
                    )}
                  </BsForm.Group>
                )}
                {botSignalStrategyType === STRATEGY_TYPE.string && (
                  <InputTagPattern
                    botValue={botValue}
                    handleDeleteBotValue={handleDeleteBotValue}
                    handleAdditionBotValue={handleAdditionBotValue}
                    setLastInputTagValue={setLastInputTagValue}
                  />
                )}
              </GridItem>
              <MethodExplain type={botSignalStrategyType} method={botMethodUsing} />
            </>
          )}
        </Grid>
      </Form.Body>
      <FormBotFooter
        isValidForm={isValidForm}
        isLoading={isLoading}
        onClose={onClose}
        saveBot={saveBot}
        allowCustomize={formType === FORM_TYPE.edit}
        handleCustomize={handleCustomize}
      />
    </>
  );
};

FormBot.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FormBot;
