export const fido = {
  nicelyMessage: 'This feature is not well-supported on your device',
  serverUrl: process.env.REACT_APP_API_URL + '/v1/pwdless',
  detectFIDOSupport: function (storage) {

    let browserSupport =  typeof window.PublicKeyCredential === 'function';
    if (!storage) return browserSupport;

    // Handle already turn-on case
    let pwdless = storage.getJsonLocalStorage('pwdless') || {};
    return browserSupport && pwdless && pwdless.email;

  },
  coerceToArrayBuffer: function (thing, name) {
    if (typeof thing === 'string') {
      // base64url to base64
      thing = thing.replace(/-/g, '+').replace(/_/g, '/');

      // base64 to Uint8Array
      var str = window.atob(thing);
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
      thing = thing.buffer;
    }

    // error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
      throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
    }

    return thing;
  },
  coerceToBase64Url: function (thing) {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
      thing = Uint8Array.from(thing);
    }

    if (thing instanceof ArrayBuffer) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
      var str = '';
      var len = thing.byteLength;

      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(thing[i]);
      }
      thing = window.btoa(str);
    }

    if (typeof thing !== 'string') {
      throw new Error('could not coerce to string');
    }

    // base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '');

    return thing;
  },
  fetchMakeCredentialOptions: async function (formData, token) {
    let response = await fetch(this.serverUrl + '/makeCredentialOptions', {
      method: 'POST', // or 'PUT'
      body: formData, // data can be `string` or {object}!
      headers: {
        Authorization: token,
        // 'SiteName': defaultSiteName,
        Accept: 'application/json',
      },
    });

    let data = await response.json();

    return data;
  },
  makeCredential: async function (username, token) {
    // console.log("Creating PublicKeyCredential...");

    var data = new FormData();
    data.append('username', username);
    data.append('displayName', username);

    // possible values: none, direct, indirect
    let attestation_type = 'none';
    // possible values: <empty>, platform, cross-platform
    let authenticator_attachment = '';

    // possible values: preferred, required, discouraged
    let user_verification = 'preferred';

    // possible values: discouraged, preferred, required
    let residentKey = 'discouraged';

    data.append('attType', attestation_type);
    data.append('authType', authenticator_attachment);
    data.append('userVerification', user_verification);
    data.append('residentKey', residentKey);
    let makeCredentialOptions = await this.fetchMakeCredentialOptions(
      data,
      token
    );

    // Turn the challenge back into the accepted format of padded base64
    makeCredentialOptions.challenge = this.coerceToArrayBuffer(
      makeCredentialOptions.challenge
    );
    // Turn ID into a UInt8Array Buffer for some reason
    makeCredentialOptions.user.id = this.coerceToArrayBuffer(
      makeCredentialOptions.user.id
    );

    makeCredentialOptions.excludeCredentials =
      makeCredentialOptions.excludeCredentials.map((c) => {
        c.id = this.coerceToArrayBuffer(c.id);
        return c;
      });

    if (
      makeCredentialOptions.authenticatorSelection.authenticatorAttachment ===
      null
    )
      makeCredentialOptions.authenticatorSelection.authenticatorAttachment =
        undefined;

    // console.log("makeCredentialOptions Created", makeCredentialOptions);

    let newCredential = await navigator.credentials.create({
      publicKey: makeCredentialOptions,
    });

    // console.log("PublicKeyCredential Created", newCredential);

    return newCredential;
  },
  registerNewCredential: async function (newCredential, token, username, storage) {
    // Move data into Arrays incase it is super long
    let attestationObject = new Uint8Array(
      newCredential.response.attestationObject
    );
    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
    let rawId = new Uint8Array(newCredential.rawId);

    const data = {
      id: newCredential.id,
      rawId: this.coerceToBase64Url(rawId),
      type: newCredential.type,
      extensions: newCredential.getClientExtensionResults(),
      response: {
        AttestationObject: this.coerceToBase64Url(attestationObject),
        clientDataJSON: this.coerceToBase64Url(clientDataJSON),
      },
    };

    let response;
    try {
      response = await this.registerCredentialWithServer(data, token);
    } catch (e) {
      console.error(e);
      return null;
    }

    // console.log("Credential Object", response);

    // show error
    if (response.status !== 'ok') {
      console.log('Error creating credential');
      console.error(response.errorMessage);
      return;
    }

    // TODO: Store credential id to local storage
    let credentialId = response.result.id;

    let pwdless = storage.getJsonLocalStorage('pwdless') || {};
    pwdless.credId = credentialId;
    pwdless.email = username;
    
    storage.setJsonLocalStorage('pwdless', pwdless);

    return response;

    // redirect to dashboard?
    //window.location.href = "/dashboard/" + state.user.displayName;
  },
  registerCredentialWithServer: async function (formData, token) {
    let response = await fetch(this.serverUrl + '/makeCredential', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(formData), // data can be `string` or {object}!
      headers: {
        Authorization: token,
        // 'SiteName': defaultSiteName,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    let data = await response.json();

    return data;
  },
  handleRegister: async function (username, token, storage) {
    try {
      let newCredential = await this.makeCredential(username, token);
      return await this.registerNewCredential(newCredential, token, username, storage);
    } catch (e) {
      console.error(e.message ? e.message : e);
      return null;
    }
  },
  verifyAssertionWithServer: async function (assertedCredential, seed) {
    // Move data into Arrays incase it is super long
    let authData = new Uint8Array(
      assertedCredential.response.authenticatorData
    );
    let clientDataJSON = new Uint8Array(
      assertedCredential.response.clientDataJSON
    );
    let rawId = new Uint8Array(assertedCredential.rawId);
    let sig = new Uint8Array(assertedCredential.response.signature);
    let userHandle = new Uint8Array(assertedCredential.response.userHandle);
    const data = {
      id: assertedCredential.id,
      rawId: this.coerceToBase64Url(rawId),
      seed: seed,
      type: assertedCredential.type,
      extensions: assertedCredential.getClientExtensionResults(),
      response: {
        authenticatorData: this.coerceToBase64Url(authData),
        clientDataJSON: this.coerceToBase64Url(clientDataJSON),
        userHandle:
          userHandle !== null ? this.coerceToBase64Url(userHandle) : null,
        signature: this.coerceToBase64Url(sig),
      },
    };

    let response;
    try {
      let res = await fetch(this.serverUrl + '/makeAssertion', {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      response = await res.json();
    } catch (e) {
      console.error('Request to server failed', e);
      return null;
    }

    // console.log("Assertion Object", response);

    // show error
    if (response.status !== 'ok') {
      console.error('Error doing assertion:', response.errorMessage);
      return null;
    }

    return response;

    // redirect?
    //window.location.href = "/dashboard/" + state.user.displayName;
  },

  handleLogin: async function (storage) {
    let pwdless = storage.getJsonLocalStorage('pwdless') || {};

    // prepare form post data
    var formData = new FormData();
    formData.append('username', pwdless.email);
    formData.append('userVerification', 'preferred')
    formData.append('credentialId', pwdless.credId)

    // send to server for registering
    let makeAssertionOptions;
    try {
      var res = await fetch(this.serverUrl + '/assertionOptions', {
        method: 'POST', // or 'PUT'
        body: formData, // data can be `string` or {object}!
        headers: {
          Accept: 'application/json',
        },
      });

      makeAssertionOptions = await res.json();
    } catch (e) {
      console.error('Request to server failed: ', e);
      return;
    }

    // console.log("Assertion Options Object", makeAssertionOptions);

    // show options error to user
    if (makeAssertionOptions.status !== 'ok') {
      console.error(
        'Error creating assertion options: ',
        makeAssertionOptions.errorMessage
      );
      return;
    }

    // todo: switch this to coercebase64
    const challenge = makeAssertionOptions.challenge
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    makeAssertionOptions.challenge = Uint8Array.from(atob(challenge), (c) =>
      c.charCodeAt(0)
    );

    // fix escaping. Change this to coerce
    makeAssertionOptions.allowCredentials.forEach(function (listItem) {
      var fixedId = listItem.id.replace(/_/g, '/').replace(/-/g, '+');
      listItem.id = Uint8Array.from(atob(fixedId), (c) => c.charCodeAt(0));
    });

    // console.log("Assertion options", makeAssertionOptions);
    // TODO: Check credential options matching curent device

    // ask browser for credentials (browser will ask connected authenticators)
    let credential;
    try {
      credential = await navigator.credentials.get({
        publicKey: makeAssertionOptions,
      });
    } catch (err) {
      console.error(err.message ? err.message : err);
      return;
    }

    let response;
    try {
      response = await this.verifyAssertionWithServer(
        credential,
        makeAssertionOptions.seed
      );
      return response;
    } catch (e) {
      console.error('Could not verify assertion: ', e);
    }
  },

  turnoff: async function(token, storage) {
    let pwdless = storage.getJsonLocalStorage('pwdless') || {};
    var formData = new FormData();
    formData.append('credentialId', pwdless.credId);

    let response = await fetch(this.serverUrl + '/turnoff', {
      method: 'POST', // or 'PUT'
      body: formData,
      headers: {
        Authorization: token,
        // 'SiteName': defaultSiteName,
        Accept: 'application/json'
      },
    });

    let data = await response.json();

    // Turn-off
    if (data && data.ok) storage.setJsonLocalStorage('pwdless', {});    

    return data;
  }
};
