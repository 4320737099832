import store from '@/containers/App/store';
import { BET_TYPE, SIGNAL_STRATEGY_TYPE, BUDGET_STEP_OPTIONS } from '@/shared/constants';

export const createDefaultValues = (isEdit, currentPlan = {}) => {
  if (isEdit) return onEdit(currentPlan);  

  const {
    reset_budget_at_resume = false,
    reset_budget_at_reprofit = false,
    reset_budget_at_tp_sl = false,
  } = store.getState()?.appConfig?.userConfig || {};

  return {
    waitSignalType: BET_TYPE.win,
    base_amount: 1,
    win_streak: 1,
    lose_streak: 1,
    no_of_entries: 1,
    signal_type: SIGNAL_STRATEGY_TYPE.botai,
    start_at: '00:00',
    experts: [],
    budget: 100,
    isUltilFinishBudget: 'a',
    candle_order_number: 1,
    reset_budget_at_resume,
    reset_budget_at_reprofit,
    reset_budget_at_tp_sl,
    candle_win_lose: 'WIN',
    ignore_budget_radio: BUDGET_STEP_OPTIONS.continueAsResult,
    ...currentPlan,
  };
};

const onEdit = (currentPlan) => {
  if (!currentPlan.id) return {};

  const signalStrategy = currentPlan.signal_strategy;
  const signalStrategyType = currentPlan.is_manual
    ? SIGNAL_STRATEGY_TYPE.manual
    : signalStrategy?.type;
  let experts = [];
  let botId = '';
  let telegram = [];
  let community = []
  if (signalStrategyType === SIGNAL_STRATEGY_TYPE.expert) {
    experts = signalStrategy.sources.map((source) => source.name);
  } else if (signalStrategyType === SIGNAL_STRATEGY_TYPE.botai) {
    botId = signalStrategy.id;
  } else if (signalStrategyType === SIGNAL_STRATEGY_TYPE.telegram) {
    console.log('onEdit telegram', signalStrategy)
    telegram = signalStrategy.sources.reduce(
      (acc, item) => [...acc, { value: item.id, label: item.name }],
      []
    );
  } else if (signalStrategyType === SIGNAL_STRATEGY_TYPE.community) {
    community = signalStrategy.sources.reduce(
      (acc, item) => [...acc, { value: item.source, label: item.name }],
      []
    );
  }

  let waitSignalType = BET_TYPE.win;
  if (currentPlan.wait_signal) {
    waitSignalType = currentPlan.win_streak > 0 ? BET_TYPE.win : BET_TYPE.lose;
  }
  const winStreak = currentPlan.wait_signal ? currentPlan.win_streak : 1;
  const loseStreak = currentPlan.wait_signal ? currentPlan.lose_streak : 1;
  const noOfEntries = currentPlan.wait_signal ? currentPlan.no_of_entries : 1;


  const reachTarget =
    currentPlan.take_profit !== 0 ||
    currentPlan.stop_loss !== 0 ||
    currentPlan.pause_win_streak !== 0 ||
    currentPlan.pause_lose_streak !== 0 ||
    currentPlan.total_order !== 0 ||
    currentPlan.stop_loss_positive !== 0;

  const subProfit =
    currentPlan.sub_take_profit !== 0 || currentPlan.sub_stop_loss !== 0 || currentPlan.sub_win_streak !== 0 || currentPlan.sub_lose_streak !== 0;
  const enableOrderIndex = !!currentPlan.order_index;
  const data = {
    ...currentPlan,
    signal_type: signalStrategyType || SIGNAL_STRATEGY_TYPE.botai,
    bot_ai_id: botId,
    telegram_chanels: telegram,
    community_signals: community,
    experts,
    waitSignalType,
    reachTarget,
    subProfit,
    showSubProfit: subProfit,
    win_streak: winStreak,
    lose_streak: loseStreak,
    no_of_entries: noOfEntries === -1 ? 1 : noOfEntries,
    isUltilFinishBudget: noOfEntries === -1 ? 'a' : 'b',
    pause_lose_streak: Math.abs(currentPlan.pause_lose_streak),
    order_index: currentPlan.order_index || undefined,
    enableOrderIndex,
    candle_order_number: currentPlan.candle_order_number || 1,
    ignore_budget_radio: currentPlan.step0_option || BUDGET_STEP_OPTIONS.continueAsResult,
    step0_option: !!currentPlan.step0_option
  };

  return data;
};
