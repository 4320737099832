import Help from '@/containers/Layout/Help';
import Loading from '@/shared/components/Loading';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const DisputeResolution = React.lazy(() =>
  import('@/containers/Help/DisputeResolution')
);
const Communications = React.lazy(() =>
  import('@/containers/Help/Communications')
);
const Faq = React.lazy(() => import('@/containers/Help/Faq'));
const FinalProvisions = React.lazy(() =>
  import('@/containers/Help/FinalProvisions')
);
const ForceMajeure = React.lazy(() => import('@/containers/Help/ForceMajeure'));
const GeneralProvisions = React.lazy(() =>
  import('@/containers/Help/GeneralProvisions')
);
const GoverningLaw = React.lazy(() => import('@/containers/Help/GoverningLaw'));
const ResponsibilityOfTheParties = React.lazy(() =>
  import('@/containers/Help/ResponsibilityOfTheParties')
);
const RightsAndObligations = React.lazy(() =>
  import('@/containers/Help/RightsAndObligations')
);
const TermAndTermination = React.lazy(() =>
  import('@/containers/Help/TermAndTermination')
);
const TermsOfUse = React.lazy(() => import('@/containers/Help/TermsOfUse'));
const PrivacyPolicy = React.lazy(() =>
  import('@/containers/Help/PrivacyPolicy')
);
const TutorialVideos = React.lazy(() =>
  import('@/containers/Help/TutorialVideos')
);

export default () => (
  <Help>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/help" component={Faq} />
        <Route
          exact
          path="/help/dispute-resolution"
          component={DisputeResolution}
        />
        <Route exact path="/help/communications" component={Communications} />
        <Route exact path="/help/faqs" component={Faq} />
        <Route
          exact
          path="/help/final-provisions"
          component={FinalProvisions}
        />
        <Route exact path="/help/force-majeure" component={ForceMajeure} />
        <Route
          exact
          path="/help/general-provisions"
          component={GeneralProvisions}
        />
        <Route exact path="/help/governing-law" component={GoverningLaw} />
        <Route
          exact
          path="/help/responsibility-of-the-parties"
          component={ResponsibilityOfTheParties}
        />
        <Route
          exact
          path="/help/rights-and-obligations"
          component={RightsAndObligations}
        />
        <Route
          exact
          path="/help/term-and-termination"
          component={TermAndTermination}
        />
        <Route exact path="/help/terms-of-use" component={TermsOfUse} />
        <Route exact path="/help/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/help/tutorial-video" component={TutorialVideos} />
      </Switch>
    </Suspense>
  </Help>
);
