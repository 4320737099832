import useTotalHistory from '@/hooks/queries/useTotalHistory';
import {
  updateBacktestDataChart,
  updateBacktestPrevHistory,
  updateBacktestProgress,
} from '@/redux/actions/planActions';
import { Button } from '@/shared/components/Button';
import formatNumber from '@/shared/helpers/formatNumber';
import { Devices, colorGreyScale50, colorSuccess } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DrawerCreateBackTest from './drawer/DrawerCreateBackTest';
import ModalHistory from './modal/ModalHistory';
import ModalResult from './modal/ModalResult';

const imgCircle = [
  <svg
    key="0"
    className="svg-circle-1"
    width="96"
    height="160"
    viewBox="0 0 96 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse opacity="0.15" cx="79.5" cy="80" rx="79.5" ry="80" fill="white" />
  </svg>,
  <svg
    key="1"
    className="svg-circle-2"
    width="115"
    height="159"
    viewBox="0 0 115 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse opacity="0.15" cx="30" cy="79.5" rx="85" ry="79.5" fill="white" />
  </svg>,
];
export default function BacktestBanner() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data = {}, refetch, isLoading } = useTotalHistory();
  const [showCreate, setShowCreate] = useState(false);
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const hideModalResult = () => {
    setShowModalResult(false);
    dispatch(updateBacktestPrevHistory(false));
  };
  const [backtestId, setBacktestId] = useState('');
  const changeBacktestId = (id) => {
    dispatch(updateBacktestDataChart([]));
    dispatch(updateBacktestProgress(0));
    setBacktestId(id);
    if (showModalHistory) {
      setShowModalHistory(false);
    }
    if (showCreate) {
      setShowCreate(false);
    }
    setShowModalResult(true);
  };

  return (
    <Wrapper>
      <div className="text-content">
        <Box mt="36px" mb="20px" className="text-center">
          <span className="badge-transparent">{t('Test your plan')}</span>
        </Box>
        <h4 className="text-center title">
          {t(
            'Check the investment plan right away, know the results immediately'
          )}
        </h4>
        <div className="text-center subtitle">
          {t('Preview investment results within 24 hours on upcoming plans')}
        </div>
        <Box
          ml="auto"
          mr="auto"
          mb="16px"
          maxWidth="267px"
          className="text-center"
        >
          <Button
            block="true"
            variant="dark"
            onClick={() => setShowCreate(true)}
          >
            {t('Check investment plan')}
          </Button>
        </Box>
        {!isLoading && (
          <div
            className="text-center btn-list-histories"
            onClick={() => setShowModalHistory(true)}
            onKeyDown={() => setShowModalHistory(true)}
            role="presentation"
          >
            {t('Test Plan History ({{amount}})', {
              amount: formatNumber.numberWithCommas(data?.total) || 0,
            })}
          </div>
        )}
      </div>
      <img
        className="thumb"
        src="/img/dashboard/backtest-banner.png"
        alt="BackTest Banner"
      />
      {imgCircle[0]}
      {imgCircle[1]}
      <ModalHistory
        show={showModalHistory}
        onHide={() => setShowModalHistory(false)}
        changeBacktestId={changeBacktestId}
      />
      {backtestId && (
        <ModalResult
          show={showModalResult}
          onHide={hideModalResult}
          backtestId={Number(backtestId)}
          showModalHistory={() => setShowModalHistory(true)}
        />
      )}
      <DrawerCreateBackTest
        totalHistory={data}
        historyLoading={isLoading}
        onRefetchHistory={refetch}
        open={showCreate}
        onClose={() => setShowCreate(false)}
        changeBacktestId={changeBacktestId}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  background: ${colorSuccess};
  border-radius: 8px;
  margin-bottom: 32px;
  margin-top: 8px;
  .text-content {
    padding: 16px;
    z-index: 10;
    position: relative;
  }
  @media ${Devices.pc} {
    display: none;
  }
  img {
    width: 100%;
    margin-top: -10%;
  }
  .badge-transparent {
    background: #55c790;
    border-radius: 100px;
    padding: 4px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: white;
  }
  .title {
    font-weight: 800;
    font-size: 28px;
    line-height: 125%;
    color: white;
    margin-bottom: 24px;
  }
  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.67);
    max-width: 267px;
    margin: 0 auto;
    margin-bottom: 35px;
  }
  .btn-list-histories {
    text-decoration: underline;
    color: white;
    font-weight: ${colorGreyScale50};
    font-size: 14px;
    line-height: 160%;
    &:hover {
      cursor: pointer;
    }
  }
  .svg-circle-1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .svg-circle-2 {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`;
