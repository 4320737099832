import React from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FORM_TYPE } from '@/shared/constants';
import { Button } from '@/shared/components/Button';
import { Form } from '@/shared/components/form/drawer/Elements';

const FormBotFooter = ({isValidForm, isLoading, onClose, saveBot, allowCustomize, handleCustomize}) => {
  const { t } = useTranslation();

  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
    }),
    isEqual
  );

  return (
    <Form.Footer>
      <Button
        className="cta-back"
        block={formType === FORM_TYPE.read_only}
        onClick={onClose}
        variant="secondary_outline"
      >
        {t('Close')}
      </Button>
      {!currentBot.is_default && formType !== FORM_TYPE.read_only && (
        <Button
          onClick={saveBot}
          disabled={!isValidForm || isLoading}
          isLoading={isLoading}
          block="true"
          variant="success"
        >
          {t(formType === FORM_TYPE.create ? 'Create Bot' : 'Save Bot')}
        </Button>
      )}
      {allowCustomize && (currentBot.is_default || formType === FORM_TYPE.read_only) && !currentBot.copy_from && (
        <Button variant="success" onClick={handleCustomize} block="true">
          {t('Customize Bot AI')}
        </Button>
      )}
    </Form.Footer>
  );
};

FormBotFooter.propTypes = {
  isValidForm: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveBot: PropTypes.func.isRequired,
  handleCustomize: PropTypes.func.isRequired,
};

export default FormBotFooter;
