import {
  get,
  post,
} from './base';
import { getQueryString } from './base/axios';

export default {
  getOverviewAffiliation: () => get('/budgets/cash/user/overview'),
  buyVip: () => post('/budgets/cash/agency-license'),
  getCommissiondetails: params => get(`/budgets/cash/history/totalcommissiondetails?${getQueryString(params)}`),
  getAffiliateManagement: params => get(`/budgets/cash/user/affiliate?days=-1&${getQueryString(params)}`),
  getReferrals: params => get(`/plans/user/affiliates?${getQueryString(params)}`),
};
