import axios, { defaultParams, getApiUrl } from './axios';

export default async (url, params) => {
  try {
    return await axios.delete(getApiUrl(url), { ...defaultParams(), data: params });
  } catch (e) {
    console.trace(e);
    if (!e || !e.response || e.response.status !== 401) { throw e; }
    return null;
  }
};
