import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import planApi from '@/utils/api/planApi';
import gtag from '@/shared/constants/gtag';

import { colorAccent, colorGreyScale600 } from '@/utils/palette';
import Modal, {
  ModalBodyStyle,
  ModalFooterStyle,
  ModalTitleStyle,
} from '@/shared/components/Modal';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';
import { Button } from '@/shared/components/Button';
import ButtonLoading from '@/shared/components/ButtonLoading';
import toastHandler from '@/shared/helpers/toastHandler';

import './index.scss';

const ga4Stats = window.ga4Stats;

const PopupSharePlan = ({ show, onHide, currentPlan, onSuccess }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();

  const accountType = useSelector((state) => state.wallet.accountType, isEqual);

  const [codePlan, setCodePlan] = useState('');

  const handleRenewCode = async () => {
    setIsLoading(true);

    const res = await toastHandler(
      t,
      'generate-plan-code',
      () => planApi.renewCode(currentPlan.id),
      t('Generate the new code successfully!')
    );

    setIsLoading(false);
    if (res?.data?.ok) {
      setCodePlan(res.data.d.code);
      onSuccess();
    }
  };

  const handleCopyCode = () => {
    copy(codePlan);
    onHide();
    ga4Stats(gtag.plan.action.shareCodePlan, gtag.plan.category, accountType);
  };

  useEffect(() => {
    if (currentPlan.id) {
      setCodePlan(currentPlan.copy_code);
    }
  }, [currentPlan]);

  if (!show) return null;

  return (
    <Modal
      isOpen={show}
      closeModal={onHide}
      title={t('Share Investment Plan')}
      centered
      className="popup-share-plan"
    >
      <ModalBodyStyle>
        <TitleStyle>{t('Easy way to share your superb plan! ')}</TitleStyle>
        <SubTitle>
          {t('Share your Investment Plan Code to your peers and trade together.')}
        </SubTitle>
        <Form>
          <Form.Group>
            <Box mb="12px">
              <Form.Label>{t('Investment Plan Code')}</Form.Label>
            </Box>
            <Box mb="12px">
              <InputGroup className="mb-2">
                <Form.Control
                  placeholder={t('Enter Code')}
                  value={codePlan}
                  className="h4 mb-0 "
                  readOnly
                />
                {!isLoading && (
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={handleRenewCode}
                    className="text-success"
                  >
                    {t('Generate')}
                  </Button>
                )}
                {isLoading && <ButtonLoading variant="outline-secondary" />}
              </InputGroup>
            </Box>
            <Form.Text>
              <strong className="me-1">{t('Note')}:</strong>
              <span>
                {t('You will be able to review the plan to confirm after importing code.')}
              </span>
            </Form.Text>
          </Form.Group>
        </Form>
      </ModalBodyStyle>
      <ModalFooterStyle>
        <Button paddinghorizontal="115" mblock="true" variant="success" onClick={handleCopyCode}>
          {t('Copy Code')}
        </Button>
      </ModalFooterStyle>
    </Modal>
  );
};

PopupSharePlan.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func,
};

PopupSharePlan.defaultProps = {
  onSuccess: () => {},
};

const TitleStyle = styled(ModalTitleStyle)`
  font-size: 20px;
  color: ${colorAccent};
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;
const SubTitle = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${colorGreyScale600};
  margin-bottom: 32px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

export default PopupSharePlan;
