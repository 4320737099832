import { useState } from 'react';
import { isEqual } from 'lodash';
import botApi from '@/utils/api/botApi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateCurrentBot } from '@/redux/actions/botActions';
import { STRATEGY_TYPE, SIGNAL_STRATEGY_TYPE, FORM_TYPE } from '@/shared/constants';
import { getBots, updateLatestCreatedBot } from '@/redux/actions/botActions';
import { getBots as getCommonBots } from '@/redux/refactor/common/actions';
import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats

const useBotManagement = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { pagination, formType } = useSelector(
    (state) => ({
      pagination: state.bot.pagination,
      formType: state.bot.formBot.type,
    }),
    isEqual
  );

  const handleResponse = (res, onClose) => {
    if (res?.data?.ok) {
      toast.success(
        t(
          formType === FORM_TYPE.create
            ? 'Create the bot successfully!'
            : 'Edit the bot successfully!'
        )
      );
      if (formType === FORM_TYPE.create) {
        dispatch(updateLatestCreatedBot({ id: res?.data?.d }));
      }
      dispatch(getCommonBots());
      const isTopBotList = location.pathname.includes('/top-signal')
      if (!isTopBotList) {
        dispatch(
          getBots({
            page: pagination.p,
            size: pagination.s,
          })
        );
      }

      onClose();
    } else {
      toast.error(t(res.data.m));
    }
  }

  const createBot = async (botName, botSignalStrategyType, sources, experts, option, onClose) => {
    setIsLoading(true);
    try {
      const response = await botApi.createBot({
        name: botName,
        strategy: botSignalStrategyType,
        sources,
        type: botSignalStrategyType === STRATEGY_TYPE.string ? '' : SIGNAL_STRATEGY_TYPE.botai,
        experts: botSignalStrategyType === STRATEGY_TYPE.mix ? experts : [],
        option: botSignalStrategyType === STRATEGY_TYPE.mix ? option : '',
      });
      
      handleResponse(response, onClose);
      if (response?.data?.ok) {        
        dispatch(updateCurrentBot({id: response?.data?.d}));
      }
      
      setIsLoading(false);

      if (botSignalStrategyType === 'CANDLE_PATTERN') {
        ga4Stats(gtag.signal.action.createBotCandle, gtag.signal.category, gtag.signal.label);
      } else {
        ga4Stats(gtag.signal.action.createBotAi, gtag.signal.category, gtag.signal.label);
      }

      return response;
    } catch (error) {
      setIsLoading(false);
      toast.error('Error creating bot');
    }
  };

  const editBot = async (currentBotId, botName, botSignalStrategyType, sources, experts, option, onClose) => {
    setIsLoading(true);
    try {
      const response = await botApi.editBot({
        id: currentBotId,
        name: botName,
        strategy: botSignalStrategyType,
        sources,
        type: botSignalStrategyType === STRATEGY_TYPE.string ? '' : SIGNAL_STRATEGY_TYPE.botai,
        experts: botSignalStrategyType === STRATEGY_TYPE.mix ? experts : [],
        option: botSignalStrategyType === STRATEGY_TYPE.mix ? option : '',
      });
      handleResponse(response, onClose);
      setIsLoading(false);

      if (response?.data?.ok) {
        ga4Stats(gtag.signal.action.edit, gtag.signal.category, gtag.signal.label);
      }     

      return response;
    } catch (error) {
      setIsLoading(false);
      toast.error('Error editing bot');
    }
  };

  return { createBot, editBot, isLoading };
};

export default useBotManagement;
