import { createAction } from 'redux-actions';
import strategyApi from '@/utils/api/strategyApi';
import { TYPE_KEY } from '@/shared/constants';

export const updateStrategies = createAction('UPDATE_STRATEGIES');
export const updateStrategy = createAction('UPDATE_STRATEGY');
export const updatePagination = createAction('UPDATE_STRATEGY_PAGINAION');
export const updateIsFetching = createAction('UPDATE_STRATEGY_IS_FETCHING');
export const updateCurrentStrategy = createAction('UPDATE_CURRENT_STRATEGY');
export const updateFormTypeStrategy = createAction('UPDATE_FORM_TYPE_STRATEGY');
export const updateFormStatusStrategy = createAction('UPDATE_FORM_STATUS_STRATEGY');
export const updateStrategyMethods = createAction('UPDATE_STRATEGY_METHODS');
export const updateStrategyOptions = createAction('UPDATE_STRATEGY_OPTIONS');
export const updateLatestCreatedStrategy = createAction('UPDATE_LATEST_CREATED_STRATEGY');

export const getStrategies = params => async (dispatch) => {
  dispatch(updateIsFetching(true));
  const { data } = await strategyApi.getStrategies(params);
  dispatch(updateIsFetching(false));
  if (data?.ok) {
    dispatch(updateStrategies(data.d.c));
    dispatch(updatePagination({
      p: data.d.p,
      s: data.d.s,
      t: data.d.t,
    }));
  }
};

export const getStrategyMethods = () => async (dispatch) => {
  const { data } = await strategyApi.getMethods(TYPE_KEY.strategy);
  if (data?.ok) {
    dispatch(updateStrategyMethods(data.d));
  }
};

export const getStrategyOptions = () => async (dispatch) => {
  const martingaleOptionsRes = await strategyApi.getStrategyOptions('MartingaleOption');
  const fiboOptionsRes = await strategyApi.getStrategyOptions('FiboOption');
  if (martingaleOptionsRes?.data?.ok && fiboOptionsRes?.data?.ok) {
    dispatch(updateStrategyOptions([
      ...martingaleOptionsRes.data.d,
      ...fiboOptionsRes.data.d,
    ]))
  }
};
