import {
  NETWORK_TYPE,
  STORAGE_KEY,
  TRANSFER_TYPES,
  WALLET_TYPE,
} from '@/config/const';
import formatNumber from '@/shared/helpers/formatNumber';
import walletApi from '@/utils/api/walletApi';
import { t } from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';

export const getBalanceRequest = createAction('wallet/getBalanceRequest');
export const getBalanceSuccess = createAction('wallet/getBalanceSuccess');
export const getBalanceFailed = createAction('wallet/getBalanceFailed');

export const getTransactionRequest = createAction(
  'wallet/getTransactionRequest'
);
export const getTransactionSuccess = createAction(
  'wallet/getTransactionSuccess'
);
export const getTransactionFailed = createAction('wallet/getTransactionFailed');

export const getUsdtAddressRequest = createAction(
  'wallet/getUsdtAddressRequest'
);
export const getUsdtAddressSuccess = createAction(
  'wallet/getUsdtAddressSuccess'
);
export const getUsdtAddressFailed = createAction('wallet/getUsdtAddressFailed');

export const getWithdrawConfigRequest = createAction(
  'wallet/getWithdrawConfigRequest'
);
export const getWithdrawConfigSuccess = createAction(
  'wallet/getWithdrawConfigSuccess'
);
export const getWithdrawConfigFailed = createAction(
  'wallet/getWithdrawConfigFailed'
);

export const setBalance = createAction('wallet/setBalance');

export const setWalletRefreshing = createAction('wallet/setWalletRefreshing');
export const setDemoReloading = createAction('wallet/setDemoReloading');

export const setAccountType = createAction('wallet/setAccountType');
export const setTransferring = createAction('wallet/setTransferring');

export const getBalance = () => async (dispatch) => {
  try {
    dispatch(getBalanceRequest());
    const resp = await walletApi.getBalance();
    if (resp?.data?.ok) {
      dispatch(getBalanceSuccess(resp?.data?.d));
    } else {
      toast.error(resp?.data?.m);
      dispatch(getBalanceFailed());
    }
  } catch (error) {
    toast.error(error?.message);
    dispatch(getBalanceFailed());
  }
};

/**
 *
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.size
 * @return
 */
export const getTransactions = (params) => async (dispatch) => {
  try {
    dispatch(getTransactionRequest());
    //
    const [balance, exchange] = await Promise.all([
      walletApi.getBalanceTransactions(params || { page: 1, size: 10 }),
      walletApi.getTransferTransactions(params || { page: 1, size: 10 }),
    ]);
    const result = _.orderBy([...balance, ...exchange], ['ts'], ['desc']);
    dispatch(getTransactionSuccess(_.take(result, 10)));
  } catch (error) {
    toast.error(error?.message);
    dispatch(getTransactionFailed());
  }
};

export const getUsdtAddress = () => async (dispatch) => {
  try {
    dispatch(getUsdtAddressRequest());
    const resp = await walletApi.getUsdtAddress();
    if (resp?.data?.ok) {
      dispatch(getUsdtAddressSuccess(resp?.data?.d?.a));
    } else {
      toast.error(resp?.data?.m);
      dispatch(getUsdtAddressFailed());
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(error?.message);
    dispatch(getUsdtAddressFailed());
  }
  return false;
};

export const getWithdrawConfig = () => async (dispatch) => {
  try {
    dispatch(getWithdrawConfigRequest());
    const resp = await walletApi.getWithdrawConfig();
    if (resp?.data?.ok) {
      window.minUsdt = resp?.data?.d?.data?.u_min;
      dispatch(getWithdrawConfigSuccess(resp?.data?.d?.data));
    } else {
      toast.error(resp?.data?.m);
      dispatch(getWithdrawConfigFailed());
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(error?.message);
    dispatch(getWithdrawConfigFailed());
  }
  return false;
};

/**
 *
 * @param {Object} params
 * @param {string} params.amount
 * @param {string} params.nickName
 * @param {string} params.verifyCode Google authenticator code
 * @returns
 */
export const internalTransfer = (params) => async (dispatch) => {
  try {
    const resp = await walletApi.internalTransfer(params);
    if (resp?.data?.ok) {
      dispatch(getTransactions());
      dispatch(getBalance());
      toast.success(t('Withdraw successful'));
    } else {
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(error?.message);
    return false;
  }
};

/**
 *
 * @param {Object} params
 * @param {string} params.amount
 * @param {string} params.toAddress
 * @param {string} params.verifyCode Google authenticator code
 * @returns
 */
export const externalWithdraw = (params) => async (dispatch) => {
  try {
    const resp = await walletApi.externalWithdraw({
      ...params,
      network: NETWORK_TYPE.BSC,
    });
    if (resp?.data?.ok) {
      dispatch(getTransactions());
      dispatch(getBalance());
      toast.success(t('Withdraw successful'));
    } else {
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(error?.message);
    return false;
  }
};

export const reloadDemoBalance = () => async (dispatch) => {
  try {
    dispatch(setDemoReloading(true));
    const resp = await walletApi.reloadDemoBalance();
    if (resp?.data?.ok) {
      dispatch(getBalance());
    }
    dispatch(setDemoReloading(false));
  } catch (error) {
    dispatch(setDemoReloading(false));
  }
};

export const switchAccountType = () => (dispatch, getState) => {
  const { wallet } = getState();
  const accountType =
    wallet.accountType === WALLET_TYPE.LIVE
      ? WALLET_TYPE.DEMO
      : WALLET_TYPE.LIVE;
  localStorage.setItem(STORAGE_KEY.WALLET_TYPE, accountType);
  dispatch(setAccountType(accountType));
};

const typeMessage = {
  [TRANSFER_TYPES.IN]: 'Live Wallet',
  [TRANSFER_TYPES.OUT]: 'USDT Wallet',
};
export const transfer = (transferType, params) => async (dispatch) => {
  dispatch(setTransferring(true));
  const request =
    transferType === TRANSFER_TYPES.IN
      ? walletApi.transferIn
      : walletApi.transferOut;
  const resp = await request(params);
  const { ok, d } = resp.data || {};
  if (ok) {
    toast.success(
      t('You have successfully transfer {{amount}} to {{type}}', {
        amount: formatNumber.formatCurrency(params.amount),
        type: t(typeMessage[transferType]),
      })
    );
    dispatch(getBalance());
    dispatch(getTransactions());
  } else {
    toast.error(t(d.err_code));
  }
  dispatch(setTransferring(false));
  return resp.data.ok;
};
