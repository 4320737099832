import { WALLET_TYPE } from '@/config/const';
import { left } from '@/utils/directions';
import {
  borderDashedStyle,
  borderDefaultStyle,
  Devices,
  dividerColor,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';

export const DrawerCardHeader = ({ title, onClose }) => (
  <Card.Header>
    <Box display="flex" gridGap={16}>
      <Box flex="1" overflow="hidden">
        <h5>{title}</h5>
      </Box>
      <button type="button" className="close" label="close" onClick={onClose} />
    </Box>
  </Card.Header>
);

export const Card = styled(BootstrapCard)`
  width: 100%;
  height: ${(props) => props.height || '100%'};
  border: none;
  border-radius: 16px;
  // transition: all 0.3s linear;
  /* overflow: hidden; */
  margin-bottom: 16px;
  @media ${Devices.pc} {
    margin-bottom: 30px;
  }
`;

export const CardHeader = styled(Card.Header)`
  padding: 16px;
  border-color: ${dividerColor};
  @media ${Devices.laptop} {
    padding: 24px;
  }
`;
export const CardBody = styled(Card.Body)`
  height: 100%;
  padding: ${(props) => props.padding};
`;

export const CardTitleWrap = styled.div`
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CardTitle = styled.h5`
  text-align: ${left};
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  ${(props) =>
    props.centered &&
    `
    text-align: center;
  `}

  @media ${Devices.laptop} {
    line-height: 140%;
    font-size: 20px;
  }
`;

export const CardSubTitle = styled(CardTitle)`
  font-size: 18px;
  line-height: 140%;
`;

export const CardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
`;

export const CardDivider = styled(Box)`
  width: 100%;
  border-bottom: ${(props) =>
    props.dashed ? borderDashedStyle : borderDefaultStyle};
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const CardGradient = styled(Box)`
  position: relative;
  background: ${(props) =>
    props.accountype === WALLET_TYPE.LIVE
      ? 'linear-gradient(112.77deg, #002b16 14.65%, #54ce93 87.93%)'
      : 'linear-gradient(112.77deg, #200041 14.65%, #8C62FF 87.93%)'};
  padding: 16px 24px;
  border-radius: 16px;
  &:after {
    position: absolute;
    right: 16px;
    top: 0;
    font-size: 40px;
    font-weight: 900;
    color: #fafafa;
    opacity: 0.1;
    content: '${(props) => props.accountype}';
  }
`;

export const CardGradientItem = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const CardGradientItemLabel = styled(Box)`
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #ffffff;
  opacity: 0.3;
`;

export const CardGradientItemValue = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.306388px;
  color: #ffffff;
`;
