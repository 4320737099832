import planApi from '@/utils/api/planApi';
import _, { isNumber } from 'lodash';
import { setBalance } from '../wallet/actions';

const { createAction } = require('redux-actions');

export const NOTIFY_ACTIONS = {
  ORDERRED: 'ORDER',
  WON: 'WIN',
  LOSE: 'LOSE',
  REFUND: 'REFUND',
  INSUF_BALANCE: 'INSUF_BALANCE',
  TP: 'TP',
  SL: 'SL',
};

export const setVisibleWindow = createAction(
  'notification_setVisibleWindowNotify'
);
export const toggleVisibleWindow = createAction(
  'notification_toggleVisibleWindowNotify'
);
export const setTab = createAction('notification_setNotifyTab');
export const pushNotify = createAction('notification_pushNotify');
export const setNotifyPlan = createAction('notification_setNotifyPlan');
export const setSearchTerm = createAction('notification_setSearchTerm');
export const setLastNotify = createAction('notification_setLastNotify');
export const setNotification = createAction('notification_setNotification');

export const getNotifyPlans = () => async (dispatch) => {
  const res = await planApi.getPlans({ page: 0, size: 120 });
  const { ok, d = {} } = res.data || res;
  if (ok) {
    dispatch(setNotifyPlan(d.c));
  }
};

const pushNotifyDebounce = _.debounce(({ dispatch, data }) => {
  const {
    extraData: { balance = null },
    accountType,
  } = data;
  if (isNumber(balance)) {
    dispatch(setBalance({ accountType, balance }));
  }
}, 300);

const setLastNotifyDebounce = _.debounce(({ dispatch, data }) => {
  console.log('setLastNotifyDebounce', data);
  dispatch(setLastNotify(data));
}, 300);

export const onReceiveNotify =
  (message, isNew = true) =>
  (dispatch) => {
    try {
      const data = JSON.parse(message);
      if (isNew) {
        dispatch(setLastNotify(data));
      }

      switch (data?.action) {
        case NOTIFY_ACTIONS.ORDERRED:
        case NOTIFY_ACTIONS.WON:
        case NOTIFY_ACTIONS.LOSE:
          // push notification
          pushNotifyDebounce({ dispatch, data, isNew });
          // setTimeout(() => {
          //   dispatch(pushNotify({ data, isNew }));
          // }, 10);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

export const setLastMessage =
  (listMessage = []) =>
  (dispatch, getState) => {
    const dict = {};
    try {
      listMessage.forEach((data) => {
        // const data = JSON.parse(message);
        if (!dict[data.planId]) dict[data.planId] = [];
        dict[data.planId].push(data);
      });

      const { notification } = getState().notification;
      Object.keys(dict).forEach((key) => {
        if (!notification[key]) notification[key] = [];
        notification[key] = [...notification[key], ...dict[key]];
      });
      dispatch(setNotification(dict));
    } catch (e) {
      console.error('setLastMessage: ', { listMessage, e });
    }
  };

export const getLastMessages = () => (dispatch) => {
  planApi
    .getLastActionHistories()
    .then((resp = {}) => {
      const { data = {} } = resp;
      if (data.ok) {
        dispatch(setLastMessage(data.d));
      } else {
        dispatch(setLastMessage([]));
      }
    })
    .catch(() => {
      dispatch(setLastMessage([]));
    });
};
