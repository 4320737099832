import exchangeApi from '@/utils/api/exchangeApi';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';
import { setEnabled2Fa } from '../exchangeTwoFactor/actions';

export const updateProfile = createAction('exchange/UPDATE_PROFILE');
export const loginRequest = createAction('exchange/LOGIN_REQUEST');
export const loginSuccess = createAction('exchange/LOGIN_SUCCESS');
export const loginFailure = createAction('exchange/LOGIN_FAILURE');
export const loginRequire2Fa = createAction('exchange/LOGIN_REQUIRE_2FA');

export const verify2FaRequest = createAction('exchange/VERIFY_2FA_REQUEST');
export const verify2FaSuccess = createAction('exchange/VERIFY_2FA_SUCCESS');
export const verify2FaFailure = createAction('exchange/VERIFY_2FA_FAILURE');
export const reset2Fa = createAction('exchange/RESET_2FA');

export const setExchangeInfoLoading = createAction('exchange/setExchangeInfoLoading');
export const setExchangeInfo = createAction('exchange/setExchangeInfo');
export const disconnectSuccess = createAction('exchange/disconnectSuccess');

export const updateTutorialState = createAction('exchange/updateTutorialState');
export const setIsError = createAction('exchange/setError');

export const getProfile = (domain) => async (dispatch, getState) => {
  try {
    const { exchange } = getState();
    const resp = await exchangeApi.getUserProfile(domain || exchange.domain);
    if (resp?.data?.ok) {
      dispatch(updateProfile(resp?.data?.d));
      dispatch(setEnabled2Fa(resp?.data?.d['2fa']));
    } else {
      toast.error(t(resp?.data?.m));
    }
  } catch (error) {
    toast.error(t(error.message));
    throw error;
  }
};

let count = 0;
let timerId = null;
export const getExchangeInfo =
  (fetching = false) =>
  async (dispatch) => {
    try {
      dispatch(setExchangeInfoLoading(fetching));
      const resp = await exchangeApi.linkAccount();
      if (resp?.data?.ok) {
        dispatch(setExchangeInfo(resp?.data?.d));
        if (resp?.data?.d?.is_connected) {
          dispatch(getProfile());
        }
        // reset timer
        clearTimeout(timerId);
        count = 0;
      }
      dispatch(setExchangeInfoLoading(false));
    } catch (error) {
      if (count < 2) {
        timerId = setTimeout(() => {
          dispatch(getExchangeInfo(true));
          count++;
        }, 5000);
      } else {
        clearTimeout(timerId);
        count = 0;
        dispatch(setIsError(true));
        dispatch(setExchangeInfoLoading(false));
      }
    }
  };

export const login = (params) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const resp = await exchangeApi.login(params);
    if (resp?.data?.ok) {
      if (resp?.data?.d?.require2Fa) {
        dispatch(loginRequire2Fa(resp?.data?.d));
      } else {
        await dispatch(getExchangeInfo());
        dispatch(loginSuccess(resp?.data?.d));
      }
    } else {
      const { email } = resp?.data?.d || {};
      toast.error(t(resp?.data?.m, { email }));
      dispatch(loginFailure());
    }
  } catch (error) {
    toast.error(t(error.message));
    dispatch(loginFailure({ error }));
    throw error;
  }
};

export const verify2Fa = (params) => async (dispatch) => {
  try {
    dispatch(verify2FaRequest());
    const resp = await exchangeApi.verify2Fa(params);
    if (resp?.data?.ok) {
      await dispatch(getExchangeInfo());
      dispatch(verify2FaSuccess(resp?.data?.d));
    } else {
      const { email } = resp?.data?.d || {};
      toast.error(t(resp?.data?.m, { email }));
      dispatch(verify2FaFailure());
    }
  } catch (error) {
    toast.error(t(error.message));
    dispatch(loginFailure({ error }));
    throw error;
  }
};

export const disconnect = (optionDeleteAllPlan) => async (dispatch, getState) => {
  try {
    const { exchange } = getState();
    const resp = await exchangeApi.disconnect(exchange.domain, optionDeleteAllPlan);
    if (resp?.data?.ok) {
      // dispatch(disconnectSuccess());
      // window.location.reload();
      return resp.data.ok;
    } else {
      toast.error(t(resp?.data?.m));
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(t(error.message));
    return false;
  }
};
