import { createSelector } from 'reselect';

export const selectWallet = (state) => state.wallet;
export const selectAddress = createSelector(selectWallet, (item) => ({
  usdtAddr: item.usdtAddr,
  usdtAddrFetching: item.usdtAddrFetching,
}));

export const selectWithdrawConfig = createSelector(
  selectWallet,
  (item) => item.withdrawConfig
);
export const selectRefreshing = createSelector(
  selectWallet,
  (item) => item.refreshing
);
export const selectDemoReloading = createSelector(
  selectWallet,
  (item) => item.demoReloading
);
export const selectBalance = createSelector(
  selectWallet,
  (item) => item.balance
);
export const selectAccountType = createSelector(
  selectWallet,
  (item) => item.accountType
);
