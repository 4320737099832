import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as BsForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FORM_TYPE, OPTIONS_MATCH_ORDER } from '@/shared/constants';

import { Form } from '@/shared/components/form/drawer/Elements';
import Tooltip from '@/shared/components/Tooltip';

const SelectTypeOrder = ({
  optionMatchOder,
  setOptionMatchOder,
  isDefaultBot,
  formType
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Label className="d-flex align-items-center sub-label mt-3 d-flex  align-items-center">
        {t('Type to match order')} <Tooltip text={t('By all tooltip')} />
      </Form.Label>

      <BsForm.Group className="wrapper-form-check">
        <div className="d-flex">
          <BsForm.Check
            label={t('By all')}
            name="option-match-order"
            type="radio"
            value="BY_ALL"
            id="type-match-order1"
            checked={optionMatchOder === OPTIONS_MATCH_ORDER.byAll}
            onChange={() => setOptionMatchOder(OPTIONS_MATCH_ORDER.byAll)}
            className={`${optionMatchOder === OPTIONS_MATCH_ORDER.byAll ? 'active me-3' : 'me-3'}`}
            disabled={isDefaultBot || formType === FORM_TYPE.read_only}
          />

          <BsForm.Check
            label={t('By majority')}
            name="option-match-order"
            type="radio"
            value="BY_MAJORITY"
            id="type-match-order2"
            checked={optionMatchOder === OPTIONS_MATCH_ORDER.byMajority}
            onChange={() => setOptionMatchOder(OPTIONS_MATCH_ORDER.byMajority)}
            className={`${!optionMatchOder === OPTIONS_MATCH_ORDER.byMajority ? 'active' : ''}`}
            disabled={isDefaultBot || formType === FORM_TYPE.read_only}
          />
        </div>
      </BsForm.Group>
    </>
  );
};

SelectTypeOrder.propTypes = {
  optionMatchOder: PropTypes.string.isRequired,
  setOptionMatchOder: PropTypes.func.isRequired,
  isDefaultBot: PropTypes.bool.isRequired,
};

export default SelectTypeOrder;
