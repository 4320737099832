/* eslint-disable react/prop-types */
import React from 'react';
import languages from '@/translations/languages';
import styled from 'styled-components';
import { Image, Modal, ModalHeader } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { setLanguageCode, updateUserConfig } from '@/redux/refactor/appConfig/actions';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Icons from '@/shared/components/icons';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const Item = ({ lang, isActive, onClick }) => {
  const handleSelect = () => onClick(lang.code);
  return (
    <MenuItem
      active={isActive}
      key={lang.code}
      onClick={() => handleSelect(lang.code)}
    >
      <Box gridGap="13px" flex={1} alignItems="center" display="flex">
        <Image style={{ borderRadius: 4 }} width="21px" src={lang.flag} />
        <Box>{lang.name} ({lang?.code.toUpperCase()})</Box>
      </Box>
      {isActive && <Icons.CheckOutlineIcon />}
    </MenuItem>
  );
};

const ModalLanguage = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { profile, currentLangCode } = useSelector(state => ({
    profile: state.exchange.profile,
    currentLangCode: state.appConfig.languageCode,
  }), isEqual);
  const dispatch = useDispatch();
  const handleSelect = (selectedKey) => {
    if (selectedKey !== currentLangCode) {
      // onClose();
      dispatch(updateUserConfig('language', selectedKey));
      dispatch(setLanguageCode(selectedKey));
      i18n.changeLanguage(selectedKey);

      const languageActions = {
        en: 'languageEn',
        vi: 'languageVi',
        th: 'languageTh',
      };
      
      let languageAction = languageActions[selectedKey] || '';      

      ga4Stats(gtag.language.action[languageAction], gtag.language.category, gtag.language.label, profile?.uid);   
    }
  };
  return (
    <ModalStyle show={open} onHide={onClose} centered>
      <ModalHeaderStyle className="d-flex justify-content-between">
        <ModalTitleStyle>{t('Language')}</ModalTitleStyle>
        <Box textAlign="right">
          <ModalSettingBtnClose onClick={onClose} />
        </Box>
      </ModalHeaderStyle>
      <ModalBodyStyle>
        {languages.map(lang => (
          <Item
            key={lang.code}
            isActive={lang.code === currentLangCode}
            lang={lang}
            onClick={handleSelect}
          />
        ))}
      </ModalBodyStyle>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  .modal-dialog {
    max-width: 320px !important;
    margin: auto auto;
  }
  .modal-content {
    overflow: hidden;
    border-radius: 16px !important;
  }
`;

export const ModalHeaderStyle = styled(ModalHeader)`
  padding: 16px;
  .modal-title {
    font-weight: 800;
  }
`;
export const ModalTitleStyle = styled(Modal.Title)`
  font-size: 17px;
  line-height: 140%;
`;

export const ModalBodyStyle = styled(Modal.Body)`
  padding: 16px 5px;
`;

export const ModalSettingBtnClose = styled(Icons.CloseIcon)`
  :hover {
    cursor: pointer;
  }
`;

const MenuItem = styled.div`
  padding: 11.6712px;
  border-radius: 7.78082px;
  gap: 13px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export default ModalLanguage;
