import TextInvalid from '@/shared/components/TextInvalid';
import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { Form as CsForm, Grid } from '@/shared/components/form/drawer/Elements';
import {
  DropdownIndicator,
  DropdownPlaceholder,
} from '@/shared/components/react-select';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import { FORM_TYPE } from '@/shared/constants';

export function InputControl({ label, validObj, ...others }) {
  return (
    <Form.Group className="full-width">
      <CsForm.Label>{label}</CsForm.Label>
      <Form.Control {...others} disabled={others.readOnly} />
      {!others.readOnly && <TextInvalid validObj={validObj} />}
    </Form.Group>
  );
}

export function AllOrders({ show, value, onChange, isDefault, validates, formType }) {
  const { t } = useTranslation();
  const handleChange = (value) => {
    onChange(0, { target: { value: value?.toString() || '' } });
  };
  if (!show) return null;
  return (
    <Grid.Row>
      <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <CsForm.Label>{t('3. Set trade amount')}</CsForm.Label>
        <InputNumberGroup
          input={{
            onChange: handleChange,
            value,
            disabled: isDefault || formType === FORM_TYPE.read_only,
            placeholder: '$0',
          }}
        />
        {!isDefault && formType !== FORM_TYPE.read_only && <TextInvalid validObj={validates[0]} />}
      </Grid.Cell>
    </Grid.Row>
  );
}

export function CustomAutoWinOrVictor({
  show,
  values = [],
  onChange,
  isDefault,
  validates,
  formType
}) {
  const { t } = useTranslation();

  if (!show) return null;

  return values.map((item, idx) => (
    <Grid.Row key={idx}>
      <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <InputControl
          type="text"
          value={item.value}
          label={`${3 + idx}. ${t('Set row')} ${idx + 1}`}
          placeholder={''}
          onChange={(event) => onChange(idx, event)}
          readOnly={isDefault || formType === FORM_TYPE.read_only}
          validObj={validates[idx]}
        />
      </Grid.Cell>
    </Grid.Row>
  ));
}

export function Martingale({
  show,
  value,
  extraValue,
  onInputChange,
  onSelectChange,
  isDefault,
  validates,
  selectOptions,
  formType
}) {
  const { t } = useTranslation();
  if (!show) return null;
  return (
    <Grid.Row cols={2}>
      <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <InputControl
          type="text"
          value={value}
          label={t('3. Set order value')}
          placeholder={''}
          onChange={(event) => onInputChange(0, event)}
          readOnly={isDefault || formType === FORM_TYPE.read_only}
          validObj={validates[0]}
        />
      </Grid.Cell>
      <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <CsForm.Label>{t('4. Option')}</CsForm.Label>
        <ReactSelect
          menuPlacement="auto"
          options={selectOptions}
          value={extraValue}
          onChange={onSelectChange}
          classNamePrefix="react-select-style2"
          className="full-width"
          isDisabled={isDefault || formType === FORM_TYPE.read_only}
          components={{ DropdownIndicator, Placeholder: DropdownPlaceholder }}
        />
      </Grid.Cell>
    </Grid.Row>
  );
}

export function Fibo({
  show,
  value,
  extraValue,
  isDefault,
  validates,
  stepValidate,
  selectOptions,
  loseStepValue,
  winStepValue,
  onInputChange,
  onSelectChange,
  onLoseChange,
  onWinChange,
  formType
}) {
  const { t } = useTranslation();
  const isPc = useMediaQuery(Devices.pc);
  const handleChangeFiboStepLose = (event) => {
    const reg = /^[0-9]*$/;
    if (reg.test(event.target.value.trim())) {
      onLoseChange(event.target.value.trim());
    }
  };

  const handleChangeFiboStepWin = (event) => {
    const reg = /^[0-9]*$/;
    if (reg.test(event.target.value.trim())) {
      onWinChange(event.target.value.trim());
    }
  };

  if (!show) return null;
  return (
    <Fragment>
      <Grid.Row cols={2}>
        <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <InputControl
            type="text"
            value={value}
            label={t('3. Set order value')}
            placeholder={''}
            onChange={(event) => onInputChange(0, event)}
            readOnly={isDefault || formType === FORM_TYPE.read_only}
            validObj={validates[0]}
          />
        </Grid.Cell>
        {isPc ? (
          <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <CsForm.Label>
              {Number(extraValue?.value) === 1
                ? t('Khi thắng sẽ tiến ')
                : t('Khi thua sẽ tiến ')}
            </CsForm.Label>
            <InputNumberGroup
              input={{
                onChange: (value) =>
                  handleChangeFiboStepLose({ target: { value: value?.toString() || '' } }),
                value: loseStepValue,
                disabled: isDefault || formType === FORM_TYPE.read_only,
                placeholder: '0',
              }}
              inputType="text"
            />
            {!isDefault && formType !== FORM_TYPE.read_only && <TextInvalid validObj={stepValidate.lose} />}
          </Grid.Cell>
        ) : (
          <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <CsForm.Label>{t('Advance option')}</CsForm.Label>
            <ReactSelect
              menuPlacement="auto"
              options={selectOptions}
              value={extraValue}
              onChange={onSelectChange}
              classNamePrefix="react-select-style2"
              className="full-width"
              isDisabled={isDefault || formType === FORM_TYPE.read_only}
              components={{
                DropdownIndicator,
                Placeholder: DropdownPlaceholder,
              }}
            />
          </Grid.Cell>
        )}
      </Grid.Row>
      <Grid.Row cols={2}>
        {isPc ? (
          <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <CsForm.Label>{t('Advance option')}</CsForm.Label>
            <ReactSelect
              menuPlacement="auto"
              options={selectOptions}
              value={extraValue}
              onChange={onSelectChange}
              classNamePrefix="react-select-style2"
              className="full-width"
              isDisabled={isDefault || formType === FORM_TYPE.read_only}
              components={{
                DropdownIndicator,
                Placeholder: DropdownPlaceholder,
              }}
            />
          </Grid.Cell>
        ) : (
          <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <CsForm.Label>
              {Number(extraValue?.value) === 1
                ? t('Khi thắng sẽ tiến ')
                : t('Khi thua sẽ tiến ')}
            </CsForm.Label>
            <InputNumberGroup
              input={{
                onChange: (value) =>
                  handleChangeFiboStepLose({ target: { value: value?.toString() || '' } }),
                value: loseStepValue,
                disabled: isDefault  || formType === FORM_TYPE.read_only,
                placeholder: '0',
              }}
              inputType="text"
            />
            {!isDefault && formType !== FORM_TYPE.read_only && <TextInvalid validObj={stepValidate.lose} />}
          </Grid.Cell>
        )}
        <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <CsForm.Label>
            {Number(extraValue?.value) === 1
              ? t('Khi thua sẽ lùi ')
              : t('Khi thắng sẽ lùi ')}
          </CsForm.Label>
          <InputNumberGroup
            input={{
              onChange: (value) =>
                handleChangeFiboStepWin({ target: { value: value?.toString() || '' } }),
              value: winStepValue,
              disabled: isDefault || formType === FORM_TYPE.read_only,
              placeholder: '0',
            }}
            inputType="text"
          />
          {!isDefault && formType !== FORM_TYPE.read_only && <TextInvalid validObj={stepValidate.win} />}
        </Grid.Cell>
      </Grid.Row>
    </Fragment>
  );
}
