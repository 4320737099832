import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import { isEqual } from "lodash";
import { Form as BsForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import { Form } from '@/shared/components/form/drawer/Elements';
import Tooltip from "@/shared/components/Tooltip";
import CustomSelect from "@/shared/components/mui-select-customized/CustomSelect";

import { FORM_TYPE } from "@/shared/constants";

const SelectSignalType = ({botSignalStrategyType, setBotSignalStrategyType }) => {
  const { t } = useTranslation();

  const { currentBot, formType, optionsType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
      optionsType: state.bot.optionsType,
    }),
    isEqual
  );

  useEffect(() => {
    if (optionsType.length && formType === FORM_TYPE.create) {
      setBotSignalStrategyType(optionsType[0].value);
    }
  }, [optionsType, formType]);

  return (
    <BsForm.Group className="full-width">
      <Form.Label className="d-flex align-items-center">
        2. {t('Strategy')}
        <Tooltip
          text={t('Please select the available strategy below to set up your Bot AI.')}
        />
      </Form.Label>
      <CustomSelect
        options={optionsType}
        value={botSignalStrategyType}
        onSelect={setBotSignalStrategyType}
        renderItemKey={(item) => item.value}
        renderItemLabel={(item) => item.label}
        renderItemValue={(item) => item.value}
        disabled={currentBot.is_default || currentBot.copy_from || formType === FORM_TYPE.read_only}
      />
    </BsForm.Group>
  )
}

SelectSignalType.propTypes = {
  botSignalStrategyType: PropTypes.string,
  setBotSignalStrategyType: PropTypes.func.isRequired,
};

export default SelectSignalType;
