/* eslint-disable react/prop-types */
import { TRANSFER_TYPE } from '@/config/const';
import {
  externalWithdraw,
  internalTransfer,
} from '@/redux/refactor/wallet/actions';
import { selectWithdrawConfig } from '@/redux/selectors/walletSelector';
import { Devices } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../Button';
import { ModalBodyStyle } from '../../Modal';
import { FormContainer } from '../../form/FormElements';
import Icons from '../../icons';
import FormWithdraw from '../components/FormWithdraw';
import SelectTransferType from '../components/SelectTransferType';
import {
  ButtonTransfer,
  CardBodyWithFooter,
  CardFooter,
  CardStyle,
  CardTitle,
  FooterHint,
  FormTitle,
} from './styles';

const PanelWithdraw = ({ show, onClose, setFocusedIdx }) => {
  const { t } = useTranslation();
  const { u_min: minUsdt } = useSelector(selectWithdrawConfig, shallowEqual);
  const [type, setType] = useState(TRANSFER_TYPE.INTERNAL);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const back = () => {
    setFocusedIdx(0);
  };
  const onSubmit = async (values) => {
    setLoading(true);
    const handler =
      type === TRANSFER_TYPE.INTERNAL ? internalTransfer : externalWithdraw;
    const ok = await dispatch(handler(values));
    setLoading(false);
    if (ok) {
      back();
    }
  };
  const mutators = {
    setAmount: (args, state, utils) => {
      utils.changeValue(state, 'amount', () => args[0]);
    },
  };

  if (!show) return <div />;
  return (
    <Form onSubmit={onSubmit} mutators={mutators}>
      {({ handleSubmit, invalid, form }) => (
        <CardStyle>
          <CardTitle>
            <Box flex={1}>{t('Exchange wallet')}</Box>
            <Box onClick={onClose}>
              <Icons.CloseIcon />
            </Box>
          </CardTitle>
          <CardBodyWithFooter>
            <FormTitle>{t('Withdraw USDT (BEP-20)')}</FormTitle>
            <FormContainer onSubmit={handleSubmit}>
              <ModalBodyCustomStyle>
                <SelectTransferType type={type} onSelect={setType} />
                <FormWithdraw formRef={form} type={type} />
              </ModalBodyCustomStyle>
            </FormContainer>
          </CardBodyWithFooter>
          <CardFooter>
            <FooterHint>
              {t('The minimum deposit amount is {{minUsdt}} USDT.', {
                minUsdt,
              })}
            </FooterHint>
            <Box width="100%" display="flex" gridGap="12px">
              <Button onClick={back} variant="secondary_outline">
                {t('< Back')}
              </Button>
              <Box flex={1}>
                <ButtonTransfer
                  variant="secondary"
                  block="true"
                  onClick={handleSubmit}
                  isLoading={loading}
                  disabled={loading || invalid}
                >
                  {t('Send USDT')}
                </ButtonTransfer>
              </Box>
            </Box>
          </CardFooter>
        </CardStyle>
      )}
    </Form>
  );
};

export default PanelWithdraw;

const ModalBodyCustomStyle = styled(ModalBodyStyle)`
  padding: 24px 12px;
  @media ${Devices.pc} {
    padding: 24px 16px;
  }
`;
