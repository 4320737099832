import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _, { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { drawSharePlanCanvas, sizeCanvas, clearCanvas } from '../helpers/canvas';
import { selectLeaderBoard } from '@/redux/selectors/dashboardSelector';

const SharePlanCanvas = ({
  id,
  screenSize,
  currentPlan,
  profit,
  percentProfit,
  titleCanvas,
  timePlan
}) => {
  const { t } = useTranslation();
  const [isSupportedCanvas, setIsSupportedCanvas] = useState(false);
  const canvas = useRef();
  const [indexUserLeaderboard, setIndexUserLeaderboard] = useState(-1);

  const { profile, topUsers } = useSelector(
    (state) => ({
      profile: state.user.profile,
      topUsers: selectLeaderBoard(state),
    }),
    isEqual
  );

  useEffect(() => {
    const context = canvas.current.getContext('2d');
    if (context) {
      setIsSupportedCanvas(true);
      drawSharePlanCanvas(t, context, profile, screenSize, currentPlan, profit, percentProfit, titleCanvas, indexUserLeaderboard, timePlan);
    } else {
      setIsSupportedCanvas(false);
    }

    return () => {
      clearCanvas(screenSize.isMobile, sizeCanvas, context);
    }
  }, [profile, screenSize, currentPlan, profit, percentProfit, titleCanvas, indexUserLeaderboard, timePlan]);

  useEffect(() => {
    if (topUsers.length && profile?.uid) {
      setIndexUserLeaderboard(_.findIndex(topUsers, (user) => user.userId === profile?.uid));
    }
  }, [topUsers, profile]);

  return (
    <canvas ref={canvas} id={id} crossOrigin="anonymous">
      {!isSupportedCanvas && 'No support Canvas.'}
    </canvas>
  );
};

SharePlanCanvas.propTypes = {
  id: PropTypes.string.isRequired,
  screenSize: PropTypes.instanceOf(Object).isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  profit: PropTypes.number.isRequired,
  percentProfit: PropTypes.number.isRequired,
  titleCanvas: PropTypes.string.isRequired,
  timePlan: PropTypes.string.isRequired
};

export default SharePlanCanvas;
