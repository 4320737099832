import React from "react";
import PropTypes from 'prop-types';
import {
  Like1,
  Eye,
  Link2,  
} from 'iconsax-react';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';

import "./index.scss";

const PostInteract = ({postId}) => {
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <div className="post-interact">
      {/* <button type="button" className="post-interact-btn">
        <Like1
          size="24"
          color="#718096"
        />
        <span className="post-interact-count">12</span>
      </button>

      <button type="button" className="post-interact-btn">
        <Eye
          size="24"
          color="#718096"
        />
        <span className="post-interact-count">12</span>
      </button> */}

      <button type="button" className="post-interact-btn" onClick={() => copy(`${location.host}/quick-insight/${postId}`)}>
        <Link2
          size="24"
          color="#718096"
        />
      </button>
    </div>
  );
};

PostInteract.propTypes = {
  postId: PropTypes.number.isRequired,
};

export default PostInteract;