import { handleActions } from 'redux-actions';
import { FORM_TYPE } from '@/shared/constants';
import {
  updateStrategies,
  updatePagination,
  updateIsFetching,
  updateCurrentStrategy,
  updateFormTypeStrategy,
  updateFormStatusStrategy,
  updateStrategyMethods,
  updateStrategyOptions,
  updateLatestCreatedStrategy
} from '../actions/strategyActions';

const defaultState = {
  strategies: [],
  pagination: {},
  isFetching: true,
  currentStrategy: {},
  formStrategy: {
    type: FORM_TYPE.create,
    isShow: false,
  },
  strategyMethods: [],
  strategyOptions: [],
  latestCreatedStrategy: {}
};

export default handleActions(
  {
    [updateStrategies](state, { payload }) {
      return {
        ...state,
        strategies: payload,
      };
    },
    [updatePagination](state, { payload }) {
      return {
        ...state,
        pagination: payload,
      };
    },
    [updateIsFetching]: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    [updateCurrentStrategy]: (state, { payload }) => ({
      ...state,
      currentStrategy: payload,
    }),
    [updateFormTypeStrategy]: (state, { payload }) => ({
      ...state,
      formStrategy: { ...state.formStrategy, type: payload },
    }),
    [updateFormStatusStrategy]: (state, { payload }) => ({
      ...state,
      formStrategy: { ...state.formStrategy, isShow: payload },
    }),
    [updateStrategyMethods]: (state, { payload }) => ({
      ...state,
      strategyMethods: payload,
    }),
    [updateStrategyOptions]: (state, { payload }) => ({
      ...state,
      strategyOptions: payload,
    }),
    [updateLatestCreatedStrategy]: (state, { payload }) => ({
      ...state,
      latestCreatedStrategy: payload,
    }),
  },
  defaultState
);
