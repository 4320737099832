import { useState, useEffect } from 'react';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [gridSize, setGridSize] = useState();

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if (windowSize.width >= 1400) {
      setGridSize('xxl');
    } else if (windowSize.width >= 1200) {
      setGridSize('xl');
    } else if (windowSize.width >= 992) {
      setGridSize('lg');
    } else if (windowSize.width >= 768) {
      setGridSize('md');
    } else if (windowSize.width >= 576) {
      setGridSize('sm');
    } else if (windowSize.width < 576) {
      setGridSize('xs');
    }
  }, [windowSize.width]);

  return gridSize;
}

export default useWindowSize;
