import axios, { defaultParams, getApiUrl } from './axios';

export default async (url, body, params) => {
  try {
    return await axios.put(getApiUrl(url), body, { ...defaultParams(), ...params });
  } catch (e) {
    console.trace(e);
    if (!e || !e.response || e.response.status !== 401) { throw e; }
    return null;
  }
};
