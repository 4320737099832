import authApi from '@/utils/api/authApi';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';

export const prepare2FaRequest = createAction('prepare2FaRequest');
export const prepare2FaSuccess = createAction('prepare2FaSuccess');
export const prepare2FaFailed = createAction('prepare2FaFailed');

export const getCode2FaRequest = createAction('getCode2FaRequest');
export const getCode2FaSuccess = createAction('getCode2FaSuccess');
export const getCode2FaFailed = createAction('getCode2FaFailed');

export const enable2FaRequest = createAction('enable2FaRequest');
export const enable2FaSuccess = createAction('enable2FaSuccess');
export const enable2FaFailed = createAction('enable2FaFailed');

export const disable2FaRequest = createAction('2fa/disable2FaRequest');
export const disable2FaSuccess = createAction('2fa/disable2FaSuccess');
export const disable2FaFailed = createAction('2fa/disable2FaFailed');

export const setEnabled2Fa = createAction('setEnabled2Fa');

export const prepare2Fa = () => async (dispatch) => {
  try {
    dispatch(prepare2FaRequest());
    const resp = await authApi.prepare2Fa();
    if (resp?.data?.ok) {
      dispatch(prepare2FaSuccess(resp?.data?.d));
    } else {
      dispatch(prepare2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(prepare2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const getCode2Fa = (captcha) => async (dispatch, getState) => {
  try {
    const { isEnabled } = getState().twoFactor;
    const request = isEnabled
      ? authApi.getCodeDeactivate2fa
      : authApi.getCodeActivate2fa;
    dispatch(getCode2FaRequest());
    const resp = await request(captcha);
    if (resp?.data?.ok) {
      dispatch(getCode2FaSuccess(resp?.data?.d?.data));
    } else {
      dispatch(getCode2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(getCode2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const enable2Fa = (params) => async (dispatch) => {
  try {
    dispatch(enable2FaRequest());
    const resp = await authApi.enable2Fa(params);
    if (resp?.data?.ok) {
      dispatch(enable2FaSuccess(resp?.data?.d));
    } else {
      dispatch(enable2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(enable2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const disable2Fa = (params) => async (dispatch) => {
  try {
    dispatch(disable2FaRequest());
    const resp = await authApi.disable2Fa(params);
    if (resp?.data?.ok) {
      dispatch(disable2FaSuccess(resp?.data?.d));
    } else {
      dispatch(disable2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(enable2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};
