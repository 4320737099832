import { handleActions } from 'redux-actions';
import {
  updateOverviewAffiliation,
  updateIsFetching,
} from '../actions/affiliationActions';

const defaultState = {
  overviewAffiliattion: {},
  isFetching: true,
};

export default handleActions(
  {
    [updateOverviewAffiliation](state, { payload }) {
      return {
        ...state,
        overviewAffiliattion: payload,
      };
    },
    [updateIsFetching]: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
  },
  defaultState,
);
