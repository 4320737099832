import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import {
  borderLeft,
  borderRight,
  marginLeft,
  marginRight,
  right,
  left,
} from '@/utils/directions';
import { Box } from '@material-ui/core';
import {
  colorAccent,
  colorBackground,
  colorBackgroundBody,
  colorBlue,
  colorDarkRed,
  colorLightText,
  colorVeryLightRed,
  colorWhite,
  logoImg,
  colorGreyScale600,
  colorGreyScale300,
  colorSuccess,
  colorText,
  Devices,
} from '../../../utils/palette';
import { PasswordIcon } from '../form/Password';
import { Button } from '../Button';
import {
  FormContainer,
  FormGroup,
  FormGroupIcon,
  FormFieldButton,
  FormGroupLabel,
} from '../form/FormElements';

const iOS = () => [
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform);

export const AccountWrap = styled.div`
  height: 100vh;
  display: grid;
  background: ${colorBackgroundBody};
  grid-template-columns: 1;
  overflow: hidden;
  @media ${Devices.pc} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AccountContent = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  overflow-y: auto;
`;

export const HelpLink = styled.div`
  /* position: absolute; */
  /* bottom: 20px; */
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #718096;
  margin-bottom: 24px;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

export const AccountCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 16px;
  max-width: 486px;
  width: 100%;
  background-color: ${colorBackground};
  
  @media ${Devices.pc} {
    padding: 28px 13px;
  }
`;

export const AccountHead = styled.div`
  margin-bottom: 40px;
`;

export const AccountTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  @media ${Devices.pc} {
    font-size: 32px;
  }
`;

export const AccountGetStarted = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${colorGreyScale600};
  a {
    color: ${colorSuccess};
    font-weight: 600;
  }
  button {
    padding: 0;
    border: none ;
    background: none;
    outline: none;
    color: ${colorSuccess};
    font-weight: 600;
  }
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

export const AccountLogo = styled.span`
  display: block;
  width: 148px;
  height: 32px;
  background-image: ${logoImg};
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 40px;
  @media ${Devices.pc} {
    width: 180px;
    height: 40px;
  }
`;

export const AccountLogoAccent = styled.span`
  color: ${colorBlue};
`;

export const AccountOr = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
  position: relative;
  p {
    color: ${colorGreyScale600};
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  &:before,
  &:after {
    content: '';
    height: 1px;
    width: calc(50% - 90px);
    background: ${colorGreyScale300};
    position: absolute;
    top: 12px;
  }

  &:before {
    ${left}: 0;
  }

  &:after {
    ${right}: 0;
  }
`;

export const AccountSocial = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 32px;
  > a {
    flex: 1;
  }
`;

export const AccountPhoto = styled(AccountWrap)`
  background: url(img/404/bg_404.jpeg) no-repeat center;
  background-size: cover;

  ${FormGroupLabel} {
    color: ${colorLightText};
  }

  ${AccountCard} {
    background-color: rgba(0, 10, 16, 0.8);
    color: ${colorWhite};
  }

  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton} {
    background: transparent;
  }

  ${FormGroupIcon}, ${PasswordIcon}, input:not(:last-child) {
    ${borderRight}: none;
  }

  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton}, input {
    border-color: ${colorWhite};
  }

  ${FormFieldButton}, input {
    ${borderLeft}: none
  }

  ${FormFieldButton}.active {
    svg {
      fill: ${colorAccent};
    }
  }

  input {
    color: ${colorWhite};
    box-shadow: none;
  }

  p {
    color: ${colorWhite};
  }

  ${AccountTitle} {
    color: ${colorWhite};
  }
`;

export const AccountButtons = styled.div`
  display: flex;
  width: calc(100% + 10px);
  margin-top: -10px;
  margin-bottom: -20px;
  ${marginRight}: 0;
  ${marginLeft}: -10px;

  a {
    margin-bottom: 20px;
    white-space: nowrap;
    ${marginRight}: 0;
    ${marginLeft}: 10px;
  }

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

export const AccountFormContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1
`;

export const AccountButton = styled(Button)`
  width: 100%;
  ${marginRight}: 0;
  font-weight: 700;
`;

export const AccountForgotPassword = styled.div`
  ${right}: 0;
  a {
    color: ${colorAccent};
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    &:hover {
      text-decoration: none;
    }
    @media ${Devices.pc} {
      font-size: 16px;
    }
  }
`;

export const ForgotFormGroup = styled(FormGroup)`
  margin-bottom: 40px;
`;

export const AccountHaveAccount = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    // transition: all 0.3s;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const LoginForm = styled(FormContainer)`
  .alert-danger {
    color: ${colorDarkRed};
    background-color: ${colorVeryLightRed};
  }
  .alert {
    position: relative;
    padding: 12px 20px;
    margin-bottom: 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
  }
`;

export const AccountSocialButton = styled.a`
  display: inline-flex;
  border-radius: 12px;
  height: 56px;
  padding: 0px 24px;
  // transition: all 0.3s;
  border: 1px solid #EEEFF2;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  color: ${colorText};
  svg {
    margin-right: 12px;
  }
`;
