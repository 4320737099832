import useCaptcha from '@/hooks/useCaptcha';
import useQuery from '@/hooks/useQuery';
import { AccountCard } from '@/shared/components/account/AccountElements';
import AccountWithoutBanner from '@/shared/components/account/AccountWithoutBanner';
import authApi from '@/utils/api/authApi';
import { cardBgColor, Devices } from '@/utils/palette';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import CardResetPassword from './components/CardResetPassword';
import CardResetPasswordSuccess from './components/CardResetPasswordSuccess';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const query = useQuery();
  const { execute } = useCaptcha();

  const handleSubmit = async (values) => {
    setLoading(true);
    const captcha = await execute('resetPassword');
    const { ok, message } = await authApi.resetPassword({
      ...values,
      email: query.get('e'),
      code: query.get('c'),
      captcha,
    });
    if (ok) {
      setIsSuccess(true);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  return (
    <AccountWithoutBanner>
      <Helmet title="Reset Password | Quickinvest.ai" />
      <AccountCardStyle>
        {!isSuccess && (
          <CardResetPassword handleSubmit={handleSubmit} loading={loading} />
        )}
        {isSuccess && <CardResetPasswordSuccess />}
      </AccountCardStyle>
    </AccountWithoutBanner>
  );
};

const AccountCardStyle = styled(AccountCard)`
  height: auto;
  max-width: 552px;
  padding: 16px;
  border-radius: 24px;
  background: ${cardBgColor}!important;
  @media ${Devices.pc} {
    padding: 40px;
  }
`;

export default ResetPassword;
