import React from "react";
import PropTypes from 'prop-types';
import { Form as BsForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Form } from '@/shared/components/form/drawer/Elements';
import TextInvalid from "@/shared/components/TextInvalid";

import { FORM_TYPE } from "@/shared/constants";

const InputSignalName = ({inputRef, isDefaultBot, botName, formType, validBotName, handleChangeBotName }) => {
  const { t } = useTranslation();

  return (
    <BsForm.Group className="full-width">
      <Form.Label>1. {t('Bot name')}*</Form.Label>
      <BsForm.Control
        ref={inputRef}
        type="text"
        placeholder={t('Enter Bot Name')}
        value={isDefaultBot ? t(botName) : botName}
        onChange={handleChangeBotName}
        disabled={isDefaultBot || formType === FORM_TYPE.read_only}
      />
      <TextInvalid validObj={validBotName} />
    </BsForm.Group>
  )
}

InputSignalName.propTypes = {
  inputRef: PropTypes.instanceOf(Object).isRequired,
  isDefaultBot: PropTypes.bool,
  botName: PropTypes.string,
  formType: PropTypes.string.isRequired,
  validBotName: PropTypes.instanceOf(Object).isRequired,
  handleChangeBotName: PropTypes.func.isRequired,
};

InputSignalName.defaultProps = {
  isDefaultBot: false
}

export default InputSignalName;
