import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ORDER_NEXT, ORDER_TYPES, conditionValidate } from '../../const';

import Exclamation from '@/shared/img/exclamation.svg';

const Bubble = memo(({ onSelect, value, state, currentBubble, isEdit, isDefault, isReadOnly }) => {
  // console.log('currentBubble-value', currentBubble, value);
  const onClick = () => {
    if (isDefault || isReadOnly) return;
    if (conditionValidate(currentBubble, value)) {
      onSelect(value, state ? ORDER_NEXT[state] : ORDER_TYPES.BUY);
    }
  };
  const valid = conditionValidate(currentBubble, value);
  return (
    <span
      data-value={value}
      className={classNames('BubbleConditions__panel-item', {
        'order-buy': state === ORDER_TYPES.BUY,
        'order-sell': state === ORDER_TYPES.SELL,
        invalid: !valid,
        disabled: isDefault,
      })}
      onClick={onClick}
    >
      {!valid && isEdit && state && <Exclamation />}
    </span>
  );
});

Bubble.propTypes = {
  isReadOnly: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDefault: PropTypes.bool,
  value: PropTypes.number.isRequired,
  currentBubble: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([undefined])
  ]),
  onSelect: PropTypes.func,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([undefined])
  ])
};

Bubble.defaultProps = {
  isReadOnly: false,
  isEdit: false,
  isDefault: false,
  onSelect: () => {},
  currentBubble: undefined,
  state: undefined,
};

export default Bubble;
