import { isEqual } from 'lodash';
import TextInvalid from '@/shared/components/TextInvalid';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TAB, conditionValidate } from '../const';
import { CandlePatternContext } from '../context';
import Results from './Results';
import './index.scss';
import SkipCandleBoard from './SkipCandleBoard';
import { Box } from '@material-ui/core';

export default function FormCandlePattern({
  botName,
  skipConfig,
  copyFrom,
  validBotName,
  onChangeName,
  onChangeSkipConfig,
  setTab,
  isDefault,
  inputRef,
}) {
  const { t } = useTranslation();
  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formCandle.type,
    }),
    isEqual
  );

  return (
    <div className="FrmCandle">
      <div className="FrmCandle__body">
        <Grid>
          <Grid.Row>
            <Grid.Cell>
              <Box mb={2} className="form-group full-width">
                <Form.Label>{t('Bot name')}</Form.Label>
                <input
                  ref={inputRef}
                  value={botName}
                  onChange={onChangeName}
                  placeholder={t('Enter Bot Name')}
                  className="form-control"
                  disabled={isDefault}
                />
                <TextInvalid validObj={validBotName} />
              </Box>
              {!copyFrom && (
                <div className="form-group full-width">
                  <SkipCandleBoard {...skipConfig} onChange={onChangeSkipConfig} formType={formType} />
                </div>
              )}
            </Grid.Cell>
          </Grid.Row>
          {!copyFrom && (
            <Grid.Row>
              <Grid.Cell>
                <AddBubble setTab={setTab} />
              </Grid.Cell>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </div>
  );
}

function AddBubble({ setTab }) {
  const { t } = useTranslation();
  const { isDefault, result, onEdit, onRemoveBubble, onDuplicate } =
    useContext(CandlePatternContext);
  const limited = useSelector((state) => state.appConfig?.siteConfig?.candle_pattern_limited || 10);
  const handleAdd = () => {
    if (bubbleLen < limited) {
      setTab('candle');
    }
  };
  const bubbleLen = result.length;
  return (
    <div className="form-group full-width">
      <Form.Label>
        {t('Bubble Conditions')} ({bubbleLen}/{limited})
      </Form.Label>
      <BubbleError setTab={setTab} />
      {!isDefault && bubbleLen < limited && (
        <button onClick={handleAdd} className="FrmCandle__button-add">
          + {t('Add Bubble')}
        </button>
      )}
      <Results
        result={result}
        onEdit={onEdit}
        onRemoveBubble={onRemoveBubble}
        onDuplicate={onDuplicate}
        setTab={setTab}
        isDefault={isDefault}
        allowDuplicate={bubbleLen < limited}
      />
    </div>
  );
}

function BubbleError({ setTab }) {
  const { t } = useTranslation();
  const { result = [], onEdit } = useContext(CandlePatternContext);
  const handleEdit = () => {
    onEdit(0);
    setTab(TAB.CANDLE);
  };
  const numOfError =
    result.filter((record) =>
      Object.entries(record.sources).find((item) => !conditionValidate(record.candle, item[0]))
    ) || [];
  if (numOfError.length === 0) return null;
  return (
    <div className="alert alert-danger">
      {t('You have {{amount}} bubbles currently with incorrect conditions.', {
        amount: numOfError.length,
      })}{' '}
      <span onClick={handleEdit} className="a">
        {t('Update now')}
      </span>
    </div>
  );
}
