import axios, { defaultParams, getApiUrl } from './axios';

export default async (url, params) => {
  try {
    return await axios.get(getApiUrl(url), { ...defaultParams(), ...params });
  } catch (e) {
    console.trace(url, e);
    if (!e || !e.response || e.response.status !== 401) { throw e; }
    return e;
  }
};
