/* eslint-disable max-len */
import useCaptcha from '@/hooks/useCaptcha';
import { AccountTitle } from '@/shared/components/account/AccountElements';
import ButtonCountdown from '@/shared/components/account/ButtonCountdown';
import SuccessImg from '@/shared/img/registerSuccess.svg';
import authApi from '@/utils/api/authApi';
import { Devices, colorGreyScale600, colorSuccess } from '@/utils/palette';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

let timerId = null;
const RegisterSuccess = () => {
  const { t } = useTranslation();
  const { email, isRegisterSuccess } = useSelector(
    (state) => state.user,
    _.isEqual
  );
  const [isResending, setIsResending] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [textCountdown, setTextCountdown] = useState('00:30');
  const { execute } = useCaptcha();

  const setCountDown = () => {
    let count = 30;
    setIsDisabled(true);
    if (timerId) clearInterval(timerId);
    timerId = setInterval(() => {
      if (count) {
        count -= 1;
        setTextCountdown(`00:${count <= 9 ? 0 : ''}${count}`);
      } else {
        setIsDisabled(false);
        setTextCountdown('00:30');
        clearInterval(timerId);
        timerId = null;
      }
    }, 1000);
  };
  const sendEmail = async () => {
    setIsResending(true);
    const captcha = await execute('sendVerifyEmail');
    authApi.sendVerifyEmail({ email, captcha }).then(
      (resp) => {
        if (resp?.data?.ok) {
          setIsResending(false);
          setCountDown(30);
        }
      },
      (error) => {
        toast.error(error.message);
        setIsResending(false);
      }
    );
  };

  useEffect(() => {
    if (isRegisterSuccess) setCountDown();
    return () => {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
    };
  }, [isRegisterSuccess]);

  return (
    <Fragment>
      <SuccessImg />
      <Box marginTop="32px" marginBottom="16px">
        <AccountTitle>
          {t('You have successfully created an account')}
        </AccountTitle>
      </Box>
      <Description
        dangerouslySetInnerHTML={{
          __html: t(
            '<p>A verification link has been sent to <a>{{email}}</a></p> <p>Please wait a few minutes and get verification link to active your account.</p>',
            { email }
          ),
        }}
      />
      <ButtonCountdownStyle
        disabled={isResending || isDisabled}
        variant="dark"
        size="lg"
        onClick={sendEmail}
      >
        {isDisabled ? textCountdown : t('Request a new one')}
      </ButtonCountdownStyle>
    </Fragment>
  );
};

const Description = styled.div`
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: ${colorGreyScale600};
    margin-bottom: 20px;
    a {
      color: ${colorSuccess}!important;
    }
    @media ${Devices.pc} {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
`;
const ButtonCountdownStyle = styled(ButtonCountdown)`
  margin-bottom: 32px;
`;

export default RegisterSuccess;
