import { React, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Box } from '@material-ui/core';
import { Setting2 } from 'iconsax-react';

import { DATA_DEFAULT, FORM_TYPE, STRATEGY_METHOD } from '@/shared/constants';
import strategyApi from '@/utils/api/strategyApi';
import { getStrategies as getCommonStrategies } from '@/redux/refactor/common/actions';
import {
  getStrategies,
  getStrategyMethods,
  getStrategyOptions,
  updateCurrentStrategy,
  updateFormTypeStrategy,
  updateLatestCreatedStrategy,
} from '@/redux/actions/strategyActions';
import {
  checkValidStrategyName,
  checkValidOrderValue,
  checkValidStep,
  parseStrategyOptions,
} from './formStrategy.helpers';
import gtag from '@/shared/constants/gtag';

import TextInvalid from '@/shared/components/TextInvalid';
import Tooltip from '@/shared/components/Tooltip';
import { CsAccordion, Form as CsForm, Grid } from '@/shared/components/form/drawer/Elements';
import { AllOrders, CustomAutoWinOrVictor, Fibo, Martingale } from './MethodElement';
import { SelectMethod } from './SelectMethod';
import StrategyExplain from './components/StrategyExplain';
import FormFooter from './components/FormFooter';
import FormReloadDemoBalance from './components/FormReloadDemoBalance';
import FormStopPlan from './components/FormStopPlan';

import './index.scss';

const ga4Stats = window.ga4Stats;


const FormStrategy = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const {
    siteConfig = {},
    pagination,
    currentStrategy,
    formType,
    strategyMethods,
    strategyOptionsRaw,
  } = useSelector(
    (state) => ({
      siteConfig: state.appConfig.siteConfig,
      pagination: state.strategy.pagination,
      currentStrategy: state.strategy.currentStrategy,
      formType: state.strategy.formStrategy.type,
      strategyMethods: state.strategy.strategyMethods,
      strategyOptionsRaw: state.strategy.strategyOptions,
    }),
    isEqual
  );

  const [isLoading, setIsLoading] = useState(false);
  const [strategyName, setStrategyName] = useState('');
  const [strategyMethod, setStrategyMethod] = useState('');
  const [strategyOrderValue, setStrategyOrderValue] = useState([{ id: 0, value: '' }]);
  const [strategyStopNotEnoughBalance, setStrategyStopNotEnoughBalance] = useState(true);
  const [strategyReloadDemoBalance, setStrategyReloadDemoBalance] = useState(false);
  const [strategyOptions, setStrategyOptions] = useState([]);
  const [strategyOption, setStrategyOption] = useState({});
  const [fiboStepLose, setFiboStepLose] = useState(1);
  const [fiboStepWin, setFiboStepWin] = useState(1);

  const [validStrategyName, setValidStrategyName] = useState({
    isValid: !(formType === FORM_TYPE.create),
    text: '',
  });
  const [validateOrderValue, setValidateOrderValue] = useState([
    {
      isValid: !(formType === FORM_TYPE.create),
      text: '',
    },
  ]);
  const [validateStep, setValidateStep] = useState({
    win: {
      isValid: !(formType === FORM_TYPE.create),
      text: '',
    },
    lose: {
      isValid: !(formType === FORM_TYPE.create),
      text: '',
    },
  });
  const [sumaryValid, setSumaryValid] = useState(!(formType === FORM_TYPE.create));

  useEffect(() => {
    dispatch(getStrategyOptions());
    dispatch(getStrategyMethods());
  }, []);

  useEffect(() => {
    if (strategyOptionsRaw.length && strategyMethod) {
      const selectOps = parseStrategyOptions(strategyMethod, strategyOptionsRaw, t);
      // console.log('strategyMethod', strategyMethod, strategyOptionsRaw, selectOps)
      setStrategyOptions(selectOps);
      setStrategyOption(selectOps[0]);
    }
  }, [strategyOptionsRaw, strategyMethod]);

  useEffect(() => {
    if (strategyMethods.length && !strategyMethod) {
      setStrategyMethod(strategyMethods[0].code);
    }
  }, [strategyMethods, formType]);

  useEffect(() => {
    setValidateStep(checkValidStep(fiboStepLose, fiboStepWin, t));
  }, [fiboStepLose, fiboStepWin, t]);

  useEffect(() => {
    const summary = validateOrderValue.reduce((sum, next) => sum && next.isValid, true);

    setSumaryValid(
      summary && validStrategyName.isValid && validateStep.win.isValid && validateStep.lose.isValid
    );
  }, [validateOrderValue, validStrategyName, validateStep]);

  useEffect(() => {
    if (currentStrategy.id) {
      setStrategyName(currentStrategy.budget_name);

      if (currentStrategy.method_code.includes(STRATEGY_METHOD.martingale)) {
        setStrategyMethod(STRATEGY_METHOD.martingale);
      } else {
        setStrategyMethod(currentStrategy.method_code);
      }

      setStrategyStopNotEnoughBalance(currentStrategy.stop_not_enough_balance);
      setStrategyReloadDemoBalance(currentStrategy.auto_reload_demo);

      let groupOrder = [];

      if (currentStrategy.method_code !== STRATEGY_METHOD.fibo) {
        groupOrder = currentStrategy.method_data.map((item, index) => ({
          id: index,
          value: item,
        }));
        setStrategyOrderValue(groupOrder);
      } else if (!currentStrategy.copy_from) {
        groupOrder = [{ id: 0, value: currentStrategy.method_data[0] }];
        setStrategyOrderValue(groupOrder);
        const fiboSteps = currentStrategy.method_data[1].split('-');
        setFiboStepLose(fiboSteps[0]);
        setFiboStepWin(fiboSteps[1]);
      }

      const listObjValid = [];
      for (let index = 0; index < groupOrder.length; index += 1) {
        listObjValid.push({ isValid: 1, text: '' });
      }
      setValidateOrderValue(listObjValid);
    }
  }, [currentStrategy]);

  useEffect(() => {
    if (currentStrategy.id) {
      initializeStrategy(currentStrategy, strategyOptions);
    }
  }, [currentStrategy, strategyOptions]);

  const initializeStrategy = (strategy, strategyOptions) => {
    // setStrategyName(strategy.budget_name);
    // if (strategy.method_code.includes(STRATEGY_METHOD.martingale)) {
    //   setStrategyMethod(STRATEGY_METHOD.martingale);
    // } else {
    //   setStrategyMethod(strategy.method_code);
    // }
    // setStrategyStopNotEnoughBalance(strategy.stop_not_enough_balance);
    // setStrategyReloadDemoBalance(strategy.auto_reload_demo);

    // let groupOrder = [];

    // if (strategy.method_code !== STRATEGY_METHOD.fibo) {
    //   groupOrder = strategy.method_data.map((item, index) => ({
    //     id: index,
    //     value: item,
    //   }));
    //   setStrategyOrderValue(groupOrder);
    // } else if (!strategy.copy_from) {
    //   groupOrder = [{ id: 0, value: strategy.method_data[0] }];
    //   setStrategyOrderValue(groupOrder);
    //   const fiboSteps = strategy.method_data[1].split('-');
    //   setFiboStepLose(fiboSteps[0]);
    //   setFiboStepWin(fiboSteps[1]);
    // }

    // const listObjValid = [];
    // for (let index = 0; index < groupOrder.length; index += 1) {
    //   listObjValid.push({ isValid: 1, text: '' });
    // }
    // setValidateOrderValue(listObjValid);

    if (currentStrategy?.method_code && strategyOptions.length) {
      if (
        currentStrategy.method_code.includes(STRATEGY_METHOD.martingale) &&
        strategyMethod === STRATEGY_METHOD.martingale
      ) {
        const opt = strategyOptions.find((item) => item.value === currentStrategy.method_code);
        setStrategyOption(opt);
      }
      if (
        currentStrategy.method_code.includes(STRATEGY_METHOD.fibo) &&
        strategyMethod === STRATEGY_METHOD.fibo
      ) {
        const opt = strategyOptions.find((item) => item.value === currentStrategy.method_data[2]);
        setStrategyOption(opt);
      }
    }
  };

  const handleChangeStrageyName = (event) => {
    setStrategyName(event.target.value);
    setValidStrategyName(checkValidStrategyName(event.target.value, t));
  };

  const handleChangeStrageyMethod = (event) => {
    if (currentStrategy.copy_from) {
      return;
    }
    const method = event.value;
    if (method === STRATEGY_METHOD.martingale) {
      setStrategyOrderValue([{ id: 0, value: DATA_DEFAULT.MARTINGALE[0] }]);
      setValidateOrderValue([{ isValid: true, text: '' }]);
    } else if (method.includes(STRATEGY_METHOD.fibo)) {
      // console.log('DATA_DEFAULT.FIBO[0]', DATA_DEFAULT.FIBO[0])
      setStrategyOrderValue([{ id: 0, value: DATA_DEFAULT.FIBO[0] }]);
      setValidateOrderValue([{ isValid: true, text: '' }]);
    } else if (method.indexOf(STRATEGY_METHOD.victor) > -1 || method === STRATEGY_METHOD.autowin) {
      const listOrder = [];
      const listObjValid = [];
      for (let index = 0; index < DATA_DEFAULT[method].length; index += 1) {
        listOrder.push({ id: index, value: DATA_DEFAULT[method][index] });
        listObjValid.push({ isValid: true, text: '' });
      }
      setStrategyOrderValue(listOrder);
      setValidateOrderValue(listObjValid);
    } else {
      setStrategyOrderValue([{ id: 0, value: '' }]);
      setValidateOrderValue([{ isValid: false, text: '' }]);
    }

    setStrategyMethod(method);
  };

  const handleChangeStrageyOrderValue = (index, event) => {
    const data = [...strategyOrderValue];
    const val = event.target.value.replaceAll('--', '-').replaceAll(' ', '');
    if (strategyMethod === STRATEGY_METHOD.all_order) {
      if (validator.isNumeric(val)) {
        data[index].value = Number(val);
      } else {
        data[index].value = val;
      }
    } else {
      data[index].value = val;
    }

    setStrategyOrderValue(data);

    const objValid = [...validateOrderValue];
    objValid[index] = checkValidOrderValue(data[index].value, index, strategyMethod, siteConfig, t);
    setValidateOrderValue(objValid);
  };

  const handleChangeStrategyStopNotEnoughBalance = (event) => {
    setStrategyStopNotEnoughBalance(event.target.checked);
  };

  const handleChangeStrategyReloadDemoBalance = (event) => {
    setStrategyReloadDemoBalance(event.target.checked);
  };

  const processStrategyOrderValue = () => {
    const orderValues = strategyOrderValue.map((order) => order.value);
    if (strategyMethod.includes(STRATEGY_METHOD.fibo)) {
      orderValues.push(`${Number(fiboStepLose)}-${Number(fiboStepWin)}`);
      orderValues.push(strategyOption.value);
    }
    return orderValues;
  };

  const handleStrategyAPIResponse = (response) => {
    if (response?.data?.ok) {
      toast.success(
        t(
          formType === FORM_TYPE.create
            ? 'Create the strategy successfully!'
            : 'Edit the strategy successfully!'
        )
      );
      if (formType === FORM_TYPE.create) {
        dispatch(updateLatestCreatedStrategy({ id: response?.data?.d }));
      }
      dispatch(getCommonStrategies());
      dispatch(getStrategies({ page: pagination.p, size: pagination.s }));
      onClose();
    } else {
      toast.error(t(response.data.m));
    }
  };

  const manageStrategy = (groupStrategyOrderValue, isCreating) => {
    const strategyData = {
      name: strategyName,
      methodType:
        strategyMethod === STRATEGY_METHOD.martingale ? strategyOption.value : strategyMethod,
      methodData: groupStrategyOrderValue,
      stopWhenNotEnoughBalance: strategyStopNotEnoughBalance,
      autoReloadDemo: strategyReloadDemoBalance,
      ...(isCreating ? {} : { id: currentStrategy.id, copy_from: currentStrategy.copy_from }),
    };

    return isCreating
      ? strategyApi.createStrategy(strategyData)
      : strategyApi.editStrategy(strategyData);
  };

  const saveStrategy = () => {
    if (sumaryValid) {
      setIsLoading(true);

      const groupStrategyOrderValue = processStrategyOrderValue();
      const isCreating = formType === FORM_TYPE.create || currentStrategy.is_customize;

      manageStrategy(groupStrategyOrderValue, isCreating)
        .then((res) => {
          handleStrategyAPIResponse(res);

          if (isCreating) {
            ga4Stats(gtag.budget.action.create, gtag.budget.category, gtag.budget.label);
          } else {
            ga4Stats(gtag.budget.action.edit, gtag.budget.category, gtag.budget.label);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleCustomize = () => {
    dispatch(updateFormTypeStrategy(FORM_TYPE.create));
    setStrategyName('');
    setValidStrategyName(checkValidStrategyName('', t));
    dispatch(
      updateCurrentStrategy({
        is_customize: true,
      })
    );
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  return (
    <>
      <CsForm.Header onClose={onClose} />
      <CsForm.Body id="form-strategy">
        <CsForm.Headline>{t('Set up your strategy')}</CsForm.Headline>
        <Grid>
          <Grid.Row cols={2}>
            <Grid.Cell>
              <Form.Group className="full-width">
                <CsForm.Label>1. {t('Strategy name')}</CsForm.Label>
                <Form.Control
                  ref={inputRef}
                  type="text"
                  placeholder={t('Enter strategy name')}
                  value={currentStrategy.is_default ? t(strategyName) : strategyName}
                  onChange={handleChangeStrageyName}
                  disabled={currentStrategy.is_default || formType === FORM_TYPE.read_only}
                />
                <TextInvalid validObj={validStrategyName} />
              </Form.Group>
            </Grid.Cell>
            <Grid.Cell>
              <Form.Group className="full-width">
                <CsForm.Label className="d-flex align-items-center">
                  2. {t('Budget method')}
                  <Tooltip
                    text={t(`Please select the suitable budget method 
                              below to control your funds.`)}
                  />
                </CsForm.Label>
                {!!strategyMethods.length && (
                  <SelectMethod
                    value={strategyMethod}
                    options={strategyMethods}
                    isDisabled={currentStrategy.is_default || currentStrategy.copy_from || formType === FORM_TYPE.read_only}
                    onChange={handleChangeStrageyMethod}
                  />
                )}
              </Form.Group>
            </Grid.Cell>
          </Grid.Row>
          {!currentStrategy.copy_from && (
            <>
              <AllOrders
                show={strategyMethod === STRATEGY_METHOD.all_order}
                value={strategyOrderValue[0]?.value}
                onChange={handleChangeStrageyOrderValue}
                isDefault={currentStrategy?.is_default}
                validates={validateOrderValue}
                formType={formType}
              />
              <CustomAutoWinOrVictor
                show={[
                  STRATEGY_METHOD.autowin,
                  STRATEGY_METHOD.victor,
                  STRATEGY_METHOD.victor2,
                  STRATEGY_METHOD.victor3,
                  STRATEGY_METHOD.victor4,
                ].includes(strategyMethod)}
                values={strategyOrderValue}
                onChange={handleChangeStrageyOrderValue}
                isDefault={currentStrategy?.is_default}
                validates={validateOrderValue}
                formType={formType}
              />
              <Martingale
                show={strategyMethod === STRATEGY_METHOD.martingale}
                value={strategyOrderValue[0]?.value}
                extraValue={strategyOption}
                onInputChange={handleChangeStrageyOrderValue}
                onSelectChange={setStrategyOption}
                isDefault={currentStrategy?.is_default}
                validates={validateOrderValue}
                selectOptions={strategyOptions}
                formType={formType}
              />
              <Fibo
                show={strategyMethod === STRATEGY_METHOD.fibo}
                value={strategyOrderValue[0]?.value}
                extraValue={strategyOption}
                onInputChange={handleChangeStrageyOrderValue}
                onSelectChange={setStrategyOption}
                isDefault={currentStrategy?.is_default}
                validates={validateOrderValue}
                selectOptions={strategyOptions}
                stepValidate={validateStep}
                winStepValue={fiboStepWin}
                loseStepValue={fiboStepLose}
                onWinChange={setFiboStepWin}
                onLoseChange={setFiboStepLose}
                formType={formType}
              />
            </>
          )}

          {!currentStrategy.copy_from &&
            strategyMethod &&
            strategyMethod !== STRATEGY_METHOD.fibo &&
            !isEmpty(strategyOption) && (
              <StrategyExplain
                strategyMethod={strategyMethod}
                strategyOption={strategyOption}
                optionsStrategyMethod={strategyMethods}
              />
            )}
        </Grid>
        <Box mt={4} />
        <CsAccordion defaultActiveKey="0">
          <CsAccordion.Item eventKey="0">
            <Grid>
              <Grid.Row>
                <CsAccordion.Header className="full-width">
                  <Setting2 size={24} style={{ marginRight: 5 }} /> {t('Advanced (Optional)')}{' '}
                </CsAccordion.Header>
              </Grid.Row>
            </Grid>
            <CsAccordion.Body>
              <Grid>
                <Grid.Row>
                  <Grid.Cell>
                    <FormStopPlan
                      currentStrategy={currentStrategy}
                      formType={formType}
                      strategyStopNotEnoughBalance={strategyStopNotEnoughBalance}
                      handleChangeStrategyStopNotEnoughBalance={
                        handleChangeStrategyStopNotEnoughBalance
                      }
                    />

                    <FormReloadDemoBalance
                      currentStrategy={currentStrategy}
                      formType={formType}
                      strategyReloadDemoBalance={strategyReloadDemoBalance}
                      handleChangeStrategyReloadDemoBalance={handleChangeStrategyReloadDemoBalance}
                    />
                  </Grid.Cell>
                </Grid.Row>
              </Grid>
            </CsAccordion.Body>
          </CsAccordion.Item>
        </CsAccordion>
      </CsForm.Body>
      <FormFooter
        currentStrategy={currentStrategy}
        formType={formType}
        isLoading={isLoading}
        sumaryValid={sumaryValid}
        allowCustomize={formType === FORM_TYPE.edit}
        onClose={onClose}
        saveStrategy={saveStrategy}
        handleCustomize={handleCustomize}
      />
    </>
  );
};

FormStrategy.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FormStrategy;
