import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { useEffect, useMemo, useState } from 'react';

const { selectSignalType } = require('@/redux/refactor/common/selector');
const { isEqual } = require('lodash');
const { useSelector } = require('react-redux');

import useFeatureFlag from '@/hooks/useFeatureFlag';

const useSignalType = ({ isEdit, currentValue, isImported }) => {
  const signalType = useSelector(selectSignalType, isEqual);
  const isFeatureFlagCommunitySignal = useFeatureFlag('feature_flag_community_signal');

  const [signalTypeWithStatus, setSignalTypeWithStatus] = useState([]);

  useEffect(() => {
    if (!signalType.length) return;
    
    setSignalTypeWithStatus(signalType.map(item => {
      if (item.code !== SIGNAL_STRATEGY_TYPE.community) {
        return {...item, isShow: true}
      } else {
        return {...item, isShow: isFeatureFlagCommunitySignal}
      }
    }))
  }, [signalType, isFeatureFlagCommunitySignal])


  return useMemo(() => {
    if (!isEdit) {
      return signalTypeWithStatus.filter(
        (item) => item.code !== SIGNAL_STRATEGY_TYPE.manual && item.isShow
      );
    }

    if (isImported) {
      return signalTypeWithStatus.filter((item) => item.code === currentValue && item.isShow);
    }

    // if (currentValue === SIGNAL_STRATEGY_TYPE.manual) {
    //   return signalTypeWithStatus.filter(item => item.code === SIGNAL_STRATEGY_TYPE.manual);
    // }
 

    return signalTypeWithStatus.filter(
      (item) => item.code !== SIGNAL_STRATEGY_TYPE.manual && item.isShow
    );
  }, [isEdit, currentValue, isImported, signalTypeWithStatus]);
};

export default useSignalType;
