/* eslint-disable react/prop-types */
import useCaptcha from '@/hooks/useCaptcha';
import useQuery from '@/hooks/useQuery';
import { Button } from '@/shared/components/Button';
import {
  AccountCard,
  AccountTitle,
} from '@/shared/components/account/AccountElements';
import AccountWithoutBanner from '@/shared/components/account/AccountWithoutBanner';
import {
  ModalAccountDescription,
  ModalAccountLinkStyle,
} from '@/shared/components/account/ModalAccount';
import { isValidEmail } from '@/shared/helpers';
import SuccessCheck from '@/shared/img/successCheck.svg';
import authApi from '@/utils/api/authApi';
import { Devices, VARIANTS, cardBgColor, colorSuccess } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import gtag from '@/shared/constants/gtag';

const ERROR_TYPE = {
  InvalidToken: 'invalid-token',
  AlreadyActive: 'already-activated',
  InvalidCaptcha: 'invalid-captcha',
};

const ga4Stats = window.ga4Stats;

const getMessage = (loading, success, errorType) => {
  if (loading) {
    return [
      [
        'EmailVerification.title',
        'Please wait a minutes for activating account!',
      ],
      [
        'EmailVerification.description',
        'Please back to login when process done & enjoy with us',
      ],
    ];
  }
  if (success) {
    return [
      ['EmailVerification.successTitle', ''],
      ['EmailVerification.successDescription', ''],
    ];
  }
  if (errorType === ERROR_TYPE.InvalidToken) {
    return [
      ['EmailVerification.invalidTokenTitle', ''],
      ['EmailVerification.invalidTokenDescription', ''],
    ];
  }
  if (errorType === ERROR_TYPE.AlreadyActive) {
    return [
      ['EmailVerification.alreadyActiveTitle', ''],
      ['EmailVerification.alreadyActiveDescription', ''],
    ];
  }
  return [
    [
      'EmailVerification.errorTitle',
      'An interruption occurred during activation',
    ],
    [
      'EmailVerification.errorDescription',
      'Please retry again or contact us for support. Sincerely sorry for the inconvenience',
    ],
  ];
};

let timerId = null;
const EmailVerification = ({ t }) => {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorType, setErrorTyped] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [textCountdown, setTextCountdown] = useState('00:30');
  const { execute, executeRecaptcha } = useCaptcha();

  const verify = async () => {
    const captcha = await execute('verifyEmail');
    const { ok, message, errorCode } = await authApi.verifyEmail({
      email: query.get('e'),
      token: query.get('c'),
      captcha,
    });
    console.log({ ok, message, errorCode });
    if (ok) {
      setIsSuccess(true);
      ga4Stats(gtag.register.action.activeAccount, gtag.register.category, gtag.register.label);
    } else if (errorCode) {
      setErrorTyped(errorCode);
    } else {
      toast.error(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (executeRecaptcha && query.get('e') && isValidEmail(query.get('e'))) {
      verify();
    }
    return () => {
      clearInterval(timerId);
      timerId = null;
    };
  }, [executeRecaptcha]);

  const setCountDown = () => {
    let count = 30;
    setIsDisabled(true);
    if (timerId) clearInterval(timerId);
    timerId = setInterval(() => {
      if (count) {
        count -= 1;
        setTextCountdown(`00:${count <= 9 ? 0 : ''}${count}`);
      } else {
        setIsDisabled(false);
        setTextCountdown('00:30');
        clearInterval(timerId);
        timerId = null;
      }
    }, 1000);
  };

  const handleSubmit = async () => {
    setIsSending(true);
    const captcha = await execute('sendVerifyEmail');
    authApi.sendVerifyEmail({ email: query.get('e'), captcha }).then(
      (resp) => {
        if (resp?.data?.ok) {
          setIsSending(false);
          setCountDown();
          ga4Stats(gtag.login.action.requestConfirmEmail, gtag.login.category, gtag.login.label);
        }
      },
      (error) => {
        toast.error(error.message);
        setIsSending(false);
      }
    );
  };

  const message = getMessage(isLoading, isSuccess, errorType);
  const showLoadingWrap = isLoading || (!isLoading && isSuccess);
  const showResult = !isLoading && !isSuccess;
  return (
    <AccountWithoutBanner>
      <Helmet title="Account Verification | Quickinvest.ai" />
      <AccountCardStyle>
        {showLoadingWrap && (
          <LoadingWrap>
            {isLoading && <SpinnerStyle animation="border" />}
            {!isLoading && isSuccess && <SuccessCheckStyle />}
          </LoadingWrap>
        )}
        {showResult && (
          <Box mb="40px" mt="62px">
            <Image src="/img/account/active-error.png" />
          </Box>
        )}
        <AccountTitleStyle>{t(message[0][0], message[0][1])}</AccountTitleStyle>
        <ModalAccountDescriptionStyle>
          {t(message[1][0], message[1][1])}
        </ModalAccountDescriptionStyle>

        {errorType !== ERROR_TYPE.InvalidToken ? (
          <Box mb="24px">
            <NavLink to="/login">
              <ModalAccountLinkStyle to="/login">
                {t('Back_to_Login', 'Back to Login')}
              </ModalAccountLinkStyle>
            </NavLink>
          </Box>
        ) : (
          <Resend
            disabled={isSending || isDisabled}
            isLoading={isSending}
            variant={VARIANTS.LINK}
            onClick={handleSubmit}
          >
            {isDisabled ? textCountdown : t('Resend')}
          </Resend>
        )}
      </AccountCardStyle>
    </AccountWithoutBanner>
  );
};
const AccountTitleStyle = styled(AccountTitle)`
  margin-bottom: 16px;
  @media ${Devices.pc} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ModalAccountDescriptionStyle = styled(ModalAccountDescription)`
  /* max-width: 350px; */
  margin: 0 auto;
  margin-bottom: 32px;
`;
const AccountCardStyle = styled(AccountCard)`
  text-align: center;
  height: auto;
  max-width: 552px;
  padding: 16px;
  border-radius: 24px;
  align-items: center;
  position: relative;
  background: ${cardBgColor}!important;
  @media ${Devices.pc} {
    margin-top: 40px;
  }
`;
const LoadingWrap = styled.div`
  position: relative;
  display: flex;
  height: 124px;
  width: 100%;
  margin-top: 62px;
  margin-bottom: 62.29px;
  justify-content: center;
`;
const SpinnerStyle = styled(Spinner)`
  width: 124px;
  height: 124px;
  color: #55c790 !important;
  border-width: 6px !important;
`;

const SuccessCheckStyle = styled(SuccessCheck)`
  position: absolute;
  top: -2px;
`;

const Resend = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.3px;
  color: ${colorSuccess};
  cursor: pointer;
  text-decoration: none;
`;

export default withTranslation()(EmailVerification);
