import React from 'react';
import isEqual from 'lodash/isEqual';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import FormWizard from './FormWizard';

const ExchangeConnect = () => {
  const connected = useSelector(state => state.exchange.connected, isEqual);
  if (connected) return <Redirect to="/" />;
  return (<>
    <Helmet title="Exchange Connect | Quickinvest.ai" />
    <FormWizard />
  </>
  );
};

export default ExchangeConnect;
