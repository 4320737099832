import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { convertTimeZone } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import { STORAGE_KEY, CURRENT_TIMEZONE } from '@/config/const';

const PartHtmlComponent = ({ rawHtml, onDidMount, closePopup }) => {
  const history = useHistory();
  const containerRef = useRef(null);
  const [processedHtml, setProcessedHtml] = useState('');

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawHtml, 'text/html');

    const timeElement = doc.querySelector('.text-time');
    if (timeElement) {
      const parsedTimeZone = convertTimeZone(timeElement.textContent, +CURRENT_TIMEZONE);
      timeElement.textContent = moment(parsedTimeZone, 'HH:mm')
        .locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE))
        .format('hh:mm A');
    }

    const priceElement = doc.querySelector('.text-price');
    if (priceElement) {
      priceElement.textContent = formatNumber.formatCurrency(
        priceElement.textContent.toString().replace(/,/g, '')
      );
    }

    setProcessedHtml(doc.body.innerHTML);

    let timeout = setTimeout(() => {
      if (timeout) {
        onDidMount();
      }
    }, 10);

    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  }, [rawHtml]);

  useEffect(() => {
    const handleElementClick = (event) => {
      if (event.target.matches('.text-signal')) {
        closePopup();
        history.push('/signal-strategies/top-signal');
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleElementClick);
    }

    return () => {
      if (container) {
        container.removeEventListener('click', handleElementClick);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="notification-item-content"
      dangerouslySetInnerHTML={{
        __html: processedHtml,
      }}
    />
  );
};

PartHtmlComponent.propTypes = {
  rawHtml: PropTypes.string.isRequired,
  onDidMount: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default PartHtmlComponent;
