import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateCurrentLayoutTicket } from '@/redux/actions/ticketActions';
import { Button } from '@/shared/components/Button';

const Footer = ({
  isValidForm,
  createTicket,
  isUpLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentTicket = useSelector(state => state.ticket.currentTicket, shallowEqual);
  const currentLayout = useSelector(state => state.ticket.currentLayout, shallowEqual);
  const profile = useSelector(state => state.user.profile, shallowEqual);

  return (
    <div className="ticket-popup-footer text-center">
      {currentLayout.isList && (
        <div className="d-grid">
          <Button variant="success" onClick={() => dispatch(updateCurrentLayoutTicket('create'))}>
            {t('Create a ticket')}
          </Button>
        </div>
      )}
      {currentLayout.isCreateForm && (
        <div className="d-grid">
          <Button variant="success" onClick={createTicket} disabled={isUpLoading || !isValidForm}>
            {t('Create a ticket')}
          </Button>
        </div>
      )}
      {currentLayout.isTicketDetail && (
        <>
          <div className="d-grid">
            <a
              className="btn btn-primary"
              href={`mailto:${profile.e}`}
            >
              {t('Open Email')}
            </a>
          </div>
          <button
            type="button"
            className="back text-center mb-0"
            onClick={() => dispatch(updateCurrentLayoutTicket('list'))}
          >
            {t('Back to ticket center')}
          </button>
        </>
      )}
      
      {(currentLayout.isListComment && ['new', 'open', 'pending', 'hold'].indexOf(currentTicket.status) > -1) && (
        <a className="btn btn-success" href={`mailto:support+id${currentTicket.id}@quickinvest.zendesk.com`}>
          {t('reply')}
        </a>
      )}

      {(currentLayout.isListComment && ['solved', 'closed'].indexOf(currentTicket.status) > -1) && (
        <p className="d-flex justify-content-center align-items-center mb-0">
          {t('Ticket has been resolved')}
        </p>
      )}
    </div>
  );
};

Footer.propTypes = {
  isValidForm: PropTypes.bool.isRequired,
  createTicket: PropTypes.func.isRequired,
  isUpLoading: PropTypes.bool.isRequired,
};

export default Footer;
