import classNames from 'classnames';
import React, { memo } from 'react';

export const Bubble = memo(({ onSelect, value, current, isDefault }) => {
  const isEven = (value + 1) % 2 === 0;
  const rightValue = value + 80;
  const onClick = () => {
    if (isDefault || isEven || rightValue === current) return;
    onSelect(rightValue);
  };
  const isActive = () => {
    return (
      !isEven &&
      (rightValue === current + 1 || (rightValue === 80 && current === 99))
    );
  };
  return (
    <span
      data-value={value}
      className={classNames('BubblePanel__list-item', {
        'BubblePanel__list-active': isActive(),
        disabled: isEven || isDefault,
      })}
      onClick={onClick}
    >
      {value + 1}
    </span>
  );
});
