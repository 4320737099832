import { PlanCrudProvider } from '@/context/PlanCrudProvider';
import { Devices } from '@/utils/palette';
import { isEqual } from 'lodash';
import React, { Suspense } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import BackTest from './BackTest';
import BacktestBanner from './BackTest/Banner';
import BannerCreatePlan from './components/BannerCreatePlan';
import './index.scss';

const TopPlans = React.lazy(() => import('./TopPlans'));
const LeaderBoard = React.lazy(() => import('./Leaderboard'));
const InsightList = React.lazy(() => import('../QuickInsight/InsightList'));

const Fallback = () => '';

const Dashboard = () => {
  const { isMobile, siteConfig } = useSelector(
    (state) => ({
      isMobile: state.theme.isMobile,
      siteConfig: state?.appConfig?.siteConfig,
    }),
    isEqual
  );

  const { t } = useTranslation();

  return (
    <PlanCrudProvider>
      <Helmet title="Dashboard | Quickinvest.ai" />
      <Wrapper>
        <Left>
          {isMobile && <BacktestBanner />}
          <BannerCreatePlan />
          <Suspense fallback={<Fallback />}>
            <TopPlans />
            <Tabs
              defaultActiveKey={
                siteConfig.insight_enable ? 'insight' : 'leaderboard'
              }
              id="dashboard-tab"
              className={`tab-leaderboard-insight style-2 ${
                siteConfig.insight_enable ? '' : 'disable-insight'
              }`}
            >
              {siteConfig.insight_enable && (
                <Tab eventKey="insight" title={t('Quick Insights')}>
                  <InsightList />
                </Tab>
              )}
              <Tab eventKey="leaderboard" title={t('Leaderboard')}>
                <LeaderBoard />
              </Tab>
            </Tabs>
          </Suspense>
        </Left>
        <Right>{!isMobile && <BackTest />}</Right>
      </Wrapper>
    </PlanCrudProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  @media ${Devices.pc} {
    margin-bottom: 0;
  }
`;
const Left = styled.div`
  overflow: hidden;
  flex: 1;
`;

const Right = styled.div`
  display: none;
  @media ${Devices.laptop} {
    display: block;
    width: 343px;
  }
`;

export default Dashboard;
