import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { validatorHelper } from '@/shared/helpers';
import planApi from '@/utils/api/planApi';
import { borderDefaultStyle } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from '../../Button';
import { renderCheckboxField } from '../../form/CheckBox';
import { FormContainer } from '../../form/FormElements';
import { checkPlanName } from '../form/validator';
import FormField from '../../form/FormField';
import Icons from '../../icons';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const PopupDuplicatePlan = ({ copyCode, show, onHide, onSuccess }) => {
  const { t } = useTranslation();
  const validate = validatorHelper(t);
  const dispatch = useDispatch();
  const { accountType } = useSelector((state) => state.wallet, isEqual);
  const { mutate, isLoading } = useMutation({
    mutationFn: async (values) => {
      const { data } = await planApi.duplicatePlan(copyCode, values);

      ga4Stats(gtag.plan.action.duplicatePlan, gtag.plan.category, accountType);

      return data;
    },
    onSuccess: ({ ok, m, d = {} }) => {
      if (ok) {
        toast.success(t('msg_duplicate_plan_success'));
        if (m) {
          setTimeout(() => toast.warn(t(m)), 300);
        }
        dispatch(getTotalPlan(accountType));
        onSuccess(d);
        onHide();
      } else {
        toast.error(t(m));
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });
  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Title>
        <Box>{t('Duplicate Plan')}</Box>
        <Icons.CloseIcon className="cursor-pointer" onClick={onHide} />
      </Modal.Title>
      <Form onSubmit={mutate}>
        {({ handleSubmit, invalid }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group">
                <label className="form-label">{t('Enter plan name')}</label>
                <Field
                  name="planName"
                  component={FormField}
                  placeholder="Enter plan name"
                  validate={validate.composeValidators(
                    validate.required,
                    checkPlanName
                  )}
                  showErrorText={false}
                />
              </div>
              <div className="form-group checkbox-style2-wrap">
                <Field
                  name="start"
                  render={renderCheckboxField}
                  label={t('Start this plan after “Confirm & Save”')}
                  type="checkbox"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Box width="100%" maxWidth={320}>
                <Button
                  variant="success"
                  disabled={invalid || isLoading}
                  isLoading={isLoading}
                  block="true"
                  onClick={handleSubmit}
                >
                  {t('Confirm & Save')}
                </Button>
              </Box>
            </Modal.Footer>
          </FormContainer>
        )}
      </Form>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-title {
    padding: 32px;
    border-bottom: ${borderDefaultStyle};
    font-size: 20px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .form-label {
      margin-bottom: 12px;
      font-weight: 700;
    }
  }
  .modal-footer {
    justify-content: center;
  }
`;

export default PopupDuplicatePlan;
