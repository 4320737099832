import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { FORM_TYPE } from '@/shared/constants';

const FormReloadDemoBalance = ({currentStrategy, formType, strategyReloadDemoBalance, handleChangeStrategyReloadDemoBalance}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className="form-group-switch full-width">
      <div className="form-group-switch-content">
        <Form.Label className="mb-1">{t('Auto reload demo balance')}</Form.Label>
        <Form.Text>
          {t(
            `Reload {{amount}} in demo balance
                               when your wallet don’t have enough.`,
            { amount: '$1000' }
          )}
        </Form.Text>
      </div>

      <Form.Check
        type="switch"
        id="check-realod-demo-balance"
        checked={strategyReloadDemoBalance}
        onChange={handleChangeStrategyReloadDemoBalance}
        disabled={currentStrategy.is_default || formType == FORM_TYPE.read_only}
      />
    </Form.Group>
  );
};

FormReloadDemoBalance.propTypes = {
  currentStrategy: PropTypes.instanceOf(Object),
  formType: PropTypes.string.isRequired,
  strategyReloadDemoBalance: PropTypes.bool.isRequired,
  handleChangeStrategyReloadDemoBalance: PropTypes.func.isRequired,
};

FormReloadDemoBalance.defaultProps = {
  currentStrategy: {},
};

export default FormReloadDemoBalance;
