import { isEqual } from 'lodash';
import { Devices } from '@/utils/palette';
import { Helmet } from 'react-helmet';
import Tooltip from '../Tooltip';
import { useMediaQuery } from '@material-ui/core';
import { StyledFooter, StyledHeader, StyledHint } from './styles';
import { TelegramMessageProvider, TelegramSelectContext, TelegramSelectProvider } from './context';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MobileView } from './MobieView';
import { PcView } from './PcView';
import { Button } from '../Button';
import { useContext } from 'react';

export default function TelegramMessage({ defaultValues = [], canSelect, onBack, onSave }) {
  const { t } = useTranslation();
  const isPc = useMediaQuery(Devices.pc);

  const {
    isCommunitySignal
  } = useSelector(
    (state) => state.common,
    isEqual
  );

  return (
    <TelegramMessageProvider>
      <TelegramSelectProvider
        canSelect={canSelect}
        defaultValues={defaultValues}
        onBack={onBack}
        onSave={onSave}
      >
        <Helmet title="Telegram Channel | Quickinvest.ai" />
        <StyledHeader>
          <span className="me-2">{!isCommunitySignal ? t('Telegram Channel') : t('Community bot')}</span>
          {!isCommunitySignal && (
            <Tooltip placement="bottom" text={t('telegram_message_help')}>
              <StyledHint>(?)</StyledHint>
            </Tooltip>
          )}
        </StyledHeader>
        {isPc ? <PcView /> : <MobileView />}
        {canSelect && <Footer />}
      </TelegramSelectProvider>
    </TelegramMessageProvider>
  );
}

function Footer() {
  const { t } = useTranslation();
  const { onBack, handleSave } = useContext(TelegramSelectContext);
  return (
    <StyledFooter>
      <Button variant="secondary_outline" onClick={onBack}>
        {t('Back')}
      </Button>
      <Button variant="success" block="true" onClick={handleSave}>
        {t('Confirm & Save')}
      </Button>
    </StyledFooter>
  );
}
