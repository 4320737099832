import { createAction } from 'redux-actions';
import affiliationApi from '@/utils/api/affiliationApi';

export const updatePlans = createAction('UPDATE_AFFILIATIONS');
export const updateOverviewAffiliation = createAction('UPDATE_AFFILIATION');
export const updateIsFetching = createAction('UPDATE_AFFILIATION_IS_FETCHING');

export const getOverviewAffiliation = userId => async (dispatch) => {
  dispatch(updateIsFetching(true));
  const { data } = await affiliationApi.getOverviewAffiliation(userId);
  dispatch(updateIsFetching(false));
  if (data?.ok) {
    dispatch(updateOverviewAffiliation(data.d));
  }
};
