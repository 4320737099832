import useDebounce from '@/hooks/useDebounce';
import {
  getBacktestDataChart,
  updateBacktestId,
} from '@/redux/actions/planActions';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  colorGreyScale400,
  colorGreyScale600,
  colorGreyScale700,
  colorRed,
  colorSuccess,
} from '@/utils/palette';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const timeFormat = 'HH:mm';
const rangeMinute = 10;

const LineChartBacktest = memo(({ backtestId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mode, backtestDataChart, backtestProgress } = useSelector(
    (state) => ({
      mode: state.theme.className,
      backtestDataChart: state.plan.backtestDataChart,
      backtestProgress: state.plan.backtestProgress,
    }),
    _.isEqual
  );
  const debounceDataChart = useDebounce(backtestDataChart, 100);

  const chartRef = useRef('');
  const [rawData, setRawData] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const [dataBets, setDataBets] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [maxPoint, setMaxPoint] = useState({});
  const [minPoint, setMinPoint] = useState({});
  const [options, setOptions] = useState({});
  const [currentPoint, setCurrentPoint] = useState({});
  const [styleTooltipCurrentPoint, setStyleTooltipCurrentPoint] = useState({});

  const adjustBackgroundColorBasedOnData = (ctx) => {
    const v = ctx.parsed.y;
    if (v === maxPoint.value) {
      return colorSuccess;
    }
    if (v === minPoint.value) {
      return colorRed;
    }
    return 'rgba(255, 208, 35, 0)';
  };

  const adjustBorderColorBasedOnData = (ctx) => {
    const v = ctx.parsed.y;
    if (v === maxPoint.value || v === minPoint.value) {
      return '#fff';
    }
    return 'rgba(255, 208, 35, 0)';
  };

  const adjustRadiusBasedOnData = (ctx) => {
    const v = ctx.parsed.y;
    if (v === maxPoint.value || v === minPoint.value) {
      return 10;
    }
    return 4;
  };

  const externalTooltipHandler = (context) => {
    if (backtestProgress === 1) {
      const { tooltip } = context;
      try {
        if (tooltip?.dataPoints && tooltip?.dataPoints.length) {
          const pointValue = tooltip?.dataPoints[0]?.raw;
          const isMax = maxPoint.value === pointValue;
          const isMin = minPoint.value === pointValue;
          const elTooltip = document.getElementById('line-chart-tooltip');
          const elWrapper = document.getElementById('wrap-line-chart');
          let leftPos = tooltip.caretX;
          const topPos = tooltip.caretY;

          if (tooltip.width / 2 > leftPos) {
            leftPos += tooltip.width / 2;
          } else if (tooltip.width / 2 + leftPos > elWrapper.offsetWidth) {
            leftPos -= tooltip.width / 2 + 24;
          }

          // Set tooltip data
          elTooltip.textContent = `${
            isMax ? t('Max Profit') : isMin ? t('Min Profit') : t('Profit')
          } : ${formatNumber.formatCurrency(pointValue)}`;
          elTooltip.style.left = `${leftPos - 8}px`;
          elTooltip.style.top = `${topPos + 24}px`;
          elTooltip.style.opacity = tooltip.opacity;
          elTooltip.style.zIndex = tooltip.opacity === 0 ? -1 : 9;
        }
      } catch (error) {
        console.log(tooltip);
      }
    }
  };

  const parsedTime = (time) => {
    const dt = moment(time).format(timeFormat).split(':');
    const hour = dt[0];
    const minute = Number(dt[1]);
    const period = Math.floor(minute / rangeMinute) * rangeMinute;
    return `${hour}:${period === 0 ? '00' : period}`;
  };

  useEffect(() => {
    if (rawData.length) {
      // eslint-disable-next-line
      const datetimeLables = _.uniq(
        rawData.map((d) => parsedTime(d.timestamp))
      );
      // console.log(datetimeLables);
      setDataLabels(datetimeLables);

      const maxP = _.maxBy(rawData, 'profit');
      const minP = _.minBy(rawData, 'profit');
      // console.log(minP.profit, maxP.profit);
      if (!_.isEmpty(maxP) && !_.isEmpty(minP)) {
        setMaxPoint({
          value: maxP.profit,
          time: parsedTime(maxP.timestamp),
        });

        setMinPoint({
          value: minP.profit,
          time: parsedTime(minP.timestamp),
        });
      } else {
        console.log(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (dataLabels.length && !_.isEmpty(minPoint) && !_.isEmpty(maxPoint)) {
      // get first element of each period time
      // eslint-disable-next-line
      const dataParsed = dataLabels.map(
        (datetime) =>
          rawData
            .filter((d) => parsedTime(d.timestamp) === datetime)
            .map((session) => ({
              profit: session.profit,
              time: parsedTime(session.timestamp),
            }))[0]
      );

      // update exact min, max element
      const indexOfMaxP = _.findIndex(
        dataParsed,
        (p) => p.time === maxPoint.time
      );
      dataParsed[indexOfMaxP].profit = maxPoint.value;
      const indexOfMinP = _.findIndex(
        dataParsed,
        (p) => p.time === minPoint.time
      );
      dataParsed[indexOfMinP].profit = minPoint.value;
      // console.log(dataParsed);
      setDataBets(dataParsed.map((p) => p.profit));

      setOptions({
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler,
          },
        },
        elements: {
          point: {
            pointBackgroundColor: adjustBackgroundColorBasedOnData,
            pointBorderColor: adjustBorderColorBasedOnData,
            radius: adjustRadiusBasedOnData,
            pointStyle: 'circle',
            hoverRadius: 10,
          },
        },
        scales: {
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              padding: 6,
              color: colorGreyScale600,
              callback: (value) =>
                value % 4 === 0 ? `${dataLabels[value]}` : '',
            },
          },
          y: {
            grid: {
              color: (context) => {
                if (context.tick.value === 0) {
                  return mode === 'dark'
                    ? colorGreyScale700
                    : colorGreyScale400;
                }

                return mode === 'dark' ? colorGreyScale700 : colorGreyScale400;
              },
            },
            border: {
              display: false,
              dash: (context) => {
                if (context.tick.value === 0) {
                  return [8, 0];
                }

                return [8, 2];
              },
            },
            ticks: {
              padding: 16,
              callback: (value) => formatNumber.formatCurrency(value),
              color: colorGreyScale600,
            },
          },
        },
      });
    }
  }, [minPoint, maxPoint, dataLabels]);

  useEffect(() => {
    if (dataBets.length) {
      setDataChart({
        labels: dataLabels,
        datasets: [
          {
            data: dataBets,
            borderColor: '#FFD023',
            tension: 0.3,
            segment: {
              borderColor: (ctx) =>
                ctx.p1.parsed.y > 0 ? colorSuccess : colorRed,
            },
          },
        ],
      });
    }
  }, [dataBets]);

  useEffect(() => {
    if (backtestDataChart.length) {
      setRawData(_.orderBy(backtestDataChart, ['timestamp'], ['asc']));
    }
  }, [debounceDataChart]);

  useEffect(() => {
    dispatch(updateBacktestId(backtestId));
    dispatch(getBacktestDataChart(backtestId));
  }, [backtestId]);

  return (
    <>
      {!_.isEmpty(dataBets) && !_.isEmpty(dataChart) && !_.isEmpty(options) && (
        <div id="wrap-line-chart" className="wrap-line-chart">
          <div id="line-chart-tooltip" className="line-chart-tooltip" />

          <Line
            height="240px"
            options={{ ...options, maintainAspectRatio: false }}
            data={dataChart}
            ref={chartRef}
          />
          {backtestProgress < 1 && (
            <div className="spinner-wrapper">
              <Spinner
                animation="grow"
                variant="secondary"
                className="chart-loading"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
});

LineChartBacktest.propTypes = {
  backtestId: PropTypes.number.isRequired,
};

export default LineChartBacktest;
