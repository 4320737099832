/* eslint-disable react/prop-types */
import useCopyToClipboard from '@/hooks/useCopyToClipboard';
import {
  selectAddress,
  selectWithdrawConfig,
} from '@/redux/selectors/walletSelector';
import { Box } from '@material-ui/core';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from '../../Button';
import Icons from '../../icons';
import {
 FormTitle,
  CardBodyWithFooter,
  CardFooter,
  FooterHint,
  CardStyle,
  CardTitle,
  Address,
  Description,
} from './styles';

const PanelDeposit = ({ show, setFocusedIdx, onClose }) => {
  const { t } = useTranslation();
  const { usdtAddr } = useSelector(selectAddress, shallowEqual);
  const { u_min: minUsdt } = useSelector(selectWithdrawConfig, shallowEqual);
  const [copiedText, copy] = useCopyToClipboard();
  const handleCopy = () => {
    copy(usdtAddr);
  };
  const back = () => {
    setFocusedIdx(0);
  };
  if (!show) return <div />;
  return (
    <CardStyle>
      <CardTitle>
        <Box flex={1}>{t('Exchange wallet')}</Box>
        <Box onClick={onClose}>
          <Icons.CloseIcon />
        </Box>
      </CardTitle>
      <CardBodyWithFooter>
        <FormTitle mb="24px">{t('Deposit USDT (BEP-20)')}</FormTitle>
        <Box textAlign="center" mb="16px">
          <QRCodeCanvas
            style={{
              padding: '8px',
              background: '#fff',
            }}
            size={186}
            value={usdtAddr}
          />
          <Address>{usdtAddr}</Address>
        </Box>
        <Box display="flex" alignItems="center">
          <Icons.CheckedIcon />
          <Box ml="12px">
            <Description>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Use this address to transfer  USDT (BEP-20) from another wallet to this wallet.',
                  ),
                }}
              />
            </Description>
          </Box>
        </Box>
      </CardBodyWithFooter>
      <CardFooter>
        <FooterHint>
          {t('The minimum deposit amount is {{minUsdt}} USDT.', { minUsdt })}
        </FooterHint>
        <Box width="100%" display="flex" gridGap="12px">
          <Button onClick={back} variant="secondary_outline">{t('< Back')}</Button>
          <Box flex={1}>
            <Button onClick={handleCopy} variant="success" block="true">{t('Copy Wallet Address')}</Button>
          </Box>
        </Box>
      </CardFooter>
    </CardStyle>
  );
};

export default PanelDeposit;
