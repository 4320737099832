/* eslint-disable react/prop-types */
import { getTxUrl } from '@/config/const';
import { timeFormat } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StatusIcon from '@/shared/img/status-icon.svg';
import WalletIcon from '@/shared/img/wallet-icon.svg';
import AmountIcon from '@/shared/img/amount-icon.svg';
import {
  DrawerHeader,
  getTransactionInfo,
  getTransactionType,
  Subtitle,
  Title,
  TitleLink,
  USDTAmount,
  WithdrawTime,
  WithdrawType,
} from '../components/DrawerTransDetail';
import TransactionIcon from '../components/TransactionIcon';
import { CardDivider } from '../../Card';
import {
  CardBodyWithShorter,
  CardFooter,
  CardStyle,
  CardTitle,
} from './styles';
import Icons from '../../icons';
import { Button } from '../../Button';

const PanelTransaction = ({
 value, setFocusedIdx, show, onClose, 
}) => {
  const { t } = useTranslation();
  const usdtAmount = formatNumber.sliceDecimalFloat(value?.amount);
  const tranInfo = getTransactionInfo(value);
  const bscUrl = useMemo(() => getTxUrl(tranInfo[1]), [tranInfo]);

  const back = () => setFocusedIdx(0);

  if (!value || !show) return <div />;
  return (
    <div>
      <CardStyle>
        <CardTitle>
          <Box flex={1}>{t('Exchange wallet')}</Box>
          <Box onClick={onClose}>
            <Icons.CloseIcon />
          </Box>
        </CardTitle>
        <CardBodyWithShorter>
          <DrawerHeader mb="24px">
            <Box>
              <TransactionIcon size={48} type={value?.type} />
            </Box>
            <Box flex={1}>
              <WithdrawType>{getTransactionType(t)(value?.type)}</WithdrawType>
              <WithdrawTime>{timeFormat(value?.ts)}</WithdrawTime>
            </Box>
          </DrawerHeader>
          <Box>
            <USDTAmount>{usdtAmount} USDT</USDTAmount>
          </Box>
          <CardDivider />
          <DrawerHeader>
            <Box>
              <StatusIcon className="icon-svg" />
            </Box>
            <Box>
              <Subtitle>{t('Status')}</Subtitle>
              <Title>{t(value?.status)}</Title>
            </Box>
          </DrawerHeader>
          <CardDivider />
          <DrawerHeader>
            <Box>
              <AmountIcon className="icon-svg" />
            </Box>
            <Box>
              <Subtitle>{t('Amount')}</Subtitle>
              <Title>
                {formatNumber.sliceDecimalFloat(value?.amount)}{' '}
                USDT
              </Title>
            </Box>
          </DrawerHeader>
          {tranInfo[0] && (
            <>
              <CardDivider />
              <DrawerHeader>
                <Box>
                  <WalletIcon className="icon-svg" />
                </Box>
                <Box overflow="hidden">
                  <Subtitle>{t(tranInfo[0])}</Subtitle>
                  {!tranInfo[2] && <Title>{tranInfo[1]}</Title>}
                  {tranInfo[2] && (
                    <TitleLink href={bscUrl} target="_blank">
                      {tranInfo[1]}
                    </TitleLink>
                  )}
                </Box>
              </DrawerHeader>
            </>
          )}
        </CardBodyWithShorter>
        <CardFooter>
          <Button onClick={back} variant="secondary_outline" block="true">
            {t('< Back to wallet')}
          </Button>
        </CardFooter>
      </CardStyle>
    </div>
  );
};

export default PanelTransaction;
