import { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deburr, isEqual } from 'lodash';

import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import {
  subscribeTelegramChannel,
  unSubscribeTelegramChannel,
} from '@/redux/refactor/common/actions';
import { selectTelegramChannels } from '@/redux/refactor/common/selector';
import { getCommunityBotMessages, setCurrentChannel, setTelegramMessage } from '@/redux/refactor/common/actions';

export const TelegramMessageContext = createContext(null);

export const TelegramSelectContext = createContext(null);

export const TelegramMessageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState([]);
  const [current, setCurrent] = useState();
  const [searchStr, setSearchStr] = useState();
  const channels = useSelector(selectTelegramChannels, isEqual);

  const {
    isCommunitySignal,
  } = useSelector(
    (state) => ({
      isCommunitySignal: state.common.isCommunitySignal
    }),
    isEqual
  );

  const handleItemClick = (item) => {
    if (isEqual(item, current)) return;
    const channelId = isCommunitySignal ? item.id : item.source;

    setCurrent(item);
    dispatch(subscribeTelegramChannel(channelId, isCommunitySignal));
  };

  useEffect(
    () => () => {
      dispatch(unSubscribeTelegramChannel());
    },
    []
  );

  useEffect(() => {
    let signalType = isCommunitySignal ? SIGNAL_STRATEGY_TYPE.community : SIGNAL_STRATEGY_TYPE.telegram;

    setFiltered(() =>
      channels.filter((item) =>
        deburr(item.name).toLowerCase().includes(deburr(searchStr).toLowerCase()) && item.type === signalType
      )
    );
  }, [searchStr, channels, isCommunitySignal]);

  useEffect(() => {
    if (filtered.length > 0 && (!current)) {
      setCurrent(filtered[0]);
      const channelId = isCommunitySignal ? filtered[0].id : filtered[0].source;
      dispatch(subscribeTelegramChannel(channelId, isCommunitySignal));
    }
  }, [filtered, current, isCommunitySignal]);

  useEffect(() => {
    if (current && isCommunitySignal) {
      dispatch(setTelegramMessage([]));
      dispatch(getCommunityBotMessages(current.id))
    }
  }, [current, isCommunitySignal]);

  const values = useMemo(
    () => ({
      searchStr,
      current,
      filtered,
      handleItemClick,
      setSearchStr,
    }),
    [current, filtered]
  );
  return (
    <TelegramMessageContext.Provider value={values}>{children}</TelegramMessageContext.Provider>
  );
};

export const TelegramSelectProvider = ({
  defaultValues = [],
  canSelect,
  children,
  onBack,
  onSave,
}) => {
  const [selected, setSelected] = useState(defaultValues);

  const handleItemSelect = ({ id }) => {
    if (selected.includes(id)) {
      setSelected(() => selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const handleSave = () => onSave(selected);
  const values = useMemo(
    () => ({
      canSelect,
      selected,
      handleItemSelect,
      handleSave,
      onBack,
    }),
    [selected, canSelect]
  );
  return <TelegramSelectContext.Provider value={values}>{children}</TelegramSelectContext.Provider>;
};
