import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from '@/shared/components/Button';
import { withTranslation } from 'react-i18next';

const ButtonLoading = withTranslation()(({ variant, size, t }) => (
  <Button variant={variant} size={size} disabled>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      className="me-1"
    />
    {t('Processing')}...
  </Button>
));

ButtonLoading.propTypes = {
  variant: PropTypes.string.isRequired,
  size: PropTypes.string,
};

ButtonLoading.defaultProps = {
  size: '',
};

export default ButtonLoading;
