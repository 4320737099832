import React, { memo, useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';

import { checkPlanTakeProfit, checkPlanStopLoss, convertMaskToNum, isInteger, checkPlanStopLossPositive } from '../validator';
import { CollapseStyle } from './FormElements';
import SettingItem from './SettingItem';
import TargetInput from './TargetInput';

// Begin ReachTarget
export default memo(({ mutators, signalType, reachTarget, budget, isShowTotalNumber = false }) => {
  const { t } = useTranslation();
  const { values = {}, errors = {} } = useFormState();

  const onSetProfit = (value) => {
    let amount = (Number(value) / 100) * convertMaskToNum(budget);
    if (!Number.isInteger(amount)) {
      amount = Number.parseInt(amount * 100, 10) / 100;
    }
    mutators.setTakeProfit(amount);
  };

  const onSetStopLoss = (value) => {
    let amount = (Number(value) / 100) * convertMaskToNum(budget);
    if (!Number.isInteger(amount)) {
      amount = Number.parseInt(amount * 100, 10) / 100;
    }
    mutators.setStopLoss(amount);
  };

  const onSetStopLossPositive = (value) => {
    let amount = (Number(value) / 100) * convertMaskToNum(budget);
    if (!Number.isInteger(amount)) {
      amount = Number.parseInt(amount * 100, 10) / 100;
    }
    mutators.setStopLossPositive(amount);
  };

  const show = SIGNAL_STRATEGY_TYPE.manual !== signalType;
  const isExpert = SIGNAL_STRATEGY_TYPE.expert === signalType;
  const isCommunity = SIGNAL_STRATEGY_TYPE.community === signalType;

  return (
    show && (
      <Grid className='target-conditions'>
        <Grid.Row className={classNames({ 'border-none': !reachTarget })}>
          <Grid.Cell>
            <SettingItem
              name="reachTarget"
              title={t('Take Profit/Stoploss Conditions')}
              desc={t(
                'The plan will take profit/stoploss when one of these conditions is reached.'
              )}
            />
          </Grid.Cell>
        </Grid.Row>
        <CollapseStyle in={reachTarget} unmountOnExit>
          <div>
            <Grid.Row cols={isExpert || isCommunity ? 2 : 3}>
              <Grid.Cell className="take-profit-condition">
                <TargetInput
                  label={'1. '.concat(t('FormCreatePlan_advanced_takeProfit_label'))}
                  tooltip={t('FormCreatePlan_advanced_takeProfit_tooltip')}
                  placeholder="$0"
                  name="take_profit"
                  onSelect={onSetProfit}
                  type="pos"
                  validate={checkPlanTakeProfit}
                />
              </Grid.Cell>
              <Grid.Cell className="stop-loss-condition">
                <TargetInput
                  label={'2. '.concat(t('FormCreatePlan_advanced_stopLoss_label'))}
                  tooltip={t('FormCreatePlan_advanced_stopLoss_tooltip')}
                  placeholder="$0"
                  name="stop_loss"
                  onSelect={onSetStopLoss}
                  type="neg"
                  validate={checkPlanStopLoss}
                />
              </Grid.Cell>
              {!(isExpert || isCommunity) && (
                <Grid.Cell className="stop-loss-positive">
                  <TargetInput
                    label={'3. '.concat(t('FormCreatePlan_advanced_stopLoss_positive_label'))}
                    tooltip={t('FormCreatePlan_advanced_stopLoss_positive_tooltip')}
                    placeholder="$0"
                    name="stop_loss_positive"
                    onSelect={onSetStopLossPositive}
                    type="pos"
                    validate={checkPlanStopLossPositive}
                    error={errors.stop_loss_positive}
                    showError
                  />
                </Grid.Cell>
              )}
            </Grid.Row>
            <Grid.Row cols={isShowTotalNumber ? 3 : 2}>
              <Grid.Cell className="win-streak-condition">
                <Form.Label>{isExpert || isCommunity ? '3' : '4'}. {t('FormCreatePlan_advanced_takeProfitStreak_label')}</Form.Label>
                <Field
                  name="pause_win_streak"
                  component={InputNumberGroup}
                  initial={1}
                  placeholder="0"
                  inputType="text"
                  validate={isInteger}
                />
              </Grid.Cell>
              <Grid.Cell className="lose-streak-condition">
                <Form.Label>{isExpert || isCommunity ? '4' : '5'}. {t('FormCreatePlan_advanced_stopLossStreak_label')}</Form.Label>
                <Field
                  name="pause_lose_streak"
                  component={InputNumberGroup}
                  initial={1}
                  placeholder="0"
                  inputType="text"
                  validate={isInteger}
                />
              </Grid.Cell>
              {isShowTotalNumber && (
                <Grid.Cell className="total-order-condition">
                  <Form.Label>{isExpert || isCommunity ? '5' : '6'}. {t('FormCreatePlan_advanced_totalNumberOrder_label')}</Form.Label>
                  <Field
                    name="total_order"
                    component={InputNumberGroup}
                    initial={1}
                    placeholder="0"
                    inputType="text"
                    validate={isInteger}
                  />
                </Grid.Cell>
              )}
            </Grid.Row>
          </div>
        </CollapseStyle>
      </Grid>
    )
  );
});
// End ReachTarget
