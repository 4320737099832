import React from 'react';
import EmptyIcon from '@/shared/components/icons/empty-wallet-change.svg';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const Empty = ({ title, isLoading }) => (
  <EmptyWrapper>
    {isLoading && <Spinner animation="border" variant="secondary" />}
    {!isLoading && (
      <Box display="flex" flexDirection="column" alignItems="center">
        <EmptyIcon />
        <Title>{title}</Title>
      </Box>
    )}
  </EmptyWrapper>
);
Empty.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};
Empty.defaultProps = {
  title: '',
  isLoading: false,
};

const EmptyWrapper = styled(Box)`
  text-align: center;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #cbd5e0;
  margin-top: 13px;
`;

export default Empty;
