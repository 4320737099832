import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  SHOW_MOBILE_HEADER,
  SHOW_MOBILE_FOOTER,
  SET_IS_MOBILE,
  SET_URL_AFTER_LOGIN,
  UPDATE_SCREEN_SIZE,
} from './actions';

const initialState = {
  className: localStorage.getItem('darkMode') === 'true' ? 'dark' : 'light',
  showHeader: true,
  showFooter: true,
  isMobile: window.innerWidth < 1024,
  urlAfterLogin: '/',
  screenSize: {
    isPc: false,
    isTablet: false,
    isMobile: true,
  }
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      localStorage.setItem('darkMode', true);
      return { ...state, className: 'dark' };
    case CHANGE_THEME_TO_LIGHT:
      localStorage.setItem('darkMode', false);
      return { ...state, className: 'light' };
    case SHOW_MOBILE_HEADER:
      return { ...state, showHeader: action.payload };
    case SHOW_MOBILE_FOOTER:
      return { ...state, showFooter: action.payload };
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case SET_URL_AFTER_LOGIN:
      return { ...state, urlAfterLogin: action.payload };
    case UPDATE_SCREEN_SIZE: 
      return { ...state, screenSize: {...action.payload} };
    default:
      return state;
  }
};

export default themeReducer;
