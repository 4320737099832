import { toast } from 'react-toastify';

const toastIds = {};

const toastHandler = async (t, feature, apiCall, successMessage, successCallback = () => {}) => {
  try {
    const res = await apiCall();
    if (res?.data?.ok) {
      if (successMessage) {
        if (!toastIds[feature] || !toast.isActive(toastIds[feature])) {
          toastIds[feature] = toast.success(successMessage);
        } else {
          toast.update(toastIds[feature], {
            type: toast.TYPE.SUCCESS,
            render: successMessage
          });
        }
      }
      
      successCallback();
      return res;
    } else {
      toast.error(t(res?.data?.m));
    }
  } catch (error) {
    toast.error(`${t('Error occurred')}: ${error}`);
  }
  return null;
};

export default toastHandler;
