import { t } from 'i18next';
import { isEqual, isNumber } from 'lodash';
import { toast } from 'react-toastify';
import {
  ORDER_TYPES,
  conditionValidate,
  inValidRecord,
  parseStringToMap,
  sortByInvalid,
} from './const';

const { createContext, useMemo, useState, useRef } = require('react');

export const CandlePatternContext = createContext('CandlePatternContext');

export const CandlePatternProvider = ({ children }) => {
  const [isDefault, setIsDefault] = useState(false);
  const currentIdx = useRef(null);
  const [result, setResult] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(ORDER_TYPES.BUY);
  const [currentCandle, setCurrentCandle] = useState(99);
  const [candles, setCandles] = useState({});

  const isExistedResult = (value, collection = []) => {
    const idx = collection.findIndex((item) => isEqual(item.record, value));
    if (isNumber(currentIdx.current)) {
      return idx !== currentIdx.current && idx >= 0;
    } else {
      return idx >= 0;
    }
  };
  const reset = () => {
    currentIdx.current = null;
    setCurrentCandle(99);
    setOrder(ORDER_TYPES.BUY);
    setCandles({});
  };
  const resetCondition = () => setCandles({});
  const onEdit = (idx) => {
    currentIdx.current = idx;
    const { num, order, candles } = parseStringToMap(result[idx].record);
    setCurrentCandle(num);
    setCurrentOrder(order);
    setCandles(candles);
  };
  const setOrder = (value) => {
    setCurrentOrder(value);
  };
  const setCandle = (num) => {
    const _current = num === 80 ? 99 : num - 1;
    const _candles = Object.entries(candles).filter((item) => {
      return conditionValidate(_current, Number(item[0]));
    });
    setCurrentCandle(_current);
    setCandles(Object.fromEntries(_candles));
  };
  const setCondition = (id, value) => {
    setCandles({
      ...candles,
      [id]: value,
    });
  };
  const onSaveBubble = (afterSave) => {
    // make array keys and remove empty value
    const sources = [];
    Object.keys(candles).map((key) => {
      if (candles[key]) {
        sources.push(`${key}:${candles[key]}`);
      }
    });
    // make final condition string
    const record = `${sources.join(';')}-${currentCandle}:${currentOrder}`;

    // ensure uniq condition in result
    if (isExistedResult(record, result)) {
      toast.error(
        t('dupplicate_bubble_condition', 'Bubble condition already exists!')
      );
      return;
    }

    const invalid = inValidRecord(currentCandle, candles);
    // insert or update condition record
    const resp = {
      sources: candles,
      record,
      candle: currentCandle,
      order: currentOrder,
      invalid,
    };

    if (isNumber(currentIdx.current)) {
      // On update
      result[currentIdx.current] = resp;
      setResult(sortByInvalid(result));
    } else {
      // On insert
      setResult(sortByInvalid([resp, ...result]));
    }

    // some callback
    reset();
    afterSave();
  };
  const onRemoveBubble = (id) => {
    const filter = result.filter((_, idx) => idx !== id);
    setResult(filter);
  };
  const quickFix = () => {
    const _candles = Object.entries(candles).filter((item) => {
      return conditionValidate(currentCandle, Number(item[0]));
    });
    setCandles(Object.fromEntries(_candles));
  };
  const onDuplicate = (idx) => {
    const { num, order, candles } = parseStringToMap(result[idx].record);
    setCurrentCandle(num);
    setCurrentOrder(order);
    setCandles(candles);
  };
  const value = useMemo(
    () => ({
      isDefault,
      isEditCond: isNumber(currentIdx.current),
      result,
      order: currentOrder,
      current: currentCandle,
      conditions: candles,
      reset,
      setCandle,
      setCondition,
      onSaveBubble,
      onRemoveBubble,
      setOrder,
      onEdit,
      setResult,
      resetCondition,
      quickFix,
      setIsDefault,
      onDuplicate,
    }),
    [currentCandle, candles, result, currentOrder, isDefault]
  );

  return (
    <CandlePatternContext.Provider value={value}>
      {children}
    </CandlePatternContext.Provider>
  );
};
