import { GRANT_TYPE } from '../../config/const';
import { deleteRequest, get, post, put } from './base';
import { clientId } from './base/axios';

export default {
  /**
   *
   * @param {Object} params
   * @param {string} params.email
   * @param {string} params.password
   * @param {string} params.captcha
   */
  login: ({ email, password, captcha }) =>
    post('/users/auth/token', {
      client_id: clientId,
      grant_type: GRANT_TYPE.PASSWORD,
      email,
      password,
      captcha,
    }),
  refresh: (refreshToken) =>
    post('/users/auth/token?refresh=1', {
      client_id: clientId,
      grant_type: GRANT_TYPE.REFRESH,
      refresh_token: refreshToken,
    }),
  getUserProfile: () => get('/users/me/profile'),
  /**
   *
   * @param {Object} params
   * @param {string} params.firstName
   * @param {string} params.lastName
   * @param {string} params.verifyCode
   */
  updateProfile: (params) => post('/users/me/profile', params),
  /**
   *
   * @param {Object} params
   * @param {string} params.email
   * @param {string} params.password
   * @param {string} params.confirmPassword
   * @param {string} params.referralCode
   */
  register: (params) => {
    const obj = {
      ...params,
      client_id: clientId,
    };
    return put('/users/account/register', obj);
  },
  /**
   *
   * @param {Object} params
   * @param {string} params.token
   * @param {string} params.code
   */
  verify2Fa: ({ token, code, captcha }) =>
    post('/users/auth/token-2fa', {
      token,
      code,
      client_id: clientId,
      captcha,
    }),
  /**
   *
   * @param {Object} params
   * @param {string} params.email
   */
  sendVerifyEmail: (params) => post('/users/account/resend-confirmation-email', params),
  forgotPassword: async (params) => {
    try {
      const resp = await post('/users/account/forgot-password', params);
      if (resp?.data?.ok) {
        return resp?.data;
      }
      return { ok: false, message: resp?.data?.m };
    } catch (error) {
      return { ok: false, message: error.message };
    }
  },
  resetPassword: async ({ email, code, password, confirmPassword, captcha }) => {
    try {
      const resp = await post('/users/account/reset-password', {
        email,
        code,
        password,
        confirmPassword,
        captcha,
      });
      if (resp?.data?.ok) {
        return resp?.data;
      }
      return { ok: false, message: resp?.message || resp?.data?.m };
    } catch (error) {
      return { ok: false, message: error.message };
    }
  },
  /**
   * @param {Object} params
   * @param {string} params.email
   * @param {string} params.token
   */
  verifyEmail: async (params) => {
    try {
      const resp = await post('/users/account/verify-email', params);
      if (resp?.data?.ok) {
        return resp?.data;
      }
      return {
        ok: false,
        message: resp?.message || resp?.data?.m,
        errorCode: resp?.data?.d?.err_code,
      };
    } catch (error) {
      return { ok: false, message: error.message };
    }
  },
  prepare2Fa: () => get('/users/2fa/g-authenticator'),
  /**
   * Assign the project to an employee.
   * @param {Object} params
   * @param {string} params.code
   * @param {string} params.password
   * @param {string} params.OtpCode
   * @param {string} params.RequestCode
   */
  enable2Fa: (params) => post('/users/2fa/g-authenticator', params),
  /**
   * @param {Object} params
   * @param {string} params.code
   * @param {string} params.password
   * @param {string} params.OtpCode
   * @param {string} params.RequestCode
   */
  disable2Fa: (params) => deleteRequest('/users/2fa/g-authenticator', params),
  getCodeActivate2fa: (captcha) => post('/users/2fa/g-authenticator/activate', { captcha }),
  getCodeDeactivate2fa: (captcha) => post('/users/2fa/g-authenticator/deactivate', { captcha }),
  /**
   *
   * @param {Object} params
   * @param {string} params.currentPassword
   * @param {string} params.newPassword
   * @param {string} params.confirmNewPassword
   * @param {string} params.verifyCode
   */
  changePassword: async (params) => {
    try {
      const resp = await post('/users/account/change-password', params);
      if (resp?.data?.ok) {
        return resp?.data;
      }
      return { ok: false, message: resp?.data?.m };
    } catch (error) {
      return { ok: false, message: error.message };
    }
  },
  /**
   *
   * @param {Object} params
   * @param {string} params.CountryCode
   * @param {string} params.FirstName
   * @param {string} params.LastName
   * @param {string} params.IdNumber
   * @param {File} params.IdCardFrontPhoto
   * @param {File} params.IdCardBackPhoto
   * @returns
   */
  kyc: (params) => post('/users/me/kyc', params),
  uploadAvatar: (data) => post('/users/me/photo', data),
  getAppConfig: () => get('/users/auth/settings'),
  setTutorialState: (state) => put('/plans/user/tutorial-state', state),
  getUserFollowers: (params) => {
    let url = `/plans/user/followers${params.isBlock ? '/block' : ''}?page=${params?.page}&size=${
      params?.size
    }`;
    if (params?.search && params?.search.length) {
      url = `${url}&nickName=${params.search}`;
    }
    return get(url);
  },
  blockUserFollower: (followerId) => post(`/plans/user/followers/block/${followerId}`),
  unBlockUserFollower: (followerId) => deleteRequest(`/plans/user/followers/block/${followerId}`),
  getUserFollowerStatistic: () => get('/plans/user/follow-statistic'),
  updateDeviceToken: (deviceToken) => put('/plans/user/device-token', { deviceToken }),
  deleteDeviceToken: () => deleteRequest('/plans/user/device-token'),
  getUserSettingV2: () => get('/plans/user/settings'),
  updateUserSettingV2: (key, value) => put('/plans/user/settings', { [key]: value }),
};
