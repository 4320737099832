/* eslint-disable react/prop-types */
import { SIGNAL_STRATEGY_TYPE, STRATEGY_METHOD } from '@/shared/constants';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserConfig } from '@/redux/refactor/appConfig/selector';

import { CsAccordion, Grid } from '@/shared/components/form/drawer/Elements';
import { Setting2 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useBudgetById } from '../useBudgetById';
import ExpertMode from './ExpertMode';
import { OrderIndex } from './OrderIndex';
import PrivateMode from './PrivateMode';
import ReverseSignal from './ReverseSignal';
import { SubProfit } from './SubProfit';
import WaitSignal from './WaitSignal';
import IgnoreBudget from './IgnoreBudget';

const checkStrategyHasStep0 = (methodData) => {
  for (let index = 0; index < methodData.length; index++) {
    const methodDataOptions = methodData[index].split('-');
    if (methodDataOptions.includes('0')) {
      return true;
    }
  }

  return false;
}

const FormAdvancedOptions = withTranslation()(({ isEdit }) => {
  const { t } = useTranslation();
  const mutators = useForm().mutators;
  const userConfigs = useSelector(selectUserConfig);
  const { values = {}, errors = {} } = useFormState();
  const {
    expert_mode,
    private_mode,
    subProfit,
    reverse_signal,
    wait_signal,
    budget_strategy_id,
    enableOrderIndex,
    step0_option
  } = values;
  const strategy = useBudgetById(budget_strategy_id);

  const [isStrategyHasStep0, setIsStrategyHasStep0] = useState(false);

  const notExpert = SIGNAL_STRATEGY_TYPE.expert !== values.signal_type;
  const notManual = SIGNAL_STRATEGY_TYPE.manual !== values.signal_type;

  const showSubProfit =
    SIGNAL_STRATEGY_TYPE.expert !== values.signal_type &&
    strategy?.method_code &&
    STRATEGY_METHOD.all_order !== strategy?.method_code;
  const showAccordion =
    expert_mode ||
    private_mode ||
    subProfit ||
    reverse_signal ||
    wait_signal ||
    enableOrderIndex ||
    step0_option;

  useEffect(() => {
    if (strategy?.method_data) {
      setIsStrategyHasStep0(checkStrategyHasStep0(strategy?.method_data))
    }
  }, [strategy?.method_data]);

  return (
    <CsAccordion defaultActiveKey={showAccordion ? '0' : null}>
      <CsAccordion.Item eventKey="0">
        <Grid>
          <Grid.Row>
            <CsAccordion.Header className="full-width">
              <Setting2 size={24} style={{ marginRight: 5 }} />{' '}
              {t('Advanced (Optional)')}{' '}
            </CsAccordion.Header>
          </Grid.Row>
        </Grid>
        <CsAccordion.Body>
          <Grid>
            {!values.copy_from && (
              <>
                {notExpert && (
                  <Grid.Row className="border-none">
                    <Grid.Cell>
                      <ExpertMode show={notExpert} />
                    </Grid.Cell>
                  </Grid.Row>
                )}
                {notManual && (
                  <Grid.Row className="border-none">
                    <Grid.Cell>
                      <PrivateMode show={notManual} />
                    </Grid.Cell>
                  </Grid.Row>
                )}
              </>
            )}
            <SubProfit
              mutators={mutators}
              show={showSubProfit}
              values={values}
            />
            <OrderIndex
              variant="v2"
              setOrderIndex={mutators.setOrderIndex}
              enableOrderIndex={values.enableOrderIndex}
              botId={values.bot_ai_id}
              error={errors.order_index}
              signalType={values.signal_type}
            />
            {notManual && (
              <>
                <Grid.Row className="border-none">
                  <Grid.Cell>
                    <ReverseSignal show={notManual} reversePseudo={userConfigs.reverse_pseudo_order} />
                  </Grid.Cell>
                </Grid.Row>
              </>
            )}

            {notExpert && notManual && (
              <Grid.Row className="border-none">
                <Grid.Cell>
                  <WaitSignal
                    mutators={mutators}
                    show={notExpert && notManual}
                    value={values.wait_signal}
                    waitSignalType={values.waitSignalType}
                  />
                </Grid.Cell>
              </Grid.Row>
            )}
            {STRATEGY_METHOD.all_order !== strategy?.method_code && isStrategyHasStep0 && (
              <Grid.Row className="border-none">
                <Grid.Cell>
                  <IgnoreBudget
                    mutators={mutators}
                    show={notExpert && notManual}
                    value={values.step0_option}
                    waitSignalType={values.waitSignalType}
                  />
                </Grid.Cell>
              </Grid.Row>
            )}
          </Grid>
        </CsAccordion.Body>
      </CsAccordion.Item>
    </CsAccordion>
  );
});

export default FormAdvancedOptions;
