import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccountTitle } from '@/shared/components/account/AccountElements';
import ResetPasswordForm from '@/shared/components/account/reset_password/ResetPasswordForm';
import { ModalAccountDescription } from '@/shared/components/account/ModalAccount';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const CardResetPassword = withTranslation()(({ handleSubmit, loading, t }) => (
  <Fragment>
    <Box marginBottom="16px">
      <AccountTitle>{t('ResetPassword.title', 'Create new password')}</AccountTitle>
    </Box>
    <ModalAccountDescription
      dangerouslySetInnerHTML={{
      __html: 
        t(
          'ResetPassword.description', 
          'Please enter a new password. Your new password must be different from previous password.',
          ), 
      }}
    />
    <ResetPasswordForm onSubmit={handleSubmit} isLoading={loading} />
  </Fragment>
));


CardResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};


export default CardResetPassword;
