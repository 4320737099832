import { t } from 'i18next';

export const convertMaskToNum = (value) => {
  if (!value) return 0;

  return value.toString().startsWith('$')
    ? Number(value.replace('$', '').replaceAll(',', ''))
    : value;
};

export const checkBaseAmount = (value) => {
  const num = convertMaskToNum(value);
  if (num > 1000000 || num < 1) {
    return t('Value is invalid');
  }
  return undefined;
};


export const advanceValidate = (value) => {
  const errors = {};

  errors.no_of_entries =
    !value.wait_signal || value.isUltilFinishBudget === 'a'
      ? undefined
      : checkBaseAmount(value.no_of_entries);

  return errors;
};
