import { STORAGE_KEY } from '@/config/const';
import { TYPE_ACCOUNT } from '@/shared/constants';
import { generic } from '@/utils/generic';
import { handleActions } from 'redux-actions';
import {
  getTotalFailed,
  getTotalRequest,
  getTotalSuccess,
  setAccountType,
  setChartData,
  setChartDataLoading,
  setTotalPlan,
  setTotalPlanLoading,
} from './actions';

const defaultState = {
  totalPlan: {
    LIVE: 0,
    DEMO: 0,
  },
  totalInvested: 0,
  totalProfit: 0,
  totalLose: 0,
  cashAvailable: 0,
  totalVolume: 0,
  totalWinCount: 0,
  totalLoseCount: 0,
  winRate: 0,
  updatedAt: new Date(),
  isFetching: true,
  accountType:
    generic.getJsonLocalStorage(STORAGE_KEY.DASHBOARD_ACCOUNT_TYPE) ||
    TYPE_ACCOUNT.demo,
  chartData: [],
  topPlans: [],
  leaderBoard: [],
  chartDataLoading: true,
  totalType: 'DAILY',
  totalPlanLoading: true,
};

export default handleActions(
  {
    [getTotalRequest]: (state, { payload }) => ({
      ...state,
      isFetching: true,
      totalType: payload,
    }),
    [getTotalSuccess]: (state, { payload }) => ({
      ...state,
      ...payload,
      isFetching: false,
    }),
    [getTotalFailed]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [setAccountType]: (state, { payload }) => {
      generic.setJsonLocalStorage(STORAGE_KEY.DASHBOARD_ACCOUNT_TYPE, payload);
      return {
        ...state,
        accountType: payload,
      };
    },
    [setChartData]: (state, { payload }) => ({
      ...state,
      chartData: payload,
    }),
    [setChartDataLoading]: (state, { payload }) => ({
      ...state,
      chartDataLoading: payload,
    }),
    [setTotalPlan]: (state, { payload }) => ({
      ...state,
      totalPlan: payload,
    }),
    [setTotalPlanLoading]: (state, { payload }) => ({
      ...state,
      totalPlanLoading: payload,
    }),
  },
  defaultState
);
