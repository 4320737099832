import useCaptcha from '@/hooks/useCaptcha';
import { login } from '@/redux/refactor/exchange/actions';
import AccountWithoutBanner from '@/shared/components/account/AccountWithoutBanner';
import ModalStartup from '@/shared/components/account/connect-exchange/ModalStartup';
import { Content } from '@/shared/components/account/connect-exchange/styles';
import Sliders from '@/shared/components/wallet/drawer-wallet/Slider';
import _, { isEqual } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import gtag from '@/shared/constants/gtag';

import PageHead from './PageHead';

const ga4Stats = window.ga4Stats;

const FormExchangeUrl = lazy(() =>
  import('@/shared/components/account/connect-exchange/exchange-url')
);
const FormExchangeLogin = lazy(() =>
  import('@/shared/components/account/connect-exchange/exchange-login')
);
const FormExchangeRegister = lazy(() =>
  import('@/shared/components/account/connect-exchange/exchange-register')
);

const Components = [
  <FormExchangeUrl key="exchange-url" />,
  <FormExchangeLogin key="exchange-login" />,
  <FormExchangeRegister key="exchange-register" />,
];

export default function FormWizard() {
  const [showStartup, setShowStartup] = useState(true);
  const [focusedIdx, setFocusedIdx] = useState(0);
  const [site, setSite] = useState({});
  const profile = useSelector((state) => state.user.profile || {});
  const { leader, client_id, domain, logo } = useSelector(
    (state) => state.exchange,
    isEqual
  );
  const { execute } = useCaptcha();
  const dispatch = useDispatch();

  const onLogin = async ({ exchangeE, exchangeP }) => {
    const captcha = await execute('login');
    dispatch(
      login({
        email: exchangeE,
        password: exchangeP,
        domain: site?.domain,
        clientId: site?.client_id,
        captcha,
      })
    );

    ga4Stats(gtag.connectAccount.action.changeExchange, gtag.connectAccount.category, gtag.connectAccount.label, profile?.uid);
  };

  useEffect(() => {
    if (domain) {
      setSite({ client_id, domain, logo });
      setFocusedIdx(1);
    }
  }, [domain]);

  const onCloseStartup = () => {
    setShowStartup(false);
  };
  const onRegisterNow = () => {
    if (leader?.domain) {
      setSite(leader);
    }
    setShowStartup(false);
    setFocusedIdx(2);
  };
  const goToCreate = () => {
    if (leader?.domain) {
      setSite(leader);
    }
    setFocusedIdx(2);
  };
  const newComponent = (component) => {
    return React.cloneElement(component, {
      site,
      leader,
      setSite,
      setFocusedIdx,
      goToCreate,
      onLogin,
    });
  };

  return (
    <AccountWithoutBanner showHint={false}>
      <Content>
        <PageHead domain={site?.domain} logo={site?.logo} />
        <Sliders focusedIdx={focusedIdx}>
          <Suspense>
            {Components.map((component, idx) => (
              <div key={idx}>
                {_.isEqual(focusedIdx, idx) && newComponent(component)}
              </div>
            ))}
          </Suspense>
        </Sliders>
      </Content>
      <ModalStartup
        isOpen={showStartup && (domain || leader?.domain)}
        closeModal={onCloseStartup}
        onRegisterNow={onRegisterNow}
        setSite={setSite}
      />
    </AccountWithoutBanner>
  );
}
