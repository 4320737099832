import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { chartTypes } from "../InsightChart";
import { toPercent } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';

const ChartTooltip = ({id, style, chartType, currentPoint}) => {
  const { t } = useTranslation();
  
  return (
    <div
      id={id}
      className="bar-chart-tooltip"
      style={style}
    >
      <div className="d-flex bar-chart-tooltip-item justify-content-between align-items-center">
        <span className="bar-chart-tooltip-label">
          {chartType === chartTypes.profitable && t('Profit')}
          {chartType === chartTypes.signal && t('Win rate')}
          {chartType === chartTypes.tele && t('Win rate')}
        </span>
        <span className="bar-chart-tooltip-value">
          {chartType === chartTypes.profitable && formatNumber.formatCurrency(currentPoint.value)}
          {chartType === chartTypes.signal && toPercent(currentPoint.value)}
          {chartType === chartTypes.tele && toPercent(currentPoint.value)}
        </span>
      </div>
      {[chartTypes.profitable, chartTypes.signal].includes(chartType) && (
        <div className="d-flex bar-chart-tooltip-item bar-chart-tooltip-item--combination justify-content-between align-items-start">
          <span className="bar-chart-tooltip-label">{t('Signal Strategy')}</span>
          {currentPoint.label && (
            <span className="bar-chart-tooltip-value text-end">
              {chartType === chartTypes.profitable && currentPoint.label.split("/")[1]}
              {chartType === chartTypes.signal && currentPoint.label}
            </span>
          )}
        </div>
      )}
      {chartType === chartTypes.profitable && (
        <div className="d-flex bar-chart-tooltip-item bar-chart-tooltip-item--combination justify-content-between align-items-start">
          <span className="bar-chart-tooltip-label">{t('Budget Strategy')}</span>
          {currentPoint.label && <span className="bar-chart-tooltip-value text-end">{currentPoint?.label.split("/")[0]}</span>}
        </div>
      )}

      {chartType === chartTypes.tele && currentPoint?.label && (
        <>
          {currentPoint?.label.split("/").map((item, index) => (
            <div className="d-flex bar-chart-tooltip-item bar-chart-tooltip-item--combination justify-content-between align-items-start" key={index}>
              <span className="bar-chart-tooltip-label">{t('Tele channel')} {index + 1}</span>
              {currentPoint.label && <span className="bar-chart-tooltip-value text-end">{item}</span>}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

ChartTooltip.propTypes = {
  id: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  chartType: PropTypes.string,
  currentPoint: PropTypes.instanceOf(Object).isRequired,
};

ChartTooltip.defaultProps = {
  id: '',
  style: {},
  chartType: ''
}

export default ChartTooltip;
