import React from "react";
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { ExchangeLogo, PageTitleStyle } from '@/shared/components/account/connect-exchange/styles';

function PageHead({ domain, logo }) {
  const { t } = useTranslation();
  return (
    <div className='head'>
      <PageTitleStyle>{t('Connect your account')}</PageTitleStyle>
      <ExchangeLogo className={logo ? 'hasImage' : ''}>
        {logo && <Image height="100%" src={logo} />}
      </ExchangeLogo>
      {domain && <p className='site-url'>{domain}</p>}
    </div>
  )
}

PageHead.propTypes = {
  domain: PropTypes.string,
  logo: PropTypes.string
}

PageHead.defaultProps = {
  domain: '',
  logg: ''
}

export default PageHead;