/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import _ from 'lodash';

// eslint-disable-next-line no-underscore-dangle
const _instance = {
    String: 'String',
    Number: 'Number',
    Boolean: 'Boolean',
    Object: 'Object',
    Array: 'Array',
    Any: 'Any',
    DefaultValue: {
        String: '',
        Number: 0,
        Boolean: false,
        Object: {},
        Array: [],
    },
    removeLocalStorage(name) {
        localStorage.removeItem(name);
    },
    setJsonLocalStorage(name, data) {
        const dataString = JSON.stringify(data);
        localStorage.setItem(name, dataString);
    },
    getJsonLocalStorage(name) {
        const value = localStorage.getItem(name);
        if (value !== 'undefined') {
            return JSON.parse(value);
        }
        return null;
    },
    // session storage
    removeSessionStorage(name) {
        sessionStorage.removeItem(name);
    },
    setJsonSessionStorage(name, data) {
        const dataString = JSON.stringify(data);
        sessionStorage.setItem(name, dataString);
    },
    dataValid(value, dataType) {
        let flag = false;
        switch (dataType) {
            case this.String:
                flag = _.isString(value);
                break;
            case this.Number:
                if (_.isString(value)) {
                    const tmp = parseFloat(value);
                    flag = !_.isNaN(tmp) && _.isFinite(tmp);
                }
                flag = flag || _.isNumber(value);
                break;
            case this.Boolean:
                flag = _.isBoolean(value);
                break;
            case this.Object:
                flag = _.isObject(value);
                break;
            case this.Array:
                flag = _.isArray(value);
                break;
            case this.Any:
                flag = true;
        }
        return flag;
    },
    parseValue(value, dataType) {
        let res;
        switch (dataType) {
            case this.String:
                res = _.isString(value) ? value : this.DefaultValue.String;
                break;
            case this.Number:
                if (_.isString(value)) {
                    value = parseFloat(value);
                    if (_.isNaN(value) || !_.isFinite(value)) {
                        value = this.DefaultValue.Number;
                    }
                }
                res = _.isNumber(value) ? value : this.DefaultValue.Number;
                break;
            case this.Boolean:
                res = _.isBoolean(value) ? value : this.DefaultValue.Boolean;
                break;
            case this.Object:
                res = _.isObject(value) ? value : this.DefaultValue.Object;
                break;
            case this.Array:
                res = _.isArray(value) ? value : this.DefaultValue.Array;
                break;
            case this.Any:
                res = value;
        }
        return res;
    },
    selectValue(object, key, dataType) {
        object = _.isObject(object) ? object : {};
        dataType = dataType || this.String;
        key = _.isString(key) ? key : '';
        const stackKeys = key.split('.').reverse();
        let tmpObj = object;
        while (stackKeys.length) {
            tmpObj = tmpObj[stackKeys.pop()] || {};
        }
        return this.parseValue(tmpObj, dataType);
    },
    setValue(object, key, dataType, value) {
        object = _.isObject(object) ? object : {};
        dataType = dataType || this.String;
        key = _.isString(key) ? key : '';
        const stackKeys = key.split('.').reverse();
        let tmpObj = object;
        while (stackKeys.length > 1) {
            const _key = stackKeys.pop();
            tmpObj[_key] = tmpObj[_key] || {};
            tmpObj = tmpObj[_key];
        }
        if (stackKeys[0]) {
            tmpObj[stackKeys[0]] = this.dataValid(value, dataType)
                ? value
                : this.DefaultValue[dataType];
        }
        return object;
    },
};

export class Entity {
    constructor(data, mapping) {
        this._parsingData(data, mapping);
    }

    _parsingData(data, mapping) {
        data = _.isObject(data) ? data : {};
        this._mapping = mapping || this.__mapping;
        for (const k in this._mapping) {
            const item = _.isArray(this._mapping[k]) ? this._mapping[k] : [];
            this[k] = _instance.selectValue(data, item[0], item[1]);
        }
    }

    set _mapping(value) {
        this.__mapping = _.isObject(value) ? value : {};
    }

    get _mapping() {
        return _.isObject(this.__mapping) ? this.__mapping : {};
    }

    get serialize() {
        const res = {};
        for (const k in this._mapping) {
            const item = _.isArray(this._mapping[k]) ? this._mapping[k] : [];
            _instance.setValue(res, item[0], item[1], this[k]);
        }
        return res;
    }
}

export const generic = {
    ..._instance,
    Entity,
};

export default generic;
