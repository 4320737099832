import _ from 'lodash';
import notificationApi from '@/utils/api/notificationApi';
import { createAction } from 'redux-actions';
import { NOTIFICATION_TYPE } from '@/shared/constants';

export const updateIsLoadingNoti = createAction('UPDATE_IS_LOADING_NOTI');
export const updateNoties = createAction('UPDATE_NOTIES');
export const addLastNoti = createAction('ADD_LAST_NOTI');
export const updateStatusNoties = createAction('UPDATE_STATUS_NOTIES');
export const updateNotiPageconfig = createAction('UPDATE_NOTI_PAGECONFIG');
export const updateIdNotiesRendered = createAction('UPDATE_ID_NOTIES_RENDERED');
export const updateNotiCurrentTab = createAction('UPDATE_CURRENT_TAB');
export const updateNotiIsActive = createAction('UPDATE_IS_ACTIVE');

export const fetchNoti = (params) => async (dispatch, getState) => {
  // when scroll down to final element (in data, not in ui) then don't call api
  // happen when scroll down list, not happen when init noti
  const currentState = getState();
  if (params.channel) {
    const pageConfig = currentState?.noti?.pageConfigs.filter(
      (p) => p.channel === params.channel
    )[0];
    if (pageConfig && pageConfig.isEnded) {
      return;
    }
  }
  // end

  dispatch(updateIsLoadingNoti(true));
  const channels = params.channel
    ? [params.channel]
    : [NOTIFICATION_TYPE.system, NOTIFICATION_TYPE.plan];

  const tasks = channels.map(async (channel) => {
    try {
      const newParams = { ...params, channel, account_type: channel === NOTIFICATION_TYPE.plan ? params.account_type : '' };

      const notiesData = await notificationApi.getNoties({...newParams, size: 16 });

      if (notiesData?.data?.ok) {
        if (notiesData?.data?.d?.c?.length) {
          dispatch(updateNoties(notiesData?.data?.d?.c));
          dispatch(
            updateNotiPageconfig({
              channel: channel,
              unread: notiesData?.data?.d?.unread,
            })
          );
        } else {
          dispatch(
            updateNotiPageconfig({
              channel: channel,
              unread: notiesData?.data?.d?.unread,
              isEnded: true
            })
          );
        }
      }
    } catch (error) {
      console.error(`Error processing channel ${channel}:`, error);
    }
  });

  Promise.all(tasks).then(() => {
    dispatch(updateIsLoadingNoti(false));
  });
};

export const changeStatusNoties = (idNotiesRendered) => async (dispatch, getState) => {
  const currentState = getState();
  const channels = [NOTIFICATION_TYPE.system, NOTIFICATION_TYPE.plan];

  channels.forEach(channel => {
    const notiesByType = currentState?.noti?.noties.filter(item => idNotiesRendered.indexOf(item.id) > -1 && item.channel === channel);
    const pageConfig = currentState?.noti?.pageConfigs.filter(
      (p) => p.channel === channel
    )[0];

    dispatch(updateNotiPageconfig({
      ...pageConfig,
      unread:  (pageConfig.unread - notiesByType.length) >= 0 ? pageConfig.unread - notiesByType.length : 0,
    }));
  })

  const noties = currentState?.noti?.noties.map(item => {
    if (idNotiesRendered.indexOf(item.id) > -1) {
      return {...item, read: true}
    }
    return item;
  });
  dispatch(updateStatusNoties(noties));
}

export const changeLastNoti = (params) => async (dispatch, getState) => {
  const currentState = getState();
  dispatch(addLastNoti(params));
  const pageConfig = currentState?.noti?.pageConfigs.filter(
    (p) => p.channel === params.channel
  )[0];

  dispatch(updateNotiPageconfig({
    ...pageConfig,
    unread:  pageConfig.unread + 1,
  }));

  if (!currentState?.noti?.isActive) {
    const currentTab = currentState?.noti?.currentTab;
    if (currentTab !== params.channel) {
      dispatch(updateNotiCurrentTab(params.channel))
    }
  }
};