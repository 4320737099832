import React, { useContext } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TAB } from './const';
import { FORM_TYPE } from '@/shared/constants';
import { CandlePatternContext } from './context';

import { Button } from '@/shared/components/Button';
import { Form } from '@/shared/components/form/drawer/Elements';

function ButtonSubmit({ isValid, onSubmit, loading, isEdit, copyFrom }) {
  const { t } = useTranslation();
  const { result = [] } = useContext(CandlePatternContext);
  const empty = !copyFrom && result.length === 0;
  return (
    <Button
      disabled={!isValid || empty || loading}
      className="footer__cta"
      onClick={() => onSubmit(result)}
      isLoading={loading}
      variant="success"
      block="true"
    >
      {t(isEdit ? 'Save Bot' : 'Create Bot')}
    </Button>
  );
}

function ButtonSave({ setTab }) {
  const { t } = useTranslation();
  const { conditions, onSaveBubble } = useContext(CandlePatternContext);
  const disabled = Object.entries(conditions).filter((item) => item[1]).length === 0;
  const onClick = () => {
    onSaveBubble(() => {
      setTab(TAB.FORM);
    });
  };
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className="footer__cta"
      block="true"
      variant="success"
    >
      {t('Save')}
    </Button>
  );
}

function ButtonBack({ tab, setTab, onClose, isBlock }) {
  const { t } = useTranslation();
  const { resetCondition } = useContext(CandlePatternContext);
  const handleClick = () => {
    if (tab === TAB.FORM) {
      onClose();
      return;
    }
    resetCondition();
    setTab(TAB.FORM);
  };

  return (
    <Button className="cta-back" variant="secondary_outline" onClick={handleClick} block={isBlock}>
      {tab === TAB.FORM ? t('Close') : t('Back')}
    </Button>
  );
}

const FormCandleFooter = ({
  tab,
  loading,
  validBotName,
  copyFrom,
  setTab,
  onClose,
  allowCustomize,
  handleCustomize,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formCandle.type,
    }),
    isEqual
  );

  return (
    <Form.Footer>
      <ButtonBack tab={tab} setTab={setTab} onClose={onClose} isBlock={formType === FORM_TYPE.read_only} />
      {allowCustomize && (currentBot.is_default || formType === FORM_TYPE.read_only) && (
        <Button onClick={handleCustomize} variant="success" block="true">
          {t('Customize Candle Pattern')}
        </Button>
      )}

      {!currentBot.is_default && formType !== FORM_TYPE.read_only && (
        <>
          {tab === TAB.FORM && (
            <ButtonSubmit
              loading={loading}
              isValid={validBotName.isValid}
              onSubmit={handleSubmit}
              isEdit={formType !== FORM_TYPE.create}
              copyFrom={copyFrom}
            />          
          )}
          {tab === TAB.CANDLE && <ButtonSave setTab={setTab} />}
        </>
      )}
    </Form.Footer>
  );
};

export default FormCandleFooter;
