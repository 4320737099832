import { Grid } from '@/shared/components/form/drawer/Elements';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SettingItem from '../SettingItem';
import './index.scss';

export const TimersConfig = memo(
  ({ variant = 'v1', timers = [], onClose = () => {} }) => {
    const { t } = useTranslation();

    const renderHeader = () => (
      <SettingItem variant={variant} title={t('Timers config')} />
    );

    if (!timers.length) {
      return;
    }

    return (
      <Grid className="plan-timer-config">
        <Grid.Row>
          <Grid.Cell>
            {renderHeader()}
            <div className="d-flex flex-wrap plan-timer-config-list">
              {timers.map((item) => (
                <Link
                  to={`/portfolios/schedule?timerId=${item.id}`}
                  key={item.id}
                  onClick={onClose}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Grid.Cell>
        </Grid.Row>
      </Grid>
    );
  }
);
