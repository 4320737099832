import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { getNotifyPlans } from '@/redux/refactor/notification/actions';
import { Button } from '@/shared/components/Button';
import { Card } from '@/shared/components/Card';
import planApi from '@/utils/api/planApi';
import {
  borderDefaultStyle,
  colorSuccess,
  colorText,
  Devices,
  VARIANTS,
} from '@/utils/palette';
import { Box, Drawer } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import gtag from '@/shared/constants/gtag';

import ReviewPlan from './ReviewPlan';

const ga4Stats = window.ga4Stats;

const DrawerClonePlan = ({
  isOpen,
  plan,
  onActivePlan,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {accountTypePlan, userActivityTracker} = useSelector(
    (state) => ({
      accountTypePlan: state.wallet.accountType,
      userActivityTracker: state.userActivityTracker
    }),
    isEqual
  );

  const [loading, setLoading] = useState(false);

  const handleImport = () => {
    setLoading(true);
    planApi.importPlan(plan.copy_code).then((res) => {
      setLoading(false);
      if (res?.data?.ok) {
        onClose();
        toast.success(t('Import the plan successfully!'));
        onSuccess();
        dispatch(getTotalPlan(accountTypePlan));
        dispatch(getNotifyPlans());
        setTimeout(() => {
          onActivePlan(res.data.d, onSuccess);
        }, 500);
        
        if (userActivityTracker.isClickCopyTopPlan) {
          ga4Stats(gtag.dashboard.action.copyTopPlan, gtag.dashboard.category, gtag.dashboard.label, res.data.id);
        }
        
        ga4Stats(gtag.plan.action.importPlan, gtag.plan.category, gtag.plan.label);
      } else {
        toast.error(t(res.data.m));
      }
    });
  };

  return (
    <DrawerStyle anchor="bottom" open={isOpen} onClose={onClose} className="drawer-clone-plan">
      <CardStyle className="form-create m-0">
        <Card.Header>
          <Box flex="1" overflow="hidden">
            <h5>{t('Review plan')}</h5>
          </Box>
          <button
            type="button"
            className="close"
            label="close"
            onClick={onClose}
          />
        </Card.Header>
        <CardBody>
          <Box maxWidth="620px" margin="0 auto">
            <Box mb="40px">
              <Title>{t('DrawerCopyPlan_title')}</Title>
              <SubTitle>{t('DrawerCopyPlan_subtitle')}</SubTitle>
            </Box>
            <ReviewPlan values={plan} />
          </Box>
        </CardBody>
        <CardFooter>
          <ButtonGroup>
            <ExpandWrap>
              <ButtonExpand
                disabled={loading}
                isLoading={loading}
                type="button"
                variant={VARIANTS.SUCCESS}
                onClick={handleImport}
                className="btn-clone-plan"
              >
                {t('DrawerCopyPlan_btnSave', 'Confirm & Save')}
              </ButtonExpand>
            </ExpandWrap>
          </ButtonGroup>
        </CardFooter>
      </CardStyle>
    </DrawerStyle>
  );
};

const DrawerStyle = styled(Drawer)`
  .MuiPaper-root {
    background-color: transparent;
  }
`;

const CardStyle = styled(Card)`
  .card-header {
    display: flex;
    justify-content: flex-end;
    border: none;
    border-bottom: ${borderDefaultStyle};
    padding: 16px !important;
    @media ${Devices.pc} {
      padding: 24px 32px !important;
    }
    h5 {
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
      color: ${colorText};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media ${Devices.pc} {
        padding-left: 24px;
      }
    }
  }
  .card-body {
    /* min-height: 50vh; */
  }
  .card-footer {
    padding: 16px;
  }
`;
const CardBody = styled(Card.Body)`
  height: 100%;
  padding: 16px;
  @media ${Devices.pc} {
    padding: 24px;
  }
`;
const CardFooter = styled(Card.Footer)`
  padding: 16px !important;
`;

const ButtonGroup = styled(Box)`
  display: flex;
  padding: 0;
  justify-content: flex-end;
  gap: 16px;
  button {
    min-width: 120px;
    padding-left: 32px;
    padding-right: 32px;
  }
  @media ${Devices.pc} {
    padding: 0 16px;
  }
`;

const ButtonExpand = styled(Button)`
  width: 100%;
  @media ${Devices.pc} {
    width: unset;
  }
  &:focus {
    background-color: ${colorSuccess}!important;
  }
`;

const ExpandWrap = styled.div`
  flex: 1;
  @media ${Devices.pc} {
    flex: 0;
  }
`;

const Title = styled.h1`
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #0ba259;
  text-align: center;
`;
const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #718096;
`;

DrawerClonePlan.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  plan: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onActivePlan: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

DrawerClonePlan.defaultProps = {
  plan: {},
  onSuccess: () => {},
};

export default DrawerClonePlan;
