import moment from 'moment';
import { createSelector } from 'reselect';
import { selectAccountType, selectWallet } from './walletSelector';

export const selectDashboard = (state) => state.dashboard;
export const selectTopPlans = (state) => state.dashboard.topPlans || [];
export const selectLeaderBoard = (state) => state.dashboard.leaderBoard;

export const selectChartLoading = (state) => state.dashboard.chartDataLoading;

export const selectTotalBalance = createSelector(selectDashboard, (item) => ({
  totalInvested: item.totalInvested,
  totalProfit: item.totalProfit,
  totalLose: item.totalLose,
  cashAvailable: item.cashAvailable,
  totalBalance: item.totalBalance,
  isFetching: item.isFetching,
}));
export const selectChartData = createSelector(
  selectDashboard,
  ({ chartData }) => {
    const data = {
      profitData: [],
      volumeData: [],
      maxProfitValue: 0,
      maxVolumeValue: 0,
    };
    chartData.forEach((item) => {
      data.profitData.push({
        value: item.profit,
        date: item.date,
      });
      data.volumeData.push({
        value: item.dailyVolume,
        date: item.date,
      });

      if (Math.abs(item.profit) > data.maxProfitValue)
        data.maxProfitValue = Math.abs(item.profit);

      if (Math.abs(item.dailyVolume) > data.maxVolumeValue)
        data.maxVolumeValue = Math.abs(item.dailyVolume);
    });

    return data;
  }
);

export const selectEvents = createSelector(
  selectDashboard,
  ({ chartData = [] }) => {
    return chartData.reduce((accum, item) => {
      if (item.profit !== 0) {
        accum.push({
          title: 'Profit',
          type: 'profit',
          start: moment(item.date).startOf('day'),
          end: moment(item.date).endOf('day'),
          value: item.profit,
        });
      }
      if (item.dailyVolume !== 0) {
        accum.push({
          title: 'Volume',
          type: 'volume',
          start: moment(item.date).startOf('day'),
          end: moment(item.date).endOf('day'),
          value: item.dailyVolume,
        });
      }
      return accum;
    }, []);
  }
);

export const selectDataStatistics = createSelector(
  selectDashboard,
  ({ chartData = [] }) => {
    return {
      totalProfit: chartData.length ? chartData[0].totalProfit : 0,
      totalVolume: chartData.length ? chartData[0].totalVolume : 0,
    };
  }
);

export const selectTotalPlan = createSelector(
  selectDashboard,
  ({ totalPlan }) => totalPlan['LIVE'] + totalPlan['DEMO']
);

export const selectCurrentTotalPlan = createSelector(
  selectAccountType,
  selectDashboard,
  (accountType, { totalPlan }) => totalPlan[accountType]
);
