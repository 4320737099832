import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import botApi from '@/utils/api/botApi';
import { FORM_TYPE, STRATEGY_TYPE, SIGNAL_STRATEGY_TYPE } from '@/shared/constants';

import { Form } from '@/shared/components/form/drawer/Elements';
import { DropdownIndicator, DropdownPlaceholder } from '@/shared/components/react-select';
import { TOTAL_BOT_MIX } from '@/config/const';

import BotAiIcon from '../../../../assets/image/top-signal/botAi.svg';
import TeleIcon from '../../../../assets/image/top-signal/tele.svg';

const CustomOption = ({ innerProps, isDisabled, children, data }) => {
  return !isDisabled ? (
    <div {...innerProps} className="react-select-style2__option d-flex align-items-center">
      <div className="me-1">
        {data.type === SIGNAL_STRATEGY_TYPE.telegram && <TeleIcon />}
        {data.type === SIGNAL_STRATEGY_TYPE.botai && <BotAiIcon />}
      </div>
      {children}
    </div>
  ) : null;
};

const SelectSignalMethod = ({ botSignalStrategyType, botMethodUsing, expertValue, setBotMethodUsing }) => {
  const { t } = useTranslation();
  const [optionsMethodUsing, setOptionsMethodUsing] = useState([]);
  const [optionsBotAIMethodUsing, setOptionsBotAIMethodUsing] = useState([]);

  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
    }),
    isEqual
  );

  useEffect(() => {
    if (optionsMethodUsing.length && botSignalStrategyType) {
      if (botSignalStrategyType === STRATEGY_TYPE.single) {
        if (formType === FORM_TYPE.create && !currentBot.is_customize) {
          setBotMethodUsing(optionsMethodUsing[0]);
        } else if (currentBot.sources) {
          const methodUsing = optionsMethodUsing.filter(
            (method) => method.value === currentBot.sources[0]?.id
          )[0];
          setBotMethodUsing(!methodUsing ? optionsMethodUsing[0] : methodUsing);
        }
      }

      if (botSignalStrategyType !== STRATEGY_TYPE.single) {
        if (formType === FORM_TYPE.create && !currentBot.is_customize) {
          setBotMethodUsing([optionsMethodUsing[0]]);
        } else if (currentBot.sources) {
          const sourceIds = currentBot.sources.map((source) => source.id);
          const methodsUsing = optionsMethodUsing.filter(
            (method) => sourceIds.indexOf(method.value) > -1
          );
          setBotMethodUsing(!methodsUsing.length ? [optionsMethodUsing[0]] : methodsUsing);
        }
      }
    }
  }, [optionsMethodUsing, botSignalStrategyType, currentBot]);

  useEffect(() => {
    getSignalSources();
  }, []);

  const handleChangeMethodUsing = (selected) => {
    if (
      botSignalStrategyType === STRATEGY_TYPE.mix &&
      selected.length + expertValue.length <= TOTAL_BOT_MIX
    ) {
      setBotMethodUsing(selected);
    }
    if (
      botSignalStrategyType === STRATEGY_TYPE.single ||
      botSignalStrategyType === STRATEGY_TYPE.string
    ) {
      setBotMethodUsing(selected);
    }
  };

  const getSignalSources = () => {
    console.log(11111)
    botApi.getSignalSources('MIX').then((res) => {
      if (res?.data?.ok) {
        const options = res.data.d.map((option) => ({
          value: option.id,
          label: option.name,
          type: option.type,
        }));

        setOptionsBotAIMethodUsing(options.filter((o) => o.type === SIGNAL_STRATEGY_TYPE.botai));
        setOptionsMethodUsing(options);
      } else {
        toast.error(t(res.data.m));
      }
    });
  };

  return (
    <>
      {botSignalStrategyType === STRATEGY_TYPE.mix && (
        <Form.Label className="d-flex align-items-center sub-label">
          {t('Bot AI & Telegram channel')}*
        </Form.Label>
      )}

      <Select
        menuPlacement="auto"
        options={
          botSignalStrategyType === STRATEGY_TYPE.mix ? optionsMethodUsing : optionsBotAIMethodUsing
        }
        value={botMethodUsing}
        onChange={handleChangeMethodUsing}
        isMulti={botSignalStrategyType === STRATEGY_TYPE.mix}
        closeMenuOnSelect={botSignalStrategyType !== STRATEGY_TYPE.mix}
        isDisabled={currentBot.is_default || formType === FORM_TYPE.read_only}
        classNamePrefix="react-select-style2"
        components={{
          DropdownIndicator,
          Placeholder: DropdownPlaceholder,
          Option: CustomOption,
        }}
      />
    </>
  );
};

SelectSignalMethod.propTypes = {
  botSignalStrategyType: PropTypes.string,
  botMethodUsing: PropTypes.instanceOf(Object).isRequired,
  expertValue: PropTypes.instanceOf(Array).isRequired,
  setBotMethodUsing: PropTypes.func.isRequired,
};

export default SelectSignalMethod;
