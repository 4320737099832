import {
  DropdownIndicator,
  DropdownPlaceholder,
} from '@/shared/components/react-select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

export function SelectMethod({ value, options = [], onChange, isDisabled }) {
  const { t } = useTranslation();
  const opts = useMemo(() => {
    return options.reduce(
      (acc, item) => [
        ...acc,
        {
          label: item.name,
          value: item.code,
        },
      ],
      []
    );
  }, [options]);
  const val = useMemo(() => {
    return opts.find((item) => item.value === value);
  }, [value, opts]);

  return (
    <ReactSelect
      menuPlacement="auto"
      options={opts}
      value={val}
      onChange={onChange}
      classNamePrefix="react-select-style2"
      className="full-width"
      isDisabled={isDisabled}
      components={{ DropdownIndicator, Placeholder: DropdownPlaceholder }}
    />
  );
}
