const sliceDecimalFloat = (val, decimal = 2) => {
  if (!val) return 0;
  let str = val.toString();
  if (str.includes('.')) {
    const ar = str.split('.');
    if (!decimal) {
      return ar[0];
    }
    str = `${ar[0]}.${ar[1].slice(0, decimal)}`;
  }
  return str;
};

export default sliceDecimalFloat;
