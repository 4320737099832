import { updateCurrentLayoutTicket } from '@/redux/actions/ticketActions';
import { ArrowLeft } from 'iconsax-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const Header = () => {
  const dispatch = useDispatch();
  const currentTicket = useSelector(
    (state) => state.ticket.currentTicket,
    shallowEqual
  );
  const currentLayout = useSelector(
    (state) => state.ticket.currentLayout,
    shallowEqual
  );

  const { t } = useTranslation();

  return (
    <>
      {!currentLayout.isTicketDetail && (
        <div className="ticket-popup-header">
          {!currentLayout.isList && (
            <button
              type="button"
              className="ticket-popup-control back"
              onClick={() => dispatch(updateCurrentLayoutTicket('list'))}
            >
              <ArrowLeft size="18" />
            </button>
          )}
          {currentLayout.isList && (
            <h2 className="ticket-popup-title">{t('Ticket Center')}</h2>
          )}
          {currentLayout.isCreateForm && (
            <h2 className="ticket-popup-title">{t('Submit a ticket')}</h2>
          )}
          {currentLayout.isListComment && (
            <h2 className="ticket-popup-title">
              {t('Ticket ID')} : {currentTicket.id}
            </h2>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
