import { isEqual } from 'lodash';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button as BsButton, Modal, Image } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styled-theming';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ArrowRight2, Add } from 'iconsax-react';

import { Button } from '../../Button';
import SuccessImg from '@/shared/img/registerSuccess.svg';

import {
  colorBackgroundModal,
  colorGreyScale300,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale800,
  colorText,
  Devices,
  colorSuccess,
} from '@/utils/palette';

const ExchangeLogoBgColor = theme('mode', {
  light: colorGreyScale300,
  dark: colorGreyScale800,
});

const ConnectedItemBorderColor = theme('mode', {
  light: colorGreyScale300,
  dark: colorGreyScale700,
});

export default function ModalStartup({ isOpen, closeModal, onRegisterNow, setSite }) {
  const { t } = useTranslation();
  const { screenSize, linkAccountConnectedHistories = [] } = useSelector(
    (state) => ({
      screenSize: state.theme.screenSize,
      linkAccountConnectedHistories: state.exchange.linkAccountConnectedHistories
    }),
    isEqual
  );

  const handleSetSite = (site) => {
    setSite({ client_id: site.client_id, domain: site.domain, logo: site.logo, email: site.email });
    closeModal();
  };

  return (
    <ModalAccountStyle show={isOpen} centered>
      <ModalAccountBodyStyle>
        {!linkAccountConnectedHistories.length && <SuccessImg className="success-img" />}
        <ModalAccountTitle
          className={linkAccountConnectedHistories.length ? 'text-success mb-2' : 'mb-2'}
        >
          {linkAccountConnectedHistories.length
            ? t('Connected Account')
            : t('Have you created an exchange account yet?')}
        </ModalAccountTitle>
        <ModalAccountDescription className={linkAccountConnectedHistories.length ? 'mb-3' : ''}>
          {linkAccountConnectedHistories.length
            ? t('List of last connected accounts.')
            : t(
                'The exchange account is used for investing and receiving signals from Quickinvest.'
              )}
        </ModalAccountDescription>
        {!!linkAccountConnectedHistories.length &&
          linkAccountConnectedHistories.map((s, i) => (
            <ConnectedItem
              className={classNames({
                'connected-item d-flex align-items-center justify-content-between': true,
                'first-item': i === 0,
              })}
              key={i}
              onClick={() => handleSetSite(s)}
            >
              <div className="d-flex align-items-center">
                <ExchangeLogo
                  className={`d-flex align-items-center me-2 ${
                    s.logo ? 'hasImage justify-content-center' : ''
                  }`}
                >
                  {s.logo && <Image height="100%" src={!screenSize?.isMobile ? s.logo : s.logo.replace('exchange/', 'exchange/fav_').replace('.svg', '.ico')} />}
                </ExchangeLogo>
                <div>
                  <div className="site-name">{s.domain}</div>
                  <div className="user-email">{s.email}</div>
                </div>
              </div>

              <ArrowRight2 size={20} />
            </ConnectedItem>
          ))}
        <Box className="mt-3">
          {!!linkAccountConnectedHistories.length && (
            <>
              <Button
                variant="outline-secondary"
                onClick={closeModal}
                block="true"
                className="mb-3"
              >
                <Add className="me-1" size="20" />
                {t('Connect Another Account')}
              </Button>
              <TextExchangeAccount className="d-flex align-items-center justify-content-center flex-column flex-xl-row">
                {t('Don’t have exchange account?')}
                <button type="button" onClick={onRegisterNow} className="text-success ms-1">
                  {t('Click Here')}
                </button>
              </TextExchangeAccount>
            </>
          )}
          {!linkAccountConnectedHistories.length && (
            <>
              <Button onClick={onRegisterNow} variant="success" block="true">
                {t('Register for an exchange account now')}
              </Button>
              <Box mb="24px" />
              <BsButton onClick={closeModal} block="true" variant="text">
                {t('I already have an exchange account')}
              </BsButton>
            </>
          )}
        </Box>
      </ModalAccountBodyStyle>
    </ModalAccountStyle>
  );
}

export const ModalAccountStyle = styled(Modal)`
  .modal-dialog {
    max-width: calc(520px + 16px);
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }
  .modal-content {
    overflow: hidden;
    border-radius: 24px !important;
    border: none;
  }
`;
export const ModalAccountBodyStyle = styled(Modal.Body)`
  padding: 24px;
  background-color: ${colorBackgroundModal};
  text-align: center;
  .paste-code {
    margin-bottom: 24px;
  }
  .btn-text {
    border: none;
    outline: none;
    color: ${colorText};
  }
  .success-img {
    margin-bottom: 32px;
  }
  @media ${Devices.pc} {
    padding: 32px;
    .paste-code {
      margin-bottom: 40px;
    }
  }
`;
export const ModalAccountTitle = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  @media ${Devices.pc} {
    font-size: 32px;
  }
`;
export const ModalAccountDescription = styled.div`
  text-align: center;
  font-weight: 400;
  color: ${colorGreyScale600};
  font-size: 16px;
`;
export const ConnectedItem = styled(Box)`
  padding: 12px 0;
  border-top: 1px solid ${ConnectedItemBorderColor};
  cursor: pointer;
  &.first-item {
    border-top: none;
  }
  .site-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
  .user-email {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${colorGreyScale600};
    text-align: left;
  }
`;
export const ExchangeLogo = styled(Box)`
  width: 32px;
  height: 32px;
  background: ${ExchangeLogoBgColor};
  border: 1px solid ${ExchangeLogoBgColor};
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  &.hasImage {
    background: linear-gradient(180deg, #666c75 0%, #020d1d 100%);
    border: 1px solid ${ExchangeLogoBgColor};
  }
  img {
    pointer-events: none;
  }
  @media ${Devices.pc} {
    width: 160px;
    height: 32px;
    border-radius: 16px;
    padding: 4px 12px;
  }
`;
export const TextExchangeAccount = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${colorGreyScale600};
  button {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    font-weight: 600;
  }
`;
