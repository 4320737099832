import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { useTranslation } from 'react-i18next';
import './index.scss';
const Img = require('@/assets/image/confirm.png');

const PopupEditConfirmPlan = ({ show, onHide, showEditPlan }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="popup-edit-confirm-plan"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <img src={Img} alt="" className="mb-2" />
        <p className="mb-2 h3">
          {t('Are you sure you want to edit this plan?')}
        </p>
        <p className="text">
          {t(
            'Modifying the ongoing plan will affect its pause conditions, are you sure you want to edit'
          )}
          ?
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="light"
          onClick={() => {
            onHide();
            showEditPlan();
          }}
          className="text-success popup-share-cta"
        >
          {t('Continue to edit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopupEditConfirmPlan.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  showEditPlan: PropTypes.func.isRequired,
};

export default PopupEditConfirmPlan;
