import { WALLET_TYPE } from '@/config/const';
import {
  getTransactions,
  getWithdrawConfig,
} from '@/redux/refactor/wallet/actions';
import ModalTransfer from '@/shared/components/wallet/ModalTransfer';
import DrawerWallet from '@/shared/components/wallet/drawer-wallet';
import formatNumber from '@/shared/helpers/formatNumber';
import { Devices, colorGreyScale600, colorText } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { ArrowDown2, Wallet } from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const WalletInfo = withTranslation()(({ balance, accountType, t }) => (
  <WalletLink className="top-wallet">
    <Wallet className="icon" />
    <Box>
      <Amount>
        {formatNumber.formatCurrency(
          WALLET_TYPE.LIVE === accountType
            ? balance.availableBalance
            : balance.demoBalance
        )}
      </Amount>
      <Title>
        {t(WALLET_TYPE.LIVE === accountType ? 'Live Wallet' : 'Demo Wallet')}
      </Title>
    </Box>
    <ArrowDown2 className="icon-down" size={16} color="#989A9C" />
  </WalletLink>
));

const TopbarWallet = () => {
  const [openWallet, setOpenWallet] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const dispatch = useDispatch();
  const { balance, accountType } = useSelector(
    (state) => ({
      balance: state.wallet.balance,
      accountType: state.wallet.accountType,
    }),
    isEqual
  );
  const toggleDrawerWallet = () => {
    setOpenWallet(!openWallet);
  };
  const toggleModalTransfer = () => {
    setOpenTransfer(!openTransfer);
  };

  const loadData = async () => {
    dispatch(getTransactions());
    dispatch(getWithdrawConfig());
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div role="none" onClick={toggleDrawerWallet}>
        <WalletInfo balance={balance} accountType={accountType} />
      </div>
      <DrawerWallet open={openWallet} onClose={toggleDrawerWallet} />
      <ModalTransfer isOpen={openTransfer} closeModal={toggleModalTransfer} />
    </>
  );
};

const WalletLink = styled(Box)`
  color: ${colorText};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  .icon {
    width: 16px;
  }
  @media ${Devices.pc} {
    .icon {
      width: 24px;
    }
  }
`;

const Title = styled.small`
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: ${colorGreyScale600};
  @media ${Devices.pc} {
    font-size: 12px;
  }
`;

const Amount = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: ${colorText};
  display: flex;
  align-items: center;
  gap: 4px;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

export default TopbarWallet;
