import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { shallowEqual } from 'recompose';
import SecurityImage from '@/shared/img/security.svg';
import { setKickOut } from '@/redux/refactor/auth/actions';
import styled from 'styled-components';
import { colorGreyScale700, colorText, Devices } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CONTACT_EMAIL } from '@/config/const';
import theme from 'styled-theming';
import { Button } from './Button';
import { timeFormat } from '../helpers';

const ModalKickOut = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isKickOut, anotherDevice } = useSelector(state => state.user, shallowEqual);
  const onHide = () => {
    dispatch(setKickOut(false));
  };
  const contactUs = () => {
    onHide();
  };
  return (
    <ModalStyle show={isKickOut} centered>
      <ModalBodyStyle>
        <SecurityImage />
        <Title>{t('Sign in from another device')}</Title>
        <BrowserInfo dangerouslySetInnerHTML={{
          __html: t('<b>{{browser}}</b><br />at <b>{{time}}</b>', {
            browser: anotherDevice?.name?.replace('Browser on ', ''),
            time: timeFormat(anotherDevice?.time, 'MM/DD/YYYY HH:mm:ss'),
          }),
        }}
        />
        <SubTitle>
          {t('If you don’t recognize this activity, please contact us immediately')}
        </SubTitle>
          <a style={{ width: '100%' }} href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
            <Button onClick={contactUs} variant="success" block="true">{t('Contact')}</Button>
          </a>
        <ButtonConfirm onClick={onHide}>{t('Confirm, it is me')}</ButtonConfirm>
      </ModalBodyStyle>
    </ModalStyle>
    );
};

const ModalStyle = styled(Modal)`
z-index: 1301;
  .modal-dialog {
    max-width: calc(520px + 16px);
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 16px !important;
    @media ${Devices.pc} {
      border-radius: 32px !important;
    }
  }
`;

const ModalBodyStyle = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 22px;
  line-height: 125%;
  text-align: center;
  color: ${colorText};
  @media ${Devices.pc} {
    font-size: 32px;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #718096;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

const BrowserInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${colorText};
  padding: 9px 0px 16px 16px;
  background: ${theme('mode', {
    light: '#EEEFF2',
    dark: colorGreyScale700,
  })};
  border-radius: 12px;
  width: 100%;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

const ButtonConfirm = styled(Box)`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: ${colorText};
  margin-top: 8px;
  cursor: pointer;
`;


export default ModalKickOut;
