import React, { useState, useRef, useEffect } from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import ticketApi from '@/utils/api/ticketApi';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  updateShowPopupTicket,
  updateCurrentTicket,
  updateCurrentLayoutTicket,
} from '@/redux/actions/ticketActions';
import ShowIcon from './ShowIcon';
import Header from './Header';
import StatusNav from './StatusNav';
import List from './List';
import CreateForm from './CreateForm';
import Detail from './Detail';
import ListComment from './ListComment';
import Footer from './Footer';
import './index.scss';

const Ticket = () => {
  const dispatch = useDispatch();
  const isShowPopup = useSelector(state => state.ticket.isShowPopup, shallowEqual);
  const currentLayout = useSelector(state => state.ticket.currentLayout, shallowEqual);
  const profile = useSelector(state => state.user.profile, shallowEqual);
  const createForm = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [ticketListOpen, setTicketListOpen] = useState([]);
  const [ticketListClosed, setTicketListClosed] = useState([]);
  const [ticketDetail, setTicketDetail] = useState('');
  const [ticketListComment, setTicketListComment] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);
  const [zuid, setZuid] = useState('');

  const getList = (status) => {
    if (status) {
      setIsOpenStatus(status === 'open');
    }

    if (!zuid) {
      return;
    }

    setIsLoading(true);

    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    ticketApi.getList(zuid, config).then((data) => {
      setIsLoading(false);
      const { requests } = data.data;
      if (requests && requests.length) {
        setTicketList(requests.map((item) => {
          const ticket = { ...item };
          if (ticket.subject) {
            const [x, subject] = ticket.subject.split('-');
            ticket.subject = subject;
          }
          return ticket;
        }));
      }
    });
  };

  const getListComment = (item) => {
    setIsLoading(true);
    dispatch(updateCurrentTicket(item));

    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    ticketApi.getListComment(item.id, config).then((data) => {
      setIsLoading(false);
      const newListComment = [];
      const u = data.data.users.filter(user => user.name === profile.nn)[0];
      if (u) {
        data.data.comments.forEach((comment) => {
          const newComment = { ...comment };
          newComment.isEndUser = u.id === newComment.author_id;
          newListComment.push(newComment);
        });
        setTicketListComment(newListComment);
        dispatch(updateCurrentLayoutTicket('list_comment'));
      }
    });
  };

  const updateTicketDetail = (ticket) => {
    setTicketDetail(ticket);
    if (ticket) {
      dispatch(updateCurrentLayoutTicket('detail'));
    }
  };

  const checkValidForm = (errors) => {
    if (!errors.subject && !errors.email && !errors.description) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  };

  const createTicket = () => {
    if (!isUpLoading && isValidForm) {
      createForm.current.createTicket();
    }
  };

  useEffect(() => {
    if (profile && profile.e) {
      ticketApi.checkUserCreatedTiket().then((res) => {
        setZuid(res.data.d.zuid);
        dispatch(updateCurrentLayoutTicket('list'));
      });
    } else {
      setZuid('');
      setTicketListClosed([]);
      setTicketListOpen([]);
      setTicketListComment([]);
    }
  }, [profile]);

  useEffect(() => {
    if (currentLayout.isList && zuid) {
      getList('open');
    }
  }, [currentLayout.isList, zuid]);

  useEffect(() => {
    if (ticketList.length) {
      setTicketListOpen(ticketList.filter(item => ['new', 'open', 'pending', 'hold'].indexOf(item.status) > -1));
    
      setTicketListClosed(ticketList.filter(item => ['solved', 'closed'].indexOf(item.status) > -1));
    }
  }, [ticketList]);

  return (
    <>
      {profile && (
        <div className="ticket">
          <ShowIcon />

          <div className={`ticket-popup ${!isShowPopup ? 'd-none' : ''}`}>
            <Header />
            <div className="ticket-popup-body">
              {currentLayout.isList && (
                <>
                  <StatusNav
                    isOpenStatus={isOpenStatus}
                    getList={getList}
                    numberOpen={ticketListOpen.length}
                    numberClosed={ticketListClosed.length}
                  />
                  {isOpenStatus && <List ticketList={ticketListOpen} getListComment={getListComment} />}
                  {!isOpenStatus && <List ticketList={ticketListClosed} getListComment={getListComment} />}
                </>
              )}
              {currentLayout.isCreateForm && (
                <CreateForm
                  ref={createForm}
                  profile={profile}
                  updateLoading={() => setIsLoading(!isLoading)}
                  updateUploading={isloading => setIsUpLoading(isloading)}
                  updateTicketDetail={updateTicketDetail}
                  checkValidForm={checkValidForm}
                  updateCreatedTicket={id => setZuid(id)}
                />
              )}
              {currentLayout.isTicketDetail && <Detail item={ticketDetail} />}
              {currentLayout.isListComment && (
                <ListComment
                  ticketListComment={ticketListComment}
                />
              )}
            </div>

            <Footer
              isUpLoading={isUpLoading}
              isValidForm={isValidForm}
              createTicket={createTicket}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Ticket;
