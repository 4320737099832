import { SIGNAL_STRATEGY_TYPE, TYPE_KEY } from '@/shared/constants';
import botApi from '@/utils/api/botApi';
import commonApi from '@/utils/api/commonApi';
import strategyApi from '@/utils/api/strategyApi';
import { getHubConnection } from '@/utils/helpers';
import _ from 'lodash';
import { createAction } from 'redux-actions';

export const setSignalType = createAction('common/setSignalType');
export const setBots = createAction('common/setBots');
export const setTelegramChannels = createAction('common/setTelegramChannels');
export const setChannelLoading = createAction('common/setChannelLoading');
export const setStrategies = createAction('common/setStrategies');
export const setTelegramMessage = createAction('common/setTelegramMessage');
export const setMessageLoading = createAction('common/setMessageLoading');
export const setCurrentChannel = createAction('common/setCurrentChannel');
export const setIsCommunitySignal = createAction('common/setIsCommunitySignal');
export const setLastIdMessage = createAction('common/setLastIdMessage');
export const setSearchText = createAction('common/setSearchText');

export const getSignalType = () => (dispatch) => {
  commonApi.getMethods(TYPE_KEY.signalType).then((res) => {
    if (res?.data?.ok) {
      dispatch(setSignalType(res.data.d));
    }
  });
};

export const getBots = () => (dispatch) => {
  botApi.getBots({ page: 1, size: 100 }).then((res) => {
    if (res?.data?.ok) {
      const { c = [] } = res.data.d || {};
      const data = c.reduce(
        (acc, item) => [...acc, { ...item, searchTerm: _.deburr(item.name).toLowerCase() }],
        []
      );
      dispatch(setBots(data));
    }
  });
};

export const getTelegramChannels = (showStatsDetail) => async (dispatch, getState) => {
  dispatch(setChannelLoading(true));

  let isSupportCommunity = false;
  const userConfigs = getState().appConfig.userConfig;

  const featureFlags = userConfigs?.feature_flags;
  if (featureFlags && featureFlags.length) {
    const featureFlag = featureFlags.find(item => item.name === 'feature_flag_community_signal');
    if (featureFlag) {
      isSupportCommunity = !!featureFlag.enable;
    }
  }

  let resTelegramSignal
  try {
    resTelegramSignal = await botApi.getSignalSources(SIGNAL_STRATEGY_TYPE.telegram, showStatsDetail, false);
  } catch (error) {
    console.error('Error fetching telegram signal:', error);
  }

  let resCommunitySignal
  try {
    resCommunitySignal = isSupportCommunity ? await botApi.getSignalSources(SIGNAL_STRATEGY_TYPE.telegram, showStatsDetail, true) : {};
  } catch (error) {
    console.error('Error fetching community signal:', error);
  }

  let resTelegramSignalData = [], resCommunitySignalData = [];

  if (resTelegramSignal?.data?.ok) {
    resTelegramSignalData = resTelegramSignal.data.d;
  }

  if (resCommunitySignal?.data?.ok) {
    resCommunitySignalData = resCommunitySignal.data.d.c.map(signal => ({...signal, type: SIGNAL_STRATEGY_TYPE.community}));
  }

  dispatch(setTelegramChannels([...resTelegramSignalData, ...resCommunitySignalData]));

  dispatch(setChannelLoading(false));
};

export const getCommunityBotMessages = (communitySignalId) => (dispatch, getState) => {
  dispatch(setMessageLoading(true));

  const { telegramMessages = [], lastIdMessage } = getState().common;

  botApi
    .getCommunityBotMessages(communitySignalId, lastIdMessage)
    .then((res) => {
      if (res?.data?.ok) {
        // console.log(res.data.d)
        // const newMessages = res.data.d.map(item => item.message.replaceAll('\n', '<br />'))
        const newMessages = res.data.d
        // console.log('newMessages', newMessages)
        dispatch(setTelegramMessage([...newMessages, ...telegramMessages]));
        dispatch(setLastIdMessage(res.data?.d[0]?.id));
      }
    })
    .finally(() => dispatch(setMessageLoading(false)));
};

export const getStrategies = () => (dispatch) => {
  strategyApi.getStrategies({ page: 1, size: 100 }).then((res) => {
    if (res?.data?.ok) {
      const { c = [] } = res.data.d || {};
      const data = c.reduce(
        (acc, item) => [...acc, { ...item, searchTerm: _.deburr(item.budget_name).toLowerCase() }],
        []
      );
      dispatch(setStrategies(data));
    }
  });
};

export const subscribeTelegramChannel = (channelId, isCommunitySignal) => async (dispatch) => {
  console.log('---------------------------------------------------');
  console.group('subscribeTelegramChannel: ' + channelId);
  try {
    await dispatch(unSubscribeTelegramChannel());
    await dispatch(setCurrentChannel(channelId));
    // dispatch(setTelegramMessage([]));
    const connection = await getHubConnection();
    const socketChannel = isCommunitySignal ? 'JoinCommunitySignalChannel' : 'JoinToTelegramChannel';
    const socketChannelParam = isCommunitySignal ? 'community_signal_'.concat(channelId) : 'telegram_'.concat(channelId);
    const resp = await connection.invoke(socketChannel, socketChannelParam);
    console.log('subscribeTelegramChannel ', socketChannelParam);
    const mess = JSON.parse(resp) || {};
    console.log(channelId, mess);
    mess[channelId]?.map((item) => {
      console.log('signal message: ', item)
      dispatch(pushTelegramMessage({ [channelId]: item }));
    });
  } catch (e) {
    console.log('subscribeTelegramChannel -> error: ', e);
  }
  console.groupEnd();
};

export const unSubscribeTelegramChannel = () => async (dispatch, getState) => {
  try {
    const { currentChannel } = getState().common;
    dispatch(setTelegramMessage([]));
    dispatch(setCurrentChannel(null));
    const connection = await getHubConnection();
    connection.invoke('LeaveTelegramChannel', 'telegram_'.concat(currentChannel));
    connection.invoke('LeaveCommunitySignalChannel', 'community_signal_'.concat(currentChannel));
    console.log('unSubscribeTelegramChannel: ', 'telegram_'.concat(currentChannel));
  } catch (e) {
    console.log('unSubscribeTelegramChannel -> error: '.e);
  }
};

export const pushTelegramMessage =
  (msg = {}) =>
  (dispatch, getState) => {
    const { telegramMessages = [], currentChannel, isCommunitySignal } = getState().common;
    console.log('pushTelegramMessage: ', currentChannel, msg);
    if (msg[currentChannel]) {
      console.log('telegramMessages', telegramMessages)
      console.log('msg[currentChannel]', msg[currentChannel])

      const messages = isCommunitySignal ? [...telegramMessages, msg[currentChannel].replaceAll('\n', '<br />')] : [
        ...telegramMessages.slice(-2),
        msg[currentChannel].replaceAll('\n', '<br />'),
      ];

      dispatch(
        setTelegramMessage(messages)
      );
    }
  };
