import { MOBILE_FOOTER_HEIGHT } from '@/config/const';
import {
  borderDefaultStyle,
  Devices,
  sidebarBackground,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import {
  Briefcase,
  Home2,
  MedalStar,
  More,
  People,
  PresentionChart,
  Share,
  Shuffle,
  Trade,
} from 'iconsax-react';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BottomBarLink from './BottomBarLink';
import SubMenu from './SubMenu';
import { selectCurrentTotalPlan } from '@/redux/selectors/dashboardSelector';

const menu = {
  more: [
    {
      key: 'manage_follower',
      route: '/manage-follower',
      icon: People,
      title: 'Manage Follower',
      description: 'Monitor your investing follower statistics',
    },
    {
      key: 'more_manual',
      route: '/manual-trade',
      icon: Shuffle,
      title: 'Manual Trade',
      description: 'Place orders without needing signals.',
    },
    {
      key: 'referral',
      route: '/referral',
      icon: Share,
      title: 'Referral',
      description: 'Manage your Quickinvest referrals',
    },
    {
      key: 'more_vip',
      route: '/affiliation',
      icon: MedalStar,
      title: 'VIP Member',
      description: 'Manage your statistic about Exchange referrals',
    },
  ],
};

const BottomBar = () => {
  const [open, setOpen] = useState(false);
  const totalPlan = useSelector(selectCurrentTotalPlan, isEqual);
  const history = useHistory();
  const toggle = (evt) => {
    evt.stopPropagation();
    setOpen(!open);
  };
  const hideModal = () => {
    if (open) {
      setOpen(false);
    }
  };
  const handleItemClick = (item) => {
    history.push(item.route);
  };
  return (
    <BottomBarContainer
      highest={open}
      onClick={hideModal}
      className="bottom-bar"
    >
      <BottomBarLink icon={Home2} route="/" exact />
      <BottomBarLink
        icon={Briefcase}
        route="/portfolios"
        numberBade={totalPlan}
        className="hot"
      />
      <BottomBarLink icon={Trade} route="/budget-strategies" />
      <BottomBarLink icon={PresentionChart} route="/signal-strategies" />
      <Box onClick={toggle}>
        <More variant={open ? 'Bulk' : 'Linear'} size={24} color="#718096" />
      </Box>
      <SubMenu
        values={menu.more}
        show={open}
        onHide={hideModal}
        onItemClick={handleItemClick}
      />
    </BottomBarContainer>
  );
};

BottomBar.propTypes = {};

const BottomBarContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${MOBILE_FOOTER_HEIGHT}px;
  background: ${sidebarBackground};
  border-top: ${borderDefaultStyle};
  z-index: ${(props) => (props.highest ? 1303 : 1001)};
  padding: 0 16px;
  @media ${Devices.pc} {
    display: none;
  }
`;
export default BottomBar;
