import moment from 'moment';
import QRCode from 'qrcode';
import { getApiUrl } from '@/utils/api/base/axios';
import { timeFormat, stringAvatar } from '.';
import { STORAGE_KEY } from '@/config/const';
import formatNumber from './formatNumber';

export const dpi = window.devicePixelRatio || 2;

export const sizeCanvas = {
  mobile: {
    width: 320,
    height: 390,
  },
  pc: {
    width: 730,
    height: 507,
  },
};

export const sizeAvatar = {
  mobile: {
    width: 28,
    height: 28,
  },
  pc: {
    width: 44,
    height: 44,
  },
};

export const fixDpi = (isMobile, size, id) => {
  const elCanvas = document.getElementById(id);
  if (elCanvas) {
    elCanvas.setAttribute('width', isMobile ? size.mobile.width * dpi : size.pc.width * dpi);
    elCanvas.setAttribute('height', isMobile ? size.mobile.height * dpi : size.pc.height * dpi);
  }
};

export const drawRectangle = (
  positionLeft,
  positionTop,
  width,
  height,
  radius,
  backgroundColor,
  borderWidth,
  borderColor,
  context,
) => {
  if (!context) {
    return;
  }

  context.beginPath();
  context.fillStyle = backgroundColor;
  const x = positionLeft * dpi;
  const y = positionTop * dpi;
  if (borderWidth) {
    context.lineWidth = borderWidth;
    context.strokeStyle = borderColor;
  }
  context.moveTo(x + radius * dpi, y);
  context.arcTo(x + width * dpi, y, x + width * dpi, y + height * dpi, radius * dpi);
  context.arcTo(x + width * dpi, y + height * dpi, x, y + height * dpi, radius * dpi);
  context.arcTo(x, y + height * dpi, x, y, radius * dpi);
  context.arcTo(x, y, x + width * dpi, y, radius * dpi);
  context.closePath();
  if (borderWidth) {
    context.stroke();
  }
  context.fill();
};

export const drawText = (fontSize, fontWeight, textColor, text, textAlign, positionLeft, positionTop, context) => {
  if (!context) {
    return;
  }
  
  context.font = `${fontWeight} ${fontSize.replace('px', '') * dpi}px Manrope`;
  context.textAlign = textAlign;
  context.fillStyle = textColor;
  context.fillText(text, positionLeft * dpi, positionTop * dpi);
};

export const drawImage = (source, positionLeft, positionTop, width, height, radius, context, myCallBack) => {
  if (!context) {
    return;
  }
  
  const newImage = new Image();
  let photo = source;
  if (photo.includes('users/me/photo')) {
    photo = `${photo}&time=${moment().unix()}`;
  }
  newImage.src = photo;
  newImage.crossOrigin = 'Anonymous';
  newImage.onload = () => {
    if (radius) {
      context.save();
      context.beginPath();
      context.arc((positionLeft + radius) * dpi, (positionTop + radius) * dpi, radius * dpi, 0, Math.PI * 2, false);
      context.closePath();
      context.clip();
    }
    context.drawImage(newImage, positionLeft * dpi, positionTop * dpi, width * dpi, height * dpi);
    if (radius) {
      context.restore();
    }
    if (myCallBack) {
      myCallBack();
    }
  };
};

export const drawCircle = (positionLeft, positionTop, borderColor, backgroundColor, width, context) => {
  if (!context) {
    return;
  }
  
  context.beginPath();
  context.arc(positionLeft * dpi, positionTop * dpi, (width * dpi) / 2, 0, 2 * Math.PI, false);
  context.fillStyle = borderColor;
  context.fill();
  context.lineWidth = 2 * dpi; // border line
  context.strokeStyle = backgroundColor;
  context.stroke();
};

export const clearCanvas = (isMobile, size, context) => {
  if (!context) {
    return;
  }
  
  if (isMobile) {
    context.clearRect(0, 0, size.mobile.width, size.mobile.height);

  } else {
    context.clearRect(0, 0, size.pc.width, size.pc.height);
  }
};

export const drawQrcode = (positionLeft, positionTop, width, height, context, content) => {
  const el = document.getElementById('canvas-qrcode');
  if (el) {
    QRCode.toCanvas(
      el,
      content,
      { margin: 2 },
      (error) => {
        if (error) {
          console.error(error);
        }
        if (context) {
          context.drawImage(
            el,
            positionLeft * dpi,
            positionTop * dpi,
            width * dpi,
            height * dpi
          );
        }
      }
    );
  }
};

export const drawSharePlanCanvas = (t, context, profile, screenSize, currentPlan, profit, percentProfit, titleCanvas, indexUserLeaderboard, timePlan) => {
  clearCanvas(screenSize.isMobile, sizeCanvas, context);
  fixDpi(screenSize.isMobile, sizeCanvas, 'main-canvas');

  // draw background color
  drawRectangle(
    0,
    0,
    screenSize.isPc ? sizeCanvas.pc.width : sizeCanvas.mobile.width,
    screenSize.isPc ? sizeCanvas.pc.height : sizeCanvas.mobile.height,
    screenSize.isPc ? 12 : 20,
    '#111827',
    0,
    '',
    context
  );

  // draw background image
  drawImage(
    `${window.location.origin}/img/share_plan/${screenSize.isPc ? '01' : '01_mobile'}.png`,
    0,
    0,
    screenSize.isPc ? sizeCanvas.pc.width : sizeCanvas.mobile.width,
    screenSize.isPc ? 408 : 336,
    0,
    context,
    () => {
      // draw background wrapper qrcode
      drawRectangle(
        screenSize.isPc ? 48 : 20,
        screenSize.isPc ? 304 : 245,
        72,
        72,
        7,
        '#fff',
        0,
        '',
        context
      );
      drawQrcode(
        screenSize.isPc ? 50 : 22,
        screenSize.isPc ? 306 : 247,
        68,
        68,
        context,
        `${window.location.origin}/portfolios?copyCode=${currentPlan.copy_code}&r=${profile.rc}`
      );
      // draw rocket
      drawImage(
        `${window.location.origin}/img/share_plan/${screenSize.isPc ? '02' : '02_mobile'}.png`,
        screenSize.isPc ? 398 : 130,
        screenSize.isPc ? 80 : 80,
        screenSize.isPc ? 332 : 187,
        screenSize.isPc ? 341 : 192,
        0,
        context
      );
      drawText(
        screenSize.isPc ? '18px' : '12px',
        '500',
        '#FAFAFA',
        titleCanvas,
        'left',
        screenSize.isPc ? 50 : 20,
        screenSize.isPc ? 76 : 36,
        context
      );
      drawText(
        screenSize.isPc ? '64px' : '44px',
        '800',
        profit >= 0 ? '#0CAF60' : '#FD4F4F',
        `${profit >= 0 ? '+' : '-'}${percentProfit}%`,
        'left',
        screenSize.isPc ? 50 : 20,
        screenSize.isPc ? 150 : 90,
        context
      );
      drawText(
        screenSize.isPc ? '16px' : '12px',
        '500',
        '#A0AEC0',
        timePlan,
        'left',
        screenSize.isPc ? 50 : 20,
        screenSize.isPc ? 180 : 115,
        context
      );

      drawText(
        '14px',
        '700',
        '#A0AEC0',
        t('Plan Code'),
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 318 : 260,
        context
      );
      drawText(
        '24px',
        '800',
        '#FAFAFA',
        currentPlan.copy_code,
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 350 : 290,
        context
      );
      drawText(
        '12px',
        '700',
        '#FAFAFA',
        t('Scan to copy plan'),
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 370 : 310,
        context
      );
    }
  );

  drawImage(
    `${window.location.origin}/img/logo/logo_dark.png`,
    screenSize.isPc ? 500 : 200,
    screenSize.isPc ? 437 : 350,
    screenSize.isPc ? 176 : 102,
    screenSize.isPc ? 36 : 21,
    0,
    context
  );
  drawText(
    screenSize.isPc ? '18px' : '12px',
    '700',
    '#fafafa',
    profile?.nn,
    'left',
    screenSize.isPc ? 100 : 46,
    screenSize.isPc ? 455 : 360,
    context
  );
  drawText(
    screenSize.isPc ? '12px' : '8px',
    '700',
    '#A0AEC0',
    timeFormat(moment().locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE))),
    'left',
    screenSize.isPc ? 100 : 46,
    screenSize.isPc ? 475 : 373,
    context
  );
  drawCircle(
    screenSize.isPc ? 70 : 27,
    screenSize.isPc ? 458 : 362,
    '#0CAF60',
    '#FFDE65',
    screenSize.isPc ? sizeAvatar.pc.width - 2 : sizeAvatar.mobile.width - 2,
    context
  );

  if (profile.photo) {
    const avatarWidth = screenSize.isPc ? sizeAvatar.pc.width - 4 : sizeAvatar.mobile.width - 2;
    drawImage(
      getApiUrl(profile?.photo),
      screenSize.isPc ? 50 : 14,
      screenSize.isPc ? 438 : 349,
      avatarWidth,
      avatarWidth,
      avatarWidth / 2,
      context
    );
  } else {
    drawText(
      screenSize.isPc ? '12px' : '10px',
      '800',
      '#fafafa',
      stringAvatar(profile?.nn),
      'center',
      screenSize.isPc ? 70 : 27,
      screenSize.isPc ? 462 : 365,
      context
    );
  }

  // draw leaderboard
  setTimeout(() => {
    if (indexUserLeaderboard >= 0 && indexUserLeaderboard <= 2) {
      if (indexUserLeaderboard === 0) {
        drawImage(
          `${window.location.origin}/img/icons/image 53.png`,
          screenSize.isPc ? 59 : 19,
          screenSize.isPc ? 419 : 336,
          screenSize.isPc ? 22 : 16,
          screenSize.isPc ? 22 : 16,
          0,
          context
        );
      }

      drawImage(
        `${window.location.origin}/img/icons/rank-${indexUserLeaderboard + 1}.png`,
        screenSize.isPc ? 59 : 19,
        screenSize.isPc ? 468 : 368,
        screenSize.isPc ? 22 : 16,
        screenSize.isPc ? 24 : 17.5,
        0,
        context
      );
    }

    if (indexUserLeaderboard > 2) {
      drawRectangle(
        screenSize.isPc ? 52 : 15,
        screenSize.isPc ? 470 : 370,
        screenSize.isPc ? 36 : 24,
        screenSize.isPc ? 14 : 12,
        screenSize.isPc ? 6 : 6,
        '#111827',
        1,
        '#FFDE65',
        context
      );

      drawText(
        screenSize.isPc ? '7px' : '6px',
        '700',
        '#fafafa',
        `Top ${indexUserLeaderboard + 1}`,
        'center',
        screenSize.isPc ? 70 : 27,
        screenSize.isPc ? 480 : 378,
        context
      );
    }
  }, 1500);
};

export const drawShareStatisticCanvas = (t, context, profile, screenSize, profit, titleCanvas, indexUserLeaderboard) => {
  clearCanvas(screenSize.isMobile, sizeCanvas, context);
  fixDpi(screenSize.isMobile, sizeCanvas, 'main-canvas');

  // draw background color
  drawRectangle(
    0,
    0,
    screenSize.isPc ? sizeCanvas.pc.width : sizeCanvas.mobile.width,
    screenSize.isPc ? sizeCanvas.pc.height : sizeCanvas.mobile.height,
    screenSize.isPc ? 12 : 20,
    '#111827',
    0,
    '',
    context
  );

  // draw background image
  drawImage(
    `${window.location.origin}/img/share_plan/${screenSize.isPc ? '01' : '01_mobile'}.png`,
    0,
    0,
    screenSize.isPc ? sizeCanvas.pc.width : sizeCanvas.mobile.width,
    screenSize.isPc ? 408 : 336,
    0,
    context,
    () => {
      // draw background wrapper qrcode
      drawRectangle(
        screenSize.isPc ? 48 : 20,
        screenSize.isPc ? 304 : 245,
        72,
        72,
        7,
        '#fff',
        0,
        '',
        context
      );
      drawQrcode(
        screenSize.isPc ? 50 : 22,
        screenSize.isPc ? 306 : 247,
        68,
        68,
        context,
        `${window.location.origin}/register?r=${profile.rc}`
      );
      // draw rocket
      drawImage(
        `${window.location.origin}/img/share_plan/${screenSize.isPc ? '02' : '02_mobile'}.png`,
        screenSize.isPc ? 398 : 130,
        screenSize.isPc ? 80 : 80,
        screenSize.isPc ? 332 : 187,
        screenSize.isPc ? 341 : 192,
        0,
        context
      );
      drawText(
        screenSize.isPc ? '18px' : '12px',
        '500',
        '#FAFAFA',
        t(titleCanvas),
        'left',
        screenSize.isPc ? 50 : 20,
        screenSize.isPc ? 76 : 36,
        context
      );
      drawText(
        screenSize.isPc ? '64px' : '44px',
        '800',
        profit >= 0 ? '#0CAF60' : '#FD4F4F',
        `${profit >= 0 ? '+' : '-'}${formatNumber.formatCurrency(profit)}`,
        'left',
        screenSize.isPc ? 50 : 20,
        screenSize.isPc ? 150 : 90,
        context
      );

      drawText(
        '14px',
        '700',
        '#A0AEC0',
        t('Referal Code'),
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 318 : 260,
        context
      );
      drawText(
        '24px',
        '800',
        '#FAFAFA',
        profile.rc,
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 350 : 290,
        context
      );
      drawText(
        '12px',
        '700',
        '#FAFAFA',
        t('Scan to copy referal code'),
        'left',
        screenSize.isPc ? 137 : 100,
        screenSize.isPc ? 370 : 310,
        context
      );
    }
  );

  drawImage(
    `${window.location.origin}/img/logo/logo_dark.png`,
    screenSize.isPc ? 500 : 200,
    screenSize.isPc ? 437 : 350,
    screenSize.isPc ? 176 : 102,
    screenSize.isPc ? 36 : 21,
    0,
    context
  );
  drawText(
    screenSize.isPc ? '18px' : '12px',
    '700',
    '#fafafa',
    profile?.nn,
    'left',
    screenSize.isPc ? 100 : 46,
    screenSize.isPc ? 455 : 360,
    context
  );
  drawText(
    screenSize.isPc ? '12px' : '8px',
    '700',
    '#A0AEC0',
    timeFormat(moment().locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE))),
    'left',
    screenSize.isPc ? 100 : 46,
    screenSize.isPc ? 475 : 373,
    context
  );
  drawCircle(
    screenSize.isPc ? 70 : 27,
    screenSize.isPc ? 458 : 362,
    '#0CAF60',
    '#FFDE65',
    screenSize.isPc ? sizeAvatar.pc.width - 2 : sizeAvatar.mobile.width - 2,
    context
  );

  if (profile.photo) {
    const avatarWidth = screenSize.isPc ? sizeAvatar.pc.width - 4 : sizeAvatar.mobile.width - 2;
    drawImage(
      getApiUrl(profile?.photo),
      screenSize.isPc ? 50 : 14,
      screenSize.isPc ? 438 : 349,
      avatarWidth,
      avatarWidth,
      avatarWidth / 2,
      context
    );
  } else {
    drawText(
      screenSize.isPc ? '12px' : '10px',
      '800',
      '#fafafa',
      stringAvatar(profile?.nn),
      'center',
      screenSize.isPc ? 70 : 27,
      screenSize.isPc ? 462 : 365,
      context
    );
  }

  // draw leaderboard
  setTimeout(() => {
    if (indexUserLeaderboard >= 0 && indexUserLeaderboard <= 2) {
      if (indexUserLeaderboard === 0) {
        drawImage(
          `${window.location.origin}/img/icons/image 53.png`,
          screenSize.isPc ? 59 : 19,
          screenSize.isPc ? 419 : 336,
          screenSize.isPc ? 22 : 16,
          screenSize.isPc ? 22 : 16,
          0,
          context
        );
      }

      drawImage(
        `${window.location.origin}/img/icons/rank-${indexUserLeaderboard + 1}.png`,
        screenSize.isPc ? 59 : 19,
        screenSize.isPc ? 468 : 368,
        screenSize.isPc ? 22 : 16,
        screenSize.isPc ? 24 : 17.5,
        0,
        context
      );
    }

    if (indexUserLeaderboard > 2) {
      drawRectangle(
        screenSize.isPc ? 52 : 15,
        screenSize.isPc ? 470 : 370,
        screenSize.isPc ? 36 : 24,
        screenSize.isPc ? 14 : 12,
        screenSize.isPc ? 6 : 6,
        '#111827',
        1,
        '#FFDE65',
        context
      );

      drawText(
        screenSize.isPc ? '7px' : '6px',
        '700',
        '#fafafa',
        `Top ${indexUserLeaderboard + 1}`,
        'center',
        screenSize.isPc ? 70 : 27,
        screenSize.isPc ? 480 : 378,
        context
      );
    }
  }, 1500);
};
