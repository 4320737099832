import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'recompose';
import Maintenance from '@/shared/img/maintenance.svg';
import styled from 'styled-components';
import { colorGreyScale700, colorText, Devices } from '@/utils/palette';
import { useTranslation } from 'react-i18next';
import { timeDurationFormat } from '@/shared/helpers';
import theme from 'styled-theming';
import { Button } from '../Button';

const ModalMaintenance = () => {
  const { t } = useTranslation();
  const { siteConfig = {}, isKickOut } = useSelector(
    state => state.appConfig,
    shallowEqual,
  );
  const onHide = () => {
    window.location.reload();
  };

  return (
    <ModalStyle show={isKickOut} centered>
      <ModalBodyStyle>
        <Maintenance />
        <Title>{t('ModalMaintenance.title')}</Title>
        <BrowserInfo
          dangerouslySetInnerHTML={{
            __html: t('ModalMaintenance.info', {
              est_time: timeDurationFormat(t)(siteConfig?.mtn_est),
            }),
          }}
        />
        <SubTitle>{t('ModalMaintenance.description')}</SubTitle>
        <Button variant="success" onClick={onHide} block="true">
          {t('ModalMaintenance.confirm')}
        </Button>
      </ModalBodyStyle>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  z-index: 1301;
  .modal-dialog {
    max-width: calc(520px + 16px);
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 16px !important;
    @media ${Devices.pc} {
      border-radius: 32px !important;
    }
  }
`;

const ModalBodyStyle = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  > svg {
    max-width: 250px;
    height: auto;
  }
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 22px;
  line-height: 125%;
  text-align: center;
  color: ${colorText};
  @media ${Devices.pc} {
    font-size: 32px;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #718096;
  max-width: 410px;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

const BrowserInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${colorText};
  padding: 9px 0px 16px 16px;
  background: ${theme('mode', {
    light: '#EEEFF2',
    dark: colorGreyScale700,
  })};
  border-radius: 12px;
  width: 100%;
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

export default ModalMaintenance;
