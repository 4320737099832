/* eslint-disable react/prop-types */
import { BUDGET_STEP_OPTIONS } from '@/shared/constants';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { CollapseStyle, FormCheckbox } from './FormElements';
import { Grid } from '@/shared/components/form/drawer/Elements';
import SettingItem from './SettingItem';

// Begin WaitSignal
export default withTranslation()(({ show, mutators, t, value }) => {
  if (!show) return null;
  return (
    <>
      <SettingItem
        name="step0_option"
        title={t('FormCreatePlan_advanced_ignoreBudget_label')}
        desc={t('FormCreatePlan_advanced_ignoreBudget_desc')}
      />
      <CollapseStyle in={value} unmountOnExit>
        <Grid.Row cols={3} className="w-100 mt-2">
          <Grid.Cell className="border-none ps-0">
            <div className="d-flex align-items-center custom-radio-button">
              <Field
                id="continueAsWin"
                name="ignore_budget_radio"
                label={t('Continue as win')}
                component={FormCheckbox}
                type="radio"
                value={BUDGET_STEP_OPTIONS.continueAsWin}
              />
            </div>
          </Grid.Cell>
          <Grid.Cell className="border-none ps-0">
            <div className="d-flex align-items-center custom-radio-button">
              <Field
                id="continueAsLose"
                name="ignore_budget_radio"
                label={t('Continue as lose')}
                component={FormCheckbox}
                type="radio"
                value={BUDGET_STEP_OPTIONS.continueAsLose}
              />
            </div>
          </Grid.Cell>
          <Grid.Cell className="border-none ps-0">
            <div className="d-flex align-items-center custom-radio-button">
              <Field
                id="continueAsResult"
                name="ignore_budget_radio"
                label={t('Continue as result')}
                component={FormCheckbox}
                type="radio"
                value={BUDGET_STEP_OPTIONS.continueAsResult}
              />
            </div>
          </Grid.Cell>
        </Grid.Row>
      </CollapseStyle>
    </>
  );
});
// End WaitSignal
