import { STORAGE_KEY, WALLET_TYPE } from '@/config/const';
import { handleActions } from 'redux-actions';
import {
  getBalanceFailed,
  getBalanceRequest,
  getBalanceSuccess,
  getTransactionFailed,
  getTransactionRequest,
  getTransactionSuccess,
  getUsdtAddressRequest,
  getUsdtAddressSuccess,
  getWithdrawConfigFailed,
  getWithdrawConfigRequest,
  getWithdrawConfigSuccess,
  setAccountType,
  setBalance,
  setDemoReloading,
  setTransferring,
  setWalletRefreshing,
} from './actions';

const defaultState = {
  usdtAddr: '',
  usdtAddrFetching: false,
  balance: {
    availableBalance: 0,
    demoBalance: 0,
    usdtAvailableBalance: 0,
    isFetching: false,
  },
  transactions: {
    isFetching: false,
    data: [],
  },
  withdrawConfig: {
    isFetching: false,
  },
  refreshing: false,
  demoReloading: false,
  accountType:
    localStorage.getItem(STORAGE_KEY.WALLET_TYPE) || WALLET_TYPE.LIVE,
  transferring: false,
};

export default handleActions(
  {
    [getBalanceRequest]: (state) => ({
      ...state,
      balance: {
        ...state.balance,
        isFetching: true,
      },
    }),
    [getBalanceSuccess]: (state, { payload }) => ({
      ...state,
      balance: {
        ...state.balance,
        ...payload,
        isFetching: false,
      },
    }),
    [getBalanceFailed]: (state) => ({
      ...state,
      balance: {
        ...state.balance,
        isFetching: false,
      },
    }),

    [getTransactionRequest]: (state) => ({
      ...state,
      transactions: {
        ...state.transactions,
        isFetching: true,
      },
    }),
    [getTransactionSuccess]: (state, { payload }) => ({
      ...state,
      transactions: {
        data: payload,
        isFetching: false,
      },
    }),
    [getTransactionFailed]: (state) => ({
      ...state,
      transactions: {
        ...state.transactions,
        isFetching: false,
      },
    }),

    [getUsdtAddressRequest]: (state) => ({
      ...state,
      usdtAddrFetching: true,
    }),
    [getUsdtAddressSuccess]: (state, { payload }) => ({
      ...state,
      usdtAddrFetching: false,
      usdtAddr: payload,
    }),
    [getUsdtAddressRequest]: (state) => ({
      ...state,
      usdtAddrFetching: false,
    }),

    [getUsdtAddressRequest]: (state) => ({
      ...state,
      usdtAddrFetching: true,
    }),
    [getUsdtAddressSuccess]: (state, { payload }) => ({
      ...state,
      usdtAddrFetching: false,
      usdtAddr: payload,
    }),
    [getUsdtAddressRequest]: (state) => ({
      ...state,
      usdtAddrFetching: false,
    }),

    [getWithdrawConfigRequest]: (state) => ({
      ...state,
      withdrawConfig: {
        isFetching: true,
      },
    }),
    [getWithdrawConfigSuccess]: (state, { payload }) => ({
      ...state,
      withdrawConfig: {
        isFetching: false,
        ...payload,
      },
    }),
    [getWithdrawConfigFailed]: (state) => ({
      ...state,
      withdrawConfig: {
        isFetching: false,
      },
    }),
    [setWalletRefreshing]: (state, { payload }) => ({
      ...state,
      refreshing: payload,
    }),
    [setDemoReloading]: (state, { payload }) => ({
      ...state,
      demoReloading: payload,
    }),
    [setAccountType]: (state, { payload }) => ({
      ...state,
      accountType: payload,
    }),
    [setTransferring]: (state, { payload }) => ({
      ...state,
      transferring: payload,
    }),
    [setBalance]: (state, { payload: { accountType, balance } }) => {
      const newBalance = { ...state.balance };
      if (accountType === WALLET_TYPE.LIVE)
        newBalance.availableBalance = balance;
      else newBalance.demoBalance = balance;

      return { ...state, balance: newBalance };
    },
  },
  defaultState
);
