import { Card } from '@/shared/components/Card';
import Nodata from '@/shared/components/Nodata';
import PaginateCustom from '@/shared/components/PaginateCustom';
import SkeletonLoading from '@/shared/components/SkeletonLoading';
import {
  DrawerStyle,
  Form,
  drawerProps,
} from '@/shared/components/form/drawer/Elements';
import { PAGINATION_DEFAULT } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CardItem from './CardItem';

export default function ModalHistory({ show, onHide, changeBacktestId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [pageConfig, setPageConfig] = useState({ ...PAGINATION_DEFAULT });
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [backtestHistory, setBacktestHistory] = useState([]);
  const isPc = useMediaQuery(Devices.pc);
  const drawerProp = isPc ? drawerProps.right : drawerProps.mobile;

  const getBacktestHistory = (config) => {
    setLoading(true);
    planApi.getBacktestHistory(config.page, config.size).then((res) => {
      setLoading(false);
      if (res?.data?.ok) {
        setBacktestHistory(res.data.d.c);
        setTotalRecords(res.data.d.t);
      }
    });
  };

  const handleChangePage = (event) => {
    if (!loading) {
      const newConfig = { ...pageConfig };
      newConfig.page = event.selected + 1;
      setPageConfig(newConfig);
      getBacktestHistory(newConfig);
    }
  };

  useEffect(() => {
    setPageCount(Math.ceil(totalRecords / pageConfig.size));
  }, [totalRecords]);

  useEffect(() => {
    if (show) {
      const newConfig = { ...pageConfig };
      newConfig.page = 1;
      setPageConfig(newConfig);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      getBacktestHistory(pageConfig);
    }
  }, [pageConfig.page, show]);

  return (
    <>
      <DrawerStyle {...drawerProp} open={show} onClose={onHide}>
        <Form.Header onClose={onHide} />
        {loading && <SkeletonLoading />}
        {!loading && (
          <Form.Body>
            <Form.Headline>{t('Test Plan History')}</Form.Headline>
            {!!backtestHistory.length && (
              <ListWrap>
                {backtestHistory.map((order) => (
                  <CardItem
                    key={order.id}
                    item={order}
                    changeBacktestId={changeBacktestId}
                  />
                ))}
              </ListWrap>
            )}
            {!backtestHistory.length && <Nodata />}
          </Form.Body>
        )}
        {!!backtestHistory.length && (
          <Form.Footer>
            <PaginateCustom
              page={pageConfig.page - 1}
              hasSizeOption={false}
              handleChangePage={handleChangePage}
              pageCount={pageCount}
            />
          </Form.Footer>
        )}
      </DrawerStyle>
    </>
  );
}

// STYLING
const ListWrap = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% - 10px), 1fr));
  width: 100%;
  overflow: auto;
  @media ${Devices.pc} {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));
    grid-gap: 20px;
  }
`;

const CardBody = styled(Card.Body)`
  height: 100%;
  padding: 0 16px;
`;

const CardFooter = styled(Card.Footer)`
  padding: 0 !important;
`;
ModalHistory.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  changeBacktestId: PropTypes.func.isRequired,
};
