/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { selectBots, selectSignalType, selectStrategies } from '@/redux/refactor/common/selector';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  colorGreyScale100,
  colorGreyScale200,
  colorGreyScale500,
  colorGreyScale700,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import _, { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';
import { convertMaskToNum } from '../validator';

const formatAmount = (value) => {
  const num = convertMaskToNum(value);
  return formatNumber.formatCurrency(num);
};

const FormReview = withTranslation()(({ t }) => {
  const { values = {} } = useFormState();
  const { telegram_chanels = [] } = values;
  const strategies = useSelector(selectStrategies, isEqual);
  const signalTypes = useSelector(selectSignalType, isEqual);
  const bots = useSelector(selectBots, isEqual);
  const accountType = useSelector((state) => state.wallet.accountType, isEqual);
  const strategy = strategies.find(
    (item) => item.id.toString() === values?.budget_strategy_id?.toString()
  );
  const signalType = useMemo(
    () =>
      values.signal_type === SIGNAL_STRATEGY_TYPE.manual
        ? t('Manual Trade')
        : signalTypes.find((item) => item.code === values.signal_type)?.name,
    [values.signal_type, signalTypes]
  );
  const bot = useMemo(
    () => bots.find((item) => item.id.toString() === values.bot_ai_id?.toString()),
    [bots, values.bot_ai_id]
  );
  const telegram = useMemo(() => {
    if (_.isArray(telegram_chanels))
      return telegram_chanels?.reduce((acc, item) => [...acc, item.label], []);
    return [];
  }, [telegram_chanels]);
  const target = useMemo(
    () =>
      `${
        Math.abs(values?.take_profit?.toString().replace('$', '')) > 0
          ? `+${formatAmount(values.take_profit)}`
          : '- -'
      }/
      ${
        Math.abs(values?.stop_loss?.toString().replace('$', '')) > 0
          ? `-${formatAmount(values.stop_loss)}`
          : '- -'
      }
      ${
        Math.abs(values?.stop_loss_positive?.toString().replace('$', '')) > 0
          ? `/+${formatAmount(values.stop_loss_positive)}`
          : values.signal_type !== SIGNAL_STRATEGY_TYPE.expert
          ? '/- -'
          : ''
      }
      `,
    [values.take_profit, values.stop_loss, values.stop_loss_positive]
  );
  const showStrategy =
    values.signal_type !== SIGNAL_STRATEGY_TYPE.manual &&
    values.signal_type !== SIGNAL_STRATEGY_TYPE.expert;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Form.Headline>{t('View & confirm your plan!')}</Form.Headline>
      <GridStyle>
        <Grid.Row>
          <Grid.Cell className="plan-name">
            <Box width="100%" className="text-ellipsis">
              <span>{values.name}</span>
            </Box>
          </Grid.Cell>
        </Grid.Row>
        <Grid.Row cols={3}>
          <Grid.Cell>
            <Form.Label className="label">{t('Allocated Budget')}</Form.Label>
            <div className="value">{formatAmount(values.budget)}</div>
          </Grid.Cell>
          <Grid.Cell>
            <Form.Label className="label">{t('Base Amount')}</Form.Label>
            <div className="value">{formatAmount(values.base_amount)}</div>
          </Grid.Cell>
          <Grid.Cell>
            <Form.Label className="label">{t('Account Type')}</Form.Label>
            <div className="value">{accountType}</div>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row cols={showStrategy ? 3 : 2}>
          <Grid.Cell>
            <Form.Label className="label">{t('Take Profit/Stoploss')}</Form.Label>
            <div className="value">{target}</div>
          </Grid.Cell>
          {showStrategy && (
            <Grid.Cell className="overflow-hidden">
              <Form.Label className="label">{t('Budget Strategy')}</Form.Label>
              <div className="value text-ellipsis">
                <span>
                  {strategy?.is_default ? t(strategy?.budget_name) : strategy?.budget_name}
                </span>
              </div>
            </Grid.Cell>
          )}
          <Grid.Cell className="overflow-hidden">
            <Form.Label className="label">{t('Signal Strategy')}</Form.Label>
            <div className="value text-ellipsis">
              <span>{signalType}</span>
            </div>
          </Grid.Cell>
        </Grid.Row>
        {!values.copy_from && (
          <Grid.Row>
            {values.signal_type === SIGNAL_STRATEGY_TYPE.botai && (
              <Grid.Cell className="overflow-hidden">
                <Form.Label className="label">{t('Bot Name')}</Form.Label>
                <div className="value list-chips">
                  <Chip>{bot?.is_default ? t(bot?.name) : bot?.name}</Chip>
                </div>
              </Grid.Cell>
            )}
            {values.signal_type === SIGNAL_STRATEGY_TYPE.telegram && (
              <Grid.Cell>
                <Form.Label className="label">{t('Telegram Channel')}</Form.Label>
                <div className="value list-chips">
                  {telegram.map((item, key) => (
                    <Chip key={key}>{item}</Chip>
                  ))}
                </div>
              </Grid.Cell>
            )}
            {values.signal_type === SIGNAL_STRATEGY_TYPE.expert && (
              <Grid.Cell>
                <Form.Label className="label">{t('Leader Nickname')}</Form.Label>
                <div className="value list-chips">
                  {values?.experts.map((item, key) => (
                    <Chip key={key}>{item}</Chip>
                  ))}
                </div>
              </Grid.Cell>
            )}
          </Grid.Row>
        )}
      </GridStyle>
    </motion.div>
  );
});

const GridStyle = styled(Grid)`
  .plan-name {
    background-color: ${theme('mode', {
      light: colorGreyScale100,
      dark: colorGreyScale700,
    })};
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .label {
    color: ${colorGreyScale500};
  }
  .value {
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }
  .list-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .overflow-hidden {
    overflow: hidden;
  }
`;

const Chip = styled.div`
  padding: 8px 12px;
  background-color: ${theme('mode', {
    light: colorGreyScale200,
    dark: colorGreyScale700,
  })};
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default FormReview;
