import React from 'react';
import Skeleton from 'react-loading-skeleton';

const items = Array.from({ length: 1 }, (v, k) => k + 1);

const SkeletonLoadingMessage = () => (
	<div className="overflow-hidden">
		{items.map((item) => (
			<Skeleton height={28} key={item} />
		))}		
	</div>
);

export default SkeletonLoadingMessage;
