import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { FORM_TYPE } from '@/shared/constants';

const FormStopPlan = ({
  currentStrategy,
  formType,
  strategyStopNotEnoughBalance,
  handleChangeStrategyStopNotEnoughBalance,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className="mb-4 form-group-switch full-width">
      <div className="form-group-switch-content">
        <Form.Label className="mb-1 accordion-form-label">
          {t('Stop when not enough balance')}
        </Form.Label>
        <Form.Text>
          {t(
            'Enable Safe Mode will help to stop your plan when your account balance is insufficient.'
          )}
        </Form.Text>
      </div>

      <Form.Check
        type="switch"
        id="check-note-enough-balance"
        checked={strategyStopNotEnoughBalance}
        onChange={handleChangeStrategyStopNotEnoughBalance}
        disabled={currentStrategy.is_default || formType == FORM_TYPE.read_only}
      />
    </Form.Group>
  );
};

FormStopPlan.propTypes = {
  currentStrategy: PropTypes.instanceOf(Object),
  formType: PropTypes.string.isRequired,
  strategyStopNotEnoughBalance: PropTypes.bool.isRequired,
  handleChangeStrategyStopNotEnoughBalance: PropTypes.func.isRequired,
};

FormStopPlan.defaultProps = {
  currentStrategy: {},
};

export default FormStopPlan;
