import { handleActions } from 'redux-actions';
import { TYPE_PLAN, TYPE_ACCOUNT } from '@/shared/constants';
import {
  updateShowPopupTicket,
  updateCurrentTicket,
  updateCurrentLayoutTicket,
} from '../actions/ticketActions';

const defaultState = {
  plans: [],
  plan: {},
  pagination: {},
  filterType: TYPE_PLAN.all,
  accountTypePlan: TYPE_ACCOUNT.live,
  isFetching: true,
  isShowPopup: false,
  currentTicket: {},
  currentLayout: {
    isList: true,
    isCreateForm: false,
    isTicketDetail: false,
    isListComment: false,
  },
};

export default handleActions(
  {
    [updateShowPopupTicket](state) {
      return {
        ...state,
        isShowPopup: !state.isShowPopup,
      };
    },
    [updateCurrentTicket](state, { payload }) {
      return {
        ...state,
        currentTicket: payload,
      };
    },
    [updateCurrentLayoutTicket](state, { payload }) {
      const newStates = {
        isList: false,
        isCreateForm: false,
        isTicketDetail: false,
        isListComment: false,
      };
  
      switch (payload) {
        case 'list':
          newStates.isList = true;
          break;
        case 'create':
          newStates.isCreateForm = true;
          break;
        case 'detail':
          newStates.isTicketDetail = true;
          break;
        case 'list_comment':
          newStates.isListComment = true;
          break;
        default:
          break;
      }

      return {
        ...state,
        currentLayout: newStates,
      };
    },
  },
  defaultState,
);
