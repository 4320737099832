import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Edit, Trash } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

import formatNumber from '@/shared/helpers/formatNumber';
import { updateBacktestMoreConditions } from '@/redux/actions/planActions';

import './index.scss';

const MoreConditions = ({ moreConditions, onShowMoreConditions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="more-conditions">
      <div className="more-conditions-header d-flex justify-content-between align-items-center">
        <h4 className="more-conditions-title">{t('Add more conditions')}</h4>
        <div className="d-flex align-items-center gap-2 more-conditions-action">
          <Edit size={20} onClick={onShowMoreConditions} />
          <Trash
            size={20}
            onClick={() => dispatch(updateBacktestMoreConditions({}))}
          />
        </div>
      </div>
      <div className="more-conditions-body">
        <ul className="more-conditions-list list-unstyled">
          <li className="more-conditions-group">
            <div className="more-conditions-group-header d-flex justify-content-between align-items-center">
              <span className="more-conditions-group-title">{t('Sub Target Conditions')}</span>
              <Form.Check
                type="switch"
                id="switch-sub-target"
                checked={moreConditions.subProfit}
                readOnly
              />
            </div>
            {moreConditions.subProfit && (
              <ul className="more-conditions-group-body list-unstyled">
                {!!moreConditions.sub_take_profit && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Sub profit')}</span>
                    <span className="more-conditions-value text-success">
                      +{formatNumber.formatCurrency(Math.abs(moreConditions.sub_take_profit))}
                    </span>
                  </li>
                )}
                {!!moreConditions.sub_stop_loss && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Sub stoploss')}</span>
                    <span className="more-conditions-value text-error">
                      -{formatNumber.formatCurrency(Math.abs(moreConditions.sub_stop_loss))}
                    </span>
                  </li>
                )}
                {!!moreConditions.sub_win_streak && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Win streak')}</span>
                    <span className="more-conditions-value">{moreConditions.sub_win_streak}</span>
                  </li>
                )}
                {!!moreConditions.sub_lose_streak && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Lose streak')}</span>
                    <span className="more-conditions-value">{moreConditions.sub_lose_streak}</span>
                  </li>
                )}
              </ul>
            )}
          </li>

          <li className="more-conditions-group">
            <div className="more-conditions-group-header d-flex justify-content-between align-items-center">
              <span className="more-conditions-group-title">{t('Reverse Signal')}</span>
              <Form.Check
                type="switch"
                id="switch-reverse-signal"
                checked={moreConditions.reverse_signal}
                readOnly
              />
            </div>
          </li>

          <li className="more-conditions-group">
            <div className="more-conditions-group-header d-flex justify-content-between align-items-center">
              <span className="more-conditions-group-title">{t('Wait Signal')}</span>
              <Form.Check
                type="switch"
                id="switch-wait-signal"
                checked={moreConditions.wait_signal}
                readOnly
              />
            </div>
            {moreConditions.wait_signal && (
              <ul className="more-conditions-group-body list-unstyled">
                <li className="more-conditions-item d-flex justify-content-between">
                  <span className="more-conditions-label">
                    {moreConditions.win_streak ? t('Wait Win Streak') : t('Wait Lose Streak')}
                  </span>
                  <span className="more-conditions-value">
                    {moreConditions.win_streak || moreConditions.lose_streak}
                  </span>
                </li>
                <li className="more-conditions-item d-flex justify-content-between">
                  <span className="more-conditions-label">{t('Place Order')}</span>
                  <span className="more-conditions-value">
                    {moreConditions.no_of_entries > 0
                      ? t('Enter {{order}} order in a row', {order: moreConditions.no_of_entries})
                      : t('when ultil finish budget')}
                  </span>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

MoreConditions.propTypes = {
  moreConditions: PropTypes.instanceOf(Object),
  onShowMoreConditions: PropTypes.func.isRequired,
};

MoreConditions.defaultProps = {
  moreConditions: {},
};

export default MoreConditions;
