import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PasswordField from '@/shared/components/form/Password';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
} from '@/shared/components/form/FormElements';
import { colorSuccess, colorText, Devices } from '@/utils/palette';
import { validatorHelper } from '@/shared/helpers';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { AccountButton } from '../AccountElements';
import renderCheckBoxField from '../../form/CheckBox';
import FormField from '../../form/FormField';
import { useCookies } from 'react-cookie';

const RegisterForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [{ REFCODE }] = useCookies(['REFCODE'])
  const validator = validatorHelper(t);
  const isFetching = useSelector(
    state => state.user.isFetching,
    _.isEqual,
  );


  return (
    <Form onSubmit={onSubmit} initialValues={{
      referrerCode: REFCODE
    }}>
      {({ handleSubmit, invalid }) => (
        <FormContainer autoComplete="off" onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupField>
              <Field
                validate={validator.composeValidators(
                  validator.required,
                  validator.isEmail,
                )}
                name="email"
                component={FormField}
                type="email"
                placeholder={t('Email')}
                autoComplete="off"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                validate={validator.composeValidators(
                  validator.required,
                  validator.minValue(6),
                  validator.maxValue(20),
                )}
                name="password"
                component={PasswordField}
                placeholder={t('Password')}
                autoComplete="off"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                validate={validator.composeValidators(
                  validator.required,
                  validator.isNickname,
                )}
                name="nickName"
                component={FormField}
                placeholder={t('Nickname')}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                name="referrerCode"
                component={FormField}
                placeholder={t('Referral ID')}
                readOnly={REFCODE}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                validate={validator.required}
                name="term"
                render={renderCheckBoxField}
                label={(
                  <TermConditions>
                    {t('By proceeding, you agree to the')}{' '}
                    <Link target="_blank" to="/help/terms-of-use">{t('Terms and Conditions')}</Link>
                  </TermConditions>
                )}
                type="checkbox"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <AccountButton
              disabled={isFetching || invalid}
              type="submit"
              variant="success"
              isLoading={isFetching}
            >
              {t('Sign up with email')}
            </AccountButton>
          </FormGroup>
        </FormContainer>
      )}
    </Form>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;

// region STYLES

const TermConditions = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${colorText};
  a {
    color: ${colorSuccess};
  }
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

// endregion
