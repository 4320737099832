import { AccountCard, AccountTitle } from '@/shared/components/account/AccountElements';
import {
  cardBgColor,
  colorGreyScale300,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale800,
  colorSuccess, colorText, Devices,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'styled-theming';

const ExchangeLogoBgColor = theme('mode', {
  light: colorGreyScale300,
  dark: colorGreyScale800,
});

export const PageTitleStyle = styled(AccountTitle)`
  margin-bottom: 16px;
  font-size: 20px;
  color: ${colorSuccess};
  @media ${Devices.pc} {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 32px;
  }
`;
export const ExchangeLogo = styled(Box)`
  min-width: 200px;
  width: fit-content;
  height: 46px;
  background: ${ExchangeLogoBgColor};
  border: 1px solid ${ExchangeLogoBgColor};
  border-radius: 27px;
  margin: 4px auto;
  padding: 9px 16px;
  text-align: center;
  &.hasImage {
    background: linear-gradient(180deg, #666C75 0%, #020D1D 100%);
    border: 1px solid ${ExchangeLogoBgColor};
  }
  img {
    pointer-events: none;
    /* mix-blend-mode: luminosity; */
  }
  @media ${Devices.pc} {
    height: 54.4px;
    padding: 10px 32px;
  }
`;
export const Content = styled(Box)`
  max-width: 522px;
  width: 100%;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .head {
    margin-bottom: 32px;
    .site-url {
      text-align: center;
      margin-top: 16px;
      font-weight: 600;
      color: ${colorGreyScale600};
    }
  }
`;

export const CardTitleStyle = styled(Box)`
  margin-bottom: 12px;
  font-weight: 800;
  font-size: 18px;
  line-height: 130%;
  color: ${colorText};
  @media ${Devices.pc} {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;
export const CardDescriptionStyle = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${colorGreyScale600};
  @media ${Devices.pc} {
    font-size: 16px;
  }
`;

export const FormLabel = styled(Box)`
  font-size: 14px;
  line-height: 150%;
  color: ${colorText};
  font-weight: 600;
  margin-bottom: 12px;
  @media ${Devices.pc} {
    font-weight: 700;
    font-size: 16px;
  }
`;
export const AccountCardStyle = styled(AccountCard)`
  max-width: 552px;
  width: 100%;
  padding: 16px;
  border-radius: 24px;
  position: relative;
  gap: 16px;
  background-color: ${cardBgColor}!important;

  @media ${Devices.pc} {
    padding: 32px
  }
`;
export const HintCreateAccount = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #718096;
  margin-top: 24px;
  span.cta {
    cursor: pointer;
    color: ${colorSuccess};
  }
`
