import React from 'react';
import PropTypes from 'prop-types';
import {
  ShieldCross,
  ShieldTick,
  WalletRemove,
  TimerStart,
  TimerPause,
  WalletAdd1,
  WalletMinus,
  Eye,
  EyeSlash
} from 'iconsax-react';
import { NOTIFICATION_ITEM_TYPE } from '@/shared/constants';

const NotificationItemIcon = ({ type }) => (
  <>
    {(NOTIFICATION_ITEM_TYPE.stopLoss === type ||
      NOTIFICATION_ITEM_TYPE.dailyGoalStopLoss === type) && <ShieldCross size="24" color="#fff" />}
    {(NOTIFICATION_ITEM_TYPE.takeProfit === type ||
      NOTIFICATION_ITEM_TYPE.dailyGoalTakeProfit === type) && <ShieldTick size="24" color="#fff" />}
    {NOTIFICATION_ITEM_TYPE.invalidBalance === type && <WalletRemove size="24" color="#fff" />}
    {NOTIFICATION_ITEM_TYPE.timerStart === type && <TimerStart size="24" color="#fff" />}
    {NOTIFICATION_ITEM_TYPE.timerStop === type && <TimerPause size="24" color="#fff" />}
    {NOTIFICATION_ITEM_TYPE.block === type && <EyeSlash size="24" color="#fff" />}
    {NOTIFICATION_ITEM_TYPE.unblock === type && <Eye size="24" color="#fff" />}
    {(NOTIFICATION_ITEM_TYPE.withdraw === type ||
      NOTIFICATION_ITEM_TYPE.withdrawError === type ||
      NOTIFICATION_ITEM_TYPE.internalWithdrawError === type ||
      NOTIFICATION_ITEM_TYPE.internalWithdrawSuccess === type) && (
      <WalletMinus size="24" color="#fff" />
    )}
    {(NOTIFICATION_ITEM_TYPE.deposit === type ||
      NOTIFICATION_ITEM_TYPE.internalDepositSuccess === type) && (
      <WalletAdd1 size="24" color="#fff" />
    )}
  </>
);

NotificationItemIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NotificationItemIcon;
