import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { AccountButton } from './AccountElements';
import ModalAccount, {
  ModalAccountDescription, ModalAccountOtpInputStyle, ModalAccountLinkStyle, ModalAccountTitle, 
} from './ModalAccount';

const ModalVerify2FA = ({
  isOpen, closeModal, onSubmit, isLoading, 
}) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // reset value on close modal
    if (!isOpen) {
      setOtp('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (otp.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp]);

  const handleSubmit = () => onSubmit(otp);
  
  const paste = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .readText()
        .then((text) => {
          setOtp(text);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <ModalAccount
      closeModal={closeModal}
      isOpen={isOpen}
    >
      <ModalAccountTitle>{t('ModalVerify2FA.title', 'Enter 2FA code')}</ModalAccountTitle>
      <ModalAccountDescriptionStyle>
        {t('ModalVerify2FA.description', 
        'If you set up 2-Step Verification, you can use the Google Authenticator app to receive codes.')}
      </ModalAccountDescriptionStyle>
      <ModalAccountOtpInputStyle
        numInputs={6}
        value={otp}
        onChange={setOtp}
        isInputNum
        shouldAutoFocus
        focusStyle="otp__focus"
      />
      <Box className="paste-code">
        <ModalAccountLinkStyle onClick={paste}>{t('Paste 2FA Code')}</ModalAccountLinkStyle>
      </Box>
      <AccountButton
        onClick={handleSubmit}
        disabled={disabled || isLoading}
        type="submit"
        size="lg"
        variant="success"
        isLoading={isLoading}
      >
        {t('Verify')}
      </AccountButton>
    </ModalAccount>
  );
};

const ModalAccountDescriptionStyle = styled(ModalAccountDescription)`
  margin-bottom: 32px;
`;

ModalVerify2FA.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ModalVerify2FA.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default ModalVerify2FA;
