import Error from '@/shared/components/form/Error';
import { colorAccent } from '@/utils/palette';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const FormField = ({
  input,
  paste,
  onPaste,
  showErrorText = true,
  meta: { touched, error },
  component: Component,
  isAboveError,
  wrapperClassName,
  ...props
}) => {
  const { t } = useTranslation();
  const handlePaste = async () => {
    try {
      if (onPaste) {
        onPaste(input);
      } else {
        const text = await navigator.clipboard.readText();
        if (text !== 'err') {
          input.onChange(text);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <FormInputWrap className={wrapperClassName}>
      <InputWrap paste={+paste}>
        <Component
          className={touched && error ? 'invalid' : ''}
          {...props}
          {...input}
        />
        {paste && <Paste onClick={handlePaste}>{t('PASTE')}</Paste>}
      </InputWrap>
      {touched && showErrorText && error && (
        <Error error={error} top={isAboveError} />
      )}
    </FormInputWrap>
  );
};

FormField.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  component: PropTypes.elementType,
  isAboveError: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  paste: PropTypes.bool,
  onPaste: PropTypes.func,
};

FormField.defaultProps = {
  input: null,
  meta: {
    touched: false,
    error: '',
  },
  component: 'input',
  isAboveError: false,
  wrapperClassName: '',
  paste: false,
  onPaste: null,
};

export const renderComponentField = (component) => (props) =>
  <FormField component={component} {...props} />;

export const renderMaskedField = renderComponentField(MaskedInput);

export default FormField;

// region STYLES

const FormInputWrap = styled.div`
  width: 100%;
`;

const InputWrap = styled(Box)`
  position: relative;
  ${(props) =>
    props.paste &&
    `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
      padding-right: 80px
    }
  `}
`;

const Paste = styled(Box)`
  user-select: none;
  position: absolute;
  right: 25px;
  /* height: 100%;
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: center; */
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${colorAccent};
`;
// endregion
