import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const ButtonCountdown = props => <ButtonStyle {...props} />;
const ButtonStyle = styled(Button)`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
`;
ButtonCountdown.propTypes = {};
export default ButtonCountdown;
