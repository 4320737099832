import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PasswordField from '@/shared/components/form/Password';
import {
  FormGroup,
  FormGroupField,
} from '@/shared/components/form/FormElements';
import { validatorHelper } from '@/shared/helpers';
import FormField from '@/shared/components/form/FormField';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import {
  AccountButton,
  AccountForgotPassword,
  LoginForm,
} from '../AccountElements';

const ShortField = props => (
  <FormGroup>
    <FormGroupField>
      <Field {...props} />
    </FormGroupField>
  </FormGroup>
);

const LogInForm = ({ onSubmit, typeFieldUser, onForgotClick }) => {
  const isFetching = useSelector(state => state.user.isFetching, _.isEqual);
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, invalid }) => (
        <LoginForm onSubmit={handleSubmit}>
          <ShortField
            validate={validator.composeValidators(
              validator.required,
              validator.isEmail,
            )}
            name="username"
            component={FormField}
            type={typeFieldUser}
            placeholder={t('Email')}
          />
          <ShortField
            validate={validator.required}
            name="password"
            component={PasswordField}
            placeholder={t('Password')}
          />

          <FormGroup>
            <div className="d-flex">
              <Box flex={1} />
              <AccountForgotPassword>
                <NavLink onClick={onForgotClick} className="text-nowrap" to="#">
                  {t('Forgot a password')}?
                </NavLink>
              </AccountForgotPassword>
            </div>
          </FormGroup>
          <FormGroup>
            <AccountButton
              disabled={isFetching || invalid}
              type="submit"
              variant="success"
              size="lg"
              isLoading={isFetching}
            >
              {t('Sign in with email')}
            </AccountButton>
          </FormGroup>
        </LoginForm>
      )}
    </Form>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  typeFieldUser: PropTypes.string,
  onForgotClick: PropTypes.func.isRequired,
};

LogInForm.defaultProps = {
  typeFieldUser: 'text',
};

export default LogInForm;
