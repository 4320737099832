import { Setting2 } from 'iconsax-react';
import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorkerRegistration';
import Loading from './shared/components/Loading';
import { NetworkHandler } from './shared/components/NetworkHandler';
import './translations/index';

const isProduction = process.env.REACT_APP_ENV === 'prod';

if (isProduction) {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
}

// // if (isProduction) {
// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
// };
// TagManager.initialize(tagManagerArgs);
// // }

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <Suspense fallback={<Loading message="Initializing..." Icon={Setting2} />}>
      <App />
      <NetworkHandler />
    </Suspense>
  </CookiesProvider>
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
