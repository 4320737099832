/* eslint-disable react/prop-types */
import Error from '@/shared/components/form/Error';
import {
  DropdownIndicator,
  DropdownPlaceholder,
} from '@/shared/components/react-select';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  Devices,
  borderDefaultStyle,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale700,
  colorOtpInputBackground,
  colorRed,
  colorSuccess,
  colorText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { Collapse, Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { TagsInput } from 'react-tag-input-component';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Button } from '../../../Button';
import CurrencyInput from '../../../CurrencyInput';

export const Input = ({
  input,
  showError,
  isEdit,
  isAboveError,
  meta,
  ...props
}) => {
  const { touched, error } = meta;
  return (
    <Box>
      <InputStyle {...props} {...input} />
      {showError && (isEdit || touched) && error && (
        <Error error={error} top={isAboveError} />
      )}
    </Box>
  );
};
export const TagInput = ({
  input,
  // meta,
  hideInput,
  form,
  onBlur,
  // isAboveError,
  ...props
}) => {
  // const { touched, error } = meta;
  const handleBlur = (evt) => {
    if (onBlur) {
      onBlur(evt);
    }
  };
  return (
    <Box>
      <TagsInputStyle hideinput={+hideInput}>
        <TagsInput {...props} {...input} onBlur={handleBlur} />
      </TagsInputStyle>
      {/* {touched && error && <Error error={error} top={isAboveError} />} */}
    </Box>
  );
};
export const BalanceInput = ({ input, showError, meta = {}, ...props }) => {
  const { error } = meta;
  return (
    <Box>
      <CurrencyInputStyle {...props} {...input} />
      {showError && error && <Error error={error} />}
    </Box>
  );
};
export const RadioAsButton = ({ input, label, id, ...props }) => (
  <RadioAsButtonStyle>
    <input {...props} {...input} id={id} />
    <label htmlFor={id}>{label} </label>
  </RadioAsButtonStyle>
);
export const FormCheckbox = ({ input, ...props }) => {
  const inputProps = {
    ...input,
  };
  if (input.type === 'switch') {
    inputProps.checked = input?.value;
  }
  return <Form.Check {...props} {...inputProps} />;
};
export const FormSelect = ({ input, ...props }) => {
  const { t } = useTranslation();
  return (
    <SelectStyle
      {...input}
      {...props}
      components={{ DropdownIndicator, Placeholder: DropdownPlaceholder }}
      noOptionsMessage={() => t('No options')}
    />
  );
};

export const InputStyle = styled.input`
  width: 100%;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${colorSuccess};
  border: none;
  background: transparent;
  &::placeholder {
    color: ${colorGreyScale500};
  }
  @media ${Devices.pc} {
    font-size: 24px;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: ${colorSuccess} !important;
  }
`;
export const FormLabel = styled.label`
  gap: 20px;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${colorText};
  margin-bottom: 20px;
  max-width: 100%;
  /* word-break: break-all; */
  @media ${Devices.pc} {
    font-size: 24px;
  }
`;
export const FormContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 32px;
  padding-bottom: 32px;
  select {
    font-weight: 800;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: ${colorSuccess};
    border: none;
    appearance: none;
    background-color: transparent;
    background-image: ${theme('mode', {
      light: 'url(/img/chevron-down.svg)',
      dark: 'url(/img/chevron-down-white.svg)',
    })};
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    padding-right: 48px;
    max-width: 100%;
    text-overflow: ellipsis;
    cursor: pointer;
    &::placeholder {
      color: ${colorGreyScale500};
    }
    @media ${Devices.pc} {
      font-size: 24px;
    }
  }

  @media ${Devices.pc} {
    gap: 64px;
    padding-top: 32px;
    padding-bottom: 64px;
  }
`;
export const CurrencyInputStyle = styled(CurrencyInput)`
  width: 100%;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${colorSuccess};
  background: transparent;
  border: none;
  &::placeholder {
    color: ${colorGreyScale500};
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: ${colorSuccess} !important;
  }
  @media ${Devices.pc} {
    font-size: 24px;
  }
`;
export const RadioAsButtonStyle = styled.div`
  display: flex;
  flex: 1;
  label {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0aec0;
    padding: 8px;
    border: ${borderDefaultStyle};
    cursor: pointer;
    width: 100%;
    transition: background 150ms linear;
  }
  &:first-child {
    label {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-right: none;
    }
  }
  &:last-child {
    label {
      border-left: none;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  input {
    display: none;
  }
  input:checked + label {
    color: #fff;
    background: #0caf60;
    border: none;
    transition: background 150ms linear;
  }
`;
export const FormHint = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${colorGreyScale500};
  /* word-break: break-all; */
`;

// Begin DefaultBudget
export const DefaultBudget = ({ onSelect, options = [] }) => (
  <DefaultBudgetWrap>
    {options.map((item) => (
      <BtnBudget
        type="button"
        onClick={() => onSelect(item)}
        variant="outline_secondary"
        key={item}
      >
        {formatNumber.formatCurrency(item)}
      </BtnBudget>
    ))}
  </DefaultBudgetWrap>
);

export const DefaultBudgetWrap = styled.div`
  display: flex;
  gap: 14px;
  margin-top: 24px;
  flex-wrap: wrap;
`;
export const BtnBudget = styled.button`
  border-radius: 20px;
  padding: 4px 20px;
  color: ${colorGreyScale500};
  background: none;
  border: 1px solid #a0aec0;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  ${(props) =>
    props.isactive &&
    `
      background: ${colorSuccess};
      border: none;
      color: white;
  `}
`;
// End DefaultBudget

export const DefaultTarget = ({ onSelect, options = [], affix = '' }) => (
  <DefaultTargetWrap>
    {options.map((item) => (
      <BtnTarget onClick={() => onSelect(item)} key={item}>
        {item}
        {affix}
      </BtnTarget>
    ))}
  </DefaultTargetWrap>
);

export const DefaultTargetWrap = styled.div`
  display: flex;
  gap: 14px;
  /* margin-top: 18px; */
  /* flex-wrap: wrap; */
`;
export const BtnTarget = styled(Button)`
  border-radius: 18px;
  padding: 8px 16px;
  color: ${colorGreyScale500};
  flex: 1;
  min-width: 64px;
  line-height: 1;
`;

export const SubTitle = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: ${colorText};
  word-break: break-all;
  margin-bottom: 14px;
  gap: 20px;
  white-space: nowrap;
`;

export const FieldStyle = styled(Field)`
  font-size: 20px;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  border: none;
  width: 100%;
  background: transparent;
  ${(props) =>
    props.valuetype === 'pos' &&
    `
    color: ${colorSuccess}!important;
  `}
  ${(props) =>
    props.valuetype === 'neg' &&
    `
    color: ${colorRed}!important;
  `}
  &[type="time"] {
    min-width: 120px;
  }
  color-scheme: ${theme('mode', {
    light: 'light',
    dark: 'dark',
  })};
`;
export const FieldWaitSignalStyle = styled(Field)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  width: 68px;
  color: ${colorText};
  background: ${colorOtpInputBackground};
  border-radius: 12px;
  padding: 8px 12px;
  text-align: center;
`;

export const CollapseStyle = styled(Collapse)``;

export const FormExternalLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  text-decoration-line: underline;
  color: ${colorText};
  cursor: pointer;
`;

export const TagsInputStyle = styled(Box)`
  .rti--container {
    border: none;
    padding: 0;
    gap: 12px;
    background: transparent;
    &:focus,
    &:focus-within {
      border: none;
      box-shadow: none;
    }
    .rti--input {
      font-weight: 500;
      line-height: 130%;
      width: auto;
      background: transparent;
      color: ${colorText};
      padding: 6px 0;
      &::placeholder {
        color: ${colorGreyScale500};
      }
    }
    .rti--tag {
      background: ${theme('mode', {
        light: colorGreyScale300,
        dark: colorGreyScale700,
      })};
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      padding: 8px 8px 8px 12px;
      border-radius: 8px;
      button {
        font-weight: 600;
        color: ${colorText};
        &:hover {
          color: ${colorRed};
        }
      }
    }
    ${(props) =>
      props.hideinput &&
      `
      .rti--input {
        display: none;
      }
    `}
  }
`;

export const SelectStyle = styled(Select)``;
