import { useSelector } from 'react-redux';
import { selectUserConfig } from '@/redux/refactor/appConfig/selector';
import { useEffect, useState } from 'react';

const useFeatureFlag = (featureName) => {
  const [isSupport, setIsSupport] = useState(false);
  const userConfigs = useSelector(selectUserConfig);

  useEffect(() => {
    const featureFlags = userConfigs?.feature_flags;
    if (!featureFlags || !featureFlags.length) return;

    const featureFlag = featureFlags.find(item => item.name === featureName);
    if (featureFlag) {
      setIsSupport(!!featureFlag.enable);
    }
  }, [userConfigs, featureName])

  return isSupport
}

export default useFeatureFlag;
