import NumberAnimated from 'animated-number-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import formatNumber from '../helpers/formatNumber';

const AnimatedNumber = ({ value, style, duration, formatValue }) => {
  const animate = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      animate.current = true;
    }, 100);
  }, []);
  return (
    <NumberAnimated
      style={style}
      value={value}
      formatValue={formatValue}
      duration={animate.current ? duration : 0}
    />
  );
};

AnimatedNumber.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object),
  duration: PropTypes.number,
  formatValue: PropTypes.func,
};

AnimatedNumber.defaultProps = {
  style: {},
  duration: 1000,
  formatValue: formatNumber.formatCurrency,
};

export default AnimatedNumber;
