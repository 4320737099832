const { checkMobileScreen } = require('@/redux/refactor/theme/actions');
const { useEffect } = require('react');
const { useDispatch } = require('react-redux');

const WindowResizeHandler = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const onResize = () => {
      dispatch(checkMobileScreen(window.innerWidth));
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [dispatch]);
  return null;
};

export default WindowResizeHandler;
