export const CONTACT_EMAIL = 'contact@quickinvest.ai';
export const DOCUMENT_URL = 'https://docs.quickinvest.ai';
export const IS_PROD = process.env.REACT_APP_ENV === 'prod';
export const API_URL = process.env.REACT_APP_API_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;
export const WIDGET_URL = process.env.REACT_APP_WIDGET_URL;
export const USER_TOKEN = 'USER_TOKEN';
export const DEVICE_TOKEN = 'DEVICE_TOKEN';
export const STATE_REQUEST_CONFIRM_EMAIL = 'STATE_REQUEST_CONFIRM_EMAIL';
export const PASSWORDLESS_ENABLED = process.env.REACT_APP_PASSWORDLESS_ENABLED === 'true';

export const SIDEBAR_WIDTH = 250;
export const SIDEBAR_COLLAPSED_WIDTH = 82;
export const FOOTER_HEIGHT = 64;
export const TOPBAR_HEIGHT = 72;
export const MOBILE_TOPBAR_HEIGHT = 56;
export const MOBILE_FOOTER_HEIGHT = 60;

export const MAX_UPLOAD_SIZE = 5242880;
export const UPLOAD_IMAGE_ALLOW = ['image/jpeg', 'image/jpg', 'image/png'];

export const STORAGE_KEY = {
  EXPAND_CURRENT_PLAN: 'EXPAND_CURRENT_PLAN',
  DASHBOARD_ACCOUNT_TYPE: 'DASHBOARD_ACCOUNT_TYPE',
  MOBILE_SIDEBAR_VISIBILITY: 'MOBILE_SIDEBAR_VISIBILITY',
  SIDEBAR_VISIBILITY: 'SIDEBAR_VISIBILITY',
  CURRENT_LANGUAGE: 'i18nextLng',
  WALLET_TYPE: 'wallet_type',
  LIST_PLAN_PAGE_SIZE: 'LIST_PLAN_PAGE_SIZE',
  MANUAL_ORDER_LOG: 'MANUAL_ORDER_LOG',
  PAUSE_PLAN_DO_NOT_SHOW_AGAIN: 'PAUSE_PLAN_DO_NOT_SHOW_AGAIN',
};

export const ID_TYPE = {
  NationalID: 'National ID',
  Passport: 'Passport',
};

export const GRANT_TYPE = {
  PASSWORD: 'password',
  REFRESH: 'refresh_token',
};

export const TRANSACTION_TYPE = {
  WITHDRAW: 'Withdraw',
  INTERNAL_WITHDRAW: 'InternalWithdraw',
  DEPOSIT: 'Deposit',
  INTERNAL_DEPOSIT: 'InternalDeposit',
  BUY_AGENCY_LICENSE: 'BUY_AGENCY_LICENSE',
  TRANSFER_IN: 'TRANSFER_IN',
  TRANSFER_OUT: 'TRANSFER_OUT',
};

export const TRANSFER_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
};

export const WALLET_TYPE = {
  LIVE: 'LIVE',
  DEMO: 'DEMO',
};

export const TRANSFER_TYPES = {
  IN: 'IN',
  OUT: 'OUT',
};

export const NETWORK_TYPE = {
  BSC: 'bsc',
};

export const BSC_SCAN_URL = process.env.REACT_APP_BSC_SCAN;
export const getTxUrl = (txid) => (BSC_SCAN_URL ? BSC_SCAN_URL.concat('/').concat(txid) : '');

export const VIP_BACKGROUND = [
  'linear-gradient(60.97deg, #6A85B6 29.01%, #BAC8E0 89.51%)',
  'linear-gradient(60.97deg, #6A85B6 29.01%, #BAC8E0 89.51%)',
  'linear-gradient(102.13deg, #F9F9F9 2.81%, #0CAFAF 33.51%, #0CAF6B 61.5%, #36C17E 89.49%)',
  'linear-gradient(102.13deg, #F9F9F9 2.81%, #0CAFAF 33.51%, #0CAF6B 61.5%, #36C17E 89.49%)',
  'linear-gradient(78.37deg, #7028E4 -5.08%, #E5B2CA 90.72%)',
  'linear-gradient(78.37deg, #7028E4 -5.08%, #E5B2CA 90.72%)',
  'linear-gradient(254.82deg, #F8DF85 -4.18%, #C06E1C 94.19%)',
];

export const getDocumentUrl = () => {
  let lang = localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE) || 'vi';
  if (lang === 'th') lang = 'en';
  return `${DOCUMENT_URL}/v/${lang}`;
};

export const CANDLE_BASE = [1, 5, 9, 13, 17, 2, 6, 10, 14, 18, 3, 7, 11, 15, 19, 4, 8, 12, 16, 20];

export const MANUAL_TRADE_DELAY_TIME = 0; // 4

export const LENGTH_VALIDATION = {
  autowin: 50000,
  all: 2000,
};

export const MAX_ORDER_VALUE = 1000000;

export const FILTER_TYPE = {
  all: 'ALL',
  order: 'ORDER',
  status: 'STATUS',
};

export const FILTER_TYPE_LABEL = {
  [FILTER_TYPE.all]: 'All',
  [FILTER_TYPE.order]: 'Orders',
  [FILTER_TYPE.status]: 'Plan Status',
};

export const FILTER_TIME = {
  RECENTLY: 'RECENTLY',
  CUSTOM_DATE: 'CUSTOM_DATE',
};

export const FILTER_TIME_LABEL = {
  [FILTER_TIME.RECENTLY]: 'Recently',
  [FILTER_TIME.CUSTOM_DATE]: 'Custom',
};

export const CURRENT_TIMEZONE = -(new Date().getTimezoneOffset() / 60);
export const CURRENT_TIMEZONE_STRING = `(UTC${
  CURRENT_TIMEZONE >= 0 ? '+' : ''
}${CURRENT_TIMEZONE})`;

export const TOTAL_BOT_MIX = 10;
export const TOTAL_TOP_SIGNAL_SELECT = 5;

export const isLocalhost = () => {
  // console.log('call -> isLocalhost');
  return ['localhost', '127.0.0.1'].includes(location.hostname);
};
export const isSTG = () => window.location.hostname === 'stg.quickinvest.ai';

export default {};
