import React from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import { updateShowPopupTicket } from '@/redux/actions/ticketActions';
import { Button } from '@/shared/components/Button';
import { Copyright } from 'iconsax-react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import TopbarSecondary from './topbar/TopbarSecondary';

import './help.scss';

const Help = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const gridSize = useWindowSize();
  const isShowPopup = useSelector(
    (state) => state.ticket.isShowPopup,
    shallowEqual
  );
  const profile = useSelector((state) => state.user.profile, shallowEqual);
  const { t } = useTranslation();
  const openTicketForm = () => {
    if (!isShowPopup) {
      dispatch(updateShowPopupTicket());
    }
    if (!profile) {
      history.push('/');
    }
  };


  return (
    <div className="help-layout">
      <TopbarSecondary />
      <div className="help-banner text-center">
        <div className="help-banner-inner">
          <h2 className="help-banner-title mb-4">
            {t('Can’t find your answer?')}
          </h2>
          <p className="help-banner-text">
            {t('Contact us and we’ll get back to you as soon as we can.')}
          </p>

          <Button
            variant="success"
            size="lg"
            className="mb-4"
            onClick={openTicketForm}
          >
            {t('Submit ticket')}
          </Button>
          <p>
            <a
              href="https://t.me/QuickInvestAI"
              target="_blank"
              rel="noreferrer"
              className="help-banner-link"
            >
              {t('Go to Telegram Support')}
            </a>
          </p>
        </div>
      </div>
      <div className="help-layout-inner">
        {gridSize && ['xs', 'sm', 'md', 'lg'].includes(gridSize) && (
          <Dropdown className="mb-3">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {t('Help center')}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="/help/faqs"
                active={location?.pathname === '/help/faqs'}
              >
                FAQs
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/general-provisions"
                active={location?.pathname === '/help/general-provisions'}
              >
                {t('General Provisions')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/tutorial-video"
                active={location?.pathname === '/help/tutorial-video'}
              >
                {t('Guideline Tutorials (10 Videos)')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/terms-of-use"
                active={location?.pathname === '/help/terms-of-use'}
              >
                {t('Terms of use')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/communications"
                active={location?.pathname === '/help/communications'}
              >
                {t('Communications')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/dispute-resolution"
                active={location?.pathname === '/help/dispute-resolution'}
              >
                {t('Dispute Resolution')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/governing-law"
                active={location?.pathname === '/help/governing-law'}
              >
                {t('Governing Law')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/force-majeure"
                active={location?.pathname === '/help/force-majeure'}
              >
                {t('Force Majeure')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/responsibility-of-the-parties"
                active={
                  location?.pathname === '/help/responsibility-of-the-parties'
                }
              >
                {t('Responsibility of the Parties')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/term-and-termination"
                active={location?.pathname === '/help/term-and-termination'}
              >
                {t('Term and Termination')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/final-provisions"
                active={location?.pathname === '/help/final-provisions'}
              >
                {t('Final Provisions')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/rights-and-obligations"
                active={location?.pathname === '/help/rights-and-obligations'}
              >
                {t('Rights and Obligations')}
              </Dropdown.Item>
              <Dropdown.Item
                href="/help/privacy-policy"
                active={location?.pathname === '/help/privacy-policy'}
              >
                {t('Privacy Policy')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {gridSize && ['xl', 'xxl'].includes(gridSize) && (
          <div className="help-layout-sidebar">
            <div className="help-layout-sidebar-inner">
              <NavLink to="/help/faqs" activeClassName="active">
                FAQs
              </NavLink>
              <NavLink to="/help/general-provisions" activeClassName="active">
                {t('General Provisions')}
              </NavLink>
              <NavLink to="/help/tutorial-video" activeClassName="active">
                {t('Guideline Tutorials (10 Videos)')}
              </NavLink>
              <NavLink to="/help/terms-of-use" activeClassName="active">
                {t('Terms of use')}
              </NavLink>
              <NavLink to="/help/communications" activeClassName="active">
                {t('Communications')}
              </NavLink>
              <NavLink to="/help/dispute-resolution" activeClassName="active">
                {t('Dispute Resolution')}
              </NavLink>
              <NavLink to="/help/governing-law" activeClassName="active">
                {t('Governing Law')}
              </NavLink>
              <NavLink to="/help/force-majeure" activeClassName="active">
                {t('Force Majeure')}
              </NavLink>
              <NavLink
                to="/help/responsibility-of-the-parties"
                activeClassName="active"
              >
                {t('Responsibility of the Parties')}
              </NavLink>
              <NavLink to="/help/term-and-termination" activeClassName="active">
                {t('Term and Termination')}
              </NavLink>
              <NavLink to="/help/final-provisions" activeClassName="active">
                {t('Final Provisions')}
              </NavLink>
              <NavLink
                to="/help/rights-and-obligations"
                activeClassName="active"
              >
                {t('Rights and Obligations')}
              </NavLink>
              <NavLink to="/help/privacy-policy" activeClassName="active">
                {t('Privacy Policy')}
              </NavLink>
            </div>
          </div>
        )}
        <div className="help-layout-main-content">{children}</div>
      </div>
      <p className="help-layout-footer d-flex justify-content-center">
        <Copyright
          size="18"
          color="#718096"
          className="me-1"
          style={{ marginTop: '3px' }}
        />
        {t('Copyright © 2022 Quickinvest, All rights reserved.')}
      </p>
    </div>
  );
};

Help.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Help;
