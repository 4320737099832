import styled from 'styled-components';
import {
  Devices,
  borderDefaultStyle,
  colorCheckboxBorder,
  colorGreyScale400,
  colorGreyScale500,
  colorOtpInputBackground,
} from '@/utils/palette';
import { Accordion, Form } from 'react-bootstrap';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 16px;
`;
export const StyledHint = styled.span`
  color: ${colorGreyScale500};
  &:hover {
    cursor: help;
  }
`;
export const StyledWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: ${borderDefaultStyle};
  border-radius: 16px;
  overflow: hidden;
`;
export const StyledListContainer = styled.div`
  border-right: ${borderDefaultStyle};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;
export const StyledMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  padding-right: 8px;
  flex: 1;
`;
export const StyledRow = styled.div`
  display: flex;
  max-height: calc(100vh - 300px);
`;
export const StyledSelectedOption = styled.div`
  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    color: ${colorGreyScale500};
    margin-bottom: 4px;
  }
  @media ${Devices.pc} {
    border-top: ${borderDefaultStyle};
    padding: 12px 16px;
  }
`;
export const StyledAccordion = styled(Accordion)`
  flex: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .accordion-item {
    margin-bottom: 16px;
    border-radius: 12px;
    border: ${borderDefaultStyle};
  }
  .accordion-button {
    width: 100%;
  }
  .accordion-header {
    padding: 16px;
  }
  .accordion-body {
    display: flex;
    height: 246px;
    padding: 16px;
    overflow: auto;
    border-top: ${borderDefaultStyle};
  }
`;

export const StyledCheckbox = styled(Form.Check)`
  .form-check-input {
    width: 16px;
    height: 16px;
    border-color: ${colorGreyScale400};
    background: none;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  > button {
    display: flex;
    align-items: center;
    gap: 12px;
    .checkbox {
      display: flex;
    }
    .form-check-input {
      height: 16px;
      width: 16px;
    }
  }
`;

export const StyledChipWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  overflow: auto;
  padding-bottom: 4px;
`;
export const StyledChipItem = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  border-radius: 8px;
  background-color: ${colorOtpInputBackground};
  padding: 8px 12px;
  .close-icon {
    height: 16px;
    width: 16px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 8px;
`;
