import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export default function ReferralHandler() {
  const [_, setCookie] = useCookies(['REFCODE']);
  useEffect(() => {
    const queryString = location.search.substring(1, location.search.length);
    const obj = new URLSearchParams(queryString);
    const code = obj.get('r');
    if (code) {
      const pattern = new RegExp(/^[a-zA-Z0-9_]*$/)
      if (code.length === 7 && pattern.test(code)) {
        setCookie('REFCODE', code, 60 * 60 * 24 * 30)
      }
    }
  }, [])
}