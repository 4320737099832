import { createSelector } from 'reselect';
import { NOTIFICATION_TYPE } from '@/shared/constants';

export const getNoti = (state) => state.noti;
export const selectIdNotiesRendered = createSelector(
  getNoti,
  (noti) => noti.idNotiesRendered
);
export const selectNotiLoading = createSelector(
  getNoti,
  (noti) => noti.isLoading
);

export const selectNotiCurrentTab = createSelector(
  getNoti,
  (noti) => noti.currentTab
);

export const selectNotiIsActive = createSelector(
  getNoti,
  (noti) => noti.isActive
);

export const makeGetNotiesByType = () => {
  return createSelector(
    [getNoti, (_, channel, accountType) => channel, (_, channel, accountType) => accountType],
    (noti, channel, accountType) => {
      return noti.noties.filter(
        (noti) => {
          if (channel === NOTIFICATION_TYPE.plan) {
            return noti.channel === channel && noti.account_type === accountType
          } else {
            return noti.channel === channel;
          }          
        }
      )
    }
  );
};

export const makeGetPageConfigsByType = () => {
  return createSelector(
    [getNoti, (_, channel, accountType) => channel, (_, channel, accountType) => accountType],
    (noti, channel, accountType) => {
      const pageConfigByType = noti.pageConfigs.filter(
        (config) =>
          config.channel === channel && noti.account_type === accountType
      );
      if (pageConfigByType.length) {
        return pageConfigByType[0];
      }
      return;
    }
  );
};
