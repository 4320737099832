/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import useCaptcha from '@/hooks/useCaptcha';
import authApi from '@/utils/api/authApi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import gtag from '@/shared/constants/gtag';

import ModalAccount, {
  ModalAccountDescription,
  ModalAccountTitle,
} from './ModalAccount';

import ButtonCountdown from './ButtonCountdown';
import ForgotPasswordForm from './reset_password/ForgotPasswordForm';

const ga4Stats = window.ga4Stats;

const message = {
  [true]: {
    title: 'You successfully reset password',
    description:
      '<p>A verification link has been sent to <a>{{email}}</a></p> <p>Please wait a few minutes and get verification link to reset password.',
  },
  [false]: {
    title: 'Reset your password',
    description:
      "<p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>",
  },
};

const ButtonResendMail = ({
  email,
  isDisabled,
  isLoading,
  label,
  defaultLabel,
  onSubmit,
}) => {
  const onClick = () => onSubmit({ email });
  return (
    <ButtonCountdown
      disabled={isDisabled || isLoading}
      variant="dark"
      size="lg"
      onClick={onClick}
    >
      {isDisabled ? label : defaultLabel}
    </ButtonCountdown>
  );
};
let timerId = null;
const ModalForgotPassword = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [textCountdown, setTextCountdown] = useState('00:30');
  const { execute } = useCaptcha();

  const setCountDown = () => {
    let count = 30;
    setIsDisabled(true);
    if (timerId) clearInterval(timerId);
    timerId = setInterval(() => {
      if (count) {
        count -= 1;
        setTextCountdown(`00:${count <= 9 ? 0 : ''}${count}`);
      } else {
        setIsDisabled(false);
        setTextCountdown('00:30');
        clearInterval(timerId);
        timerId = null;
      }
    }, 1000);
  };

  const handleSubmit = async ({ email }) => {
    setIsLoading(true);
    const captcha = await execute();
    const resp = await authApi.forgotPassword({
      email,
      captcha,
    });
    if (resp.ok) {
      setIsLoading(false);
      setInputEmail(email);
      setIsSuccess(true);
      setCountDown();
      ga4Stats(gtag.login.action.forgotPassword, gtag.login.category, gtag.login.label);
    } else {
      toast.error(resp.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      setIsSuccess(false);
      setTextCountdown('00:30');
    } else {
      clearInterval(timerId);
      timerId = null;
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
    };
  }, [isOpen]);

  return (
    <ModalAccount closeModal={closeModal} isOpen={isOpen}>
      <ModalAccountTitle fontsize={isSuccess ? '32px' : '24px'}>
        {t(message[isSuccess].title)}
      </ModalAccountTitle>
      <ModalAccountDescription
        dangerouslySetInnerHTML={{
          __html: t(message[isSuccess].description, { email: inputEmail }),
        }}
      />
      {isSuccess && (
        <ButtonResendMail
          email={inputEmail}
          isDisabled={isDisabled}
          isLoading={isLoading}
          label={textCountdown}
          defaultLabel={t('Request a new one')}
          onSubmit={handleSubmit}
        />
      )}
      {!isSuccess && (
        <ForgotPasswordForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          closeModal={closeModal}
        />
      )}
    </ModalAccount>
  );
};

ModalForgotPassword.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ModalForgotPassword.defaultProps = {
  isOpen: false,
};

export default ModalForgotPassword;
