import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorHover, sidebarCollapseBtnBackground, dividerColor } from '@/utils/palette';
import Icons from '@/shared/components/icons';

const TopbarSidebarButton = ({ collapse, onClickDesktop, onClickMobile }) => (
  <CollapseButton>
    <TopbarDesktopButton
      onClick={onClickDesktop}
      type="button"
    >
      <TopbarButtonIcon flip={Number(collapse)} />
    </TopbarDesktopButton>
    <TopbarMobileButton
      onClick={onClickMobile}
      type="button"
    >
      <TopbarButtonIcon flip={Number(collapse)} />
    </TopbarMobileButton>
  </CollapseButton>
);

TopbarSidebarButton.propTypes = {
  onClickDesktop: PropTypes.func.isRequired,
  onClickMobile: PropTypes.func.isRequired,
  collapse: PropTypes.bool.isRequired,
};

export default TopbarSidebarButton;

// region STYLES
const CollapseButton = styled.div`
  position: absolute;
  right: -16px;
  background: transparent;
`;
const TopbarButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid ${dividerColor};
  box-shadow: 2px 4px 24px rgba(26, 32, 44, 0.06);
  border-radius: 100%;
  background: ${sidebarCollapseBtnBackground};
  &:focus {
    outline: none;
  }

  &:hover {
    background: ${colorHover};
  }
`;

export const TopbarDesktopButton = styled(TopbarButton)`
  
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const TopbarMobileButton = styled(TopbarButton)`
  
  @media screen and (min-width: 576px) {
    display: none;
  }
`;

const TopbarButtonIcon = styled(Icons.ChevronLeftIcon)`
  margin: auto;
  // transition: all 0.3s;
  width: 16px;
  z-index: 101;
  ${props => props.flip && `
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  `}
  transition: transform 0.2s linear;
`;

// endregion
