import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { timeFromNow } from '@/shared/helpers';
import NotificationItemIcon from './NotificationItemIcon';
import PartHtmlComponent from './PartHtmlComponent';

const NotificationItem = ({ item, onDidMount, closePopup, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className="notification-item d-flex" {...props}>
      <div className="notification-item-icon d-flex justify-content-center align-items-center">
        <NotificationItemIcon type={item.type} />
        {!item.read && (
          <span className="notification-item-icon-status notification-item-icon-status--new"></span>
        )}
      </div>
      <div className="notification-item-body">
        <PartHtmlComponent rawHtml={item.data} onDidMount={onDidMount} closePopup={closePopup}  />
        <span className="notification-item-date">
          {t('{{ time }} before', {time: timeFromNow(item.created_at)})}
        </span>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.instanceOf(Object),
  onDidMount: PropTypes.func,
  closePopup: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {
  item: {},
  onDidMount: () => {},
};

export default NotificationItem;
