import React, {
  useState, forwardRef, useEffect, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ticketApi from '@/utils/api/ticketApi';
import Form from 'react-bootstrap/Form';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const CreateForm = forwardRef(({
  profile,
  updateTicketDetail,
  updateLoading,
  updateUploading,
  checkValidForm,
  updateCreatedTicket,
}, ref) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState({
    subject: false,
    email: false,
    description: true,
  });

  const [errorMes, setErrorMes] = useState('');

  const [listProblem, setListProblem] = useState([]);
  const [listProblemTitle, setListProblemTitle] = useState([]);
  const [problemName, setProblemName] = useState('');
  const [problemTitle, setProblemTitle] = useState('');
  const [hasProblemTitle, setHasProblemTitle] = useState(true);
  const [tokenFile, setTokenFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [modelData, setModelData] = useState({
    subject: null,
    message: '',
    requester: {
      name: null,
      email: '',
    },
  });

  const checkValidator = (data) => {
    const newErrors = { ...errors };
    if (data.requester.email) {
      newErrors.email = false;
    } else {
      newErrors.email = true;
    }
    const descriptionValue = data.message;
    if (!descriptionValue || descriptionValue.trim().length < 50) {
      newErrors.description = true;
      setErrorMes(t('Please insert at least 50 characters'));
    } else {
      newErrors.description = false;
    }
    setErrors(newErrors);
    checkValidForm(newErrors);
  };

  useImperativeHandle(ref, () => ({
    createTicket() {
      checkValidator(modelData);
      if (errors.subject || errors.email || errors.description) {
        return;
      }
  
      updateLoading();
      const newModelData = { ...modelData };

      newModelData.subject = problemName;
      if (hasProblemTitle) {
        newModelData.subject = `${newModelData.subject} - ${problemTitle}`;
      }

      setModelData(newModelData);

      const dataTicket = {
        request: {
          subject: newModelData.subject,
          requester: {
            name: newModelData.requester.name ? newModelData.requester.name : 'Guest',
            email: newModelData.requester.email,
          },
          comment: {
            body: newModelData.message ? newModelData.message : 'Help me!',
            uploads: [tokenFile],
          },
        },
      };
  
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
  
      ticketApi.createTicket(JSON.stringify(dataTicket), config).then((data) => {
        updateLoading();
        updateTicketDetail(data.data.request);
        updateCreatedTicket(data.data.request.requester_id);
        setTokenFile(null);
        ga4Stats(gtag.help.action.submitTicket, gtag.help.category, gtag.help.label, profile?.uid);
      });
    },
  }));

  const changeProblem = (event) => {
    setProblemName(event.target.value);

    const itemProblem = listProblem.filter(item => item.problem === event.target.value);

    setListProblemTitle(itemProblem[0].title);
    if (itemProblem[0].title.length) {
      setHasProblemTitle(true);
      setProblemTitle(itemProblem[0].title[0]);
    } else {
      setHasProblemTitle(false);
      setProblemTitle(null);
    }
  };

  const uploadFile = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileName(file.name);

    updateUploading(true);

    const config = {
      headers: {
        dataType: 'json',
        processData: false,
        'content-type': 'application/binary',
      },
    };

    ticketApi.uploadFile(file.name, file, config).then((data) => {
      updateUploading(false);
      setTokenFile(data.data.upload.token);
    });
  };

  const handleChangeMessage = (event) => {
    const newModelData = { ...modelData, message: event.target.value };
    setModelData(newModelData);
    checkValidator(newModelData);
  };

  const handleChangeEmail = (event) => {
    const newModelData = { ...modelData, requester: { ...modelData.requester, email: event.target.value } };
    setModelData(newModelData);
    checkValidator(newModelData);
  };

  useEffect(() => {
    if (profile && profile.e) {
      const newModelData = { ...modelData };
      const newErrors = { ...errors };

      newModelData.requester.name = profile.nn;
      newModelData.requester.email = profile.e;
      setModelData(newModelData);

      newErrors.email = false;
      setErrors(newErrors);
      checkValidForm(newErrors);
    }
  }, [profile]);

  useEffect(() => {
    if (listProblem.length) {
      setProblemName(listProblem[0].problem);
      setListProblemTitle(listProblem[0].title);
      setProblemTitle(listProblem[0].title[0]);
    }
  }, [listProblem]);

  // useEffect(() => {
  //   checkValidator();
  // }, [modelData]);

  useEffect(() => {
    setListProblem([
      {
        problem: 'Investment Plan',
        title: [
          'Unable to add/edit/delete plan',
          'Unable to import plan',
          'Unable to place orders with current plan',
          'Unable to start/stop plan',
          'Other plan issues',
        ],
      },
      {
        problem: 'Budget',
        title: [
          'Unable to add/edit/delete budget',
          'Unable to import budget',
          'Other budget issues',
        ],
      },
      {
        problem: 'Bot',
        title: [
          t('Unable to add/edit/delete bot'),
          t('Unable to import bot'),
          t('Other bot issues'),
        ],
      },
      {
        problem: 'Account',
        title: [
          'Register & Login',
          'Google 2FA',
          'KYC',
          'Email receiving issues',
          'Exchange Connect & Disconnect',
          'Other account issues',
        ],
      },
      {
        problem: 'Deposit',
        title: [
          'Deposit didn’t arrive',
          'Deposit to a wrong address/account',
          'Deposit wrong token',
          'Other deposit issues',
        ],
      },
      {
        problem: 'Withdrawal',
        title: [
          'Withdrawal didn’t arrive',
          'Withdrawal to a wrong address/account',
          'Withdrawal 2FA issues',
          'Other withdrawal issues',
        ],
      },
      {
        problem: 'Trading',
        title: [
          'Wrong results',
          'Error placing order',
          'Trading commission issues',
          'Other trading issues',
        ],
      },
      {
        problem: 'VIP',
        title: [
          'VIP register issues',
          'VIP commission issues',
          'Other VIP issues',
        ],
      },
      {
        problem: 'Mobile application',
        title: [
          'New features request',
          'Current features feedback',
          'Bugs report',
          'Other app issues',
        ],
      },
      {
        problem: 'Suggestions',
        title: [
          'New features request',
          'Current features feedback',
          'Bugs report',
          'Other suggestions',
        ],
      },
    ]);
  }, []);

  return (
    <div className="ticket-create-form">
        <div className="form-group mb-4">
            <label htmlFor="problem-name">1. { t('Select your issue') }</label>
            {listProblem.length && (
              <select
                id="problem-name"
                className="form-select"
                value={problemName}
                onChange={changeProblem}
              >
                {
                  listProblem.map((problemNameItem, index) => (
                    <option key={problemNameItem.problem} value={problemNameItem.problem}>
                      {t(problemNameItem.problem)}
                    </option>
                  ))
                }
              </select>
            )}
        </div>
        {hasProblemTitle && (
          <div className="form-group mb-4">
              <label htmlFor="problem-title">2. { t('Subject') }</label>
              <select
                id="problem-title"
                className="form-select"
                onChange={event => setProblemTitle(event.target.value)}
                value={problemTitle}
              >
                {listProblemTitle.map((problemTitleItem, index) => (
                  <option key={problemTitleItem} value={problemTitleItem}>
                    {t(problemTitleItem)}
                  </option>
                ))}
              </select>
          </div>
        )}
        <div className="form-group mb-4">
            <div className="d-flex justify-content-between">
                <label htmlFor="message">3. { t('Description') }</label>
                <span className="count-text">{modelData.message.length}/50</span>
            </div>
            
            <Form.Control
              as="textarea"
              rows={5}
              id="message"
              className="form-control"
              value={modelData.message}
              placeholder={t('Please specify your problem')}
              onChange={handleChangeMessage}
            />
            {(errors.description && !!errorMes.length) && <p className="form-error">{ errorMes }</p>}
        </div>
        {profile && (
          <div className="form-group d-none">
              <label htmlFor="problem-title">{ t('Email') }*</label>
              <input
                type="text"
                className="form-control"
                value={modelData.requester.email}
                onChange={handleChangeEmail}
              />
          </div>
        )}
        <div className="ticket-upload-file text-center">
            <input type="file" onChange={uploadFile} accept="image/*;capture=camera" />
            <p className="ticket-upload-file-label">{ !fileName ? t('Attach Image') : fileName }</p>
            <p className="ticket-upload-file-des">
              (<strong>{t('Note')}: </strong>{t('Maximum file size is {{size}}', { size: '10mb' })})
            </p>
        </div>
    </div>
  );
});

CreateForm.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  updateTicketDetail: PropTypes.func.isRequired,
  updateLoading: PropTypes.func.isRequired,
  updateUploading: PropTypes.func.isRequired,
  checkValidForm: PropTypes.func.isRequired,
  updateCreatedTicket: PropTypes.func.isRequired,
};

export default CreateForm;
