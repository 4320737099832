import {
  FOOTER_HEIGHT,
  MOBILE_FOOTER_HEIGHT,
  MOBILE_TOPBAR_HEIGHT,
  TOPBAR_HEIGHT,
} from '@/config/const';
import NotFound404 from '@/containers/404';
import Dashboard from '@/containers/Dashboard';
import useAuth from '@/hooks/useAuth';
import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { getBalance } from '@/redux/refactor/wallet/actions';
import Loading from '@/shared/components/Loading';
import AlertMaintenance from '@/shared/components/maintenance/AlertMaintenance';
import { paddingLeft, paddingRight } from '@/utils/directions';
import { Devices, colorBackgroundGrey } from '@/utils/palette';
import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import styled from 'styled-components';
import Layout from '../../../Layout/index';

const Portfolio = React.lazy(() => import('@/containers/Portfolio/index'));
const DetailPlan = React.lazy(() => import('@/containers/Portfolio/DetailPage'));
const ManualPlan = React.lazy(() => import('@/containers/Portfolio/ManualPlan'));
const Strategy = React.lazy(() => import('@/containers/Strategy/index'));
const Bot = React.lazy(() => import('@/containers/Bot/index'));
const DetailBot = React.lazy(() => import('@/containers/Bot/DetailPage/index'));
const Affiliation = React.lazy(() => import('@/containers/Affiliation/index'));
const Settings = React.lazy(() => import('@/containers/Settings'));
const Referral = React.lazy(() => import('@/containers/Referral'));
const ManageFollwer = React.lazy(() => import('@/containers/ManageFollower'));

export default () => {
  const dispatch = useDispatch();
  const { isAuth } = useAuth();
  const accountType = useSelector((state) => state.wallet.accountType, _.isEqual);

  useEffect(() => {
    if (isAuth) {
      // dispatch(getNotifyPlans());
      // dispatch(getLastMessages());
      dispatch(getBalance());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (isAuth && accountType) {
      dispatch(getTotalPlan(accountType));
    }
  }, [isAuth, dispatch, accountType]);

  return (
    <div>
      <Layout />
      <ContainerWrap>
        <AlertMaintenance />
        <ContainerStyle>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/:tab" component={Settings} />
              <Route exact path="/portfolios" component={Portfolio} />
              <Route exact path="/portfolios/statistic" component={Portfolio} />
              <Route exact path="/portfolios/schedule" component={Portfolio} />
              <Route exact path="/portfolios/:planId" component={DetailPlan} />
              <Route exact path="/budget-strategies" component={Strategy} />
              <Route exact path="/signal-strategies" component={Bot} />
              <Route exact path="/signal-strategies/top-signal" component={Bot} />
              <Route exact path="/signal-strategies/telegram-channel" component={Bot} />
              <Route exact path="/signal-strategies/community-bot" component={Bot} />
              <Route exact path="/signal-strategies/:planId" component={DetailBot} />
              <Route exact path="/affiliation" component={Affiliation} />
              <Route exact path="/manual-trade" component={ManualPlan} />
              <Route exact path="/referral" component={Referral} />
              <Route exact path="/manage-follower" component={ManageFollwer} />
              <Route exact path="/404" component={NotFound404} />
              <Route component={NotFound404} />
            </Switch>
          </Suspense>
        </ContainerStyle>
      </ContainerWrap>
      {/* <ButtonNotification />
      <MessageBox />
      <MessageDrawer />
      <RefreshTokenHandler /> */}
    </div>
  );
};

// region STYLES

const ContainerWrap = styled.div`
  padding-top: calc(${MOBILE_TOPBAR_HEIGHT}px);
  padding-bottom: ${MOBILE_FOOTER_HEIGHT}px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  ${paddingLeft}: 0;
  background: ${colorBackgroundGrey};
  @media ${Devices.laptop} {
    padding-top: calc(${TOPBAR_HEIGHT}px);
    padding-bottom: 24px;
    ${paddingLeft}: 250px;
  }
`;

const ContainerStyle = styled(Container)`
  overflow-x: hidden;
  @media ${Devices.laptop} {
    padding-top: 32px;
    /* padding-bottom: ${FOOTER_HEIGHT}px; */
    ${paddingLeft}: 48px;
    ${paddingRight}: 48px;
  }
`;

// endregion
