import {
  get,
  put,
  post,
  deleteRequest,
} from './base';

// signal_strategy_not_found 			Signal Strategy is not found
// signal_strategy_expert_invalid		There is one or more expert invalid
// signal_strategy_expert_self_follow	Your Signal Strategy can't follow yourself
// signal_strategy_type_invalid		Signal type is invalid
// signal_strategy_source_invalid		There is one or more source invalid

export default {
  /**
   * 
   * @param {Object} params 
   * @param {number} params.page
   * @param {number} params.size
   * @param {string} params.search
   * @returns 
   */
  getBots: (params) => {
    // check params.isTopBotList
    
    let url = `/plans/signal-strategies${params.isTopBotList ? '/topv2' : ''}?page=${params?.page}&size=${params?.size}`;
    if (params.search) {
      url = `${url}&${params.isTopBotList ? 'text' : 'search'}=${params.search}`;
    }

    if (params.sort) {
      url = `${url}&sort=${params.sort}`;
    }

    return get(url);
  },
  createBot: params => post('/plans/signal-strategies', params),
  editBot: params => put(`/plans/signal-strategies/${params.id}`, params),
  deleteBot: (id, optionDeleteAllPlan) => deleteRequest(`/plans/signal-strategies/${id}?isDeletePlans=${optionDeleteAllPlan}`),
  importBot: code => post(`/plans/signal-strategies/copy-code/${encodeURIComponent(code)}`),
  getMethods: type => get(`/plans/Shared/${type}`),
  renewCode: id => get(`/plans/signal-strategies/${id}/copy-code`),
  getSignalSources: (type, showStatsDetail, isCommunity) => {
    if (isCommunity) {
      return get(`/qi/community-signals?page=1&size=999999`)
    }

    return get(`/plans/signal-sources/${type}?showStatsDetail=${showStatsDetail}`)
  },
  getCommunityBotMessages: (communitySignalId, lastId) => get(`/qi/community-signals/${communitySignalId}/messages${lastId ? '?lastId=' + lastId : ''}`),
  checkPlanExistUsingBot: id => get(`/plans/signal-strategies/${id}/validate`),
  pinTopSignal: (planId, isPin) => post(`/plans/signal-strategies/top/pin?planId=${planId}&isPin=${isPin}`),
  getStatistic: planId => get(`/plans/signal-strategies/top/${planId}`),
  getStatisticStreaks: planId => get(`/plans/signal-strategies/top/streaks/${planId}`),
};
