import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { timeFormat } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import styled from 'styled-components';
import { colorBorder, colorText } from '@/utils/palette';
import { useTranslation } from 'react-i18next';
import TransactionIcon from './TransactionIcon';

const getType = translate => type => translate(type);

const TransactionItem = memo(({ value, onClick }) => {
  const { t } = useTranslation();
  const handleClick = () => onClick(value);
  return (
    <TransactionItemWrap onClick={handleClick}>
      <TransactionIcon type={value.type} />
      <Box flex={1}>
        <TransactionType>{getType(t)(value?.type)}</TransactionType>
        <TransactionTime>{timeFormat(value?.ts)}</TransactionTime>
      </Box>
      <TransactionItemAmount>
        {formatNumber.formatCurrency(value?.amount)}
      </TransactionItemAmount>
    </TransactionItemWrap>
); 
});

TransactionItem.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
};

TransactionItem.defaultProps = {
  onClick: () => {},
};

const TransactionItemWrap = styled(Box)`
  display: flex;
  column-gap: 24px;
  border-bottom: 1px solid ${colorBorder};
  padding: 12px 4px 12px 0;
  &:hover {
    cursor: pointer;
  }
  img {
    margin-top: 5px;
  }
  &:last-child {
    border: none
  }
`;
const TransactionItemAmount = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: right;
  color: ${colorText};
`;

const TransactionType = styled(Box)`
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: ${colorText};
`;
const TransactionTime = styled(Box)`
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #747A80;
`;

export default TransactionItem;
