import useQuery from '@/hooks/useQuery';
import useWindowSize from '@/hooks/useWindowSize';
import {
  changeScreenSize,
  changeThemeToDark,
  changeThemeToLight,
} from '@/redux/refactor/theme/actions';
import ModalKickOut from '@/shared/components/ModalKickOut';
import WindowResizeHandler from '@/shared/components/WindowResizeHandler';
import ModalMaintenance from '@/shared/components/maintenance/ModalMaintenance';
import { colorGreyScale700, colorGreyScale900 } from '@/utils/palette';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConnectedRouter } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import '../../assets/sass/index.scss';
import i18n from '../../translations/index';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import CustomStyle from './customStyle';
import GlobalStyles from './globalStyles';
import history from './history';
import RechartStyles from './rechartStyles';
import store from './store';
import { USER_TOKEN } from '@/config/const';
import gtag from '@/shared/constants/gtag';

import { IS_PROD } from '@/config/const';
import LanguageDetector from '@/shared/components/LanguageDetector';
import ReferralHandler from '@/shared/components/ReferralHandler';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PushNotification from '../../shared/components/PushNotification';
import useAuth from '@/hooks/useAuth';
import GlobalPopup from '@/shared/components/GlobalPopup';
import RefreshTokenHandler from '@/shared/components/RefreshTokenHandler';

const ga4Stats = window.ga4Stats;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

i18n.init();

const skeletonProps = {
  light: {},
  dark: {
    baseColor: colorGreyScale700,
    highlightColor: colorGreyScale900,
  },
};

const ThemeComponent = ({ children }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const gridSize = useWindowSize();
  const { isAuth, logout } = useAuth();

  const { mode, direction, shadow, border, sidebar, profile } = useSelector(
    (state) => ({
      mode: state.theme.className,
      direction: state.rtl.direction,
      shadow: state.shadow.className,
      border: state.border.className,
      sidebar: state.sidebar,
      profile: state.user.profile,
    }),
    _.isEqual
  );

  const theme = createTheme({
    palette: {
      type: mode,
    },
    typography: {
      fontFamily: 'Manrope,Roboto,sans-serif',
    },
  });

  const themeConfig = {
    mode,
    direction,
    shadow,
    border,
  };

  useEffect(() => {
    if (mode) {
      const prevMode = mode === 'light' ? 'dark' : 'light';
      document.body.classList.remove(prevMode);
      document.body.classList.add(mode);
    }
  }, [mode]);

  useEffect(() => {
    sidebar.collapse
      ? document.body.classList.add('collapsed')
      : document.body.classList.remove('collapsed');
  }, [sidebar.collapse]);

  useEffect(() => {
    if (!query.get('theme')) {
      return;
    }

    if (query.get('theme') === 'dark') {
      ga4Stats(
        gtag.darkMode.action.darkModeOn,
        gtag.darkMode.category,
        gtag.darkMode.label,
        profile?.uid
      );
      dispatch(changeThemeToDark());
    } else {
      ga4Stats(
        gtag.darkMode.action.darkModeOff,
        gtag.darkMode.category,
        gtag.darkMode.label,
        profile?.uid
      );
      dispatch(changeThemeToLight());
    }
  }, [query.get('theme')]);

  useEffect(() => {
    const screenSize = {
      isPc: false,
      isTablet: false,
      isMobile: false,
    };
    if (gridSize && ['xl', 'xxl'].includes(gridSize)) {
      screenSize.isPc = true;
    } else if (gridSize && ['md', 'lg'].includes(gridSize)) {
      screenSize.isTablet = true;
    } else if (gridSize && ['xs', 'sm'].includes(gridSize)) {
      screenSize.isMobile = true;
    }
    dispatch(changeScreenSize(screenSize));
  }, [gridSize, isAuth]);

  useEffect(() => {
    if (!isAuth) {
      queryClient.clear();
    }
  }, [isAuth]);

  useEffect(() => {
    window.addEventListener('storage', function (event) {
      if (event.key === USER_TOKEN && !event.newValue) {
        logout();
      }
    });
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={themeConfig}>
        <SkeletonTheme {...skeletonProps[mode]}>
          <GlobalStyles />
          <RechartStyles />
          {children}
          <CustomStyle />
          <ModalKickOut />
          <GlobalPopup />
          <ModalMaintenance />
          <ToastContainer theme={mode} hideProgressBar newestOnTop autoClose={3000} />
        </SkeletonTheme>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

const recaptchaProps = {
  reCaptchaKey: process.env.REACT_APP_RECATPCHA_KEY,
  useRecaptchaNet: true,
  scriptProps: { async: true, defer: true, appendTo: 'body' },
};

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider {...recaptchaProps}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              <ConnectedThemeComponent>
                <ScrollToTop>
                  <Router />
                </ScrollToTop>
              </ConnectedThemeComponent>
              <WindowResizeHandler />
              <LanguageDetector />
              <ReferralHandler />
              <RefreshTokenHandler />
            </I18nextProvider>
          </BrowserRouter>
        </ConnectedRouter>
      </GoogleReCaptchaProvider>
      {!IS_PROD && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
    <PushNotification />
  </Provider>
);
export default App;
