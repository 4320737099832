import _ from 'lodash';
export const mutators = {
  setSubProfit: (args, state, utils) => {
    utils.changeValue(state, 'sub_take_profit', () => args[0]);
  },
  setSubStopLoss: (args, state, utils) => {
    utils.changeValue(state, 'sub_stop_loss', () => args[0]);
  },
  setShowSubProfit: (args, state, utils) => {
    utils.changeValue(state, 'showSubProfit', () => args[0]);
  },
};
