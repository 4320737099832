import EyeOnIcon from './eye-on.svg';
import EyeOffIcon from './eye-off.svg';
import CloseIcon from './close.svg';
import ChevronLeftIcon from './chevron-left.svg';
import HomeIcon from './home.svg';
import HomeOutlineIcon from './home-outline.svg';
import BriefCaseIcon from './briefcase.svg';
import BriefCaseOutlineIcon from './briefcase-outline.svg';
import TradeIcon from './trade.svg';
import TradeOutlineIcon from './trade-outline.svg';
import PresentationChartIcon from './presention-chart.svg';
import PresentationChartOutlineIcon from './presention-chart-outline.svg';
import SettingIcon from './setting.svg';
import SettingOutlineIcon from './setting-outline.svg';
import QuestionCircleIcon from './question-circle.svg';
import QuestionCircleOutlineIcon from './question-circle-outline.svg';
import LogoutIcon from './logout.svg';
import LogoutOutlineIcon from './logout-outline.svg';
import GlobalIcon from './global.svg';
import WalletIcon from './wallet.svg';
import WalletAddIcon from './wallet-add.svg';
import WalletMinusIcon from './wallet-minus.svg';
import WalletMoneyIcon from './wallet-money.svg';
import RefreshOutlineIcon from './refresh-outline.svg';
import ChartIcon from './chart.svg';
import ThreeDotIcon from './threedot.svg';
import AlertCircleIcon from './alert-circle.svg';
import CheckedIcon from './checked.svg';
import EditIcon from './edit.svg';
import PauseIcon from './pause.svg';
import ResetIcon from './reset.svg';
import SendIcon from './send.svg';
import TrashIcon from './trash.svg';
import PlayIcon from './play.svg';
import PlusIcon from './plus.svg';
import TrendUpIcon from './trend-up.svg';
import TrendDownIcon from './trend-down.svg';
import AddFilledIcon from './add-filled.svg';
import CloudUploadIcon from './cloud-upload.svg';
import CheckOutlineIcon from './check-outline.svg';
import SlashIcon from './slash.svg';

export default {
    EyeOnIcon,
    EyeOffIcon,
    CloseIcon,
    ChevronLeftIcon,
    HomeIcon,
    BriefCaseIcon,
    TradeIcon,
    PresentationChartIcon,
    HomeOutlineIcon,
    BriefCaseOutlineIcon,
    TradeOutlineIcon,
    PresentationChartOutlineIcon,
    SettingIcon,
    SettingOutlineIcon,
    QuestionCircleIcon,
    QuestionCircleOutlineIcon,
    LogoutIcon,
    LogoutOutlineIcon,
    GlobalIcon,
    WalletIcon,
    WalletAddIcon,
    WalletMinusIcon,
    WalletMoneyIcon,
    RefreshOutlineIcon,
    ChartIcon,
    ThreeDotIcon,
    AlertCircleIcon,
    CheckedIcon,
    EditIcon,
    PauseIcon,
    ResetIcon,
    SendIcon,
    TrashIcon,
    PlayIcon,
    PlusIcon,
    TrendUpIcon,
    TrendDownIcon,
    AddFilledIcon,
    CloudUploadIcon,
    CheckOutlineIcon,
    SlashIcon,
};
