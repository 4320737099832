import { TRANSACTION_TYPE } from '@/config/const';

export class Transaction {
  ts = 0

  type = ''

  amount = 0.0

  txid = ''

  network = ''

  memo = ''

  status = ''
}

export const TransactionType = TRANSACTION_TYPE;
