/* eslint-disable react/prop-types */
import { isEqual } from 'lodash';
import { WALLET_TYPE } from '@/config/const';
import { reloadDemoBalance } from '@/redux/refactor/wallet/actions';
import {
  CardDivider,
  CardGradient,
  CardGradientItem,
  CardGradientItemLabel,
  CardGradientItemValue,
} from '@/shared/components/Card';
import formatNumber from '@/shared/helpers/formatNumber';
import { Box } from '@material-ui/core';
import {
  ArrangeHorizontal,
  RepeateMusic,
  WalletAdd1,
  WalletMinus,
} from 'iconsax-react';
import React from 'react';
import { Col, Image, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const CardBalanceInfo = ({ handleWithdraw, handleDeposit, handleTransfer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { profileTool, accountType, balance, logoExchange, profileExchange = {}, demoReloading } = useSelector(
    (state) => ({
      profileTool: state.user.profile,
      accountType: state.wallet.accountType,
      balance: state.wallet.balance,
      demoReloading: state.wallet.demoReloading,
      logoExchange: state.exchange.logo,
      profileExchange: state.exchange.profile,
    }),
    isEqual
  );

  const handleReloadDemo = () => {
    dispatch(reloadDemoBalance());
    ga4Stats(gtag.wallet.action.refillDemo, gtag.wallet.category, gtag.wallet.label, profileTool.uid);
  };

  return (
    <Box>
      <CardGradient accountype={accountType}>
        <ImageStyle src={logoExchange} />
        <CardGradientItem>
          <CardGradientItemValue fontSize="18px" fontWeight="700">
            <TruncateText>{profileExchange?.e}</TruncateText>
          </CardGradientItemValue>
          <CardGradientItemLabel>{t('Email account')}</CardGradientItemLabel>
        </CardGradientItem>
        <CardDividerStyle />
        <Row>
          {accountType === WALLET_TYPE.LIVE ? (
            <>
              <Col>
                <CardGradientItem>
                  <CardGradientItemValue>
                    {formatNumber.formatCurrency(balance.usdtAvailableBalance)}
                  </CardGradientItemValue>
                  <CardGradientItemLabel>
                    {t('USDT Wallet')}
                  </CardGradientItemLabel>
                </CardGradientItem>
              </Col>
              <Col>
                <CardGradientItem>
                  <CardGradientItemValue>
                    {formatNumber.formatCurrency(balance.availableBalance)}
                  </CardGradientItemValue>
                  <CardGradientItemLabel>
                    {t('Live Wallet')}
                  </CardGradientItemLabel>
                </CardGradientItem>
              </Col>
            </>
          ) : (
            <Col>
              <CardGradientItem>
                <CardGradientItemValue>
                  {formatNumber.formatCurrency(balance.demoBalance)}
                </CardGradientItemValue>
                <CardGradientItemLabel>
                  {t('Demo Wallet')}
                </CardGradientItemLabel>
              </CardGradientItem>
            </Col>
          )}
        </Row>
      </CardGradient>
      <Box mt="24px" display="flex" justifyContent="space-around">
        {accountType === WALLET_TYPE.LIVE ? (
          <>
            <ButtonItem onClick={handleWithdraw}>
              <ButtonStyle variant="outline">
                <WalletMinus size={18} />
              </ButtonStyle>
              {t('Withdraw')}
            </ButtonItem>
            <ButtonItem onClick={handleDeposit}>
              <ButtonStyle variant="outline">
                <WalletAdd1 size={18} />
              </ButtonStyle>
              {t('Deposit')}
            </ButtonItem>
            <ButtonItem onClick={handleTransfer}>
              <ButtonStyle variant="outline">
                <ArrangeHorizontal size={18} />
              </ButtonStyle>
              {t('Transfer')}
            </ButtonItem>
          </>
        ) : (
          <ButtonItem onClick={handleReloadDemo}>
            <ButtonStyle variant="outline">
              {demoReloading ? (
                <Spinner animation="border" variant="secondary" size="sm" />
              ) : (
                <RepeateMusic size={18} />
              )}
            </ButtonStyle>
            {t('Refill Balance')}
          </ButtonItem>
        )}
      </Box>
    </Box>
  );
};

const CardDividerStyle = styled(CardDivider)`
  margin: 12px 0;
  opacity: 0.2;
  border: 1.11194px solid rgba(255, 255, 255, 0.2);
`;

const TruncateText = styled(Box)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ImageStyle = styled(Image)`
  height: 24px;
  padding: 4px 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 17px;
  margin-bottom: 14px;
`;

const ButtonItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  gap: 4px;
  cursor: pointer;
`;

const ButtonStyle = styled(Box)`
  width: 44px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${theme('mode', {
      light: 'rgba(207, 219, 213, 0.7)',
      dark: '#323B49',
    })};
  border-radius: 8px;
`;

export default CardBalanceInfo;
