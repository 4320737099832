import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SuccessImg from '@/shared/img/registerSuccess.svg';
import { AccountButton, AccountTitle } from '@/shared/components/account/AccountElements';
import { ModalAccountDescription } from '@/shared/components/account/ModalAccount';
import { Link, useHistory } from 'react-router-dom';

let seconds = 10;
const CardResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const [text, setText] = useState('10s');
  const history = useHistory();
  useEffect(() => {
    const timerId = setInterval(() => {
      if (seconds > 0) {
        seconds -= 1;
        setText(`${seconds < 10 ? '0' : ''}${seconds}s`);
      } else {
        clearInterval(timerId);
        history.push('/login');
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);
  return (
  <div className="d-flex flex-column align-items-center text-center">
    <Box marginBottom="32px"><SuccessImg /></Box>
    <Box marginBottom="16px">
      <AccountTitle>{t(
        'ResetPassword.successTitle',
        'Request a new password successfully!',
      )}
      </AccountTitle>
    </Box>
    <ModalAccountDescription
      dangerouslySetInnerHTML={{
      __html: t(
        'ResetPassword.successDescription',
        'Your account has been reset password.',
      ),
    }}
    />
    <Link className="full-width" to="/login">
      <AccountButton size="lg" variant="success">
        {t('Back to Login')} ({text})
      </AccountButton>
    </Link>
  </div>
); 
};

export default CardResetPasswordSuccess;
