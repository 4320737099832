import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { paddingLeft } from '@/utils/directions';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '@/redux/refactor/sidebar/actions';
import {
  changeThemeToDark,
  changeThemeToLight,
} from '@/redux/refactor/theme/actions';
import { SIDEBAR_COLLAPSED_WIDTH } from '@/config/const';
import _ from 'lodash';
import { Devices } from '@/utils/palette';
import Topbar from './topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import BottomBar from './bottombar/BottomBar';
import gtag from '@/shared/constants/gtag';
const ga4Stats = window.ga4Stats;

const Layout = () => {
  const { isAuth } = useSelector(state => state.user, _.isEqual);
  const { sidebar, showHeader, showFooter, profile } = useSelector(state => ({
    sidebar: state.sidebar,
    showHeader: state.theme.showHeader,
    showFooter: state.theme.showFooter,
    profile: state.user.profile,
  }), _.isEqual);

  const dispatch = useDispatch();

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    ga4Stats(gtag.darkMode.action.darkModeOn, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    ga4Stats(gtag.darkMode.action.darkModeOff, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
    dispatch(changeThemeToLight());
  };

  if (!isAuth) return <Redirect to="/login" />;

  return (
    <LayoutContainer collapse={sidebar.collapse}>
      <Sidebar
        sidebar={sidebar}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
        topNavigation={false}
      />
      <Topbar collapsed={sidebar.collapse} showOnMobile={showHeader} />
      {/* <Footer collapsed={sidebar.collapse} /> */}
      {showFooter && <BottomBar /> }
    </LayoutContainer>
  );
};

export default withRouter(Layout);

// region STYLES

const LayoutContainer = styled.div`
  & + div {
    @media ${Devices.laptop} {
      ${props => props.collapse
        && `
        ${paddingLeft(props)}: ${SIDEBAR_COLLAPSED_WIDTH}px;
      `}

      ${props => props.topNavigation
        && `
        ${paddingLeft(props)}: 0;
      `}
    }
  }
`;

// endregion
