/* eslint-disable react/prop-types */
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PlanBudget from './PlanBudget';
import PlanName from './PlanName';

const FormBaseInfo = ({ isEdit }) => {
  const { t } = useTranslation();
  const { values = {} } = useFormState();
  const currentAccountType = useSelector(
    (state) => state.wallet.accountType,
    isEqual
  );
  const accountType = isEdit ? values?.account_type : currentAccountType;

  return (
    <Box>
      <Form.Headline>
        {t('Step 1: Plan profile')}
        <BadgeStyle type={accountType}>{accountType}</BadgeStyle>
      </Form.Headline>
      <Grid>
        <Grid.Row cols={2}>
          <Grid.Cell>
            <PlanName isEdit={isEdit} />
          </Grid.Cell>
          <Grid.Cell>
            <PlanBudget />
          </Grid.Cell>
        </Grid.Row>
      </Grid>
    </Box>
  );
};

const BadgeStyle = styled(Badge)`
  margin-left: 8px;
  font-weight: 700;
  font: 14px;
  padding: 6.424px 9.636px;
  background: ${(props) =>
    props.type === 'LIVE' ? '#e7f7ef ' : '#f5eafb'}!important;
  color: ${(props) =>
    props.type === 'LIVE' ? '#0caf60 ' : '#8130a9'}!important;
`;

export default FormBaseInfo;
