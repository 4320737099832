import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { updateUserConfig } from '@/redux/refactor/appConfig/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserConfig } from '@/redux/refactor/appConfig/selector';

const NotificationSettingItem = ({setting, label, text, name}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userConfigs = useSelector(selectUserConfig);
  const { profile } = useSelector(
    (state) => ({
      profile: state.user.profile
    }),
    isEqual
  );
  
  const handleChangeSetting = (e) => {
    dispatch(updateUserConfig(name, e.target.checked, profile?.uid));
  }

  return (
    <li className="notification-setting-item">
      <Form.Group className="form-group-switch full-width">
        <div className="form-group-switch-content">
          <Form.Label className="mb-1">
            {t(label)}
          </Form.Label>
          <Form.Text>
            {t(text)}
          </Form.Text>
        </div>

        <Form.Check
          type="switch"
          id="timer-stop"
          checked={setting !== undefined ? setting : true}
          disabled={userConfigs.is_fetching}
          onChange={handleChangeSetting}
        />
      </Form.Group>
    </li>
  )
};

NotificationSettingItem.propTypes = {
  settting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

NotificationSettingItem.defaultProps = {
  settting: true,
}

export default NotificationSettingItem;
