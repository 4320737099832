import { get, put } from './base';
import { getQueryString } from './base/axios';

export default {
  getNoties: (params) => {
    return get(`/plans/notification?${getQueryString(params)}`);
  },
  updateReadNoti: (id) => put(`/plans/notification/${id}/read`),
  updateReadNoties: (notification_ids) => put(`/plans/notification/reads`, {notification_ids}),
};
