import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

const PaginateCustom = ({
  hasSizeOption,
  size,
  page,
  handleChangePageSize,
  handleChangePage,
  pageCount,
}) => {
  const { t } = useTranslation();
  if (pageCount === 0) return null;
  return (
    <div className="pagination-combine d-flex">
      {hasSizeOption && (
        <div className="d-flex align-items-center number-result">
          <div className="label">{t('Show result:')}</div>
          <Form.Select
            className="ms-1"
            value={size}
            onChange={handleChangePageSize}
          >
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="24">24</option>
          </Form.Select>
        </div>
      )}
      <ReactPaginate
        className="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        nextClassName="nextClassName"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handleChangePage}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        forcePage={page}
      />
    </div>
  );
};

PaginateCustom.propTypes = {
  hasSizeOption: PropTypes.bool,
  size: PropTypes.number,
  page: PropTypes.number,
  handleChangePageSize: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

PaginateCustom.defaultProps = {
  hasSizeOption: true,
  size: 0,
  page: 0,
  handleChangePageSize: () => {},
};

export default PaginateCustom;
