import {
  PAGINATION_DEFAULT,
  TYPE_ACCOUNT,
  TYPE_PLAN,
  FORM_TYPE,
} from '@/shared/constants';
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  updateAccountType,
  updateBacktestDataChart,
  updateBacktestId,
  updateBacktestPrevHistory,
  updateBacktestProgress,
  updateBacktestMoreConditions,
  updateBetSession,
  updateCandles,
  updateCountdownTimer,
  updateCurrentSession,
  updateFollwers,
  updateFollwersIsFetching,
  updateFollwersPagination,
  updateIsFetching,
  updateListPlanLoading,
  updateOrderResult,
  updatePageConfig,
  updatePagination,
  updatePlan,
  updatePlans,
  updateSelectedPlans,
  updateCurrentPlan,
  updateCurrentOrder,
  updateCurrentCondition,
  updateConditions,
  updateConditionIsLoading,
  updateConditionsShowFormContion,
  updateConditionsFormTypeContion,
  updateIsEditReorderConditions,
  updateIsSaveReorderConditions,
  updateWaitSignal,
  updateDisplayReport,
  updateManualTradeOrderSessionReady
} from '../actions/planActions';

const defaultState = {
  plans: [],
  selectedPlans: [],
  currentPlan: {},
  currentIds: [],
  plan: {},
  pagination: {},
  filterType: TYPE_PLAN.all,
  accountTypePlan: TYPE_ACCOUNT.live,
  isFetching: true,
  listPlanLoading: true,
  pageConfig: {
    ...PAGINATION_DEFAULT,
    filter_type: TYPE_PLAN.all,
    account_type: TYPE_ACCOUNT.live,
    search: '',
    sort: '',
    sort_field: '',
  },
  countdownTimer: 0,
  isBetSession: true,
  currentSession: '',
  orderResult: '',
  backtestDataChart: [],
  backtestProgress: 1,
  backtestId: '',
  backtestPrevHistory: false,
  backtestMoreConditions: {},
  candles: [],
  follower: {
    followers: [],
    pagination: {},
    sFetching: true,
  },
  currentOrder: {},
  conditions: [],
  currentCondition: {},
  isLoadingCondition: false,
  formCondition: {
    type: FORM_TYPE.create,
    isShow: false,
  },
  isEditReorderConditions: false,
  isSaveReorderConditions: false,
  waitSignal: {},
  displayReport: {
    isShowProfit: true,
    isShowVolume: true
  },
  manualTradeOrderSessionReady: 0
};

export default handleActions(
  {
    [updatePlans](state, { payload = [] }) {
      const currentIds = payload.reduce((acc, item) => [...acc, item.id], []);
      return {
        ...state,
        plans: payload,
        currentIds,
      };
    },
    [updatePlan](state, { payload }) {
      return {
        ...state,
        plan: payload,
      };
    },
    [updatePagination](state, { payload }) {
      return {
        ...state,
        pagination: payload,
      };
    },
    [updateIsFetching]: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    [updateAccountType]: (state, { payload }) => ({
      ...state,
      accountTypePlan: payload,
    }),
    [updatePageConfig]: (state, { payload }) => ({
      ...state,
      pageConfig: payload,
    }),
    [updateCountdownTimer]: (state, { payload }) => ({
      ...state,
      countdownTimer:
        payload.session >= state.currentSession
          ? payload.order
          : payload.countdownTimer,
    }),
    [updateBetSession]: (state, { payload }) => ({
      ...state,
      isBetSession:
        payload.session >= state.currentSession
          ? payload.session % 2 === 1
          : payload.isBetSession,
    }),
    [updateCurrentSession]: (state, { payload }) => ({
      ...state,
      currentSession:
        payload.session >= state.currentSession
          ? payload.session
          : state.currentSession,
    }),
    [updateOrderResult]: (state, { payload }) => ({
      ...state,
      orderResult: payload,
    }),
    [updateBacktestDataChart]: (state, { payload }) => {
      if (
        payload.length &&
        state.backtestId.toString() !== payload[payload.length - 1].btId
      ) {
        return state;
      }
      if (state.backtestDataChart.length && payload.length) {
        const lastBacktestId =
          state.backtestDataChart[state.backtestDataChart.length - 1].btId;
        const newBacktestId = payload[payload.length - 1].btId;
        if (lastBacktestId !== newBacktestId) {
          return {
            ...state,
            backtestDataChart: payload,
          };
        }
        const newBacktestDataChart = [...state.backtestDataChart, ...payload];

        return {
          ...state,
          backtestDataChart: _.uniqBy(newBacktestDataChart, 'timestamp'),
        };
      }
      return {
        ...state,
        backtestDataChart: payload,
      };
    },
    [updateBacktestProgress]: (state, { payload }) => ({
      ...state,
      backtestProgress: payload,
    }),
    [updateBacktestMoreConditions]: (state, { payload }) => ({
      ...state,
      backtestMoreConditions: payload,
    }),    
    [updateBacktestId]: (state, { payload }) => ({
      ...state,
      backtestId: payload,
    }),
    [updateBacktestPrevHistory]: (state, { payload }) => ({
      ...state,
      backtestPrevHistory: payload,
    }),
    [updateCandles]: (state, { payload }) => {
      return {
        ...state,
        candles: payload,
      }
    },
    [updateFollwers](state, { payload }) {
      return {
        ...state,
        follower: {
          ...state.follower,
          followers: payload,
        },
      };
    },
    [updateFollwersPagination](state, { payload }) {
      return {
        ...state,
        follower: {
          ...state.follower,
          pagination: payload,
        },
      };
    },
    [updateFollwersIsFetching]: (state, { payload }) => ({
      ...state,
      follower: {
        ...state.follower,
        isFetching: payload,
      },
    }),
    [updateListPlanLoading]: (state, { payload }) => ({
      ...state,
      listPlanLoading: payload,
    }),
    [updateSelectedPlans]: (state, { payload }) => ({
      ...state,
      selectedPlans: payload,
    }),
    [updateCurrentPlan]: (state, { payload }) => ({
      ...state,
      currentPlan: payload,
    }),
    [updateCurrentOrder]: (state, { payload }) => ({
      ...state,
      currentOrder: payload,
    }),
    [updateCurrentCondition]: (state, { payload }) => ({
      ...state,
      currentCondition: payload,
    }),
    [updateConditions]: (state, { payload }) => ({
      ...state,
      conditions: payload,
    }),
    [updateConditionIsLoading]: (state, { payload }) => ({
      ...state,
      isLoadingCondition: payload,
    }),
    [updateConditionsShowFormContion]: (state, { payload }) => ({
      ...state,
      formCondition: {...state.formCondition, isShow: payload},
    }),
    [updateConditionsFormTypeContion]: (state, { payload }) => ({
      ...state,
      formCondition: {...state.formCondition, type: payload},
    }),
    [updateIsEditReorderConditions]: (state, { payload }) => ({
      ...state,
      isEditReorderConditions: payload,
    }),
    [updateIsSaveReorderConditions]: (state, { payload }) => ({
      ...state,
      isSaveReorderConditions: payload,
    }),
    [updateWaitSignal]: (state, { payload }) => {
      if (payload?.plan_id == state?.plan?.id) {
        return {
          ...state,
          waitSignal: payload,
        }
      }
      return state;
    },
    [updateDisplayReport]: (state, { payload }) => {
      return {
        ...state,
        displayReport: payload,
      }
    },
    [updateManualTradeOrderSessionReady]: (state, { payload }) => ({
      ...state,
      manualTradeOrderSessionReady: payload,
    }),
  },
  defaultState
);
  