import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
  TOPBAR_HEIGHT,
} from '@/config/const';
import Logo from '@/shared/components/Logo';
import Scrollbar from '@/shared/components/ScrollBar';
import { marginRight, sidebarClose } from '@/utils/directions';
import { Devices, dividerColor, sidebarBackground } from '@/utils/palette';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TopbarSidebarButton from '../topbar/TopbarSidebarButton';
import SidebarContent from './SidebarContent';

const Sidebar = ({
  changeToDark,
  changeToLight,
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  sidebar,
  topNavigation,
}) => (
  <SidebarWrap
    show={sidebar.show}
    topNavigation={topNavigation}
    collapse={sidebar.collapse}
    className="sidebar-wrap"
  >
    <SidebarLogo>
      <Link to="/">
        <LogoStyle short={Number(sidebar.collapse)} width={153} height={33} />
      </Link>
      <TopbarSidebarButton
        collapse={sidebar.collapse}
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeSidebarVisibility}
      />
    </SidebarLogo>

    <SidebarScroll>
      <SidebarWrapper topNavigation={topNavigation}>
        <SidebarContentStyle
          onClick={changeMobileSidebarVisibility}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          collapse={sidebar.collapse}
        />
      </SidebarWrapper>
    </SidebarScroll>
  </SidebarWrap>
);

Sidebar.propTypes = {
  sidebar: PropTypes.shape({
    show: PropTypes.bool,
    collapse: PropTypes.bool,
  }).isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  topNavigation: PropTypes.bool.isRequired,
};

export default Sidebar;

// region STYLES

const SidebarWrapper = styled.div`
  display: block;
  height: 100%;
  @media screen and (min-width: 576px) {
    display: ${(props) => (props.topNavigation ? 'none' : 'block')};
  }
`;

const SidebarScroll = styled(Scrollbar)`
  width: ${SIDEBAR_WIDTH}px;
  height: 100%;
  & > div {
    height: calc(100vh - 72px);
    & > div {
      height: 100%;
    }
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const SidebarWrap = styled.div`
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: ${SIDEBAR_WIDTH}px;
  transition: transform 0.3s;
  background: ${sidebarBackground};
  border-right: 2px solid ${dividerColor};
  z-index: 1000;
  display: none;

  ${(props) =>
    props.show &&
    `
    display: block;
    transform: ${sidebarClose};
  `};

  @media ${Devices.pc} {
    z-index: 1001;
    display: block;
    top: 0;

    ${(props) =>
      props.collapse &&
      `
      width: ${SIDEBAR_COLLAPSED_WIDTH}px;
      overflow: visible;
      transition: transform 0.3s, width 0.3s;
  
      ${SidebarScroll} {
        width: ${SIDEBAR_COLLAPSED_WIDTH}px;
        overflow: visible !important;
        transition: width 0.3s;
      }
  
      .scrollbar-track.scrollbar-track-y {
        ${marginRight(props)}: 188px;
      }
    }
  `};
  }
`;

const SidebarContentStyle = styled(SidebarContent)`
  height: 100%;
`;
const SidebarLogo = styled.div`
  padding: 24px;
  height: ${TOPBAR_HEIGHT}px;
`;
const LogoStyle = styled(Logo)`
  position: absolute;
`;
// endregion
