import { selectSiteConfig } from '@/redux/refactor/appConfig/selector';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';

const useCaptcha = () => {
  const siteConfig = useSelector(selectSiteConfig);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const execute = useCallback(async (action) => {
    if (siteConfig && siteConfig.googleRecaptcha) {
      const token = await executeRecaptcha(action);
      return token; 
    }
    return 'string';
  }, [executeRecaptcha, siteConfig]);

  return {
    execute,
    executeRecaptcha,
  };
};

export default useCaptcha;
