import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Form as BsForm } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WithContext as ReactTags } from 'react-tag-input';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { KEY_CODES } from '@/shared/constants';
import { Form } from '@/shared/components/form/drawer/Elements';

import { FORM_TYPE } from '@/shared/constants';

const InputTagPattern = ({
  botValue,
  handleDeleteBotValue,
  handleAdditionBotValue,
  setLastInputTagValue,
}) => {
  const { t } = useTranslation();

  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
    }),
    isEqual
  );

  return (
    <BsForm.Group className="full-width">
      <Form.Label>3. {t('Set pattern')}</Form.Label>
      <ReactTags
        classNames={{
          tagInputField: 'form-control',
          tag: classNames({ disabled: currentBot.is_default }),
        }}
        tags={botValue}
        delimiters={[KEY_CODES.comma, KEY_CODES.enter]}
        handleDelete={handleDeleteBotValue}
        handleAddition={handleAdditionBotValue}
        handleInputChange={(text) => setLastInputTagValue(text)}
        inputFieldPosition="inline"
        placeholder={t('Press enter to add...')}
        readOnly={currentBot.is_default || formType === FORM_TYPE.read_only}
        read
      />
    </BsForm.Group>
  );
};

InputTagPattern.propTypes = {
  botValue: PropTypes.instanceOf(Array).isRequired,
  handleDeleteBotValue: PropTypes.func.isRequired,
  handleAdditionBotValue: PropTypes.func.isRequired,
  setLastInputTagValue: PropTypes.func.isRequired,
};

export default InputTagPattern;
