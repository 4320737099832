import { handleActions } from 'redux-actions';
import { FORM_TYPE } from '@/shared/constants';

import {
  updateBots,
  updatePagination,
  updateIsFetching,
  updateTotalSignal,
  updateIsTopBotList,
  updateCurrentBot,
  updateFormTypeBot,
  updateFormStatusBot,
  updateOptionsTypeBot,
  updateSelectedBots,
  updateLatestCreatedBot,
  updateFormTypeCandle,
  updateFormStatusCandle
} from '../actions/botActions';

const defaultState = {
  bots: [],
  pagination: {},
  totalSignal: 0,
  isTopBotList: false,
  currentBot: {},
  formBot: {
    type: FORM_TYPE.create,
    isShow: false,
  },
  formCandle: {
    type: FORM_TYPE.create,
    isShow: false,
  },
  optionsType: [],
  selectedBots: [],
  latestCreatedBot: {}
};

export default handleActions(
  {
    [updateBots](state, { payload }) {
      return {
        ...state,
        bots: payload,
      };
    },
    [updatePagination](state, { payload }) {
      return {
        ...state,
        pagination: payload,
      };
    },
    [updateIsFetching]: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    [updateTotalSignal]: (state, { payload }) => ({
      ...state,
      totalSignal: payload,
    }),
    [updateIsTopBotList]: (state, { payload }) => ({
      ...state,
      isTopBotList: payload,
    }),
    [updateCurrentBot]: (state, { payload }) => ({
      ...state,
      currentBot: payload,
    }),
    [updateFormTypeBot]: (state, { payload }) => ({
      ...state,
      formBot: {...state.formBot, type: payload},
    }),
    [updateFormStatusBot]: (state, { payload }) => ({
      ...state,
      formBot: {...state.formBot, isShow: payload},
    }),
    [updateOptionsTypeBot]: (state, { payload }) => ({
      ...state,
      optionsType: payload,
    }),
    [updateSelectedBots]: (state, { payload }) => ({
      ...state,
      selectedBots: payload,
    }),
    [updateLatestCreatedBot]: (state, { payload }) => ({
      ...state,
      latestCreatedBot: payload,
    }),
    [updateFormTypeCandle]: (state, { payload }) => ({
      ...state,
      formCandle: {...state.formCandle, type: payload},
    }),
    [updateFormStatusCandle]: (state, { payload }) => ({
      ...state,
      formCandle: {...state.formCandle, isShow: payload},
    }),
  },
  defaultState,
);
