import authApi from '@/utils/api/authApi';
import { getAccessToken, getRefreshToken, setToken } from '@/utils/helpers';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';
import { loadUserConfig } from '../appConfig/actions';
import { getExchangeInfo } from '../exchange/actions';
import { setEnabled2Fa } from '../twoFactor/actions';

import { PASSWORDLESS_ENABLED } from '@/config/const';
import { fido } from '@/fido';
import { generic } from '@/utils/generic';
import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

export const authLoaded = createAction('auth/AUTH_LOADED');
export const updateProfile = createAction('auth/UPDATE_PROFILE');
export const clearAuthority = createAction('auth/CLEAR_AUTHORITY');

export const loginRequest = createAction('auth/LOGIN_REQUEST');
export const loginSuccess = createAction('auth/LOGIN_SUCCESS');
export const loginFailure = createAction('auth/LOGIN_FAILURE');
export const loginRequire2Fa = createAction('auth/LOGIN_REQUIRE_2FA');

export const verify2FaRequest = createAction('auth/VERIFY_2FA_REQUEST');
export const verify2FaSuccess = createAction('auth/VERIFY_2FA_SUCCESS');
export const verify2FaFailure = createAction('auth/VERIFY_2FA_FAILURE');

export const registerRequest = createAction('auth/REGISTER_REQUEST');
export const registerSuccess = createAction('auth/REGISTER_SUCCESS');
export const registerFailure = createAction('auth/REGISTER_FAILURE');

export const updateInfoRequest = createAction('auth/updateInfoRequest');
export const updateInfoRequestSuccess = createAction('auth/updateInfoRequestSuccess');
export const updateInfoRequestFailed = createAction('auth/updateInfoRequestFailed');

export const setKickOut = createAction('auth/setKickOut');
export const setAnotherDevice = createAction('auth/setAnotherDevice');
export const setPasswordlessSubmitting = createAction('auth/passwordlessSubmitting');

const showMessage = (errCode, m) => {
  switch (errCode) {
    case 'DuplicateUserName':
      toast.error(t('Email is already taken.'));
      return;
    default:
      toast.error(t(m));
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    const resp = await authApi.getUserProfile();
    if (resp?.data?.ok) {
      window.userId = resp?.data?.d?.uid;
      dispatch(updateProfile(resp?.data?.d));
      dispatch(setEnabled2Fa(resp?.data?.d['2fa']));
    } else {
      toast.error(t(resp?.data?.m));
    }
  } catch (error) {
    toast.error(t(error.message));
    throw error;
  }
};

export const beginLogin = (history) => async (dispatch) => {
  if (PASSWORDLESS_ENABLED && fido.detectFIDOSupport(generic)) {
    dispatch(loginPwdless(history));
  } else {
    history.push('/login');
  }
};

export const autoPwdlessLogin = (history) => async (dispatch) => {
  const pwdless = generic.getJsonLocalStorage('pwdless') || {};
  if (PASSWORDLESS_ENABLED && fido.detectFIDOSupport(generic) && !pwdless.loggedout) {
    dispatch(loginPwdless(history));
  }
};

export const loginPwdless = (history) => async (dispatch) => {
  const pwdless = generic.getJsonLocalStorage('pwdless') || {};
  const processLogin = async (resolve, reject) => {
    try {
      dispatch(setPasswordlessSubmitting(true));
      const fidoResp = await fido.handleLogin(generic);
      if (!fidoResp) {
        reject();
        return history.push('/login');
      }

      if (fidoResp.status == 'ok') {
        delete pwdless.loggedout;
        generic.setJsonLocalStorage('pwdless', pwdless);

        const data = fidoResp.data;
        setToken(data);
        dispatch(loadUserConfig());
        dispatch(getProfile());
        await dispatch(getExchangeInfo());
        dispatch(loginSuccess(data));
        resolve();
        ga4Stats(gtag.login.action.loginPwdLess, gtag.login.category, gtag.login.label);
      } else {
        reject();
        toast.error(t(fidoResp.errorMessage));
        dispatch(loginFailure());
      }
    } catch {
      reject();
    } finally {
      dispatch(setPasswordlessSubmitting(false));
    }
  };

  toast.promise(new Promise((resolve, reject) => processLogin(resolve, reject)), {
    pending: `Signing in to account ${pwdless.email}...`,
    success: 'Logged in successfully',
    error: 'An error occurred during passwordless login',
  });
};

export const login = (params) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const resp = await authApi.login(params);

    if (resp?.data?.ok) {
      if (resp?.data?.d?.require2Fa) {
        dispatch(loginRequire2Fa(resp?.data?.d));
      } else {
        setToken(resp?.data?.d);
        dispatch(loadUserConfig());
        dispatch(getProfile());
        dispatch(getExchangeInfo(true));
        dispatch(loginSuccess(resp?.data?.d));
        ga4Stats(gtag.login.action.loginWithout2Fa, gtag.login.category, gtag.login.label);
      }
    } else {
      toast.error(t(resp?.data?.m));
      dispatch(loginFailure());
    }
  } catch (error) {
    toast.error(t(error.message));
    dispatch(loginFailure({ error }));
    throw error;
  }
};

export const verify2Fa = (params) => async (dispatch) => {
  try {
    dispatch(verify2FaRequest());
    const resp = await authApi.verify2Fa(params);
    if (resp?.data?.ok) {
      setToken(resp?.data?.d);
      dispatch(loadUserConfig());
      dispatch(getProfile());
      dispatch(getExchangeInfo(true));
      dispatch(verify2FaSuccess(resp?.data?.d));
      ga4Stats(gtag.login.action.loginInclude2FA, gtag.login.category, gtag.login.label);
    } else {
      toast.error(t(resp?.data?.m));
      dispatch(verify2FaFailure());
    }
  } catch (error) {
    toast.error(t(error.message));
    dispatch(loginFailure({ error }));
    throw error;
  }
};

export const refreshToken = (token) => async (dispatch) => {
  try {
    const resp = await authApi.refresh(token);
    if (resp?.data?.ok) {
      setToken(resp?.data?.d);
      dispatch(loginSuccess(resp?.data?.d));
    } else {
      dispatch(clearAuthority());
    }
    return resp;
  } catch (error) {
    toast.error(t(error.message));
    dispatch(clearAuthority());
    throw error;
  }
};

/**
 *
 * @param {Object} params
 * @param {string} params.email
 * @param {string} params.password
 * @param {string} params.confirmPassword
 * @param {string} params.referralCode
 */
export const register = (params) => async (dispatch) => {
  try {
    dispatch(registerRequest(params.email));
    const resp = await authApi.register({
      ...params,
      confirmPassword: params.password,
    });
    if (resp?.data?.ok) {
      dispatch(registerSuccess());
      ga4Stats(gtag.register.action.signUp, gtag.register.category, gtag.register.label);
    } else {
      showMessage(resp?.data?.d?.err_code, resp?.data?.m);
      dispatch(registerFailure());
    }
  } catch (error) {
    toast.error(t(error.message));
    dispatch(registerFailure());
    throw error;
  }
};

/**
 *
 * @param {Object} params
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.verifyCode
 */
export const updateInfo = (params) => async (dispatch) => {
  try {
    dispatch(updateInfoRequest());
    const resp = await authApi.updateProfile(params);
    if (resp?.data?.ok) {
      dispatch(getProfile());
      dispatch(updateInfoRequestSuccess());
      toast.success(t('Your profile has changed'));
      ga4Stats(gtag.setting.action.changeName, gtag.setting.category, gtag.login.label, params.uid);
    } else {
      toast.error(t(resp?.data?.m));
      dispatch(updateInfoRequestFailed());
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(t(error.message));
    dispatch(updateInfoRequestFailed());
    return false;
  }
};

export const initAuthReducer = () => async (dispatch) => {
  const isAuth = !!getAccessToken();
  const token = getRefreshToken();

  if (isAuth) {
    dispatch(getExchangeInfo(true));
    dispatch(getProfile());
    dispatch(loadUserConfig());
  } else if (token) {
    await dispatch(refreshToken(token));
    dispatch(getExchangeInfo(true));
    dispatch(getProfile());
    dispatch(loadUserConfig());
  } else {
    dispatch(clearAuthority());
  }
};

export const kyc = (params) => async (dispatch) => {
  try {
    const resp = await authApi.kyc(params);
    if (resp?.data?.ok) {
      dispatch(getProfile());
      toast.success(t('Successfully Submitted!'));
    } else {
      toast.error(t(resp?.data?.m));
    }
    return resp?.data?.ok;
  } catch (error) {
    toast.error(t(error.message));
    return false;
  }
};

window.actions = {
  clearAuthority,
  refreshToken,
};
