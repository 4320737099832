import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Copy, Edit, Eye, Trash } from 'iconsax-react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { inValidRecord } from '../../const';

import './index.scss';

const ResultItem = ({
  index,
  value,
  isDefault,
  isReadOnly,
  onEdit,
  onShowView,
  onDelete,
  onDuplicate,
  allowDuplicate,
}) => {
  const { t } = useTranslation();
  const { candle, order, sources = {} } = value;
  const invalid = inValidRecord(candle, sources);
  const candleLen = Object.keys(sources).filter((key) => !!sources[key]).length;

  return (
    <li className="Results__pattern-candle-item">
      <div
        className={classNames('Results__pattern-candle-item-text', { invalid })}
        dangerouslySetInnerHTML={{
          __html: t('FrmCandle_Results_item_text', {
            classes: classNames('badge-order', {
              'badge-buy': order === 'b',
              'badge-sell': order === 's',
            }),
            candle: candle === 99 ? 1 : candle + 2 - 80,
            candleLen,
          }),
        }}
      />
      <button onClick={() => !isReadOnly ? onEdit(index) : onShowView()}>
        {(!isDefault && !isReadOnly) ? <Edit size={27} /> : <Eye size={27} />}
      </button>
      {(!isDefault && !isReadOnly) && (
        <Dropdown drop="start">
          <Dropdown.Toggle></Dropdown.Toggle>
          <Dropdown.Menu>
            {allowDuplicate && (
              <Dropdown.Item
                onClick={() => onDuplicate(index)}
                eventKey="1"
                className="d-flex align-items-center"
              >
                <Copy size={20} className="me-2" /> {t('Duplicate')}
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => onDelete(index)}
              eventKey="2"
              className="d-flex align-items-center"
            >
              <Trash size={20} className="me-2" /> {t('Delete')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </li>
  );
};

ResultItem.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  isDefault: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  onShowView: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  allowDuplicate: PropTypes.bool
};

ResultItem.defaultProps = {
  isDefault: false,
  onEdit: () => {},
  onShowView: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
  allowDuplicate: false,
  isReadOnly: false
}

export default ResultItem;
