import { isEqual } from 'lodash';
import {
  updateBacktestDataChart,
  updateBacktestId,
  updateBacktestProgress,
} from '@/redux/actions/planActions';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import { Devices, colorSuccess, sidebarBackground } from '@/utils/palette';
import { useMutation } from '@tanstack/react-query';
import { isArray } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import gtag from '@/shared/constants/gtag';


import FormBackTest from './form';
import DrawerCreateOptionsBackTest from './drawer/DrawerCreateOptionsBackTest';

const ga4Stats = window.ga4Stats;

const initValues = {
  signal_type: SIGNAL_STRATEGY_TYPE.botai,
};

const mutators = {
  setBudgetId: (args, state, utils) => {
    utils.changeValue(state, 'budget_strategy', () => args[0]);
  },
  setSignalId: (args, state, utils) => {
    utils.changeValue(state, 'bot_ai', () => args[0]);
  },
  setTelegramChannel: (args, state, utils) => {
    utils.changeValue(state, 'telegram_chanels', () => args[0]);
  },
};

const getParams = (values = {}) => {
  const botAI = isArray(values.bot_ai) ? values.bot_ai[0] : values.bot_ai;
  const budgetStrategy = isArray(values.budget_strategy)
    ? values.budget_strategy[0]
    : values.budget_strategy;

  const params = {
    signal_type: values?.signal_type,
    budget_strategy_id: budgetStrategy?.value,
  };

  if (values.signal_type === SIGNAL_STRATEGY_TYPE.botai) {
    params.bot_ai_id = botAI?.value;
  } else {
    params.telegram_chanels = values?.telegram_chanels?.reduce(
      (acc, item) => [...acc, item?.value?.toString()],
      []
    );
  }
  return params;
};

export default function CardBackTest({
  changeBacktestId,
  onRefetchHistory,
  totalToDay,
  limitPerDay,
  closeModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { backtestMoreConditions } = useSelector(
    (state) => ({
      backtestMoreConditions: state.plan.backtestMoreConditions,
    }),
    isEqual
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: async (values) => {
      const params = getParams(values);
      const { data } = await planApi.createBacktest({...params, ...backtestMoreConditions});
      
      ga4Stats(gtag.dashboard.action.backTestRun, gtag.dashboard.category, gtag.dashboard.label);

      return data;
    },
    onSuccess: ({ ok, m, d = {} }) => {
      if (ok) {
        changeBacktestId(d?.id);
        dispatch(updateBacktestId(d?.id));
        onRefetchHistory();
        dispatch(updateBacktestDataChart([]));
        dispatch(updateBacktestProgress(0));
      } else {
        toast.error(m);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const [showCreateConditions, setShowCreateConditions] = useState(false);
  const [formValues, setFormValues] = useState({});

  return (
    <>
      <CardBackTestStyle className="card-back-test">
        <img className="thumb" src="/img/dashboard/backtest-thumb.png" alt="BackTest Thumb" />
        <div className="content">
          <h5 className="title">{t('backtest_title')}</h5>
          <Form onSubmit={mutate} mutators={mutators} initialValues={initValues}>
            {(props) => (
              <FormBackTest
                {...props}
                isLoading={isLoading}
                totalToDay={totalToDay}
                limitPerDay={limitPerDay}
                closeModal={closeModal}
                onShowMoreConditions={() => setShowCreateConditions(true)}
                handleFormChange={(values) => setFormValues(values)}
              />
            )}
          </Form>
          {formValues?.signal_type && formValues?.budget_strategy && (
            <DrawerCreateOptionsBackTest
              isOpen={showCreateConditions}
              onClose={() => setShowCreateConditions(false)}
              signalType={formValues?.signal_type}
              budgetId={formValues?.budget_strategy[0]?.value || formValues?.budget_strategy?.value}
            />
          )}
        </div>
      </CardBackTestStyle>
    </>
  );
}

const CardBackTestStyle = styled.div`
  img.thumb {
    display: none;
    @media ${Devices.pc} {
      display: block;
    }
  }
  .content {
    display: block;
    @media ${Devices.pc} {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      background: ${sidebarBackground};
      border-radius: 0 0 20px 20px;
    }
    .title {
      display: none;
      color: ${colorSuccess};
      font-weight: 800;
      line-height: 140%;
      @media ${Devices.pc} {
        display: block;
      }
    }
    .subTitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 12px;
    }
    .backtest-add-conditions {
      padding: 0;
      background: none;
      border: none;
      box-shadow: none;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
