import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  AccountCard,
  AccountContent,
  AccountWrap,
  AccountLogo,
  AccountFormContainer,
  AccountGetStarted,
} from '@/shared/components/account/AccountElements';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register, registerFailure } from '@/redux/refactor/auth/actions';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import SelectLanguage from '@/shared/components/SelectLanguage';
import { Helmet } from 'react-helmet';
import useCaptcha from '@/hooks/useCaptcha';
import RightBanner from '../../../shared/components/account/RightBanner';
import RegisterSuccess from './components/RegisterSuccess';
import CardRegister from './components/CardRegister';

const Register = () => {
  const { t } = useTranslation();
  const { isRegisterSuccess } = useSelector(state => state.user, _.isEqual);
  const dispatch = useDispatch();
  const { execute } = useCaptcha();

  const handleSubmit = async (values) => {
    const captcha = await execute('register');
    dispatch(register({ ...values, captcha }));
  };

  useEffect(
    () => () => {
      if (dispatch) dispatch(registerFailure(null));
    },
    [dispatch],
  );

  return (
    <AccountWrap>
      <Helmet title="Register | Quickinvest.ai" />
      <AccountContent>
        <AccountCard>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <NavLink to="/">
              <AccountLogo />
            </NavLink>
            <Box mb="40px">
              <SelectLanguage />
            </Box>
          </Box>
          <AccountFormContainer>
            {isRegisterSuccess && <RegisterSuccess />}
            {!isRegisterSuccess && <CardRegister onSubmit={handleSubmit} />}
            <AccountGetStarted>
              {t('Already have an account?')}{' '}
              <NavLink to="/login">{t('Sign In')}</NavLink>
            </AccountGetStarted>
          </AccountFormContainer>
        </AccountCard>
      </AccountContent>
      <RightBanner />
    </AccountWrap>
  );
};

export default withRouter(Register);
