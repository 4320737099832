import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { dateFormat } from '@/shared/helpers';
import { useTranslation } from 'react-i18next';
import ItemComment from './ItemComment';

const ListComment = ({ ticketListComment }) => {
  const { t } = useTranslation();
  const currentTicket = useSelector(state => state.ticket.currentTicket, shallowEqual);

  return (
    <>
      {!!ticketListComment.length && (
        <Scrollbars style={{ height: 300 }}>
          <div className="ticket-list-comment">
            <div className="ticket-list-comment-header">
              <h3 className="ticket-list-comment-header-issue">
                {currentTicket.subject ? t(currentTicket.subject.trim()) : ''}
              </h3>
              <p className="ticket-list-comment-header-time mb-0">{dateFormat(currentTicket.created_at)}</p>
            </div>
            <div className="ticket-list-comment-body">
              {ticketListComment.map(item => (
                <ItemComment item={item} key={item.id} />
              ))}
            </div>
          </div>
        </Scrollbars>
      )}
    </>
  );
};

ListComment.propTypes = {
  ticketListComment: PropTypes.instanceOf(Array).isRequired,
};

export default ListComment;
