import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  pushNotify,
  setLastNotify,
  setNotification,
  setNotifyPlan,
  setSearchTerm,
  setTab,
  setVisibleWindow,
  toggleVisibleWindow,
} from './actions';

export const MAX_ITEM = 100;
export const TAB_TYPE = {
  PLAN: 'PLAN',
  DETAIL: 'DETAIL',
};

const defaultState = {
  tab: TAB_TYPE.PLAN,
  allPlans: [],
  notification: {},
  oldNotification: {},
  selected: {},
  visible: false,
  searchTerm: '',
  hasNotify: false,
  lastNotify: {},
};

const addNotify = (collection, newItem) => {
  if (collection.length === MAX_ITEM) {
    return [newItem, ...collection.slice(0, MAX_ITEM - 1)];
  }
  return [newItem, ...collection];
};

export default handleActions(
  {
    [setTab]: (state, { payload }) => {
      const { tab, selected = {} } = payload;
      return {
        ...state,
        tab,
        selected,
      };
    },
    [setVisibleWindow]: (state, { payload }) => ({
      ...state,
      visible: payload,
    }),
    [toggleVisibleWindow]: (state) => ({
      ...state,
      visible: !state.visible,
      hasNotify: false,
    }),
    [pushNotify]: (state, { payload }) => {
      const { data, isNew } = payload;
      const { planId, time, action } = data;
      const notification = { ...state.notification };
      // init key inside dict if not exist
      if (!notification[planId]) {
        notification[planId] = [];
      }
      // return current state if already exist
      if (
        notification[planId].find(
          (item) => item.time === time && item.action === action
        )
      ) {
        return state;
      }
      // push notify to limit array by max 100
      notification[planId] = addNotify(notification[planId], data);
      return {
        ...state,
        notification,
        // just show notify alert from event ReceiveMessage (SignalR) only
        hasNotify: isNew ? !state.visible : false,
      };
    },
    [setNotifyPlan]: (state, { payload = [] }) => {
      // create fulltext search string
      const allPlans = payload.reduce(
        (total, plan) => [
          ...total,
          {
            ...plan,
            searchTerm: `${plan.id} ${_.deburr(plan.name).toLowerCase()}`,
          },
        ],
        []
      );
      return {
        ...state,
        allPlans,
      };
    },
    [setSearchTerm]: (state, { payload }) => ({
      ...state,
      searchTerm: payload,
    }),
    [setLastNotify]: (state, { payload }) => ({
      ...state,
      lastNotify: payload,
    }),
    [setNotification]: (state, { payload }) => ({
      ...state,
      oldNotification: payload,
    }),
  },
  defaultState
);
