import React, {useContext} from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { conditionValidate } from '../../const';
import { CandlePatternContext } from '../../context';

import Tooltip from '@/shared/components/Tooltip';

const BubbleErrors = ({ isEdit }) => {
  const { t } = useTranslation();
  const { current, conditions, quickFix } = useContext(CandlePatternContext);
  const errorCount = Object.entries(conditions).filter((item) => {
    return item[1] && !conditionValidate(current, Number(item[0]));
  });

  if (!isEdit || errorCount.length === 0) return null;
  return (
    <div className="alert alert-danger">
      {t('There are {{amount}} shadows with incorrect conditions.', {
        amount: errorCount.length,
      })}{' '}
      <Tooltip
        text={t(
          'This execution will preserve the correct conditions and eliminate the incorrect conditions'
        )}
      >
        <span onClick={quickFix} className="a">
          {t('Quick fix')}
        </span>
      </Tooltip>
    </div>
  );
}

BubbleErrors.propTypes = {
  isEdit: PropTypes.bool,
};

BubbleErrors.defaultProps = {
  isEdit: false,
};

export default BubbleErrors;
