import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { Form } from '@/shared/components/form/drawer/Elements';
import { validatorHelper } from '@/shared/helpers';
import { colorSuccess } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { checkPlanBudget } from '../validator';
import { BalanceInput, DefaultBudgetWrap, FormLabel } from './FormElements';

const defaultBudget = [100, 1000, 10000];

const DefaultBudgetWrapStyle = styled(DefaultBudgetWrap)`
  flex-wrap: nowrap;
  margin-top: 0;
`;

const BalanceInputStyle = styled(BalanceInput)`
  font-weight: 600;
  border: 1px solid #a0aec0;
  border-radius: 20px;
  padding: 4px 18px;
  font-size: 16px;
  text-align: center;
  width: 125px;
  line-height: 150%;
  &::placeholder {
    color: #a0aec0;
  }
`;

const FormLabelStyle = styled(FormLabel)`
  margin-top: 20px;
  margin-bottom: 0;
  .live {
    color: ${colorSuccess};
  }
  .demo {
    color: #8c62ff;
  }
`;
const SelectAmountWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 14px;
  overflow-x: auto;
  padding: 5px 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Begin PlanBudget
export default memo(() => {
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  return (
    <Box>
      <Form.Label>{t('Investment fund')}</Form.Label>
      <Field
        name="budget"
        component={InputNumberGroup}
        placeholder="$0"
        validate={validator.composeValidators(
          validator.required,
          checkPlanBudget
        )}
      />
    </Box>
  );
});
// End PlanBudget
