import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { Form } from '@/shared/components/form/drawer/Elements';
import { validatorHelper } from '@/shared/helpers';
import React, { Fragment, memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { checkBaseAmount } from '../validator';

const defaultBudget = [1, 5, 10];
// Begin BaseAmount
export default memo(({ value }) => {
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  return (
    <Fragment>
      <Form.Label>{t('Set base amount')}</Form.Label>
      <Field
        name="base_amount"
        component={InputNumberGroup}
        placeholder={t('FormCreatePlan_budget_baseAmount_placeholder')}
        initial={1}
        validate={validator.composeValidators(
          validator.required,
          checkBaseAmount
        )}
      />
    </Fragment>
  );
});
// End BaseAmount
