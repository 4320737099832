import { withPlanCrud } from '@/context/PlanCrudProvider';
import { selectCurrentTotalPlan } from '@/redux/selectors/dashboardSelector';
import { Button } from '@/shared/components/Button';
import { colorText, Devices } from '@/utils/palette';
import { Box } from '@material-ui/core';
import _, { isEqual } from 'lodash';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const BannerCreatePlan = withRouter(({ planCrud }) => {
  const { t } = useTranslation();
  const { fetching } = useSelector(
    (state) => ({
      fetching: state.dashboard.totalPlanLoading,
    }),
    isEqual
  );
  const total = useSelector(selectCurrentTotalPlan, isEqual);
  const handleCreate = () => planCrud.handleCreate({ pos: 'BannerCreatePlan' });
  if (fetching || total > 0) return null;
  return (
    <BannerWrapper>
      <BannerTitle
        dangerouslySetInnerHTML={{
          __html: t(
            'DashboardPage.Banner.title',
            "You don’t have any plan now!<br />Create a plan now and <span class='success'>earn more profit</span>"
          ),
        }}
      />
      <ButtonStyle onClick={handleCreate} variant="secondary">
        {t('Create a Plan')}
      </ButtonStyle>
      <ImageStyle src="/img/dashboard/banner-right.png" />
    </BannerWrapper>
  );
});

const BannerWrapper = styled(Box)`
  position: relative;
  padding: 16px;
  background: linear-gradient(90.21deg, #009d78 3.09%, #0c0c0c 88.88%);
  border-radius: 16px;
  margin-bottom: 62px;
  @media ${Devices.pc} {
    padding: 24px;
  }
`;

const BannerTitle = styled(Box)`
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 24px;
  z-index: 2;
  position: relative;
  .success {
    color: #86d7b0;
  }
  @media ${Devices.pc} {
    max-width: calc(100% - 160px);
    font-size: 24px;
  }
`;

const ButtonStyle = styled(Button)`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.2px;
  color: ${colorText};
  z-index: 2;
  border: none;
  @media ${Devices.pc} {
    padding-left: 57px;
    padding-right: 57px;
  }
`;
const ImageStyle = styled(Image)`
  position: absolute;
  right: -55px;
  bottom: -65px;
  z-index: 1;
  width: 262px;
  height: auto;
  @media ${Devices.pc} {
    width: auto;
    right: -38px;
    bottom: -55px;
  }
`;

export default withPlanCrud(BannerCreatePlan);
