import validator from 'validator';
import { validateText } from "@/shared/helpers";
import { STRATEGY_METHOD } from '@/shared/constants';
import { LENGTH_VALIDATION, MAX_ORDER_VALUE } from '@/config/const';

export const checkValidStrategyName = (value, t) => {
  if (value.length > 50 || !validateText(value)) {
    return {
      isValid: false,
      text: t('Strategy name is invalid'),
    };
  } else {
    return{ isValid: true, text: '' };
  }
};

export const checkValidOrderValue = (value, index, strategyMethod, siteConfig, t) => {
  const val = value.toString();
  const maxLength =
    strategyMethod === STRATEGY_METHOD.autowin
      ? LENGTH_VALIDATION.autowin
      : LENGTH_VALIDATION.all;
  const objValid = {};
  if (validator.isEmpty(val)) {
    objValid.isValid = false;
    objValid.text = t('Value is invalid!');
  } else if (val.length > maxLength) {
    objValid.isValid = false;
    objValid.text = t('Max length is {{amount}} characters', {
      amount: maxLength,
    });
  } else {
    const txts = val.split('-');
    for (let i = 0; i < txts.length; i += 1) {
      const val = txts[i];
      if (validator.isNumeric(val)) {
        if (strategyMethod === STRATEGY_METHOD.all_order && (val > MAX_ORDER_VALUE || val < 1)) {
          objValid.isValid = false;
          objValid.text = t('The input value cannot be greater than 1000000 or less than 1');
          break;
        } else if (
          !siteConfig.first_zero &&
          Number(val) === 0 &&
          i === 0 &&
          ((strategyMethod === STRATEGY_METHOD.autowin && index == 1) ||
            (strategyMethod.includes(STRATEGY_METHOD.victor) && index === 0) ||
            strategyMethod === STRATEGY_METHOD.martingale ||
            strategyMethod.includes(STRATEGY_METHOD.fibo))
        ) {
          objValid.isValid = false;
          objValid.text = t('use wait signal when use streak');
          break;
        }
        objValid.isValid = true;
        objValid.text = '';
      } else {
        objValid.isValid = false;
        objValid.text = t('Value is invalid!');
        break;
      }
    }
  }

  return objValid;
};

export const checkValidStep = (fiboStepLose, fiboStepWin, t) => {
  const obj = {};
  
  if (Number(fiboStepWin) < 0) {
    obj.win = { isValid: false, text: t('Cấu hình không hợp lệ') };
  } else {
    obj.win = { isValid: true, text: '' };
  }

  if (Number(fiboStepLose) < 0) {
    obj.lose = { isValid: false, text: t('Cấu hình không hợp lệ') };
  } else {
    obj.lose = { isValid: true, text: '' };
  }

  return obj;
}

export const parseStrategyOptions = (strategyMethod, strategyOptionsRaw, t) => {
  let selectOps = strategyOptionsRaw;
  if (strategyMethod === STRATEGY_METHOD.martingale) {
    selectOps = strategyOptionsRaw.filter(item => item.type === 'MartingaleOption').map(
      item => ({
        label: t(item.name),
        value: item.code,
      })
    );
  } else if (strategyMethod === STRATEGY_METHOD.fibo) {
    selectOps = strategyOptionsRaw.filter(item => item.type === 'FiboOption').map(
      item => ({
        label: t(item.name),
        value: item.code,
      })
    );
  }

  return selectOps;
}
