import { TRANSFER_TYPE } from '@/config/const';
import {
  FormGroup,
  FormGroupField,
} from '@/shared/components/form/FormElements';
import FormField from '@/shared/components/form/FormField';
import { validatorHelper } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  Devices,
  colorAccent,
  colorGreyScale300,
  colorGreyScale900,
  colorText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';
import withdrawValidator from './withdrawValidator';

function capitalizeFirstLetter(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const FormWithdraw = withTranslation()(({ t, type, formRef }) => {
  const { usdtAvailableBalance } = useSelector(
    (state) => state.wallet.balance,
    _.isEqual
  );
  const { logo } = useSelector((state) => state.exchange, shallowEqual);
  const validator = validatorHelper(t);

  return (
    <>
      <FormGroup>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormLabel>{t('USDT Amount')}</FormLabel>
          <BalanceBox
            onClick={() => formRef.mutators.setAmount(usdtAvailableBalance)}
          >
            <Box className="balance pc">
              <span>
                {t('Balance')}:{' '}
                {formatNumber.sliceDecimalFloat(usdtAvailableBalance)}
              </span>
              <Max>{t('MAX')}</Max>
            </Box>
            <Box className="balance mobile">
              <Box display="flex">
                {formatNumber.sliceDecimalFloat(usdtAvailableBalance)}
                <Max>{t('MAX')}</Max>
              </Box>
            </Box>
          </BalanceBox>
        </Box>
        <FormGroupField>
          <Field
            validate={validator.composeValidators(
              withdrawValidator(t).minAmount,
              withdrawValidator(t).maxAmount(usdtAvailableBalance)
            )}
            name="amount"
            component={FormField}
            type="number"
            placeholder={t('Enter withdrawal amount')}
          />
        </FormGroupField>
      </FormGroup>
      <ExchangeWrapper>
        <ImageStyle src={logo} />
        {type === TRANSFER_TYPE.INTERNAL ? (
          <FormGroup>
            <FormLabel>{t('Recipient nickname')}</FormLabel>
            <FormGroupField>
              <Field
                validate={validator.required}
                name="nickName"
                component={FormField}
                placeholder={t('Enter nickname')}
                paste
              />
            </FormGroupField>
          </FormGroup>
        ) : (
          <FormGroup>
            <FormLabel>{t('Receiving address')}</FormLabel>
            <FormGroupField>
              <Field
                validate={validator.composeValidators(
                  validator.required,
                  withdrawValidator(t).bscAddress
                )}
                name="toAddress"
                component={FormField}
                placeholder={t('Enter address')}
                paste
              />
            </FormGroupField>
          </FormGroup>
        )}
        <FormGroup mb="8px">
          <FormLabel>{t('2FA Code')}</FormLabel>
          <FormGroupField>
            <Field
              validate={validator.required}
              name="verifyCode"
              component={FormField}
              placeholder={t('Enter 2FA Code')}
              paste
            />
          </FormGroupField>
        </FormGroup>
      </ExchangeWrapper>
    </>
  );
});

FormWithdraw.propTypes = {
  type: PropTypes.string.isRequired,
};

const FormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: ${colorText};
  @media ${Devices.pc} {
    font-size: 16px;
    font-weight: 700;
  }
`;

const BalanceBox = styled(Box)`
  user-select: none;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${colorText};
  cursor: pointer;
  .balance {
    margin-bottom: 8px;
  }
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media ${Devices.pc} {
    .pc {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
`;

const Max = styled(Box)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  margin-left: 5px;
  color: ${colorAccent};
`;

const ExchangeWrapper = styled(Box)`
  padding: 28px 24px;
  background: ${theme('mode', {
    light: colorGreyScale300,
    dark: colorGreyScale900,
  })};
  border-radius: 18px;
  margin-top: 46px;
  position: relative;
`;

const ImageStyle = styled(Image)`
  height: 31px;
  padding: 5px 12px;
  background-color: ${colorGreyScale900};
  border-radius: 17px;
  margin-bottom: 14px;
  position: absolute;
  top: -15px;
`;

export default FormWithdraw;
