import useBacktestDetail from '@/hooks/queries/useBacktestDetail';
import { Button } from '@/shared/components/Button';
import LineChartBacktest from '@/shared/components/LineChartBacktest';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { colorGreyScale100, colorGreyScale700, colorGreyScale900 } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';
import FormCreatePlan from './FormCreatePlan';
import Headline from './Headline';
import usePrevious from '@/hooks/usePrevious';

const CardItem = ({ backtestId, onHide, formProps, isSubmitting }) => {
  const { t } = useTranslation();
  const { backtestProgress, mode } = useSelector(
    (state) => ({
      mode: state.theme.className,
      backtestProgress: state.plan.backtestProgress,
      backtestPrevHistory: state.plan.backtestPrevHistory,
      isMobile: state.theme.isMobile,
    }),
    isEqual
  );
  const { refetch, data = {}, isLoading } = useBacktestDetail({ variables: backtestId });
  const preData = usePrevious(data);

  useEffect(() => {
    if (isEqual(backtestProgress, 1)) {
      refetch();
    }
  }, [backtestProgress]);

  useEffect(() => {
    if (data && !isEqual(data, preData)) {
      formProps.form.mutators.setId(data?.id ?? Number(data?.id));
      formProps.form.mutators.setPlanName(data?.planName);
      formProps.form.mutators.setBaseAmount(data?.baseAmount || 0);
      formProps.form.mutators.setTakeProfit(data?.maxProfit || 0);
      formProps.form.mutators.setStopLoss(data?.minProfit || 0);
    }
  }, [data]);

  return (
    <>
      <Form.Header onClose={onHide} />
      <CardItemWrap>
        <Headline data={data} backtestProgress={backtestProgress} />
        <Grid>
          <Grid.Row>
            <Grid.Cell className="chart-holder">
              <Box className="chart-wrapper">
                <LineChartBacktest backtestId={backtestId} />
              </Box>
            </Grid.Cell>
          </Grid.Row>
          <FormCreatePlan {...formProps} data={data} isLoading={isLoading} />
        </Grid>
      </CardItemWrap>
      <Form.Footer>
        <Button variant="outline-secondary" onClick={onHide} className="btn-back me-2">
          {'< '}
          {t('Back')}
        </Button>
        <Button
          onClick={formProps.handleSubmit}
          disabled={formProps.invalid || isLoading || isSubmitting}
          variant="success"
          isLoading={isSubmitting}
          block="true"
        >
          {t('Create Plan')}
        </Button>
      </Form.Footer>
    </>
  );
};

const CardItemWrap = styled(Form.Body)`
  .chart-holder {
    position: relative;
    background-color: ${theme('mode', {
      light: 'rgba(217, 217, 217, 0.3)',
      dark: colorGreyScale900,
    })};
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    height: 250px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .chart-wrapper {
    flex: 1;
    width: 100%;
  }
  .form-holder {
    width: unset;
  }
  .plan-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    background: ${theme('mode', {
      light: colorGreyScale100,
      dark: colorGreyScale700,
    })};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`;

CardItem.propTypes = {
  backtestId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  formProps: PropTypes.instanceOf(Object).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CardItem;
