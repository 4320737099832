import { isSTG } from '@/config/const';
import { loadSiteConfig } from '@/redux/refactor/appConfig/actions';
import Loading from '@/shared/components/Loading';
import { motion } from 'framer-motion';
import { SecurityUser } from 'iconsax-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UnderMaintenance from '../../shared/components/maintenance';
import PageError from '@/shared/components/PageError';
import { useTranslation } from 'react-i18next';

const motionProps = {
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.4 },
};

const direction = (location, rtl) => (location.pathname === '/' ? 'ltr' : rtl.direction);

const MainWrapper = ({ children, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rtl, isFetching, siteConfig, isChecking, isAuth, isError } = useSelector(
    (state) => ({
      rtl: state.rtl,
      isFetching: state?.user?.processing,
      siteConfig: state?.appConfig?.siteConfig,
      isChecking: state?.exchange?.isChecking,
      isAuth: state.user.isAuth,
      isError: state?.exchange?.isError,
    }),
    _.isEqual
  );

  useEffect(() => {
    if (dispatch) {
      dispatch(loadSiteConfig());
    }
  }, [dispatch]);

  if (siteConfig.mtn_enabled && !isSTG()) {
    return <UnderMaintenance />;
  }

  // Show loading on fetching data
  if (isFetching || isChecking) {
    return (
      <Loading loading={isFetching || isChecking} message={'Checking...'} Icon={SecurityUser} />
    );
  }

  // Show error if cannot retrieve exchange data
  if (isError) {
    return (
      <PageError
        message={t('exchange_connect_error', 'Something went wrong')}
        description={t(
          'exchange_connect_error_description',
          'Cannot connect to the server. Please try again later.'
        )}
      />
    );
  }

  return (
    <motion.div
      {...motionProps}
      className={`${direction(location, rtl)}-support`}
      dir={direction(location, rtl)}
    >
      <div className="wrapper">{children}</div>
    </motion.div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(MainWrapper);
