/* eslint-disable max-len */
import { STORAGE_KEY } from '@/config/const';
import { VARIANTS } from '@/utils/palette';
import { abbreviateNumber } from 'js-abbreviation-number';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import 'moment/locale/vi';
import { PLAN_STATUS } from '../constants';
import formatNumber from './formatNumber';

const ligthTheme = {
  backgroundColor: 'white',
  color: '#111827',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const emailPattern =
  /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*?\.[a-zA-Z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
export const urlPattern =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,9})?(\/.*)?$/;
export const nicknamePattern = /^[a-zA-Z]{1}[a-zA-Z0-9._-]{5,19}$/;
export const fullnamePattern = /^[a-zA-Z]{1,}(?: [a-zA-Z]+){0,2}$/;

export const isValidEmail = (value) => emailPattern.test(value);
export const isValidNickname = (value) => nicknamePattern.test(value);
export const isValidUrl = (value) => urlPattern.test(value?.toLowerCase());
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, _validator) => error || _validator(value),
      undefined
    );

export const toPercent = (decimal, fixed = 0) =>
  decimal ? `${decimal.toFixed(fixed)}%` : '0%';

export const firstLetterToUpperCase = (str) => {
  if (!str) return '';

  const firstLetterUpperCase = str[0].toUpperCase();
  return `${firstLetterUpperCase}${str.slice(1)}`;
};

export const validatorHelper = (translate) => ({
  required: (value) => {
    if (typeof value === 'boolean') {
      return value ? undefined : translate('Required');
    }
    return isEmpty(value?.toString()) ? translate('Required') : undefined;
  },
  isEmail: (value) =>
    isValidEmail(value) ? undefined : translate('Your email is invalid'),
  isUrl: (value) =>
    isValidUrl(value) ? undefined : translate('Exchange URL is incorrect!'),
  isNickname: (value) =>
    isValidNickname(value)
      ? undefined
      : translate(
          'A nickname must be between 6 and 20 characters, start with a letter and contain no special characters.'
        ),
  isFullname: (value) => {
    const error = 'A full name is invalid';
    if (value) {
      const arr = value.trim().split(' ');
      return arr.length > 1 ? undefined : error;
    }
    return error;
  },
  minValue: (min) => (value) =>
    value.trim().length >= min
      ? undefined
      : translate('Must be at least {{min}} characters long.', { min }),
  maxValue: (max) => (value) =>
    value.trim().length <= max
      ? undefined
      : translate('Must be less than {{max}} characters long.', { max }),
  match: ({ password, confirmPassword }) =>
    password === confirmPassword
      ? undefined
      : translate('Confirm password not match'),
  fileRequired: (value = []) =>
    value.length > 0 ? undefined : translate('Required'),
  fileSize: (size) => (value) =>
    value[0].size <= size
      ? undefined
      : translate('File exceeds the allowed size'),
  composeValidators:
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, _validator) => error || _validator(value),
        undefined
      ),
});

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  if (!name) return 'JD';
  let str = '';
  const nameArr = name.split(' ');
  if (nameArr.length > 1) {
    str = nameArr[0][0] + nameArr[nameArr.length - 1][0];
  } else {
    str = name[0] + name[1];
  }
  return str.toUpperCase();
};

/**
 *
 * @param {*} timestamp
 * @param {*} format
 * @returns
 */
export const timeFormat = (timestamp, format = 'DD/MM/YYYY, HH:mm:ss') => {
  moment.locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE));
  return moment(timestamp).format(format);
};

export const dateFormat = (timestamp, format = 'DD/MM/YYYY') => {
  if (!timestamp) {
    return '';
  }
  moment.locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE));
  return moment(timestamp).format(format);
};

export const dateFormatWithMonth = (timestamp, format = 'DD/MM/YYYY') => {
  moment.locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE));
  return moment(timestamp).format(format);
};

export const timeFromNow = (timestamp) => {
  return moment(timestamp)
    .locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE))
    .fromNow(true);
};

/**
 *
 * @param {Function} t Translator function
 * @param {Number} duration
 * @returns
 */
export const timeDurationFormat = (t) => (duration) => {
  if (duration < 60) return `${duration} ${t('minutes')}`;

  const time = moment.utc(
    moment.duration(duration, 'minutes').asMilliseconds()
  );

  const minutes = time.minutes();
  const hours = time.hours();
  const hourFormatStr = hours === 1 ? t('hour') : t('hours');
  const minuteFormatStr = minutes === 1 ? t('minute') : t('minutes');
  if (!time.minutes()) {
    return time.format(`h [${hourFormatStr}]`);
  }
  return time.format(`h [${hourFormatStr}] mm [${minuteFormatStr}]`);
};

export const removeAscent = (str) => {
  let text = str;
  if (text === null || text === undefined) return text;
  text = text.toLowerCase();
  text = text.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  text = text.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  text = text.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  text = text.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  text = text.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  text = text.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  text = text.replace(/đ/g, 'd');
  return text;
};

export const removeSpace = (str) => {
  let text = str;
  if (text === null || text === undefined) return text;
  text = text.replace(/ /g, '');
  return text;
};

export const getPercentProfit = (plan) => {
  if (plan.budget) {
    return Math.round((Math.abs(plan.profit) / plan.budget) * 10000) / 100;
  }
  return 0;
};


export const getProfitRate = (profit, loss, totalVolume) => {
  const pnl = profit + loss;
  if (totalVolume !== 0) {
    return (pnl / totalVolume) * 100;
  }
  return 0;
};

export const getTimeZone = () => -(new Date().getTimezoneOffset() / 60);

export const convertTimeZone = (time, utc) => {
  if (!time) {
    return '';
  }
  const ar = time.split(':');
  const hour = Number(ar[0]);
  let hourUtc = hour + utc;

  if (hourUtc < 0) {
    hourUtc += 24;
  } else if (hourUtc >= 24) {
    hourUtc -= 24;
  }

  if (hourUtc < 10) {
    hourUtc = `0${hourUtc.toString()}`;
  }

  return `${hourUtc}:${ar[1]}`;
};

export const getPlanStatusConfig = (t) => (plan) => {
  if (plan.current_profit >= plan.take_profit && plan.is_endtarget) {
    return {
      bg: VARIANTS.SUCCESS,
      text: t('Take profit'),
    };
  }
  if (plan.current_profit <= plan.stop_loss && plan.is_endtarget) {
    return {
      bg: VARIANTS.DANGER,
      text: t('Stop loss'),
    };
  }
  if (plan.status === PLAN_STATUS.running) {
    return {
      bg: VARIANTS.PRIMARY,
      text: t('On going'),
    };
  }
  if (plan.status === PLAN_STATUS.stopped) {
    return {
      bg: VARIANTS.SECONDARY,
      text: t('Inactive'),
    };
  }
  return { bg: VARIANTS.SECONDARY, text: '-' };
};

export const numberToString = (value, digit = 0) =>
  abbreviateNumber(value, digit);

export const validateText = (text) => {
  // eslint-disable-next-line
  const re = new RegExp(
    '^([a-z]|[0-9]|[àáâãèéêìíòóôõùúăđĩũơưăạảấầẩẫậắằẳẵặẹẻẽềềểễếệỉịọỏốồổỗộớờởỡợụủứừửữựỳỵýỷỹ]|[ ]|[_]|[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ])+$',
    'gi'
  );
  return re.test(text);
};

export const decimalSub = (val1, val2, decimal) => {
  const x = Math.pow(10, decimal);
  return formatNumber.sliceDecimalFloat((val1 * x - val2 * x) / x, decimal);
};

export const parseArrayMatrix = (blocks) => {
  const newBlocks = [];
  blocks.forEach((block, arBlock) => {
    const newBlock = [];
    const candles = block.split('');

    for (let x = 0; x < 4; x++) {
      // row 0 1 2 3
      for (let y = 0; y < 5; y++) {
        // col 0 1 2 3 4
        newBlock.push({
          code: candles[y + 5 * x ],
          index: y * 4 + x + arBlock * 20
        })
      }
    }

    newBlocks.push(newBlock);
  });

  return newBlocks
}

export const getTimePeriods = (type) => {
  const today = moment().utc().startOf('day');
  const endToday = moment().utc().endOf('day')

  // this week
  const startOfThisWeek = moment().utc().startOf('week');
  const todayThisWeek = moment().utc();

  // last week
  const startOfLastWeek = moment().utc().subtract(1, 'weeks').startOf('week');
  const endOfLastWeek = moment().utc().subtract(1, 'weeks').endOf('week');

  // this month
  const startOfThisMonth = moment().utc().startOf('month');
  const todayThisMonth = moment().utc();

  if (type === 'THIS_WEEK') {
    return { start: startOfThisWeek.valueOf(), end: todayThisWeek.valueOf() }
  } else if (type === 'LAST_WEEK') {
    return { start: startOfLastWeek.valueOf(), end: endOfLastWeek.valueOf() }
  } else if (type === 'THIS_MONTH') {
    return { start: startOfThisMonth.valueOf(), end: todayThisMonth.valueOf() }
  }

  return { start: today.valueOf(), end: endToday.valueOf() }
}

export const getTimeZoneDisplay = () => {
  const now = moment();
  const offset = now.utcOffset();
  const hoursOffset = offset / 60;
  const sign = hoursOffset >= 0 ? '+' : '-';

  return `UTC ${sign}${Math.abs(hoursOffset).toString().padStart(2, '0')}`;
}
