import { TAB } from '../const';
import ResultItem from './ResultItem';

export default function Results({
  result,
  isDefault,
  setTab,
  onRemoveBubble,
  onEdit,
  onDuplicate,
  allowDuplicate,
}) {
  const handleEdit = (index) => {
    onEdit(index);
    setTab(TAB.CANDLE);
  };
  const handleDelete = (index) => {
    onRemoveBubble(index);
  };
  const handleDuplicate = (index) => {
    onDuplicate(index);
    setTab(TAB.CANDLE);
  };
  return (
    <ul className="Results__pattern-candle">
      {result.map((item, idx) => (
        <ResultItem
          key={idx}
          index={idx}
          value={item}
          isDefault={isDefault}
          allowDuplicate={allowDuplicate}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onDuplicate={handleDuplicate}
        />
      ))}
    </ul>
  );
}
