import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { getNotifyPlans } from '@/redux/refactor/notification/actions';
import { updateSelectedPlans } from '@/redux/actions/planActions';
import ButtonLoading from '@/shared/components/ButtonLoading';
import planApi from '@/utils/api/planApi';
import toastHandler from '@/shared/helpers/toastHandler';
import { selectSelectedPlans } from '@/redux/selectors/planSelector';
import sleep from '@/shared/helpers/sleep';
import gtag from '@/shared/constants/gtag';

import './index.scss';

const ga4Stats = window.ga4Stats;

const PopupDeletePlan = ({ show, onHide, currentPlan, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { accountType, selectedPlans } = useSelector(
    (state) => ({
      accountType: state.wallet.accountType,
      selectedPlans: selectSelectedPlans(state)
    }),
    _.isEqual
  );

  const [isLoading, setIsLoading] = useState(false);  

  const handleDeletePlan = async () => {
    try {
      setIsLoading(true);
      const plans = _.isEmpty(currentPlan) ? selectedPlans : [currentPlan];

      for (let index = 0; index < plans.length; index++) {
        await toastHandler(
          t,
          'delete_plan',
          () => planApi.deletePlan(plans[index].id),
          t('Delete the plan: {{planName}} successfully!', {planName: plans[index].name}),
          onSuccess
        );

        ga4Stats(gtag.plan.action.deletePlan, gtag.plan.category, accountType, plans[index].id);

        await sleep(100);
      }

      dispatch(getTotalPlan(accountType));
      dispatch(getNotifyPlans());
      dispatch(updateSelectedPlans([]));
      setIsLoading(false);
      onHide();
    } catch (error) {
      console.log(`Handle delete plan error: ${error}`);
    }
  };
  
  if (!show) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="popup-delete-plan"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <img src="/img/strategy/img_1.png" alt="" className="mb-2" />
        <p className="mb-2 h3">
          {(selectedPlans.length === 1 || !_.isEmpty(currentPlan)) && t('Are you sure you want to delete this plan?')}
          {selectedPlans.length > 1 && t('Are you sure you want to delete these plans?')}
        </p>
        <p className="text">
          {(selectedPlans.length === 1 || !_.isEmpty(currentPlan)) && t(
            'This plan will be deleted immediately. You can’t undo this action.'
          )}
          {selectedPlans.length > 1 && t(
            'These plans will be deleted immediately. You can’t undo this action.'
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {!isLoading && (
          <Button
            variant="light"
            onClick={handleDeletePlan}
            className="text-success popup-share-cta"
            
          >
            {t('Confirm & delete')}
          </Button>
        )}
        {isLoading && <ButtonLoading variant="light" />}
      </Modal.Footer>
    </Modal>
  );
};

PopupDeletePlan.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  currentPlan: PropTypes.instanceOf(Object).isRequired
};

PopupDeletePlan.defaultProps = {
  onSuccess: () => {}
}

export default PopupDeletePlan;
