import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);
  const { t } = useTranslation();
  const copyText = async (text) => {
    try {
      copy(text);
      setCopiedText(text);
      toast.success(t('Copied to clipboard'));
      return true;
    } catch (error) {
      alert(JSON.stringify(error));
      toast.error(error.message);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copyText];
}

export default useCopyToClipboard;
