import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { Notification } from 'iconsax-react';
import notificationApi from '@/utils/api/notificationApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNoti,
  changeStatusNoties,
  updateIdNotiesRendered,
  updateNotiIsActive,
} from '@/redux/actions/notiActions';
import {
  makeGetPageConfigsByType,
  selectIdNotiesRendered,
  selectNotiIsActive,
} from '@/redux/selectors/notiSelector';
import {
  DrawerStyle,
  drawerProps,
} from '@/shared/components/form/drawer/Elements';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import NotificationPopup from './NotificationPopup';
import { NOTIFICATION_TYPE } from '@/shared/constants';

import './index.scss';

const GlobalNotification = () => {
  const dispatch = useDispatch();
  const getGetPageConfigsByType = useMemo(makeGetPageConfigsByType, []);
  const {
    pageConfigsOfTypeSystem,
    pageConfigsOfTypePlan,
    accountType,
    idNotiesRendered,
    currentLangCode,
    isActive,
  } = useSelector(
    (state) => ({
      pageConfigsOfTypeSystem: getGetPageConfigsByType(
        state,
        NOTIFICATION_TYPE.system
      ),
      pageConfigsOfTypePlan: getGetPageConfigsByType(
        state,
        NOTIFICATION_TYPE.plan
      ),
      idNotiesRendered: selectIdNotiesRendered(state),
      isActive: selectNotiIsActive(state),
      accountType: state.wallet.accountType,
      currentLangCode: state.appConfig.languageCode,
    }),
    _.isEqual
  );

  const isPc = useMediaQuery(Devices.pc);
  const drawerProp = isPc ? drawerProps.right : drawerProps.mobile;
  const [totalUnread, setTotalUnread] = useState(0);

  const handleClosePopup = () => {
    notificationApi
      .updateReadNoties(idNotiesRendered)
      .then((res) => {
        if (res.data.ok) {
          dispatch(changeStatusNoties(idNotiesRendered));
          dispatch(updateIdNotiesRendered([]));
        }
      })
      .catch((err) => {
        console.log(`Update read noties error: ${err}`);
      });

    dispatch(updateNotiIsActive(false));
  };

  useEffect(() => {
    setTotalUnread(
      pageConfigsOfTypeSystem?.unread + pageConfigsOfTypePlan?.unread
    );
  }, [pageConfigsOfTypeSystem?.unread, pageConfigsOfTypePlan?.unread]);

  useEffect(() => {
    dispatch(
      fetchNoti({
        lang: currentLangCode,
        account_type: accountType,
      })
    );
  }, [accountType]);

  return (
    <div className="notification">
      <div
        className="notification-icon-wrapper"
        onClick={() => dispatch(updateNotiIsActive(true))}
      >
        <Notification
          size="24"
          color="currentColor"
          className="notification-icon"
        />
        {!!totalUnread && (
          <span className="notification-count-new">
            <span>{totalUnread}</span>
          </span>
        )}
      </div>

      <DrawerStyle
        anchor="right"
        open={isActive}
        onClose={handleClosePopup}
        {...drawerProp}
        className="notification-popup"
      >
        {isActive && <NotificationPopup closePopup={handleClosePopup} />}
      </DrawerStyle>
    </div>
  );
};

export default GlobalNotification;
