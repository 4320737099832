import { isEqual } from 'lodash';
import { WALLET_TYPE } from '@/config/const';
import {
  getUsdtAddress,
  getWithdrawConfig,
  switchAccountType,
} from '@/redux/refactor/wallet/actions';
import { colorGreyScale600, colorText } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { RecoveryConvert } from 'iconsax-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Icons from '../../icons';
import CardBalanceInfo from '../components/CardBalanceInfo';
import ListTransaction from '../components/ListTransaction';
import { CardBody, CardStyle, CardTitle } from './styles';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const PanelInfo = withTranslation()(
  ({ t, setFocusedIdx, show, onClose, setSelected }) => {
    const dispatch = useDispatch();
    
    const { profile, accountType } = useSelector(
      (state) => ({
        profile: state.user.profile,
        accountType: state.wallet.accountType,
      }),
      isEqual
    );

    const handleSwitch = () => {
      dispatch(switchAccountType());
      ga4Stats(gtag.wallet.action.switchWallet, gtag.wallet.category, gtag.wallet.label, profile?.uid);
    };
    const handleDeposit = () => {
      dispatch(getUsdtAddress());
      setFocusedIdx(1);
      ga4Stats(gtag.wallet.action.deposit, gtag.wallet.category, gtag.wallet.label, profile?.uid);
    };
    const handleWithdraw = () => {
      dispatch(getWithdrawConfig());
      setFocusedIdx(2);
    };
    const handleTransfer = () => {
      setFocusedIdx(3);
    };

    const handleItemClick = (value) => {
      setSelected(value);
      setFocusedIdx(4);
    };

    if (!show) return <div />;
    return (
      <div>
        <CardStyle className="wallet-info">
          <CardTitle>
            <Box flex={1}>{t('Exchange wallet')}</Box>
            <Box className="drawer-close" onClick={onClose}>
              <Icons.CloseIcon />
            </Box>
          </CardTitle>
          <CardBody>
            <Box mb="32px">
              <SwitchMode mb="12px">
                <span className="btn-switch-wallet">
                  <span role="none" onClick={handleSwitch}>
                    <RecoveryConvert size={16} />
                    {t('Switch to {{accountType}} Mode', {
                      accountType:
                        accountType === WALLET_TYPE.DEMO
                          ? WALLET_TYPE.LIVE
                          : WALLET_TYPE.DEMO,
                    })}
                  </span>
                </span>
              </SwitchMode>
              <CardBalanceInfo
                handleWithdraw={handleWithdraw}
                handleDeposit={handleDeposit}
                handleTransfer={handleTransfer}
              />
            </Box>
            <Box>
              <Title>{t('Latest Transactions')}</Title>
              <ListTransaction onItemClick={handleItemClick} />
            </Box>
          </CardBody>
        </CardStyle>
      </div>
    );
  }
);

const Title = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #747a80;
`;

const SwitchMode = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: ${colorText};
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${colorGreyScale600};
  }
`;

export default PanelInfo;
