export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const SHOW_MOBILE_HEADER = 'SHOW_MOBILE_HEADER';
export const SHOW_MOBILE_FOOTER = 'SHOW_MOBILE_FOOTER';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_URL_AFTER_LOGIN = 'SET_URL_AFTER_LOGIN';
export const UPDATE_SCREEN_SIZE = 'UPDATE_SCREEN_SIZE';

export function changeThemeToDark() {
  return {
    type: CHANGE_THEME_TO_DARK,
  };
}

export function changeThemeToLight() {
  return {
    type: CHANGE_THEME_TO_LIGHT,
  };
}

export function setShowMobileHeader(payload) {
  return {
    type: SHOW_MOBILE_HEADER,
    payload,
  };
}

export function setShowMobileFooter(payload) {
  return {
    type: SHOW_MOBILE_FOOTER,
    payload,
  };
}

export function checkMobileScreen(payload) {
  return {
    type: SET_IS_MOBILE,
    payload: payload < 1024,
  };
}

export function setUrlAfterLogin(payload) {
  return {
    type: SET_URL_AFTER_LOGIN,
    payload: payload,
  };
}


export function changeScreenSize(payload) {
  return {
    type: UPDATE_SCREEN_SIZE,
    payload: payload,
  };
}