import { Field } from 'react-final-form';
import { Grid, Form } from '@/shared/components/form/drawer/Elements';
import formatNumber from '@/shared/helpers/formatNumber';
import { InputNumberGroup } from '@/shared/components/form/InputNumberGroup';
import { borderDefaultStyle } from '@/utils/palette';
import { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertMaskToNum, isInteger } from '../validator';
import { CollapseStyle } from './FormElements';
import SettingItem from './SettingItem';
import SubProfitInput from './SubProfitInput';

export function SubProfit({ values = {}, show = false, mutators }) {
  const { subProfit, budget } = values;
  const { t } = useTranslation();
  const onSetTP = (value) => {
    let amount = (Number(value) / 100) * convertMaskToNum(budget);
    if (!Number.isInteger(amount)) {
      amount = Number.parseInt(amount * 100, 10) / 100;
    }
    mutators.setSubProfit(amount);
  };
  const onSetSL = (value) => {
    let amount = (Number(value) / 100) * convertMaskToNum(budget);
    if (!Number.isInteger(amount)) {
      amount = Number.parseInt(amount * 100, 10) / 100;
    }
    mutators.setSubStopLoss(amount);
  };

  useEffect(() => {
    mutators.setShowSubProfit(show);
  }, [show]);

  if (!show) return null;
  return (
    <>
      <Grid.Row className="border-none">
        <Grid.Cell>
          <SettingItem
            name="subProfit"
            title={t('FormCreatePlan_sub_target_label')}
            desc={t('FormCreatePlan_sub_target_desc')}
          />
          <ErrorWarning />
        </Grid.Cell>
      </Grid.Row>
      <CollapseStyle in={subProfit} unmountOnExit>
        <div>
          <BorderRow cols={2}>
            <Grid.Cell>
              <SubProfitInput
                label={t('FormCreatePlan_sub_target_tp_label')}
                placeholder="$0"
                name="sub_take_profit"
                onSelect={onSetTP}
                type="pos"
              />
            </Grid.Cell>
            <Grid.Cell>
              <SubProfitInput
                label={t('FormCreatePlan_sub_target_sl_label')}
                placeholder="$0"
                name="sub_stop_loss"
                onSelect={onSetSL}
                type="neg"
              />
            </Grid.Cell>
          </BorderRow>
          <BorderRow cols={2}>
            <Grid.Cell className="sub-win-streak-condition">
              <Form.Label>3. {t('Sub Win Streak')}</Form.Label>
              <Field
                name="sub_win_streak"
                component={InputNumberGroup}
                initial={1}
                placeholder="0"
                inputType="text"
                validate={isInteger}
              />
            </Grid.Cell>
            <Grid.Cell className="sub-lose-streak-condition">
              <Form.Label>4. {t('Sub Lose Streak')}</Form.Label>
              <Field
                name="sub_lose_streak"
                component={InputNumberGroup}
                initial={1}
                placeholder="0"
                inputType="text"
                validate={isInteger}
              />
            </Grid.Cell>
          </BorderRow>
        </div>
      </CollapseStyle>
    </>
  );
}

function ErrorWarning() {
  const { t } = useTranslation();
  const { errors = {} } = useFormState();
  const show =
    errors.sub_take_profit === 'amount_too_large' || errors.sub_stop_loss === 'amount_too_large';

  if (!show) return null;
  return (
    <AlertStyle variant="danger">
      {t('FormCreatePlan_sub_target_exceed_error', {
        amount: formatNumber.numberWithCommas(1000000),
      })}
    </AlertStyle>
  );
}

const AlertStyle = styled(Alert)`
  background-color: rgba(255, 35, 35, 0.1);
  border: none;
  border-radius: 5px;
  color: #fd8181;
  font-size: 12px;
  font-weight: 600;
  line-height: 160%;
  padding: 8px 24px;
  margin: 1rem 0 0 0;
  text-align: center;
  width: 100%;
`;

const BorderRow = styled(Grid.Row)`
  width: 100%;
  border-bottom: ${borderDefaultStyle}!important;
  &:first-child {
    border-top: ${borderDefaultStyle};
  }
`;
