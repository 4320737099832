import React from 'react';
import { withTranslation } from 'react-i18next';
import SettingItem from './SettingItem';

// Begin ReverseSignal
export default withTranslation()(
  ({ show, reversePseudo, t }) =>
    show && (
      <>
        <SettingItem
          name="reverse_signal"
          title={t('FormCreatePlan_advanced_reverseSignal_label')}
          desc={t(reversePseudo ? 'FormCreatePlan_advanced_reverseSignal_reverse_pseudo_desc' : 'FormCreatePlan_advanced_reverseSignal_desc')}
        />
      </>
    )
);
// End ReverseSignal
