import React from "react";
import PropTypes from 'prop-types';
import {
  Add,
} from 'iconsax-react';
import { useHistory } from 'react-router-dom';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import useAuth from '@/hooks/useAuth';
import { Button } from '@/shared/components/Button';
import { useTranslation } from 'react-i18next';
import insightApi from "@/utils/api/insightApi";
import { withPlanCrud } from '@/context/PlanCrudProvider';

const CreatePlan = ({ chartId, planCrud }) => {
  const history = useHistory();
  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const handleCreatePlan = () => {
    if (!isAuth) {
      history.push('/login')
    } else {
      insightApi.getSignalBudgetDefaultFromChartId(chartId).then(res => {
        if (res.data.ok) {
          const {budgetStrategyId, signalStrategyId, telegrams} = res.data.d;
          planCrud.handleCreate({
            signal_type: signalStrategyId ? SIGNAL_STRATEGY_TYPE.botai : SIGNAL_STRATEGY_TYPE.telegram,
            bot_ai_id: signalStrategyId ? signalStrategyId.toString() : '',
            budget_strategy_id: budgetStrategyId ? budgetStrategyId.toString() : '',
            telegram_chanels: telegrams ? telegrams.map(item => ({value: item.id, label: item.value})) : '',
          });
        }
      });
    }
  }

  return (
    <Button variant="success" onClick={() => handleCreatePlan()}>
      <Add
        size="24"
        color="#fff"
      />
      {t('Create Plan')}
    </Button>
  )
};

CreatePlan.propTypes = {
  chartId: PropTypes.string.isRequired,
};

export default withPlanCrud(CreatePlan);