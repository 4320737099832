/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Carousel from '@/shared/components/carousel/CarouselSingle';

import { Box } from '@material-ui/core';
import { Devices } from '@/utils/palette';
import { AccountContent } from './AccountElements';

const data = [
  {
    id: 0,
    src: `${process.env.PUBLIC_URL}/img/account/banner-1.png`,
    title: {
      width: '559px',
      text: 'The Emerging Era of Digital Asset Investment',
    },
    description: {
      width: '561px',
      text: 'Thriving to provide the best possible trading experience to all traders, Quickinvest is owing unique features, and effective money-making strategies.',
    },
  },
  {
    id: 1,
    src: `${process.env.PUBLIC_URL}/img/account/banner-2.png`,
    title: {
      width: '472px',
      text: 'All You Need For Successful Trading',
    },
    description: {
      width: '472px',
      text: 'Quickinvest offers everything you need on a single platform with a user-friendly interface that facilitates world-class trading!',
    },
  },
  {
    id: 2,
    src: `${process.env.PUBLIC_URL}/img/account/banner-3.png`,
    title: {
      width: '472px',
      text: 'Gain Profits With Powerful Trading Tools',
    },
    description: {
      width: '472px',
      text: 'Quickinvest provides highly sophisticated, yet user-friendly indicators that support traders in making more profitable trading decisions.',
    },
  },
];

const RightBanner = () => {
  const { t } = useTranslation();
  return (
    <AccountSlide>
      <AccountBanner>
        <Carousel>
          {data.map(item => (
            <div key={item.id}>
              <div className='slide__image'>
                <img src={item.src} alt={`slide_${item.src}`} />
              </div>
              <div className='slide__description'>
                <AccountBannerTitle
                  maxWidth={item.title.width}
                >{t(item.title.text)}
                </AccountBannerTitle>
                <AccountBannerDesc
                  maxWidth={item.description.width}
                >{t(item.description.text)}
                </AccountBannerDesc>
              </div>
            </div>
          ))}
        </Carousel>
      </AccountBanner>
    </AccountSlide>
  );
};

const AccountSlide = styled(AccountContent)`
  background: #0caf60;
  padding: 0 !important;
  width: 100%;
  align-items: center;
  overflow: hidden;
  display: none;
  @media ${Devices.laptop} {
    display: flex;
  }
`;

const AccountBanner = styled(AccountContent)`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
  .slick-arrow {
    display: none !important;
  }
  .slide__image {
    overflow: hidden;
    display: flex;
    img {
      object-fit: cover;
      width: 100%;
      min-height: auto;
    }
  }
  .slide__description {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
  }
  .slick-slide {
    > div > div {
      height: 100vh;
      display: flex!important;
      flex-direction: column;
      gap: 24px
    }
  }
  .slick-dots {
    bottom: 8px;
  }
`;

const AccountBannerTitle = styled(Box)`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  max-width: 559px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const AccountBannerDesc = styled(Box)`
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #ceefdf;
  max-width: 561px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

export default RightBanner;
