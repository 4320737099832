import React from 'react';
import { dropdownBackground, dropdownItemBackground, dropdownShadow } from '@/utils/palette';
import { NavDropdown as BsNavDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';


export const NavDropdown = ({ align, title, items = {} }) => (
  <NavDropdownStyle align={align} title={title}>
    {items.map(item => <NavDropdownItemStyle key={item.key} />)}
  </NavDropdownStyle>
);

NavDropdown.propTypes = {
  align: PropTypes.oneOf(['left']),
  title: PropTypes.node,
  items: PropTypes.arrayOf(Object),
};

NavDropdown.defaultProps = {
  align: 'left',
  title: null,
  items: [],
};


export const NavDropdownStyle = styled(BsNavDropdown)`
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 213px;
    padding: 11.6712px;
    background: ${dropdownBackground};
    box-shadow: ${dropdownShadow};
    border-radius: 15.5616px;
    margin-top: 16px;
    border: none;
  }
`;

export const NavDropdownItemStyle = styled(BsNavDropdown.Item)`
  padding: 11.6712px;
  border-radius: 7.78082px;
  gap: 13px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${dropdownItemBackground};
    font-weight: 700;
  }
  svg {
    width: 19.4px;
  }
`;
