import { IS_PROD, getDocumentUrl } from '@/config/const';
import useAuth from '@/hooks/useAuth';
import Icons from '@/shared/components/icons';
import {
  VARIANTS,
  borderDefaultStyle,
  colorGreyScale50,
  colorGreyScale600,
  colorGreyScale900,
  colorSuccess,
  sidebarBackground,
} from '@/utils/palette';
import { Box, Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import theme from 'styled-theming';
import InfoBlock from './InfoBlock';
import Menu, { menuKeys } from './Menu';

const DrawerSettings = ({ open, onClose, toggleChangeLang }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { logout } = useAuth();
  const handleMenuClick = (key) => {
    switch (key) {
      case menuKeys.Info:
        history.push('/settings/infomation');
        break;
      case menuKeys.Security:
        history.push('/settings/security');
        break;
      case menuKeys.Kyc:
        history.push('/settings/verification');
        break;
      case menuKeys.Exchange:
        history.push('/settings/exchange');
        break;
      case menuKeys.plan:
        history.push('/settings/plan');
        break;
      case menuKeys.Lang:
        toggleChangeLang();
        break;
      case menuKeys.Support:
        history.push('/help/faqs');
        break;
      case menuKeys.Documents:
        window.open(getDocumentUrl(), '_blank');
        break;
      default:
        break;
    }
    onClose();
  };
  return (
    <DrawerStyle anchor="right" open={open} onClose={onClose}>
      <Container>
        <Header>
          <Back onClick={onClose} variant={VARIANTS.LINK}>
            <Icons.ChevronLeftIcon /> {t('Back')}
          </Back>
        </Header>
        <Body>
          <InfoBlock />
          <Box mb="24px" />
          <Menu onMenuSelect={handleMenuClick} />
          <LogoutButton onClick={logout}>{t('Log out')}</LogoutButton>
          {!IS_PROD && window.device_name && (
            <Box style={{ opacity: 0.5 }}>
              <LogoutButton disabled>
                <Box fontWeight={500}>{window.device_name}</Box>
              </LogoutButton>
            </Box>
          )}
        </Body>
      </Container>
    </DrawerStyle>
  );
};

DrawerSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleChangeLang: PropTypes.func.isRequired,
};

const DrawerStyle = styled(Drawer)`
  position: relative;
`;
const Back = styled(Box)`
  color: ${colorSuccess};
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  svg {
    height: 24px;
    width: 24px;
    path {
      stroke: ${colorSuccess};
    }
  }
`;
const Container = styled(Box)`
  height: 100vh;
  width: 100vw;
  background: ${theme('mode', {
    dark: colorGreyScale900,
    light: colorGreyScale50,
  })};
  display: flex;
  flex-direction: column;
  padding-top: 58px;
  overflow-y: auto;
`;
const Header = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${sidebarBackground};
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: ${borderDefaultStyle};
`;
const Body = styled(Box)`
  padding: 24px 16px;
  flex: 1;
`;
const LogoutButton = styled(Box)`
  background: ${sidebarBackground};
  border-radius: 12px;
  text-align: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${colorGreyScale600};
  margin-top: 12px;
  padding: 13.5px;
`;

export default DrawerSettings;
