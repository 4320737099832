import {
  BalanceInput,
  BtnBudget,
  DefaultBudgetWrap,
} from '@/shared/components/portfolio/form/components/FormElements';
import {
  checkBaseAmount,
  convertMaskToNum,
} from '@/shared/components/portfolio/form/validator';
import { validatorHelper } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  colorGreyScale200,
  colorGreyScale500,
  colorGreyScale700,
  colorGreyScale900,
  colorSuccess,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styled-theming';

const defaultBudget = [1, 5];

const DefaultBudgetWrapStyle = styled(DefaultBudgetWrap)`
  flex-wrap: nowrap;
  margin-top: 0;
  gap: 0;
  button {
    border: none;
    border-radius: 8px;
    padding: 4.5px 12px;
    font-weight: 500;
    font-size: 12px;
    color: ${colorGreyScale500};
    &.active {
      color: ${colorSuccess};
      background-color: ${theme('mode', {
        light: '#fff',
        dark: colorGreyScale900,
      })};
    }
  }
`;

const BalanceInputStyle = styled(BalanceInput)`
  border: none;
  border-radius: 8px;
  padding: 4.5px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  width: 90px;
  line-height: 150%;
  color: ${colorGreyScale500};
  &::placeholder {
    color: ${colorGreyScale500};
  }
  &.active {
    background: #fff;
    color: ${colorSuccess};
  }
`;

const SelectAmountWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0;
  border-radius: 8px;
  background-color: ${theme('mode', {
    light: colorGreyScale200,
    dark: colorGreyScale700,
  })};
  padding: 2px;
`;

// Begin PlanBudget
export default withTranslation()(({ form, t, values }) => {
  const onSelect = (value) => {
    document.getElementById('base-amount-input').value = null;
    form.mutators.setBaseAmount(value);
  };
  const validator = validatorHelper(t);

  const handleAmountChange = (evt) => {
    const value = evt.target.value || '';
    if (value.length === 0) {
      form.mutators.setBaseAmount(null);
    } else {
      const num = convertMaskToNum(value);
      form.mutators.setBaseAmount(num);
    }
  };

  const defaultValue = !defaultBudget.includes(values.baseAmount)
    ? values.baseAmount
    : null;

  return (
    <Box>
      <Field
        name="baseAmount"
        component="input"
        type="hidden"
        validate={validator.composeValidators(
          validator.required,
          checkBaseAmount
        )}
      />
      <SelectAmountWrap className="base-amount-select">
        <DefaultBudgetWrapStyle>
          {defaultBudget.map((item) => (
            <BtnBudget
              type="button"
              onClick={() => onSelect(item)}
              className={item === values.baseAmount ? 'active' : ''}
              key={item}
            >
              {formatNumber.formatCurrency(item)}
            </BtnBudget>
          ))}
        </DefaultBudgetWrapStyle>
        <Box>
          <BalanceInputStyle
            id="base-amount-input"
            defaultValue={defaultValue}
            placeholder={t('FormCreatePlan_baseInfo_budget_placeholder')}
            onChange={handleAmountChange}
            className={
              !defaultBudget.includes(values.baseAmount) ? 'active' : ''
            }
          />
        </Box>
      </SelectAmountWrap>
    </Box>
  );
});
// End PlanBudget
