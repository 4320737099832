/* eslint-disable react/prop-types */
import { isEmpty, isEqual } from 'lodash';
import {
  FormHint,
  FormSelect,
  RadioAsButton,
} from '@/containers/Dashboard/BackTest/form/PortfolioElements';
import { getBotAndMethod } from '@/containers/Portfolio/helpers';
import { LinkToTelegram } from '@/shared/components/portfolio/form/components/SelectChannel';
import { checkTelegram } from '@/shared/components/portfolio/form/validator';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { Box } from '@material-ui/core';
import React, { useMemo, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Add } from 'iconsax-react';
import { FILTER_TIME } from '@/config/const';

import { updateBacktestMoreConditions } from '@/redux/actions/planActions';
import CustomDateTimePicker from '@/shared/components/custom-datetime-picker/CustomDateTimePicker';
import MoreConditions from '../MoreConditions';

const getDefaultTimeRange = () => {
  const from = new Date();
  const to = new Date();

  from.setDate(from.getDate() - 1);
  from.setHours(0);
  from.setMinutes(0);

  to.setDate(to.getDate() - 1);
  to.setHours(23);
  to.setMinutes(59);

  return { fromDate: from, toDate: to };
};

export function SignalType() {
  const { t } = useTranslation();
  return (
    <div className="block">
      <p className="subTitle">
        {t('To test run the investment plan, you need to choose a type of signal')}
      </p>
      <Box display="flex" gridGap="12px">
        <Field
          name="signal_type"
          label="Bot AI"
          value={SIGNAL_STRATEGY_TYPE.botai}
          id={SIGNAL_STRATEGY_TYPE.botai}
          type="radio"
          component={RadioAsButton}
        />
        <Field
          name="signal_type"
          label="Telegram signal"
          value={SIGNAL_STRATEGY_TYPE.telegram}
          id={SIGNAL_STRATEGY_TYPE.telegram}
          type="radio"
          component={RadioAsButton}
        />
      </Box>
    </div>
  );
}

export function SelectBot({ data = [], value, show }) {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      data.map((item) => ({
        value: item.id,
        label: item.is_default ? t(item.name) : item.name,
      })),
    [data, t]
  );
  const selected = getBotAndMethod(
    value?.value || (value && value[0] && value[0].values) || data[0]?.id,
    data
  );
  if (!show) return null;
  return (
    <div className="block">
      <p className="subTitle">{t('Next step, select the Bot type')}</p>
      <Field
        name="bot_ai"
        component={FormSelect}
        options={options}
        menuPlacement="auto"
        isClearable={false}
        isMulti={false}
      />
      {selected.botName && !selected.copy_from && (
        <FormHint>
          {t('{{botName}} use method: {{methodName}}', {
            botName: selected?.is_default ? t(selected?.botName) : selected?.botName,
            methodName: selected?.methodName,
          })}
        </FormHint>
      )}
    </div>
  );
}

export function SelectChannel({ data = [], values = [], show }) {
  const { t } = useTranslation();
  const options = useMemo(() => data.map((item) => ({ value: item.id, label: item.name })), [data]);
  if (!show) return null;
  return (
    <div className="block">
      <p className="subTitle">{t('Next step, select the Telegram channel')}</p>
      <Field
        name="telegram_chanels"
        component={FormSelect}
        options={options}
        menuPlacement="auto"
        isMulti
        validate={checkTelegram}
        closeMenuOnSelect={false}
        isClearable={false}
        isOptionDisabled={() => values?.length >= 5}
      />
      <LinkToTelegram channels={data} values={values} />
    </div>
  );
}

export function SelectBudgetStrategy({ data = [], value }) {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      data.map((item) => ({
        value: item.id,
        label: item.is_default ? t(item.budget_name) : item.budget_name,
      })),
    [data, t]
  );
  const selected = data.find(
    (item) =>
      item.id.toString() === (value?.value || (value && value[0] && value[0].value))?.toString()
  );
  return (
    <div className="block">
      <p className="subTitle">
        {t('Then, choose a budget strategy to enhance investment efficiency.')}
      </p>
      <Field
        name="budget_strategy"
        component={FormSelect}
        options={options}
        menuPlacement="auto"
        isClearable={false}
        isMulti={false}
      />
      {selected && (
        <FormHint>
          {selected.is_default ? t(selected.budget_name) : selected.budget_name} {t('use method:')}{' '}
          {selected.method_type}
        </FormHint>
      )}
    </div>
  );
}

export function BacktestOptions({ onShowMoreConditions }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { backtestMoreConditions } = useSelector(
    (state) => ({
      backtestMoreConditions: state.plan.backtestMoreConditions,
    }),
    isEqual
  );
  const [timeRange, setTimeRange] = useState({});

  const handleDateChange = (val) => {
    setTimeRange(val.timeOpts);
  };

  useEffect(() => {
    setTimeRange(getDefaultTimeRange());
  }, []);

  useEffect(() => {
    if (!isEmpty(timeRange)) {
      dispatch(
        updateBacktestMoreConditions({
          ...backtestMoreConditions,
          selected_date: `${timeRange?.fromDate?.getFullYear()}-${
            timeRange?.fromDate?.getMonth() + 1
          }-${timeRange?.fromDate?.getDate()}`,
        })
      );
    }
  }, [timeRange]);

  return (
    <div className="block">
      <p className="subTitle">{t('Backtest options')}</p>
      <Box display="flex" flexDirection="column" gridGap="12px">
        <CustomDateTimePicker
          onChange={handleDateChange}
          type={FILTER_TIME.CUSTOM_DATE}
          timeOpts={timeRange}
          calendarOnly
          showIcon
          isShowTimeRange={false}
          defaultDate={new Date(Date.now() - 86400000)}
          validDays={7}
        />
        {(!backtestMoreConditions?.subProfit || (backtestMoreConditions?.subProfit && (!backtestMoreConditions?.sub_take_profit && !backtestMoreConditions?.sub_stop_loss && !backtestMoreConditions?.sub_win_streak && !backtestMoreConditions?.sub_lose_streak))) &&
          !backtestMoreConditions?.reverse_signal &&
          !backtestMoreConditions?.wait_signal && (
            <button
              className="backtest-add-conditions text-success"
              type="button"
              onClick={onShowMoreConditions}
            >
              <Add size={28} className="m-0" /> {t('Add more conditions')}
            </button>
          )}
        {(backtestMoreConditions?.subProfit && (backtestMoreConditions?.sub_take_profit || backtestMoreConditions?.sub_stop_loss || backtestMoreConditions?.sub_win_streak || backtestMoreConditions?.sub_lose_streak) ||
          backtestMoreConditions?.reverse_signal ||
          backtestMoreConditions?.wait_signal) && (
          <MoreConditions
            moreConditions={backtestMoreConditions}
            onShowMoreConditions={onShowMoreConditions}
          />
        )}
      </Box>
    </div>
  );
}
