import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'react-bootstrap';

const SkeletonLoading = () => (
	<div className="overflow-hidden">
		<Row>
			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>

			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>

			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>
		</Row>
		
		<Row>
			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>

			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>

			<Col md="4">
				<div className="p-4">
					<div className="d-flex alight-items-center">
						<Skeleton height={26} width={26} circle />
						<Skeleton height={12} width={100} className="ms-2" />
					</div>
					<Skeleton height={1} className="mt-4 mb-4" />
					<Skeleton height={26} width={150} className="mb-3" />
					<Skeleton height={26} width={100} />
				</div>
			</Col>
		</Row>
	</div>
);

export default SkeletonLoading;
