/* eslint-disable camelcase */
import decode from 'jwt-decode';
import { USER_TOKEN, DEVICE_TOKEN } from '../config/const';
import { generic } from './generic';

export function setToken(token) {
  generic.setJsonLocalStorage(USER_TOKEN, token);
}

export function getToken() {
  return generic.getJsonLocalStorage(USER_TOKEN);
}

export function removeToken() {
  generic.removeLocalStorage(USER_TOKEN);
}

export function getDeviceToken() {
  return generic.getJsonLocalStorage(DEVICE_TOKEN);
}

export function setDeviceToken(token) {
  return generic.setJsonLocalStorage(DEVICE_TOKEN, token);
}

export function decodeJwt(encoded) {
  try {
    return decode(encoded);
  } catch (e) {
    return {};
  }
}

export function getAccessToken() {
  const token = getToken();
  if (token) {
    const { access_token } = token;
    const decodedAccessToken = decodeJwt(access_token);
    if (!decodedAccessToken.exp) {
      window.logError('ACCESS_TOKEN_INVALID');
      return undefined;
    }
    return access_token;
    // const expiryDate = new Date(0);
    // expiryDate.setUTCSeconds(decodedAccessToken.exp - 10);
    // if (!!access_token && expiryDate > new Date()) {
    //   return access_token;
    // }
    // window.logError('ACCESS_TOKEN_EXPIRED_' + decodedAccessToken.exp);
  }
  return undefined;
}

export function getRefreshToken() {
  const token = getToken();
  if (token) {
    const { refresh_token } = token;
    const decodedRefreshToken = decodeJwt(refresh_token);
    if (!decodedRefreshToken.exp) {
      window.logError('REFRESH_TOKEN_INVALID');
      return undefined;
    }
    return refresh_token;
    // const expiryDate = new Date(0);
    // expiryDate.setUTCSeconds(decodedRefreshToken.exp - 10);

    // if (!!refresh_token && expiryDate > new Date()) {
    //   return refresh_token;
    // }
    // window.logError('REFRESH_TOKEN_EXPIRED_' + decodedRefreshToken.exp);
  }
  return undefined;
}

function until(conditionFunction) {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    else setTimeout((_) => poll(resolve), 10);
  };
  return new Promise(poll);
}

let hubConnection = null;
export async function getHubConnection() {
  await until(() => hubConnection && hubConnection.state === 'Connected');
  return hubConnection;
}

export function setHubConnection(connection) {
  hubConnection = connection;
}
