import React, { memo, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import useTotalHistory from '@/hooks/queries/useTotalHistory';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import { getOptionsTypeBot } from '@/redux/actions/botActions';

import { Button } from '../../../Button';
import { Form } from '../../../form/drawer/Elements';
import { createDefaultValues } from '../createDefaultValues';
import { mutators } from '../mutators';
import { advanceValidate } from '../validator';
import { createBody as createBodyOptionBacktest } from '@/containers/Dashboard/BackTest/drawer/DrawerCreateOptionsBackTest/createBody';

import FormAdvancedOptions from './FormAdvancedOptions';
import FormBaseInfo from './FormBaseInfo';
import FormReview from './FormReview';
import FormSignal from './FormSignal';
import ReachTarget from './ReachTarget';
import ResetBudgetConfig from './ResetBudgetConfig';
import { TimersConfig } from './TimersConfig';

const FORM_STEP = {
  INPUT: 'input',
  REVIEW: 'review',
};

export const FormContainer = ({ currentPlan, submit, onClose, isFetching, onOpenBT, isEdit }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(FORM_STEP.INPUT);

  useEffect(() => {
    dispatch(getOptionsTypeBot());
  }, []);

  const initialValues = useMemo(() => createDefaultValues(isEdit, currentPlan), []);

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      mutators={mutators}
      validate={advanceValidate}
      className="form-create-plan"
    >
      {({ form, handleSubmit, invalid, values, errors }) => (
        <>
          <Form.Header onClose={onClose} />
          <Form.Body>
            {FORM_STEP.INPUT === step && (
              <div className="d-flex flex-column gap-4">
                <FormBaseInfo isEdit={isEdit} />
                <FormSignal
                  isEdit={isEdit}
                />
                {values.signal_type !== SIGNAL_STRATEGY_TYPE.expert && <ResetBudgetConfig />}
                {isEdit && (
                  <TimersConfig
                    variant="v2"
                    timers={currentPlan.timers ? currentPlan.timers : []}
                    onClose={onClose}
                  />
                )}
                <ReachTarget
                  mutators={form.mutators}
                  signalType={values.signal_type}
                  reachTarget={values.reachTarget}
                  budget={values.budget}
                  isShowTotalNumber
                />
                <FormAdvancedOptions isEdit={isEdit} />
              </div>
            )}
            {FORM_STEP.REVIEW === step && <FormReview isEdit={isEdit} />}
          </Form.Body>
          <Footer
            values={values}
            isSubmitting={isFetching}
            onOpenBT={onOpenBT}
            onClose={onClose}
            handleSubmit={handleSubmit}
            invalid={invalid}
            step={step}
            setStep={setStep}
            isEdit={isEdit}
          />
        </>
      )}
    </Form>
  );
};

const Footer = memo(
  ({ values, isSubmitting, onOpenBT, onClose, handleSubmit, invalid, step, setStep, isEdit }) => {
    const { t } = useTranslation();
    const isInputStep = FORM_STEP.INPUT === step;
    const isExpert = values.signal_type === SIGNAL_STRATEGY_TYPE.expert;
    const handleNext = () => {
      if (FORM_STEP.INPUT === step && !isEdit) {
        setStep(FORM_STEP.REVIEW);
      } else {
        handleSubmit();
      }
    };
    return (
      <Form.Footer className="footer-create-plan">
        {isInputStep && (isEdit || isExpert) && (
          <Button variant="secondary_outline" block="true" className="cta-back" onClick={onClose}>
            {t('Close')}
          </Button>
        )}
        {isInputStep && !isEdit && !isExpert && (
          <BtnRunBacktest onOpenBT={onOpenBT} values={values} />
        )}
        {!isInputStep && (
          <Button
            variant="secondary_outline"
            block="true"
            className="cta-back"
            onClick={() => setStep(FORM_STEP.INPUT)}
            disabled={isSubmitting}
          >
            {'< '} {t('Back')}
          </Button>
        )}
        <Button
          disabled={invalid || isSubmitting}
          onClick={handleNext}
          variant="success"
          block="true"
          isLoading={isSubmitting}
        >
          {t(FORM_STEP.INPUT === step && !isEdit ? 'Next Step' : 'Confirm & save')}
          {FORM_STEP.INPUT === step && !isEdit && ' >'}
        </Button>
      </Form.Footer>
    );
  }
);

function BtnRunBacktest({ onOpenBT, values }) {
  const { t } = useTranslation();
  const { refetch, data = {}, isLoading } = useTotalHistory();
  const { mutate, isSubmitting } = useMutation({
    mutationFn: async () => {
      const backtestOption = createBodyOptionBacktest(values);
      const params = {
        budget_strategy_id: values.budget_strategy_id,
        signal_type: values.signal_type,
      };
      if (values.signal_type === SIGNAL_STRATEGY_TYPE.botai) {
        params.bot_ai_id = values.bot_ai_id;
      } else {
        params.telegram_chanels = values?.telegram_chanels?.reduce(
          (acc, item) => [...acc, item?.value?.toString()],
          []
        );
      }
      const { data } = await planApi.createBacktest({ ...params, ...backtestOption });
      return data;
    },
    onSuccess: ({ ok, m, d = {} }) => {
      if (ok) {
        refetch();
        onOpenBT({
          id: d.id,
        });
      } else {
        toast.error(t(m));
      }
    },
    onError: (e) => {
      toast.error(t(e.message));
    },
  });

  return (
    <StyledBackTest
      variant="secondary_outline"
      block="true"
      onClick={mutate}
      isLoading={isLoading || isSubmitting}
      disabled={isLoading || isSubmitting || data?.totalToDay >= data?.limitPerDay}
      className="btn-create-backtest"
    >
      {t('Test Plan')} ({data.totalToDay || 0}/{data.limitPerDay || 0})
    </StyledBackTest>
  );
}

const StyledBackTest = styled(Button)`
  max-width: 200px;
`;
