import {
  get,
  put,
  post,
  deleteRequest,
} from './base';

export default {
  getStrategies: (params) => {
    let url = `/plans/budget-strategies?page=${params?.page}&size=${params?.size}`;
    if (params?.search && params?.search.length) {
      url = `${url}&text=${params.search}`;
    }
    return get(url);
  },
  createStrategy: params => post('/plans/budget-strategies', params),
  editStrategy: params => put('/plans/budget-strategies', params),
  deleteStrategy: (id, optionDeleteAllPlan) => deleteRequest(`/plans/budget-strategies/${id}?isDeletePlans=${optionDeleteAllPlan}`),
  getMethods: type => get(`/plans/Shared/${type}`),
  importStrategy: code => post(`/plans/budget-strategies/${encodeURIComponent(code)}`),
  renewCode: budgetStrategyId => put(`/plans/budget-strategies/${budgetStrategyId}/renew-code`),
  checkPlanExistUsingStrategy: id => get(`/plans/budget-strategies/${id}/validate`),
  getStrategyOptions: type => get(`/plans/Shared/${type}`),
};
