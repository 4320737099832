import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Setting2, ArrowLeft } from 'iconsax-react';
import NotificationTab from './NotificationTab';
import NotificationSetting from './NotificationSetting';

const NotificationPopup = ({closePopup}) => {
  const { t } = useTranslation();
  const [isSettingMode, setIsSettingMode] = useState(false);

  return (
    <>
      <div className="notification-popup-head d-flex justify-content-between">
        <h3 className="notification-popup-title">
          {!isSettingMode ? t('Notifications') : t('Notifications Settings')}
        </h3>
        <button
          className="notification-button-nav text-success d-flex align-items-center"
          onClick={() => setIsSettingMode(!isSettingMode)}
        >
          {!isSettingMode && (
            <>
              <Setting2 size="16" color="currentColor" className="me-1" />
              {t('Setting')}
            </>
          )}

          {isSettingMode && (
            <>
              <ArrowLeft size="16" color="currentColor" className="me-1" />
              {t('Back')}
            </>
          )}
        </button>
      </div>

      {!isSettingMode && <NotificationTab closePopup={closePopup} />}
      {isSettingMode && <NotificationSetting />}
    </>
  );
};

NotificationPopup.propTypes = {
  closePopup: PropTypes.func.isRequired
};

export default NotificationPopup;
