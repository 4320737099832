import React from 'react';
import PropTypes from 'prop-types';
import { dateFormat } from '@/shared/helpers';

const ItemComment = ({ item }) => (
  <div className={`item-comment ${item.isEndUser ? 'end-user' : ''} ${!item.isEndUser ? 'support' : ''}`}>
    <p className="item-comment-body mb-0" dangerouslySetInnerHTML={{ __html: item.html_body }} />
    <p className="item-comment-time">{dateFormat(item.created_at)}</p>
  </div>
);

ItemComment.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default ItemComment;
