import { STORAGE_KEY } from '@/config/const';
import { generic } from '@/utils/generic';
import { cloneDeep, isBoolean } from 'lodash';
import { handleActions } from 'redux-actions';
import {
  setKickOut,
  setLanguageCode,
  setSiteConfig,
  setUserSettings,
  toggleExpandCurrentPlan,
} from './actions';

const getExpandCurrentPlan = () => {
  const value = generic.getJsonLocalStorage(STORAGE_KEY.EXPAND_CURRENT_PLAN);
  return isBoolean(value) ? value : true;
};

export const userConfigMap = {};

const defaultState = {
  expandCurrentPlan: getExpandCurrentPlan(),
  languageCode: localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE) || 'en',
  siteConfig: {
    googleRecaptcha: false,
    mtn_enabled: false,
    mtn_frm: null,
    mtn_est: 0,
    tuts: [],
    first_zero: false,
    time_range_order_in_second: {
      second_order_min: 7,
      second_order_max: 23,
    },
    api_key: false,
    minimum_timer_limited_in_minute: 10,
    timer_limitted_per_plan: 3,
  },
  isKickOut: false,
  userConfig: {
    is_fetching: true,
  },
  isOnline: true,
};

export default handleActions(
  {
    [toggleExpandCurrentPlan]: (state) => {
      generic.setJsonLocalStorage(
        STORAGE_KEY.EXPAND_CURRENT_PLAN,
        !state.expandCurrentPlan
      );
      return {
        ...state,
        expandCurrentPlan: !state.expandCurrentPlan,
      };
    },
    [setLanguageCode]: (state, { payload }) => ({
      ...state,
      languageCode: payload,
    }),
    [setSiteConfig]: (state, { payload }) => {
      const newState = cloneDeep(state);
      Object.assign(newState.siteConfig, payload);
      return newState;
    },
    [setKickOut]: (state, { payload }) => ({
      ...state,
      isKickOut: true,
      siteConfig: {
        ...state.siteConfig,
        mtn_est: payload,
      },
    }),
    [setUserSettings]: (state, { payload }) => ({
      ...state,
      userConfig: {
        ...state.userConfig,
        ...payload,
      },
    }),
  },
  defaultState
);
