import { useContext } from 'react';
import { MessageBox } from './Message';
import { SearchBox } from './SearchBox';
import { Box } from '@material-ui/core';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AccordionHeader, StyledAccordion, StyledCheckbox } from './styles';

import { TelegramMessageContext, TelegramSelectContext } from './context';
import { SelectedChannels } from './SelectedChannels';
import formatNumber from '@/shared/helpers/formatNumber';

export function MobileView() {
  const { current, filtered = [] } = useContext(TelegramMessageContext);
  return (
    <>
      <Box mb={2}>
        <SearchBox />
        <Box mb={2} />
        <SelectedChannels />
      </Box>
      {current && (
        <StyledAccordion defaultActiveKey={current?.id}>
          {filtered.map((item) => (
            <AccordionItem key={item.id} data={item} />
          ))}
        </StyledAccordion>
      )}
    </>
  );
}
``;

export function AccordionItem({ data }) {
  const { t } = useTranslation();
  const { current, handleItemClick } = useContext(TelegramMessageContext);
  const { canSelect, selected = [], handleItemSelect } = useContext(TelegramSelectContext);
  const handleClick = () => handleItemClick(data);
  const handleSelect = () => handleItemSelect(data);
  const handleCheckboxClick = (evt) => evt.stopPropagation();
  const isSelected = selected.includes(data.id);
  return (
    <Accordion.Item
      id={'telegram_' + data.id}
      eventKey={data.id}
      key={data.id}
      onClick={handleClick}
    >
      <AccordionHeader className="d-flex align-items-center">
        {canSelect && (
          <StyledCheckbox
            checked={isSelected}
            className="checkbox"
            onChange={handleSelect}
            onClick={handleCheckboxClick}
            disabled={!isSelected && selected.length >= 5}
          />
        )}
        <div className="d-block">
          <StyledName>{data.name}</StyledName>
          {data.winRate && (
            <StyledExtraInfo>
              {t('Win rate:')}{' '}
              <span className="text-success">
                {formatNumber.sliceDecimalFloat(data.winRate * 100)}%
              </span>
              {' | '}
              {t('Win/Lose Streak:')} <span className="text-success">{data.maxWinStreak}</span>
              {'/'}
              <span className="text-danger">{data.maxLoseStreak}</span>
            </StyledExtraInfo>
          )}
        </div>
      </AccordionHeader>
      <Accordion.Body>{current.id === data.id && <MessageBox />}</Accordion.Body>
    </Accordion.Item>
  );
}

const StyledName = styled.div`
  font-size: 16px;
`;

const StyledExtraInfo = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;
