import { STRATEGY_TYPE, SIGNAL_STRATEGY_TYPE } from "@/shared/constants";

export const getBotAndMethod = (id, optionsBotName) => {
  const bot = optionsBotName.filter(b => b.id === Number(id));
  if (bot.length > 0) {
    const methodNames = bot[0]?.strategy === STRATEGY_TYPE.candlePattern
      ? ['Candle pattern']
      : bot[0].sources.filter(s => s.type !== 'OPTION').map(s => s.type === SIGNAL_STRATEGY_TYPE.expert ? s.source : s.name);
    return {
      botName: bot[0].name,
      methodName: methodNames.join(', '),
      copy_from: bot[0].copy_from,
      is_default: bot[0].is_default
    };
  }

  return {
    botName: '',
    methodName: '',
    copy_from: '',
  };
};

export const midnight = () => {
  const datetime = new Date();
  return {
    year: datetime.getFullYear(),
    month: datetime.getMonth() + 1,
    day: datetime.getDay(),
  };
};

export default getBotAndMethod;
