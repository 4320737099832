const gtag = {
  register: {
    category: 'Register',
    label: '',
    action: {
      signUp: 'sign_up',
      activeAccount: 'active_account'
    }
  },
  login: {
    category: 'Login',
    label: '',
    action: {
      loginWithout2Fa: 'login_without_2FA',
      loginInclude2FA: 'login_include_2FA',
      loginPwdLess: 'login_pwd_less',
      forgotPassword: 'forgot_password',
      requestConfirmEmail: 'request_confirm_email',      
    }
  },
  logout: {
    category: 'Logout',
    label: '',
    action: {
      logout: 'logout_account',
    }
  },
  connectAccount: {
    category: 'Connect account',
    label: '',
    action: {
      changeExchange: 'connect_when_change_exchange',
      notExchange: 'connect_when_not_exchange',
    }
  },
  dashboard: {
    category: 'Dashboard',
    label: '',
    action: {
      copyTopPlan: 'copy_top_plan',
      createPlanFromLeaderPlan: 'create_leader_plan',
      createPlanFromBacktestBotAi: 'back_test_bot_ai',
      createPlanFromBacktestTelegram: 'back_test_telegram',
      backTestRun: 'back_test_run'
    }
  },
  setting: {
    category: 'Setting',
    label: '',
    action: {
      uploadAvatar: 'setting_infor_upload_avatar',
      changeName: 'setting_infor_change_name',
      changePass: 'setting_security_change_pass',
      on2FaTool: 'setting_security_on_2FA',
      off2FaTool: 'setting_security_off_2FA',
      on2FaExchange: 'setting_exchange_on_2FA',
      off2FaExchange: 'setting_exchange_off_2FA',
      onPwl: 'setting_passwordless_ON',
      offPwl: 'setting_passwordless_OFF',
      exchangeDisconnect: 'setting_exchange_disconnect',
      resetReachingTpSlOn: 'setting_reset_reaching_TP_SL_ON',
      resetReachingTpSlOff: 'setting_reset_reaching_TP_SL_OFF',
      resetResumeOn: 'setting_reset_resume_ON',
      resetResumeOff: 'setting_reset_resume_OFF',
      resetPnlOn: 'setting_reset_PnL_ON',
      resetPnlOff: 'setting_reset_PnL_OFF',
      resetDailyOn: 'setting_reset_daily_ON',
      resetDailyOff: 'setting_reset_daily_OFF',
      timerStopOn: 'setting_noti_timer_stop_ON',
      timerStopOff: 'setting_noti_timer_stop_OFF',
      timerStartOn: 'setting_noti_timer_start_ON',
      timerStartOff: 'setting_noti_timer_start_OFF',
      insufficientBalanceOn: 'setting_noti_insuf_balance_ON',
      insufficientBalanceOff: 'setting_noti_insuf_balance_OFF',
      takeProfitOn: 'setting_noti_take_profit_ON',
      takeProfitOff: 'setting_noti_take_profit_OFF',
      stopLossOn: 'setting_noti_stop_loss_ON',
      stopLossOff: 'setting_noti_stop_loss_OFF',
      userStopLossOn: 'setting_noti_user_stop_loss_ON',
      userStopLossOff: 'setting_noti_user_stop_loss_OFF',
      userTakeProfitOn: 'setting_noti_user_take_profit_ON',
      userTakeProfitOff: 'setting_noti_user_take_profit_OFF',
      widthdrawOn: 'setting_noti_widthdraw_ON',
      widthdrawOff: 'setting_noti_widthdraw_OFF',
      depositOn: 'setting_noti_deposit_ON',
      depositOff: 'setting_noti_deposit_OFF',
    }
  },
  language: {
    category: 'Language',
    label: '',
    action: {
      languageEn: 'language_en',
      languageVi: 'language_vi',
      languageTh: 'language_th'
    }
  },
  darkMode: {
    category: 'Dark mode',
    label: '',
    action: {
      darkModeOn: 'dark_mode_on',
      darkModeOff: 'dark_mode_off',
    }
  },
  document: {
    category: 'Documents',
    label: '',
    action: {
      viewDocument: 'view_document',
    }
  },
  help: {
    category: 'Get Help',
    label: '',
    action: {
      viewHelp: 'view_help',
      submitTicket: 'submit_ticket',
    }
  },
  referral: {
    category: 'Referral',
    label: '',
    action: {
      copyRef: 'referral_copylink',
    }
  },
  wallet: {
    category: 'Referral',
    label: '',
    action: {
      switchWallet: 'wallet_switch',
      withdraw: 'wallet_withdraw',
      deposit: 'wallet_deposit',
      transferToUSDT: 'wallet_transfer_to_USDT',
      transferToTrade: 'wallet_transfer_to_TRADE',
      refillDemo: 'wallet_refill'
    }
  },
  manual: {
    category: 'Manage Trade',
    label: '',
    action: {
      active: 'manual_trade_active',
      active_floating: 'manual_trade_active_floating',
      betLong: 'manual_trade_long',
      betShort: 'manual_trade_short',
      expertOn: 'manual_trade_expert_ON',
      expertOff: 'manual_trade_expert_OFF',
      viewFollower: 'manual_trade_view_follower',
      filterRecently: 'manual_trade_filter_recently',
      filterCustom: 'manual_trade_filter_custom',
      filterTradeAmount: 'manual_trade_filter_trade_amount',
    }
  },
  planOrder: {
    category: 'Plan Order',
    label: '',
    action: {
      refreshOrder: 'plan_order_refresh'
    }
  },
  plan: {
    category: 'Portfolios',
    label: '',
    action: {
      createPlan: 'list_plan_create',
      editPlan: 'list_plan_create',
      deletePlan: 'list_plan_delete',
      importPlan: 'list_plan_import',
      copyPlanDemoToLive: 'list_plan_copy_plan_demo_to_live',
      resetTodayProfit: 'list_plan_reset_today_profit',
      resumePlan: 'list_plan_resume_plan',
      pausePlan: 'list_plan_pause_plan',
      duplicatePlan: 'list_plan_duplicate_plan',
      shareQRPlan: 'list_plan_share_qr_plan',
      shareQRPStatistic: 'list_plan_share_qr_statistic',
      shareCodePlan: 'list_plan_share_code_plan',
      setDailyGoal: 'list_plan_set_daily_goal',
      filterPlans: 'list_plan_filter_plans',
      sortPnlPlans: 'list_plan_sort_pnl_plans',
      sortStatusPlans: 'list_plan_sort_status_plans',
      filterTypeHistoryOrder: 'detail_plan_filter_type_history_order',
      filterTimeHistoryOrder: 'detail_plan_filter_time_history_order',
      filterTradeAmountHistoryOrder: 'detail_plan_filter_trade_amount_history_order',
      createProfitStrategy: 'detail_plan_create_profit_strategy',
      editProfitStrategy: 'detail_plan_edit_profit_strategy',
      deleteProfitStrategy: 'detail_plan_delete_profit_strategy',
      editSequenceProfitStrategy: 'detail_plan_edit_sequence_profit_strategy',
      statisticsListPlan: 'statistics_list_plan',
      statisticsPlan: 'statistics_plan',
      addTimer: 'add_timer',
      editTimer: 'edit_timer',
      deleteTimer: 'delete_timer',
    }
  },
  budget: {
    category: 'Budget Strategy',
    label: '',
    action: {
      create: 'budget_create',
      edit: 'budget_edit',
      delete: 'budget_delete',
      share: 'budget_share',
      copy: 'budget_copy'
    }
  },
  signal: {
    category: 'Signal Strategy',
    label: '',
    action: {
      createBotAi: 'signal_create_bot_ai',
      createBotCandle: 'signal_create_bot_candle',
      edit: 'signal_edit',
      delete: 'signal_delete',
      share: 'signal_share',
      copy: 'signal_copy',
      create_mix_box: 'top_signal_create_mix_bot',
      list_tele_signal: 'list_tele_signal',
      createPlanFromTopSignal: 'top_signal_create_plan_from_top_signal',
      createPlanFromMultiTopSignal: 'top_signal_create_plan_from_multi_top_signal',
      pinSignal: 'top_signal_pin_signal'
    }
  },
  follower: {
    category: 'Manage Follower',
    label: '',
    action: {
      block: 'follower_block',
      unBlock: 'follower_un_block',
      viewPlans: 'follower_view_plans',
      viewPlansReload: 'follower_view_plans_reload',
    }
  },
  vipMember: {
    category: 'Vip Member',
    label: '',
    action: {
      buyVip: 'buy_vip_member',
    }
  }
}

export default gtag;
