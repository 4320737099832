import { useEffect } from 'react';

export function NetworkHandler() {
  useEffect(() => {
    window.isOnline = window.navigator.onLine;
    const onOnline = () => {
      console.log('Online :D');
      window.isOnline = true;
    };
    const onOffline = () => {
      console.log('Offline :(');
      window.isOnline = false;
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);
}
