import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import {
  colorGreyScale600,
  colorGreyScale900,
  colorSuccess,
} from '@/utils/palette';
import { left } from '@/utils/directions';
import { Box } from '@material-ui/core';
import theme from 'styled-theming';

const BottomBarLink = ({
  icon: Icon,
  collapse,
  route,
  exact,
  onClick,
  numberBade,
  className
}) => (
  <Box position="relative" className={className}>
    <NavLinkStyle
      to={route}
      exact={exact}
      onClick={onClick}
      className="d-flex justify-content-between"
      activeClassName="active"
    >
      <LinkIcon className="link-icon">
        <Icon size={24} variant="Bulk" className="icon icon-active" />
        <Icon size={24} className="icon icon-default" />
      </LinkIcon>
      {!collapse && !!numberBade && (
        <NumberBadge bg="secondary" className="number-bade">
          {numberBade}
        </NumberBadge>
      )}
    </NavLinkStyle>
  </Box>
);

BottomBarLink.propTypes = {
  icon: PropTypes.instanceOf(Object),
  collapse: PropTypes.bool,
  route: PropTypes.string,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  numberBade: PropTypes.number,
  className: PropTypes.string,
};

BottomBarLink.defaultProps = {
  icon: null,
  collapse: false,
  route: '/',
  exact: false,
  onClick: () => {},
  numberBade: 0,
  className: null
};

const NavLinkStyle = styled(NavLink)`
  position: relative;
  cursor: pointer;
  display: flex;
  border: none;
  color: ${colorGreyScale600};
  text-align: ${left};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  &.active {
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }

  &:hover {
    text-decoration: none;
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }
`;

const NumberBadge = styled(Badge)`
  background-color: ${theme('mode', {
    light: colorGreyScale900,
    dark: '#fff',
  })}!important;
  color: ${theme('mode', {
    light: '#fff',
    dark: colorGreyScale900,
  })};
  border: 1px solid #fff;
  font-size: 8px;
  font-weight: 500;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const LinkIcon = styled.span`
  width: 24px;
  height: 24px;
  position: relative;
  .icon {
    position: absolute;
    /* // transition: all 0.3s linear; */
    &-active {
      opacity: 0;
    }
  }
`;

export default BottomBarLink;
