import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FORM_TYPE } from '@/shared/constants';
import { Button } from '@/shared/components/Button';
import { Form as CsForm } from '@/shared/components/form/drawer/Elements';

const FormFooter = ({
  currentStrategy,
  formType,
  isLoading,
  sumaryValid,
  onClose,
  saveStrategy,
  allowCustomize,
  handleCustomize,
}) => {
  const { t } = useTranslation();

  return (
    <CsForm.Footer>
      <Button
        variant="secondary_outline"
        className="cta-back"
        onClick={onClose}
        block={formType === FORM_TYPE.read_only}
        disabled={isLoading}
      >
        {t('Close')}
      </Button>
      {!currentStrategy.is_default && formType !== FORM_TYPE.read_only && (
        <Button
          variant="success"
          onClick={saveStrategy}
          disabled={!sumaryValid || isLoading}
          block="true"
          isLoading={isLoading}
        >
          {t('Save Strategy')}
        </Button>
      )}
      {allowCustomize && currentStrategy.is_default && !currentStrategy.copy_from && formType !== FORM_TYPE.read_only && (
        <Button variant="success" onClick={handleCustomize} block="true">
          {t('Customize Budget')}
        </Button>
      )}
    </CsForm.Footer>
  );
};

FormFooter.propTypes = {
  currentStrategy: PropTypes.instanceOf(Object),
  formType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sumaryValid: PropTypes.bool.isRequired,
  allowCustomize: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveStrategy: PropTypes.func.isRequired,
  handleCustomize: PropTypes.func.isRequired,
};

FormFooter.defaultProps = {
  currentStrategy: {},
};

export default FormFooter;
