import React from 'react';
import PropTypes from 'prop-types';

const CardItemRow = ({ label, value }) => (
  <div className="item-row">
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
);

CardItemRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CardItemRow;
