/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const Sliders = ({ focusedIdx, children, duration = 300 }) => {
  const offset = -100 * focusedIdx;

  return (
    <StyledOuterSliders className="slider-container">
      <StyledSliders offset={offset} duration={duration}>
        {children}
      </StyledSliders>
    </StyledOuterSliders>
  );
};

const StyledOuterSliders = styled.div`
  overflow: hidden;
  width: 100%;
`;
const StyledSliders = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;

  transform: translateX(${props => `${props.offset}%`});
  transition: transform ${props => props.duration}ms;

  > div {
    flex-shrink: 0;
    width: 100%;
  }
`;


export default Sliders;
