import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  colorAccent,
  colorGreyScale100,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale900,
  Devices,
} from '@/utils/palette';
import theme from 'styled-theming';
import { dateFormatWithMonth } from '@/shared/helpers';
import CardItemHead from './CardItemHead';
import CardItemRow from './CardItemRow';

const CardItem = ({ item, changeBacktestId }) => {
  const { t } = useTranslation();
  return (
    <CardItemStyle>
      <CardItemHead id={item.id} progress={item.progress} profit={item.profit} changeBacktestId={changeBacktestId} />
      <CardItemRow label={t('Test ID')} value={`#${item.id}`} />
      <CardItemRow
        label={t('Signal Strategy')}
        value={item.isDefaultSignal ? t(item.signalStrategyName) : item.signalStrategyName}
      />
      <CardItemRow
        label={t('Budget Strategy')}
        value={item.isDefaultBudget ? t(item.budgetName) : item.budgetName}
      />
      <CardItemRow label={t('Time to run Backtest')} value={dateFormatWithMonth(item.createdAt)} />
    </CardItemStyle>
  );
};

const CardItemStyle = styled.div`
  background: 'transparent';
  border-bottom: ${theme('mode', {
  light: `1px solid ${colorGreyScale300}`,
  dark: `1px solid ${colorGreyScale700}`,
})};;
  
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:last-child {
    border-bottom: none;
  }
  .item-head {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    .status {
      flex: 1;
      &__badge {
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        padding: 4px 8px;
        line-height: 24px;
        font-weight: 700;
        font-size: 16px;
        display: inline-block;
        color: ${colorGreyScale900};
        &.positive {
          background: #E7F7EF;
        }
        &.negative {
          background: #FFF0F0;
        }
        &.progressing {
          padding: 6px 8px;
          line-height: 20px;
          background:#FEF7DC;
          color: #FFD023;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
    .navigate {
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      cursor: pointer;
      color: ${colorAccent};
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
    }
  }
  .item-row {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    .label {
      color: ${colorGreyScale500};
      font-size: 14px;
      line-height: 160%;
      font-weight: 500;
      @media ${Devices.pc} {
        font-size: 12px;
      }
    }
    .value {
      text-align: right;
      color: #718096;
      font-size: 14px;
      line-height: 160%;
      font-weight: 600;
      max-width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  @media ${Devices.pc} {
    background: ${theme('mode', {
  light: colorGreyScale100,
  dark: 'transparent',
})};
    border: ${theme('mode', {
  light: '',
  dark: `1px solid ${colorGreyScale600}`,
})};;
    border-radius: 12px;
    padding: 16px;
    &:last-child {
      border-bottom: ${theme('mode', {
  light: '',
  dark: `1px solid ${colorGreyScale600}`,
})};;
    }
  }
`;

CardItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  changeBacktestId: PropTypes.func.isRequired,
};

export default CardItem;
