import { timeDurationFormat, timeFormat } from '@/shared/helpers';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';

function AlertMaintenance() {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const { siteConfig = {} } = useSelector(
    state => ({
      siteConfig: state.appConfig.siteConfig,
    }),
    isEqual,
  );
  return (
    <AlertStyle
      show={show && !!siteConfig.mtn_frm}
      variant="warning"
      onClose={() => setShow(false)}
      dismissible="true"
    >
      <MarqueeStyle gradient={false} speed={70}>
          <ContentStyle
            dangerouslySetInnerHTML={{
              __html: t('AlertMaintenance.content', {
                time: timeFormat(siteConfig?.mtn_frm),
                est_time: timeDurationFormat(t)(siteConfig?.mtn_est),
              }),
            }}
          />
      </MarqueeStyle>
    </AlertStyle>
  );
}

const AlertStyle = styled(Alert)`
  border: none;
  border-radius: 0;
  .btn-close:focus {
    box-shadow: none;
  }
  background: ${theme('mode', {
    light: '#fff6d3',
    dark: '#ffc1077d',
  })};
  color: ${theme('mode', {
    light: '',
    dark: '#ffffff',
  })};
`;
const MarqueeStyle = styled(Marquee)`
  gap: 8px;
`;
const ContentStyle = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export default AlertMaintenance;
