import {
  borderDefaultStyle,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale900,
  colorText,
  Devices,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Button } from '../../Button';

export const CardStyle = styled(Card)`
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  .icon-svg {
    circle {
      fill: ${theme('mode', {
        light: '',
        dark: '#111827',
      })};
    }

    path {
      stroke: ${theme('mode', {
        light: '',
        dark: '#fff',
      })};
    }
  }
  @media ${Devices.laptop} {
    border-radius: unset;
  }
`;
export const CardTitle = styled(Card.Title)`
  padding: 24px 16px;
  display: flex;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  border-bottom: ${borderDefaultStyle};
  margin: 0;
  @media ${Devices.laptop} {
    border-radius: unset;
  }
`;

export const CardBody = styled(Card.Body)`
  height: calc(95vh - 216px);
  overflow-y: auto;
  @media ${Devices.pc} {
    height: calc(100vh - 80px);
    padding: 24px;
  }
`;
export const CardBodyWithFooter = styled(Card.Body)`
  height: calc(95vh - (216px + 125.6px));
  overflow-y: auto;
  @media ${Devices.pc} {
    height: calc(100vh - 200.2px);
  }
`;
export const CardBodyWithShorter = styled(Card.Body)`
  height: calc(95vh - (216px + 91.4px));
  overflow-y: auto;
  @media ${Devices.pc} {
    height: calc(100vh - (91.4px + 71.1px));
  }
`;

export const CardFooter = styled(Card.Footer)`
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  border-top: ${borderDefaultStyle};
  .btn-secondary_outline {
    color: ${colorText};
    border-color: ${colorGreyScale600};
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  padding: 16px;
  flex: 1;
`;

export const FormFooter = styled.div`
  padding: 16px;
  border-top: ${borderDefaultStyle};
  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff; */
`;

export const FooterHint = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #a0aec0;
`;

export const FormTitle = styled(Box)`
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  color: ${colorText};
  padding: 0 12px;
`;

export const Address = styled(Box)`
  padding: 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${colorText};
  margin-top: 16px;
  background: ${theme('mode', {
    light: '#fafafa',
    dark: colorGreyScale700,
  })};
`;
export const Description = styled(Box)`
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: ${colorText};
`;

export const ButtonTransfer = styled(Button)`
  width: 100%;
  background: ${colorGreyScale900};
  border-radius: 12px;
  color: #fff;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: #fff !important;
    background: ${colorGreyScale600}!important;
  }
  @media ${Devices.pc} {
    max-width: 320px;
  }
`;
