import {
  get,
  post,
} from './base';

export default {
  getList(zuid, config) {
    return get(`/supports/api/v2/users/${zuid}/requests.json?&sort_by=createdat&sort_order=desc`,
      { ...config, baseURL: process.env.REACT_APP_API_URL });
  },
  getListComment(idTicket, config) {
    return get(`/supports/api/v2/requests/${idTicket}/comments.json`, 
      { ...config, baseURL: process.env.REACT_APP_API_URL });
  },
  createTicket(dataTicket, config) {
    return post('/supports/api/v2/requests.json',
      dataTicket,
      { ...config, baseURL: process.env.REACT_APP_API_URL });
  },
  uploadFile(fileName, fileData, config) {
    return post(`/supports/api/v2/uploads?filename=${fileName}`,
      fileData,
      { ...config, baseURL: process.env.REACT_APP_API_URL });
  },
  checkUserCreatedTiket() {
    return get('/supports/api/check-user-create-ticket', { baseURL: process.env.REACT_APP_API_URL });
  },
};
