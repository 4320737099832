import { clearAuthority } from '@/redux/refactor/auth/actions';
import { disconnectSuccess } from '@/redux/refactor/exchange/actions';
import rootReducers from '@/redux/rootReducers';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import history from './history';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  ...rootReducers,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === clearAuthority.toString()) {
    return appReducer(
      {
        appConfig: state.appConfig,
        user: state.user,
      },
      action
    );
  }
  if (action.type === disconnectSuccess.toString()) {
    return appReducer(
      {
        user: state.user,
        appConfig: state.appConfig,
      },
      action
    );
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = (store) => (next) => (action) => {
  // console.group(action.type);
  // console.info('dispatching', action);
  const result = next(action);
  // console.log('next state', store.getState());
  // console.groupEnd();
  return result;
};

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk, logger))
);
window.store = store;
export const persistor = persistStore(store);
persistor.purge();

export default store;
