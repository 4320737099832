import { WIDGET_URL, IS_PROD } from '@/config/const';
import useWindowSize from '@/hooks/useWindowSize';
import { setUrlAfterLogin } from '@/redux/refactor/theme/actions';
import { dateFormatWithMonth } from '@/shared/helpers';
import insightApi, { wpBaseDomain } from '@/utils/api/insightApi';
import { motion } from 'framer-motion';
import _, { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CreatePlan from '../CreatePlan';
import PostInteract from '../PostInteract';
import './index.scss';

const allowedCategory = ['investment-tips', 'signal-insights', 'how-to-use'];

const motionProps = {
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const InsightPost = ({ post, isDetail }) => {
  const { t } = useTranslation();
  const currentLangCode = useSelector((state) => state.appConfig.languageCode, isEqual);
  const dispatch = useDispatch();
  const gridSize = useWindowSize();
  const { isAuth } = useSelector((state) => state.user, _.isEqual);

  const { mode } = useSelector(
    (state) => ({
      mode: state.theme.className,
    }),
    _.isEqual
  );
  const [featuredImage, setFeaturedImage] = useState('');
  const [category, setCategory] = useState({});
  const [wpContentPost, setWpContentPost] = useState('');
  const INSIGHT_CHART_URL = `${WIDGET_URL}/${
    currentLangCode !== 'en' ? currentLangCode + '/' : ''
  }insight-chart`;

  useEffect(() => {
    if (!post.featured_media || isDetail) {
      return;
    }

    insightApi.getFeaturedImage(post.featured_media).then((res) => {
      if (res.status === 200) {
        setFeaturedImage(res.data.source_url);
      }
    });
  }, [post.featured_media]);

  useEffect(() => {
    if (!post?.categories?.length) {
      return;
    }

    insightApi.getCategory(post.categories[0]).then((res) => {
      if (res.status === 200) {
        setCategory(res.data);
      }
    });
  }, [post.categories]);

  useEffect(() => {
    if (!isAuth && isDetail) {
      dispatch(setUrlAfterLogin(location.pathname));
    } else if (isAuth && isDetail) {
      dispatch(setUrlAfterLogin('/'));
    }
  }, [isDetail, isAuth]);

  useEffect(() => {
    if (!post.id) {
      return;
    }
    if (post.acf.chart.trim()) {
      let wpContent = post.content.rendered;
      if (post.acf.chart) {
        wpContent = wpContent.replace(post.acf.chart, `${post.acf.chart}?mode=${mode}`);
      }

      wpContent = wpContent.replace(
        '/wp-content/uploads',
        'https://insights.quickinvest.ai/wp-content/uploads'
      );

      setWpContentPost(wpContent);
    }
  }, [post.id, gridSize]);

  return (
    <>
      {isDetail && <Helmet title={`${post.title.rendered || 'Insights'} | Quickinvest.ai`} />}
      {post.id && ((IS_PROD && allowedCategory.includes(category.slug)) || !IS_PROD) && (
        <motion.div {...motionProps} className="wrap-post">
          {!isDetail && (
            <motion.div {...motionProps} className="post-date">
              {dateFormatWithMonth(post.date)}
            </motion.div>
          )}
          <div className={`post ${isDetail ? 'post--detail' : 'post--list'}`}>
            {isDetail && gridSize && ['lg', 'xl', 'xxl'].includes(gridSize) && (
              <motion.div {...motionProps} className="post-sidebar">
                <PostInteract postId={post.id} />
                {post.acf.chart && <CreatePlan chartId={post.acf.chart} />}
              </motion.div>
            )}

            <div className="post-main">
              <div className={`post-body ${featuredImage ? 'has-thumbnail' : ''}`}>
                <div className="post-content">
                  <div className={`post-category ${category?.slug}`}>{t(category?.name)}</div>
                  <h2 className="post-title">
                    {isDetail ? (
                      post.title.rendered
                    ) : (
                      <NavLink to={`/quick-insight/${post.id}`}>{post.title.rendered}</NavLink>
                    )}
                  </h2>
                  <div
                    className="post-main-content"
                    dangerouslySetInnerHTML={{
                      __html: isDetail ? wpContentPost : post.excerpt.rendered,
                    }}
                  />
                </div>

                {featuredImage && !isDetail && !post.acf.chart && (
                  <motion.div {...motionProps} className="post-thumbnail">
                    <img src={wpBaseDomain + featuredImage} alt="" />
                  </motion.div>
                )}
                {post.acf.chart && !isDetail && (
                  <motion.div {...motionProps} className="post-chart">
                    <iframe
                      src={`${INSIGHT_CHART_URL}?id=${post.acf.chart}&mode=${mode}`}
                      width={'100%'}
                      height={500}
                    />
                  </motion.div>
                )}
              </div>

              <div className="post-footer">
                <PostInteract postId={post.id} />
                {isDetail && (
                  <motion.div {...motionProps} className="post-footer-text">
                    <h3>{t('Thanks for reading!')}</h3>
                    <p>
                      {t(
                        'If you enjoyed this insight please leave a like, share it with your friends!'
                      )}
                    </p>
                  </motion.div>
                )}
                {post.acf.chart && <CreatePlan chartId={post.acf.chart} />}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

InsightPost.propTypes = {
  post: PropTypes.instanceOf(Object),
  isDetail: PropTypes.bool,
};

InsightPost.defaultProps = {
  post: {},
  isDetail: false,
};

export default InsightPost;
