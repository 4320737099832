import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StatusNav = ({
  isOpenStatus,
  getList,
  numberOpen,
  numberClosed,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ticket-status-list">
      <div className="ticket-status-item">
        <div
          className={`ticket-status-item-inner ${isOpenStatus ? 'active' : ''}`}
          onClick={() => getList('open')}
          onKeyDown={() => getList('open')}
          role="presentation"
        >
          <span className="ticket-status-text">{t('Open Ticket')}</span>
          <span className="ticket-status-number">{numberOpen}</span>
        </div>
      </div>
      <div className="ticket-status-item">
        <div
          className={`ticket-status-item-inner ${!isOpenStatus ? 'active' : ''}`}
          onClick={() => getList('closed')}
          onKeyDown={() => getList('closed')}
          role="presentation"
        >
          <span className="ticket-status-text">{t('Closed Ticket')}</span>
          <span className="ticket-status-number">{numberClosed}</span>
        </div>
      </div>
    </div>
  );
};

StatusNav.propTypes = {
  isOpenStatus: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  numberOpen: PropTypes.number.isRequired,
  numberClosed: PropTypes.number.isRequired,
};

export default StatusNav;
