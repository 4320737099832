/* eslint-disable react/prop-types */
import Empty from '@/shared/components/Empty';
import { Box } from '@material-ui/core';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import TransactionItem from './TransactionItem';

const ListTransaction = ({ onItemClick }) => {
  const { t } = useTranslation();
  const { isFetching, data } = useSelector(
    state => state.wallet.transactions,
    _.isEqual,
  );

  return (
    <ListWrap>
      {data.map((value, id) => (
        <TransactionItem value={value} key={value.txid.concat(id)} onClick={onItemClick} />
      ))}
      {isFetching && (
        <Box padding="20px" textAlign="center">
          <Spinner animation="border" variant="secondary" />
        </Box>
      )}
      {!isFetching && data.length === 0 && (
        <Box>
          <Empty title={t('There is no transactions here!')} />
        </Box>
      )}
    </ListWrap>
  );
};

const ListWrap = styled.div`
  /* max-height: 316px; */
  overflow: auto;
  /* margin-bottom: 15px; */
  /* margin-top: 15px; */

`;
export default ListTransaction;
