/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormFieldButton } from '@/shared/components/form/FormElements';
import Icons from '@/shared/components/icons';
import { paddingRight } from '@/utils/directions';
import FormField from './FormField';

const PasswordField = React.forwardRef(({ ...props }, ref) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    meta: { touched, error },
  } = props;
  return (
    <>
      <FormFieldStyle
        {...props}
        className={touched && error && 'invalid'}
        type={passwordShown ? 'text' : 'password'}
        ref={ref}
      />
      <FormFieldButtonStyle
        tabIndex="-1"
        active={passwordShown}
        type="button"
        onClick={() => setPasswordShown(shown => !shown)}
      >
        {passwordShown ? (
          <Icons.EyeOnIcon className="eyeon-icon" color="transparent" />
        ) : (
          <Icons.EyeOffIcon color="transparent" />
        )}
      </FormFieldButtonStyle>
    </>
  );
});

PasswordField.propTypes = {};

PasswordField.defaultProps = {};

export default PasswordField;

// region STYLES

export const PasswordIcon = styled.div``;

const FormFieldStyle = styled(FormField)`
  ${paddingRight}: 56px!important;
`;

const FormFieldButtonStyle = styled(FormFieldButton)`
  background: none;
  border: none;
  position: absolute;
  top: 12px;
  right: 12px;
  svg {
    width: 24px;
    height: 24px;
    // transition: all 0.3s;
  }
`;

// endregion
