import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
} from '@/shared/components/form/FormElements';
import { Box } from '@material-ui/core';
import { validatorHelper } from '@/shared/helpers';
import { useTranslation } from 'react-i18next';
import { AccountButton } from '../AccountElements';
import PasswordField from '../../form/Password';

const ResetPasswordForm = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  const validate = (values) => {
    const errors = {};
    errors.password = validator.required(values.password)
      || validator.minValue(6)(values.password)
      || validator.maxValue(20)(values.password);
    errors.confirmPassword = validator.required(values.confirmPassword) || validator.match(values);
    return errors;
  };
  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, invalid }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder={t('Password')}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                name="confirmPassword"
                component={PasswordField}
                placeholder={t('Confirm new password')}
              />
            </FormGroupField>
          </FormGroup>
          <Box marginTop="8px" flex={1}>
            <AccountButton
              type="submit"
              disabled={isLoading || invalid}
              size="lg"
              variant="success"
              isLoading={isLoading}
            >
              {t('Reset Password')}
            </AccountButton>
          </Box>
        </FormContainer>
      )}
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  isLoading: false,
};

export default ResetPasswordForm;
