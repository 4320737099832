import { setLanguageCode } from '@/redux/refactor/appConfig/actions';
import _ from 'lodash';
import languages from '@/translations/languages';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default function LanguageDetector() {
  const [cookies, setCookie] = useCookies(['WFCOUNTRY']);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const checkLanguage = () => {
    if (!cookies.WFCOUNTRY) {
      fetch(
        'https://api.ipstack.com/check?access_key=ab95bab02cfaca13104686fa838dee10'
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let countryCode = data.country_code;
          if (countryCode === 'VN') {
            countryCode = 'vi';
          }
          let currentLanguage = _.find(
            languages,
            (p) => p.code === countryCode.toLowerCase()
          );
          if (currentLanguage) {
            dispatch(setLanguageCode(currentLanguage.code));
            i18n.changeLanguage(currentLanguage.code);
          } else {
            currentLanguage = { code: 'en' };
            dispatch(setLanguageCode('en'));
            i18n.changeLanguage('en');
          }
          if (currentLanguage) {
            setCookie(
              'WFCOUNTRY',
              currentLanguage.code,
              1000 * 60 * 60 * 24 * 10
            );
          }
        });
    }
  };
  useEffect(() => {
    if (dispatch && i18n) {
      checkLanguage();
    }
  }, [dispatch, i18n]);
}
