const withdrawValidator = (translate) => ({
  minAmount: (value) =>
    Number(value) >= window.minUsdt
      ? undefined
      : translate('Minimum withdrawal amount is {{min}} USDT', {
          min: window.minUsdt,
        }),
  maxAmount: (max) => (value) =>
    Number(value) <= max ? undefined : translate('Insufficient balance'),
  bscAddress: (value) => {
    if (value.indexOf('0x') !== 0 && value.length !== 42) {
      return translate('Invalid address');
    }
    return undefined;
  },
});

export default withdrawValidator;
