import React from 'react';
import PropTypes from 'prop-types';
import { TransactionType } from '@/redux/types/transaction';
import { ArrowSwapHorizontal, WalletAdd, WalletMinus } from 'iconsax-react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'styled-theming';
import { TRANSACTION_TYPE } from '@/config/const';

const TransactionIcon = ({ type, size }) => {
  const ratio = size ? size / 32 : 1;
  let Icon = WalletMinus;
  if (type === TransactionType.INTERNAL_DEPOSIT || type === TransactionType.INTERNAL_WITHDRAW 
    || type === TransactionType.TRANSFER_IN || type === TRANSACTION_TYPE.TRANSFER_OUT) {
    Icon = ArrowSwapHorizontal;
  } else if (type === TransactionType.DEPOSIT) {
    Icon = WalletAdd;
  }
  return (
  <Wrap ratio={ratio}><Icon color="#0CAF60" size={16 * ratio} /></Wrap>
);
};

const Wrap = styled(Box)`
  height: ${props => props.ratio * 32}px;
  width: ${props => props.ratio * 32}px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme('mode', {
    light: '#E7F7EF',
    dark: '#111827',
  })}
`;

TransactionIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
};
TransactionIcon.defaultProps = {
  type: '',
  size: 32,
};

export default TransactionIcon;
