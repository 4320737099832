export const TYPE_PLAN = {
  all: 'ALL',
  on_going: 'ON_GOING',
  pause: 'PAUSE',
  ended: 'ENDED',
};

export const TYPE_ACCOUNT = {
  demo: 'DEMO',
  live: 'LIVE',
};

export const STRATEGY_METHOD = {
  all_order: 'ALL_ORDERS',
  martingale: 'MARTINGALE',
  victor: 'VICTOR',
  victor2: 'VICTOR2',
  victor3: 'VICTOR3',
  victor4: 'VICTOR4',
  fibo: 'FIBO',
  autowin: 'AUTOWIN',
};

export const TYPE_KEY = {
  account: 'AccountType',
  strategy: 'BudgetStrategyMethod',
  signalStrategyType: 'SignalStrategyType',
  signalType: 'SignalType',
};

export const SIGNAL_STRATEGY_TYPE = {
  botai: 'BOTAI',
  expert: 'EXPERT',
  telegram: 'TELEGRAM',
  manual: 'MANUAL',
  community: 'COMMUNITY'
};

export const SIGNAL_STRATEGY_TYPE_NAME = {
  BOTAI: 'Bot AI',
  EXPERT: 'Follow Leader',
  TELEGRAM: 'Telegram Signal',
};

export const MODE_TYPE = {
  view: 'VIEW',
  review: 'REVIEW',
};

export const PLAN_STATUS = {
  stopped: 'STOPPED',
  running: 'RUNNING',
};
export const PLAN_UPDATE_ACTIONS = {
  stop: 'STOPPED',
  start: 'RUNNING',
  restart: 'RESTART',
};

export const DATA_DEFAULT = {
  MARTINGALE: ['1-2-4-8-17-35'],
  VICTOR2: [
    '1-1-2-2-3-4-5-7-10-13-18-24-32-44-59-80-108-146-197-271',
    '1-2-4-4-6-8-10-14-20-26-36-48-64-88-118-160-216-292-394-542',
  ],
  VICTOR3: [
    '1-1-1-1-1-1-1.5-2-2-2-2.5-3-3.5-4-4.5-5.4-6-7-8-9.5-11',
    '1-2-2-2-2-2-3-3.9-3.9-3.9-4.875-5.85-6.825-7.8-8.775-10.53-11.7-13.65-15.6-18.525-21.45',
    // eslint-disable-next-line
    '1-4-4-4-4-4-6-7.605-7.605-7.605-9.50625-11.4075-13.30875-15.21-17.11125-20.5335-22.815-26.6175-30.42-36.12375-41.8275',
  ],
  VICTOR4: [
    '1-1-1-1-1-1-1-1-1-1-1-1-1-1-1.23-1.25-1.28-1.3-1.47-1.6-1.74-1.88-2.04-2.22',
    // eslint-disable-next-line
    '1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-1.95-2.28-2.32-2.36-2.41-2.73-2.96-3.21-3.48-3.78',
    // eslint-disable-next-line
    '3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-3.8-4.22-4.29-4.37-4.45-5.04-5.47-5.94-6.44-6.99-7.59',
    // eslint-disable-next-line
    '7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.41-7.81-7.94-8.08-8.24-9.33-10.12-10.99-11.92-12.93-14.03',
  ],
  AUTOWIN: ['1-1-2-6-4-3', '1-2-4-8-17-35', '2-3-4-5-6-1'],
  FIBO: ['1-2-3-5-8-13-21-34-55-89-144'],
};

export const ORDER_TYPE = {
  up: 'UP',
  down: 'DOWN',
};

export const STRATEGY_TYPE = {
  single: 'SINGLE',
  string: 'STRING',
  mix: 'MIX',
  candlePattern: 'CANDLE_PATTERN',
};

export const PAGINATION_DEFAULT = {
  page: 1,
  size: 6,
};

export const BET_TYPE = {
  win: 'WIN',
  lose: 'LOSE',
};

export const PLAN_RESULT = {
  WIN: 'WIN',
  LOSE: 'LOSE',
  REFUND: 'REFUND',
  OPEN: 'OPEN'
};

export const FORM_TYPE = {
  create: 'CREATE',
  edit: 'EDIT',
  read_only: 'READ_ONLY'
};


export const NOTIFICATION_TYPE = {
  system: 'SYSTEM',
  plan: 'PLAN_ACTION'
}

export const NOTIFICATION_ITEM_TYPE = {
  timerStop: 'TIMER_STOP',
  timerStart: 'TIMER_START',
  takeProfit: 'TP',
  stopLoss: 'SL',
  invalidBalance: 'INSUF_BALANCE',
  dailyGoalTakeProfit: 'USER_TP',
  dailyGoalStopLoss: 'USER_SL',
  withdraw: 'WITHDRAW_SUCCESS',
  withdrawError: 'WITHDRAW_ERROR',
  internalWithdrawError: 'INTERNAL_WITHDRAW_ERROR',
  internalWithdrawSuccess: 'INTERNAL_WITHDRAW_SUCCESS',
  deposit: 'DEPOSIT_SUCCESS',
  internalDepositSuccess: 'INTERNAL_DEPOSIT_SUCCESS',
  block: 'BLOCK',
  unblock: 'UNBLOCK'
}

export const typeStatus = {
  start: 'START',
  stop: 'STOP',
  stopLoss: 'SL',
  takeProfit: 'TP',
  delayResult: 'DELAY_RESULT',
  resetProfit: 'RESET_PROFIT',
  reload: 'RELOAD',
  restart: 'RESTART',
  startFailed: 'START_FAILED',
  dailyTakeProfit: 'USER_TP',
  dailyStopLoss: 'USER_SL',
  updateDailyGoal: 'UPDATE_DAILY_GOAL',
  subTP: 'SUB_TP',
  subSL: 'SUB_SL',
  resetAndRestart: 'RESET_RESTART',
  switch: 'SWITCH',
  switchResetProfit: 'SWITCH_RESET_PROFIT',
  reachTargetBoard: 'REACH_TARGET_BOARD5',
  step0: 'STEP_0',
  positiveSl: 'POSITIVE_SL',
};

export const typeStatusLabel = {
  [typeStatus.start]: 'Start Plan',
  [typeStatus.stop]: 'Stop Plan',
  [typeStatus.stopLoss]: 'Stop Loss',
  [typeStatus.takeProfit]: 'Take Profit',
  [typeStatus.delayResult]: 'Delay result',
  [typeStatus.resetProfit]: 'Reset profit',
  [typeStatus.reload]: 'Reload',
  [typeStatus.restart]: 'Restart',
  [typeStatus.startFailed]: 'Start failed',
  [typeStatus.dailyTakeProfit]: 'Daily Goal',
  [typeStatus.dailyStopLoss]: 'Daily Goal',
  [typeStatus.updateDailyGoal]: 'Update daily goal',
  [typeStatus.subTP]: 'Sub profit',
  [typeStatus.subSL]: 'Sub stoploss',
  [typeStatus.resetAndRestart]: 'Reset and restart',
  [typeStatus.switch]: 'Switch strategy',
  [typeStatus.switchResetProfit]: 'Switch reset profit',
  [typeStatus.reachTargetBoard]: 'Reach target board 5',
  [typeStatus.step0]: 'Step 0',
  [typeStatus.positiveSl]: 'Take Profit',
};

export const typeReason = {
  PROFIT: 'PROFIT',
  LOSE_COUNT: 'LOSE_COUNT',
  WIN_COUNT: 'WIN_COUNT',
};

export const SORTS_TYPE = {
  asc: 'ASC',
  desc: 'DESC'
};


export const PLAN_SORT_LABLE = {
  pnl: 'PNL',
  status: 'STATUS'
}

export const CANDLE_COLORS = {
  buy: '\uDFE2',
  sell: '\uDFE0',
  white: '⚪',
  default: '\uDFE1',
  empty: '\ud83d'
}

export const TRIGGER_TYPES = {
  candlePattern: 'candle-pattern-strategy',
  stringMethod: 'string-strategy',
  expert: 'EXPERT_SIGNAL',
  tele: 'telegram-signal-strategy'
}

export const KEY_CODES = {
  comma: 188,
  enter: 13,
}

export const BUDGET_STEP_OPTIONS = {
  continueAsWin: 'CONTINUE_AS_WIN',
  continueAsLose: 'CONTINUE_AS_LOSE',
  continueAsResult: 'CONTINUE_AS_RESULT'
}
export const OPTIONS_MATCH_ORDER = {
  byAll: 'BY_ALL',
  byMajority: 'BY_MAJORITY',
}
