/* eslint-disable react/prop-types */
import { selectStrategies } from '@/redux/refactor/common/selector';
import { Grid } from '@/shared/components/form/drawer/Elements';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { isEqual } from 'lodash';
import React from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import BaseAmount from './BaseAmount';
import SelectBudget from './SelectBudget';

const FormBudget = ({ setBudgetId, isEdit }) => {
  const { values = {} } = useFormState();
  const strategies = useSelector(selectStrategies, isEqual);
  const showSelect =
    !values.copy_from &&
    SIGNAL_STRATEGY_TYPE.expert !== values.signal_type &&
    SIGNAL_STRATEGY_TYPE.manual !== values.signal_type;

  return (
    <>
      <Grid.Row cols={showSelect ? 2 : 1}>
        {showSelect && (
          <Grid.Cell
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="overflow-hidden"
          >
            <SelectBudget
              setBudgetId={setBudgetId}
              isEdit={isEdit}
              value={values.budget_strategy_id}
              strategies={strategies}
            />
          </Grid.Cell>
        )}
        <Grid.Cell>
          <BaseAmount value={values.base_amount} />
        </Grid.Cell>
      </Grid.Row>
    </>
  );
};

export default FormBudget;
