import { isEmpty } from 'lodash';
import { removeAscent, removeSpace, validateText } from '@/shared/helpers';
import { t } from 'i18next';

export const convertMaskToNum = (value) => {
  if (!value) return 0;

  return value.toString().startsWith('$')
    ? value.replace('$', '').replaceAll(',', '') // Number(value.replace('$', '').replaceAll(',', '')) -> Decimal numbers cannot be entered
    : value;
};
export const checkPlanName = (value) => {
  let text = value;
  text = removeAscent(text);
  if (text.length > 50) {
    return t('Plan name must be less than {{size}} characters long.', {
      size: 50,
    });
  }
  text = removeSpace(text);
  if (!validateText(text)) {
    return t('Plan name is invalid');
  }
  return undefined;
};
export const checkPlanBudget = (value) => {
  const num = convertMaskToNum(value);
  if (num > 1000000) {
    return t('Max value is $1,000,000');
  }

  return undefined;
};

export const checkBaseAmount = (value) => {
  const num = convertMaskToNum(value);
  if (num > 1000000 || num < 1) {
    return t('Value is invalid');
  }
  return undefined;
};

export const checkPlanTakeProfit = (takeProfit) => {
  const numTakeProfit = convertMaskToNum(takeProfit);
  
  if (numTakeProfit > 1000000) {
    return t('Take profit is invalid');
  }

  return undefined;
};

export const checkPlanStopLossPositive = (stopLossPositive) => {
  
  const numStopLossPositive = convertMaskToNum(stopLossPositive);

  if (numStopLossPositive > 0 && numStopLossPositive < 1) {
    return t('Positive stop loss is invalid');
  }

  return undefined;
};

export const checkPauseStreak = (value) => {
  const num = Number(value);
  if (!value || (num >= 0 && num <= 200)) {
    return undefined;
  }
  return t('Value greater than 200 is not allowed');
};

export const checkSubStreak = (value) => {
  const num = Number(value);
  if (!value || (num >= 0 && num <= 10000)) {
    return undefined;
  }
  return t('Number cannot greater than 10000');
};

export const checkTotalNumber = (value) => {
  const num = Number(value);
  if (!value || (num >= 0 && num <= 10000)) {
    return undefined;
  }
  return t('Number cannot greater than 10000');
};

export const checkPlanStopLoss = (value) => {
  const num = convertMaskToNum(value);
  if (num > 1000000) {
    return t('Stop Loss is invalid');
  }
  return undefined;
};

export const checkScheduleTime =
  (formValues = {}) =>
  () => {
    if (!formValues.is_scheduled) return undefined;

    // if (!formValues.start_at || !formValues.pause_at) {
    //   return t('All fields must have value');
    // }

    if (formValues.start_at === formValues.pause_at) {
      return t('Start time and Pause time must be different');
    }
    return undefined;
  };

export const checkExperts = (experts = []) => {
  if (experts.length === 0) {
    return t('error_input_experts_require');
  }
  if (experts.length > 5) {
    return t('error_input_experts_maximum');
  }
  return undefined;
};

export const checkTelegram = (experts = []) => {
  if (experts.length === 0) {
    return t('error_input_telegram_require');
  }
  return undefined;
};

export const advanceValidate = (value) => {
  const errors = {};

  errors.no_of_entries =
    !value.wait_signal || value.isUltilFinishBudget === 'a'
      ? undefined
      : checkBaseAmount(value.no_of_entries);

  errors.total_order = checkTotalNumber(value.total_order);

  const numStopLossPositive = convertMaskToNum(value.stop_loss_positive);
  const numTakeProfit = convertMaskToNum(value.take_profit);  

  if (value.subProfit) {
    errors.sub_win_streak = checkSubStreak(value.sub_win_streak);
    errors.sub_lose_streak = checkSubStreak(value.sub_lose_streak);
  } else {
    errors.sub_win_streak = undefined;
    errors.sub_lose_streak = undefined;
  }

  if (value.reachTarget) {
    errors.pause_win_streak = checkPauseStreak(value.pause_win_streak);
    errors.pause_lose_streak = checkPauseStreak(value.pause_lose_streak);
  } else {
    errors.pause_win_streak = undefined;
    errors.pause_lose_streak = undefined;
  }

  if (value.stop_loss_positive && value.take_profit && Number(numTakeProfit) > 0 && Number(numStopLossPositive) >= Number(numTakeProfit)) {
    errors.stop_loss_positive = t('Positive stop loss must be less than take profit');
  }

  return errors;
};

export const isInteger = (value) => {
  if (value && !Number.isInteger(Number(value))) {
    return t('Value must be an integer');
  }
  return undefined;
};
