import { Form } from '@/shared/components/form/drawer/Elements';
import { validatorHelper } from '@/shared/helpers';
import { Box } from '@material-ui/core';
import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { checkPlanName } from '../validator';
import { Input } from './FormElements';

// Begin PlanName
export default memo(({ isEdit }) => {
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  return (
    <Box className="not-atc-custom full-width">
      <Form.Label>{t('Plan name')}</Form.Label>
      <Field
        id="ipPlanName"
        name="name"
        component={Input}
        placeholder={t('Enter plan name...')}
        maxLength="50"
        validate={validator.composeValidators(
          validator.required,
          checkPlanName
        )}
        showError
        autoFocus={!isEdit}
      />
    </Box>
  );
});
// End PlanName
