import useDebounce from '@/hooks/useDebounce';
import AnimatedNumber from '@/shared/components/AnimatedNumber';
import { Form } from '@/shared/components/form/drawer/Elements';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import formatNumber from '@/shared/helpers/formatNumber';

import {
  colorSuccess,
  colorRed,
} from '@/utils/palette';

const Headline = ({ data, backtestProgress }) => {
  const { t } = useTranslation();
  const { backtestDataChart = [] } = useSelector(
    (state) => ({
      backtestDataChart: state.plan.backtestDataChart,
    }),
    _.isEqual
  );
  const debounceDataChart = useDebounce(backtestDataChart, 500);

  const profit = data?.profit || debounceDataChart.at(-1)?.profit || 0;

  const parseText = () => {
    if (data.maxProfit === 0 && data.minProfit < 0) {
      return t('Risky choice! You could lose {{amount}} with tactic.', {amount: formatNumber.formatCurrency(data.minProfit)});
    } else if (data.maxProfit === 0 && data.minProfit === 0) {
      return `${t('backtest_modal_result_title')} ${formatNumber.formatCurrency(data.maxProfit)}` ;
    } else if (data.maxProfit > 0 && data.maxProfit < 200) {
      return t('Nice option! You could win {{amount}} with tactic.', {amount: formatNumber.formatCurrency(data.maxProfit)});
    } else if (data.maxProfit >= 200 && data.maxProfit < 300) {
      return t('Great choice! You could win {{amount}} with tactic.', {amount: formatNumber.formatCurrency(data.maxProfit)})
    } 

    return t('You are genius! With this tactic, you could win {{amount}}.', {amount: formatNumber.formatCurrency(data.maxProfit)});
  }

  return (
    <Form.Headline style={{ marginBottom: 32 }}>
      <TextStyled>
        {backtestProgress !== 1 && (
          <>
            <span>{t('backtest_modal_result_title')}</span>
            <span
              className={classNames({
                'text-success': profit >= 0,
                'text-danger': profit < 0,
              })}
            >
              {profit > 0 ? '+' : profit < 0 ? '-' : ''}
              <AnimatedNumber value={Math.abs(profit)} />
            </span>
          </>
        )}
        {backtestProgress === 1 && (
          <>
            <span
              className={classNames({
                'good-choise': data.maxProfit > 0,
                'bad-choise': data.maxProfit <= 0,
              })}
              dangerouslySetInnerHTML={{
                __html: parseText(),
              }}
            />
          </>
        )}
      </TextStyled>
    </Form.Headline>
  );
};

Headline.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  backtestProgress: PropTypes.number.isRequired,
};

export default Headline;

const TextStyled = styled(Box)`
  .good-choise {
    span {
      color: ${colorSuccess};
    }
  }
  .bad-choise {
    span {
      color: ${colorRed};
    }
  }
`
