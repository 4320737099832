import { USER_TOKEN } from '@/config/const';
import authApi from '@/utils/api/authApi';
import { decodeJwt, getAccessToken, getRefreshToken, setToken } from '@/utils/helpers';
import { isEqual, sortBy } from 'lodash';
import React from 'react';

const currentTabKey = 'currentTabKey_';
const tabId = currentTabKey + new Date().getTime();
let timerId = null;

const getTabs = () => {
  const tabIds = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith('currentTabKey_')) {
      tabIds.push({
        key,
        created: Number(localStorage.getItem(key)),
      });
    }
  }
  return sortBy(tabIds, ['created']);
};
const setTab = () => {
  localStorage.setItem(tabId, new Date().getTime());
};
const removeTab = () => {
  localStorage.removeItem(tabId);
};

const canRefresh = () => {
  const stack = getTabs();
  return isEqual(tabId, stack[stack.length - 1]?.key);
};
const getRefreshTime = (accessToken) => {
  const { exp } = decodeJwt(accessToken);
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = Math.floor(exp);
  const secondsRemaining = expirationTime - currentTime;
  return (secondsRemaining - 30) * 1000;
};
const refreshHandler = () => {
  const accessToken = getAccessToken();
  const refreshTime = getRefreshTime(accessToken);
  if (refreshTime > 0) {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(refreshToken, refreshTime);
  }
};
const refreshToken = async () => {
  try {
    if (canRefresh()) {
      const token = getRefreshToken();
      const {
        data: { ok, d },
      } = await authApi.refresh(token);
      if (ok) {
        setToken(d);
      }
    }
  } catch (e) {
    console.log('refreshToken error: ', e);
  } finally {
    refreshHandler();
  }
};
const handleStorageChange = (event) => {
  if (event.key === USER_TOKEN) {
    refreshHandler();
  }
};

class RefreshTokenHandler extends React.PureComponent {
  componentDidMount() {
    setTab();
    window.addEventListener('beforeunload', removeTab);
    window.addEventListener('storage', handleStorageChange);
    refreshHandler();
  }

  componentWillUnmount() {
    removeTab();
    window.removeEventListener('beforeunload', removeTab);
    window.removeEventListener('storage', handleStorageChange);
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
  }

  render() {
    return null;
  }
}

export default RefreshTokenHandler;
