import numberWithCommas from './numberWithCommas';
import sliceDecimalFloat from './sliceDecimalFloat';
import formatBigNumber from './formatBigNumber';
import formatCurrency from './formatCurrency';
import formatCurrencyBigNumber from './formatCurrencyBigNumber';

const formatNumber = {
  sliceDecimalFloat,
  formatBigNumber,
  numberWithCommas,
  formatCurrency,
  formatCurrencyBigNumber
}

export default formatNumber;

