import { SIDEBAR_COLLAPSED_WIDTH, getDocumentUrl } from '@/config/const';
import { left } from '@/utils/directions';
import {
  colorBorder,
  colorGreyScale500,
  sidebarBackground,
  sidebarBackgroundHover,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import {
  Bookmark2,
  Briefcase,
  Home2,
  MedalStar,
  MessageText,
  People,
  PresentionChart,
  Share,
  Shuffle,
  Trade,
} from 'iconsax-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import gtag from '@/shared/constants/gtag';

import { ExchangeLinkedBadge } from './ExchangeLinkedBadge';
import SidebarLink, {
  SidebarExternalLink,
  SidebarLinkTitle,
} from './SidebarLink';
import { selectCurrentTotalPlan } from '@/redux/selectors/dashboardSelector';

const ga4Stats = window.ga4Stats;

const SidebarContent = ({ collapse }) => {
  const totalPlan = useSelector(selectCurrentTotalPlan, _.isEqual);

  const handleClickViewDocument = () => {
    ga4Stats(gtag.document.action.viewDocument, gtag.document.category, gtag.document.label);
  }

  const handleClickViewHelp = () => {
    ga4Stats(gtag.help.action.viewHelp, gtag.help.category, gtag.help.label);
  }

  const { t } = useTranslation();
  return (
    <SidebarContentWrap collapse={collapse} className="main-sidebar">
      <SidebarSubtitle collapse={collapse}>{t('MENU')}</SidebarSubtitle>
      <SidebarBlock collapse={collapse} className="sidebar-links">
        <SidebarLink
          title={t('Dashboard')}
          icon={Home2}
          route="/"
          collapse={collapse}
          exact
        />
        <SidebarLink
          title={t('Portfolio')}
          icon={Briefcase}
          route="/portfolios"
          numberBade={totalPlan}
          collapse={collapse}
          id="sidebar-portfolio"
          // className="hot"
        />
        <SidebarLink
          title={t('Budget Strategy')}
          icon={Trade}
          route="/budget-strategies"
          id="sidebar-budget-strategy"
          collapse={collapse}
        />
        <SidebarLink
          title={t('Signal Strategy')}
          icon={PresentionChart}
          route="/signal-strategies"
          id="sidebar-signal-strategy"
          collapse={collapse}
        />
      </SidebarBlock>
      <SidebarBlockDivider collapse={collapse} />
      <SidebarBlock className="border-none" collapse={collapse}>
        <SidebarLink
          title={t('Manage Follower')}
          icon={People}
          route="/manage-follower"
          id="manage-follower"
          collapse={collapse}
        />
        <SidebarLink
          title={t('Manual Trade')}
          icon={Shuffle}
          route="/manual-trade"
          id="sidebar-manual-trade"
          collapse={collapse}
        />
        <SidebarLink
          title={t('Referral')}
          icon={Share}
          route="/referral"
          id="sidebar-vip-member"
          collapse={collapse}
        />
        <SidebarLink
          title={t('Vip Member')}
          icon={MedalStar}
          route="/affiliation"
          id="sidebar-vip-member"
          collapse={collapse}
        />
      </SidebarBlock>
      <Box flex={1} />
      <SidebarBlockBottom collapse={collapse}>
        <SidebarExternalLink
          title={t('Documents')}
          icon={Bookmark2}
          url={getDocumentUrl()}
          id="sidebar-document"
          collapse={collapse}
          onClick={handleClickViewDocument}
        />
        <SidebarLink
          title={t('Get Help')}
          icon={MessageText}
          route="/help/faqs"
          id="sidebar-help"
          collapse={collapse}
          onClick={handleClickViewHelp}
        />
        {!collapse && <ExchangeLinkedBadge />}
      </SidebarBlockBottom>
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  display: flex;
  flex-direction: column;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  .border-none {
    border: none !important;
  }

  @media screen and (min-width: 576px) {
    padding-top: 24px;

    ${(props) =>
      props.collapse &&
      `
      width: ${SIDEBAR_COLLAPSED_WIDTH}px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: ${SIDEBAR_COLLAPSED_WIDTH - 2}px;
        background: ${sidebarBackground(props)};
        
        span:last-of-type {
          // opacity: 0;
          // transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${sidebarBackgroundHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        // width: 275px;
        align-items: center;
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

const SidebarSubtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: ${colorGreyScale500};
  padding: 0 24px;
  margin-bottom: 8px;
  ${(props) =>
    props.collapse &&
    `
    visibility: hidden;
  `}
`;

const SidebarBlockDivider = styled(SidebarBlock)`
  border: none;
  /* flex: 1; */
`;
const SidebarBlockBottom = styled(SidebarBlock)`
  border: none;
`;

// endregion
