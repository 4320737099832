import { parseStringToMap, inValidRecord } from "./const"

const configOldRegex = /^(-?\d+):(t?w+)-(-?\d+):(t?l+)$/;
const configRegex = /^(-?\d+):(t?w+)-(-?\d+):(t?l+)-(-?\d+):(to)$/;
const winLoseText = {
  w: 'w',
  l: 'l',
  tw: 'tw',
  tl: 'tl',
  to: 'to'
}

export const getOrderType = (win, lose, order, winText, loseText, orderText) => {
  if (win && winLoseText.w === winText) {
    return 'WIN'
  }

  if (win && winLoseText.tw === winText) {
    return 'WIN_STREAK'
  }

  if (lose && winLoseText.l === loseText) {
    return 'LOSE'
  }

  if (lose && winLoseText.tl === loseText) {
    return 'LOSE_STREAK'
  }

  if (order && winLoseText.to === orderText) {
    return 'MAX_ORDERS'
  }

  return 'WIN';
}

export const getSkipConfigRow = (skipConfig) => {
  if (!skipConfig.enableSkip) return '0:w-0:l-0:to';

  const baseConfig = '0:w-0:l';
  if (skipConfig.orderType === 'MAX_ORDERS') {
    return `${baseConfig}-${skipConfig.order}:to`;
  }

  const orderTypeWin = skipConfig.orderType.includes('WIN') ? skipConfig.order : 0;
  const orderTypeLose = skipConfig.orderType.includes('LOSE') ? skipConfig.order : 0;
  const suffix = skipConfig.orderType.includes('STREAK') ? 't' : '';

  return `${orderTypeWin}:${suffix}w-${orderTypeLose}:${suffix}l`;
};

export const parseSourcesToResult = (sources) => {
  return sources.reduce((acc, item) => {
    const { num, order, candles } = parseStringToMap(item.source);
    return [
      ...acc,
      {
        record: item.source,
        sources: candles,
        candle: num,
        order,
        invalid: inValidRecord(num, candles),
      },
    ];
  }, []);
};

export const parseFirstSourceForSkipConfig = (sources) => {
  const firstSource = sources[0]?.source;
  const matches = firstSource.match(configRegex) || firstSource.match(configOldRegex);
  if (!matches) {
    return null;
  }

  const win = Number(matches[1]);
  const lose = Number(matches[3]);
  const order = Number(matches[5]) || 0;

  return {
    enableSkip: win + lose + order > 0,
    order: win || lose || order || 1,
    orderType: getOrderType(win, lose, order, matches[2], matches[4], matches[6] || ''),
  };
};
