import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserConfig } from '@/redux/refactor/appConfig/selector';
import NotificationSettingItem from './NotificationSettingItem';
import './index.scss';

const NotificationSetting = () => {
  const userConfigs = useSelector(selectUserConfig);

  const settingList = useMemo(() => [
    {
      setting: userConfigs.noti_timer_stop,
      label: 'Timer stop',
      text: 'Timer stop description',
      name: 'noti_timer_stop'
    },
    {
      setting: userConfigs.noti_timer_start,
      label: 'Timer start',
      text: 'Timer start description',
      name: 'noti_timer_start'
    },
    {
      setting: userConfigs.noti_insuf_balance,
      label: 'Insufficient balance',
      text: 'Insufficient balance description',
      name: 'noti_insuf_balance'
    },
    {
      setting: userConfigs.noti_take_profit,
      label: 'Take profit',
      text: 'Take profit description',
      name: 'noti_take_profit'
    },
    {
      setting: userConfigs.noti_stop_loss,
      label: 'Stop loss',
      text: 'Stop loss description',
      name: 'noti_stop_loss'
    },
    {
      setting: userConfigs.noti_user_stop_loss,
      label: 'Daily goal stop loss',
      text: 'Daily goal stop loss description',
      name: 'noti_user_stop_loss'
    },
    {
      setting: userConfigs.noti_user_take_profit,
      label: 'Daily goal take profit',
      text: 'Daily goal take profit description',
      name: 'noti_user_take_profit'
    },
    {
      setting: userConfigs.noti_widthdraw,
      label: 'Widthdraw',
      text: 'Widthdraw description',
      name: 'noti_widthdraw'
    },
    {
      setting: userConfigs.noti_deposit,
      label: 'Deposit',
      text: 'Deposit description',
      name: 'noti_deposit'
    },
  ], [userConfigs]);

  return (
    <ul className="notification-setting-list list-unstyled">
      {settingList.map(s => (
        <NotificationSettingItem
          setting={s.setting}
          label={s.label}
          text={s.text}
          name={s.name}
          key={s.name}
        />
      ))}
    </ul>
  );
};

export default NotificationSetting;
