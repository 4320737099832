import { getAccessToken, getToken, removeToken } from '@/utils/helpers';
import { handleActions } from 'redux-actions';
import {
  authLoaded,
  clearAuthority,
  loginFailure,
  loginRequest,
  loginRequire2Fa,
  loginSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  setAnotherDevice,
  setKickOut,
  setPasswordlessSubmitting,
  updateInfoRequest,
  updateInfoRequestFailed,
  updateInfoRequestSuccess,
  updateProfile,
  verify2FaFailure,
  verify2FaRequest,
  verify2FaSuccess,
} from './actions';

const defaultState = {
  isAuth: !!getAccessToken(),
  token: getToken(),
  email: null,
  token2Fa: null,
  profile: null,
  isFetching: false,
  error: null,
  isRequire2Fa: false,
  isVerifying: false,
  processing: true,
  isRegisterSuccess: false,
  infoUpdating: false,
  isKickOut: false,
  anotherDevice: null,
  passwordlessSubmitting: false,
};

export default handleActions(
  {
    [clearAuthority](state) {
      removeToken();
      return {
        ...state,
        isAuth: false,
        token: null,
        profile: null,
        processing: false,
      };
    },
    [updateProfile](state, { payload }) {
      return {
        ...state,
        profile: payload,
        isVerifying: false,
        isFetching: false,
        processing: false,
      };
    },
    [authLoaded](state) {
      return {
        ...state,
        processing: false,
      };
    },
    [loginRequest](state) {
      return {
        ...state,
        isFetching: true,
        isAuth: false,
        isRequire2Fa: false,
        isVerifying: false,
        error: null,
      };
    },
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        token: payload,
        isFetching: false,
        isAuth: true,
        error: null,
        processing: false,
      };
    },
    [loginFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isAuth: false,
        error: payload,
      };
    },
    [loginRequire2Fa](state, { payload }) {
      return {
        ...state,
        token2Fa: payload,
        isRequire2Fa: true,
        isFetching: false,
        isAuth: false,
        error: null,
      };
    },
    [verify2FaRequest](state) {
      return {
        ...state,
        isVerifying: true,
        isFetching: false,
        isAuth: false,
        error: null,
      };
    },
    [verify2FaSuccess](state, { payload }) {
      return {
        ...state,
        isAuth: true,
        token: payload,
        error: null,
        token2Fa: null,
        isRequire2Fa: false,
        isVerifying: false,
      };
    },
    [verify2FaFailure](state, { payload }) {
      return {
        ...state,
        isAuth: false,
        error: payload,
        isVerifying: false,
        isFetching: false,
      };
    },
    [registerRequest](state, { payload }) {
      return {
        ...state,
        isFetching: true,
        email: payload,
        isRegisterSuccess: false,
        error: null,
      };
    },
    [registerSuccess](state) {
      return {
        ...state,
        isFetching: false,
        isRegisterSuccess: true,
        error: null,
      };
    },
    [registerFailure](state, { payload }) {
      return {
        ...state,
        error: payload,
        isRegisterSuccess: false,
        isFetching: false,
        email: null,
      };
    },

    [updateInfoRequest](state) {
      return {
        ...state,
        infoUpdating: true,
      };
    },
    [updateInfoRequestSuccess](state) {
      return {
        ...state,
        infoUpdating: false,
      };
    },
    [updateInfoRequestFailed](state) {
      return {
        ...state,
        infoUpdating: false,
      };
    },
    [setKickOut](state, { payload }) {
      return {
        ...state,
        isKickOut: payload,
      };
    },
    [setAnotherDevice](state, { payload }) {
      return {
        ...state,
        anotherDevice: payload,
        isKickOut: true,
      };
    },

    [setPasswordlessSubmitting](state, { payload }) {
      return {
        ...state,
        passwordlessSubmitting: payload,
      };
    },
  },
  defaultState
);
