import useQuery from '@/hooks/useQuery';
import useWindowSize from '@/hooks/useWindowSize';
import formatNumber from '@/shared/helpers/formatNumber';
import insightApi from '@/utils/api/insightApi';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ChartTooltip from '../ChartTooltip';
import './index.scss';

export const chartTypes = {
  profitable: 'best-profitable-tactics',
  signal: 'best-signal-strategy',
  tele: 'best-telegram-channel',
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InsightChart = () => {
  const { t } = useTranslation();
  const gridSize = useWindowSize();
  const query = useQuery();
  const { mode } = useSelector(
    (state) => ({
      mode: state.theme.className,
    }),
    _.isEqual
  );

  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: '#0CAF60',
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  });
  const [options, setOptions] = useState({});
  const [currentPoint, setCurrentPoint] = useState({});
  const [chartType, setChartType] = useState('');
  const [styleTooltipCurrentPoint, setStyleTooltipCurrentPoint] = useState({});

  const customTicks = () => {
    return [chartTypes.profitable, chartTypes.tele].includes(chartType)
      ? {
          color: '#A0AEC0',
          callback: (value, index, ticks) => `${t('Combination')} ${index + 1}`,
        }
      : {
          color: '#A0AEC0',
        };
  };

  const externalTooltipHandler = (context) => {
    const { tooltip } = context;
    try {
      if (tooltip?.dataPoints && tooltip?.dataPoints.length) {
        console.log(tooltip?.dataPoints[0]);
        const pointValue = tooltip?.dataPoints[0]?.raw;
        const pointLabel = tooltip?.dataPoints[0]?.label;
        setCurrentPoint({
          // eslint-disable-next-line
          label: pointLabel,
          value: pointValue,
        });
        const elTooltip = document.getElementById('bar-chart-tooltip');
        const elWrapper = document.getElementById('wrap-bar-chart');
        let leftPos = tooltip.caretX;
        const topPos = tooltip.caretY;

        if (elTooltip.offsetWidth + leftPos > elWrapper.offsetWidth) {
          leftPos -= elTooltip.offsetWidth;
        }

        setStyleTooltipCurrentPoint({
          left: `${leftPos}px`,
          top: `${topPos}px`,
          opacity: tooltip.opacity,
          zIndex: tooltip.opacity === 0 ? -1 : 9,
        });
      }
    } catch (error) {
      console.log(tooltip);
    }
  };

  useEffect(() => {
    document.body.classList.add('insight');

    return () => {
      document.body.classList.remove('insight');
    };
  });

  useEffect(() => {
    if (!query.get('id')) {
      return;
    }

    const newDataChart = { ...dataChart };

    insightApi.getDataChart(query.get('id')).then((res) => {
      if (!res.m) {
        const data = res.data.d.data || [];
        setChartType(res.data.d.chartType);

        newDataChart.labels = data.map((item) => item.name);

        newDataChart.datasets[0].data = data.map((item) => item.value);

        newDataChart.datasets[0].backgroundColor = data.map((item, index) => {
          if (mode === 'light') {
            return index === 0 ? '#0CAF60' : '#E7F7EF';
          } else {
            return index === 0 ? '#0CAF60' : 'rgba(231, 247, 239, .1)';
          }
        });
        setDataChart(newDataChart);
      }
    });
  }, [mode, query.get('id')]);

  useEffect(() => {
    if (!chartType) {
      return;
    }
    setOptions({
      responsive: true,
      layout: {
        padding: {
          top: 50, // Số lượng pixel để căn chỉnh khoảng cách padding top
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler,
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: customTicks(),
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            color: '#A0AEC0',
          },
        },
      },
      options: {
        maintainAspectRatio: false,
      },
    });
  }, [chartType]);

  return (
    <>
      <div id="wrap-bar-chart" className="wrap-bar-chart">
        <h3 className="chart-name">
          {chartType === chartTypes.profitable && t('4H Most profitable tactics')}
          {chartType === chartTypes.signal && t('4H Most signal strategy')}
          {chartType === chartTypes.tele && t('4H Most telegram channel')}
        </h3>

        {gridSize && ['sm', 'md', 'lg', 'xl', 'xxl'].includes(gridSize) && (
          <ChartTooltip
            id="bar-chart-tooltip"
            style={styleTooltipCurrentPoint}
            chartType={chartType}
            currentPoint={currentPoint}
          />
        )}

        {!!dataChart.datasets[0].data.length && (
          <>
            {gridSize && ['sm', 'md', 'lg', 'xl', 'xxl'].includes(gridSize) && (
              <Bar options={options} data={dataChart} />
            )}
            {gridSize && ['xs'].includes(gridSize) && (
              <div className="mb-chart">
                {dataChart.datasets[0].data.map((item, index) => (
                  <div className="mb-chart-item" key={index}>
                    <span className="mb-chart-item-label">{dataChart.labels[index]}</span>
                    <div
                      className="mb-chart-item-bar"
                      style={{
                        width: `${(item / dataChart.datasets[0].data[0]) * 100}%`,
                      }}
                    >
                      <span className="mb-chart-item-value">
                        {formatNumber.formatCurrency(item)}
                      </span>
                      <ChartTooltip
                        chartType={chartType}
                        currentPoint={{
                          label: dataChart.labels[index],
                          value: item,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InsightChart;
