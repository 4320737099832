import { Form } from '@/shared/components/form/drawer/Elements';
import React, { Fragment, memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TagInput } from './FormElements';

// Begin SelectLeader
export default memo(({ pushExpert, value = [], maxExpert = 5 }) => {
  const { t } = useTranslation();
  const hideInput = value.length >= maxExpert;

  const onBlur = (evt) => {
    pushExpert(evt.target.value);
    evt.preventDefault();
    evt.target.value = '';
  };

  const checkExperts = (experts = []) => {
    if (experts.length === 0) {
      return t('error_input_experts_require');
    }
    if (experts.length > maxExpert) {
      return t('error_input_experts_maximum');
    }
    return undefined;
  };

  return (
    <Fragment>
      <Form.Label>{t('Leader username')}</Form.Label>
      <Field
        name="experts"
        component={TagInput}
        hideInput={hideInput}
        placeHolder={t('Enter nickname')}
        validate={checkExperts}
        separators={['Enter', ',']}
        onBlur={onBlur}
      />
    </Fragment>
  );
});
// End SelectLeader
