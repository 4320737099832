import _ from 'lodash';
import store from '@/containers/App/store';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';


export const mutators = {
  setBudget: (args, state, utils) => {
    utils.changeValue(state, 'budget', () => args[0]);
  },
  setOtherBudget: (args, state, utils) => {
    utils.changeValue(state, 'otherBudget', () => args[0]);
  },
  setBudgetId: (args, state, utils) => {
    utils.changeValue(state, 'budget_strategy_id', () => args[0]);
  },
  setSignalId: (args, state, utils) => {
    utils.changeValue(state, 'bot_ai_id', () => args[0]);
  },
  setTelegramChannel: (args, state, utils) => {
    const { signal_type } = state.formState.values;

    if (signal_type === SIGNAL_STRATEGY_TYPE.telegram) {
      utils.changeValue(state, 'telegram_chanels', () => args[0]);
    } else if (signal_type === SIGNAL_STRATEGY_TYPE.community) {
      utils.changeValue(state, 'community_signals', () => args[0]);
    }
  },
  setBaseAmount: (args, state, utils) => {
    utils.changeValue(state, 'base_amount', () => args[0]);
  },
  setTakeProfit: (args, state, utils) => {
    utils.changeValue(state, 'take_profit', () => args[0]);
  },
  setStopLoss: (args, state, utils) => {
    utils.changeValue(state, 'stop_loss', () => args[0]);
  },
  setStopLossPositive: (args, state, utils) => {
    utils.changeValue(state, 'stop_loss_positive', () => args[0]);
  },
  setExperts: (args, state, utils) => {
    utils.changeValue(state, 'experts', () => args[0]);
  },
  pushExpert: (args, state, utils) => {
    const val = args[0];
    const { experts = [] } = state.formState.values;
    if (!_.isEmpty(val) && !_.includes(experts, val)) {
      utils.changeValue(state, 'experts', () => [...experts, val]);
    }
  },
  setExpertMode: (args, state, utils) => {
    utils.changeValue(state, 'expert_mode', () => args[0]);
  },
  setWinStreak: (args, state, utils) => {
    utils.changeValue(state, 'pause_win_streak', () => args[0]);
  },
  setLoseStreak: (args, state, utils) => {
    utils.changeValue(state, 'pause_lose_streak', () => args[0]);
  },
  setSubProfit: (args, state, utils) => {
    utils.changeValue(state, 'sub_take_profit', () => args[0]);
  },
  setSubStopLoss: (args, state, utils) => {
    utils.changeValue(state, 'sub_stop_loss', () => args[0]);
  },
  setShowSubProfit: (args, state, utils) => {
    utils.changeValue(state, 'showSubProfit', () => args[0]);
  },
  setOrderIndex: (args, state, utils) => {
    utils.changeValue(state, 'order_index', () => args[0]);
  },
  setDefaultBudgetConfig: (args, state, utils) => {
    const {
      reset_budget_at_resume = false,
      reset_budget_at_reprofit = false,
      reset_budget_at_tp_sl = false,
    } = store.getState()?.appConfig?.userConfig || {};
    utils.changeValue(
      state,
      'reset_budget_at_resume',
      () => reset_budget_at_resume
    );
    utils.changeValue(
      state,
      'reset_budget_at_reprofit',
      () => reset_budget_at_reprofit
    );
    utils.changeValue(
      state,
      'reset_budget_at_tp_sl',
      () => reset_budget_at_tp_sl
    );
  },
};
