/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { Box } from '@material-ui/core';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateCurrentBot,
  updateFormTypeBot,
  updateFormStatusBot,
  updateLatestCreatedBot,
  updateFormStatusCandle,
  updateFormTypeCandle
} from '@/redux/actions/botActions';

import {
  updateCurrentStrategy,
  updateFormTypeStrategy,
  updateFormStatusStrategy,
  updateLatestCreatedStrategy
} from '@/redux/actions/strategyActions';

import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { FORM_TYPE, SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { validatorHelper } from '@/shared/helpers';
import FormBudget from './FormBudget';
import SelectBotAI from './SelectBotAI';
import SelectChannel from './SelectChannel';
import SelectLeader from './SelectLeader';
import { SelectedInfo } from './SelectedInfo';
import SignalType from './SignalType';

const FormSignal = ({ isEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { latestCreatedBot, latestCreatedStrategy } = useSelector(
    (state) => ({
      latestCreatedBot: state.bot.latestCreatedBot,
      latestCreatedStrategy: state.strategy.latestCreatedStrategy,
    }),
    isEqual
  );

  const validator = validatorHelper(t);

  const { pushExpert, setTelegramChannel, setSignalId, setBudgetId, setExpertMode } =
    useForm().mutators;
  const { values = {} } = useFormState();
  const signalType = values.signal_type ? values.signal_type : SIGNAL_STRATEGY_TYPE.botai;

  const handleViewBotDetail = (bot) => {
    dispatch(updateCurrentBot(bot));
    dispatch(updateFormTypeBot(FORM_TYPE.read_only));
    dispatch(updateFormStatusBot(true));
  };
  const handleViewStrategyDetail = (strat) => {
    dispatch(updateFormTypeStrategy(FORM_TYPE.edit));
    dispatch(updateCurrentStrategy({ ...strat, is_default: true }));
    dispatch(updateFormStatusStrategy(true));
  };
  const handleViewCandleDetail = (candle) => {
    dispatch(updateCurrentBot(candle));
    dispatch(updateFormTypeCandle(FORM_TYPE.read_only));
    dispatch(updateFormStatusCandle(true));
  };

  useEffect(() => {
    if (latestCreatedBot?.id) {
      setSignalId(latestCreatedBot.id);
      dispatch(updateLatestCreatedBot({}))
    }
  }, [latestCreatedBot?.id]);

  useEffect(() => {
    if (latestCreatedStrategy?.id) {
      setBudgetId(latestCreatedStrategy.id);
      dispatch(updateLatestCreatedStrategy({}))
    }
  }, [latestCreatedStrategy?.id]);

  return (
    <Box>
      <Form.Headline>{t('Step 2: Setup your plan')}</Form.Headline>
      <SignalType setExpertMode={setExpertMode} isEdit={isEdit} />
      <Grid>
        {!values.copy_from && (
          <Grid.Row style={{ minHeight: 107 }}>
            {SIGNAL_STRATEGY_TYPE.botai === signalType && (
              <Grid.Cell
                className="overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <Field
                  name="bot_ai_id"
                  component={'input'}
                  type="hidden"
                  validate={validator.required}
                />
                <SelectBotAI
                  isEdit={isEdit}
                  setSignalId={setSignalId}
                  value={values.bot_ai_id}
                />
              </Grid.Cell>
            )}
            {SIGNAL_STRATEGY_TYPE.expert === signalType && (
              <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <SelectLeader pushExpert={pushExpert} value={values?.experts} />
              </Grid.Cell>
            )}
            
            {(SIGNAL_STRATEGY_TYPE.telegram === signalType || SIGNAL_STRATEGY_TYPE.community === signalType) && (
              <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <SelectChannel
                  isEdit={isEdit}
                  value={values.signal_type === SIGNAL_STRATEGY_TYPE.telegram ? values.telegram_chanels : values.community_signals}
                  setTelegramChannel={setTelegramChannel}
                />
              </Grid.Cell>
            )}
          </Grid.Row>
        )}
        <FormBudget setBudgetId={setBudgetId} isEdit={isEdit} />
        {!values.copy_from && SIGNAL_STRATEGY_TYPE.expert !== signalType && (
          <SelectedInfo
            viewBotDetail={handleViewBotDetail}
            viewStrategyDetail={handleViewStrategyDetail}
            viewCandleDetail={handleViewCandleDetail}
            isEdit={isEdit}
          />
        )}
      </Grid>
    </Box>
  );
};

export default FormSignal;
