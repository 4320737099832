import classNames from 'classnames';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colorGreyScale400, colorGreyScale900, colorPrimary100 } from '@/utils/palette';
import { TelegramMessageContext, TelegramSelectContext } from './context';
import formatNumber from '@/shared/helpers/formatNumber';

export function ListItem({ value = {} }) {
  const { t } = useTranslation();
  const { current, handleItemClick } = useContext(TelegramMessageContext);
  const { canSelect, selected = [], handleItemSelect } = useContext(TelegramSelectContext);
  const handleClick = () => handleItemClick(value);
  const handleSelect = () => handleItemSelect(value);
  const isSelected = selected.includes(value?.id);
  const isActive = current?.id === value?.id;
  const handleCheckboxClick = (evt) => evt.stopPropagation();
  return (
    <StyledItemContainer className="d-flex align-items-center">
      <StyledContent
        className={classNames({
          active: isActive,
          canSelect,
          'd-flex align-items-center': true,
        })}
        onClick={handleClick}
      >
        {canSelect && (
          <Form.Check
            disabled={!isSelected && selected.length >= 5}
            checked={isSelected}
            onChange={handleSelect}
            onClick={handleCheckboxClick}
          />
        )}
        <div className="d-block">
          <StyledName>{value?.name}</StyledName>
          {value?.winRate && (
            <StyledExtraInfo>
              {t('Win rate:')}{' '}
              <span className="text-success">
                {formatNumber.sliceDecimalFloat(value?.winRate * 100)}%
              </span>
              {' | '}
              {t('Win/Lose Streak:')} <span className="text-success">{value?.maxWinStreak}</span>
              {'/'}
              <span className="text-danger">{value?.maxLoseStreak}</span>
            </StyledExtraInfo>
          )}
        </div>
      </StyledContent>
    </StyledItemContainer>
  );
}

const StyledItemContainer = styled.div`
  padding: 0 16px;
  height: 100%;
`;

const StyledContent = styled.div`
  width: 100%;
  padding: 12px 16px;
  gap: 12px;
  &:hover,
  &.active {
    cursor: pointer;
    background-color: ${colorPrimary100};
    border-radius: 12px;
    color: ${colorGreyScale900};
  }
  .form-check-input {
    width: 16px;
    height: 16px;
    border-color: ${colorGreyScale400};
    background: none;
  }
  > div {
    display: flex;
    align-items: center;
  }
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledName = styled.div`
  font-size: 16px;
`;

const StyledExtraInfo = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;
