import React, { useContext } from "react";
import styled from 'styled-components';

import { TelegramMessageContext } from './context';

const MessageHeader = () => {
  const { current } = useContext(TelegramMessageContext);

  return <StyledHeader>{current?.name?.concat(':')}</StyledHeader>
}

const StyledHeader = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 16px;
`;

export default MessageHeader;
