import {
  colorGreyScale100,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale700,
  colorPrimary100,
  colorSuccess,
} from '@/utils/palette';
import styled from 'styled-components';
import theme from 'styled-theming';
import { BalanceInput } from '../portfolio/form/components/FormElements';
import { convertMaskToNum } from '../portfolio/form/validator';
import Error from './Error';

const svg = {
  minus: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6665 8H12.6665"
        stroke="#A0AEC0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plus: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.62414 2.30067L8.70899 13.6992"
        stroke="#0CAF60"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.96729 7.95752L14.3658 8.04237"
        stroke="#0CAF60"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

export function InputNumberGroup({
  input,
  isEdit,
  inputType,
  isAboveError,
  meta = {},
  min = 0,
  max = 9999999,
  ...props
}) {
  const { readOnly, disabled } = props;
  const { value, onChange } = input;

  const handleAdd = () => {
    if (disabled || readOnly) return;
    let num = 0;
    if (inputType === 'text') {
      num = Number(value);
    } else {
      num = convertMaskToNum(value);
    }
    if (num < max) onChange(num + 1);
  };
  const handleMinus = () => {
    if (disabled || readOnly) return;
    let num = 0;
    if (inputType === 'text') {
      num = Number(value);
    } else {
      num = convertMaskToNum(value);
    }
    if (num - 1 <= min) onChange(min);
    else onChange(num - 1);
  };
  const handleInputChange = (evt) => {
    if (disabled || readOnly) return;
    const num = convertMaskToNum(evt.target.value);
    if (num < min) {
      input.onChange(min);
    } else if (num > max) {
      input.onChange(max);
    } else {
      input.onChange(num);
    }
  };
  return (
    <div>
      <StyledGroup className="input-number-group">
        <ButtonMinus disabled={disabled || readOnly} onClick={handleMinus}>
          {svg.minus}
        </ButtonMinus>
        {inputType === 'text' ? (
          <NativeInputStyle
            type="number"
            min={min}
            max={max}
            {...meta}
            {...input}
            {...props}
            onChange={handleInputChange}
            onFocus={(evt) => evt.target.select()}
          />
        ) : (
          <InputStyle
            {...meta}
            {...input}
            {...props}
            onChange={handleInputChange}
            onFocus={(evt) => evt.target.select()}
          />
        )}
        <ButtonPlus disabled={disabled || readOnly} onClick={handleAdd}>
          {svg.plus}
        </ButtonPlus>
      </StyledGroup>
      {meta.error && <Error error={meta.error} />}
    </div>
  );
}

const StyledGroup = styled.div`
  display: flex;
  gap: 8px;
`;
const ButtonBase = styled.button`
  all: unset;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  &:disabled {
    pointer-events: none;
    background-color: ${theme('mode', {
      light: colorGreyScale300,
      dark: colorGreyScale700,
    })}!important;
    svg path {
      stroke: ${theme('mode', {
        dark: colorGreyScale500,
      })};
    }
  }
`;
const ButtonMinus = styled(ButtonBase)`
  background-color: ${theme('mode', {
    light: colorGreyScale300,
    dark: colorGreyScale700,
  })};
`;
const ButtonPlus = styled(ButtonBase)`
  background-color: ${theme('mode', {
    light: colorPrimary100,
    dark: colorSuccess,
  })};
  svg path {
    stroke: ${theme('mode', {
      dark: colorGreyScale100,
    })};
  }
`;
const InputStyle = styled(BalanceInput)`
  text-align: center;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  width: 90px;
  height: 32px;
`;

const NativeInputStyle = styled.input`
  all: unset;
  text-align: center;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  width: 60px;
  height: 32px;
`;
