import { left, paddingLeft } from '@/utils/directions';
import {
  Devices,
  colorAccent,
  colorAccentHover,
  colorAdditional,
  colorBackgroundBody,
  colorGreyScale300,
  colorGreyScale700,
  colorRed,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '@/utils/palette';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --toastify-color-dark: ${colorGreyScale700};
    --border-color: ${colorGreyScale300};
  }
  .dark {
    --border-color: ${colorGreyScale700};
  }
  
  .Toastify__toast {
    border-radius: 0px;
    align-items: center;
  }
  @media ${Devices.pc} {
    .Toastify__toast {
    border-radius: 16px;
    align-items: center;
  }
  }
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  main {
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0 !important;
    box-sizing: border-box;
    font-family: 'Manrope', 'Roboto', sans-serif;
    background-color: #F9FAFB;
  }
  :focus-visible {
    outline: none!important;
  }
  .row > * {
    padding: 0 15px;
  }

  * {
    box-sizing: inherit;
  }

  ul, ol {
    // doesn't do RTL, it break a sidebar
    padding-left: 15px;
    margin-bottom: 0;
    /* list-style-image: url(${process.env.PUBLIC_URL}/img/circle.svg); */
  }

  a {
    color: ${colorAccent};
    text-decoration: none;

    &:hover {
      color: ${colorAccentHover};
    }
  }

  button:focus, button:active {
    outline: none;
  }

  /* img {
    width: 100%;
  } */

  label {
    margin-bottom: 8px;
  }

  //TYPOGRAPHY
  p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    /* font-weight: 400; */
    margin-bottom: 0;
    color: ${colorText};
  }

  h1 {
    font-size: 36px;
    line-height: 48px;

    &.subhead {
      font-size: 30px;
      color: ${colorAdditional};
      line-height: 36px;
    }
  }

  h2 {

    &.subhead {
      font-size: 24px;
      color: ${colorAdditional};
      line-height: 32px;
    }
  }

  h3 {
    &.subhead {
      font-size: 18px;
      color: ${colorAdditional};
      line-height: 24px;
    }
  }

  h4 {
    /* font-size: 18px; */
    /* line-height: 24px; */

    &.subhead {
      font-size: 12px;
      color: ${colorAdditional};
      line-height: 16px;
    }
  }

  h5 {
    /* font-size: 14px; */
    /* line-height: 18px; */

    &.subhead {
      font-size: 10px;
      color: ${colorAdditional};
      line-height: 12px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 16px;

    &.subhead {
      font-size: 8px;
      color: ${colorAdditional};
      line-height: 10px;
    }
  }
  
  hr {
    opacity: 0.1;
  }
  
  .table-responsive {
    ${scrollbarStyles};
  }

  blockquote {
    font-size: 13px;
    font-style: italic;
    border-left: 3px solid ${colorAccent};
    margin-bottom: 0;
    text-align: ${left};
    ${paddingLeft}: 10px;
  }

  .highlight {
    background-color: ${colorAccent};
    color: ${colorWhite};
  }

  .typography-message{
    text-align: ${left};
  }

  .red-text {
    color: ${colorRed};
  }

  .page-title {
    text-align: ${left};
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;

    &:last-child {
      margin-bottom: 30px;
    }

    &.page-title--not-last {
      margin-top: 20px;
    }
  }

  .page-subhead {
    text-align: ${left};
    margin-bottom: 20px;

    &.subhead {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  ::selection {
    color: ${colorWhite};
    background: ${colorAccent};
  }

  .first-letter-uppercase:first-letter {
    text-transform: uppercase;
  }

  .container {

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 1630px;
    }
  }


  .rc-slider-tooltip {
    padding-bottom: 2px;

    .rc-slider-tooltip-inner {
      margin-bottom: 5px;
      background-color: ${colorBackgroundBody};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      color: ${colorText};
      font-size: 12px;
      line-height: 12px;
      padding: 4px 4px;
      height: 20px;
      min-width: 28px;
      border-radius: 0;
    }

    &.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      border-top-color: ${colorBackgroundBody};
    }

    &.rc-slider-tooltip-hidden {
      display: block;
    }
  }
  .full-height{
    height: 100%;
  }

  @keyframes flick {
    0% {
      transform: rotateZ(0deg);
    }

    50% {
      transform: rotateZ(50deg);
    }

    100% {
      transform: rotateZ(5deg);
    }
  }
  .flick-animate {
    animation: flick 1.3s ease-out backwards;
    animation-iteration-count: infinite;
  }
  .text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dot1 {
  animation: visibility 3s linear infinite;
}

@keyframes visibility {
  0% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 3s linear infinite;
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }

  21% {
    opacity: 0;
  }

  22% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 3s linear infinite;
}

@keyframes visibility3 {
  0% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.overflow-hidden {
  overflow: hidden;
}
.hidden {
  display: none;
}
.block {
  display: block;
}


`;

export default GlobalStyles;
