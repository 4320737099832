/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import useCaptcha from '@/hooks/useCaptcha';
import authApi from '@/utils/api/authApi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ButtonCountdown from './ButtonCountdown';
import ModalAccount, {
  ModalAccountDescription,
  ModalAccountTitle,
} from './ModalAccount';
import FormResendEmail from './registerForm/FormResendEmail';

const message = {
  [true]: {
    title: ['ModalResendEmail.successTitle', 'Confirmation email sent!'],
    description: [
      'ModalResendEmail.successDescription',
      '<p>A verification link has been sent to <a>{{email}}</a></p> <p>Please wait a few seconds to receive the confirmation email.',
    ],
  },
  [false]: {
    title: ['ModalResendEmail.title', 'Resend confirmation email'],
    description: [
      'ModalResendEmail.description',
      '<p>Please enter your email to resend your confirmation email.</p>',
    ],
  },
};

const ButtonResendMail = ({
  email,
  isDisabled,
  isLoading,
  label,
  defaultLabel,
  onSubmit,
}) => {
  const onClick = () => onSubmit({ email });
  return (
    <ButtonCountdown
      disabled={isDisabled || isLoading}
      variant="dark"
      size="lg"
      onClick={onClick}
    >
      {isDisabled ? label : defaultLabel}
    </ButtonCountdown>
  );
};
let timerId = null;
const ModalResendEmail = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [textCountdown, setTextCountdown] = useState('00:30');
  const { execute } = useCaptcha();

  const setCountDown = () => {
    let count = 30;
    setIsDisabled(true);
    if (timerId) clearInterval(timerId);
    timerId = setInterval(() => {
      if (count) {
        count -= 1;
        setTextCountdown(`00:${count <= 9 ? 0 : ''}${count}`);
      } else {
        setIsDisabled(false);
        setTextCountdown('00:30');
        clearInterval(timerId);
        timerId = null;
      }
    }, 1000);
  };

  const handleSubmit = async ({ email }) => {
    setIsLoading(true);
    const captcha = await execute('sendVerifyEmail');
    authApi.sendVerifyEmail({ email, captcha }).then(
      (resp) => {
        if (resp?.data?.ok) {
          setIsLoading(false);
          setInputEmail(email);
          setIsSuccess(true);
          setCountDown();
        }
      },
      (error) => {
        toast.error(error.message);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      setIsSuccess(false);
      setTextCountdown('00:30');
    } else {
      clearInterval(timerId);
      timerId = null;
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
    };
  }, [isOpen]);

  return (
    <ModalAccount closeModal={closeModal} isOpen={isOpen}>
      <ModalAccountTitle fontsize={isSuccess ? '32px' : '24px'}>
        {t(message[isSuccess].title[0], message[isSuccess].title[1])}
      </ModalAccountTitle>
      <ModalAccountDescription
        dangerouslySetInnerHTML={{
          __html: t(
            message[isSuccess].description[0],
            message[isSuccess].description[1],
            { email: inputEmail }
          ),
        }}
      />
      {isSuccess && (
        <ButtonResendMail
          email={inputEmail}
          isDisabled={isDisabled}
          isLoading={isLoading}
          label={textCountdown}
          defaultLabel={t('Request a new one')}
          onSubmit={handleSubmit}
        />
      )}
      {!isSuccess && (
        <FormResendEmail isLoading={isLoading} onSubmit={handleSubmit} />
      )}
    </ModalAccount>
  );
};

ModalResendEmail.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ModalResendEmail.defaultProps = {
  isOpen: false,
};

export default ModalResendEmail;
