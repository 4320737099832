import useDebounce from '@/hooks/useDebounce';
import { selectBots } from '@/redux/refactor/common/selector';
import { Button } from '@/shared/components/Button';
import { Form } from '@/shared/components/form/drawer/Elements';
import CustomSelect from '@/shared/components/mui-select-customized/CustomSelect';
import SearchInput from '@/shared/components/mui-select-customized/SearchInput';
import { Box } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import PlusIcon from 'mdi-react/PlusIcon';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { FORM_TYPE } from '@/shared/constants';
import { updateCurrentBot, updateFormTypeBot, updateFormStatusBot } from '@/redux/actions/botActions';

// Begin SelectBotAI
export default memo(({ setSignalId, isEdit, value }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 200);
  const { t } = useTranslation();
  const bots = useSelector(selectBots, isEqual);
  const [filtered, setFiltered] = useState([]);

  const handleRenderValue = (selected) => {
    const bot = bots.find((item) =>
      _.isEqual(item.id.toString(), selected.toString())
    );
    return bot?.name;
  };
  const handleSelect = (value) => setSignalId(value.toString());
  const handleOnClose = () => setSearch('');
  const openFormBot = () => {
    dispatch(updateCurrentBot({}));
    dispatch(updateFormTypeBot(FORM_TYPE.create));
    dispatch(updateFormStatusBot(true));
  }

  const handleRenderCTA = () => {
    return (
      <div className="mui-select-item cta-success" value="qi-cta">
        <Box
          className="mui-select-customize-searchbox"
          display={'flex'}
          alignItems={'center'}
        >
          <SearchInput
            placeholder={t('Search by name...')}
            value={search}
            onChange={setSearch}
          />
          <Button onClick={openFormBot} variant="success_outline">
            <PlusIcon />
          </Button>
        </Box>
      </div>
    );
  };

  // initial value
  useEffect(() => {
    if (!isEdit && _.isEmpty(value) && bots.length > 0) {
      setTimeout(() => {
        setSignalId(bots[0]?.id?.toString());
      }, 0);
    }
  }, [bots]);

  useEffect(() => {
    let res = bots;
    const searchTerm = _.deburr(debouncedSearchTerm).toLocaleLowerCase();
    if (!isEmpty(searchTerm)) {
      res =
        bots?.filter((item) => {
          return _.includes(item.searchTerm, searchTerm);
        }) || [];
    }
    setFiltered(res);
  }, [bots, debouncedSearchTerm]);

  return (
    <Fragment>
      <Box className="full-width select-signal">
        <Form.Label>{t('Signal strategy')}*</Form.Label>
        <CustomSelect
          options={filtered}
          value={value}
          placeHolder={t('Select Bot...')}
          onSelect={handleSelect}
          onClose={handleOnClose}
          onRenderValue={handleRenderValue}
          renderItemKey={(item) => item.id}
          renderItemLabel={(item) => item.name}
          renderItemValue={(item) => item.id}
          renderCTA={handleRenderCTA}
          autoFocus={false}
        />
      </Box>
    </Fragment>
  );
});
