/* eslint-disable max-len */
import { Button } from '@/shared/components/Button';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './index.scss';

const NotFound404 = () => {
  const { t } = useTranslation();
  return (
    <div className="page-404">
      <Helmet title={`${t('Not found')} | Quickinvest.ai`} />
      <h2 className="mb-3">{t('WELL, THIS IS EMBRASSING!')}</h2>
      <p className="mb-4">
        {t(
          'We are very sorry for inconvenience, it looks like you’re trying to access  a page that either has been deleted or never existed'
        )}
      </p>
      <Button as={Link} variant="success" to="/">
        {t('back_home', 'Back to Home')}
      </Button>
    </div>
  );
};

export default NotFound404;
