import { darken, lighten } from 'polished';
import theme from 'styled-theming';

export const VARIANTS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  PRIMARY: 'primary',
  DANGER: 'danger',
  INFO: 'info',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  LINK: 'link',
  OUTLINE: 'outline',
  OUTLINE_SUCCESS: 'outline_success',
};
export const SIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
};

export const BreakPoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const Devices = {
  mobileS: `(min-width: ${BreakPoints.mobileS})`,
  mobileM: `(min-width: ${BreakPoints.mobileM})`,
  mobileL: `(min-width: ${BreakPoints.mobileL})`,
  tablet: `(min-width: ${BreakPoints.tablet})`,
  laptop: `(min-width: ${BreakPoints.laptop})`,
  pc: `(min-width: ${BreakPoints.laptop})`,
  laptopL: `(min-width: ${BreakPoints.laptopL})`,
  // desktop: `(min-width: ${BreakPoints.desktop})`,
  // desktopL: `(min-width: ${BreakPoints.desktop})`,
};

export const colorPrimary100 = '#e7f7ef';

export const colorAccent = '#0BA259';
export const colorLightAccent = '#86D7B0';
export const colorAccentHover = '#55C790';
export const colorAdditional = '#999999';

export const colorSuccess = '#0CAF60';
export const colorSuccessHover = '#55C790';
export const colorSuccessDisabled = '#86D7B0';

export const colorYellow = '#f6da6e';
export const colorLightYellow = '#f4dd87';
export const colorYellowHover = darken(0.1, colorYellow);

export const colorViolet = '#c88ffa';

export const colorRed = '#FD4F4F';
export const colorDarkRed = '#721c24';
export const colorLightRed = '#f88e7d';
export const colorVeryLightRed = '#f8d7da';
export const colorRedHover = lighten(0.1, colorRed);

export const colorBlue = '#0062FF';
export const colorLightBlue = '#70bbfd';
export const colorBlueHover = lighten(0.1, colorBlue);

export const colorBlack = '#000000';
export const colorGray = '#787985';
export const colorLightGray = '#d8dfe9';
export const colorDustyWhite = '#dddddd';
export const colorWhite = '#ffffff';

export const colorGreen = '#b8e986';

export const colorDarkFieldsBorder = '#33333a';

export const colorBackgroundGrey = theme('mode', {
  light: '#F9FAFB',
  dark: '#111827',
});
export const colorBackground = theme('mode', {
  light: 'white',
  dark: '#111827',
});

export const colorBackgroundBody = theme('mode', {
  light: '#FFFFFF',
  dark: '#111827',
});
export const colorBackgroundTopbarSecondary = theme('mode', {
  light: '#FFFFFF',
  dark: '#1F2937',
});

export const colorBackgroundModal = theme('mode', {
  light: colorWhite,
  dark: '#1F2937',
});

export const inboxButtonBackground = theme('mode', {
  light: '#f2f4f7',
  dark: '#2a2a31',
});

export const inboxButtonBackgroundHover = theme('mode', {
  light: darken(0.05, '#f2f4f7'),
  dark: lighten(0.05, '#2a2a31'),
});

export const colorHeaderDataPicker = theme('mode', {
  light: '#6db0ff',
  dark: '#063263',
});

export const logoImg = theme('mode', {
  light: `url(${process.env.PUBLIC_URL}/img/logo/logo_light.svg?v=1)`,
  dark: `url(${process.env.PUBLIC_URL}/img/logo/logo_dark.svg?v=1)`,
});
export const shortLogoImg = theme('mode', {
  light: `url(${process.env.PUBLIC_URL}/img/logo/short_logo_light.svg?v=1)`,
  dark: `url(${process.env.PUBLIC_URL}/img/logo/short_logo_dark.svg?v=1)`,
});

export const logoUrl = theme('mode', {
  light: `${process.env.PUBLIC_URL}/img/logo/logo_light.svg?v=1`,
  dark: `${process.env.PUBLIC_URL}/img/logo/logo_dark.svg?v=1`,
});

export const colorHover = theme('mode', {
  light: '#fafbfe',
  dark: '#38373f',
});

export const colorFolderHover = theme('mode', {
  light: '#f0eeee',
  dark: '#ffffff1A',
});

export const colorBorder = theme('mode', {
  light: '#eff1f5',
  dark: '#333246',
});

export const colorIcon = theme('mode', {
  light: '#dddddd',
  dark: '#605f7b',
});

export const imgInvert = theme('mode', {
  light: 'invert(0%)',
  dark: 'invert(100%)',
});

export const colorFieldsBorder = theme('mode', {
  light: '#FAFAFA',
  dark: '#323B49',
});

export const colorBubble = theme('mode', {
  light: 'rgba(242, 244, 247, 0.65)',
  dark: 'rgba(68, 79, 97, 0.65)',
});

export const colorBubbleActive = theme('mode', {
  light: 'rgba(234, 238, 255, 0.6)',
  dark: 'rgba(92, 104, 156, 0.6)',
});

export const colorScrollbar = theme('mode', {
  light: '#CBD5E0',
  dark: '#606071',
});

export const colorFitness = theme('mode', {
  light: '#111827',
  dark: '#ffffff',
});

export const colorEmoji = theme('mode', {
  light: '#232329',
  dark: '#ffffff',
});

export const colorCheckboxBorder = theme('mode', {
  light: '#CBD5E0',
  dark: '#323B49',
});

export const colorBlackBackground = theme('mode', {
  light: '#232329',
  dark: '#16161C',
});
// export const colorBackgroundBody= '#f2f4f7';
export const colorLightText = '#111827';
// export const colorHover = '#fafbfe';

export const scrollbarStyles = (props) => `
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    opacity: 0.3;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${colorScrollbar(props)};
  }   
`;

/** Refactor Color */
export const colorGreyScale50 = '#FAFAFA';
export const colorGreyScale100 = '#F9FAFB';
export const colorGreyScale200 = '#F1F2F4';
export const colorGreyScale300 = '#EEEFF2';
export const colorGreyScale400 = '#CBD5E0';
export const colorGreyScale500 = '#A0AEC0';
export const colorGreyScale600 = '#718096';
export const colorGreyScale700 = '#323B49';
export const colorGreyScale800 = '#1F2937';
export const colorGreyScale900 = '#111827';

export const colorText = theme('mode', {
  light: colorGreyScale900,
  dark: colorGreyScale50,
});

export const colorSubText = theme('mode', {
  light: colorGreyScale600,
  dark: colorGreyScale50,
});

export const colorDarkText = theme('mode', {
  light: '#555555',
  dark: colorGreyScale50,
});

export const colorTextAdditional = theme('mode', {
  light: colorGreyScale900,
  dark: '#999999',
});

export const borderDefaultStyle = theme('mode', {
  light: '1px solid #F1F2F4',
  dark: '1px solid #323B49',
});
export const borderDashedStyle = theme('mode', {
  light: '1px dashed #F1F2F4',
  dark: '1px dashed #323B49',
});

export const sidebarBackground = theme('mode', {
  light: '#ffffff',
  dark: '#1F2937',
});

export const sidebarBackgroundHover = theme('mode', {
  light: '#fafbfe',
  dark: colorGreyScale700,
});

export const dividerColor = theme('mode', {
  light: '#F1F2F4',
  dark: '#323B49',
});
export const sidebarCollapseBtnBackground = theme('mode', {
  light: '#ffffff',
  dark: '#111827',
});
export const sidebarColor = theme('mode', {
  light: 'aliceblue',
  dark: '#232329',
});

export const colorInputBackground = theme('mode', {
  light: '#FAFAFA',
  dark: colorGreyScale700,
});

export const colorInputText = theme('mode', {
  light: '#323B49',
  dark: colorWhite,
});

export const colorOtpInputBackground = theme('mode', {
  light: colorGreyScale300,
  dark: colorGreyScale700,
});

export const colorOtpInputText = theme('mode', {
  light: colorGreyScale900,
  dark: colorGreyScale50,
});

export const dropdownBackground = theme('mode', {
  light: '#ffffff',
  dark: colorGreyScale800,
});

export const dropdownItemBackground = theme('mode', {
  light: colorGreyScale100,
  dark: colorGreyScale700,
});

export const dropdownShadow = theme('mode', {
  light: '9.72603px 14.589px 48.6301px rgba(113, 128, 150, 0.2)',
  dark: '10px 15px 50px rgba(0, 0, 0, 0.5)',
});

export const cardBgColor = theme('mode', {
  light: colorWhite,
  dark: colorGreyScale800,
});
