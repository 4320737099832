/* eslint-disable no-param-reassign */
import { API_URL, STORAGE_KEY, USER_TOKEN } from '@/config/const';
import axios from 'axios';
import { isArray } from 'lodash';
import { decodeJwt, getAccessToken, getRefreshToken } from '../../helpers';
import jwtDecode from 'jwt-decode';

axios.defaults.baseURL = API_URL.concat('/v1');

export const clientId = process.env.REACT_APP_CLIENT_ID;
export const getApiUrl = (endpoint) => {
  if (endpoint && endpoint.startsWith('/api/auth/me/photo')) {
    endpoint = endpoint.replace('/api/auth', `${API_URL}/v1/users`);
  }
  return endpoint;
};
export const getQueryString = (params) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key] && !isArray(params[key])) {
      query.append(key, params[key]);
    }
    if (params[key] && isArray(params[key])) {
      params[key].map((item) => {
        if (item) {
          query.append(key, item);
        }
      });
    }
  });

  return query.toString();
};
export const defaultParams = () => ({
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const lstAuthRequest = [
  '/users/auth/token',
  '/users/auth/token?refresh=1',
  '/users/account/forgot-password',
  '/users/account/reset-password',
  '/users/account/register',
  '/users/account/verify-email',
  '/users/account/resend-confirmation-email',
  '/users/auth/token-2fa',
  '/users/auth/settings',
  '/wp-json/wp/v2',
  '/plan-insights/',
];

const isAuthRequest = (config) => {
  return lstAuthRequest.some(
    (p) => p === config.url || API_URL.concat(p) === config.url || config.url.includes(p)
  );
};

const dispatch = (action) => window?.store?.dispatch(action);
const getActions = (name, params) => (window?.actions[name] ? window?.actions[name](params) : null);
let fetchingTokenRequest;
const fetchAccessToken = () => {
  if (!fetchingTokenRequest) {
    const token = getRefreshToken();
    if (token) {
      fetchingTokenRequest = dispatch(getActions('refreshToken', token));
    } else {
      dispatch(getActions('clearAuthority'));
      window.location.reload();
      throw new axios.Cancel('Operation canceled.');
    }
  }
  return fetchingTokenRequest;
};

const setHeaderAuthorization = (reqConfig) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    reqConfig.headers.Authorization = `Bearer ${accessToken}`;
  }
};

axios.interceptors.request.use(
  async (reqConfig) => {
    // if (!window.isOnline) throw new axios.Cancel('Network offline!');
    const unauthorizedCounter = JSON.parse(localStorage.getItem('unauthorizedCounter'));

    if (unauthorizedCounter) {
      if (typeof unauthorizedCounter === 'number') {
        localStorage.removeItem("unauthorizedCounter");
      }

      if (unauthorizedCounter.count >= 15) {
        localStorage.removeItem("unauthorizedCounter");
        localStorage.removeItem("USER_TOKEN");
      }
    }

    const accessToken = getAccessToken();
    if (!accessToken) {
      if (!isAuthRequest(reqConfig)) {
        await fetchAccessToken()
          .then((resp) => {
            // console.log('axios.interceptors.request: ', resp);
            fetchingTokenRequest = null;
            if (resp && !resp.error) {
              setHeaderAuthorization(reqConfig);
            } else {
              dispatch(getActions('clearAuthority'));              

              throw new axios.Cancel('Unauthorized.');
            }
          })
          .catch((_error) => {
            console.log('axios.interceptors.request: ', _error);
            fetchingTokenRequest = null;
            dispatch(getActions('clearAuthority'));
            return Promise.reject(_error);
          });
      }
    } else {
      setHeaderAuthorization(reqConfig);
    }

    return reqConfig;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response = {} } = error;
    const { status } = response;

    const currentTime = new Date().getTime();

    let unauthorizedCounter = JSON.parse(localStorage.getItem('unauthorizedCounter'));

    if (status === 429) {
      alert('Please try again later.');
      return new Promise(() => {});
    }
    if (status === 401) {
      if (!unauthorizedCounter) {
        unauthorizedCounter = { count: 1, firstTimestamp: currentTime };
        localStorage.setItem('unauthorizedCounter', JSON.stringify(unauthorizedCounter));
      } else {
        if (currentTime - unauthorizedCounter.firstTimestamp <= 15000) {
          unauthorizedCounter.count += 1;
          localStorage.setItem('unauthorizedCounter', JSON.stringify(unauthorizedCounter));
        } else {
          localStorage.removeItem("unauthorizedCounter");
        }

        if (unauthorizedCounter?.count >= 15) {
          localStorage.removeItem("unauthorizedCounter");
          localStorage.removeItem("USER_TOKEN");
        }
      }

      return fetchAccessToken()
        .then((resp) => {
          fetchingTokenRequest = null;
          if (resp && !resp.error) {
            setHeaderAuthorization(config);
            return axios(config);
          }
          dispatch(getActions('clearAuthority'));
          throw new axios.Cancel('Unauthorized.');
        })
        .catch((_error) => {
          fetchingTokenRequest = null;
          dispatch(getActions('clearAuthority'));
          return Promise.reject(_error);
        });
    }

    return Promise.reject(error);
  }
);

export default axios;
