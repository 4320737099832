import { deleteRequest, get, post, put } from './base';

import { getHubConnection } from '../helpers';
import { getQueryString } from './base/axios';

export default {
  getPlans: (params) => get(`/plans/investment-plan?${getQueryString(params)}`),
  getPlan: (planId) => get(`/plans/investment-plan/${planId}`),
  createPlan: (params, version) =>
    post(`/plans/investment-plan?version=${version}`, params),
  editPlan: (params, version) =>
    put(`/plans/investment-plan?version=${version}`, params),
  getMethods: (type) => get(`/plans/Shared/${type}`),
  resetTodayProfit: (planId) =>
    put(`/plans/investment-plan/${planId}/reset-profit`),
  importPlan: (code) =>
    post(`/plans/investment-plan/${encodeURIComponent(code)}`, {}),
  clonePlan: (code) =>
    post(`/plans/investment-plan/${encodeURIComponent(code)}/true`, {}),
  duplicatePlan: (code, data) =>
    post(`/plans/investment-plan/${encodeURIComponent(code)}/false`, data),
  updateStatus: (planId, planStatus) =>
    put(`/plans/investment-plan/${planId}/${planStatus}`),
  renewCode: (planId) => put(`/plans/investment-plan/${planId}/renew-code`),
  deletePlan: (planId) => deleteRequest(`/plans/investment-plan/${planId}`),
  getStatusHistory: (
    planId,
    page,
    size,
    includeAction = true,
    includeOrder = true
  ) =>
    get(
      `/plans/investment-plan/${planId}/action-history?page=${page}&size=${size}&includeAction=${includeAction}&includeOrder=${includeOrder}`
    ),
  getStatusHistoryV2: (planId, queries) =>
    get(
      `/plans/investment-plan/${planId}/action-history-v2?${getQueryString(
        queries
      )}`
    ),
  // trade: params => post('/plans/investment-plan/manual-order', params),
  trade: async (params) => {
    const connection = await getHubConnection();
    return connection.invoke('ManualOrder', params);
  },
  followerPlan: () => get('/plans/investment-plan/follower-count'),
  getPlanByCode: (code) => get(`/plans/investment-plan/${code}/copy-plan`),
  getManualPlan: (accountType) =>
    get(`/plans/investment-plan/${accountType}/manual-plan`),
  createManualPlan: (accountType) =>
    post(`/plans/investment-plan/${accountType}/create-manual-plan`),
  updateManualPlan: (expertMode) =>
    put(`/plans/investment-plan/${expertMode}/manual-plan`),
  totalActive: () => get('/plans/investment-plan/total-active'),
  createBacktest: (params) => post('/plans/backtest', params),
  getBacktestHistory: (page, size) =>
    get(`/plans/backtest/histories?page=${page}&size=${size}`),
  getBacktestHistoryDetail: (id) => get(`/plans/backtest/histories/${id}`),
  getTotalHistory: () => get('/plans/backtest/total-history'),
  getBacktestDataChart: (id) => get(`/plans/backtest/${id}`),
  createPlanByBacktest: (params) => post('/plans/backtest/plan', params),
  getSignalStrategy: () => get('/plans/backtest/signal-strategies'),
  getBudgetStrategy: () => get('/plans/backtest/budget-strategies'),
  getFollowers: (params) => {
    let url = `/plans/investment-plan/follow-plans?page=${params?.page}&size=${params?.size}`;
    return get(url);
  },
  getDailyGoal: (accountType) => get(`/plans/site/${accountType}/daily-goal`),
  setDailyGoal: (params) => post('/plans/site/daily-goal', params),
  getSessionHistories: (params) =>
    get(`/plans/investment-plan/follow-leader?${getQueryString(params)}`),
  getStepBudget: (planId) =>
    get(`/plans/investment-plan/current-position-budget?planId=${planId}`),
  getTimers: (accountType) =>
    get(`/plans/investment-plan/${accountType}/timers`),
  createTimer: (params) => post('/plans/investment-plan/timer', params),
  updateTimer: (params) => put('/plans/investment-plan/timer', params),
  deleteTimer: (timerId) =>
    deleteRequest(`/plans/investment-plan/${timerId}/timer`),
  getLastActionHistories: () =>
    get('/plans/investment-plan/last-action-histories'),
  getFollowerPlans: (params) => get(`/plans/investment-plan/follower-plans?${getQueryString(params)}`),
  getFollowerPlanActionHistories: (planId) => get(`/plans/investment-plan/follower-plans?planId=${planId}`),
  getOrderTriggerData: (orderId) => get(`/plans/investment-plan/order-trigger-data/${orderId}`),
  getOrderData: (planId, session, type) => get(`/plans/investment-plan/refresh-calculating-plan?planId=${planId ? planId : ''}&session=${session}&type=${type}`),
  createCondition: (params) => post('/plans/investment-plan/plan-switch-strategy', params),
  getConditions: (planId) => get(`/plans/investment-plan/plan-switch-strategy?planId=${planId}`),
  updateConditions: (params) => put('/plans/investment-plan/plan-switch-strategies', params),
  deleteCondition: (conditionId) => deleteRequest(`/plans/investment-plan/plan-switch-strategy/${conditionId}`),
  getWaitSignal: (planId) => get(`/plans/investment-plan/plan-wait-signals?planId=${planId}`),
};
