import { handleActions } from 'redux-actions';

import {
  updateUserClickCreatePlanFromLeaderPlan,
  updateUserClickBacktestSignalType,
  updateUserClickCopyTopPlan,
  updateUserClickCreatePlanFromTopSignal,
  updateUserClickCreatePlanFromMultiTopSignal
} from '../actions/userActivityTrackerActions';

const defaultState = {
  isClickCreatePlanFromLeaderPlan: false,
  backtestSignalType: '',
  isClickCopyTopPlan: false,
  isClickCreatePlanFromTopSignal: false,
  isClickCreatePlanFromMultiTopSignal: false,
};

export default handleActions(
  {
    [updateUserClickCreatePlanFromLeaderPlan](state, { payload }) {
      return {
        ...state,
        isClickCreatePlanFromLeaderPlan: payload,
      };
    },
    [updateUserClickBacktestSignalType](state, { payload }) {
      return {
        ...state,
        backtestSignalType: payload,
      };
    },
    [updateUserClickCopyTopPlan](state, { payload }) {
      return {
        ...state,
        isClickCopyTopPlan: payload,
      };
    },
    [updateUserClickCreatePlanFromTopSignal](state, { payload }) {
      return {
        ...state,
        isClickCreatePlanFromTopSignal: payload,
      };
    },
    [updateUserClickCreatePlanFromMultiTopSignal](state, { payload }) {
      return {
        ...state,
        isClickCreatePlanFromMultiTopSignal: payload,
      };
    },
  },
  defaultState,
);
