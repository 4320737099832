/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import planApi from '@/utils/api/planApi';
import toastHandler from '@/shared/helpers/toastHandler';
import { PlanCrudContext } from '.';
import gtag from '@/shared/constants/gtag';
import { updateUserClickCopyTopPlan, updateUserClickCreatePlanFromTopSignal, updateUserClickCreatePlanFromMultiTopSignal, updateUserClickCreatePlanFromLeaderPlan } from '@/redux/actions/userActivityTrackerActions';

import DrawerClonePlan from '@/containers/Portfolio/DrawerClonePlan';
import PopupActivePlan from '@/shared/components/portfolio/PopupActivePlan';
import PopupDuplicatePlan from '@/shared/components/portfolio/PopupDuplicatePlan';
import PopupSocialPlan from '@/shared/components/portfolio/PopupSocialPlan';
import DrawerCreatePlan from '@/shared/components/portfolio/form/DrawerCreatePlan';
import PopupDeletePlan from '@/shared/components/portfolio/PopupDeletePlan';
import PopupImportPlan from '@/shared/components/portfolio/PopupImportPlan';
import PopupSharePlan from '@/shared/components/portfolio/PopupSharePlan';
import PopupEditConfirmPlan from '@/shared/components/portfolio/PopupEditConfirmPlan';
import PopupConfirmStartPlan from '@/shared/components/portfolio/PopupConfirmStartPlan';

const ga4Stats = window.ga4Stats;

const voidFunc = () => {};
let currentCallback = voidFunc;

export const PlanCrudProvider = ({ children }) => {
  const planRef = useRef({});
  const isEdit = useRef(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const accountType = useSelector((state) => state.wallet.accountType, _.isEqual);

  const [modalsState, setModalsState] = useState({
    showCreate: false,
    showShare: false,
    showActive: false,
    showClone: false,
    showSocial: false,
    showDuplicate: false,
    showDelete: false,
    showImport: false,
    showEditConfirm: false,
    showConfirmStart: false,
  });

  const toggleModal = useCallback((modalName, value) => {
    setModalsState((prevState) => ({
      ...prevState,
      [modalName]: value,
    }));
  }, []);

  const getPlanDetail = async (plan) => {
    const res = await toastHandler(t, 'get_detail_plan', () => planApi.getPlan(plan.id));
    if (res?.data?.ok) {
      planRef.current = res?.data?.d;
      isEdit.current = true;
      toggleModal('showCreate', true);
    }
  };

  const handleCreate = (initialValues = {}, onSuccess = voidFunc) => {
    isEdit.current = false;
    currentCallback = onSuccess;
    if (!modalsState.showCreate) {
      planRef.current = initialValues;
    }
    
    toggleModal('showCreate', !modalsState.showCreate);
  };

  const handleEdit = (plan = {}, onSuccess = voidFunc) => {
    currentCallback = onSuccess;
    if (modalsState.showActive) {
      toggleModal('showActive', false);
    }

    if (plan.id) {
      getPlanDetail(plan);
    } else {
      planRef.current = {};
      isEdit.current = false;
      toggleModal('showCreate', false);
    }
  };

  const handleActive = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    toggleModal('showActive', !modalsState.showActive);
  };

  const handleImportPlan = (onSuccess = voidFunc) => {
    currentCallback = onSuccess;
    toggleModal('showImport', true);
  };

  const handleClonePlan = (plan) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    toggleModal('showClone', true);
  };

  const handleSocialPlan = async (plan) => {
    const res = await toastHandler(t, 'get_detail_plan', () => planApi.getPlan(plan.id));
    
    if (res?.data?.ok) {
      planRef.current = res?.data?.d;
      toggleModal('showSocial', true);
    }

    ga4Stats(gtag.plan.action.shareQRPlan, gtag.plan.category, accountType);
  };

  const handleDuplicatePlan = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    toggleModal('showDuplicate', !modalsState.showDuplicate);
  };

  const handleShowDeletePlan = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    toggleModal('showDelete', true);
  };

  const handleDeletePlanOnSuccess = () => {
    const currentPath = location.pathname.replace('/portfolios', '');
    if (currentPath && currentPath.length) {
      history.push('/portfolios');
    } else {
      currentCallback();
    }
  };

  const handleSharePlan = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    toggleModal('showShare', !modalsState.showShare);
  };

  const handleEditConfirm = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    if (plan.is_ongoing) {
      toggleModal('showEditConfirm', true);
    } else {
      handleEdit(plan, onSuccess);
    }
  };

  const handleConfirmStart = (plan, onSuccess = voidFunc) => {
    planRef.current = !_.isEmpty(plan) ? plan : {};
    currentCallback = onSuccess;
    toggleModal('showConfirmStart', true);
  };

  useEffect(() => {
    if (!modalsState?.showClone) {
      dispatch(updateUserClickCopyTopPlan(false))
    }
  }, [modalsState?.showClone])

  useEffect(() => {
    if (!modalsState?.showCreate) {
      dispatch(updateUserClickCreatePlanFromTopSignal(false));
      dispatch(updateUserClickCreatePlanFromMultiTopSignal(false));
      dispatch(updateUserClickCreatePlanFromLeaderPlan(false))
    }
  }, [modalsState?.showCreate])  

  const values = useMemo(
    () => ({
      handleCreate,
      handleEdit,
      handleActive,
      handleClonePlan,
      handleSocialPlan,
      handleDuplicatePlan,
      handleShowDeletePlan,
      handleImportPlan,
      handleSharePlan,
      handleEditConfirm,
      handleConfirmStart,
    }),
    []
  );

  return (
    <PlanCrudContext.Provider value={values}>
      {children}
      <DrawerCreatePlan
        isOpen={modalsState.showCreate}
        onClose={handleCreate}
        onActivePlan={handleActive}
        currentPlan={planRef.current}
        isEdit={isEdit.current}
        onSuccess={currentCallback}
      />
      <PopupActivePlan
        show={modalsState.showActive}
        setShow={handleActive}
        lastCreatedPlan={planRef.current}
        onEditPlan={handleEdit}
        onSuccess={currentCallback}
      />
      <PopupEditConfirmPlan
        show={modalsState.showEditConfirm}
        onHide={() => toggleModal('showEditConfirm', false)}
        showEditPlan={() => handleEdit(planRef.current, currentCallback)}
      />
      <PopupImportPlan
        show={modalsState.showImport}
        onHide={() => toggleModal('showImport', false)}
        onShowDrawerClonePlan={handleClonePlan}
      />
      <DrawerClonePlan
        plan={planRef.current}
        isOpen={modalsState.showClone}
        onClose={() => toggleModal('showClone', false)}
        onActivePlan={handleActive}
        onSuccess={currentCallback}
      />
      <PopupSocialPlan
        currentPlan={planRef.current}
        show={modalsState.showSocial}
        setShow={(val) => {
          toggleModal('showSocial', val);
          planRef.current = {};
        }}
      />
      <PopupDuplicatePlan
        copyCode={planRef.current?.copy_code}
        show={modalsState.showDuplicate}
        onHide={handleDuplicatePlan}
        onSuccess={currentCallback}
      />

      <PopupDeletePlan
        show={modalsState.showDelete}
        onHide={() => toggleModal('showDelete', false)}
        currentPlan={planRef.current || {}}
        onSuccess={handleDeletePlanOnSuccess}
      />

      <PopupSharePlan
        show={modalsState.showShare}
        onHide={() => toggleModal('showShare', false)}
        currentPlan={planRef.current || {}}
        onSuccess={currentCallback}
      />

      <PopupConfirmStartPlan
        show={modalsState.showConfirmStart}
        onHide={() => toggleModal('showConfirmStart', false)}
        currentPlan={planRef.current || {}}
        onSuccess={currentCallback}
      />
    </PlanCrudContext.Provider>
  );
};

export const withPlanCrud = (Child) => (props) =>
  (
    <PlanCrudContext.Consumer>
      {(value) => <Child {...props} planCrud={value} />}
    </PlanCrudContext.Consumer>
  );
