import EmailVerification from '@/containers/Account/EmailVerification';
import ExchangeConnect from '@/containers/Account/ExchangeConnect';
import LogIn from '@/containers/Account/LogIn';
import Register from '@/containers/Account/Register';
import ResetPassword from '@/containers/Account/ResetPassword';
import Landing from '@/containers/Landing';
import InsightChart from '@/containers/QuickInsight/InsightChart';
import InsightDetail from '@/containers/QuickInsight/InsightDetail';
import SignalHub from '@/shared/components/signal-hub';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import { AuthRoute, ConnectedRoute, ProtectedRoute } from './CustomRoute';
import HelpRoutes from './HelpRoutes';
import WrappedRoutes from './WrappedRoutes';

const mapStateToPropsRoute = (state) => ({
  loggedIn: !!state.user.isAuth,
});

const Router = connect(mapStateToPropsRoute)(({ loggedIn }) => {
  console.log('Router -> loggedIn', loggedIn);
  return (
    <MainWrapper>
      <SignalHub />
      <main>
        <Switch>
          <AuthRoute path="/login" component={LogIn} />
          <AuthRoute path="/register" component={Register} />
          <AuthRoute path="/verify-account" component={EmailVerification} />
          <AuthRoute path="/reset-password" component={ResetPassword} />
          <ConnectedRoute path="/connect" component={ExchangeConnect} />
          <Route path="/help" component={HelpRoutes} />
          <Route path="/quick-insight/:postId" component={InsightDetail} />
          {!loggedIn ? (
            <Route exact path="/" component={Landing} />
          ) : (
            <ProtectedRoute path="/" component={WrappedRoutes} />
          )}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </main>
    </MainWrapper>
  );
});

export default Router;
