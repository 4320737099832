import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NotificationList from './NotificationList';
import {
  makeGetNotiesByType,
  makeGetPageConfigsByType,
  selectNotiCurrentTab
} from '@/redux/selectors/notiSelector';
import { updateNotiCurrentTab } from '@/redux/actions/notiActions';
import { NOTIFICATION_TYPE } from '@/shared/constants';

const CustomTabTitle = ({ title, count }) => (
  <div className="d-flex align-items-center">
    {title} {!!count && <span className="count ms-1">{count}</span>}
  </div>
);

const NotificationTab = ({ closePopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getNotiesByType = useMemo(makeGetNotiesByType, []);
  const getGetPageConfigsByType = useMemo(makeGetPageConfigsByType, []);
  const [isMounted, setIsMounted] = useState(false);

  const {
    notiesOfTypeSystem, 
    notiesOfTypePlan, 
    pageConfigsOfTypeSystem, 
    pageConfigsOfTypePlan ,
    currentTab
  } = useSelector(state => ({
    notiesOfTypeSystem: getNotiesByType(state, NOTIFICATION_TYPE.system, state.wallet.accountType),
    notiesOfTypePlan: getNotiesByType(state, NOTIFICATION_TYPE.plan, state.wallet.accountType),
    pageConfigsOfTypeSystem: getGetPageConfigsByType(state, NOTIFICATION_TYPE.system),
    pageConfigsOfTypePlan: getGetPageConfigsByType(state, NOTIFICATION_TYPE.plan),
    currentTab: selectNotiCurrentTab(state)
  }), _.isEqual);

  useEffect(() => {    
    if(!isMounted &&
      ((!notiesOfTypeSystem.length && notiesOfTypePlan.length) || 
      (notiesOfTypeSystem.length && notiesOfTypePlan.length && notiesOfTypeSystem[0].read && !notiesOfTypePlan[0].read))
    ) {
      dispatch(updateNotiCurrentTab(NOTIFICATION_TYPE.plan))
    }
  }, [notiesOfTypeSystem, notiesOfTypePlan]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Tabs
      defaultActiveKey={NOTIFICATION_TYPE.system}
      id="notification-tab"
      className="style-3"
      activeKey={currentTab}
      onSelect={(k) => dispatch(updateNotiCurrentTab(k))}
    >
      <Tab
        eventKey={NOTIFICATION_TYPE.system}
        title={
          <CustomTabTitle
            title={t('System')}
            count={pageConfigsOfTypeSystem?.unread}
          />
        }
      >
        {currentTab === NOTIFICATION_TYPE.system && (
          <NotificationList
            items={notiesOfTypeSystem}
            channel={NOTIFICATION_TYPE.system}
            closePopup={closePopup}
          />
        )}
      </Tab>
      <Tab
        eventKey={NOTIFICATION_TYPE.plan}
        title={
          <CustomTabTitle
            title={t('Plan Events')}
            count={pageConfigsOfTypePlan?.unread}
          />
        }
      >
        {currentTab === NOTIFICATION_TYPE.plan && (
          <NotificationList
            items={notiesOfTypePlan}
            channel={NOTIFICATION_TYPE.plan}
            closePopup={closePopup}
          />
        )}
      </Tab>
    </Tabs>
  );
};

NotificationTab.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default NotificationTab;
