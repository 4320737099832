import useQuery from '@/hooks/useQuery';
import { Button } from '@/shared/components/Button';
import ButtonLoading from '@/shared/components/ButtonLoading';
import Modal, {
  ModalBodyStyle,
  ModalFooterStyle,
  ModalTitleStyle,
} from '@/shared/components/Modal';
import planApi from '@/utils/api/planApi';
import {
  colorAccent,
  colorGreyScale500,
  colorGreyScale600,
  colorGreyScale900,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const PopupImportPlan = ({ show, onHide, onShowDrawerClonePlan }) => {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [codePlan, setCodePlan] = useState('');
  const { t } = useTranslation();
  const handeCodeChange = (event) => {
    const reg = /^[A-Za-z0-9 -]*$/;
    if (reg.test(event.target.value)) {
      setCodePlan(event.target.value.replace(/ /g, ''));
    }
  };

  const handlePaste = async () => {
    const code = await navigator.clipboard.readText();
    setCodePlan(code);
  };

  const handleSubmit = () => {
    if (!codePlan.length) {
      return;
    }
    setIsLoading(true);
    planApi.getPlanByCode(codePlan).then((res) => {
      setIsLoading(false);
      if (res.data.ok) {
        onShowDrawerClonePlan(res.data.d);
        onHide();
        setCodePlan('');
      } else {
        toast.error(t(res.data.m));
      }
    });
  };

  useEffect(() => {
    if (query.get('copyCode')) {
      setCodePlan(query.get('copyCode'));
    }
  }, [query.get('copyCode')]);

  if (!show) return null;

  return (
    <Modal
      isOpen={show}
      closeModal={onHide}
      title={t('Import Investment Plan')}
      centered
    >
      <ModalBodyStyle>
        <TitleStyle>{t('Easy way to set up your superb plan!')}</TitleStyle>
        <SubTitle>
          {t('Enter your shared plan code to start your ideal investment plan')}
        </SubTitle>
        <Form>
          <Form.Group>
            <Box mb="12px">
              <Form.Label>{t('Shared plan code')}</Form.Label>
            </Box>
            <Box mb="12px">
              <InputGroup className="mb-2">
                <FormInput
                  placeholder={t('Enter Code')}
                  value={codePlan}
                  onChange={handeCodeChange}
                  className="mb-0"
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={handlePaste}
                  className="text-success"
                >
                  {t('PASTE')}
                </Button>
              </InputGroup>
            </Box>
            <Form.Text>
              <strong className="me-1">{t('Note')}:</strong>
              <span>
                {t(
                  'You will be able to review the plan to confirm after importing code.'
                )}
              </span>
            </Form.Text>
          </Form.Group>
        </Form>
      </ModalBodyStyle>
      <ModalFooterStyle>
        {!isLoading && (
          <Button
            variant="success"
            paddinghorizontal="115"
            mblock="true"
            onClick={handleSubmit}
            disabled={!codePlan.length}
          >
            {t('Import Now')}
          </Button>
        )}
        {isLoading && <ButtonLoading variant="success" />}
      </ModalFooterStyle>
    </Modal>
  );
};

PopupImportPlan.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onShowDrawerClonePlan: PropTypes.func.isRequired,
};

const TitleStyle = styled(ModalTitleStyle)`
  font-size: 20px;
  color: ${colorAccent};
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;
const SubTitle = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${colorGreyScale600};
  margin-bottom: 32px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;
const FormInput = styled(Form.Control)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: ${colorGreyScale900};
  ::placeholder {
    color: ${colorGreyScale500};
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`;

export default PopupImportPlan;
