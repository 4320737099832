import axios from "axios";
import { get } from './base';
export const wpBaseDomain = 'https://insights.quickinvest.ai';
export const insightDomain = 'https://qinsights.bitgo.press';

export default {
  getPosts: () => axios.get(`${wpBaseDomain}/wp-json/wp/v2/posts`),
  getPost: id => axios.get(`${wpBaseDomain}/wp-json/wp/v2/posts/${id}`),
  getFeaturedImage: id => axios.get(`${wpBaseDomain}/wp-json/wp/v2/media/${id}`),
  getCategory: id => axios.get(`${wpBaseDomain}/wp-json/wp/v2/categories/${id}`),
  getDataChart: id => axios.get(`${insightDomain}/plan-insights/charts/${id}`),
  getSignalBudgetDefaultFromChartId: id => get(`/plans/plan-insights/signalbudget/${id}`),
};
