import { Button } from '@/shared/components/Button';
import Logo from '@/shared/components/Logo';
import Maintenance from '@/shared/img/maintenance.svg';
import {
  borderDefaultStyle,
  colorBackground,
  colorText,
  Devices,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SelectLanguage from '../SelectLanguage';

const UnderMaintenance = () => {
  const { t } = useTranslation();
  const onRefresh = async () => {
    window.location.reload();
  };
  return (
    <Wrapper>
      <Nav>
        <Box flex={1}>
          <Logo />
        </Box>
        <SelectLanguage />
      </Nav>
      <Body>
        <MaintenanceStyle />
        <Title>{t('We are under maintenance')}</Title>
        <SubTitle>
          {t(
            'The page you’re looking for is currently under maintenance and will be back soon'
          )}
        </SubTitle>
        <Box width="100%" maxWidth="450px">
          <Button onClick={onRefresh} variant="success" block="true">
            {t('Refresh page')}
          </Button>
        </Box>
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled(Box)``;
const Nav = styled.nav`
  background: ${colorBackground};
  border-bottom: ${borderDefaultStyle};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  @media ${Devices.pc} {
    height: 97px;
    padding: 0 100px;
  }
`;
const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;
const MaintenanceStyle = styled(Maintenance)`
  max-width: 539px;
  width: 100%;
  height: auto;
  margin-top: 48px;
  margin-bottom: 24px;
  @media ${Devices.pc} {
    margin-top: 84px;
    margin-bottom: 84px;
  }
`;
const Title = styled(Box)`
  font-weight: 800;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: ${colorText};
  margin-bottom: 16px;
  @media ${Devices.pc} {
    font-size: 40px;
  }
`;
const SubTitle = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #718096;
  margin-bottom: 32px;
  @media ${Devices.pc} {
    margin-bottom: 40px;
    font-size: 16px;
  }
`;

export default UnderMaintenance;
