import {
  Devices,
  colorGreyScale100,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale700,
} from '@/utils/palette';
import { SearchNormal } from 'iconsax-react';
import { forwardRef, useContext } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { useTranslation } from 'react-i18next';
import { TelegramMessageContext } from './context';

export const SearchBox = forwardRef(() => {
  const { t } = useTranslation();
  const { searchStr = '', setSearchStr } = useContext(TelegramMessageContext);
  const handleChange = ({ target }) => setSearchStr(target.value);
  
  return (
    <StyledSearchBox className='signal-search-box'>
      <SearchNormal />
      <StyledInput value={searchStr} onChange={handleChange} placeholder={`${t('Search Channel')}...`} />
    </StyledSearchBox>
  );
});

const background = theme('mode', {
  dark: colorGreyScale700,
  light: colorGreyScale100,
});
const backgroundMobile = theme('mode', {
  dark: colorGreyScale700,
  light: colorGreyScale300,
});

const StyledSearchBox = styled.div`
  background: ${backgroundMobile};
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  @media ${Devices.pc} {
    border-radius: 0;
    background: ${background};
  }
`;
const StyledInput = styled.input`
  all: unset;
  flex: 1;
  &::placeholder {
    color: ${colorGreyScale500};
  }
`;
