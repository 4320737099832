import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _, { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getBots, updateCurrentBot, updateFormTypeCandle, updateLatestCreatedBot } from '@/redux/actions/botActions';
import { getBots as getCommonBots } from '@/redux/refactor/common/actions';
import { FORM_TYPE, SIGNAL_STRATEGY_TYPE, STRATEGY_TYPE } from '@/shared/constants';
import { validateText } from '@/shared/helpers';
import botApi from '@/utils/api/botApi';
import { getSkipConfigRow, parseSourcesToResult, parseFirstSourceForSkipConfig } from './candlePattern.helper';

import { Form } from '@/shared/components/form/drawer/Elements';

import CandleConfigPanel from './config-panel';
import { TAB } from './const';
import { CandlePatternContext, CandlePatternProvider } from './context';
import FormCandlePattern from './form';
import FormCandleFooter from './FormCandleFooter';

function CandlePattern({
  onClose,
}) {
  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formCandle.type,
    }),
    isEqual
  );
  const inputRef = useRef(null);
  const isEdit = formType === FORM_TYPE.edit;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pagination } = useSelector((state) => state.bot, _.isEqual);
  const { isDefault, setResult, setIsDefault } = useContext(CandlePatternContext);
  const [tab, setTab] = useState(TAB.FORM);
  const [copyFrom, setCopyFrom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [botName, setBotName] = useState('');
  const [validBotName, setValidBotName] = useState({
    isValid: false,
    text: '',
  });
  const [skipConfig, setSkipConfig] = useState({
    enableSkip: false,
    orderType: 'WIN',
    order: 1,
  });

  const checkValidBotName = (value = '') => {
    const text = value;
    if (text.length > 50 || !validateText(text)) {
      setValidBotName({ isValid: false, text: t('Bot name is invalid') });
    } else {
      setValidBotName({ isValid: true, text: '' });
    }
  };

  const handleChangeBotName = (event) => {
    setBotName(event.target.value);
    checkValidBotName(event.target.value);
  };

  const handleChangeSkipConfig = (option) => {
    setSkipConfig({ ...skipConfig, ...option });
  };

  const handleSubmit = async (candles = []) => {
    try {
      setLoading(true);
      const configRow = getSkipConfigRow(skipConfig);      
      const sources = candles.reduce((acc, item) => [...acc, item.record], [configRow]);
      const body = {
        name: botName,
        strategy: STRATEGY_TYPE.candlePattern,
        type: SIGNAL_STRATEGY_TYPE.botai,
        sources,
      };

      if (!(formType === FORM_TYPE.create || currentBot.is_customize)) {
        body.id = currentBot.id;
      }

      const request = (formType === FORM_TYPE.create || currentBot.is_customize) ? botApi.createBot : botApi.editBot;

      const { data: { ok, d, m } } = await request(body);

      if (!ok) {
        toast.error(t(d[0] || m));
        return;
      }

      toast.success(t(formType === FORM_TYPE.create ? 'Create the bot successfully!' : 'Edit the bot successfully!'));
      dispatch(getCommonBots());
      dispatch(getBots({ page: pagination.p, size: pagination.s }));
      if (formType === FORM_TYPE.create) {
        dispatch(updateLatestCreatedBot({ id: d }));
      }      
      onClose();
    } catch (e) {
      toast.error(t(e.message));
    } finally {
      setLoading(false);
    }
  };

  const handleCustomize = () => {
    dispatch(updateFormTypeCandle(FORM_TYPE.create));
    setBotName('');
    checkValidBotName('');
    setIsDefault(false);
    dispatch(updateCurrentBot({
      is_customize: true,
      type: currentBot.type,
      strategy: currentBot.strategy,
      sources: currentBot.sources,
    }));
    setTimeout(() => inputRef?.current?.focus(), 100);
  };

  const initializeForm = (bot) => {
    setBotName(bot.name);
    checkValidBotName(bot.name);
    setCopyFrom(bot['copy_from']);
    setIsDefault(formType === FORM_TYPE.read_only);
  
    if (!bot['copy_from']) {
      initializeSkipConfigAndResult(bot.sources);
    }
  };

  const initializeSkipConfigAndResult = (sources) => {
    let updatedSources = [...sources];
    const skipConfig = parseFirstSourceForSkipConfig(updatedSources);
    if (skipConfig) {
      setSkipConfig(skipConfig);
      updatedSources = updatedSources.slice(1);
    }
    setResult(parseSourcesToResult(updatedSources));
  };
  
  useEffect(() => {
    if (currentBot?.name) {
      initializeForm(currentBot);
    }    
  }, [currentBot]);

  return (
    <Fragment>
      <Form.Header onClose={onClose} />
      <Form.Body>
        {tab === TAB.FORM && (
          <Form.Headline>
            {t(isDefault ? 'View candle pattern' : 'Setup your candle pattern')}
          </Form.Headline>
        )}
        {tab === TAB.FORM ? (
          <FormCandlePattern
            inputRef={inputRef}
            botName={botName}
            skipConfig={skipConfig}
            validBotName={validBotName}
            copyFrom={copyFrom}
            onChangeName={handleChangeBotName}
            setTab={setTab}
            isDefault={isDefault}
            onChangeSkipConfig={handleChangeSkipConfig}
          />
        ) : (
          <CandleConfigPanel isEdit={isEdit} isReadOnly={false} />
        )}
      </Form.Body>
      <FormCandleFooter
        tab={tab}
        loading={loading}
        validBotName={validBotName}
        copyFrom={copyFrom}
        setTab={setTab}
        onClose={onClose}
        handleCustomize={handleCustomize}
        allowCustomize={formType === FORM_TYPE.edit}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
}

export default function ({ ...props }) {
  return useMemo(
    () => (
      <CandlePatternProvider>
        <CandlePattern {...props} />
      </CandlePatternProvider>
    ),
    []
  );
}
