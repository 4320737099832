import React from 'react';
import {
 AccountHead, AccountOr, AccountSocial, AccountTitle, 
} from '@/shared/components/account/AccountElements';
import RegisterForm from '@/shared/components/account/registerForm/RegisterForm';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GoogleAuthBtn from '../../AuthBtn/googleAuthBtn';
import AppleAuthBtn from '../../AuthBtn/appleAuthBtn';

const CardRegister = ({ onSubmit, t }) => (
  <>
    <AccountHead>
      <AccountTitle>{t('Create your account')}</AccountTitle>
    </AccountHead>
    <RegisterForm onSubmit={onSubmit} />
    {/* <AccountOr>
      <p>{t('Or sign up with')}</p>
    </AccountOr>
    <AccountSocial>
      <GoogleAuthBtn />
      <AppleAuthBtn />
    </AccountSocial> */}
  </>
);

CardRegister.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default withTranslation()(CardRegister);
