import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { STRATEGY_METHOD } from '@/shared/constants';

import { Grid } from '@/shared/components/form/drawer/Elements';

const StrategyExplain = ({ strategyMethod, strategyOption, optionsStrategyMethod }) => {
  const { t } = useTranslation();
  const [showMoreText, setShowMoreText] = useState(false);

  const getStrategyMethodName = () =>
    optionsStrategyMethod.filter((o) => o.code === strategyMethod)[0]?.name;

  return (
    <Grid.Row>
      <Grid.Cell initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {strategyMethod === STRATEGY_METHOD.all_order && (
          <Form.Text className="d-block">
            <p>{t('The bet multiplier for each order is fixed and does not change')}</p>
          </Form.Text>
        )}
        {(strategyMethod === STRATEGY_METHOD.martingale ||
          strategyMethod.indexOf(STRATEGY_METHOD.victor) > -1 ||
          strategyMethod.indexOf(STRATEGY_METHOD.autowin) > -1) && (
          <Form.Text className="d-block">
            <p className="d-flex alight-items-center">
              {t('Giải thích ngắn gọn về ')} {getStrategyMethodName()}
              <button
                type="button"
                className="text-success ms-1"
                onClick={() => setShowMoreText(!showMoreText)}
              >
                {!showMoreText ? t('Learn more') : t('Hide')}
              </button>
            </p>
            <div className={!showMoreText ? 'd-none' : ''}>
              {strategyMethod === STRATEGY_METHOD.martingale && (
                <Form.Text className="mb-4 d-block">
                  {'MARTINGALE' === strategyOption?.value && (
                    <p>{t('explain_martingale_increase_after_loss')}</p>
                  )}
                  {'MARTINGALE2' === strategyOption?.value && (
                    <p>{t('explain_martingale_increase_after_win')}</p>
                  )}
                  {'MARTINGALE3' === strategyOption?.value && (
                    <p>{t('explain_martingale_increase_always')}</p>
                  )}

                  <p>{t('Value chain (entry amount):')}</p>
                  <p>{t('- String format: x-y-z-…')}</p>
                  <p>{t('- Unlimited number of orders')}</p>
                  <p>{t('- Default value: 1-2-4-8-17-35')}</p>
                </Form.Text>
              )}
              {strategyMethod === STRATEGY_METHOD.victor2 && (
                <Form.Text className="mb-4 d-block">
                  <p>{t('There will be 2 sequences')}</p>
                  <p>{t('If you lose on sequence 1')}</p>
                  <p>{t('If you win in sequence 2')}</p>
                </Form.Text>
              )}
              {strategyMethod === STRATEGY_METHOD.victor3 && (
                <Form.Text className="mb-4 d-block">
                  <p>{t('There will be 3 sequences')}</p>
                  <p>{t('victor3_des_1')}</p>
                  <p>{t('victor3_des_2')}</p>
                  <p>{t('victor3_des_3')}</p>
                  <p>{t('victor3_des_4')}</p>
                </Form.Text>
              )}
              {strategyMethod === STRATEGY_METHOD.victor4 && (
                <Form.Text className="mb-4 d-block">
                  <p>{t('There will be 4 sequences')}</p>
                  <p>{t('victor4_des_1')}</p>
                  <p>{t('victor4_des_2')}</p>
                  <p>{t('victor4_des_3')}</p>
                  <p>{t('victor4_des_4')}</p>
                </Form.Text>
              )}
              {strategyMethod === STRATEGY_METHOD.autowin && (
                <Form.Text className="mb-4 d-block">
                  <p>{t('There will be 3 sequences')}</p>
                  <p>{t('autowin_des_1')}</p>
                  <p>{t('autowin_des_2')}</p>
                </Form.Text>
              )}
            </div>
          </Form.Text>
        )}
      </Grid.Cell>
    </Grid.Row>
  );
};

StrategyExplain.propTypes = {
  strategyMethod: PropTypes.string.isRequired,
  strategyOption: PropTypes.instanceOf(Object).isRequired,
  optionsStrategyMethod: PropTypes.instanceOf(Array).isRequired,
};

export default StrategyExplain;

