import Tooltip from '@/shared/components/Tooltip';
import { Form, Grid } from '@/shared/components/form/drawer/Elements';
import { isEmpty, isEqual } from 'lodash';
import { Form as BsForm } from 'react-bootstrap';
import { BalanceInput } from '@/shared/components/portfolio/form/components/FormElements';
import { checkPlanName, convertMaskToNum } from '@/shared/components/portfolio/form/validator';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  colorGreyScale200,
  colorGreyScale400,
  colorGreyScale500,
  colorGreyScale600,
  colorGreyScale700,
} from '@/utils/palette';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import theme from 'styled-theming';
import FieldBaseAmount from './FieldBaseAmount';
import { validatorHelper, dateFormatWithMonth } from '@/shared/helpers';
import { Input } from '../../form/PortfolioElements';

export const formatAmount = (value) => {
  const num = convertMaskToNum(value);
  return formatNumber.formatCurrency(num);
};

export default function FormCreatePlan({ form, values, data = {}, isLoading }) {
  const { t } = useTranslation();
  const validator = validatorHelper(t);
  const [backtestMoreConditions, setBacktestMoreConditions] = useState({});
  
  useEffect(() => {
    setBacktestMoreConditions({
      subProfit: !!data?.subStopLoss || !!data?.subTakeProfit || !!data?.subWinStreak || !!data?.subLoseStreak,
      sub_stop_loss: data?.subStopLoss,
      sub_take_profit: data?.subTakeProfit,
      win_streak: data?.winStreak,
      lose_streak: data?.loseStreak,
      wait_signal: data?.isWaitSignal,
      reverse_signal: data?.reverseSignal,
      no_of_entries: data?.noOfEntries,
      sub_win_streak: data?.subWinStreak,
      sub_lose_streak: data?.subLoseStreak
    })
  }, [data]);

  return (
    <>
      <RowStyle>
        <Grid.Cell>
          <Form.Label className="form-content-row-label">{t('Plan Name')}*</Form.Label>
          <div className="form-content-row-planName full-width">
            <Field
              type="text"
              validate={validator.composeValidators(validator.required, checkPlanName)}
              component={Input}
              name="planName"
              showError
              autoFocus
            />
          </div>
        </Grid.Cell>
      </RowStyle>
      <RowStyle cols={3}>
        <Grid.Cell>
          <Form.Label className="form-content-row-label">{t('Time to run Backtest')}</Form.Label>
          <div className="form-content-row-time-run-backtest">
            {isLoading ? <Skeleton height={22} width={100} /> : dateFormatWithMonth(data?.from)}
          </div>
        </Grid.Cell>
        <Grid.Cell>
          <Form.Label className="form-content-row-label">{t('Allocated Budget')}</Form.Label>
          <div className="form-content-row-amount">
            {isLoading ? <Skeleton height={22} width={100} /> : formatAmount(data?.budget)}
          </div>
        </Grid.Cell>
        <Grid.Cell>
          <Form.Label className="form-content-row-label">TP/SL</Form.Label>
          <div className="form-content-row-profit">
            <Field type="text" placeholder="$0" component={BalanceInput} name="takeProfit" />
            <span>/</span>
            <Field type="text" placeholder="$0" component={BalanceInput} name="stopLoss" />
          </div>
        </Grid.Cell>
      </RowStyle>
      <RowStyle cols={3}>
        <Grid.Cell>
          <Form.Label className="form-content-row-label">{t('Base Amount')}</Form.Label>
          <div className="form-content-row-value">
            <FieldBaseAmount form={form} values={values} />
          </div>
        </Grid.Cell>
        <Grid.Cell className="overflow-hidden">
          <Form.Label className="form-content-row-label">{t('Signal Strategy')}</Form.Label>
          <Tooltip
            text={
              data?.isDefaultSignal ? t(data?.signalStrategyName) : data?.signalStrategyName || ''
            }
          >
            <div className="form-content-row-strategy text-ellipsis">
              <span>
                {isLoading ? (
                  <Skeleton height={22} width={120} />
                ) : data?.isDefaultSignal ? (
                  t(data?.signalStrategyName)
                ) : (
                  data?.signalStrategyName || ''
                )}
              </span>
            </div>
          </Tooltip>
        </Grid.Cell>
        <Grid.Cell className="overflow-hidden">
          <Form.Label className="form-content-row-label">{t('Budget Strategy')}</Form.Label>
          <Tooltip text={data?.isDefaultBudget ? t(data.budgetName) : data.budgetName || ''}>
            <div className="form-content-row-strategy">
              {isLoading ? (
                <Skeleton height={22} width={120} />
              ) : data?.isDefaultBudget ? (
                t(data.budgetName)
              ) : (
                data.budgetName || ''
              )}
            </div>
          </Tooltip>
        </Grid.Cell>
      </RowStyle>
      {!isEmpty(backtestMoreConditions) && (
        <RowStyle cols={3}>
          <Grid.Cell className="justify-content-start">
            <div className="d-flex align-items-center justify-content-between w-100">
              <Form.Label className="form-content-row-label mb-0">
                {t('Sub Target Conditions')}
              </Form.Label>
              <BsForm.Check
                type="switch"
                id="switch-sub-target"
                checked={backtestMoreConditions.subProfit}
                readOnly
              />
            </div>
            {backtestMoreConditions.subProfit && (
              <ul className="more-conditions-group-body list-unstyled">
                {!!backtestMoreConditions.sub_take_profit && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Sub profit')}</span>
                    <span className="more-conditions-value text-success">
                      +
                      {formatNumber.formatCurrency(
                        Math.abs(backtestMoreConditions.sub_take_profit)
                      )}
                    </span>
                  </li>
                )}
                {!!backtestMoreConditions.sub_stop_loss && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Sub stoploss')}</span>
                    <span className="more-conditions-value text-error">
                      -{formatNumber.formatCurrency(Math.abs(backtestMoreConditions.sub_stop_loss))}
                    </span>
                  </li>
                )}
                {!!backtestMoreConditions.sub_win_streak && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Win streak')}</span>
                    <span className="more-conditions-value">
                      {backtestMoreConditions.sub_win_streak}
                    </span>
                  </li>
                )}
                {!!backtestMoreConditions.sub_lose_streak && (
                  <li className="more-conditions-item d-flex justify-content-between">
                    <span className="more-conditions-label">{t('Lose streak')}</span>
                    <span className="more-conditions-value">
                      {backtestMoreConditions.sub_lose_streak}
                    </span>
                  </li>
                )}
              </ul>
            )}
          </Grid.Cell>
          <Grid.Cell className="overflow-hidden justify-content-start">
            <div className="d-flex align-items-center justify-content-between w-100">
              <Form.Label className="form-content-row-label mb-0">{t('Reverse Signal')}</Form.Label>
              <BsForm.Check
                type="switch"
                id="switch-reverse-signal"
                checked={backtestMoreConditions.reverse_signal}
                readOnly
              />
            </div>
          </Grid.Cell>
          <Grid.Cell className="overflow-hidden justify-content-start">
            <div className="d-flex align-items-center justify-content-between w-100">
              <Form.Label className="form-content-row-label mb-0">{t('Wait Signal')}</Form.Label>
              <BsForm.Check
                type="switch"
                id="switch-wait-signal"
                checked={backtestMoreConditions.wait_signal}
                readOnly
              />
            </div>
            {backtestMoreConditions.wait_signal && (
              <ul className="more-conditions-group-body list-unstyled">
                <li className="more-conditions-item d-flex justify-content-between">
                  <span className="more-conditions-label">
                    {backtestMoreConditions.win_streak
                      ? t('Wait Win Streak')
                      : t('Wait Lose Streak')}
                  </span>
                  <span className="more-conditions-value">
                    {backtestMoreConditions.win_streak || backtestMoreConditions.lose_streak}
                  </span>
                </li>
                <li className="more-conditions-item d-flex justify-content-between">
                  <span className="more-conditions-label">{t('Place Order')}</span>
                  <span className="more-conditions-value">
                    {backtestMoreConditions.no_of_entries > 0
                      ? t('Enter {{order}} order in a row', {
                          order: backtestMoreConditions.no_of_entries,
                        })
                      : t('when ultil finish budget')}
                  </span>
                </li>
              </ul>
            )}
          </Grid.Cell>
        </RowStyle>
      )}
    </>
  );
}

const RowStyle = styled(Grid.Row)`
  .form-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .overflow-hidden {
      overflow: hidden;
    }
    &-row {
      &-label {
        font-weight: 500;
        color: ${colorGreyScale500};
        white-space: nowrap;
      }
      &-amount {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
      }
      &-profit {
        display: flex;
        gap: 6px;
        input {
          width: 90px;
          padding: 6px 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          text-align: center;
          border-radius: 8px;
          color: ${colorGreyScale600}!important;
        }
      }
      &-strategy {
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
      &-planName {
        input {
          border: none;
          outline: none;
          box-shadow: none;
          width: 100%;
          padding: 16px;
          border-radius: 12px;
        }
      }
    }
  }
  .dash-divider {
    border: 1px dashed
      ${theme('mode', {
        light: colorGreyScale400,
        dark: colorGreyScale600,
      })};
  }

  input[type=text] {
    background-color: ${theme('mode', {
      light: colorGreyScale200,
      dark: colorGreyScale700,
    })};
  }
`;
