import { Grid } from '@/shared/components/form/drawer/Elements';
import { STRATEGY_TYPE } from '@/shared/constants';
import { colorGreyScale500 } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const methodExplain = {
  Reverse: "Place the order based on the previous session's results.",
  'Smart AI 1': 'Randomly place an order.',
  'Smart AI 2': 'Place the same order as Reverse method but skip one session.',
  'Sideway 1-1':
    'Place the opposite order with the result of the previous session.',
  'High Sentiment': 'Place the order based on high-index sentiment indicator.',
  'Low Sentiment': 'Place the order based on low-index sentiment indicator.',
  'Always Up': 'Place Long orders for all sessions.',
  'Always Down': 'Place Short orders for all sessions.',
  'Smart Surf 1': 'Place the order based on the signal candle.',
  Pingpong: 'Place the opposite order with the signal candle.',
};

export function MethodExplain({ type, method }) {
  const { t } = useTranslation();
  let explain = [];
  if (type == STRATEGY_TYPE.single && methodExplain[method?.label]) {
    explain = [methodExplain[method?.label]];
  } else if (type == STRATEGY_TYPE.string) {
    explain = [
      's = SELL, b = BUY',
      'The resulting string and the wanted signal are separated by -',
      'Multiple strings can be combined, and longer strings take precedence.',
    ];
  }

  return (
    explain.length > 0 && (
      <Grid.Row>
        <Grid.Cell>
          {explain.map((item, key) => (
            <Box
              color={colorGreyScale500}
              fontSize="14px"
              fontWeight={500}
              key={key}
              dangerouslySetInnerHTML={{
                __html:`* ${t(item)}`,
              }}
            />
          ))}
        </Grid.Cell>
      </Grid.Row>
    )
  );
}
