import sliceDecimalFloat from './sliceDecimalFloat';

const formatCurrency = (
  amount,
  minimumFractionDigits = 2, // 0 -> $0, 2 -> $0.00
  maximumFractionDigits = 2,
  currency = 'USD',
  locale = 'en-US'
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(sliceDecimalFloat(amount, maximumFractionDigits));
};

export default formatCurrency;
