import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CANDLE_BASE } from '@/config/const';
import { CandlePatternContext } from '../../context';

import { Bubble } from './Bubble';

const BubblePanel = ({ isReadOnly, currentCandle }) => {
  const { t } = useTranslation();
  let isDefault, current, order, setCandle, setOrder;
  if (!isReadOnly) {
    ({ isDefault, current, order, setCandle, setOrder } = useContext(CandlePatternContext));
  }

  const onSelectChange = ({ target }) => {
    setOrder(target.value);
  };
  return (
    <div className="BubblePanel">
      <div className="BubblePanel__head">
        {!isReadOnly ? t('(Board 5) I want') : t('(Board 5) Place')}
        {!isReadOnly && (
          <select
            data-value={order}
            className="form-select BubblePanel__head-select"
            value={order}
            onChange={onSelectChange}
            disabled={isDefault}
          >
            {/* <option value="o">Bỏ qua</option> */}
            <option value="b">Long</option>
            <option value="s">Short</option>
          </select>
        )}
        {isReadOnly && (
          <span className={currentCandle.c === 's' ? 'text-error' : 'text-success'}>
            {currentCandle.c === 's' ? 'Short' : 'Long'}
          </span>
        )}
        {t('for the number')}
      </div>
      <div className="BubblePanel__list">
        {CANDLE_BASE.map((item) => (
          <Bubble
            key={item}
            current={!isReadOnly ? current : currentCandle.i}
            onSelect={setCandle ? setCandle : () => {}}
            value={item - 1}
            isDefault={isDefault}
          />
        ))}
      </div>
    </div>
  );
};

BubblePanel.propTypes = {
  isReadOnly: PropTypes.bool,
  currentCandle: PropTypes.instanceOf(Object),
};

BubblePanel.defaultProps = {
  isReadOnly: false,
  currentCandle: {},
};

export default memo(BubblePanel);
