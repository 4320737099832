import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  updateIsLoadingNoti,
  updateNoties,
  addLastNoti,
  updateStatusNoties,
  updateNotiPageconfig,
  updateIdNotiesRendered,
  updateNotiCurrentTab,
  updateNotiIsActive
} from '../actions/notiActions';
import { NOTIFICATION_TYPE } from '@/shared/constants';

const defaultState = {
  isLoading: false,
  noties: [],
  idNotiesRendered: [],
  currentTab: NOTIFICATION_TYPE.system,
  isActive: false,
  pageConfigs: [
    {
      channel: NOTIFICATION_TYPE.system,
      unread: 0,
      isEnded: false,
    },
    {
      channel: NOTIFICATION_TYPE.plan,
      unread: 0,
      isEnded: false,
    }
  ]
};

export default handleActions(
  {
    [updateIsLoadingNoti](state, { payload }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    [updateNoties](state, { payload }) {
      // console.log('updateNoties', payload);
      return {
        ...state,
        noties: _.uniqBy(state.noties.concat(payload), 'id'),
      };
    },
    [addLastNoti](state, { payload }) {
      return {
        ...state,
        noties: _.uniqBy([payload, ...state.noties], 'id'),
      };
    },
    
    [updateStatusNoties](state, { payload }) {
      return {
        ...state,
        noties: payload,
      };
    },
    [updateNotiPageconfig](state, { payload }) {
      const isExist = state.pageConfigs.filter(item => item.channel === payload.channel);
      if (isExist.length) {
        return {
          ...state,
          pageConfigs: state.pageConfigs.map(item => {
            if (item.channel === payload.channel) {
                return payload;
            }
            return item;
          }),
        };
      } else {
        return {
          ...state,
          pageConfigs: [...state.pageConfigs, payload]
        };
      }
    },
    [updateIdNotiesRendered](state, { payload }) {
      return {
        ...state,
        idNotiesRendered: payload.length ? _.uniq(payload.concat(state.idNotiesRendered)) : [],
      };
    },
    [updateNotiCurrentTab](state, { payload }) {
      return {
        ...state,
        currentTab: payload,
      };
    },
    [updateNotiIsActive](state, { payload }) {
      return {
        ...state,
        isActive: payload,
      };
    },
  },
  defaultState
);
