import { STRATEGY_TYPE } from "@/shared/constants";

const getSignalExpert = (botSignalStrategyType, botMethodUsing, expertValue, lastInputTagExpertValue, botValue, lastInputTagValue) => {
  let sources;
  let experts;
  if (botSignalStrategyType === STRATEGY_TYPE.single) {
    sources = [botMethodUsing.value];
  } else if (botSignalStrategyType === STRATEGY_TYPE.mix) {
    sources = botMethodUsing.map((source) => source.value);
    experts = expertValue.map((source) => source.text);
    if (lastInputTagExpertValue.length) {
      experts.push(lastInputTagExpertValue);
    }
  } else if (botSignalStrategyType === STRATEGY_TYPE.string) {
    sources = botValue.map((tag) => tag.text);
    if (lastInputTagValue.length) {
      sources.push(lastInputTagValue);
    }
  }

  return {
    sources,
    experts
  }
};

export default getSignalExpert;
