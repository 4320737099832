import { axios, get } from './base';
var CancelToken = axios.CancelToken;
let chart_request_cancel;
let total_request_cancel;
let total_plan_request_cancel;
export default {
  getStatisticsChart: (accountType, fromDate, toDate, planId) => {
    chart_request_cancel && chart_request_cancel();
    let url = `/plans/statistics/chart/${accountType}/daily-statistic?fromDate=${fromDate}&toDate=${toDate}${planId ? '&planId=' + planId : ''}`;
    return get(url, {
      cancelToken: new CancelToken(function executor(c) {
        chart_request_cancel = c;
      }),
    });
  },
  getTotal: (accountType, start, end, includeManual, planId) => {
    total_request_cancel && total_request_cancel();
    return get(
      `/plans/statistics/total/${accountType}?fromDate=${start}&toDate=${end}&includeManual=${includeManual}${planId ? '&planId=' + planId : ''}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          total_request_cancel = c;
        }),
      }
    );
  },
  getTopPlans: () => get('/plans/leaderboard/plans'),
  getLeaderBoard: () => get('/plans/leaderboard/users'),
  getTotalPlan: (accountType) => {
    // total_plan_request_cancel && total_plan_request_cancel();
    return get(`/plans/investment-plan/${accountType}/total-plan`, {
      // cancelToken: new CancelToken(function executor(c) {
      //   total_plan_request_cancel = c;
      // }),
    });
  },
};
