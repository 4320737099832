/* eslint-disable react/prop-types */
import Tooltip from '@/shared/components/Tooltip';
import { Form } from '@/shared/components/form/drawer/Elements';
import { Devices, colorGreyScale500 } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { InfoCircle } from 'iconsax-react';
import React from 'react';
import styled from 'styled-components';
import { BalanceInput, DefaultTarget, FieldStyle } from './FormElements';

import Error from '@/shared/components/form/Error';

const defaultTargets = [10, 50];

const TargetInput = ({
  label,
  name,
  placeholder,
  type,
  onSelect,
  validate,
  tooltip,
  error,
  showError
}) => (
  <TargetInputWrap>
    <Form.Label>
      {label}
      {tooltip && (
        <Tooltip text={tooltip} />
      )}
    </Form.Label>
    <Box display="flex" alignItems="center" gridGap="8px">
      <Box maxWidth="90px">
        <FieldStyle
          name={name}
          component={BalanceInput}
          placeholder={placeholder}
          valuetype={type}
          validate={validate}
        />
      </Box>
      <DefaultTarget onSelect={onSelect} options={defaultTargets} affix="%" />
    </Box>
    {showError && error && <Error error={error} />}
  </TargetInputWrap>
);

const TargetInputWrap = styled(Box)`
  flex: 1;
  @media ${Devices.pc} {
    /* margin-top: 41px; */
  }
`;

export default TargetInput;
