/* eslint-disable no-param-reassign */
import { IS_PROD, WS_URL } from '@/config/const';
import { CANDLE_COLORS } from '@/shared/constants';
import {
  getBacktestDataChart,
  updateBacktestDataChart,
  updateBacktestProgress,
  updateBetSession,
  updateCandles,
  updateCountdownTimer,
  updateCurrentSession,
  updateOrderResult,
  updateWaitSignal,
  updateManualTradeOrderSessionReady
} from '@/redux/actions/planActions';
import { setKickOut } from '@/redux/refactor/appConfig/actions';
import { clearAuthority, refreshToken, setAnotherDevice } from '@/redux/refactor/auth/actions';
import { onReceiveNotify, setLastMessage } from '@/redux/refactor/notification/actions';
import { changeLastNoti } from '@/redux/actions/notiActions';
import { decodeJwt, getAccessToken, getRefreshToken } from '@/utils/helpers';
import { HttpTransportType, LogLevel } from '@microsoft/signalr';
import _ from 'lodash';
import { pushTelegramMessage, setTelegramMessage } from '@/redux/refactor/common/actions';
import { parseArrayMatrix } from '@/shared/helpers';

export const logLevel = IS_PROD ? LogLevel.Error : LogLevel.Error;
export const SOCKET_URL = WS_URL.concat('/plan-order-hub');

export const serverTimeoutInMilliseconds = 120000;
export const SOCKET_CONFIG = {
  skipNegotiation: true,
  transport: HttpTransportType.WebSockets,
};

export const onClose = (connection) => {
  if (connection) connection.stop();
  connection = null;
};

export const accessTokenFactory = async ({ dispatch, connection }) => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    const tokenRefresh = getRefreshToken();
    if (tokenRefresh) {
      const resp = await dispatch(refreshToken(tokenRefresh));
      if (resp && resp?.data?.d?.access_token) {
        return resp?.data?.d?.access_token;
      }
    } else {
      onClose(connection);
      dispatch(clearAuthority());
    }
  }
  return accessToken;
};

export const getLastMessages = ({ dispatch, connection }) => {
  connection.invoke('GetLastMessages').then(
    (resp = []) => {
      dispatch(setLastMessage(resp));
    },
    (error) => console.log({ error })
  );
};

// region EVENTS

const onReceiveMessage = ({ dispatch, message }) => {
  // console.log('message', message);
  dispatch(onReceiveNotify(message));
};

const onTimerMessage = ({ dispatch, message }) => {
  const val = JSON.parse(message);
  dispatch(updateCountdownTimer(val));
  dispatch(updateBetSession(val));
  dispatch(updateCurrentSession(val));
};

let lastOrderResult = null;
const onManualOrderResult = ({ dispatch, message }) => {
  const val = JSON.parse(message);
  if (!lastOrderResult || lastOrderResult.orderId !== val.orderId) {
    lastOrderResult = val;
    dispatch(updateOrderResult(val));
  }
};

const onOrderReadyMessage = ({ dispatch, message }) => {  
  // console.log('onOrderReadyMessage', message)
  if (message?.session) {
    dispatch(updateManualTradeOrderSessionReady(message?.session));
  }
};


const debounceBacktestProgress = _.debounce((dispatch, progress) => {
  dispatch(updateBacktestProgress(progress));
}, 100);

const onBacktestResult = ({ dispatch, message }) => {
  const val = JSON.parse(message);
  dispatch(updateBacktestDataChart([val]));
  debounceBacktestProgress(dispatch, val.progress);
  if (val.progress === 1) {
    dispatch(getBacktestDataChart(val.btId));
  }
};

const onUserLoginMessage = ({ dispatch, message }) => {
  const kickOutDeviceInfo = {
    name: '',
    time: message.loginDatetime,
    id: '',
  };
  if (message.deviceId) {
    const [name, id] = message.deviceId.split('/');
    if (id) {
      kickOutDeviceInfo.name = name;
      kickOutDeviceInfo.id = id;
    }
  }
  const accessToken = getAccessToken();
  if (!accessToken) return;
  if (
    message.source === process.env.REACT_APP_CLIENT_ID &&
    decodeJwt(accessToken).d_id.split('/')[1] !== kickOutDeviceInfo.id
  ) {
    dispatch(setAnotherDevice(kickOutDeviceInfo));
    dispatch(clearAuthority());
  }
};

const MaintenanceStarted = ({ dispatch, message }) => {
  console.log('MaintenanceStarted: ', message);
  dispatch(setKickOut(message.minutes));
};

export const onCandlePattern = ({ dispatch, message }) => {
  const blocks = [];
  const rows = message?.data?.split('\r\n') || [];
  // console.log('onCandlePattern', message?.data);
  
  if(rows.length) {
    rows.forEach((row) => {
      if (row.length) {
        const rowAr = row.split(' ');
        rowAr.forEach(
          (item, index) => (blocks[index] = blocks[index] ? blocks[index].concat(item.replaceAll(CANDLE_COLORS.empty, '')) : item.replaceAll(CANDLE_COLORS.empty, ''))
        );
      }
    });
    
    dispatch(updateCandles(parseArrayMatrix(blocks)));
  }
};

const onUserNotification = ({ dispatch, message }) => {
  dispatch(changeLastNoti(JSON.parse(message)));
};

const onReceiveTelegramMessage = ({ dispatch, message }) => {
  try {
    console.log('onReceiveTelegramMessage: ', message);
    const obj = JSON.parse(message.replaceAll('\n', '<br />'));
    dispatch(pushTelegramMessage(obj));
  } catch (e) {
    console.log('onReceiveTelegramMessage error: ', e);
  }
};

const onReceiveWaitSignalMessage = ({ dispatch, message }) => {
  try {
    dispatch(updateWaitSignal(JSON.parse(message)));
  } catch (e) {
    console.log('onReceiveWaitSignalMessage error: ', e);
  }
};

export const events = [
  {
    name: 'ReceiveMessage',
    handler: onReceiveMessage,
  },
  {
    name: 'UserLoginMessage',
    handler: onUserLoginMessage,
  },
  {
    name: 'MaintenanceStarted',
    handler: MaintenanceStarted,
  },
  {
    name: 'TimerMessage',
    handler: onTimerMessage,
  },
  {
    name: 'ManualOrderResult',
    handler: onManualOrderResult,
  },
  {
    name: 'BacktestProfit',
    handler: onBacktestResult,
  },
  {
    name: 'CandlePattern',
    handler: onCandlePattern,
  },
  {
    name: 'UserNotification',
    handler: onUserNotification,
  },
  {
    name: 'ReceiveTelegramMessage',
    handler: onReceiveTelegramMessage,
  },
  {
    name: 'ReceiveCommunitySignalMessage',
    handler: onReceiveTelegramMessage,
  },
  {
    name: 'WaitSignalsMessage',
    handler: onReceiveWaitSignalMessage,
  },
  {
    name: 'OrderReadyMessage',
    handler: onOrderReadyMessage,
  }  
];

export const eventHandler = ({ dispatch, connection }) => {
  events.forEach((item) => {
    connection.on(item.name, (message) =>
      item.handler({
        dispatch,
        connection,
        message,
      })
    );
  });
};
