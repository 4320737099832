import { colorGreyScale600, colorGreyScale900 } from '@/utils/palette';
import { InfoCircle } from 'iconsax-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styled-theming';

const Tooltip = ({ placement, text, iconSize, iconColor, children, ...other }) => {
  const getTooltip = () => {
    return <StyledTooltip placement={placement}>{text}</StyledTooltip>;
  };
  if (!text) return children;

  return (
    <OverlayTrigger overlay={getTooltip()} {...other} placement={placement}>
      {children ? children : <InfoCircle size={iconSize} color={iconColor} className="ms-2" />}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  children: PropTypes.node,
};
Tooltip.defaultProps = {
  placement: 'top',
  iconSize: 18,
  iconColor: '#A0AEC0',
};

export default Tooltip;

// region STYLES

const StyledTooltip = styled(BootstrapTooltip)`
  z-index: 10001;
  pointer-events: none;
  /* position: fixed !important; */
  .tooltip-inner {
    background-color: ${theme('mode', {
      light: colorGreyScale900,
      dark: colorGreyScale600,
    })};
    font-size: 12px;
    line-height: 14px;
    padding: 5px 15px;
  }

  &.bs-tooltip-end .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='end'] .tooltip-arrow::before {
    border-right-color: ${theme('mode', {
      light: colorGreyScale900,
      dark: colorGreyScale600,
    })};
  }

  &.bs-tooltip-top .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='top'] .tooltip-arrow::before {
    border-top-color: ${theme('mode', {
      light: colorGreyScale900,
      dark: colorGreyScale600,
    })};
  }

  &.bs-tooltip-bottom .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='bottom'] .tooltip-arrow::before {
    border-bottom-color: ${theme('mode', {
      light: colorGreyScale900,
      dark: colorGreyScale600,
    })};
  }

  &.bs-tooltip-start .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='start'] .tooltip-arrow::before {
    border-left-color: ${theme('mode', {
      light: colorGreyScale900,
      dark: colorGreyScale600,
    })};
  }
`;

// endregion
