import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import _, { isEqual } from 'lodash';
import insightApi from "@/utils/api/insightApi";
import { useParams } from 'react-router-dom';
import { PlanCrudProvider } from '@/context/PlanCrudProvider';
import TopbarSecondary from "@/containers/Layout/topbar/TopbarSecondary";
import InsightPost from "../InsightPost";

const InsightDetail = () => {
  const currentLangCode = useSelector(
    (state) => state.appConfig.languageCode,
    isEqual
  );

  const [post, setPost] = useState({});
  const [posts, setPosts] = useState([]);
  const params = useParams();
  
  useEffect(() => {
    insightApi.getPosts().then(res => {
      if (res.status === 200) {
        setPosts(res.data);
      }
    })
  }, []);

  useEffect(() => {
    if (!params.postId) {
      return;
    }

    insightApi.getPost(params.postId).then(res => {
      if (res.status === 200) {
        setPost(res.data);
      }
    })
  }, [params.postId]);

  useEffect(() => {
    if(!_.isEmpty(post) && post?.acf?.lang !== currentLangCode) {
      const postForLang = _.find(posts, p => p?.acf?.group_post_id === post?.acf?.group_post_id && p?.acf?.lang === currentLangCode);
      if (postForLang) {
        setPost(postForLang);
      }
    }
  }, [currentLangCode, post]);

  return (
    <PlanCrudProvider>
      <TopbarSecondary />
      {!_.isEmpty(post) && <InsightPost post={post} isDetail />}
    </PlanCrudProvider>
  )
};

export default InsightDetail;