import { FILTER_TIME, FILTER_TIME_LABEL } from '@/config/const';
import {
  Devices,
  borderDefaultStyle,
  colorGreyScale500,
  colorText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { Calendar } from 'iconsax-react';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import React, { Fragment, memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styled-theming';
import { ModalCalendar } from './ModalCalendar';

function CustomDateTimePicker({
  type,
  timeOpts = {},
  onChange,
  calendarOnly,
  showIcon,
  isShowTimeRange = true,
  defaultDate,
  validDays
}) {
  const { t } = useTranslation();
  const { fromDate, toDate } = timeOpts;
  const [showPicker, setShowPicker] = useState(false);
  const handleClose = () => {
    setShowPicker(false);
  };
  const handleOptionChange = (option) => {
    if (option === FILTER_TIME.CUSTOM_DATE) {
      setShowPicker(true);
      return;
    }
    // Prevent set multiple time
    if (option !== type) {
      onChange({
        time: option,
        timeOpts: {
          fromDate: null,
          toDate: null,
        },
      });
    }
  };
  const handleDateChange = ({ date, fromTime, toTime }) => {
    const from = new Date(date);
    const to = new Date(date);
    const [fromHrs, fromMinutes] = fromTime.split(':');
    const [toHrs, toMinutes] = toTime.split(':');

    from.setHours(Number(fromHrs));
    from.setMinutes(Number(fromMinutes));
    from.setSeconds(0);
    from.setMilliseconds(0);

    to.setHours(Number(toHrs));
    to.setMinutes(Number(toMinutes));
    to.setSeconds(59);
    to.setMilliseconds(59);

    onChange({
      time: FILTER_TIME.CUSTOM_DATE,
      timeOpts: {
        fromDate: from,
        toDate: to,
      },
    });
    setShowPicker(false);
  };

  return (
    <Fragment>
      <DropdownWrap className="CustomDateTimePicker">
        <Dropdown onSelect={handleOptionChange}>
          {type !== FILTER_TIME.CUSTOM_DATE ? (
            <Dropdown.Toggle
              className={classNames(
                {
                  'is-custom': type === FILTER_TIME.CUSTOM_DATE,
                },
                'CustomDateTimePicker__toggle'
              )}
              variant="primary"
              id="dropdown-basic"
            >
              {t(FILTER_TIME_LABEL[type])}
            </Dropdown.Toggle>
          ) : (
            <Box
              className={classNames('CustomDateTimePicker__label', {
                'has-icon': showIcon,
                'no-close-icon': calendarOnly,
              })}
              display="flex"
              alignItems="center"
              paddingRight={1}
              flex={1}
            >
              {showIcon && (
                <Calendar
                  className="CustomDateTimePicker__label-icon"
                  cursor="pointer"
                  onClick={() => setShowPicker(true)}
                />
              )}
              <CustomTimeLabel
                className="CustomDateTimePicker__label-text"
                onClick={() => setShowPicker(true)}
              >
                {!isShowTimeRange && moment(fromDate).format('L')}
                {isShowTimeRange && `${moment(fromDate).format('L')} ${moment(fromDate).format(
                  'hh:mm A'
                )} - ${moment(toDate).format('hh:mm A')}`}
              </CustomTimeLabel>
              {!calendarOnly && (
                <CloseIcon
                  className="CustomDateTimePicker__label-close"
                  onClick={() => handleOptionChange(FILTER_TIME.RECENTLY)}
                  color={colorGreyScale500}
                  cursor="pointer"
                />
              )}
            </Box>
          )}
          <Dropdown.Menu>
            {Object.entries(FILTER_TIME).map(([, key]) => (
              <Dropdown.Item key={key} eventKey={key}>
                {t(FILTER_TIME_LABEL[key])}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </DropdownWrap>
      <ModalCalendar
        values={timeOpts}
        open={showPicker}
        onClose={handleClose}
        onConfirm={handleDateChange}
        isShowTimeRange={isShowTimeRange}
        defaultDate={defaultDate}
        validDays={validDays}
      />
    </Fragment>
  );
}

const DropdownWrap = styled.div`
  background-color: transparent;
  color: ${colorText};
  border: ${borderDefaultStyle};
  height: auto;
  border-radius: 8px;
  display: flex;
  flex: 1;
  @media ${Devices.pc} {
    display: block;
    flex: unset;
  }
  .dropdown {
    display: flex;
    flex: 1;
    > button {
      flex: 1;
    }
    @media ${Devices.pc} {
      display: block;
    }
  }
  .dropdown-toggle {
    all: unset;
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    padding: 8px 32px 8px 12px;
    cursor: pointer;
    background-image: ${theme('mode', {
      light:
        "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e')",
      dark: "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e')",
    })};
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    &:focus {
      background-color: transparent;
      color: unset;
    }
    &::after {
      all: unset;
    }
    &.is-custom {
      border-right: ${borderDefaultStyle}!important;
    }
  }
  .dropdown-menu {
    margin-top: 8px;
  }
  .CustomDateTimePicker__label {
    padding: 8px;
    gap: 8px;
  }
`;

const CustomTimeLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  flex: 100%;
`;

export default memo(CustomDateTimePicker);
