import { colorBackground, colorGreyScale500 } from '@/utils/palette';
import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

const PageError = ({ message, description }) => {
  const { t } = useTranslation();
  const tryAgain = () => window.location.reload();
  return (
    <Wrapper>
      <div className="content">
        <div className="img">
          <img src="/img/account/active-error.png" alt="error" />
        </div>
        <div>
          <h4>{message}</h4>
          <p>{description}</p>
          <div>
            <Button onClick={tryAgain} variant="success">
              {t('Try again')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PageError;

// region STYLES

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: ${colorBackground};
  z-index: 1000;
  left: 0;
  top: 0;
  .content {
    text-align: center;
    h4 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 2.5rem;
      color: ${colorGreyScale500};
    }
    .img {
      margin-bottom: 2rem;
    }
    button {
      width: 100%;
      max-width: 15rem;
    }
  }
`;
//
