import React from 'react';
import { withTranslation } from 'react-i18next';
import SettingItem from './SettingItem';

// Begin ExpertMode
export default withTranslation()(({ show, t }) =>
  show ? (
    <SettingItem
      name="private_mode"
      title={t('FormCreatePlan_advanced_privateMode_label', 'Private Mode')}
      desc={t(
        'FormCreatePlan_advanced_privateMode_desc',
        'Private Mode description'
      )}
    />
  ) : null
);
// End ExpertMode
