import { handleActions } from 'redux-actions';
import {
  enable2FaFailed,
  enable2FaRequest,
  enable2FaSuccess,
  prepare2FaFailed,
  prepare2FaRequest,
  prepare2FaSuccess,
  getCode2FaRequest,
  getCode2FaSuccess,
  getCode2FaFailed,
  setEnabled2Fa,
  disable2FaRequest,
  disable2FaSuccess,
  disable2FaFailed,
} from './actions';

const defaultState = {
  isEnabled: false,
  isFetching: false,
  requestCode: null,
  isCodeFetching: false,
  gAuthenticator: {
    key: '',
    uri: '',
  },
};

export default handleActions({
  [prepare2FaRequest]: state => ({
    ...state,
    isFetching: true,
    requestCode: null,
    gAuthenticator: {
      key: '',
      uri: '',
    },
  }),
  [prepare2FaSuccess]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    gAuthenticator: payload,
  }),
  [prepare2FaFailed]: state => ({
    ...state,
    isFetching: false,
  }),

  [getCode2FaRequest]: state => ({
    ...state,
    isCodeFetching: true,
  }),
  [getCode2FaSuccess]: (state, { payload }) => ({
    ...state,
    isCodeFetching: false,
    requestCode: payload,
  }),
  [getCode2FaFailed]: state => ({
    ...state,
    isCodeFetching: false,
  }),

  [enable2FaRequest]: state => ({
    ...state,
    isFetching: true,
  }),
  [enable2FaSuccess]: state => ({
    ...state,
    isFetching: false,
    isEnabled: true,
  }),
  [enable2FaFailed]: state => ({
    ...state,
    isFetching: false,
  }),

  [disable2FaRequest]: state => ({
    ...state,
    isFetching: true,
  }),
  [disable2FaSuccess]: state => ({
    ...state,
    isFetching: false,
    isEnabled: false,
  }),
  [disable2FaFailed]: state => ({
    ...state,
    isFetching: false,
  }),

  [setEnabled2Fa]: (state, { payload }) => ({
    ...state,
    isEnabled: payload,
  }),
}, defaultState);
