/* eslint-disable react/prop-types */
import { getBots, getStrategies, getTelegramChannels } from '@/redux/refactor/common/actions';
import { updateUserClickBacktestSignalType } from '@/redux/actions/userActivityTrackerActions';

import {
  selectBots,
  selectStrategies,
  selectTelegramChannels,
} from '@/redux/refactor/common/selector';
import { Button } from '@/shared/components/Button';
import { Card } from '@/shared/components/Card';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { Devices, colorText } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormState } from 'react-final-form';
import { SelectBot, SelectBudgetStrategy, SelectChannel, SignalType, BacktestOptions } from './FormElements';

export default function FormBackTest({
  handleSubmit,
  values,
  form,
  invalid,
  isLoading,
  isProcessing,
  totalToDay,
  limitPerDay,
  closeModal,
  onShowMoreConditions,
  handleFormChange,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channels = useSelector(selectTelegramChannels);  
  const signals = useSelector(selectBots);
  const budgetStrategies = useSelector(selectStrategies);
  const [teleChannels, setTeleChannels] = useState([]);
  const formState = useFormState();

  useEffect(() => {
    if (budgetStrategies.length > 0) {
      form.mutators.setBudgetId([
        {
          value: budgetStrategies[0]?.id,
          label: budgetStrategies[0]?.budget_name,
        },
      ]);
    }
  }, [budgetStrategies]);

  useEffect(() => {
    if (channels.length > 0) {
      setTeleChannels(channels.filter((item) => item.type === SIGNAL_STRATEGY_TYPE.telegram))
    }
  }, [channels]);

  useEffect(() => {
    if (teleChannels.length > 0) {
      form.mutators.setTelegramChannel([
        {
          value: teleChannels[0]?.id,
          label: teleChannels[0]?.name,
        },
      ]);
    }
  }, [teleChannels]);

  useEffect(() => {
    if (signals.length > 0) {
      form.mutators.setSignalId([
        {
          value: signals[0]?.id,
          label: signals[0]?.name,
        },
      ]);
    }
  }, [signals]);

  useEffect(() => {
    handleFormChange(formState?.values)
    dispatch(updateUserClickBacktestSignalType(formState?.values?.signal_type))
  }, [formState?.values]);

  useEffect(() => {
    dispatch(getBots());
    dispatch(getTelegramChannels(false));
    dispatch(getStrategies());
  }, []);

  const buttonDisable = invalid || isLoading || isProcessing || totalToDay >= limitPerDay;
  return (
    <form onSubmit={handleSubmit}>
      <CardStyle className="form-create-backtest m-0">
        <Card.Header>
          <Box flex="1" overflow="hidden" />
          <button type="button" className="close" label="close" onClick={closeModal} />
        </Card.Header>
        <Card.Body>
          <SignalType />
          <SelectBot
            show={values.signal_type === SIGNAL_STRATEGY_TYPE.botai}
            data={signals}
            value={values?.bot_ai}
          />
          <SelectChannel
            show={values.signal_type === SIGNAL_STRATEGY_TYPE.telegram}
            data={teleChannels}
            values={values?.telegram_chanels}
          />
          <SelectBudgetStrategy data={budgetStrategies} value={values?.budget_strategy} />
          <BacktestOptions onShowMoreConditions={onShowMoreConditions} />
        </Card.Body>
        <Card.Footer>
          <Button
            disabled={buttonDisable}
            type="submit"
            isLoading={isLoading || isProcessing}
            block="true"
            variant="dark"
            className="btn-run-backtest"
          >
            {t('Test run the plan')} ({totalToDay || 0}/{limitPerDay || 0})
          </Button>
        </Card.Footer>
      </CardStyle>
    </form>
  );
}

const CardStyle = styled(Card)`
  .card-header {
    display: flex;
    justify-content: flex-end;
    padding: 16px !important;
    @media ${Devices.pc} {
      display: none;
    }
    h5 {
      font-weight: 800;
      font-size: 20px;
      line-height: 140%;
      color: ${colorText};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media ${Devices.pc} {
        padding-left: 24px;
      }
    }
  }
  .card-body {
    padding: 24px 16px !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media ${Devices.pc} {
      padding: 0 !important;
      max-height: unset !important;
      overflow-y: unset !important;
    }
  }
  .card-footer {
    padding: 16px;
    @media ${Devices.pc} {
      padding: 24px 0 0 0 !important;
      border: none;
    }
  }
`;
