const numberWithCommas = (number) => {
  if (!number) return '0';

  const [integerPart, decimalPart] = number.toString().split('.');

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (!decimalPart) return formattedInteger;

  return `${formattedInteger}.${decimalPart}`;
};

export default numberWithCommas;
