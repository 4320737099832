import React from 'react';
import { Image, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import languages, { languageMap } from '@/translations/languages';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguageCode, updateUserConfig } from '@/redux/refactor/appConfig/actions';
import { isEqual } from 'lodash';
import {
 colorText, dropdownBackground, dropdownItemBackground, dropdownShadow, 
} from '@/utils/palette';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const Title = ({ selected }) => (
  <TitleStyle>
    {selected.name}
    <FlagStyle width="18px" src={selected.flag} />
  </TitleStyle>
);
Title.propTypes = {
  selected: PropTypes.shape({
    name: PropTypes.string,
    flag: PropTypes.string,
  }),
};

Title.defaultProps = {
  selected: {
    name: 'Việt Nam',
    flag: '/img/flags/vi.svg',
  },
};

const SelectLanguage = () => {
  const { i18n } = useTranslation();

  const { profile, currentLangCode } = useSelector(state => ({
    profile: state.exchange.profile,
    currentLangCode: state.appConfig.languageCode,
  }), isEqual);

  const dispatch = useDispatch();
  const handleSelect = (selectedKey) => {
    if (selectedKey !== currentLangCode) {
      dispatch(updateUserConfig('language', selectedKey));
      dispatch(setLanguageCode(selectedKey));
      i18n.changeLanguage(selectedKey);

      const languageActions = {
        en: 'languageEn',
        vi: 'languageVi',
        th: 'languageTh',
      };
      
      let languageAction = languageActions[selectedKey] || '';      

      ga4Stats(gtag.language.action[languageAction], gtag.language.category, gtag.language.label, profile?.uid);      
    }
  };
  return (
    <MenuWrap onSelect={handleSelect} align="end" title={<Title selected={languageMap[currentLangCode]} />}>
      {languages.map(lang => (
        <MenuItem eventKey={lang.code} key={lang.code}>
          <Image style={{ borderRadius: 4 }} width="18px" src={lang.flag} /> {lang.name}
        </MenuItem>
      ))}
    </MenuWrap>
  );
};

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.2px;
  color: ${colorText};
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
  }
`;

const FlagStyle = styled(Image)`
  margin-left: 6px;
  border-radius: 4px;
`;

const MenuWrap = styled(NavDropdown)`
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 213px;
    padding: 11.6712px;
    background: ${dropdownBackground};
    box-shadow: ${dropdownShadow};
    border-radius: 15.5616px;
    margin-top: 16px;
    margin-bottom: 16px;
    border: none;
  }
`;

const MenuItem = styled(NavDropdown.Item)`
  padding: 11.6712px;
  border-radius: 7.78082px;
  gap: 13px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${dropdownItemBackground};
    font-weight: 700;
  }
  svg {
    width: 19.4px;
  }
`;
export default SelectLanguage;
