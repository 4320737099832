import {
  colorAccent,
  colorGreyScale500,
  colorGreyScale900,
  colorText,
  Devices,
  VARIANTS,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TRANSFER_TYPE } from '@/config/const';
import { Button } from '@/shared/components/Button';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import theme from 'styled-theming';

const SelectTransferType = ({ type, onSelect }) => {
  const { t } = useTranslation();
  const withdrawConfig = useSelector(
    state => state.wallet.withdrawConfig,
    _.isEqual,
  );
  return (
    <Box mb="32px">
      <FormLabel>{t('Transfer network')}</FormLabel>
      <Buttons>
        <Button
          variant={
            type === TRANSFER_TYPE.INTERNAL
              ? VARIANTS.SUCCESS
              : VARIANTS.SECONDARY
          }
          onClick={() => onSelect(TRANSFER_TYPE.INTERNAL)}
        >
          {t('Internal')}
          <small>{t('Fee: {{amount}} USDT', { amount: 0 })}</small>
        </Button>
        <Button
          variant={
            type === TRANSFER_TYPE.EXTERNAL
              ? VARIANTS.SUCCESS
              : VARIANTS.SECONDARY
          }
          onClick={() => onSelect(TRANSFER_TYPE.EXTERNAL)}
        >
          {t('Bep-20 (BSC)')}
          <small>{t('Fee: {{amount}} USDT', { amount: withdrawConfig?.u_fee_bsc })}</small>
        </Button>
      </Buttons>
    </Box>
  );
};

SelectTransferType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string,
};

SelectTransferType.defaultProps = {
  type: false,
};

const FormLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: ${colorText};
`;

const Buttons = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  button {
    flex: 1;
    font-weight: 700;
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${theme('mode', {
      light: 'rgba(207, 219, 213, 0.7)',
      dark: '#323B49',
    })};
    &:hover {
      border-color: ${theme('mode', {
        light: 'rgba(207, 219, 213, 0.7)',
        dark: '#323B49',
      })}!important;
    }
    &:focus {
      background-color: #0BA259!important;
    }
    &.btn-success {
      background: ${colorAccent};
    }
    &.btn-secondary {
      color: ${colorGreyScale500};
    }
    small {
      font-weight: 500;
      font-size: 14px;
      line-height: 22.4px;
    }
    div {
      justify-content: space-between;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

export default SelectTransferType;
