import { updateBacktestPrevHistory } from '@/redux/actions/planActions';
import formatNumber from '@/shared/helpers/formatNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const CardItemHead = ({ id, progress, profit, changeBacktestId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showModalResult = () => {
    dispatch(updateBacktestPrevHistory(true));
    changeBacktestId(id);
  };
  return (
    <div className="item-head">
      <div className="status">
        <span
          className={`status__badge
         ${progress === 0 ? 'progressing' : ''} ${
            profit > 0 ? 'positive' : 'negative'
          }`}
        >
          {progress === 0 ? (
            'IN PROGRESS'
          ) : (
            <>
              <span className={profit > 0 ? 'text-success' : 'text-danger'}>
                {formatNumber.formatCurrency(profit)}
              </span>
              /24h
            </>
          )}
        </span>
      </div>
      <div
        className="navigate"
        onClick={showModalResult}
        onKeyDown={showModalResult}
        role="presentation"
      >
        {t('View Result')} {' >'}
      </div>
    </div>
  );
};

CardItemHead.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  profit: PropTypes.number.isRequired,
  changeBacktestId: PropTypes.func.isRequired,
};

export default CardItemHead;
