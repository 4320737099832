import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CANDLE_BASE } from '@/config/const';
import { CandlePatternContext } from '../../context';
import Bubble from './Bubble';

const CandlePanel = ({ value, conditions, setCondition, isEdit, isReadOnly, currentCandle }) => {
  const { t } = useTranslation();
  const { isDefault, current, isEditCond } = useContext(CandlePatternContext);
  return (
    <div>
      <p className="BubbleConditions__panel-title">
        {t('Board {{value}}', { value: value + 1 })}
      </p>
      <div className="BubbleConditions__panel">
        {CANDLE_BASE.map((item) => (
          <Bubble
            key={`panel${value}-item${item}`}
            onSelect={setCondition}
            value={item - 1 + value * 20}
            state={conditions[item - 1 + value * 20]}
            currentBubble={!isReadOnly ? current : currentCandle}
            isEdit={isEdit && isEditCond}
            isDefault={isDefault}
          />
        ))}
      </div>
    </div>
  );
};

CandlePanel.propTypes = {
  isReadOnly: PropTypes.bool,
  isEdit: PropTypes.bool,
  value: PropTypes.number.isRequired,
  conditions: PropTypes.instanceOf(Object),
  setCondition: PropTypes.func,
  currentCandle: PropTypes.number,
};

CandlePanel.defaultProps = {
  isReadOnly: false,
  isEdit: false,
  conditions: [],
  setCondition: () => {},
  currentCandle: 999
};

export default CandlePanel;
