/* eslint-disable react/prop-types */
import { TRANSFER_TYPES } from '@/config/const';
import { selectBalance } from '@/redux/selectors/walletSelector';
import { Box } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { transfer } from '@/redux/refactor/wallet/actions';
import { ArrowSwapHorizontal } from 'iconsax-react';
import formatNumber from '@/shared/helpers/formatNumber';
import {
  colorAccent,
  colorGreyScale500,
  colorGreyScale600,
  colorGreyScale900,
  colorText,
} from '@/utils/palette';
import { Button as BsButton } from 'react-bootstrap';
import { Button } from '../../Button';
import Icons from '../../icons';
import {
  CardBodyWithShorter,
  CardFooter,
  CardStyle,
  CardTitle,
} from './styles';
import CurrencyInput from '../../CurrencyInput';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const PanelTransfer = ({ show, setFocusedIdx, onClose }) => {
  const { t } = useTranslation();
  const back = () => {
    setFocusedIdx(0);
  };
  const dispatch = useDispatch();

  const { profile, transferring } = useSelector(state => ({
    profile: state.user.profile,
    transferring: state.wallet.transferring
  }), isEqual);

  const [transferType, setTransferType] = useState(TRANSFER_TYPES.IN);
  const [amount, setAmount] = useState(null);
  const [invalidAmount, setInvalidAmount] = useState(true);
  const { availableBalance, usdtAvailableBalance } = useSelector(
    selectBalance,
    _.isEqual,
  );
  const maxBalance = transferType === TRANSFER_TYPES.IN
      ? usdtAvailableBalance
      : availableBalance;
  const fromTitle = transferType === TRANSFER_TYPES.IN
      ? 'USDT Wallet'
      : 'Live Wallet';
  const toTitle = transferType === TRANSFER_TYPES.IN ? 'Live Wallet' : 'USDT Wallet';

  const handleChangeType = () => {
    setAmount(null);
    setTransferType(
      transferType === TRANSFER_TYPES.IN
        ? TRANSFER_TYPES.OUT
        : TRANSFER_TYPES.IN,
    );
  };
  const handleMax = () => {
    setAmount(
      transferType === TRANSFER_TYPES.IN
        ? usdtAvailableBalance
        : availableBalance,
    );
  };
  const handleInputChange = ({ target }) => {
    const { value } = target;
    if (value !== '') {
      const num = Number(value.replace('$', '').replaceAll(',', ''));
      setAmount(num);
    } else {
      setAmount(null);
    }
  };

  const handleSubmit = async () => {
    const ok = await dispatch(
      transfer(transferType, {
        amount,
        confirmed: true,
      }),
    );
    if (ok) {
      const transferTypeActions = {
        IN: 'transferToTrade',
        OUT: 'transferToUSDT'
      }

      const transferTypeAction = transferTypeActions[transferType];

      ga4Stats(gtag.wallet.action[transferTypeAction], gtag.wallet.category, gtag.wallet.label, profile?.uid);
      setFocusedIdx(0);
    }
  };

  useEffect(() => {
    if (show) {
      setTransferType(TRANSFER_TYPES.IN);
      setAmount(null);
    }
  }, [show]);

  useEffect(() => {
    if (amount > 0 && amount <= maxBalance) {
      setInvalidAmount(false);
    } else {
      setInvalidAmount(true);
    }
  }, [amount, maxBalance]);

  if (!show) return <div />;
  return (
    <CardStyle>
      <CardTitle>
        <Box flex={1}>{t('Exchange wallet')}</Box>
        <Box onClick={onClose}>
          <Icons.CloseIcon />
        </Box>
      </CardTitle>
      <CardBodyWithShorter>
        <Box padding="0px 16px">
          <TransferType mt="48px">
            <TransferType>
              <Box className="title">
                {t('From')} <br />
                {t(fromTitle)}
              </Box>
              <Box flex={1} display="flex" justifyContent="center">
                <ButtonStyle
                  onClick={handleChangeType}
                  variant="outline"
                  size="sm"
                >
                  <ArrowSwapHorizontal size={14} />
                </ButtonStyle>
              </Box>
              <Box className="title">
                {t('To')} <br />
                {t(toTitle)}
              </Box>
            </TransferType>
          </TransferType>
          <CurrencyInputStyle
            value={amount}
            onChange={handleInputChange}
            placeholder="$0"
            type="text"
          />
          <BalanceBox onClick={handleMax}>
            <span>
              {t('Balance')}: {formatNumber.sliceDecimalFloat(maxBalance)}
            </span>
            <Max>{t('MAX')}</Max>
          </BalanceBox>
        </Box>
      </CardBodyWithShorter>
      <CardFooter>
        <Box width="100%" display="flex" gridGap="12px">
          <Button onClick={back} variant="secondary_outline">
            {t('< Back')}
          </Button>
          <Box flex={1}>
            <ButtonTransfer
              onClick={handleSubmit}
              disabled={invalidAmount || transferring}
              isLoading={transferring}
              variant="secondary"
              block="true"
            >
              {t(amount > maxBalance ? 'Insufficient Balance' : 'Transfer Now')}
            </ButtonTransfer>
          </Box>
        </Box>
      </CardFooter>
    </CardStyle>
  );
};

const TransferType = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 32px;
  gap: 32px;
  .title {
    font-weight: 800;
    font-size: 16px;
    line-height: 140%;
    color: ${colorText};
    white-space: nowrap;
  }
`;
const ButtonStyle = styled(BsButton)`
  padding: 0;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(207, 219, 213, 0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorText};
  &:hover {
    border-color: rgba(207, 219, 213, 0.7) !important;
  }
`;
const CurrencyInputStyle = styled(CurrencyInput)`
  border: none;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${colorText};
  background: transparent;
  &::placeholder {
    color: ${colorGreyScale500};
  }
`;

const BalanceBox = styled(Box)`
  user-select: none;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 41px;
  color: ${colorText};
  cursor: pointer;
  .balance {
    margin-bottom: 8px;
  }
`;

const Max = styled(Box)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  margin-left: 5px;
  color: ${colorAccent};
`;

const ButtonTransfer = styled(Button)`
  background: ${colorGreyScale900};
  border-radius: 12px;
  color: #fff;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: #fff !important;
    background: ${colorGreyScale600}!important;
  }
`;

export default PanelTransfer;
