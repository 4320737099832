import React from 'react';
import { Drawer } from '@material-ui/core';
import CardBackTest from '../CardBackTest';

export default function DrawerCreateBackTest({
  open,
  onClose,
  totalHistory,
  onRefetchHistory,
  changeBacktestId,
}) {
  return (
    <Drawer
      style={{ padding: 0 }}
      PaperProps={{
        style: {
          borderRadius: '16px 16px 0 0',
        },
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      <CardBackTest
        onRefetchHistory={onRefetchHistory}
        totalToDay={totalHistory.totalToDay}
        limitPerDay={totalHistory.limitPerDay}
        changeBacktestId={changeBacktestId}
        closeModal={onClose}
      />
    </Drawer>
  );
}
