import styled from 'styled-components';
import { Devices, logoImg, shortLogoImg } from '@/utils/palette';
import { Box } from '@material-ui/core';

const Logo = styled(Box)`
  display: block;
  background-image: ${shortLogoImg};
  width:32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  @media ${Devices.pc} {
    background-image: ${props => (props.short ? shortLogoImg : logoImg)};
    width: ${props => props.width || 180}px;
    height: ${props => props.height || 40}px;
  }
`;
export default Logo;
