import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectNotiLoading,
} from '@/redux/selectors/notiSelector';
import { fetchNoti, updateIdNotiesRendered } from '@/redux/actions/notiActions';
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import NotificationItem from './NotificationItem';
import Nodata from '@/shared/components/Nodata';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 50,
});

const NotificationList = ({ items, channel, closePopup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    currentLangCode,
    accountType
  } = useSelector(state => ({
    isLoading: selectNotiLoading(state),
    currentLangCode: state.appConfig.languageCode,
    accountType: state.wallet.accountType,
  }), _.isEqual);  

  const rowRenderer = ({ key, index, style, parent }) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <NotificationItem
            item={items[index]}
            style={style}
            onDidMount={measure}
            closePopup={closePopup}
          />
        )}
      </CellMeasurer>
    );
  };

  const onRowsRendered = ({ startIndex, stopIndex }) => {
    // console.log("Rows in view:", startIndex, "to", stopIndex);
    const visibleItemsId = items
      .slice(startIndex, stopIndex + 1)
      .filter((item) => !item.read)
      .map((item) => item.id);
    dispatch(updateIdNotiesRendered(visibleItemsId));
  };

  const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    // console.log('handleScroll', scrollHeight, scrollTop, clientHeight);
    const isBottom =
      scrollHeight > clientHeight &&
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (isBottom && !isLoading) {
      dispatch(
        fetchNoti({
          created_at: items[items.length - 1].created_at,
          lang: currentLangCode,
          channel: channel,
          account_type: accountType
        })
      );
    }
  };

  return (
    <>
      {!!items.length && (
        <AutoSizer>
          {({ width, height }) => (
            <List
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
              onRowsRendered={onRowsRendered}
              rowCount={items.length}
              width={width}
              height={height}
              onScroll={handleScroll}
              className="notification-list"
            />
          )}
        </AutoSizer>
      )}

      {!items.length && <Nodata text={t("You don't have any notifications yet")} />}
    </>
    
  );
};

NotificationList.propTypes = {
  items: PropTypes.instanceOf(Array),
  channel: PropTypes.string.isRequired,
  closePopup: PropTypes.func.isRequired
};

NotificationList.defaultProps = {
  items: [],
};

export default NotificationList;
