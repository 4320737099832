import { PlanCrudProvider } from '@/context/PlanCrudProvider';
import useTotalHistory from '@/hooks/queries/useTotalHistory';
import {
  updateBacktestDataChart,
  updateBacktestPrevHistory,
  updateBacktestProgress,
} from '@/redux/actions/planActions';
import formatNumber from '@/shared/helpers/formatNumber';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CardBackTest from './CardBackTest';
import ModalHistory from './modal/ModalHistory';
import ModalResult from './modal/ModalResult';

export default function BackTest() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModalHistory, setShowModalHistory] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const [backtestId, setBacktestId] = useState('');
  const changeBacktestId = (id) => {
    dispatch(updateBacktestDataChart([]));
    dispatch(updateBacktestProgress(0));
    setBacktestId(id);
    if (showModalHistory) {
      // setShowModalHistory(false);
    }
    setShowModalResult(true);
  };
  const hideModalResult = () => {
    setShowModalResult(false);
    dispatch(updateBacktestPrevHistory(false));
  };
  const { refetch, data = {}, isLoading } = useTotalHistory();
  return (
    <PlanCrudProvider>
      <BackTestWrap>
        <CardBackTest
          {...data}
          changeBacktestId={changeBacktestId}
          isLoading={isLoading}
          onRefetchHistory={refetch}
        />
        {!isLoading && (
          <PlanHistoryCTA onClick={() => setShowModalHistory(true)}>
            {t('Test Plan History ({{amount}})', {
              amount: formatNumber.numberWithCommas(data?.total) || 0,
            })}
          </PlanHistoryCTA>
        )}
        <ModalHistory
          show={showModalHistory}
          onHide={() => setShowModalHistory(false)}
          changeBacktestId={changeBacktestId}
        />
        {backtestId && (
          <ModalResult
            show={showModalResult}
            onHide={hideModalResult}
            backtestId={Number(backtestId)}
            showModalHistory={() => setShowModalHistory(true)}
          />
        )}
      </BackTestWrap>
    </PlanCrudProvider>
  );
}

const BackTestWrap = styled.div``;

const PlanHistoryCTA = styled.div`
  text-align: center;
  padding: 24px 16px;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
