import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

const mapStateToPropsRoute = (state) => ({
  loggedIn: !!state.user.token,
  connected: !!state.exchange.connected,
  urlAfterLogin: state.theme.urlAfterLogin,
});

export const AuthRoute = connect(mapStateToPropsRoute)(
  ({ loggedIn, urlAfterLogin, ...restOfProps }) => {
    return loggedIn ? <Redirect to={urlAfterLogin} /> : <Route {...restOfProps} />
  }
    
);

export const ConnectedRoute = connect(mapStateToPropsRoute)(
  ({ loggedIn, connected, ...restOfProps }) => {
    if (connected) return <Redirect to="/" />;
    if (!loggedIn) return <Redirect to="/login" />;
    return <Route {...restOfProps} />;
  }
);

export const ProtectedRoute = connect(mapStateToPropsRoute)(
  ({ loggedIn, connected, component: Component, ...restOfProps }) => {
    if (!loggedIn) return <Redirect to="/login" />;
    if (!connected) return <Redirect to="/connect" />;
    return (
      <Route {...restOfProps} render={(props) => <Component {...props} />} />
    );
  }
);
