import { STORAGE_KEY } from '@/config/const';
import { generic } from '@/utils/generic';
import {
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
} from './actions';

const initialState = {
  show: false,
  collapse: generic.getJsonLocalStorage(STORAGE_KEY.SIDEBAR_VISIBILITY) || false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      generic.setJsonLocalStorage(STORAGE_KEY.SIDEBAR_VISIBILITY, !state.collapse);
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    default:
      return state;
  }
};

export default sidebarReducer;
