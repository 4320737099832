import React, {useState} from "react";
import styled from 'styled-components';
import { Devices, colorBackgroundTopbarSecondary, colorGreyScale300 } from '@/utils/palette';
import useAuth from '@/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Logo from '@/shared/components/Logo';
import Ticket from '@/shared/components/Ticket/index';
import { Button } from '@/shared/components/Button';
import DrawerSettings from '../components/drawer-settings';
import ModalLanguage from '../components/drawer-settings/ModalLanguage';
import TopbarUserInfo from '../components/topbar/TopbarUserInfo';

const TopbarSecondary = () => {
  const history = useHistory();
  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const [openSettings, setOpenSettings] = useState(false);
  const [openLang, setOpenLang] = useState(false);

  const toggleDrawer = () => {
    setOpenSettings(!openSettings);
  };
  const toggleModal = () => {
    setOpenLang(!openLang);
  };
  const toggleModalMobile = () => {
    setOpenLang(!openLang);
    setTimeout(toggleDrawer, 500);
  };

  return (
    <>
      <Header>
        <Container>
          <Box display="flex" alignItems="center">
            <NavLink to="/">
              <Logo />
            </NavLink>
            <Box
              flex={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gridGap="16px"
            >
              <TopbarUserInfo
                toggleDrawer={toggleDrawer}
                toggleChangeLang={toggleModal}
              />
              {!isAuth && (
                <Button
                  variant="outline-success"
                  onClick={() => history.push('/login')}
                >
                  {t('Login')}
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </Header>
      {location.pathname.includes("/help/") && <Ticket />}
      <DrawerSettings
        open={openSettings}
        onClose={toggleDrawer}
        toggleChangeLang={toggleModalMobile}
      />
      <ModalLanguage open={openLang} onClose={toggleModal} />
    </>
  )
};

const Header = styled.div`
  padding: 0 16px !important;
  height: 56px;
  background: ${colorBackgroundTopbarSecondary};
  display: flex;
  align-items: center;
  @media ${Devices.pc} {
    /* margin-bottom: 121px; */
    height: unset;
    padding: 16px;
    padding: 29px !important;
  }
`;

export default TopbarSecondary;
