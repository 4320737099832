import React, { Fragment, memo, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import PlusIcon from 'mdi-react/PlusIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateCurrentStrategy, updateFormTypeStrategy, updateFormStatusStrategy } from '@/redux/actions/strategyActions';

import { FORM_TYPE } from '@/shared/constants';
import useDebounce from '@/hooks/useDebounce';
import { Button } from '@/shared/components/Button';
import { Form } from '@/shared/components/form/drawer/Elements';
import CustomSelect from '@/shared/components/mui-select-customized/CustomSelect';
import SearchInput from '@/shared/components/mui-select-customized/SearchInput';

// Begin SelectBudget
export default memo(
  ({ value, setBudgetId, isEdit, strategies }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const debouncedSearchTerm = useDebounce(search, 200);
    const [filtered, setFiltered] = useState([]);

    const handleRenderValue = (selected) => {
      const budget = strategies.find((item) =>
        _.isEqual(item.id.toString(), selected.toString())
      );
      return budget?.budget_name;
    };
    const handleSelect = (value) => setBudgetId(value.toString());
    const handleOnClose = () => setSearch('');

    const openFormStrategy = () => {
      dispatch(updateCurrentStrategy({}));
      dispatch(updateFormTypeStrategy(FORM_TYPE.create));
      dispatch(updateFormStatusStrategy(true));
    }

    const handleRenderCTA = () => {
      return (
        <div className="mui-select-item cta-success" value="qi-cta">
          <Box
            className="mui-select-customize-searchbox"
            display={'flex'}
            alignItems={'center'}
          >
            <SearchInput
              placeholder={t('Search by name...')}
              value={search}
              onChange={setSearch}
            />
            <Button onClick={openFormStrategy} variant="success_outline">
              <PlusIcon />
            </Button>
          </Box>
        </div>
      );
    };
    useEffect(() => {
      if (strategies.length > 0) {
        if (!isEdit && _.isEmpty(value)) {
          setTimeout(() => {
            setBudgetId(strategies[0]?.id?.toString());
          }, 0);
        } else {
          const exist = strategies.find((item) =>
            isEqual(item.id.toString(), value.toString())
          );
          if (!exist) {
            setTimeout(() => {
              setBudgetId('');
            }, 0);
          }
        }
      }
    }, [strategies]);

    useEffect(() => {
      let res = strategies;
      const searchTerm = _.deburr(debouncedSearchTerm).toLocaleLowerCase();
      if (!isEmpty(searchTerm)) {
        res =
          strategies?.filter((item) => {
            return _.includes(item.searchTerm, searchTerm);
          }) || [];
      }
      setFiltered(res);
    }, [strategies, debouncedSearchTerm]);

    return (
      <Fragment>
        <Form.Label>{t('Budget strategy')}*</Form.Label>
        <CustomSelect
          options={filtered}
          value={value}
          placeHolder={t('Select strategy...')}
          onClose={handleOnClose}
          onSelect={handleSelect}
          onRenderValue={handleRenderValue}
          renderItemKey={(item) => item.id}
          renderItemLabel={(item) => item.budget_name}
          renderItemValue={(item) => item.id}
          renderCTA={handleRenderCTA}
          autoFocus={false}
        />
      </Fragment>
    );
  }
);
// End SelectBudget
