import { Devices, colorGreyScale400 } from '@/utils/palette';
import PropTypes from 'prop-types';
import React, { memo, useContext, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';
import theme from 'styled-theming';
import Empty from '../Empty';
import { TelegramMessageContext } from './context';

const listStyle = { overflowX: false, overflowY: false };

const VirtualList = ({ rowHeight, ListItem, maxWidth }) => {
  const { filtered = [] } = useContext(TelegramMessageContext);
  const list = useRef();
  const handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e.target;
    const { Grid } = list.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });
  };
  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => (
    <div key={key} style={style} className='signal-item'>
      <ListItem value={filtered[index]} />
    </div>
  );
  return (
    <VirtualListContainer width={maxWidth}>
      <AutoSizer>
        {({ height, width }) => (
          <CustomScrollbars onScroll={handleScroll} style={{ height, width }}>
            <List
              width={width}
              height={height}
              rowCount={filtered.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              ref={list}
              style={listStyle}
              noRowsRenderer={() => <Empty />}
              className='signal-list'
            />
          </CustomScrollbars>
        )}
      </AutoSizer>
    </VirtualListContainer>
  );
};

VirtualList.propTypes = {
  rowHeight: PropTypes.number,
  maxWidth: PropTypes.number,
};
VirtualList.defaultProps = {
  rowHeight: 48,
  maxWidth: 319,
};

const VirtualListContainer = styled.div`
  width: calc(100vw);
  height: 100vh;
  @media ${Devices.pc} {
    width: ${(props) => props.width}px;
    /* height: 438px; */
  }
`;

export const CustomScrollbars = styled(Scrollbars)`
  & > div:last-child {
    opacity: 0;
    transition: opacity 200ms ease;
    & > div {
      background-color: ${theme('mode', {
        light: colorGreyScale400,
        dark: 'rgba(203, 213, 224, 0.1)',
      })}!important;
    }
  }
  &:hover {
    & > div:last-child {
      opacity: 1;
    }
  }
`;

export default memo(VirtualList);
