/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowRight2 } from 'iconsax-react';
import { FOOTER_HEIGHT } from '@/config/const';
import {
  borderDefaultStyle,
  colorGreyScale600,
  colorText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function ModalSubMenu({
  values, show, onHide, onItemClick,
}) {
  const { t } = useTranslation();
  return (
    <ModalStyle show={show} onHide={onHide}>
      <Modal.Body>
        <List>
          {values.map(item => (
            <ListItem key={item.key} onClick={() => onItemClick(item)}>
              <Box width="48px" textAlign="center">
                <item.icon size={24} />
              </Box>
              <Box flex={1}>
                <h4>{t(item.title)}</h4>
                <p>{t(item.description)}</p>
              </Box>
              <ArrowRight2 size={20} />
            </ListItem>
          ))}
        </List>
      </Modal.Body>
    </ModalStyle>
  );
}

ModalSubMenu.propTypes = {
  values: PropTypes.arrayOf(Object).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const ModalStyle = styled(Modal)`
  .modal-dialog {
    position: fixed;
    width: 100%;
    max-width: 100%;
    left: 0;
    bottom: ${FOOTER_HEIGHT + 12}px;
    margin: 0;
    padding: 0px 16px;
  }
  &.fade .modal-dialog {
    transform: translate(0, 50px) !important;
  }
  &.show .modal-dialog {
    transform: none !important;
  }
  .modal-body {
    padding: 4px 16px;
  }
`;
const List = styled.ul`
  padding: 0;
`;
const ListItem = styled.li`
  padding: 12px 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: ${borderDefaultStyle};
  &:last-child {
    border: none;
  }
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    color: ${colorText};
    margin-bottom: 4px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: ${colorGreyScale600};
    /* max-width: 200px; */
  }
`;
