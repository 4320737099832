import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { lighten } from 'polished';
import {
  colorBackground,
  colorGray,
  colorGreyScale600,
  colorGreyScale900,
  colorSuccess,
} from '@/utils/palette';
import {
  left,
  marginRight,
} from '@/utils/directions';
import { SIDEBAR_WIDTH } from '@/config/const';
import theme from 'styled-theming';

const SidebarLink = ({
  title, id, className, icon: Icon, collapse, route, exact, onClick, numberBade,
}) => (
    <li id={id} className={className}>
      <SidebarNavLink
        to={route}
        href={route}
        exact={exact}
        onClick={onClick}
        className="d-flex justify-content-between"
        activeClassName="active"
      >
        <span className="d-flex nav-link-title">
          <LinkIcon className="link-icon">
            <Icon size={24} variant="Bulk" className="icon icon-active" />
            <Icon size={24} className="icon icon-default" />
          </LinkIcon>
          {!collapse && (
            <SidebarLinkTitle>
              {title}
            </SidebarLinkTitle>
          )}
        </span>
        {!collapse && !!numberBade && (
          <NumberBadge bg="secondary" className="number-bade">{numberBade}</NumberBadge>
        )}
      </SidebarNavLink>
    </li>
  );

  SidebarLink.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(Object),
    collapse: PropTypes.bool,
    route: PropTypes.string,
    exact: PropTypes.bool,
    onClick: PropTypes.func,
    numberBade: PropTypes.number,
    id: PropTypes.string,
    className: PropTypes.string,
  };
  
  SidebarLink.defaultProps = {
    icon: null,
    collapse: false,
    route: '/',
    exact: false,
    onClick: () => {},
    numberBade: 0,
    id: null,
    className: null
  };

  export const SidebarExternalLink = ({
    title, icon: Icon, collapse, url, target, onClick, numberBade,
  }) => (
      <li>
        <SidebarExternalNavLink
          href={url}
          target={target}
          onClick={onClick}
          className="d-flex justify-content-between"
        >
          <span className="d-flex">
            <LinkIcon className="link-icon">
              <Icon size={24} variant="Bulk" className="icon icon-active" />
              <Icon size={24} className="icon icon-default" />
            </LinkIcon>
            {!collapse && (
              <SidebarLinkTitle>
                {title}
              </SidebarLinkTitle>
            )}
          </span>
          {!collapse && !!numberBade && (
            <NumberBadge bg="secondary" className="number-bade">{numberBade}</NumberBadge>
          )}
        </SidebarExternalNavLink>
      </li>
    );

SidebarExternalLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  collapse: PropTypes.bool,
  url: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  numberBade: PropTypes.number,
};

SidebarExternalLink.defaultProps = {
  icon: null,
  collapse: false,
  url: '',
  onClick: () => {},
  numberBade: 0,
  target: '_blank',
};


export default SidebarLink;

// region STYLES

export const SidebarNavLink = styled(NavLink)`
  height: 48px;
  width: ${SIDEBAR_WIDTH}px;
  // transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  background: transparent;
  border: none;
  color: ${colorGreyScale600};
  text-align: ${left};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  .link-icon {
    display: flex;
    align-items: center;
    ${marginRight}: 16px;
  }
  &.active {
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }

  &:hover {
    text-decoration: none;
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;
    span {
      position: relative;
      animation: none;
      ${left}: 0;
    }
  }
`;

export const SidebarExternalNavLink = styled.a`
  height: 48px;
  width: ${SIDEBAR_WIDTH}px;
  // transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: ${colorGreyScale600};
  text-align: ${left};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  .link-icon {
    display: flex;
    align-items: center;
    ${marginRight}: 16px;
  }
  &.active {
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }

  &:hover {
    text-decoration: none;
    color: ${colorSuccess};
    font-weight: 700;
    .icon-active {
      opacity: 1;
    }
    .icon-default {
      opacity: 0;
    }
    .number-bade {
      background: ${colorSuccess} !important;
      color: #fff;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;

    span {
      position: relative;
      animation: none;
      ${left}: 0;
    }
  }
`;

const NumberBadge = styled(Badge)`
  background-color: ${theme('mode', {
    light: colorGreyScale900,
    dark: '#fff',
  })}!important;
  color: ${theme('mode', {
    light: '#fff',
    dark: colorGreyScale900,
  })};
  font-size: 12px;
  font-weight: 500;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const SidebarLinkTitle = styled.span`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const SidebarLinkIcon = styled.span`
  font-size: 13px;
  line-height: 13px;
  color: ${lighten(0.25, colorGray)};
  ${marginRight}: 10px;
`;

export const LinkIcon = styled.span`
  width: 24px;
  height: 24px;
  position: relative;
  .icon {
    position: absolute;
    // transition: all 0.3s linear;
    &-active {
      opacity: 0;
    }
  }
`;

// endregion
