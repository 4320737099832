import {
  Devices,
  borderDefaultStyle,
  colorBackgroundModal,
  colorGreyScale500,
  colorText,
} from '@/utils/palette';
import { Drawer } from '@material-ui/core';
import { motion } from 'framer-motion';
import CloseIcon from 'mdi-react/CloseIcon';
import { Accordion as BsAccordion } from 'react-bootstrap';
import { Form as RFForm } from 'react-final-form';
import styled from 'styled-components';
import { Input } from '../../portfolio/form/components/FormElements';

export const drawerProps = {
  bottom: {
    PaperProps: { className: 'custom-paper-bottom' },
    anchor: 'bottom',
  },
  right: {
    PaperProps: { className: 'custom-paper-right' },
    anchor: 'right',
  },
  mobile: {
    PaperProps: { className: 'custom-paper-mobile' },
    anchor: 'bottom',
  },
};

function Header({ Title, onClose }) {
  return (
    <HeaderStyle>
      <TitleContainer>{Title}</TitleContainer>
      <CloseIcon
        className="cursor-pointer"
        color={colorGreyScale500}
        onClick={onClose}
      />
    </HeaderStyle>
  );
}

const DrawerStyle = styled(Drawer)`
  .MuiDrawer-paper {
    overflow: visible;
  }
  .custom-paper-right {
    max-width: 850px;
    width: 100%;
    background: ${colorBackgroundModal}!important;
  }
  .custom-paper-bottom {
    background: none;
  }
  .custom-paper-mobile {
    background: ${colorBackgroundModal}!important;
    max-height: calc(100vh - 120px);
    border-radius: 16px 16px 0 0;
  }
`;
const HeaderStyle = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
`;
const TitleContainer = styled.h3`
  flex: 1;
  font-size: 24px;
`;
const BodyStyle = styled.div`
  flex: 1;
  padding: 0 8px 24px 8px;
  overflow-y: auto;
  input {
    font-size: 16px;
    font-weight: 500;
    color: ${colorText};
  }
  @media ${Devices.pc} {
    padding: 0 16px 24px 16px;
  }
`;
const FooterStyle = styled.div`
  padding: 24px 16px;
  border-top: ${borderDefaultStyle};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  :nth-child() {
    flex: 1;
  }
`;
const Form = styled(RFForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
Form.Header = Header;
Form.Body = BodyStyle;
Form.Footer = FooterStyle;
Form.Headline = styled.p`
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1rem;
`;
Form.Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  color: ${colorGreyScale500}!important;
`;

// Props as number of columns
const Grid = styled.div`
  border: ${borderDefaultStyle};
  border-radius: 12px;
`;
Grid.Row = styled.div`
  display: grid;
  border-bottom: ${borderDefaultStyle};
  &:last-child {
    border-bottom: none;
  }
  @media ${Devices.tablet} {
    grid-template-columns: repeat(${(props) => props.cols || 1}, 1fr);
    ${(props) =>
      (!props.cols || props.cols < 2) &&
      `
      & > div {
        border: none;
      }
    `}
  }
`;
Grid.Cell = styled(motion.div)`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-bottom: ${borderDefaultStyle};
  &:last-child {
    border-bottom: none;
  }
  @media ${Devices.tablet} {
    padding: 16px;
    border-left: ${borderDefaultStyle};
    border-bottom: none;
    &:first-child {
      border-left: none;
    }
  }
`;

const CsAccordion = styled(BsAccordion)`
  .accordion-button {
    font-weight: 700;
    line-height: 140%;
    width: 100%;
    padding: 20px 16px;
    &:not(.collapsed)::after {
      transform: rotate(0deg);
    }
  }
  .accordion-body {
    padding-top: 12px;
  }
`;

// FormControl
const FormControl = {};
FormControl.Input = styled(Input)``;

export { CsAccordion, DrawerStyle, Form, Grid };
