import { Box } from '@material-ui/core';
import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { colorGreyScale300, colorGreyScale600, colorGreyScale700 } from '@/utils/palette';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

export const ExchangeLinkedBadge = () => {
  const { t } = useTranslation();
  const { logo } = useSelector(state => state.exchange, isEqual);
  return (
  <LinkStyle to="/settings/exchange">
    <div className="content">
      <Box className="content__title" color={colorGreyScale600}>{t('Exchange Linked')}</Box>
      <FigureStyle bg={logo} />
    </div>
  </LinkStyle>
); 
};

export const ExchangeLogo = () => {
  const { logo, domain } = useSelector(state => state.exchange, isEqual);
  return (
    <ExchangeLogoStyle>
      <Image src={logo} alt={domain} />
    </ExchangeLogoStyle>
  );
};

const ExchangeLogoStyle = styled(Box)`
  background: linear-gradient(180deg, #666C75 0%, #020D1D 100%);
  border-radius: 6.80769px;
  padding: 5.15px 8.51px;
  width: fit-content;
  img {
    height: 13px;
  }
`;

const LinkStyle = styled(Link)`
  padding: 0 24px;
  display: block;
  margin-bottom: 16px;
  margin-top: 8px;
  .content {
    padding: 10px 15px;
    /* background: linear-gradient(180deg, #666C75 0%, #020D1D 100%); */
    background: ${colorGreyScale700};
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    &__title {
      opacity: 0.5;
      color: ${colorGreyScale300};
      margin-bottom: 4px;
    }
    img {
      height: 24px;
    }
  }
`;


const FigureStyle = styled.figure`
  height: 24px;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  mix-blend-mode: luminosity
`;
