import { createAction } from 'redux-actions';
import botApi from '@/utils/api/botApi';
import { TYPE_KEY } from '@/shared/constants';

export const updateBots = createAction('UPDATE_BOTS');
export const updateBot = createAction('UPDATE_BOT');
export const updatePagination = createAction('UPDATE_BOT_PAGINAION');
export const updateIsFetching = createAction('UPDATE_BOT_IS_FETCHING');
export const updateTotalSignal = createAction('UPDATE_TOTAL_SIGNAL');
export const updateIsTopBotList = createAction('UPDATE_IS_TOP_BOT_LIST');
export const updateCurrentBot = createAction('UPDATE_CURRENT_BOT');
export const updateFormTypeBot = createAction('UPDATE_FORM_TYPE_BOT');
export const updateFormStatusBot = createAction('UPDATE_FORM_STATUS_BOT');
export const updateOptionsTypeBot = createAction('UPDATE_OPTIONS_TYPE_BOT');
export const updateSelectedBots = createAction('UPDATE_SELECTED_BOTS');
export const updateLatestCreatedBot = createAction('UPDATE_LATEST_CREATED_BOT');
export const updateFormTypeCandle = createAction('UPDATE_FORM_TYPE_CANDLE');
export const updateFormStatusCandle = createAction('UPDATE_FORM_STATUS_CANDLE');

export const getBots =
  (params, hasLoading = true) =>
  async (dispatch) => {
    if (hasLoading) {
      dispatch(updateIsFetching(true));
    }
    const search = new URLSearchParams(window.location.search).get('search');
    const { data } = await botApi.getBots({ ...params, search });
    dispatch(updateIsFetching(false));
    if (data?.ok) {
      if (!params.isTopBotList) {
        dispatch(updateBots(data.d.c));
      } else {
        dispatch(
          updateBots(
            data.d.c.map((plan) => {
              return { ...plan, id: plan.planId };
            })
          )
        );
      }

      dispatch(
        updatePagination({
          p: data.d.p,
          s: data.d.s,
          t: data.d.t,
        })
      );

      if (!params.isTopBotList) {
        dispatch(updateTotalSignal(data.d.t));
      }
    }
  };

export const getOptionsTypeBot = () => async (dispatch) => {
  const { data } = await botApi.getMethods(TYPE_KEY.signalStrategyType);
  if (data?.ok) {
    const options = data?.d?.map((option) => ({
      value: option.code,
      label: option.name,
    }));
    dispatch(updateOptionsTypeBot(options));
  }
};
