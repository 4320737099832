import { colorSuccess } from '@/utils/palette';
import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Switch = props => <SwitchStyle {...props} type="switch" />;

const SwitchStyle = styled(Form.Check)`
  input {
    cursor: pointer;
    height: 33.27px!important;
    width: 61px!important;
    &:checked {
      background-color: ${colorSuccess};
      border-color: ${colorSuccess};
    }
  }

`;

export default Switch;
