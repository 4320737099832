/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { Card, CardBody, CardTitle } from '@/shared/components/Card';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import formatNumber from '@/shared/helpers/formatNumber';
import { colorGreyScale100, colorGreyScale600, colorText } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styled-theming';

const ReviewPlan = withTranslation()(({ values, t }) => {
  const target = `${
    values.take_profit ? formatNumber.formatCurrency(values.take_profit) : '- -'
  }/-${values.stop_loss ? formatNumber.formatCurrency(values.stop_loss) : '- -'}${
    values.stop_loss_positive
      ? `/${formatNumber.formatCurrency(values.stop_loss_positive)}`
      : values?.signal_strategy_type !== SIGNAL_STRATEGY_TYPE.expert
      ? '/- -'
      : ''
  }`;
  return (
    <CardStyle>
      <CardTitleStyle>{`${values.name} Copy`}</CardTitleStyle>
      <CardBodyStyle>
        <AllocatedBudget
          label={t('Allocated Budget')}
          value={formatNumber.formatCurrency(values.budget)}
        />
        <Divider />
        <Item label={t('Account Type')} value={values.account_type} />
        <Item label={t('Take Profit/Stoploss')} value={target} />
        <Divider />
        {values.signal_strategy_type !== SIGNAL_STRATEGY_TYPE.expert && (
          <Item label={t('Budget Strategy')} value={values.budget_strategy_name} />
        )}
        <Item label={t('Signal Strategy')} value={t(values.signal_strategy_name)} />
        <Item label={t('Base Amount')} value={formatNumber.formatCurrency(values?.base_amount)} />
      </CardBodyStyle>
    </CardStyle>
  );
});

const AllocatedBudget = ({ label, value }) => (
  <ItemWrap display="flex">
    <Box className="item-label" flex={1}>
      {label}
    </Box>
    <Box className="item-amount">{value}</Box>
  </ItemWrap>
);
const Item = ({ label, value }) => (
  <ItemWrap>
    <Box className="item-label" flex={1}>
      {label}
    </Box>
    <Box className="item-value">{value}</Box>
  </ItemWrap>
);
const ItemWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  .item-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #a0aec0;
    white-space: nowrap;
  }
  .item-value {
    font-weight: 550;
    font-size: 14px;
    line-height: 160%;
    text-align: right;
    color: ${colorText};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .item-amount {
    font-weight: 800;
    font-size: 32px;
    line-height: 125%;
    color: ${theme('mode', {
      light: '#404040',
      dark: colorGreyScale100,
    })};
  }
`;
const CardStyle = styled(Card)`
  border: 1px solid
    ${theme('mode', {
      light: '#eeeff2',
      dark: colorGreyScale600,
    })};
  border-radius: 16px;
  margin-bottom: 0px;
`;
const CardTitleStyle = styled(CardTitle)`
  font-weight: 800;
  font-size: 20px;
  line-height: 140%;
  color: ${theme('mode', {
    light: '#404040',
    dark: colorGreyScale100,
  })};
  padding: 20px 24px 16px;
  border-bottom: 1px solid
    ${theme('mode', {
      light: '#eeeff2',
      dark: colorGreyScale600,
    })};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CardBodyStyle = styled(CardBody)`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Divider = styled.div`
  /* border-bottom: 1px dashed #CBD5E0; */
  margin: 12px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CBD5E0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-size: 1000%;
  height: 1px;
`;
export default ReviewPlan;
