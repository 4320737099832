import {
  colorGreyScale300,
  colorGreyScale600,
  colorText,
} from '@/utils/palette';
import { CloseCircle, SearchNormal1 } from 'iconsax-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'styled-theming';

const SearchInput = ({ value, onChange, ...props }) => {
  const { t } = useTranslation();
  const handleChange = (evt) => {
    onChange(evt.target.value);
  };
  const handleClear = () => onChange('');
  return (
    <Wrapper onClick={(evt) => evt.stopPropagation()}>
      <Icon size={22} />
      <Input
        {...props}
        onKeyDown={(evt) => evt.stopPropagation()}
        value={value}
        onChange={handleChange}
      />
      {value && (
        <IconClear onClick={handleClear} color={colorGreyScale600} size={24} />
      )}
    </Wrapper>
  );
};
SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
SearchInput.defaultProps = {
  value: '',
  onChange: () => {},
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`;

const Icon = styled(SearchNormal1)`
  position: absolute;
  left: 16px;
`;
const IconClear = styled(CloseCircle)`
  position: absolute;
  cursor: pointer;
  right: 16px;
`;
const Input = styled.input`
  padding: 16px 52px;
  background: ${theme('mode', {
    dark: 'none',
    light: colorGreyScale300,
  })};
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  width: 100%;
  height: 44px;
  color: ${colorText};
  border: ${theme('mode', {
    dark: '1px solid ' + colorGreyScale600,
    light: 'none',
  })};
`;

export default SearchInput;
