import exchangeApi from '@/utils/api/exchangeApi';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';

export const prepare2FaRequest = createAction('exchange_2fa/prepare2FaRequest');
export const prepare2FaSuccess = createAction('exchange_2fa/prepare2FaSuccess');
export const prepare2FaFailed = createAction('exchange_2fa/prepare2FaFailed');

export const getCode2FaRequest = createAction('exchange_2fa/getCode2FaRequest');
export const getCode2FaSuccess = createAction('exchange_2fa/getCode2FaSuccess');
export const getCode2FaFailed = createAction('exchange_2fa/getCode2FaFailed');

export const enable2FaRequest = createAction('exchange_2fa/enable2FaRequest');
export const enable2FaSuccess = createAction('exchange_2fa/enable2FaSuccess');
export const enable2FaFailed = createAction('exchange_2fa/enable2FaFailed');

export const disable2FaRequest = createAction('exchange_2fa/disable2FaRequest');
export const disable2FaSuccess = createAction('exchange_2fa/disable2FaSuccess');
export const disable2FaFailed = createAction('exchange_2fa/disable2FaFailed');

export const setEnabled2Fa = createAction('exchange_2fa/setEnabled2Fa');

export const prepare2Fa = () => async (dispatch, getState) => {
  try {
    const { exchange } = getState();
    dispatch(prepare2FaRequest());
    const resp = await exchangeApi.prepare2Fa(exchange.domain);
    if (resp?.data?.ok) {
      dispatch(prepare2FaSuccess(resp?.data?.d));
    } else {
      dispatch(prepare2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(prepare2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const getCode2Fa = (captcha) => async (dispatch, getState) => {
  try {
    const { exchangeTwoFactor, exchange } = getState();
    const request = exchangeTwoFactor.isEnabled
      ? exchangeApi.getCodeDeactivate2fa
      : exchangeApi.getCodeActivate2fa;
    dispatch(getCode2FaRequest());
    const resp = await request(exchange.domain, captcha);
    if (resp?.data?.ok) {
      dispatch(getCode2FaSuccess(resp?.data?.d?.data));
    } else {
      dispatch(getCode2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(getCode2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const enable2Fa = (params) => async (dispatch, getState) => {
  try {
    const { exchange } = getState();
    dispatch(enable2FaRequest());
    const resp = await exchangeApi.enable2Fa(exchange.domain, params);
    if (resp?.data?.ok) {
      dispatch(enable2FaSuccess(resp?.data?.d));
    } else {
      dispatch(enable2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(enable2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};

export const disable2Fa = (params) => async (dispatch, getState) => {
  try {
    const { exchange } = getState();
    dispatch(disable2FaRequest());
    const resp = await exchangeApi.disable2Fa(exchange.domain, params);
    if (resp?.data?.ok) {
      dispatch(disable2FaSuccess(resp?.data?.d));
    } else {
      dispatch(disable2FaFailed(resp?.data?.m));
      toast.error(resp?.data?.m);
    }
    return resp?.data?.ok;
  } catch (e) {
    toast.error(e?.message || 'Something went wrong!');
    dispatch(enable2FaFailed(e?.message || 'Something went wrong!'));
  }
  return false;
};
