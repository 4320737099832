import '@/assets/sass/_mui-select.scss';
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import Empty from '../Empty';

const ITEM_HEIGHT = 56;
const ITEM_PADDING = 16;
const MenuProps = {
  PaperProps: {
    className: 'mui-select-paper-customize',
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING,
      width: 250,
      borderRadius: 12,
      paddingLeft: 8,
      paddingRight: 8,
      boxShadow:
        '0px 8px 50px 0px rgba(26, 32, 44, 0.08), 5px 5px 50px 0px rgba(26, 32, 44, 0.06)',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export default function CustomSelect({
  options = [],
  value,
  placeHolder,
  onSelect,
  onRenderValue,
  renderItemKey,
  renderItemValue,
  renderItemLabel,
  renderCTA,
  autoFocus = true,
  ...rest
}) {
  const handleChange = (event) => {
    if (event.target.value === 'qi-cta') {
      return;
    }
    onSelect(event.target.value);
  };
  return (
    <FormControl fullWidth={true}>
      {placeHolder && <InputLabel>{placeHolder}</InputLabel>}
      <Select
        value={value || ''}
        onChange={handleChange}
        MenuProps={{ ...MenuProps, autoFocus }}
        className="mui-select-customize"
        renderValue={onRenderValue}
        placeholder="Select bot..."
        IconComponent={(props) => (
          <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M4 5.99982L8 9.99982L12 5.99982"
              stroke="#A0AEC0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        {...rest}
      >
        {renderCTA && renderCTA()}
        {options.map((item) => (
          <MenuItem
            className="mui-select-item"
            key={renderItemKey(item)}
            value={renderItemValue(item)}
          >
            <div className="text-ellipsis">{renderItemLabel(item)}</div>
          </MenuItem>
        ))}
        {options.length === 0 && <Empty />}
      </Select>
    </FormControl>
  );
}
