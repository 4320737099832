import React from 'react';
import { withTranslation } from 'react-i18next';
import SettingItem from './SettingItem';

// Begin ExpertMode
export default withTranslation()(({ show, t }) =>
  show ? (
    <SettingItem
      name="expert_mode"
      title={t('FormCreatePlan_advanced_expertMode_label')}
      desc={t('FormCreatePlan_advanced_expertMode_desc')}
    />
  ) : null
);
// End ExpertMode
