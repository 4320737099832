import {
  get, put, post,
} from './base';
import { getQueryString } from './base/axios';

export default {
  /**
   * 
   * @returns {json} {data: {
    "ok": true,
    "d": {
      "availableBalance": 0.0000,
      "usdtAvailableBalance": 8000.0000,
      "demoBalance": 1000.0000,
      "aliAvailableBalance": 7000.0000,
      "aliPrice": 2.98
    }
  }}
   */
  getBalance: () => (
    get('/budgets/cash/spot-balance')
  ),
  /**
   * 
   * @param {Object} params
   * @param {number} params.page
   * @param {number} params.size
   * @return 
   */
  getBalanceTransactions: async (params) => {
    try {
      const resp = await get(`/budgets/usdt/transactions/balance?${getQueryString(params)}`);
      if (resp?.data?.ok) {
        return resp?.data?.d?.c || [];
      }
      return [];
    } catch (e) {
      return [];
    }
  },
  getTransferTransactions: async (params) => {
    try {
      const resp = await get(`/budgets/cash/spot-wallet-transactions?${getQueryString(params)}`);
      if (resp?.data?.ok) {
        const data = resp?.data?.d?.c || [];
        return data.map(item => ({
          ts: item.transactionTime,
          type: item.type,
          amount: item.amount,
          txid: '{}',
          status: item.status,
        }));
      }
      return [];
    } catch (e) {
      return [];
    }
  },
  getUsdtAddress: () => post('/budgets/USDT/address'),
  getWithdrawConfig: () => get('/budgets/withdraw-config'),
  /**
   * 
   * @param {Object} params 
   * @param {string} params.amount
   * @param {string} params.nickName
   * @param {string} params.verifyCode Google authenticator code
   * @returns 
   */
  internalTransfer: params => post('/budgets/usdt/transfer', params),
  /**
    * 
    * @param {Object} params 
    * @param {string} params.amount
    * @param {string} params.toAddress
    * @param {string} params.verifyCode Google authenticator code
    * @param {string} param.network BSC
    * @returns 
    */
  externalWithdraw: params => post('/budgets/usdt/withdraw', params),
  reloadDemoBalance: () => put('/budgets/cash/demo'),
  transferIn: params => post('/budgets/cash/move-usdt-fu', params),
  transferOut: params => post('/budgets/cash/move-fu-usdt', params),
};
