import {
  StyledListContainer,
  StyledMessageContainer,
  StyledRow,
  StyledWrap,
} from './styles';
import { SearchBox } from './SearchBox';
import List from './List';
import { MessageBox } from './Message';
import { SelectedChannels } from './SelectedChannels';
import { ListItem } from './ListItem';
import MessageHeader from './MessageHeader';

export function PcView() {
  return (
    <StyledWrap>
      <StyledRow>
        <StyledListContainer>
          <SearchBox />
          <List ListItem={ListItem} rowHeight={66} />
        </StyledListContainer>
        <StyledMessageContainer>
          <MessageHeader />
          <MessageBox />
        </StyledMessageContainer>
      </StyledRow>
      <SelectedChannels />
    </StyledWrap>
  );
}
