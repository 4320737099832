import { handleActions } from 'redux-actions';

import { updateUserFollowers, updateUserFollowerPagination, updateUserFollowerIsFetching } from '../actions/userFollowerActions';

const defaultState = {
  user_followers: [],
  pagination: {},
};

export default handleActions(
  {
    [updateUserFollowers](state, { payload }) {
      return {
        ...state,
        user_followers: payload,
      };
    },
    [updateUserFollowerPagination](state, { payload }) {
      return {
        ...state,
        pagination: payload,
      };
    },
    [updateUserFollowerIsFetching]: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
  },
  defaultState,
);
