/* eslint-disable react/prop-types */
import { Devices } from '@/utils/palette';
import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import NavDropdownUserInfo from './NavDropdownUserInfo';
import TopbarUserInfoItem from './TopbarUserInfoItem';

const TopbarUserInfo = ({ toggleDrawer, toggleChangeLang }) => (
    <>
      <ShowOnPc id="pc-top-settings">
        <NavDropdownUserInfo toggleChangeLang={toggleChangeLang} />
      </ShowOnPc>
      <ShowOnMobile id="mobile-top-settings" onClick={toggleDrawer}>
        <TopbarUserInfoItem />
      </ShowOnMobile>
    </>
  );

const ShowOnPc = styled(Box)`
  display: none;
  @media ${Devices.pc} {
    display: block;
  }
`;

const ShowOnMobile = styled(Box)`
  display: block;
  @media ${Devices.pc} {
    display: none;
  }
`;
export default TopbarUserInfo;
