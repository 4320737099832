import useAuth from '@/hooks/useAuth';
import {
  colorBackgroundBody,
  colorBackgroundGrey,
  colorGreyScale900,
  colorText,
  Devices,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button';
import Logo from '../Logo';
import SelectLanguage from '../SelectLanguage';
import { AccountContent, AccountWrap, HelpLink } from './AccountElements';

// eslint-disable-next-line react/prop-types
const AccountWithoutBanner = ({ children, showHint = true }) => {
  const { isAuth, logout } = useAuth();
  const { t } = useTranslation();
  return (
    <Fragment>
      <AccountWrapStyle>
        <Box padding="0px">
          <Header>
            <Container>
              <Box display="flex" alignItems="center">
                <Logo />
                <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" gridGap="16px">
                  <SelectLanguage />
                  {isAuth && (
                    <ButtonLogout onClick={logout} variant="link">
                      {t('Logout')}
                    </ButtonLogout>
                  )}
                </Box>
              </Box>
            </Container>
          </Header>
        </Box>
        <AccountContentStyle>
          {children}
          {showHint && <HelpLink>
            {t('Have a problems with something?')} {' '}
            <NavLink target="_blank" to="/help/faqs">{t('Get Help')}</NavLink>
          </HelpLink>}
        </AccountContentStyle>
      </AccountWrapStyle>
    </Fragment>
  );
};

const AccountWrapStyle = styled(AccountWrap)`
  background: ${colorBackgroundGrey};
  display: block;
  overflow: unset;
`;
const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 16px !important;
  height: 56px;
  background: ${colorBackgroundBody};
  display: flex;
  align-items: center;
  z-index: 100;
  @media ${Devices.pc} {
    /* margin-bottom: 121px; */
    height: unset;
    padding: 16px;
    padding: 29px !important;
  }
`;
const AccountContentStyle = styled(AccountContent)`
  flex-direction: column;
  align-items: center;
  padding-top: 98px!important;
  padding-bottom: 24px!important;
  min-height: 100vh;
  gap: 16px;
  @media ${Devices.pc} {
    padding-top: 110px!important;
  }
`
const ButtonLogout = styled(Button)`
  color: ${colorText};
  text-decoration: none;
  padding: 0;
`;

export default AccountWithoutBanner;
