import getBotAndMethod from '@/containers/Portfolio/helpers';
import { selectBots, selectStrategies } from '@/redux/refactor/common/selector';
import { Grid } from '@/shared/components/form/drawer/Elements';
import { SIGNAL_STRATEGY_TYPE, STRATEGY_TYPE } from '@/shared/constants';
import { colorSuccess } from '@/utils/palette';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormHint } from './FormElements';

const Item = ({ show, name, method, prefix = '', onMoreClick }) => {
  const { t } = useTranslation();
  if (!show) return;
  return (
    <Box>
      <FormHint>
        {prefix} {`“${name}”`}
        {t('use method:')} {method}{' '}
        <MoreDetail onClick={onMoreClick}>{t('more detail')}</MoreDetail>
      </FormHint>
    </Box>
  );
};

export const SelectedInfo = ({ isEdit, viewBotDetail, viewStrategyDetail, viewCandleDetail }) => {
  const { t } = useTranslation();
  const strategies = useSelector(selectStrategies, isEqual);
  const bots = useSelector(selectBots, isEqual);
  const { values = {} } = useFormState();
  const selected = strategies.find(
    (item) => item.id.toString() === values?.budget_strategy_id?.toString()
  );
  const bot = bots.find((item) => item.id.toString() === values?.bot_ai_id?.toString());

  const selectedBot = getBotAndMethod(values.bot_ai_id || (!isEdit ?? bots[0]?.id), bots);

  const showBudget = !values.copy_from && SIGNAL_STRATEGY_TYPE.expert !== values.signal_type;

  const showBotDes =
    !selectedBot.copy_from &&
    SIGNAL_STRATEGY_TYPE.botai === values.signal_type &&
    selectedBot.botName;

  const botName = selectedBot?.is_default ? t(selectedBot?.botName) : selectedBot?.botName;
  const budgetName = selected?.is_default ? t(selected?.budget_name) : selected?.budget_name;

  const handleBotView = () => {
    if (bot?.strategy === STRATEGY_TYPE.candlePattern) {
      viewCandleDetail(bot);
    } else {
      viewBotDetail(bot);
    }
  };

  return (
    selected && (
      <Grid.Row>
        <Grid.Cell>
          <Box className="show-detail" display="flex" flexDirection="column" gridGap={5}>
            <Item
              show={showBotDes}
              name={botName}
              method={selectedBot?.methodName}
              onMoreClick={handleBotView}
              prefix="*"
            />
            <Item
              show={showBudget}
              name={budgetName}
              method={selected.method_type}
              onMoreClick={() => viewStrategyDetail(selected)}
              prefix="**"
            />
          </Box>
        </Grid.Cell>
      </Grid.Row>
    )
  );
};

const MoreDetail = styled.span`
  font-weight: 500;
  color: ${colorSuccess};
  cursor: pointer;
  margin-left: 6px;
`;
