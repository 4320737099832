/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
  changeThemeToDark,
  changeThemeToLight,
} from '@/redux/refactor/theme/actions';
import { selectIsDarkMode } from '@/redux/selectors/themeSelector';
import Switch from '@/shared/components/Switch';
import { languageMap } from '@/translations/languages';
import {
  colorGreyScale50,
  colorGreyScale500,
  colorGreyScale900,
  colorText,
  dividerColor,
  sidebarBackground,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import { ArrowRight2 } from 'iconsax-react';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';
import { ExchangeLogo } from '../sidebar/ExchangeLinkedBadge';
import icons from './icons';
import gtag from '@/shared/constants/gtag';
const ga4Stats = window.ga4Stats;

export const menuKeys = {
  Info: 'Info',
  Security: 'Security',
  Kyc: 'Kyc',
  Exchange: 'Exchange',
  Lang: 'Lang',
  Support: 'Support',
  Documents: 'Documents',
  plan: 'plan',
};

const ListItem = ({ dataKey, title, description, icon, onClick, isLast }) => {
  const handleClick = () => onClick(dataKey);
  return (
    <>
      <Li onClick={handleClick}>
        <div className="icon">{icon}</div>
        <div className="info">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
        <div className="arrow-right">
          <ArrowRight2 size={20} color={colorGreyScale500} />
        </div>
      </Li>
      {!isLast && <Divider />}
    </>
  );
};

const DarkModeItem = () => {
  const { t } = useTranslation();
  const isDarkMode = useSelector(selectIsDarkMode, isEqual);
  const { profile } = useSelector(state => ({
    profile: state.user.profile,
  }), isEqual);

  const dispatch = useDispatch();
  const handleChangeTheme = (evt) => {
    evt.stopPropagation();
    if (isDarkMode) {
      ga4Stats(gtag.darkMode.action.darkModeOff, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
      dispatch(changeThemeToLight());
    } else {
      ga4Stats(gtag.darkMode.action.darkModeOn, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
      dispatch(changeThemeToDark());
    }
  };
  return (
    <>
      <Li onClick={handleChangeTheme}>
        <div className="icon">{icons.DarkMode}</div>
        <div className="info">
          <div className="title">{t('Dark Mode')}</div>
          <div className="description">{t('Allow change UI to dark')}</div>
        </div>
        <div className="arrow-right">
          <SwitchStyle onChange={handleChangeTheme} checked={isDarkMode} />
        </div>
      </Li>
    </>
  );
};

const Menu = ({ onMenuSelect }) => {
  const { t } = useTranslation();
  const currentLangCode = useSelector(
    (state) => state.appConfig.languageCode,
    isEqual
  );
  const menu = [
    {
      dataKey: menuKeys.Info,
      title: t('Personal Informations'),
      description: t('Profile foto, name & language'),
      icon: icons.User,
    },
    {
      dataKey: menuKeys.Security,
      icon: icons.ShieldLock,
      title: t('Security'),
      description: t('Secure your account'),
    },
    {
      dataKey: menuKeys.Exchange,
      icon: icons.Exchange,
      title: t('Exchange Connect'),
      description: <ExchangeLogo />,
    },
    {
      dataKey: menuKeys.plan,
      icon: icons.PlanSetting,
      title: t('Setting Plan Default'),
      description: t('Setting options default'),
    },
  ];
  return (
    <>
      <Ul className="mobile-setting">
        {menu.map((item, idx) => (
          <ListItem key={item.dataKey} {...item} onClick={onMenuSelect} />
        ))}
        <ListItem
          dataKey={menuKeys.Lang}
          title={t('Language')}
          description={languageMap[currentLangCode]?.name}
          icon={icons.Global}
          onClick={onMenuSelect}
        />
        <DarkModeItem />
      </Ul>
      <Box mb="12px" />
      <Ul>
        <ListItem
          dataKey={menuKeys.Documents}
          title={t('Documents')}
          description={t('View Gitbook')}
          icon={icons.Documents}
          onClick={onMenuSelect}
        />
        <ListItem
          dataKey={menuKeys.Support}
          title={t('Get help')}
          description={t('Have a problems? Click here')}
          icon={icons.Help}
          onClick={onMenuSelect}
          isLast
        />
      </Ul>
    </>
  );
};

const Ul = styled.ul`
  background: ${sidebarBackground};
  border-radius: 12px;
  padding: 16px;
`;

const Li = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  .info {
    flex: 1;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 160%;
    color: ${colorText};
  }
  .description {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: ${colorGreyScale500};
  }
  .icon {
    rect {
      fill: ${theme('mode', {
        light: '',
        dark: colorGreyScale900,
      })};
    }
    path {
      stroke: ${theme('mode', {
        light: '',
        dark: colorGreyScale50,
      })};
    }
  }
`;

const SwitchStyle = styled(Switch)`
  input {
    height: 24px !important;
    width: 44px !important;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${dividerColor};
  margin: 12px 0;
`;
export default Menu;
