import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { CandlePatternContext } from '../../context';

import CandlePanel from './CandlePanel';
import BubbleErrors from './BubbleErrors';

function BubbleConditions({ isEdit, isReadOnly, sources, currentCandle }) {
  const { t } = useTranslation();
  let conditions, setCondition;

  if (!isReadOnly) {
    ({ conditions, setCondition } = useContext(CandlePatternContext))
  }

  return (
    <div className="BubbleConditions">
      <div className="BubbleConditions__head">
        <div className="BubbleConditions__head-title">
          <div>{!isReadOnly ? t('If the following conditions are satisfied:') : t('Conditions are fulfilled:')}</div>
          {/* <FlickLeft className="flick-left" /> */}
        </div>
        {!isReadOnly && <BubbleErrors isEdit={isEdit} />}
      </div>
      <div className="BubbleConditions__body">
        {_.range(0, 5).map((item) => (
          <CandlePanel
            key={item}
            value={item}
            conditions={!isReadOnly ? conditions : sources}
            setCondition={!isReadOnly ? setCondition : () => {}}
            isEdit={isEdit}
            currentCandle={currentCandle}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
    </div>
  );
}

BubbleConditions.propTypes = {
  isReadOnly: PropTypes.bool,
  isEdit: PropTypes.bool,
  sources: PropTypes.instanceOf(Object),
  currentCandle: PropTypes.number,
};

BubbleConditions.defaultProps = {
  isReadOnly: false,
  isEdit: false,
  sources: {},
  currentCandle: 999
};

export default memo(BubbleConditions);
