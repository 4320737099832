import _ from 'lodash';

export const TAB = {
  FORM: 'form',
  CANDLE: 'candle',
};

export const ORDER_TYPES = {
  BUY: 'b',
  SELL: 's',
  SKIP: 'sk',
};

export const ORDER_NEXT = {
  b: ORDER_TYPES.SELL,
  s: null,
};

export const parseStringToMap = (str = '') => {
  const [conditionStr, candleStr] = str.split('-');
  const [num, order] = candleStr.split(':');
  const candles = conditionStr.split(';').reduce((acc, item) => {
    const arr = item.split(':');
    return {
      ...acc,
      [arr[0]]: arr[1],
    };
  }, {});
  return { num: Number(num), order, candles };
};

export const parseMapToString = (candles) => {
  const sources = [];
  Object.keys(candles).map((key) => {
    const str = Object.keys(candles[key])
      .map((item) => `${item}:${candles[key][item]}`)
      .join(';');
    sources.push(`${str}-${key}`);
  });
  return sources;
};

export const conditionValidate = (bubble, condition) => {
  return condition < bubble;
};

export const inValidRecord = (candle, sources) => {
  const resp = Object.entries(sources).some(
    (item) => !conditionValidate(candle, item[0])
  );
  return resp;
};

export const sortByInvalid = (collection) => {
  return _.sortBy(collection, [
    function (o) {
      return !o.invalid;
    },
  ]);
};
