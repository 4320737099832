import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ModalVerify2FA from '@/shared/components/account/ModalVerify2FA';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountTitle,
  AccountWrap,
  AccountLogo,
  AccountFormContainer,
  AccountGetStarted,
} from '@/shared/components/account/AccountElements';
import RightBanner from '@/shared/components/account/RightBanner';
import { GRANT_TYPE } from '@/config/const';
import { useDispatch, useSelector } from 'react-redux';
import { login, verify2Fa } from '@/redux/refactor/auth/actions';
import { NavLink, Redirect } from 'react-router-dom';
import ModalForgotPassword from '@/shared/components/account/ModalForgotPassword';
import ModalResendEmail from '@/shared/components/account/ModalResendEmail';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import SelectLanguage from '@/shared/components/SelectLanguage';
import { Helmet } from 'react-helmet';
import useCaptcha from '@/hooks/useCaptcha';

const LogIn = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenForgot, setIsOpenForgot] = useState(false);
  const [isOpenResend, setIsOpenResend] = useState(false);

  const dispatch = useDispatch();
  const {
    isRequire2Fa,
    isVerifying,
    token2Fa = {},
    isAuth,
  } = useSelector(state => state.user, _.isEqual);

  const { execute } = useCaptcha();

  const toggleModal2Fa = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalForgot = () => {
    setIsOpenForgot(!isOpenForgot);
  };
  const toggleModalResend = () => setIsOpenResend(!isOpenResend);

  const submit = async ({ username, password }) => {

    const captcha = await execute('login');
    dispatch(
      login({
        grant_type: GRANT_TYPE.PASSWORD,
        email: username,
        password,
        captcha,
      }),
    );
  };

  const onVerify = async (otp) => {
    const captcha = await execute('verify2fa');
    dispatch(
      verify2Fa({
        token: token2Fa.t,
        code: otp,
        captcha,
      }),
    );
  };

  useEffect(() => {
    if (isRequire2Fa) {
      setIsOpen(true);
    }
  }, [isRequire2Fa]);

  if (isAuth) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Helmet title="Login | Quickinvest.ai" />
      <AccountWrap>
        <AccountContent>
          <AccountCard>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <NavLink to="/">
                <AccountLogo />
              </NavLink>
              <Box mb="40px"><SelectLanguage /></Box>
            </Box>
            <AccountFormContainer>
              <AccountHead>
                <AccountTitle>{t('Login to your account')}</AccountTitle>
              </AccountHead>
              <LogInForm onSubmit={submit} onForgotClick={toggleModalForgot} />
              {/* <AccountOr>
                <p>{t('Or login with')}</p>
              </AccountOr>
              <AccountSocial>
                <GoogleAuthBtn />
                <AuthAppleBtn />
              </AccountSocial> */}
              <AccountGetStarted mb="16px">
                {t('Don’t have an account?')}{' '}
                <NavLink to="/register">{t('Get Started')}</NavLink>
              </AccountGetStarted>
              <AccountGetStarted>
                {t('Didn’t receive a confirmation email?')}{' '}
                <button onClick={toggleModalResend} type="button">{t('Request new one')}</button>
              </AccountGetStarted>
            </AccountFormContainer>
          </AccountCard>
        </AccountContent>
        <RightBanner />
      </AccountWrap>
      <ModalVerify2FA
        isOpen={isOpen}
        closeModal={toggleModal2Fa}
        isLoading={isVerifying}
        onSubmit={onVerify}
      />
      <ModalForgotPassword
        isOpen={isOpenForgot}
        closeModal={toggleModalForgot}
      />
      <ModalResendEmail
        isOpen={isOpenResend}
        closeModal={toggleModalResend}
      />
    </Fragment>
  );
};

export default LogIn;
