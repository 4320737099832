import { t } from 'i18next';
import { toast } from 'react-toastify';
import { GRANT_TYPE } from '../../config/const';
import { deleteRequest, get, post, put } from './base';
import { clientId as CLIENT_ID } from './base/axios';

export default {
  /**
   *
   * @param {Object} params
   * @param {string} params.email
   * @param {string} params.password
   * @param {string} params.domain
   * @param {string} params.clientId
   */
  login: ({ email, password, domain, clientId, captcha }) =>
    post(`/${domain}/users/auth/token`, {
      client_id: clientId,
      grant_type: GRANT_TYPE.PASSWORD,
      email,
      password,
      captcha,
    }),
  getUserProfile: (domain) => get(`/${domain}/users/me/profile`),
  verify2Fa: ({ token, code, domain, clientId, captcha }) =>
    post(`/${domain}/users/auth/token-2fa`, {
      token,
      code,
      client_id: clientId,
      captcha,
    }),
  siteCheck: async (params) => {
    try {
      const resp = await post('/plans/site/check', {
        ...params,
        client_id: CLIENT_ID,
      });

      if (!resp?.data?.ok) {
        toast.error(t('Unsupported exchange!'));
      }

      return {
        ok: resp?.data?.ok,
        data: {
          ...(resp?.data?.d?.data || {}),
          domain: params.domain,
        },
      };
    } catch (error) {
      toast.error(t('Unsupported exchange!'));
    }
  },
  linkAccount: () => get('/plans/site/link-account-connected'),
  linkAccounts: () => get('/plans/site/link-accounts'),
  disconnect: (domain, optionDeleteAllPlan) =>
    put('/plans/site/disconnect-v2', {
      domain,
      deleteAllPlan: optionDeleteAllPlan,
    }),
  /**
   * @param {Object} body
   * @param {String} body.domain
   * @param {Object} body.params
   * @param {string} body.params.email
   * @param {string} body.params.client_id
   * @param {string} body.params.password
   * @param {string} body.params.confirmPassword
   * @param {string} body.params.referralCode
   */
  register: (body) => put(`/${body.domain}/users/account/register`, body.params),
  checkActiveEmail: (trackingKey) =>
    get(`/plans/user/check-active-email?trackingKey=${trackingKey}&t=${new Date().getTime()}`),
  prepare2Fa: (domain) => get(`/${domain}/users/2fa/g-authenticator`),
  enable2Fa: (domain, params) => post(`/${domain}/users/2fa/g-authenticator`, params),
  disable2Fa: (domain, params) => deleteRequest(`/${domain}/users/2fa/g-authenticator`, params),
  getCodeActivate2fa: (domain, captcha) =>
    post(`/${domain}/users/2fa/g-authenticator/activate`, { captcha }),
  getCodeDeactivate2fa: (domain, captcha) =>
    post(`/${domain}/users/2fa/g-authenticator/deactivate`, { captcha }),
};
