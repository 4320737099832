import useWindowSize from '@/hooks/useWindowSize';
import { Button } from '@/shared/components/Button';
import SelectLanguage from '@/shared/components/SelectLanguage';
import 'animate.css';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import './index.scss';

import { beginLogin, autoPwdlessLogin } from '@/redux/refactor/auth/actions';
import { isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

const notiAnimation = () => {
  // Remove the transition class
  document.querySelector('.box-4-item--1')?.classList?.remove('animate__bounceInRight');
  document.querySelector('.box-4-item--2')?.classList?.remove('animate__bounceInRight');
  document.querySelector('.box-4-item--3')?.classList?.remove('animate__bounceInRight');

  // Create the observer, same as before:
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.querySelector('.box-4-item--1')?.classList?.add('animate__bounceInRight');
        document.querySelector('.box-4-item--2')?.classList?.add('animate__bounceInRight');
        document.querySelector('.box-4-item--3')?.classList?.add('animate__bounceInRight');
        return;
      }

      document.querySelector('.box-4-item--1')?.classList?.remove('animate__bounceInRight');
      document.querySelector('.box-4-item--2')?.classList?.remove('animate__bounceInRight');
      document.querySelector('.box-4-item--3')?.classList?.remove('animate__bounceInRight');
    });
  });

  observer.observe(document.querySelector('.box-4'));
};

const cloundAnimation = () => {
  // Remove the transition class
  document.querySelector('.box-2-clound-1')?.classList?.remove('animate__slideInRight');
  document.querySelector('.box-2-clound-2')?.classList?.remove('animate__slideInLeft');

  // Create the observer, same as before:
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.querySelector('.box-2-clound-1')?.classList?.add('animate__slideInRight');
        document.querySelector('.box-2-clound-2')?.classList?.add('animate__slideInLeft');
        return;
      }

      document.querySelector('.box-2-clound-1')?.classList?.remove('animate__slideInRight');
      document.querySelector('.box-2-clound-2')?.classList?.remove('animate__slideInLeft');
    });
  });

  observer.observe(document.querySelector('.box-2'));
};

const animalBottomLeftAnimation = () => {
  // Remove the transition class
  document.querySelector('.box-5-img-3')?.classList?.remove('animate__fadeInBottomLeft');

  // Create the observer, same as before:
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.querySelector('.box-5-img-3')?.classList?.add('animate__fadeInBottomLeft');
        return;
      }

      document.querySelector('.box-5-img-3')?.classList?.remove('animate__fadeInBottomLeft');
    });
  });

  observer.observe(document.querySelector('.box-5'));
};

const animalBottomRightAnimation = () => {
  // Remove the transition class
  document.querySelector('.box-6-img-2')?.classList?.remove('animate__fadeInBottomRight');

  // Create the observer, same as before:
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.querySelector('.box-6-img-2')?.classList?.add('animate__fadeInBottomRight');
        return;
      }

      document.querySelector('.box-6-img-2')?.classList?.remove('animate__fadeInBottomRight');
    });
  });

  observer.observe(document.querySelector('.box-6'));
};

const textAnimation = (el, animation) => {
  // Remove the transition class
  document.querySelector(el)?.classList?.remove(animation);

  // Create the observer, same as before:
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.querySelector(el)?.classList?.add(animation);
        return;
      }

      document.querySelector(el)?.classList?.remove(animation);
    });
  });

  observer.observe(document.querySelector(el));
};

const Landing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const gridSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const passwordlessSubmitting = useSelector(({ user }) => user.passwordlessSubmitting, isEqual);

  const handleLogin = (params) => {
    dispatch(beginLogin(history));
  };

  useLayoutEffect(() => {
    notiAnimation();
    cloundAnimation();
    animalBottomLeftAnimation();
    animalBottomRightAnimation();
    textAnimation('.box-5-title', 'animate__rotateInUpLeft');
    textAnimation('.box-6-title', 'animate__rotateInUpLeft');
    textAnimation('.box-7-title', 'animate__rotateInUpLeft');
  }, []);

  useLayoutEffect(() => {
    if (gridSize && ['lg', 'xl', 'xxl'].includes(gridSize)) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [gridSize]);

  useEffect(() => {
    console.log('Landing -> autoPwdlessLogin');
    // Auto popup passwordless
    dispatch(autoPwdlessLogin(history));
  }, []);

  return (
    <div className="landing-page">
      <Helmet title="Welcome to Quickinvest.ai" />
      <div className="landing-page-inner">
        <div className="main-header d-flex justify-content-between align-items-center">
          <NavLink to="/">
            {!isMobile && <img src="/img/logo/logo_light.svg" alt="" />}
            {isMobile && <img src="/img/logo/short_logo_light.svg" alt="" />}
          </NavLink>
          <div className="d-flex">
            <div className="pe-2">
              <Button
                variant="outline-seconday"
                className="btn-sign-up"
                onClick={() => history.push('/register')}
              >
                {t('Sign up')}
              </Button>
            </div>
            <div className="ps-2">
              <Button
                disabled={passwordlessSubmitting}
                isLoading={passwordlessSubmitting}
                variant="success"
                onClick={() => handleLogin()}
              >
                {t('Login')}
              </Button>
            </div>
          </div>
        </div>
        <div className="box-1">
          <Row className="align-items-center">
            <Col lg="5" className="box-1-col-content">
              <div className="box-1-content">
                <h2
                  className="box-1-title"
                  dangerouslySetInnerHTML={{
                    __html: t('Invest confidently with Quickinvest'),
                  }}
                />
                <p className="box-1-text">{t('Quickinvest offers a suite of professional')}</p>
                <Button variant="success" onClick={() => history.push('/register')}>
                  {t('Get Started')}
                </Button>
              </div>
            </Col>
            <Col lg="7" className="box-1-col-media pe-0 ps-0 ps-lg-3">
              <img className="box-1-img" src="/img/landing/1.png" alt="" />
            </Col>
          </Row>
        </div>
        {/* box 1 */}

        <div className="box-2">
          <Row className="justify-content-center">
            <Col lg="6">
              <div className="box-2-content text-center">
                <h2 className="box-2-title">
                  <img
                    className="box-2-clound-1 animate__animated"
                    src="/img/landing/clound_1.svg"
                    alt=""
                  />
                  {t('An all-encompassing solution for your trading needs.')}
                  <img
                    className="box-2-clound-2 animate__animated"
                    src="/img/landing/clound_2.svg"
                    alt=""
                  />
                </h2>
                <p className="box-2-text">{t('Our investment platform delivers a seamless')}</p>
                <Button variant="success" onClick={() => history.push('/register')}>
                  {t('Get Started')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        {/* box 2 */}

        <div className="box-3">
          <Row className="box-3-list align-items-stretch">
            <Col lg="4" className="box-3-item">
              <div className="box-3-item-inner">
                <img className="box-3-item-img" src="/img/landing/2.png" alt="" />
                <h3 className="box-3-item-title">{t('Transparency')}</h3>
                <p className="box-3-item-text">
                  {t(
                    'Transparency is at the core of our values as we utilize insights from popular trading exchanges.'
                  )}
                </p>
              </div>
            </Col>
            <Col lg="4" className="box-3-item">
              <div className="box-3-item-inner">
                <img className="box-3-item-img" src="/img/landing/3.png" alt="" />
                <h3 className="box-3-item-title">{t('Diverse Investing Methods')}</h3>
                <p className="box-3-item-text">{t('Quickinvest is multiple investment')}</p>
              </div>
            </Col>
            <Col lg="4" className="box-3-item">
              <div className="box-3-item-inner">
                <img className="box-3-item-img" src="/img/landing/4.png" alt="" />
                <h3 className="box-3-item-title">{t('Time-saving')}</h3>
                <p className="box-3-item-text">
                  {t(
                    'Our intuitive interface and advanced tools offer time-efficient strategies for wise investment decisions.'
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </div>
        {/* box 3 */}

        <div className="box-4">
          <h3
            className="box-4-title"
            dangerouslySetInnerHTML={{
              __html: t('Ready to invest'),
            }}
          />
          <div className="box-4-ani">
            <img className="box-4-img" src="/img/landing/laptop.svg" alt="" />
            <div className="box-4-item box-4-item--1 d-flex flex-column justify-content-center animate__animated">
              <div className="box-4-item-avatar d-flex justify-content-center align-items-center">
                <span>P1</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <h4 className="box-4-item-title">Buy a new house</h4>
                <span className="box-4-item-dt">09:30 AM</span>
              </div>
              <div className="d-flex justify-content-between">
                <p className="box-4-item-noti">
                  You successfully earned <span className="text-success">$19.75</span>
                </p>
              </div>
            </div>

            <div className="box-4-item box-4-item--2 d-flex flex-column justify-content-center animate__animated justify-content-center animate__delay-slow">
              <div className="box-4-item-avatar d-flex justify-content-center align-items-center">
                <span>P1</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <h4 className="box-4-item-title">Buy a new car</h4>
                <span className="box-4-item-dt">09:30 AM</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="box-4-item-noti">
                  Place $10 order with <span className="text-error">Short 10x</span>
                </p>
                <span className="box-4-item-number">3</span>
              </div>
            </div>

            <div className="box-4-item box-4-item--3 d-flex flex-column justify-content-center animate__animated justify-content-center animate__delay-slower">
              <div className="box-4-item-avatar d-flex justify-content-center align-items-center">
                <span>P1</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <h4 className="box-4-item-title">Buy a new motorbike</h4>
                <span className="box-4-item-dt">09:30 AM</span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="box-4-item-noti">
                  Place $10 order with <span className="text-error">Short 10x</span>
                </p>
                <span className="box-4-item-number">3</span>
              </div>
            </div>
          </div>
          <img className="box-4-whale" src="/img/landing/whale.svg" alt="" />
        </div>
        {/* box 4 */}

        <div className="box-5">
          <Row className="align-items-center">
            <Col lg="6" className="box-5-media text-end ps-0">
              <img className="box-5-img" src="/img/landing/6.png" alt="" />
              <img className="box-5-img-2" src="/img/landing/9.png" alt="" />
            </Col>
            <Col lg="6" className="box-5-content">
              <div className="box-5-wrapper-title">
                <h3
                  className="box-5-title animate__animated"
                  dangerouslySetInnerHTML={{
                    __html: `${t('Expedite your investment returns')} ✨`,
                  }}
                />
              </div>
              <p className="box-5-text">{t('By leveraging advanced investment algorithms')}</p>
              <Button variant="success" onClick={() => history.push('/register')}>
                {t('Start Investing')}
              </Button>
            </Col>
          </Row>
          <img className="box-5-img-3 animate__animated" src="/img/landing/animal_1.svg" alt="" />
        </div>
        {/* box 5 */}

        <div className="box-6">
          <Row className="align-items-center">
            <Col lg="6" className="box-6-content">
              <div className="box-6-wrapper-title">
                <h3 className="box-6-title animate__animated">
                  {t('Your ticket to financial freedom.')}
                </h3>
              </div>
              <p className="box-6-text">{t('We are here to help you attain')}</p>
              <Button variant="success" onClick={() => history.push('/register')}>
                {t('Start Investing')}
              </Button>
            </Col>
            <Col lg="6" className="box-6-media text-start pe-0">
              <img className="box-6-img" src="/img/landing/12.png" alt="" />
              <img className="box-6-img-3" src="/img/landing/15.png" alt="" />
            </Col>
          </Row>
          <img className="box-6-img-2 animate__animated" src="/img/landing/animal_2.svg" alt="" />
        </div>
        {/* box 6 */}

        <div className="compared">
          <div className="compared-head">
            <div className="compared-marquee">
              <div className="compared-marquee-wrapper">
                <div className="compared-marquee-text d-flex align-items-center">
                  <img src="/img/landing/11.png" alt="" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('When compared to others'),
                    }}
                  />
                </div>
                <div className="compared-marquee-text d-flex align-items-center">
                  <img src="/img/landing/11.png" alt="" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('When compared to others'),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="compared-body">
            <div className="compared-table">
              <div className="compared-row compared-row--head">
                <div className="compared-col"></div>
                <div className="compared-col">
                  <img src="/img/logo/logo_dark.svg" alt="" />
                </div>
                <div className="compared-col">{t('Full-service brokers')}</div>
                <div className="compared-col">{t('Other robo-advisors')}</div>
                <div className="compared-col">{t('Human advisors')}</div>
              </div>

              <div className="compared-row">
                <div className="compared-col">{t('Advanced investment algorithms')}</div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/circle.svg" alt="" />
                </div>
              </div>

              <div className="compared-row">
                <div className="compared-col">{t('Efficient high-yield profits')}</div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/circle.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
                <div className="compared-col">
                  <img src="/img/landing/check.svg" alt="" />
                </div>
              </div>

              <div className="compared-row">
                <div className="compared-col">{t('Management fees')}</div>
                <div className="compared-col">0%</div>
                <div className="compared-col">&gt;1%</div>
                <div className="compared-col">0.24-0.50%</div>
                <div className="compared-col">&gt;1%</div>
              </div>

              <div className="compared-row">
                <div className="compared-col"></div>
                <div className="compared-col">
                  <Button variant="success" onClick={() => history.push('/register')}>
                    {t('Start Investing')}
                  </Button>
                </div>
                <div className="compared-col"></div>
                <div className="compared-col"></div>
                <div className="compared-col"></div>
              </div>
            </div>
          </div>
        </div>
        {/* compared */}

        <div className="box-7 d-flex align-items-center">
          <div className="box-7-content">
            <div className="box-6-wrapper-title">
              <h3
                className="box-7-title animate__animated"
                dangerouslySetInnerHTML={{
                  __html: t('We are here to help'),
                }}
              />
            </div>
            <p className="box-7-text">{t('If you encounter any issues or have any questions')}</p>
            <div className="box-7-socials d-flex">
              <a
                href="https://www.youtube.com/@QuickInvest"
                target="_blank"
                rel="noreferrer"
                className="box-7-socials-item d-flex align-items-center"
              >
                <img src="/img/landing/youtube.svg" alt="" />
                <span>Youtube</span>
              </a>
              {/* <div className="box-7-socials-item d-flex align-items-center">
								<img src="/img/landing/tele.svg" alt="" />
								<span>Telegram</span>
							</div> */}
            </div>
          </div>
        </div>
        {/* box 7 */}

        <div className="box-8">
          <div className="d-flex justify-content-between align-items-center">
            <h3
              className="box-8-title"
              dangerouslySetInnerHTML={{
                __html: t('Join our investment community and start earning'),
              }}
            />
            <NavLink to="/register">
              <img className="box-8-img" src="/img/landing/arrow.svg" alt="" />
            </NavLink>
          </div>
        </div>
        {/* box 8 */}

        <div className="main-footer">
          <div className="main-footer-inner">
            <Row>
              <Col lg="3">
                <NavLink to="/">
                  <img className="main-footer-logo" src="/img/logo/logo_dark.svg" alt="" />
                </NavLink>
                <p className="main-footer-copyright">{t('All rights reserved')}</p>
              </Col>
              <Col lg="3">
                <h3 className="main-footer-title text-success">{t('Contact')}</h3>
                <ul className="list-unstyled">
                  <li>
                    <a href="mailto:contact@quickinvest.ai">contact@quickinvest.ai</a>
                  </li>
                </ul>
              </Col>
              <Col lg="3">
                <h3 className="main-footer-title text-success">{t('Social Chanel')}</h3>
                <ul className="list-unstyled">
                  {/* <li><a href="#">Telegram</a></li> */}
                  <li>
                    <a href="https://www.youtube.com/@QuickInvest" target="_blank" rel="noreferrer">
                      Youtube
                    </a>
                  </li>
                  {/* <li><a href="#">Twitter</a></li> */}
                </ul>
              </Col>
              <Col lg="3">
                <div className="select-language">
                  <SelectLanguage />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
