import { BET_TYPE, SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { convertMaskToNum } from './validator';

export const createBody = (values, accountType) => {
  const isWinStreak = values.waitSignalType === BET_TYPE.win;
  const body = {
    name: values.name.trim(),
    account_type: accountType,
    budget: convertMaskToNum(values.budget),
    expert_mode: values.expert_mode,
    private_mode: values.private_mode,
    budget_strategy_id: values.budget_strategy_id,
    signal_type: values.signal_type,
    bot_ai_id: values.bot_ai_id,
    base_amount: convertMaskToNum(values.base_amount),
    reverse_signal: values.reverse_signal,
    wait_signal: values.wait_signal,
    step0_option: values.step0_option ? values.ignore_budget_radio : '',
    win_streak: isWinStreak ? values.win_streak : 0,
    lose_streak: !isWinStreak ? values.lose_streak : 0,
    no_of_entries: values.isUltilFinishBudget === 'a' ? -1 : values.no_of_entries,
    send_signal: false,
    experts: [],
    telegram_chanels: [],
    community_signals: [],
    take_profit: '',
    stop_loss: '',
    stop_loss_positive: 0,
    pause_win_streak: 0,
    pause_lose_streak: 0,
    total_order: 0,
    sub_take_profit: 0,
    sub_stop_loss: 0,
    reset_budget_at_tp_sl: values.reset_budget_at_tp_sl,
    reset_budget_at_resume: values.reset_budget_at_resume,
    reset_budget_at_reprofit: values.reset_budget_at_reprofit,
    sub_win_streak: Number(values.sub_win_streak) || 0,
    sub_lose_streak: Number(values.sub_lose_streak) || 0,
  };

  if (values.id) {
    body.id = values.id;
  }

  if (values.showSubProfit && values.subProfit) {
    body.sub_take_profit = convertMaskToNum(values.sub_take_profit);
    body.sub_stop_loss = convertMaskToNum(values.sub_stop_loss);
  }

  if (values.reachTarget) {
    body.take_profit = convertMaskToNum(values.take_profit);
    body.stop_loss = convertMaskToNum(values.stop_loss);
    body.stop_loss_positive = convertMaskToNum(values.stop_loss_positive);
    body.pause_win_streak = Number(values.pause_win_streak) || 0;
    body.pause_lose_streak = Number(values.pause_lose_streak) || 0;
    body.total_order = Number(values.total_order) || 0;
  }

  if (values.signal_type === SIGNAL_STRATEGY_TYPE.expert) {
    body.experts = values.experts;
    body.expert_mode = false;
  }

  if (values.signal_type === SIGNAL_STRATEGY_TYPE.telegram && values.telegram_chanels && values.telegram_chanels.length > 0) {
    body.telegram_chanels = values.telegram_chanels.reduce((acc, item) => [...acc, item.value], []);
  }

  if (values.signal_type === SIGNAL_STRATEGY_TYPE.community && values.community_signals && values.community_signals.length > 0) {
    body.community_signals = values.community_signals.reduce((acc, item) => [...acc, item.value], []);
  }

  if (values.enableOrderIndex) {
    body.order_index = Number(values.order_index);
  }

  return body;
};
