
import planApi from '@/utils/api/planApi';
import { createQuery } from 'react-query-kit';

export default createQuery({
  primaryKey: 'getTotalHistory',
  queryFn: async () => {
    const { data = {} } = await planApi.getTotalHistory();
    return data?.d || {};
  },
});
