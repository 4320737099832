/* eslint-disable react/prop-types */
import useAuth from '@/hooks/useAuth';
import { languageMap } from '@/translations/languages';
import { Box } from '@material-ui/core';
import {
  Global,
  Logout,
  ProfileCircle,
  Moon,
} from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Image, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { changeThemeToDark, changeThemeToLight } from '@/redux/refactor/theme/actions';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { NavDropdownItemStyle, NavDropdownStyle } from '@/shared/components/NavDropdown';
import TopbarUserInfoItem from './TopbarUserInfoItem';
import gtag from '@/shared/constants/gtag';
const ga4Stats = window.ga4Stats;

const NavDropdownUserInfo = withTranslation()(({ toggleChangeLang, t }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const navigate = useHistory();
  const currentLangCode = useSelector(state => state.appConfig.languageCode, isEqual);
  const {
    mode,
    profile
  } = useSelector(
    state => ({
      mode: state.theme.className,
      profile: state.user.profile,
    }),
    isEqual,
  );

  const handleChangeTheme = () => {
    if (mode === 'light') {
      ga4Stats(gtag.darkMode.action.darkModeOn, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
      dispatch(changeThemeToDark());
    } else {
      ga4Stats(gtag.darkMode.action.darkModeOff, gtag.darkMode.category, gtag.darkMode.label, profile?.uid);
      dispatch(changeThemeToLight());
    }
  };

  return (
    <NavDropdownStyle
      align="end" 
      title={<TopbarUserInfoItem />}
    >
      <NavDropdownItemStyle onClick={() => navigate.push('/settings')}>
        <ProfileCircle />{t('Settings')}
      </NavDropdownItemStyle>
      <NavDropdownItemStyle onClick={toggleChangeLang}>
        <Box flex={1} display="flex" alignItems="center">
          <Box gridGap="16px" flex={1} display="flex" alignItems="center">
            <Global /> {t('Language')}
          </Box>
          <Box display="flex" alignItems="center" gridGap="5px">
            <FlagStyle width="18px" src={languageMap[currentLangCode]?.flag} />
            {languageMap[currentLangCode]?.code.toUpperCase()}
          </Box>
        </Box>
      </NavDropdownItemStyle>
      <NavDropdownItemStyle onClick={handleChangeTheme}>
        <Box gridGap="16px" flex={1} display="flex" alignItems="center">
          <Box gridGap="16px" flex={1} display="flex" alignItems="center">
            <Moon />{t('Dark mode')}
          </Box>
          <SwitchWrapper>
            <Form.Check
              type="switch"
              id="switch-dark-mode"
              checked={mode === 'dark'}
              readOnly
            />
            <SwitchMask />
          </SwitchWrapper>
        </Box>
      </NavDropdownItemStyle>
      <NavDropdownItemStyle onClick={logout}>
        <Logout />{t('Logout')}
      </NavDropdownItemStyle>
    </NavDropdownStyle>
  );
});

const FlagStyle = styled(Image)`
  margin-left: 6px;
  border-radius: 4px;
`;

const SwitchWrapper = styled(Form)`
  position: relative;
`;

const SwitchMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
export default NavDropdownUserInfo;
