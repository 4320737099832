import ModalResult from '@/containers/Dashboard/BackTest/modal/ModalResult';
import {
  getBots,
  getSignalType,
  getStrategies,
  getTelegramChannels,
} from '@/redux/refactor/common/actions';
import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { getNotifyPlans } from '@/redux/refactor/notification/actions';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import gtag from '@/shared/constants/gtag';

import { DrawerStyle, drawerProps } from '../../form/drawer/Elements';
import { FormContainer } from './components/FormContainer';
import { createBody } from './createBody';

const ga4Stats = window.ga4Stats

const DrawerCreatePlan = ({
  isOpen,
  onClose,
  onActivePlan,
  currentPlan,
  isEdit,
  onSuccess,
}) => {
  const isPc = useMediaQuery(Devices.pc);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const testID = useRef(null);
  const [isOpenBT, setIsOpenBT] = useState(false);
  const handleCloseBT = () => setIsOpenBT(false);
  const handleOpenBT = (id) => {
    testID.current = id;
    setIsOpenBT(true);
  };

  const accountType = useSelector(
    (state) => (isEdit ? currentPlan.account_type : state.wallet.accountType),
    isEqual
  );

  const userActivityTracker = useSelector(
    (state) => state.userActivityTracker,
    isEqual
  )

  const [isFetching, setIsFetching] = useState(false);

  const handleSuccessCallback = () => {

    onSuccess();
    onClose();
  };

  const submitCreate = async (values = {}) => {
    const body = createBody(values, accountType);
    setIsFetching(true);
    planApi
      .createPlan(body, 2)
      .then((res) => {
        if (res?.data?.ok) {
          toast.success(t('Create the plan successfully!'));
          onSuccess();
          dispatch(getTotalPlan(accountType));
          dispatch(getNotifyPlans());
          onClose();
          if (values.signal_type !== SIGNAL_STRATEGY_TYPE.manual) {
            setTimeout(() => {
              onActivePlan(res?.data?.d, onSuccess);
            }, 500);
          }

          if (userActivityTracker.isClickCreatePlanFromLeaderPlan) {
            ga4Stats(gtag.dashboard.action.createPlanFromLeaderPlan, gtag.dashboard.category, gtag.dashboard.label, res.data.id);
          } else if (userActivityTracker.isClickCreatePlanFromTopSignal) {
            ga4Stats(gtag.signal.action.createPlanFromTopSignal, gtag.signal.category, gtag.signal.label, res.data.id);
          } else if (userActivityTracker.isClickCreatePlanFromMultiTopSignal) {
            ga4Stats(gtag.signal.action.createPlanFromMultiTopSignal, gtag.signal.category, gtag.signal.label, res.data.id);
          }

          ga4Stats(gtag.plan.action.createPlan, gtag.plan.category, accountType, res.data.id);
        } else {
          toast.error(t(res?.data?.m));
        }
      })
      .finally(() => setIsFetching(false));
  };

  const submitEdit = async (values = {}) => {
    const body = createBody(values, accountType);
    setIsFetching(true);
    planApi
      .editPlan(body, 2)
      .then((res) => {
        if (res?.data?.ok) {
          toast.success(t('Edit the plan successfully!'));
          onSuccess();
          dispatch(getNotifyPlans());
          onClose();
          ga4Stats(gtag.plan.action.editPlan, gtag.plan.category, accountType, res.data.id);
        } else {
          toast.error(t(res?.data?.m));
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const submit = (values = {}) => {
    if (isEdit) {
      submitEdit(values);
    } else {
      submitCreate(values);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getSignalType());
      dispatch(getBots());
      dispatch(getTelegramChannels(false));
      dispatch(getStrategies());
    }
  }, [isOpen]);

  const modalProp = useCallback(() => {
    return isPc ? drawerProps.right : drawerProps.mobile;
  }, [isPc]);

  return (
    <DrawerStyle
      ModalProps={{ keepMounted: false }}
      open={isOpen}
      onClose={onClose}
      {...modalProp()}
    >
      <FormContainer
        currentPlan={currentPlan}
        onClose={onClose}
        submit={submit}
        isFetching={isFetching}
        onOpenBT={handleOpenBT}
        isEdit={isEdit}
      />
      <ModalResult
        show={isOpenBT}
        onHide={handleCloseBT}
        backtestId={testID.current?.id}
        onSuccessCallback={handleSuccessCallback}
      />
    </DrawerStyle>
  );
};

DrawerCreatePlan.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onActivePlan: PropTypes.func.isRequired,
  currentPlan: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.func,
};

DrawerCreatePlan.defaultProps = {
  currentPlan: {},
  onSuccess: () => {},
};

export default memo(DrawerCreatePlan);
