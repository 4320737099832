import { handleActions } from 'redux-actions';
import { PAGINATION_DEFAULT } from '@/shared/constants';

import {
  setBots,
  setChannelLoading,
  setCurrentChannel,
  setSignalType,
  setStrategies,
  setTelegramChannels,
  setTelegramMessage,
  setIsCommunitySignal,
  setMessageLoading,
  setLastIdMessage,
  setSearchText
} from './actions';

const defaultState = {
  signalType: [],
  bots: [],
  telegramChannels: [],
  strategies: [],
  channelLoading: true,
  telegramMessages: [],
  messageLoading: false,
  currentChannel: null,
  isCommunitySignal: false,
  lastIdMessage: null,
  searchText: ''
};

export default handleActions(
  {
    [setSignalType]: (state, { payload }) => ({
      ...state,
      signalType: payload,
    }),
    [setBots]: (state, { payload }) => ({
      ...state,
      bots: payload,
    }),
    [setTelegramChannels]: (state, { payload }) => ({
      ...state,
      telegramChannels: payload,
    }),
    [setStrategies]: (state, { payload }) => ({
      ...state,
      strategies: payload,
    }),
    [setChannelLoading]: (state, { payload }) => ({
      ...state,
      channelLoading: payload,
    }),
    [setTelegramMessage]: (state, { payload }) => ({
      ...state,
      telegramMessages: payload,
    }),
    [setMessageLoading]: (state, { payload }) => ({
      ...state,
      messageLoading: payload,
    }),    
    [setIsCommunitySignal]: (state, { payload }) => ({
      ...state,
      isCommunitySignal: payload,
    }),    
    [setCurrentChannel]: (state, { payload }) => ({
      ...state,
      currentChannel: payload,
    }),
    [setLastIdMessage]: (state, { payload }) => ({
      ...state,
      lastIdMessage: payload,
    }),
    [setSearchText]: (state, { payload }) => ({
      ...state,
      searchText: payload,
    }),
  },
  defaultState
);
