import { Box, Drawer } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { timeFormat } from '@/shared/helpers';
import formatNumber from '@/shared/helpers/formatNumber';
import DrawerCloseIcon from '@/shared/img/drawer-close.svg';
import StatusIcon from '@/shared/img/status-icon.svg';
import WalletIcon from '@/shared/img/wallet-icon.svg';
import AmountIcon from '@/shared/img/amount-icon.svg';
import { getTxUrl, TRANSACTION_TYPE } from '@/config/const';
import { CardDivider } from '@/shared/components/Card';
import { t } from 'i18next';
import {
 colorText, Devices, dividerColor, sidebarBackground, 
} from '@/utils/palette';
import theme from 'styled-theming';
import TransactionIcon from './TransactionIcon';

import './style.scss';


export const getTransactionType = translate => (type) => {
  if (type === TRANSACTION_TYPE.INTERNAL_WITHDRAW) {
    return translate('Withdraw USDT (Internal)');
  }
  if (type === TRANSACTION_TYPE.INTERNAL_DEPOSIT) {
    return translate('Deposit USDT (Internal)');
  }
  return translate(type?.concat(' USDT (Bep20)'));
};

/**
 *
 * @returns [Title, TxID, isLink]
 */
export const getTransactionInfo = (value) => {
  if (value?.type === TRANSACTION_TYPE.BUY_AGENCY_LICENSE 
    || value?.type === TRANSACTION_TYPE.TRANSFER_IN
    || value?.type === TRANSACTION_TYPE.TRANSFER_OUT) {
    return [null, null, false];
  }

  const txid = value?.txid ? JSON.parse(value.txid) : {};
  if (txid?.ReceiverNickName) {
    return ['Recipient nickname', txid.ReceiverNickName, false];
  }
  if (value?.type === TRANSACTION_TYPE.INTERNAL_DEPOSIT) {
    return ['Sender nickname', txid?.TransactionId, false];
  }
  return ['TxID', txid?.TransactionId, true];
};

const DrawerTransDetail = ({ value, isOpen, onClose }) => {
  const usdtAmount = formatNumber.sliceDecimalFloat(value?.amount);
  const tranInfo = getTransactionInfo(value);
  const bscUrl = useMemo(() => getTxUrl(tranInfo[1]), [tranInfo]);
  return (
    <DrawerStyle
      anchor="right"
      open={isOpen}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      className="transaction-detail__drawer"
    >
      <DrawerContent>
        <DrawerHeader mb="24px">
          <Box>
            <TransactionIcon size={48} type={value?.type} />
          </Box>
          <Box flex={1}>
            <WithdrawType>{t(value?.type)}</WithdrawType>
            <WithdrawTime>{timeFormat(value?.ts)}</WithdrawTime>
          </Box>
          <Close onClick={onClose}>
            <DrawerCloseIcon />
          </Close>
        </DrawerHeader>
        <Box>
          <USDTAmount>{usdtAmount} USDT</USDTAmount>
        </Box>
        <CardDivider />
        <DrawerHeader>
          <Box>
            <StatusIcon className="icon-svg" />
          </Box>
          <Box>
            <Subtitle>{t('Status')}</Subtitle>
            <Title>{t(value?.status)}</Title>
          </Box>
        </DrawerHeader>
        <CardDivider />
        <DrawerHeader>
          <Box>
            <AmountIcon className="icon-svg" />
          </Box>
          <Box>
            <Subtitle>{t('Amount')}</Subtitle>
            <Title>
              {formatNumber.sliceDecimalFloat(value?.amount)}
              USDT
            </Title>
          </Box>
        </DrawerHeader>
        {tranInfo[0] && (
          <>
            <CardDivider />
            <DrawerHeader>
              <Box>
                <WalletIcon className="icon-svg" />
              </Box>
              <Box overflow="hidden">
                <Subtitle>{t(tranInfo[0])}</Subtitle>
                {!tranInfo[2] && <Title>{tranInfo[1]}</Title>}
                {tranInfo[2] && (
                  <TitleLink href={bscUrl} target="_blank">
                    {tranInfo[1]}
                  </TitleLink>
                )}
              </Box>
            </DrawerHeader>
          </>
        )}
      </DrawerContent>
    </DrawerStyle>
  );
};

DrawerTransDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object),
};
DrawerTransDetail.defaultProps = {
  value: {
    type: 'Withdraw',
    amount: 0,
  },
};

const DrawerStyle = styled(Drawer)`
  .MuiPaper-root {
    box-shadow: none;
    padding-top: 55px;
    @media ${Devices.pc} {
      padding-top: 72px;
    }
  }
  .icon-svg {
    circle {
      fill: ${theme('mode', {
        light: '',
        dark: '#111827',
      })};
    }

    path {
      stroke: ${theme('mode', {
        light: '',
        dark: '#fff',
      })};
    }
  }
`;
const DrawerContent = styled(Box)`
  width: 360px;
  background: ${sidebarBackground};
  flex: 1;
  padding: 30px;
  border-left: 1px solid ${dividerColor};
  border-top: 2px solid ${dividerColor};
`;

export const DrawerHeader = styled(Box)`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;
export const WithdrawType = styled(Box)`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: ${colorText};
`;
export const WithdrawTime = styled(Box)`
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #a6a9b9;
`;
const Close = styled(Box)`
  cursor: pointer;
  svg {
    path {
      stroke: ${theme('mode', {
        light: '#191D31',
        dark: '#A0AEC0',
      })}!important
    }
  }
`;

export const USDTAmount = styled(Box)`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: ${colorText};
`;

export const Subtitle = styled(Box)`
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #a6a9b9;
`;

export const Title = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: ${colorText};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`;
export const TitleLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: ${colorText};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-decoration: underline;
`;

export default DrawerTransDetail;
