import { createSelector } from 'reselect';

export const selectState = (state) => state.common;
export const selectSignalType = createSelector(
  selectState,
  (item) => item.signalType || []
);
export const selectBots = createSelector(
  selectState,
  (item) => item.bots || []
);
export const selectTelegramChannels = createSelector(
  selectState,
  (item) => item.telegramChannels || []
);
export const selectStrategies = createSelector(
  selectState,
  (item) => item.strategies || []
);
