import { DrawerStyle, Form, drawerProps } from '@/shared/components/form/drawer/Elements';
import { convertMaskToNum } from '@/shared/components/portfolio/form/validator';
import planApi from '@/utils/api/planApi';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import PlanCrudContext from '@/context';
import {
  getBots as getCommonBots,
  getStrategies as getCommonStrategies,
} from '@/redux/refactor/common/actions';
import { getTotalPlan } from '@/redux/refactor/dashboard/actions';
import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import gtag from '@/shared/constants/gtag';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CardItem from './CardItem';

const ga4Stats = window.ga4Stats;

const initialValues = {
  baseAmount: 1,
};

const mutators = {
  setId: (args, state, utils) => {
    utils.changeValue(state, 'id', () => args[0]);
  },
  setPlanName: (args, state, utils) => {
    utils.changeValue(state, 'planName', () => args[0]);
  },
  setBaseAmount: (args, state, utils) => {
    utils.changeValue(state, 'baseAmount', () => args[0]);
  },
  setTakeProfit: (args, state, utils) => {
    utils.changeValue(state, 'takeProfit', () => args[0]);
  },
  setStopLoss: (args, state, utils) => {
    utils.changeValue(state, 'stopLoss', () => args[0]);
  },
};

const ModalResult = ({ show, onHide, backtestId, onSuccessCallback }) => {
  const planCrud = useContext(PlanCrudContext);
  const dispatch = useDispatch();
  const isPc = useMediaQuery(Devices.pc);
  const { accountType, userActivityTracker } = useSelector(
    (state) => ({
      accountType: state.wallet.accountType,
      userActivityTracker: state.userActivityTracker
    }),
    isEqual
  );
  const { mutate, isLoading } = useMutation({
    mutationFn: async (values) => {
      const { data } = await planApi.createPlanByBacktest({
        ...values,
        takeProfit: convertMaskToNum(values.takeProfit),
        stopLoss: convertMaskToNum(values.stopLoss),
        type: accountType,
      });

      if (userActivityTracker.backtestSignalType === SIGNAL_STRATEGY_TYPE.telegram) {
        ga4Stats(gtag.dashboard.action.createPlanFromBacktestTelegram, gtag.dashboard.category, gtag.dashboard.label, data.id);
      } else if (userActivityTracker.backtestSignalType === SIGNAL_STRATEGY_TYPE.botai) {
        ga4Stats(gtag.dashboard.action.createPlanFromBacktestBotAi, gtag.dashboard.category, gtag.dashboard.label, data.id);
      }      

      return data;
    },
    onSuccess: (resp) => {
      if (resp?.ok) {
        onHide();
        dispatch(getTotalPlan(accountType));
        dispatch(getCommonStrategies());
        dispatch(getCommonBots());
        onSuccessCallback();
        setTimeout(() => {
          planCrud.handleActive(resp?.d, onSuccessCallback);
        }, 500);
      } else {
        toast.error(resp?.m);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });
  const drawerProp = isPc ? drawerProps.right : drawerProps.mobile;
  return (
    <>
      <DrawerStyle {...drawerProp} open={show} onClose={onHide}>
        <Form onSubmit={mutate} mutators={mutators} initialValues={initialValues}>
          {(props) => (
            <CardItem
              backtestId={backtestId}
              formProps={props}
              isSubmitting={isLoading}
              onHide={onHide}
            />
          )}
        </Form>
      </DrawerStyle>
    </>
  );
};

ModalResult.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  backtestId: PropTypes.number,
  onSuccessCallback: PropTypes.func,
};

ModalResult.defaultProps = {
  onSuccessCallback: () => null,
};

export default ModalResult;
