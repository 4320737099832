import GlobalNotification from '@/shared/components/GlobalNotification';
import Icons from '@/shared/components/icons';
import { Devices, colorSuccess, dividerColor } from '@/utils/palette';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DrawerSettings from '../components/drawer-settings';
import ModalLanguage from '../components/drawer-settings/ModalLanguage';
import {
  MobileLogo,
  TopbarContainer,
  TopbarLeft,
  TopbarRight,
} from '../components/topbar/BasicTopbarComponents';
import TopbarLeftContent from '../components/topbar/TopbarLeftContent';
import TopbarUserInfo from '../components/topbar/TopbarUserInfo';
import TopbarWallet from '../components/topbar/TopbarWallet';

const Topbar = ({ collapsed, showOnMobile }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [openSettings, setOpenSettings] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const toggleDrawer = () => {
    setOpenSettings(!openSettings);
  };
  const toggleModal = () => {
    setOpenLang(!openLang);
  };
  const toggleModalMobile = () => {
    setOpenLang(!openLang);
    setTimeout(toggleDrawer, 500);
  };
  const goBack = () => {
    history.goBack();
    setOpenSettings(true);
  };
  return (
    <>
      <TopbarContainer
        zIndex={102}
        showonmobile={+showOnMobile}
        className="main-header"
      >
        <TopbarLeft collapsed={Number(collapsed)}>
          <Link to="/">
            <MobileLogo short="true" width={32} height={32} />
          </Link>
          <TopbarLeftContent />
        </TopbarLeft>
        <TopbarRight>
          <TopbarWallet />
          {/* <OrderNotificationIcon /> */}
          <GlobalNotification />
          <Divider />
          <TopbarUserInfo
            toggleDrawer={toggleDrawer}
            toggleChangeLang={toggleModal}
          />
        </TopbarRight>
        <DrawerSettings
          open={openSettings}
          onClose={toggleDrawer}
          toggleChangeLang={toggleModalMobile}
        />
        <ModalLanguage open={openLang} onClose={toggleModal} />
      </TopbarContainer>
      <TopbarContainer zIndex={101} showonmobile={+!showOnMobile}>
        <Back onClick={goBack}>
          <Icons.ChevronLeftIcon /> {t('Back')}
        </Back>
      </TopbarContainer>
    </>
  );
};

Topbar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  showOnMobile: PropTypes.bool.isRequired,
};

const Divider = styled(Box)`
  width: 1px;
  height: 24px;
  background-color: ${dividerColor};
  @media ${Devices.pc} {
    height: 40px;
  }
`;

const Back = styled(Box)`
  display: flex;
  align-items: center;
  color: ${colorSuccess};
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  padding: 0 16px;
  svg {
    height: 24px;
    width: 24px;
    path {
      stroke: ${colorSuccess};
    }
  }
`;

export default Topbar;
