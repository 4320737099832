import { STORAGE_KEY } from '@/config/const';
import { SIGNAL_STRATEGY_TYPE_NAME } from '@/shared/constants';
import { ArrowCircleDown2 } from 'iconsax-react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import 'moment/locale/vi';
import PropTypes from 'prop-types';

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharePlanCanvas from '../../SharePlanCanvas';
import './index.scss';

const PopupSocialPlan = ({ show, setShow, currentPlan }) => {
  const { t } = useTranslation();
  const downloadcanvasEl = useRef('');
  const { screenSize } = useSelector(
    (state) => ({
      screenSize: state.theme.screenSize,
    }),
    _.isEqual
  );
  
  const [timePlan, setTimePlan] = useState('');
  const [profit, setProfit] = useState(0);
  const [percentProfit, setPercentprofit] = useState(0);
  const [titleCanvas, setTitleCanvas] = useState('');

  const downloadCanvas = (e) => {
    e.preventDefault();
    const dataURL = document.getElementById('main-canvas').toDataURL();
    downloadcanvasEl.current.href = dataURL;
    downloadcanvasEl.current.download = `${currentPlan.name}.png`;
    downloadcanvasEl.current.click();
  };

  useEffect(() => {
    if (currentPlan.created_at) {
      const createdTime = moment(currentPlan.created_at);
      const days = moment().diff(createdTime, 'days');
      if (currentPlan.profit < currentPlan.current_profit) {
        setTimePlan(t('In 24h'));
      } else {
        if (days > 7) {
          setTimePlan(t('In 7 days'));
        } else {
          setTimePlan(
            `${t('In')} ${createdTime
              .locale(localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE))
              .fromNow(true)}`
          );
        }
      }
    }
  }, [currentPlan.created_at]);

  useEffect(() => {
    if (currentPlan.budget) {
      const estimateProfit =
        currentPlan.profit > currentPlan.current_profit
          ? currentPlan.profit
          : currentPlan.current_profit;
      setProfit(estimateProfit);

      setPercentprofit(Math.round((Math.abs(estimateProfit) / currentPlan.budget) * 10000) / 100);
    }
  }, [currentPlan.profit, currentPlan.current_profit, currentPlan.budget]);

  useEffect(() => {
    if (currentPlan?.name) {
      let planName = currentPlan?.name;
      if (planName && planName.length > 15) {
        planName = planName.slice(0, 15).concat('...');
      }
      const signalStrategy = t(SIGNAL_STRATEGY_TYPE_NAME[currentPlan?.signal_strategy?.type]);
      const methodName = currentPlan?.method_name;
      setTitleCanvas(`${planName}  |  ${signalStrategy}  |  ${methodName}`);
    }
  }, [currentPlan?.name, currentPlan?.signal_strategy?.type, currentPlan?.method_name]);

  return (
    <>
      {titleCanvas && (
        <Modal show={show} onHide={() => setShow(false)} className="popup-social-plan" centered>
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <canvas id="canvas-qrcode" />
            <SharePlanCanvas
              id="main-canvas"
              screenSize={screenSize}
              currentPlan={currentPlan}
              profit={profit}
              percentProfit={percentProfit}
              titleCanvas={titleCanvas}
              timePlan={timePlan}
            />
            <a ref={downloadcanvasEl} href="/" className="link-download">
              {t('Download')}
            </a>
            {screenSize.isPc && (
              <Button
                variant="dark"
                onClick={downloadCanvas}
                className="btn-download d-flex align-items-center"
              >
                <ArrowCircleDown2 size="24" className="me-2" />
                {t('Download')}
              </Button>
            )}
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      )}
    </>
  );
};

PopupSocialPlan.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
};

export default PopupSocialPlan;
