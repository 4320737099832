import dashboardApi from '@/utils/api/dashboardApi';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';

import gtag from '@/shared/constants/gtag';
import { getTimePeriods } from '@/shared/helpers';

export const getTotalRequest = createAction('dashboard_getTotalRequest');
export const getTotalSuccess = createAction('dashboard_getTotalSuccess');
export const getTotalFailed = createAction('dashboard_getTotalFailed');
export const setAccountType = createAction('dashboard_setAccountType');
export const setChartData = createAction('dashboard_setChartData');
export const setChartDataLoading = createAction(
  'dashboard_setChartDataLoading'
);
export const setTotalPlan = createAction('dashboard_setTotalPlan');
export const setTotalPlanLoading = createAction(
  'dashboard_setTotalPlanLoading'
);

const ga4Stats = window.ga4Stats;

/**
 *
 * @param {string} accountType
 * @param {string} type
 * @param {string} includeManual
 * @returns
 */
export const getTotal =
  ({ accountType, type = 'TODAY', includeManual = true, planId }) =>
  async (dispatch) => {
    try {
      dispatch(getTotalRequest(type));
      const {start, end} = getTimePeriods(type);

      const { data = {} } = await dashboardApi.getTotal(
        accountType,
        start,
        end,
        includeManual,
        planId
      );
      const { ok, d, m } = data;
      if (ok) {
        dispatch(getTotalSuccess(d));
      } else {
        toast.error(m);
        dispatch(getTotalFailed());
      }
    } catch (error) {
      dispatch(getTotalFailed());
    }
  };

export const getChartData =
  (
    accountType,
    fromDate = moment().startOf('month').format('YYYY-MM-DD'),
    toDate = moment().endOf('month').format('YYYY-MM-DD'),
    planId
  ) =>
  (dispatch) => {
    dispatch(setChartDataLoading(true));
    dispatch(setChartData([]));
    dashboardApi
      .getStatisticsChart(accountType, fromDate, toDate, planId)
      .then((res) => {
        if (res.data.ok) {
          const data = _.reverse(res.data.d);
          dispatch(setChartData(data));
          dispatch(setChartDataLoading(false));

          if (planId) {
            ga4Stats(gtag.plan.action.statisticsPlan, gtag.plan.category, gtag.plan.label);
          } else {
            ga4Stats(gtag.plan.action.statisticsListPlan, gtag.plan.category, gtag.plan.label);
          }          
        }
      });
  };

export const getTotalPlan = () => async (dispatch) => {
  dispatch(setTotalPlanLoading(true));
  Promise.all([
    dashboardApi
      .getTotalPlan('LIVE')
      .then((resp) => resp?.data.d?.total_plan || 0),
    dashboardApi
      .getTotalPlan('DEMO')
      .then((resp) => resp?.data.d?.total_plan || 0),
  ])
    .then(([LIVE, DEMO]) => dispatch(setTotalPlan({ LIVE, DEMO })))
    .finally(() => {
      dispatch(setTotalPlanLoading(false));
    });
};
