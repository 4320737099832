import i18n from 'i18next';
import { toast } from 'react-toastify';
import { isSTG } from '@/config/const';
import authApi from '@/utils/api/authApi';
import { createAction } from 'redux-actions';
import { initAuthReducer } from '../auth/actions';
import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

const userConfigs = {
  reset_budget_at_tp_sl: {
    on: 'resetReachingTpSlOn',
    off: 'resetReachingTpSlOff'
  },
  reset_budget_at_resume: {
    on: 'resetResumeOn',
    off: 'resetResumeOff'
  },
  reset_budget_at_reprofit: {
    on: 'resetPnlOn',
    off: 'resetPnlOff'
  },
  reset_budget_at_initial_profit_day: {
    on: 'resetDailyOn',
    off: 'resetDailyOff'
  },
  reverse_pseudo_order: {
    on: 'reversePseudoOn',
    off: 'reversePseudoOff'
  },
  noti_timer_stop: {
    on: 'timerStopOn',
    off: 'timerStopOff'
  },
  noti_timer_start: {
    on: 'timerStartOn',
    off: 'timerStartOff'
  },
  noti_insuf_balance: {
    on: 'insufficientBalanceOn',
    off: 'insufficientBalanceOff'
  },
  noti_take_profit: {
    on: 'takeProfitOn',
    off: 'takeProfitOff'
  },
  noti_stop_loss: {
    on: 'stopLossOn',
    off: 'stopLossOff'
  },
  noti_user_stop_loss: {
    on: 'userStopLossOn',
    off: 'userStopLossOff'
  },
  noti_user_take_profit: {
    on: 'userTakeProfitOn',
    off: 'userTakeProfitOff'
  },
  noti_widthdraw: {
    on: 'widthdrawOn',
    off: 'widthdrawOff'
  },
  noti_deposit: {
    on: 'depositOn',
    off: 'depositOff'
  }
}

export const toggleExpandCurrentPlan = createAction(
  'dashboard_toggleExpandCurrentPlan'
);
export const setLanguageCode = createAction('setLanguageCode');
export const setSiteConfig = createAction('setSiteConfig');
export const setKickOut = createAction('appConfig/setKickOut');
export const setUserSettings = createAction('appConfig/setUserSettings');

export const loadSiteConfig = () => async (dispatch) => {
  const resp = await authApi.getAppConfig();
  const { ok, d } = resp?.data || {};
  if (ok) {
    dispatch(setSiteConfig(d));
    if (!d.mtn_enabled || isSTG()) {
      dispatch(initAuthReducer());
    }
  }
};

export const loadUserConfig = () => async (dispatch) => {
  dispatch(setUserSettings({ is_fetching: true }));
  const setting = await authApi.getUserSettingV2();
  dispatch(setLanguageCode(setting?.data?.d?.language || 'en'));
  i18n.changeLanguage(setting?.data?.d?.language || 'en');
  dispatch(setUserSettings({ ...setting?.data?.d, is_fetching: false }));
};

export const updateUserConfig = (key, value, uid) => async (dispatch) => {
  dispatch(setUserSettings({ is_fetching: true }));
  const res = await authApi.updateUserSettingV2(key, value);
  if (res?.data?.ok) {
    dispatch(setUserSettings({ [key]: value, is_fetching: false }));

    if (key === 'language') return true;

    const userConfig = userConfigs[key][value ? 'on' : 'off'];

    ga4Stats(gtag.setting.action[userConfig], gtag.setting.category, gtag.setting.label, uid);
  } else {
    dispatch(setUserSettings({ is_fetching: false }));
    toast.error(res?.data?.m);
  }
};
