import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { WithContext as ReactTags } from 'react-tag-input';
import { useSelector } from 'react-redux';

import { KEY_CODES } from '@/shared/constants';
import { Form } from '@/shared/components/form/drawer/Elements';

import { FORM_TYPE } from '@/shared/constants';

const InputTagExpert = ({
  expertValue,
  handleDeleteExpertValue,
  handleAdditionExpertValue,
  handleChangeLastExpertValue,
}) => {
  const { t } = useTranslation();

  const { currentBot, formType } = useSelector(
    (state) => ({
      currentBot: state.bot.currentBot,
      formType: state.bot.formBot.type,
    }),
    isEqual
  );

  return (
    <>
      <Form.Label className="d-flex align-items-center sub-label mt-3">
        {t('Signal Leader')}
      </Form.Label>

      <ReactTags
        classNames={{
          tagInputField: 'form-control',
        }}
        tags={expertValue}
        delimiters={[KEY_CODES.comma, KEY_CODES.enter]}
        handleDelete={handleDeleteExpertValue}
        handleAddition={handleAdditionExpertValue}
        handleInputChange={(text) => handleChangeLastExpertValue(text)}
        inputFieldPosition="inline"
        placeholder={t('Enter nickname')}
        readOnly={currentBot.is_default || formType === FORM_TYPE.read_only}
      />
    </>
  );
};

InputTagExpert.propTypes = {
  expertValue: PropTypes.instanceOf(Array).isRequired,
  handleDeleteExpertValue: PropTypes.func.isRequired,
  handleAdditionExpertValue: PropTypes.func.isRequired,
  handleChangeLastExpertValue: PropTypes.func.isRequired,
};

export default InputTagExpert;
