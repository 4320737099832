/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { selectTelegramChannels } from '@/redux/refactor/common/selector';
import { DrawerStyle, Form, drawerProps } from '@/shared/components/form/drawer/Elements';
import { Devices, colorText } from '@/utils/palette';
import { Box, useMediaQuery } from '@material-ui/core';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SIGNAL_STRATEGY_TYPE } from '@/shared/constants';
import { checkTelegram } from '../validator';
import { FormExternalLink, FormSelect } from './FormElements';
import TelegramMessage from '@/shared/components/telegram-msg';

const SelectBox = ({ values = [], disabled = false }) => {
  const [nameSelect, setNameSelect] = useState('telegram_chanels');
  const [placeholderSelect, setPlaceholderSelect] = useState('Select channel...');
  const [isDisabledSelect, setIsDisabledSelect] = useState(false);

  const options = useMemo(
    () => values.map((item) => ({ value: item.id, label: item.name })),
    [values]
  );
  const formState = useFormState();

  useEffect(() => {
    setNameSelect(formState?.values?.signal_type === SIGNAL_STRATEGY_TYPE.telegram ? 'telegram_chanels' : 'community_signals');
    setPlaceholderSelect(formState?.values?.signal_type === SIGNAL_STRATEGY_TYPE.telegram ? 'Select channel...' : 'Select community...');
  }, [formState?.values?.signal_type]);

  useEffect(() => {
    if (formState?.values?.signal_type === SIGNAL_STRATEGY_TYPE.telegram && formState?.values?.telegram_chanels?.length >= 5) {
      setIsDisabledSelect(true);
    } else if (formState?.values?.signal_type === SIGNAL_STRATEGY_TYPE.community && formState?.values?.community_signals?.length >= 5) {
      setIsDisabledSelect(true);
    } else {
      setIsDisabledSelect(false);
    }
  }, [formState?.values?.signal_type, formState?.values?.telegram_chanels]);

  return (
    <Field
      name={nameSelect}
      component={FormSelect}
      options={options}
      menuPlacement="auto"
      isMulti
      validate={checkTelegram}
      closeMenuOnSelect={false}
      isClearable={false}
      isOptionDisabled={() => isDisabledSelect}
      placeholder={t(placeholderSelect)}
      disabled
      className="full-width"
      classNamePrefix="react-select-style2"
    />
  );
};
export const LinkToTelegram = ({ channels = [], values = [] }) => {
  const [currentChannel, setCurrentChannel] = useState([]);
  useEffect(() => {
    if (values.length > 0 && channels.length > 0) {
      const ids = values?.reduce((acc, item) => [...acc, item.value], []);
      const channel = channels.filter((item) => item.data && ids.includes(item.id));
      setCurrentChannel(channel);
    } else {
      setCurrentChannel([]);
    }
  }, [values, channels]);
  return (
    currentChannel.length > 0 && (
      <LinkToTelegramStyle>
        <span className="title">Link:</span>
        {currentChannel.map((item, idx) => (
          <FormExternalLink key={item.id} href={item.data} target="_blank">
            {item.name}
            {idx < currentChannel.length - 1 ? ',' : ''}
          </FormExternalLink>
        ))}
      </LinkToTelegramStyle>
    )
  );
};

const LinkToTelegramStyle = styled(Box)`
  margin-top: 21px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: ${colorText};
  }
`;

// Begin SelectChannel
export default memo(({ setTelegramChannel, value = [], isEdit }) => {
  const [show, setShow] = useState(false);
  const isPc = useMediaQuery(Devices.pc);
  const { t } = useTranslation();
  const channels = useSelector(selectTelegramChannels || [], isEqual);
  const [filterChannels, setFilterChannels] = useState([]);
  const drawerProp = isPc ? drawerProps.right : drawerProps.mobile;
  const { values = {} } = useFormState();

  const toggle = () => setShow(!show);

  const onSaveChannel = (selected = []) => {
    setTimeout(() => {
      setTelegramChannel(mapSelectedChannel(channels, selected));
      toggle();
    }, 0);
  };

  useEffect(() => {
    if (!!channels.length && values?.signal_type) {
      setFilterChannels(channels.filter(c => c.type === values?.signal_type));
    }    
  }, [channels, values?.signal_type]);

  useEffect(() => {
    if (!isEdit && (!value.length || (value.length && !filterChannels.filter(c => c.id === value[0].value).length))) {
      setTimeout(() => {
        setTelegramChannel([
          {
            value: filterChannels[0]?.id,
            label: filterChannels[0]?.name,
          },
        ]);
      }, 0);
    }    
  }, [filterChannels, isEdit, value]);


  const selected = useMemo(() => value.reduce((acc, item) => [...acc, item.value], []), [value]);

  return (
    <Fragment>
      <StyledFormLabel>
        <Box>{values?.signal_type === SIGNAL_STRATEGY_TYPE.telegram ? t('Telegram channel') : t('Community signal')}</Box>
        <Box>
          <span className="me-1">{t('View Channel')}</span>
          <a onClick={toggle} href="javascript://">
            ({t('Details')})
          </a>
        </Box>
      </StyledFormLabel>
      <SelectBox values={filterChannels} />
      <LinkToTelegram channels={filterChannels} values={value} />
      <DrawerStyle {...drawerProp} open={show} onClose={toggle}>
        <Form.Header onClose={toggle} />
        <StyledTelegramWrapper>
          <TelegramMessage
            canSelect={true}
            defaultValues={selected}
            onBack={toggle}
            onSave={onSaveChannel}
          />
        </StyledTelegramWrapper>
      </DrawerStyle>
    </Fragment>
  );
});
// End SelectChannel

function mapSelectedChannel(collections, selected) {
  const fullData = [];
  for (let i = 0; i < selected.length; i++) {
    const foundItem = collections.find((item) => item.id === selected[i]);
    if (foundItem) {
      fullData.push({
        value: foundItem.id,
        label: foundItem.name,
      });
    }
  }
  return fullData;
}

const StyledFormLabel = styled(Form.Label)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledTelegramWrapper = styled.div`
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
