import { getNotifyPlans } from '@/redux/refactor/notification/actions';
import { Button } from '@/shared/components/Button';
import ButtonLoading from '@/shared/components/ButtonLoading';
import { PLAN_STATUS } from '@/shared/constants';
import planApi from '@/utils/api/planApi';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import './index.scss';

const PopupActivePlan = ({
  show,
  setShow,
  lastCreatedPlan,
  onEditPlan,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleUpdatePlanStatus = () => {
    setIsLoading(true);
    planApi
      .updateStatus(lastCreatedPlan.id, PLAN_STATUS.running)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.ok) {
          toast.success(
            t('Active {{name}} plan successfully!', {
              name: lastCreatedPlan.name,
            })
          );
          onSuccess();
          dispatch(getNotifyPlans());
        } else {
          toast.error(t(res.data.m));
        }
        setShow(false);
        setTimeout(() => {
          const elementBody = document.getElementsByTagName('body')[0];
          elementBody.style.overflow = null;
        }, 1000);
      });
  };

  if (!show) return null;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="popup-active-plan"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        {!lastCreatedPlan.copy_from && (
          <img
            src="/img/portfolio/img_2.png"
            style={{ maxWidth: 259, height: 'auto' }}
            alt=""
          />
        )}
        {!!lastCreatedPlan.copy_from && (
          <img src="/img/portfolio/img_1.png" alt="" />
        )}
        <p className="mb-3 h3">
          {!lastCreatedPlan.copy_from
            ? t('Do you want to active this plan now?')
            : t('Import plan successfully')}
        </p>
        <p className="text mb-1">
          {!lastCreatedPlan.copy_from
            ? t('This modification will active this plan')
            : t(
                'Start exploring investment opportunities and earn profits by start an investment plan today'
              )}
        </p>
        {!lastCreatedPlan.copy_from && (
          <p className="h4 text-ellipsis plan-name">{lastCreatedPlan?.name}</p>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {!isLoading && (
          <Button
            variant="success"
            block="true"
            onClick={() => handleUpdatePlanStatus(lastCreatedPlan)}
          >
            {t('Start Plan Now')}
          </Button>
        )}
        {isLoading && (
          <ButtonLoading
            paddinghorizontal="115"
            mblock="true"
            variant="success"
          />
        )}
        <Button
          variant="light"
          paddinghorizontal="115"
          block="true"
          onClick={() => onEditPlan(lastCreatedPlan)}
          className="text-success mt-2"
        >
          {t('Edit plan')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopupActivePlan.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  lastCreatedPlan: PropTypes.instanceOf(Object).isRequired,
  onEditPlan: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};
PopupActivePlan.defaultProps = {
  onSuccess: () => {},
};

export default PopupActivePlan;
