import planApi from '@/utils/api/planApi';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { findIndex } from 'lodash';
import i18n from 'i18next';

import gtag from '@/shared/constants/gtag';

const ga4Stats = window.ga4Stats;

export const updatePlans = createAction('UPDATE_PLANS');
export const updatePlan = createAction('UPDATE_PLAN');
export const updatePagination = createAction('UPDATE_PLAN_PAGINAION');
export const updateIsFetching = createAction('UPDATE_PLAN_IS_FETCHING');
export const updateAccountType = createAction('UPDATE_ACCOUNT_TYPE');
export const updatePageConfig = createAction('UPDATE_PLAN_PAGE_CONFIG');
export const updateCountdownTimer = createAction('UPDATE_COUNTDOWN_TIMER');
export const updateBetSession = createAction('UPDATE_BET_SESSION');
export const updateCurrentSession = createAction('UPDATE_CURRENT_SESSION');
export const updateOrderResult = createAction('UPDATE_ORDER_RESULT');
export const updateBacktestDataChart = createAction(
  'UPDATE_BACKTEST_DATA_CHART'
);
export const updateBacktestProgress = createAction('UPDATE_BACKTEST_PROGRESS');
export const updateBacktestId = createAction('UPDATE_BACKTEST_ID');
export const updateBacktestPrevHistory = createAction(
  'UPDATE_BACKTEST_PREV_HISTORY'
);
export const updateBacktestMoreConditions = createAction(
  'UPDATE_BACKTEST_MORE_CONDITIONS'
);
export const updateCandles = createAction('UPDATE_CANDLES');
export const updateFollwers = createAction('UPDATE_FOLLOWERS');
export const updateFollwersIsFetching = createAction(
  'UPDATE_FOLLOWERS_IS_FETCHING'
);
export const updateFollwersPagination = createAction(
  'UPDATE_FOLLOWERS_PAGINATION'
);

export const updateSelectedPlans = createAction(
  'UPDATE_SELECTED_PLANS'
);

export const updateCurrentPlan = createAction(
  'UPDATE_CURRENT_PLAN'
);

export const updateCurrentOrder = createAction(
  'UPDATE_CURRENT_ORDER'
);

export const updateListPlanLoading = createAction('Plan/UpdateListPlanLoading');

export const updateCurrentCondition = createAction(
  'UPDATE_CURRENT_CONDITION'
);

export const updateConditions = createAction(
  'UPDATE_CONDITIONS'
);

export const updateConditionIsLoading = createAction(
  'UPDATE_CONDITION_IS_LOADING'
);

export const updateConditionsShowFormContion = createAction(
  'UPDATE_CONDITIONS_IS_SHOW_FORM_CONDITION'
);

export const updateConditionsFormTypeContion = createAction(
  'UPDATE_CONDITIONS_FORM_TYPE_CONDITION'
);

export const updateIsEditReorderConditions = createAction(
  'UPDATE_IS_EDIT_REORDER_CONDITIONS'
);

export const updateIsSaveReorderConditions = createAction(
  'UPDATE_IS_SAVE_REORDER_CONDITIONS'
);

export const updateWaitSignal = createAction(
  'UPDATE_WAIT_SIGNAL'
);

export const updateDisplayReport = createAction(
  'UPDATE_DISPLAY_REPORT'
);

export const updateManualTradeOrderSessionReady = createAction('UPDATE_MANUAL_TRADE_ORDER_SESSION_READY');

export const getPlans =
  (params, loading = true) =>
  async (dispatch) => {
    dispatch(updateListPlanLoading(loading));
    try {
      const res = await planApi.getPlans(params);
      const data = res.data || res;
      if (data?.ok) {
        if (params.page > 1 && !data.d.c.length) {
          dispatch(
            updatePageConfig({
              ...params,
              page: params.page - 1,
            })
          );
          return;
        }

        dispatch(updatePlans(data.d.c));
        dispatch(
          updatePagination({
            p: data.d.p,
            s: data.d.s,
            t: data.d.t, // both live, demo
            r: data.d.r,
            all: data.d.all_total, // live or demo
            ended_total: data.d.endtarget_total,
            ongoing_total: data.d.ongoing_total,
            paused_total: data.d.pause_total,
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(updateListPlanLoading(false));
    }
  };

export const getPlan = (planId) => async (dispatch) => {
  dispatch(updateIsFetching(true));
  const { data } = await planApi.getPlan(planId);
  dispatch(updateIsFetching(false));
  if (data?.ok) {
    dispatch(updatePlan(data.d));
  } else {
    dispatch(updatePlan({ id: -1 }));
  }
};

export const getBacktestDataChart = (backtestId) => async (dispatch) => {
  planApi.getBacktestDataChart(backtestId).then((res) => {
    if (res.data.ok) {
      dispatch(updateBacktestDataChart(res.data.d.profits));
      dispatch(updateBacktestProgress(res.data.d.progress));
    }
  });
};

export const getFollowers = (params) => async (dispatch) => {
  dispatch(updateFollwersIsFetching(true));
  const { data } = await planApi.getFollowers(params);
  dispatch(updateFollwersIsFetching(false));
  if (data?.ok) {
    dispatch(updateFollwers(data.d.c));
    dispatch(
      updateFollwersPagination({
        p: data.d.p,
        s: data.d.s,
        t: data.d.t,
      })
    );
  }
};

export const getConditions = (planId) => async (dispatch) => {
  dispatch(updateConditionIsLoading(true));
  const { data } = await planApi.getConditions(planId);
  dispatch(updateConditionIsLoading(false));
  if (data?.ok) {
    dispatch(updateConditions(data.d.c));
  }
};

export const deleteCondition = (conditionId) => async (dispatch, getState) => {
  const currentState = getState();
  const conditions = currentState?.plan?.conditions ? [...currentState.plan.conditions] : [];

  dispatch(updateConditionIsLoading(true));
  const { data } = await planApi.deleteCondition(conditionId);
  dispatch(updateConditionIsLoading(false));

  if (data?.ok) {
    toast.success(i18n.t('Delete Strategy successfully!'));
    const indexOfCondition = conditions.findIndex((c) => c.id === conditionId);
    if (indexOfCondition > -1) {
      conditions.splice(indexOfCondition, 1);
      dispatch(updateConditions(conditions));
    }

    ga4Stats(gtag.plan.action.deleteProfitStrategy, gtag.plan.category, gtag.plan.label);
  } else {
    toast.error(i18n.t(data?.m));
  }
};

export const getCurrentCondition = (conditionId) => async (dispatch, getState) => {
  const currentState = getState();
  const conditions = currentState?.plan?.conditions;

  const indexCondition = findIndex(conditions, function(c) { return c.id === conditionId; });

  if (indexCondition > -1) {
    dispatch(updateCurrentCondition(conditions[indexCondition]));
  }
};

export const createCondition = (condition) => async (dispatch, getState) => {
  const currentState = getState();
  const conditions = currentState?.plan?.conditions ? [...currentState.plan.conditions] : [];

  dispatch(updateConditionIsLoading(true));
  const { data } = await planApi.createCondition(condition);

  if (!conditions.length) {
    dispatch(getConditions(condition.planId));
  }
  
  dispatch(updateConditionIsLoading(false));

  if (data?.ok) {
    toast.success(i18n.t('Create Strategy successfully!'));
    dispatch(updateConditionsShowFormContion(false));
    if (conditions.length) {
      conditions.splice(conditions.length - 1, 0, data?.d);
      dispatch(updateConditions(conditions));
    }

    ga4Stats(gtag.plan.action.createProfitStrategy, gtag.plan.category, gtag.plan.label);
  } else {
    toast.error(i18n.t(data?.m));
  }
};

export const updateCondition = (changedConditions) => async (dispatch) => {
  dispatch(updateConditionIsLoading(true));
  const { data } = await planApi.updateConditions(changedConditions);
  dispatch(getConditions(changedConditions[0].planId));
  dispatch(updateConditionIsLoading(false));

  if (data?.ok) {
    toast.success(i18n.t(changedConditions.length > 1 ? 'Update Strategies successfully!' : 'Update Strategy successfully!'));
    dispatch(updateConditionsShowFormContion(false));
    dispatch(updateIsEditReorderConditions(false));
    dispatch(updateIsSaveReorderConditions(false));

    if (changedConditions.length > 1) {
      ga4Stats(gtag.plan.action.editSequenceProfitStrategy, gtag.plan.category, gtag.plan.label);
    } else {
      ga4Stats(gtag.plan.action.editProfitStrategy, gtag.plan.category, gtag.plan.label);
    }
  } else {
    toast.error(i18n.t(data?.m));
  }
};

export const getWaitSignal = (planId) => async (dispatch) => {
  const { data } = await planApi.getWaitSignal(planId);

  if (data?.ok) {
    dispatch(updateWaitSignal({...data?.d, plan_id: planId}));
  } else {
    toast.error(i18n.t(data?.m));
  }
};
