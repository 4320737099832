import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
} from '@/shared/components/form/FormElements';
import { Box } from '@material-ui/core';
import { composeValidators, isValidEmail } from '@/shared/helpers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AccountButton } from '../AccountElements';
import { ModalAccountLinkStyle } from '../ModalAccount';
import FormField from '../../form/FormField';

const ForgotPasswordForm = ({
  onSubmit, closeModal, isLoading,
}) => {
  const { t } = useTranslation();
  const required = value => (value ? undefined : t('Required'));
  const isEmail = value => (isValidEmail(value) ? undefined : t('Your email is invalid'));
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, invalid }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupStyle>
            <FormGroupField>
              <Field
                validate={composeValidators(required, isEmail)}
                name="email"
                component={FormField}
                type="email"
                placeholder={t('Email')}
              />
            </FormGroupField>
          </FormGroupStyle>
          <Box marginBottom="40px">
            <ModalAccountLinkStyle onClick={closeModal}>{t('Return to login')}</ModalAccountLinkStyle>
          </Box>
          <AccountButton
            onClick={handleSubmit}
            disabled={invalid || isLoading}
            type="submit"
            size="lg"
            variant="success"
            isLoading={isLoading}
          >
            {t('Continue')}
          </AccountButton>
        </FormContainer>
    )}
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  isLoading: PropTypes.bool,
};
ForgotPasswordForm.defaultProps = {
  isLoading: false,
  closeModal: () => {},
};

const FormGroupStyle = styled(FormGroup)`
  margin-bottom: 32px;
`;

export default ForgotPasswordForm;
