import { selectStrategies } from '@/redux/refactor/common/selector';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBudgetById = (id) => {
  const [data, setData] = useState({});
  const strategies = useSelector(selectStrategies, isEqual);
  useEffect(() => {
    const strategy =
      strategies.find((item) => item.id.toString() === id?.toString()) || {};
    setData(strategy);
  }, [id, strategies]);
  return data;
};
