import { marginLeft, marginRight } from '@/utils/directions';
import {
  Devices,
  VARIANTS,
  colorBlue,
  colorBlueHover,
  colorDustyWhite,
  colorGray,
  colorGreyScale200,
  colorGreyScale300,
  colorGreyScale500,
  colorGreyScale600,
  colorGreyScale700,
  colorGreyScale900,
  colorLightGray,
  colorRed,
  colorRedHover,
  colorSuccess,
  colorSuccessDisabled,
  colorSuccessHover,
  colorWhite,
  colorYellow,
  colorYellowHover,
} from '@/utils/palette';
import _ from 'lodash';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button as BootstrapButton,
  ButtonGroup as BootstrapButtonGroup,
  ButtonToolbar as BootstrapButtonToolbar,
  Spinner,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const darkColor = {
  light: colorGreyScale900,
  dark: colorGreyScale700,
};

const getColor = (variant, theme = 'light') => {
  switch (true) {
    case variant?.includes('secondary'):
      return theme === 'light' ? colorGreyScale300 : colorGreyScale700;
    case variant?.includes('primary'):
      return colorBlue;
    case variant?.includes('success'):
      return colorSuccess;
    case variant?.includes('warning'):
      return colorYellow;
    case variant?.includes('danger'):
      return colorRed;
    case variant?.includes('dark'):
      return darkColor[theme];
    case variant?.includes('link'):
      return 'transparent';
    default:
      return colorLightGray;
  }
};

const getBorderColor = (variant, theme) => {
  switch (true) {
    case variant?.includes('secondary'):
      return colorGreyScale700;
    case variant?.includes('primary'):
      return colorBlue;
    case variant?.includes('success'):
      return colorSuccess;
    case variant?.includes('warning'):
      return colorYellow;
    case variant?.includes('danger'):
      return colorRed;
    case variant?.includes('dark'):
      return darkColor[theme];
    case variant?.includes('link'):
      return 'transparent';
    default:
      return colorLightGray;
  }
};

const getBackgroundColor = (variant, theme) => {
  if (!variant || variant?.includes('outline')) {
    return null;
  }

  return getColor(variant, theme);
};

const getHoverColor = (variant, theme) => {
  if (variant?.includes('outline')) {
    return getColor(variant, theme);
  }

  switch (true) {
    case variant?.includes(VARIANTS.OUTLINE_SUCCESS):
      return colorSuccess;
    case variant?.includes('secondary'):
      return theme === 'light' ? colorGreyScale200 : colorGreyScale300;
    case variant?.includes('primary'):
      return colorBlueHover;
    case variant?.includes('success'):
      return colorSuccessHover;
    case variant?.includes('warning'):
      return colorYellowHover;
    case variant?.includes('danger'):
      return colorRedHover;
    case variant?.includes('dark'):
      return colorGreyScale700;
    case variant?.includes('link'):
      return 'transparent';
    default:
      return null;
  }
};

const getBorderHoverColor = (variant, theme) => {
  if (variant?.includes('outline') && variant?.includes('secondary')) {
    return theme === 'light' ? colorGreyScale500 : colorGreyScale700;
  }
  return getHoverColor(variant, theme);
};

const getBackgroundHoverColor = (variant, theme) => {
  if (variant?.includes('outline')) {
    return 'transparent';
  }

  switch (true) {
    case variant?.includes(VARIANTS.OUTLINE_SUCCESS):
      return colorSuccess;
    case variant?.includes('secondary'):
      return theme === 'light' ? colorGreyScale200 : colorGreyScale700;
    case variant?.includes('primary'):
      return colorBlueHover;
    case variant?.includes('success'):
      return colorSuccessHover;
    case variant?.includes('warning'):
      return colorYellowHover;
    case variant?.includes('danger'):
      return colorRedHover;
    case variant?.includes('dark'):
      return colorGreyScale700;
    case variant?.includes('link'):
      return 'transparent';
    default:
      return null;
  }
};

const getDisabledColor = (variant, theme) => {
  if (variant?.includes('outline')) {
    return getColor(variant, theme);
  }

  switch (true) {
    case variant?.includes('secondary'):
      return null;
    case variant?.includes('primary'):
      return colorBlueHover;
    case variant?.includes('success'):
      return colorSuccessDisabled;
    case variant?.includes('warning'):
      return colorYellowHover;
    case variant?.includes('danger'):
      return colorRedHover;
    case variant?.includes('dark'):
      return colorGreyScale600;
    case variant?.includes('link'):
      return 'transparent';
    default:
      return null;
  }
};

const getHoverTextColor = (variant, theme) => {
  if (!variant || variant?.includes('secondary') || variant?.includes('link')) {
    return colorGray;
  }

  if (variant?.includes('outline')) {
    return getColor(variant, theme);
  }

  return colorWhite;
};

const getTextColor = (variant, theme) => {
  if (!variant?.includes('outline')) {
    if (variant?.includes('secondary')) {
      return theme === 'light' ? colorGreyScale700 : colorWhite;
    }
    return colorWhite;
  }

  return variant?.includes('secondary')
    ? theme === 'light'
      ? colorGreyScale700
      : colorWhite
    : getColor(variant, theme);
};

const getPaddings = (size, paddingHorizontal) => {
  switch (size) {
    case 'sm':
      return `6.5px ${paddingHorizontal || 24}px`;
    case 'lg':
      return `16px ${paddingHorizontal || 24}px`;
    default:
      return `13px ${paddingHorizontal || 24}px`;
  }
};

const getBorderRadius = (rounded, squared) => {
  switch (true) {
    case rounded:
      return '30px';
    case squared:
      return 0;
    default:
      return '12px';
  }
};
const SpinnerWrap = styled.div`
  margin-right: 8px;
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isloading &&
    `
    svg {
      display: none;
    }
  `}
`;

export const Button = ({ children, isLoading, ...props }) => {
  const { mode } = useSelector(
    (state) => ({
      mode: state.theme.className,
    }),
    _.isEqual
  );

  return (
    <ButtonStyle theme={mode} {...props}>
      {isLoading && (
        <SpinnerWrap>
          <Spinner animation="border" size="sm" />
        </SpinnerWrap>
      )}
      <ButtonContent isloading={isLoading}>{children}</ButtonContent>
    </ButtonStyle>
  );
};
Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  isLoading: false,
};

export const ButtonStyle = styled(BootstrapButton)`
  border-radius: ${(props) => getBorderRadius(props.rounded, props.squared)};
  padding: ${(props) => getPaddings(props.size)};
  // transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: ${(props) =>
    getBackgroundColor(props.variant, props.theme) || 'transparent'};
  border: 1px solid;
  border-color: ${(props) => getBorderColor(props.variant, props.theme)};
  color: ${(props) => getTextColor(props.variant, props.theme)};
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${(props) => props.fw || 700};
  ${(props) =>
    props.mblock &&
    `
    width: 100%;
  `}

  @media ${Devices.pc} {
    padding: ${(props) => getPaddings(props.size, props.paddinghorizontal)};
    ${(props) =>
      props.mblock &&
      `
      width: unset;
    `}
  }

  ${(props) =>
    props.block &&
    `
    width: 100%
  `};

  &:last-child {
    margin-right: 0;
    ${marginRight}: 0;
  }

  span {
    // transition: all 0.3s;
    font-weight: 500;
    color: ${(props) => getTextColor(props.variant, props.theme)};
  }

  svg {
    height: 20px;
    width: 20px;
    // transition: all 0.3s;
    /* fill: ${(props) => getTextColor(props.variant)}; */
    margin-right: 4px;
    /* &:not(:last-child) {
      ${marginRight}: 5px;
    }
    
    &:not(:first-child) {
      ${marginLeft}: 5px;
    } */
  }

  &:hover,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    color: ${(props) =>
      getHoverTextColor(props.variant, props.theme)}!important;
    border-color: ${(props) =>
      getBorderHoverColor(props.variant, props.theme) ||
      darken(0.1, colorLightGray)}!important;
    background-color: ${(props) =>
      getBackgroundHoverColor(props.variant, props.theme) ||
      'transparent'}!important;
    // transition: all 0.4s;
    span {
      color: ${(props) => getHoverTextColor(props.variant, props.theme)};
    }

    svg {
      /* fill: ${(props) => getHoverTextColor(props.variant)}; */
    }
  }

  &:disabled {
    background-color: ${(props) =>
      getDisabledColor(props.variant, props.theme)}!important;
    border-color: ${(props) =>
      getDisabledColor(props.variant, props.theme)}!important;
    pointer-events: none !important;
    // transition: all 0.4s;
    opacity: 1;
    &:hover {
      cursor: no-drop !important;
    }
    span {
      color: ${colorDustyWhite};
    }

    svg {
      /* fill: ${colorDustyWhite}; */
    }
  }
`;

export const ButtonToolbar = styled(BootstrapButtonToolbar)`
  margin-top: 15px;
  margin-bottom: 10px;

  & > * {
    ${marginRight}: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  ${(props) =>
    props.centered &&
    `
    & > * {
      &,
      &:last-child,
      &:first-child {
        margin-right: auto;
        margin-left: auto;
      }
    }
  `}
`;

export const ButtonGroup = styled(BootstrapButtonGroup)`
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    padding: 10px 15px;
    font-weight: 500;
    ${marginRight}: 0;
  }

  ${(props) =>
    props.justified &&
    `
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  `}

  ${(props) =>
    props.icons &&
    `
    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  `}

  &.open .dropdown-toggle {
    box-shadow: none;
  }
`;
