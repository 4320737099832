import { Form } from '@/shared/components/form/drawer/Elements';
import { Box } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { convertMaskToNum } from '../validator';
import { BalanceInput, DefaultTarget, FieldStyle } from './FormElements';

const defaultTargets = [10, 20, 50];

export default withTranslation()(
  ({ label, name, placeholder, onSelect, type }) => {
    const validate = (value) => {
      const num = convertMaskToNum(value);
      if (isNaN(num)) return 'not_a_number';
      if (num > 1000000) return 'amount_too_large';
      return undefined;
    };
    return (
      <Box flex={1}>
        <Form.Label>{label}</Form.Label>
        <Box display="flex" alignItems="center">
          <Box maxWidth="90px">
            <FieldStyle
              valuetype={type}
              name={name}
              component={BalanceInput}
              placeholder={placeholder}
              validate={validate}
            />
          </Box>
          <DefaultTarget
            onSelect={onSelect}
            options={defaultTargets}
            affix="%"
          />
        </Box>
      </Box>
    );
  }
);
