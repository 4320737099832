import { createAction } from 'redux-actions';
import authApi from '@/utils/api/authApi';

export const updateUserFollowers = createAction('UPDATE_USER_FOLLOWERS');
export const updateUserFollowerPagination = createAction('UPDATE_USER_FOLLOWER_PAGINAION');
export const updateUserFollowerIsFetching = createAction('UPDATE_UserFollower_IS_FETCHING');

export const getUserFollowers = params => async (dispatch) => {
  dispatch(updateUserFollowerIsFetching(true));
  const { data } = await authApi.getUserFollowers(params);
  dispatch(updateUserFollowerIsFetching(false));
  if (data?.ok) {
    dispatch(updateUserFollowers(data.d.c));
    dispatch(updateUserFollowerPagination({
      p: data.d.p,
      s: data.d.s,
      t: data.d.t,
    }));
  }
};
