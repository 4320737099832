import { handleActions } from 'redux-actions';
import { orderBy } from 'lodash';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  loginRequire2Fa,
  verify2FaRequest,
  verify2FaSuccess,
  verify2FaFailure,
  updateProfile,
  setExchangeInfo,
  setExchangeInfoLoading,
  updateTutorialState,
  reset2Fa,
  setIsError,
} from './actions';

const defaultState = {
  connected: false,
  token2Fa: null,
  profile: null,
  isFetching: false,
  isRequire2Fa: false,
  isVerifying: false,
  isChecking: false,
  isError: false,
};

export default handleActions(
  {
    [loginRequest](state) {
      return {
        ...state,
        isFetching: true,
        isRequire2Fa: false,
        isVerifying: false,
      };
    },
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        token: payload,
        isFetching: false,
      };
    },
    [loginFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
      };
    },
    [loginRequire2Fa](state, { payload }) {
      return {
        ...state,
        token2Fa: payload,
        isRequire2Fa: true,
        isFetching: false,
      };
    },
    [verify2FaRequest](state) {
      return {
        ...state,
        isVerifying: true,
        isFetching: false,
      };
    },
    [verify2FaSuccess](state, { payload }) {
      return {
        ...state,
        token: payload,
        token2Fa: null,
        isRequire2Fa: false,
        isVerifying: false,
      };
    },
    [verify2FaFailure](state, { payload }) {
      return {
        ...state,
        isVerifying: false,
        isFetching: false,
      };
    },
    [updateProfile](state, { payload }) {
      return {
        ...state,
        profile: payload,
        isVerifying: false,
        isFetching: false,
      };
    },
    [setExchangeInfo](state, { payload }) {
      localStorage.setItem('TUTORIAL_STATE', payload.tutorial_state);
      return {
        ...state,
        ...payload,
        isError: false,
        linkAccountConnectedHistories: orderBy(
          payload.linkAccountConnectedHistories,
          ['last_connected_time'],
          ['desc']
        ),
        connected: payload.is_connected,
      };
    },
    [setExchangeInfoLoading](state, { payload }) {
      return {
        ...state,
        isChecking: payload,
      };
    },
    [updateTutorialState](state, { payload }) {
      localStorage.setItem('TUTORIAL_STATE', payload);
      return state;
    },
    [reset2Fa](state, { payload }) {
      return {
        ...state,
        token: null,
        token2Fa: null,
        isFetching: false,
        isRequire2Fa: false,
        isVerifying: false,
      };
    },
    [setIsError](state, { payload }) {
      return {
        ...state,
        isError: payload,
      };
    },
  },
  defaultState
);
