import { BET_TYPE } from '@/shared/constants';

export const createDefaultValues = (backtestMoreConditions) => {
  return {
    budget: 1000,
    subProfit: backtestMoreConditions?.subProfit ? backtestMoreConditions?.subProfit : false,
    sub_take_profit: backtestMoreConditions?.sub_take_profit ? backtestMoreConditions?.sub_take_profit : 0,
    sub_stop_loss: backtestMoreConditions?.sub_stop_loss ? backtestMoreConditions?.sub_stop_loss : 0,
    sub_win_streak: backtestMoreConditions?.sub_win_streak ? backtestMoreConditions?.sub_win_streak : 0,
    sub_lose_streak: backtestMoreConditions?.sub_lose_streak ? backtestMoreConditions?.sub_lose_streak : 0,
    reverse_signal: backtestMoreConditions?.reverse_signal ? true : false,
    wait_signal: backtestMoreConditions?.wait_signal ? true : false,
    waitSignalType: backtestMoreConditions?.waitSignalType ? backtestMoreConditions?.waitSignalType : BET_TYPE.win,
    win_streak: backtestMoreConditions?.win_streak ? backtestMoreConditions?.win_streak : 1,
    lose_streak: backtestMoreConditions?.lose_streak ? backtestMoreConditions?.lose_streak : 1,
    no_of_entries: backtestMoreConditions?.no_of_entries > 1 ? backtestMoreConditions?.no_of_entries : 1,
    isUltilFinishBudget: Number(backtestMoreConditions?.no_of_entries) > 0 ? 'b' : 'a',
  };
};
