/* eslint-disable react/prop-types */
import Icons from '@/shared/components/icons';
import {
  Devices,
  colorBackgroundModal,
  colorGreyScale500,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader } from 'react-bootstrap';
import styled from 'styled-components';

const ModalSettings = ({
  title,
  subTitle,
  isOpen,
  closeModal,
  children,
  maxWidth,
}) => (
  <ModalSettingStyle
    maxwith={maxWidth}
    onHide={closeModal}
    show={isOpen}
    centered
  >
    <ModalHeaderStyle className="d-flex justify-content-between align-items-start">
      <ModalTitleStyle>
        {title}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </ModalTitleStyle>
      <Box textAlign="right">
        <ModalSettingBtnClose onClick={closeModal} />
      </Box>
    </ModalHeaderStyle>
    {children}
  </ModalSettingStyle>
);

export const ModalSettingStyle = styled(Modal)`
  z-index: 1301;
  .modal-dialog {
    max-width: ${(props) =>
      props.maxwith ? props.maxwith : 'calc(598px + 16px)'};
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }

  .modal-content {
    overflow: hidden;
    border-radius: 16px !important;
    @media ${Devices.pc} {
      border-radius: 24px !important;
    }
  }
`;
export const ModalHeaderStyle = styled(ModalHeader)`
  padding: 16px;
  .modal-title {
    font-weight: 800;
  }
  @media ${Devices.pc} {
    padding: 24px;
  }
`;
export const ModalTitleStyle = styled(Modal.Title)`
  font-size: 17px;
  line-height: 140%;
  @media ${Devices.pc} {
    font-size: 20px;
  }
`;
export const ModalSettingBodyStyle = styled(Modal.Body)`
  padding: 16px;
  background-color: ${colorBackgroundModal};
  @media ${Devices.pc} {
    padding: 24px;
  }
`;
export const ModalSettingFooterStyle = styled(Modal.Footer)`
  padding: 16px;
  background-color: ${colorBackgroundModal};
  justify-content: center;
  @media ${Devices.pc} {
    padding: 24px;
  }
`;
export const ModalSettingBtnClose = styled(Icons.CloseIcon)`
  :hover {
    cursor: pointer;
  }
`;

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${colorGreyScale500};
`;

ModalSettings.propTypes = {
  title: PropTypes.any,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ModalSettings.defaultProps = {
  title: '',
  isOpen: false,
};

export default ModalSettings;
