/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import {
  colorSuccess,
  colorBackgroundModal,
  colorGreyScale600,
  colorText,
  Devices,
  colorOtpInputBackground,
  colorOtpInputText,
} from '@/utils/palette';
import { Box } from '@material-ui/core';
import Logo from '../Logo';
import Icons from '../icons';

const ModalAccount = ({ isOpen, closeModal, children }) => (
  <ModalAccountStyle onHide={closeModal} show={isOpen} centered>
    <ModalAccountBodyStyle>
      <Box textAlign="right">
        <ModalAccountBtnClose onClick={closeModal} />
      </Box>
      <Logo width="173.9" height="36" marginBottom="40px" />
      {children}
    </ModalAccountBodyStyle>
  </ModalAccountStyle>
);

export const ModalAccountStyle = styled(Modal)`
  .modal-dialog {
    max-width: calc(520px + 16px);
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }
  .modal-content {
    overflow: hidden;
    border-radius: 24px !important;
  }
`;
export const ModalAccountBodyStyle = styled(Modal.Body)`
  padding: 24px;
  background-color: ${colorBackgroundModal};
  .paste-code {
    margin-bottom: 24px
  }
  @media ${Devices.pc} {
    padding: 32px;
    .paste-code {
      margin-bottom: 40px
    }
  }
`;
export const ModalAccountBtnClose = styled(Icons.CloseIcon)`
  :hover {
    cursor: pointer;
  }
`;
export const ModalAccountTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: ${colorText};
  margin-bottom: 12px;
  @media ${Devices.pc} {
    font-size: ${props => props.fontsize || '24px'};
  }
`;
export const ModalAccountDescription = styled.div`
  font-weight: 500;
  color: ${colorGreyScale600};
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 150%;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: ${colorGreyScale600};
    margin-bottom: 24px;
    a {
      color: ${colorSuccess}!important;
    }
  }
  @media ${Devices.pc} {
    font-size: 18px;
    p {
      font-size: 18px;
      line-height: 150%;
    }
  }
`;
export const ModalAccountLinkStyle = styled(Box)`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.2px;
  color: ${colorSuccess};
  cursor: pointer;
`;
export const ModalAccountOtpInputStyle = styled(OtpInput)`
  flex: 1;
  margin-bottom: 24px;
  input {
    font-family: 'Manrope';
    width: 40px !important;
    height: 40px !important;
    border: 1px solid transparent;
    background: ${colorOtpInputBackground};
    color: ${colorOtpInputText};
    border-radius: 12px;
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    caret-color: transparent;
    :hover,
    :focus-visible {
      border-color: ${colorSuccess}!important;
      background: none;
      outline: none !important;
    }
  }
  @media ${Devices.pc} {
    margin-bottom: 32px;
    input {
      width: 56px !important;
      height: 56px !important;
      font-size: 24px;
    }
  }
`;

ModalAccount.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ModalAccount.defaultProps = {
  isOpen: false,
};

export default ModalAccount;
