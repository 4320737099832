import React from 'react';
import BubbleConditions from './bubble-conditions';
import BubblePanel from './bubble-panel';
import './index.scss';

export default function CandleConfigPanel({ isEdit, isReadOnly, currentCandle, sources }) {
  return (
    <div className="CandlePanel">
      <BubblePanel isReadOnly={isReadOnly} currentCandle={currentCandle} />
      <BubbleConditions isEdit={isEdit} isReadOnly={isReadOnly} sources={sources} currentCandle={currentCandle?.i} />
    </div>
  );
}

