import planApi from '@/utils/api/planApi';
import { createQuery } from 'react-query-kit';

export default createQuery({
  primaryKey: 'getBacktestHistoryDetail',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data = {} } = await planApi.getBacktestHistoryDetail(variables);
    return data?.d || {};
  },
});
