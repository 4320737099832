import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@/shared/helpers';

const Detail = ({
  item,
}) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState('');
  const [issue, setIssue] = useState('');

  useEffect(() => {
    if (item?.subject) {
      const s = item.subject.split('-');
      setSubject(s[1]);
      setIssue(s[0]);
    }
  }, [item]);

  return (
    <div className="ticket-detail">
        <div className="ticket-detail-header">  
            <h2
              className="ticket-detail-title"
              dangerouslySetInnerHTML={{
                __html: t('Ticket successfully created!'),
              }}
            />
        </div>
        
        <div className="ticket-detail-body">
            <h3 className="ticket-detail-subject">{t('Ticket ID')}: #{item.id}</h3>
            <ul className="ticket-detail-properties">
                <li className="ticket-detail-item">
                    <span className="ticket-detail-item-label">{ t('Created time') }:</span>
                    <span className="ticket-detail-item-value">{ dateFormat(item.created_at) }</span>
                </li>
                <li className="ticket-detail-item">
                    <span className="ticket-detail-item-label">{ t('Issue') }:</span>
                    <span className="ticket-detail-item-value">{ issue ? t(issue.trim()) : '' }</span>
                </li>
                <li className="ticket-detail-item">
                    <span className="ticket-detail-item-label">{ t('Subject') }:</span>
                    <span className="ticket-detail-item-value">{ subject ? t(subject.trim()) : '' }</span>
                </li>
            </ul>
        </div>
    </div>
  );
};

Detail.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default Detail;
