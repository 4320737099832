import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars';
import { NoteRemove } from 'iconsax-react';
import ListItem from './ListItem';

const List = ({ ticketList, getListComment }) => {
  const { t } = useTranslation();

  return (
    <div className={`ticket-content ${!ticketList.length ? 'is-empty' : ''}`}>
      {!ticketList.length && (
        <div className="ticket-empty">
          <span className="ticket-empty-icon">
            <NoteRemove
              size="80"
              color="#CBD5E0"
              variant="Bold"
            />
          </span>
          <p className="ticket-empty-text mb-0">{t('There is no tickets here!')}</p>
        </div>
      )}
      {!!ticketList.length && (
        <Scrollbars>
          <div className="ticket-list">
            {ticketList.map(item => (
              <ListItem item={item} key={item.id} getListComment={getListComment} />
            ))}
          </div>
        </Scrollbars>
      )}
    </div>
  );
};

List.propTypes = {
  ticketList: PropTypes.instanceOf(Array).isRequired,
  getListComment: PropTypes.func.isRequired,
};

export default List;
