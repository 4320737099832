import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { NoteRemove } from 'iconsax-react';

const Nodata = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center no-data" {...props}>
      <NoteRemove
        size="132"
        color="#CBD5E0"
        variant="Bold"
      />
      <p className="text" dangerouslySetInnerHTML={{
          __html: text || t('There is no data here!'),
      }} />
    </div>
  );
};

Nodata.propTypes = {
  text: PropTypes.string,
};

Nodata.defaultProps = {
  text: '',
};

export default Nodata;
