/* eslint-disable react/prop-types */
import { colorGreyScale500 } from '@/utils/palette';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import styled from 'styled-components';
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M4 5.99982L8 9.99982L12 5.99982"
          stroke="#A0AEC0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const DropdownPlaceholder = (props) => {
  const { t } = useTranslation();
  return <PlaceHolderStyle {...props}>{t('Select...')}</PlaceHolderStyle>;
};

const PlaceHolderStyle = styled.span`
  color: ${colorGreyScale500};
`;
