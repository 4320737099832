import { colorBackground } from '@/utils/palette';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Loading = ({ loading, message, Icon }) => (
  <Load
    loading={Number(loading)}
    className={classNames({
      loaded: loading,
    })}
  >
    <LoadIconWrap>
      <LoadIcon>
        <path fill="#0CAF60" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </LoadIcon>
      {Icon && <Icon color="#0CAF60" style={{ marginLeft: -29 }} size="32" />}
      {message && <span className="loading-message">{message}</span>}
    </LoadIconWrap>
  </Load>
);

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;

// region STYLES

const Load = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: ${colorBackground};
  z-index: 1000;
  left: 0;
  top: 0;
  ${(props) => !props.loading && 'animation: ease loaded 0.5s'};

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  @keyframes loaded {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .loading-message {
    margin-left: 24px;
    font-family: 'Manrope';
    font-weight: 500 !important;
  }
`;

const LoadIconWrap = styled.div`
  margin: auto;
`;

const LoadIcon = styled.svg`
  animation: linear load 1s infinite;
  margin: auto;
  width: 32px;
  height: 32px;

  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }
`;

//
