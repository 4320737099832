import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateShowPopupTicket } from '@/redux/actions/ticketActions';
import {
  I24Support, Add,
} from 'iconsax-react';

const ShowIcon = () => {
  const dispatch = useDispatch();
  const isShowPopup = useSelector(state => state.ticket.isShowPopup, shallowEqual);

  return (
    <>
      <button
        type="button"
        className={`ticket-show-icon ${isShowPopup ? 'close' : ''}`}
        onClick={() => dispatch(updateShowPopupTicket())}
      >
        {!isShowPopup && (
          <I24Support
            size="24"
            color="#fff"
          />
        )}

        {isShowPopup && (
          <Add
            size="24"
            color="#fff"
            style={{ transform: 'rotate(45deg)' }}
          />
        )}
      </button>
    </>
  );
};

export default ShowIcon;
