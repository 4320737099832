import useAuth from '@/hooks/useAuth';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setHubConnection } from '@/utils/helpers';

import {
  SOCKET_CONFIG,
  SOCKET_URL,
  accessTokenFactory,
  eventHandler,
  logLevel,
  onCandlePattern,
  onClose,
  serverTimeoutInMilliseconds,
} from './helper';

let connection = null;

const SignalHub = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuth } = useAuth();
  const [didStart, setDidStart] = useState(false);

  const initConnection = () => {
    connection = new HubConnectionBuilder()
      .withUrl(SOCKET_URL, {
        ...SOCKET_CONFIG,
        accessTokenFactory: () =>
          accessTokenFactory({
            dispatch,
            connection,
          }),
      })
      .withAutomaticReconnect()
      .configureLogging(logLevel)
      .build();
    connection.serverTimeoutInMilliseconds = serverTimeoutInMilliseconds;
    eventHandler({ dispatch, connection });
    connection.onclose((e) => console.log('Socket on close'));

    setHubConnection(connection);

    connection
      .start()
      .then(() => {
        setDidStart(true);
        // getLastMessages({ dispatch, connection });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (isAuth) {
      initConnection();
    }
    return () => {
      onClose(connection);
    };
  }, [isAuth]);

  useEffect(() => {
    if (didStart) {
      const pathName = location.pathname;
      if (
        pathName.includes('/manual-trade') ||
        pathName.includes('/portfolios/') ||
        pathName.includes('/signal-strategies')
      ) {
        connection.invoke('SubscribeTimer').then(
          (resp = []) => {
            // console.log(resp);
          },
          (error) => console.log({ error })
        );
        connection.invoke('GetLastCandlePattern').then(
          (message) => {
            onCandlePattern({ dispatch, message });
          },
          (error) => console.log({ error })
        );
      } else {
        connection.invoke('UnSubscribeTimer').then(
          (resp) => {
            // console.log(resp);
          },
          (error) => console.log({ error })
        );
      }
    }
  }, [didStart, location]);
  return null;
};

export default SignalHub;
