import { isEmpty, isEqual } from 'lodash';
import { Devices } from '@/utils/palette';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { DrawerStyle, drawerProps } from '@/shared/components/form/drawer/Elements';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateBacktestMoreConditions } from '@/redux/actions/planActions';
import { SIGNAL_STRATEGY_TYPE, STRATEGY_METHOD } from '@/shared/constants';
import { useBudgetById } from '@/shared/components/portfolio/form/useBudgetById';
import { createDefaultValues } from './createDefaultValues';
import { mutators } from './mutators';
import { advanceValidate } from './validator';
import { selectUserConfig } from '@/redux/refactor/appConfig/selector';

import { Grid } from '@/shared/components/form/drawer/Elements';
import { Button } from '@/shared/components/Button';
import { Form } from '@/shared/components/form/drawer/Elements';
import ReverseSignal from '@/shared/components/portfolio/form/components/ReverseSignal';
import { SubProfit } from '@/shared/components/portfolio/form/components/SubProfit';
import WaitSignal from '@/shared/components/portfolio/form/components/WaitSignal';
import { createBody } from './createBody';

const DrawerCreateOptionsBackTest = ({
  isOpen,
  onClose,
  signalType,
  budgetId,
}) => {
  const dispatch = useDispatch();
  const isPc = useMediaQuery(Devices.pc);

  const { t } = useTranslation();
  
  const { userConfigs, backtestMoreConditions } = useSelector(
    (state) => ({
      userConfigs: selectUserConfig(state),
      backtestMoreConditions: state.plan.backtestMoreConditions,
    }),
    isEqual
  );

  const initialValues = useMemo(() => createDefaultValues(backtestMoreConditions), [backtestMoreConditions]);

  const strategy = useBudgetById(budgetId);
  const showSubProfit =
    SIGNAL_STRATEGY_TYPE.expert !== signalType &&
    strategy?.method_code &&
    STRATEGY_METHOD.all_order !== strategy?.method_code;

  const submit = (values = {}) => {
    const body = createBody(values);
    dispatch(updateBacktestMoreConditions({...backtestMoreConditions, ...body}));
    onClose();
  };

  const modalProp = useCallback(() => {
    return isPc ? drawerProps.right : drawerProps.mobile;
  }, [isPc]);

  useEffect(() => {
    if (!showSubProfit) {
      dispatch(updateBacktestMoreConditions({...backtestMoreConditions, subProfit: false}));
    }
  }, [showSubProfit])

  return (
    <DrawerStyle
      ModalProps={{ keepMounted: false }}
      open={isOpen}
      onClose={onClose}
      {...modalProp()}
    >
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        mutators={mutators}
        validate={advanceValidate}
      >
        {({ form, handleSubmit, invalid, values, errors }) => (
          <>
            <Form.Header Title={t('Add more conditions')} onClose={onClose} />
            <Form.Body>
              <Grid>
                <SubProfit mutators={form.mutators} show={showSubProfit} values={values} />
                <Grid.Row className="border-none">
                  <Grid.Cell>
                    <ReverseSignal show reversePseudo={userConfigs.reverse_pseudo_order} />
                  </Grid.Cell>
                </Grid.Row>

                <Grid.Row className="border-none">
                  <Grid.Cell>
                    <WaitSignal
                      mutators={mutators}
                      value={values.wait_signal}
                      waitSignalType={values.waitSignalType}
                      show
                    />
                  </Grid.Cell>
                </Grid.Row>
              </Grid>
            </Form.Body>
            <Form.Footer>
              <Button
                variant="secondary_outline"
                className="cta-back"
                onClick={onClose}
              >
                {t('Cancel')}
              </Button>
              <Button disabled={invalid} variant="success" block="true" onClick={handleSubmit}>
                {isEmpty(backtestMoreConditions) ? t('Add') : t('Update')}
              </Button>
            </Form.Footer>
          </>
        )}
      </Form>
    </DrawerStyle>
  );
};

DrawerCreateOptionsBackTest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

DrawerCreateOptionsBackTest.defaultProps = {
  onSuccess: () => {},
};

export default memo(DrawerCreateOptionsBackTest);
