
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  colorGreyScale600, colorText, Devices,
} from '@/utils/palette';
import styled from 'styled-components';
import _ from 'lodash';

const TopbarLeftContent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const profile = useSelector(state => state.user.profile, _.isEqual);
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.indexOf('portfolio') > -1) {
      setPageName('portfolio');
    } else if (currentPath.indexOf('budget-strategies') > -1) {
      setPageName('buget-strategy');
    } else if (currentPath.indexOf('signal-strategy') > -1) {
      setPageName('bot');
    } else if (currentPath.indexOf('affiliation') > -1) {
      setPageName('affiliation');
    } else if (currentPath.indexOf('settings') > -1) {
      setPageName('settings');
    } else if (currentPath.indexOf('manual-trade') > -1) {
      setPageName('manual-trade');
    } else if (currentPath.indexOf('referral') > -1) {
      setPageName('referral');
    } else {
      setPageName('dashboard');
    }
  }, [location]);

  return (
    <TopbarLeftContentWrap>
      {pageName === 'dashboard' && (
        <>
          <Title>{t('Hello, {{name}}!', { name: profile?.ln || profile?.nn })}</Title>
        </>
      )}
      {pageName === 'portfolio' && (
        <>
          <Title>{t('Investment Plan')}</Title>
        </>
      )}

      {pageName === 'buget-strategy' && (
        <>
          <Title>{t('Manage Strategy')}</Title>
        </>
      )}

      {pageName === 'bot' && (
        <>
          <Title>{t('Manage Signal')}</Title>
        </>
      )}

      {pageName === 'affiliation' && (
        <>
          <Title>{t('VIP MEMBER')}</Title>
        </>
      )}

      {pageName === 'settings' && (
        <>
          <Title>{t('Setting')}</Title>
        </>
      )}

      {pageName === 'manual-trade' && (
        <>
          <Title>{t('Manual Trade')}</Title>
        </>
      )}

      {pageName === 'referral' && (
        <>
          <Title>{t('Referral')}</Title>
        </>
      )}
    </TopbarLeftContentWrap>
  );
};

const TopbarLeftContentWrap = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  @media ${Devices.laptop} {
    display: flex;
  }

`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: ${colorText};
  margin-bottom: 4px
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: ${colorGreyScale600};
`;
export default TopbarLeftContent;
